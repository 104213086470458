import React, { ReactNode, useState } from "react";
import styled, { isMobile } from "style/styled-components";
import strings from "localisation/strings";

import DateTimeRange from "api/models/DateTimeRange";
import Location from "api/models/Location";
import ModifiedBy from "api/models/ModifiedBy";
import PriceMinMax from "api/models/PriceMinMax";
import PublicationStatuses from "api/models/PublicationStatuses";
import { UserStatuses } from "api/models/RepresentativeInterface";
import TagInterface from "api/models/TagInterface";

import ListItemActions from "components/lists/item/ListItemActions";
import ListItemLocation from "components/lists/item/ListItemLocation";
import ListItemDateTime from "components/lists/item/ListItemDateTime";
import ListItemPriceDisplay from "components/lists/item/ListItemPriceDisplay";
import LastModified from "components/generic/LastModified";
import useWindowDimensions from "hooks/useWindowDimensions";

import {
  DescriptionArea,
  Details,
  HideLink,
  Image,
  ListItemContainer,
  ListItemProps,
  PriceAndModified,
  Tags,
  EmojiTitle,
} from "components/lists/item/BaseListItemComponents";
import ContentType from "common/api/models/ContentType";

const BaseInfo = styled.div`
  display: flex;
  flex-grow: 2;
`;

export interface BaseListItemProps extends ListItemProps {
  children?: ReactNode;
  dateTimeRange?: DateTimeRange;
  deleteAction?: (id: string) => void;
  isDeleteActionDisabled?: boolean;
  email?: string;
  id: string;
  imageUrl?: string | null;
  lastModifiedBy?: ModifiedBy;
  location?: Location;
  noImage?: boolean;
  modifiedDateTime?: string;
  onClick: (id: string) => void;
  phone?: string;
  priceMinMax?: PriceMinMax;
  role?: string;
  status: PublicationStatuses | UserStatuses;
  tags?: TagInterface[];
  title?: string;
  contentType?: ContentType;
  tagsMaxDepthFromTop?: number;
}

/**
 * BaseListItem is a generic list item component, providing a basic pattern for
 * list item display.
 *
 * Mandatory fields -  id, status, onClick - are required for all entities.
 *
 * Any children provided to instances of this component will be rendered between
 * dateTimeRange and tags display areas.
 *
 * Component only works with manual prop mapping as the varying object shapes
 * provided by the BE don't currently allow for generalised mapping logic
 * across the entities. Prop types should be helpful in matching fields.
 *
 * TODO: Map props directly from entity instead of manual prop mapping, once the relate field names get synced up across BE services.
 */
const BaseListItem = ({
  children,
  dateTimeRange,
  deleteAction,
  isDeleteActionDisabled,
  id,
  imageUrl,
  location,
  lastModifiedBy,
  noImage,
  modifiedDateTime,
  onClick,
  priceMinMax,
  status,
  tags,
  title,
  contentType,
  tagsMaxDepthFromTop,
}: BaseListItemProps) => {
  const { width } = useWindowDimensions();
  const isMobileDevice = isMobile(width);
  const [isOpened, setOpened] = useState(false);
  const toggleDetails = () => setOpened(!isOpened);

  const showContent = isMobileDevice ? isOpened : true;
  return (
    <ListItemContainer>
      <BaseInfo>
        {!noImage && (
          <Image
            src={imageUrl}
            onClick={() => onClick(id)}
            contentType={contentType}
          />
        )}
        <DescriptionArea noImage={noImage}>
          <EmojiTitle title={title} onClick={() => onClick(id)} />
          <ListItemLocation location={location} />
          <ListItemDateTime dateTimeRange={dateTimeRange} />
          {children}
          <Tags tags={tags} maxDepthFromTop={tagsMaxDepthFromTop} />
        </DescriptionArea>
      </BaseInfo>

      {showContent && (
        <Details>
          <PriceAndModified>
            <ListItemPriceDisplay priceMinMax={priceMinMax} />
            <LastModified
              modifiedBy={lastModifiedBy}
              modifiedDateTime={modifiedDateTime}
            />
            {isMobileDevice && isOpened && (
              <HideLink
                onClick={toggleDetails}
                text={strings("buttons.less")}
              />
            )}
          </PriceAndModified>
        </Details>
      )}

      <ListItemActions
        id={id}
        isMobile={isMobileDevice}
        onClick={onClick}
        status={status}
        deleteAction={deleteAction}
        isDeleteActionDisabled={isDeleteActionDisabled}
        isOpened={isOpened}
        toggleDetails={toggleDetails}
      />
    </ListItemContainer>
  );
};

export default BaseListItem;
