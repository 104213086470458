import { SidebarLink } from "components/generic/SidenavLayout";
import { routeNames } from "core/navigation";
import strings from "localisation/strings";
import { ReactComponent as PlanIcon } from "assets/icons/settingsWheel.svg";
import { ReactComponent as LocationIcon } from "assets/icons/zoo.svg";
import React from "react";
import {
  generatePath,
  matchPath,
  RouteComponentProps,
  withRouter,
} from "react-router";
import useLoginState from "hooks/useLoginState";
import {
  PaymentsSidebarLink,
  PlansSidebarLink,
} from "views/BusinessSettings/links";

const isAdditionalSettingsPathRoute = (path: string) => {
  const matchedPath = matchPath(path, [
    routeNames.BusinessSettings.AdditionalSettingsAdmin,
  ]);
  return !!matchedPath && matchedPath.isExact;
};

const isBusinessVenuesPathRoute = (path: string) => {
  const matchedPath = matchPath(path, [
    routeNames.BusinessSettings.BusinessVenues,
  ]);
  return !!matchedPath && matchedPath.isExact;
};

const BusinessSettingsAdminSidebar = ({
  match: {
    path,
    params: { businessId },
  },
}: RouteComponentProps<{ businessId: string }>) => {
  const planPath = generatePath(routeNames.BusinessSettings.PlanAdmin, {
    businessId,
  });
  const planChangePath = generatePath(
    routeNames.BusinessSettings.PlanChangeAdmin,
    {
      businessId,
    },
  );
  const planChangeConfirmationPath = generatePath(
    routeNames.BusinessSettings.PlanChangeConfirmationAdmin,
    {
      businessId,
    },
  );
  const paymentsPath = generatePath(routeNames.BusinessSettings.PaymentAdmin, {
    businessId,
  });
  const additionalSettingsPath = generatePath(
    routeNames.BusinessSettings.AdditionalSettingsAdmin,
    { businessId },
  );

  const businessVenuesPath = generatePath(
    routeNames.BusinessSettings.BusinessVenues,
    { businessId },
  );

  const isDefaultCompanyContext = useLoginState().isDefaultBusinessContext();

  return (
    <>
      {!isDefaultCompanyContext && (
        <>
          <PlansSidebarLink
            link={planPath}
            matchedRoutes={[
              planPath,
              planChangePath,
              planChangeConfirmationPath,
            ]}
          />
          <PaymentsSidebarLink link={paymentsPath} />
        </>
      )}
      <SidebarLink
        to={additionalSettingsPath}
        text={strings("businessSettings.additional-settings.title")}
        icon={PlanIcon}
        isActive={isAdditionalSettingsPathRoute(path)}
      />
      <SidebarLink
        to={businessVenuesPath}
        text={strings("businessSettings.businessVenuesScreen.sideNavLink")}
        icon={LocationIcon}
        isActive={isBusinessVenuesPathRoute(path)}
      />
    </>
  );
};

export default withRouter(BusinessSettingsAdminSidebar);
