import React, { useState, useEffect } from "react";
import { includes } from "ramda";
import onClickOutside from "react-onclickoutside";

import styled from "style/styled-components";
import strings from "localisation/strings";

import TagInterface from "api/models/TagInterface";
import QueryStatus from "common/api/models/QueryStatus";
import { SelectionStateInterface } from "components/lists/filtering/SelectionStateInterface";
import TagList from "components/forms/DropdownTagList";
import {
  TagSelectionFieldContainer,
  TagSelectionTitle,
  DropdownWindow,
  DropdownContainer,
  SpacedCloseableTagButton,
  TagButtonArea,
  AddTag,
  SearchInput,
} from "components/forms/TagSelection/TagSelectionStyles";
import {
  reduceTagFilterToSelected,
  reduceSelectedTags,
  getTagIdPaths,
} from "common/components/filtering/filterTransformUtils";
import {
  getValue,
  findSuggestions,
} from "components/helpers/formHelpers/tagSelection";
import FieldErrors from "components/forms/FieldErrors";
import { defaultDistrictLimit } from "constants/FormConstants";

const GeneralTagSelectionFieldContainer = styled(TagSelectionFieldContainer)`
  margin-top: ${({ theme }) => theme.margin.large};
`;

export interface CategorySelectProps {
  buttonText: string;
  tags?: TagInterface[];
  allTagResults: TagInterface[];
  generalTagResult: TagInterface[];
  generalTagStatus: QueryStatus;
  onTagChange?: (tags: TagInterface[], errorMessage: string) => void;
  hasTagFetchError?: boolean;
  hasTagFieldError?: boolean;
  toggleTag: (idPath: string[], errorMessage: string) => void;
  isTagSelected: (idPath: string[]) => boolean;
  generalTagErrorMessage?: string;
  setIsSavingBlocked?: (isSavingBlocked: boolean) => void;
}

const TagSelectionField = ({
  tags = [],
  allTagResults,
  generalTagResult,
  generalTagStatus,
  hasTagFetchError,
  hasTagFieldError,
  toggleTag,
  isTagSelected,
  generalTagErrorMessage,
  setIsSavingBlocked,
}: CategorySelectProps) => {
  const [open, setOpen] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(true);
  const toggleDropdown = () => setOpen(!open);
  (TagSelectionField as any).handleClickOutside = () => setOpen(false);

  const generalTagResults: TagInterface[] = allTagResults.filter(result =>
    includes(result, generalTagResult),
  );

  const selectionState: SelectionStateInterface = {
    selectedFilters: {},
    selectedTagFilters: reduceTagFilterToSelected(tags, generalTagResult),
  };

  const tagIdPaths = getTagIdPaths(selectionState.selectedTagFilters);

  const toggleTagForGeneralOnes = (idPath: string[]) => {
    toggleTag(idPath, strings("gneralTagSelector.districtTagErrorMessage"));
    setInputValue("");
  };

  const reducedTags = reduceSelectedTags(
    generalTagResults,
    selectionState.selectedTagFilters,
  );

  const displayableTags = reducedTags.map(({ id, name }) => (
    <SpacedCloseableTagButton
      key={id}
      onClick={toggleDropdown}
      onClose={() => toggleTagForGeneralOnes(tagIdPaths[id])}
    >
      {name}
    </SpacedCloseableTagButton>
  ));

  const districtLimit = process.env.REACT_APP_DISTRICT_LIMIT
    ? parseInt(process.env.REACT_APP_DISTRICT_LIMIT, 10)
    : defaultDistrictLimit;
  const tooManyDistricts =
    reducedTags.filter(
      tag => tag.parentId === process.env.REACT_APP_DISTRICT_PARENT_TAG_ID,
    ).length > districtLimit;

  const [inputValue, setInputValue] = useState<string>("");

  let value = getValue(inputValue);
  let length = value.trim().length;
  let suggestions = findSuggestions(generalTagResult, value, length);

  useEffect(() => {
    value = getValue(inputValue);
    length = value.trim().length;
    suggestions = findSuggestions(generalTagResult, value, length);
    if (generalTagStatus === QueryStatus.SUCCESSFUL) {
      setHasSuggestions(suggestions.length > 0);
    }
  }, [inputValue]);

  useEffect(() => {
    if (setIsSavingBlocked) setIsSavingBlocked(tooManyDistricts);
  }, [tooManyDistricts]);

  const isDropDownWindowReady = open && !hasTagFetchError && hasSuggestions;

  return (
    <>
      <GeneralTagSelectionFieldContainer>
        <TagSelectionTitle
          hasErrors={!!generalTagErrorMessage || tooManyDistricts}
        >
          {strings("businessUserProfileScreen.fields.generalTagsField.title")}
        </TagSelectionTitle>
        <DropdownContainer>
          <TagButtonArea>
            {displayableTags.length > 0 && displayableTags}
            {open ? (
              <SearchInput
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                autoFocus={true}
                placeholder={strings("autosizeInputPlaceholder")}
              />
            ) : (
              <AddTag
                onClick={toggleDropdown}
                hasErrors={!!generalTagErrorMessage || tooManyDistricts}
              >
                {strings("categoryForm.addCategory")}
              </AddTag>
            )}
          </TagButtonArea>
          {isDropDownWindowReady && (
            <DropdownWindow isOpened={open}>
              <TagList
                tagFilter={suggestions}
                isTagSelected={isTagSelected}
                toggleTag={toggleTagForGeneralOnes}
              />
            </DropdownWindow>
          )}
        </DropdownContainer>
      </GeneralTagSelectionFieldContainer>
      {generalTagErrorMessage && (
        <FieldErrors testId="gtags" errors={[generalTagErrorMessage]} />
      )}
      {tooManyDistricts && (
        <FieldErrors
          testId="gtags"
          errors={[
            strings("gneralTagSelector.tooManyDistricts", {
              maxNumDistrict: districtLimit,
            }),
          ]}
        />
      )}
    </>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => (TagSelectionField as any).handleClickOutside,
};

export default onClickOutside(TagSelectionField, clickOutsideConfig);
