import React, { useState, useCallback, useEffect } from "react";
import styled from "style/styled-components";
import { debounce } from "lodash";

import SearchBox, {
  SearchBoxType,
} from "components/lists/header/Search/SearchBox";
import SearchInput from "components/lists/header/Search/SearchInput";
import { ReactComponent as SearchIcon } from "common/assets/icons/search-custom.svg";
import strings from "localisation/strings";

const Icon = styled(SearchIcon)`
  flex-shrink: 0;
  opacity: 0.3;
`;

const FieldSearchInput = styled(SearchInput)`
  width: 30px;
  margin-left: ${({ theme }) => theme.margin.medium};
`;

interface Props {
  className?: string;
  onSearch: (q: string) => void;
  value?: string;
  maxLength?: number;
  type: SearchBoxType;
}

const SearchField = (props: Props) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const debouncedCall = useCallback(debounce(props.onSearch, 1500), []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setValue(input);
    debouncedCall(input);
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      props.onSearch(value);
    }
  };

  return (
    <SearchBox type={props.type} className={props.className}>
      <Icon width={20} height={20} />
      <FieldSearchInput
        placeholder={strings("tabBar.Search")}
        type="text"
        onChange={handleInput}
        onKeyDown={handleKey}
        value={value}
        maxLength={props.maxLength}
      />
    </SearchBox>
  );
};

export default SearchField;
