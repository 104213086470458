import DateTimeRange from "api/models/DateTimeRange";
import DateRange from "api/models/DateRange";

export interface PublicUserInterface {
  userId: string;
  fullName: string;
  email: string;
  dateOfBirth: string | null;
  address: string | null;
  status: string;
  permissionToAnalyzeData: boolean;
  modifiedDateTime: string | null;
}

export enum MarketingStatuses {
  ALL = "all",
  YES = "yes",
  NO = "no",
}

export interface PublicUserProfileFilters {
  dateFilter: DateRange;
  modifiedDateTimeFilter: DateTimeRange;

  termsMapFilter: {
    fullAddress: string;
    fullName: string;
    email: string;
  };

  booleanFilter?: {
    permissionToAnalyzeData?: boolean;
  };
}
