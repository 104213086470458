import React, { ReactNode } from "react";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";

export interface TextButtonBaseProps {
  onClick?: () => void;
  className?: string;
  children: ReactNode;
  title?: string;
  disabled?: boolean;
}

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  @media ${breakpoints.phoneMax} {
    flex-flow: column nowrap;
  }
`;

const TextButtonBase = ({ children, ...props }: TextButtonBaseProps) => (
  <Button {...props}>{children}</Button>
);

export default TextButtonBase;
