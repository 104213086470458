import RequestOptions from "api/RequestOptions";

import AuthedAPI from "api/AuthedAPI";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import ListParams from "api/models/ListParams";
import ListResultType from "api/models/ListResultType";
import { PublicUserInterface } from "api/models/PublicUserInterface";

const clientAPI = new AuthedAPI({});

const postPublicUsersAdminSearch = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  clientAPI.postListJson<ListResultType<PublicUserInterface>>({
    queryParams,
    options,
    body,
    path: "users/bo/public-users/search",
  });

const activatePublicUserById = (
  userId: string,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.postJson<Partial<PublicUserInterface>>({
    queryParams,
    path: `users/bo/public-users/${userId}/activate`,
  });

const deactivatePublicUserById = (
  userId: string,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.postJson<Partial<PublicUserInterface>>({
    queryParams,
    path: `users/bo/public-users/${userId}/deactivate`,
  });

const exportPublicUsersProfiles = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  clientAPI.download({
    queryParams,
    options,
    body,
    path: "users/bo/public-users/export",
  });

export {
  postPublicUsersAdminSearch,
  activatePublicUserById,
  deactivatePublicUserById,
  exportPublicUsersProfiles,
};
