import React from "react";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import LastModifiedDateTime from "components/generic/LastModifiedDateTime";
import LastModifiedBy from "components/generic/LastModifiedBy";
import ModifiedBy from "api/models/ModifiedBy";

const LastModifiedContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: flex-end;
  white-space: nowrap;
  @media ${breakpoints.phoneMax} {
    white-space: normal;
    display: inline-block;
  }
`;

export interface PostLastModifiedProps {
  modifiedBy?: ModifiedBy;
  modifiedDateTime?: string;
}

const LastModified = ({
  modifiedBy,
  modifiedDateTime,
}: PostLastModifiedProps) => {
  const hasName = modifiedBy && (modifiedBy.firstName || modifiedBy.lastName);

  return (
    <LastModifiedContainer>
      {hasName && <LastModifiedBy modifiedBy={modifiedBy} />}
      {modifiedDateTime && (
        <LastModifiedDateTime modifiedDateTime={modifiedDateTime} />
      )}
    </LastModifiedContainer>
  );
};

export default LastModified;
