import EntityTypes from "api/models/EntityTypes";

export enum TagStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export default interface TagInterface {
  id: string;
  shortName?: string;
  name: string;
  parentId?: string;
  weight: number;
  tagStatus?: TagStatus;
  tagSuccessors?: TagInterface[] | null;
  tagType?: EntityTypes;
}

export interface TagFilter {
  tagFilter: TagInterface[];
}
