import React from "react";
import styled from "style/styled-components";
import RegistrationForm from "components/forms/RegistrationForm";
import strings from "localisation/strings";
import {
  CenteredContentViewContainer,
  TitleCentered,
} from "components/generic";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";

const RegistrationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.forms.xxxlarge};
  ${inputContainerAutoZoomPrevetionStyles};
`;

const Registration = () => {
  return (
    <CenteredContentViewContainer>
      <RegistrationContainer>
        <TitleCentered>{strings("registrationScreen.title")}</TitleCentered>
        <RegistrationForm />
      </RegistrationContainer>
    </CenteredContentViewContainer>
  );
};

export default Registration;
