import React from "react";
import strings from "localisation/strings";
import { SimpleText } from "components/generic";
import CurrentPlanHorizontalCard, {
  Props as CurrentPlanHorizontalCardProps,
} from "views/BusinessSettings/PlanSelection/components/CurrentPlanHorizontalCard";
import TextButton from "components/buttons/TextButton";
import Plan from "api/models/Plan";

interface NullablePlanProps {
  plan: Plan | null;
}

const CurrentPlanHorizontalCardOrLink = ({
  plan,
  ...props
}: Omit<CurrentPlanHorizontalCardProps, "plan"> & NullablePlanProps) => {
  if (plan) {
    return <CurrentPlanHorizontalCard plan={plan} {...props} />;
  }

  return (
    <div>
      <SimpleText>{strings("businessSettings.plan.notSet")}</SimpleText>
      <TextButton
        onClick={props.onChangePlan}
        text={strings("businessSettings.plan.changePlan")}
      />
    </div>
  );
};

export default CurrentPlanHorizontalCardOrLink;
