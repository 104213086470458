import styled from "style/styled-components";
import { mobileHeaderWidthMax } from "style/breakpoints";

const HeaderOrFooterMedia = styled.div<{
  desktop?: boolean;
  mobile?: boolean;
}>`
  display: none;
  position: sticky;
  top: 0px;
  z-index: ${({ theme }) => theme.layers.modal};
  ${({ desktop }) =>
    desktop
      ? `
    @media (min-width: ${mobileHeaderWidthMax + 0.02}px) {
      display: block;
    }
  `
      : ""};
  ${({ mobile }) =>
    mobile
      ? `
    @media (max-width: ${mobileHeaderWidthMax}px) {
      display: block;
    }
  `
      : ""};
`;

export default HeaderOrFooterMedia;
