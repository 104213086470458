import React from "react";
import ListImage from "components/lists/item/ListImage";
import ListItemTitle from "components/lists/item/ListItemTitle";
import { SimpleText, TagList } from "components/generic";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import { InlineTextButton } from "components/buttons/TextButton";
import getEmojiTextComponents from "components/helpers/getEmojiTextComponents";

export interface ListItemProps {
  onClick: (id: string) => void;
}

export const ListItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  margin: ${({ theme }) => theme.margin.medium} 0;
  height: ${({ theme }) => theme.list.itemHeight.desktop};

  @media ${breakpoints.tabletMax} {
    height: auto;
  }

  @media ${breakpoints.phoneMax} {
    flex-direction: column;
  }
`;

export const Image = styled(ListImage)<ListItemProps>`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius.mediumSmall};

  @media ${breakpoints.tabletMax} {
    border-radius: ${({ theme }) => theme.border.radius.medium};
    width: 140px;
    height: ${({ theme }) => theme.list.itemHeight.tablet};
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
  flex: 1;
  margin-right: ${({ theme }) => theme.margin.large};
  height: 100%;

  @media ${breakpoints.desktopMin} {
    align-items: stretch;
  }

  @media ${breakpoints.tabletMax} {
    margin: 0 ${({ theme }) => theme.margin.medium};
    height: ${({ theme }) => theme.list.itemHeight.tablet};
  }

  @media ${breakpoints.phoneMax} {
    flex-direction: column;
    margin: 0;
    height: ${({ theme }) => theme.list.itemHeight.phone};
    flex: 1 1 auto;
  }
`;

export const DescriptionArea = styled.div<{ noImage?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0;
  flex: 1;
  width: 100%;
  align-self: flex-start;
  margin-left: ${({ theme, noImage }) => (noImage ? 0 : theme.margin.large)};
`;

const TitleStyle = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.xlarge};
  color: ${({ theme }) => theme.color.font.onLightBackground};

  @media ${breakpoints.tabletMax} {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: 18px;
  }
`;

interface TitleProps {
  title?: string;
  onClick?: () => void;
}

export const Title = ({ title, onClick }: TitleProps) => (
  <ListItemTitle onClick={onClick}>
    <TitleStyle>{title}</TitleStyle>
  </ListItemTitle>
);

export const EmojiTitle = ({ title, onClick }: TitleProps) => {
  if (!title) {
    return <Title title={title} onClick={onClick} />;
  }

  return (
    <ListItemTitle onClick={onClick}>
      <TitleStyle>{getEmojiTextComponents(title, 15)}</TitleStyle>
    </ListItemTitle>
  );
};

export const Text = styled(SimpleText)`
  margin-left: ${({ theme }) => theme.margin.medium};
  text-transform: uppercase;
`;

export const Tags = styled(TagList)`
  margin-top: ${({ theme }) => theme.margin.small};
`;

export const ShowLink = styled(InlineTextButton)`
  align-items: flex-start;
  position: relative;
  text-align: left;

  @media ${breakpoints.phoneMax} {
    height: 45px;
    line-height: 45px;
    margin-right: calc(100% - 400px);
  }

  // Specific styles for iPhone 5/SE and similar device width
  @media (max-width: 425px) {
    margin-right: 0;
  }
`;

export const HideLink = styled(ShowLink)`
  position: absolute;
  bottom: 0;
  padding-bottom: ${({ theme }) => theme.margin.medium};
`;

export const PriceAndModified = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  text-align: right;
  height: 100%;

  @media ${breakpoints.phoneMax} {
    text-align: left;
    padding-top: ${({ theme }) => theme.margin.medium};
  }
`;
