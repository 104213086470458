import React from "react";
import useFormFields from "hooks/useFormFields";
import strings from "localisation/strings";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import planIcon from "assets/icons/plans.svg";
import SelectComponent from "components/forms/Select";
import Plan from "api/models/Plan";
import ActionArea from "components/generic/ActionArea";
import ActionButton from "components/buttons/ActionButton";
import styled from "styled-components";
import breakpoints from "style/breakpoints";

const FormContainer = styled.div`
  width: ${({ theme }) => theme.forms.xlarge};
  max-width: ${({ theme }) => theme.forms.xlarge};

  @media ${breakpoints.phoneMax} {
    width: 100%;
  }
`;

interface PlanSelectionProps {
  plans: Plan[];
  selectedPlanId?: string;
  onSave: (planId: string) => void;
}

const PlanSelectionForm = ({
  plans,
  selectedPlanId,
  onSave,
}: PlanSelectionProps) => {
  const { getSelectFieldProps, values } = useFormFields({
    translationScope: "businessSettings.plan.fields",
    selectFields: {
      plan: selectedPlanId || "",
    },
  });

  const planOptions = plans.map(({ planId, name, price }) => ({
    label: `${name} (${price})`,
    value: planId,
  }));

  return (
    <FormContainer>
      <InlineInputGroup
        icon={planIcon}
        placeholder={strings("businessSettings.plan.fields.plan.label")}
      >
        <SelectComponent
          {...getSelectFieldProps("plan", planOptions)}
          classNamePrefix="plan"
        />
      </InlineInputGroup>

      <ActionArea>
        <ActionButton
          text={strings("buttons.save")}
          special="publish"
          onClick={() => onSave(values.plan)}
          loadingText={strings("buttons.states.savingInProgress")}
        />
      </ActionArea>
    </FormContainer>
  );
};

export default PlanSelectionForm;
