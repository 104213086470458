import { useEffect, useState } from "react";
import useMutation from "common/hooks/useMutation";
import { getRepresentativeRoles } from "api/businessRepresentatives";
import {
  getAdminRoles,
  getRepresentativeRolesAdmin,
} from "api/backOfficeBusinessRepresentatives";
import { addRolesLabels } from "components/forms/BusinessRepresentative/helpers";
import useLoginState from "hooks/useLoginState";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { BusinessRepresentativeRole } from "api/models/BusinessRepresentativeRoles";

const useBusinessRepresentativeRoles = () => {
  const [roles, setRoles] = useState<
    BusinessRepresentativeRole[] | undefined
  >();
  const [error, setError] = useState<APIErrorResponse | undefined>();

  const loginState = useLoginState();
  const fetchAdminRoles = useMutation(getAdminRoles);
  const fetchRepresentativeRolesAdmin = useMutation(
    getRepresentativeRolesAdmin,
  );
  const fetchRepresentativeRoles = useMutation(getRepresentativeRoles);

  useEffect(() => {
    const isDefaultCompany = loginState.isDefaultBusinessContext();
    const hasAdminRights = loginState.hasAdminRights();

    const fetchRoles = isDefaultCompany
      ? fetchAdminRoles
      : hasAdminRights
      ? fetchRepresentativeRolesAdmin
      : fetchRepresentativeRoles;

    const getRoles = async () => {
      const response = (await fetchRoles.makeRequest({})) || {};
      if (response.error) setError(response.error);
      if (response.result) {
        const labeledRoles = addRolesLabels(response.result);
        return setRoles(labeledRoles);
      }
    };
    if (!roles) getRoles();
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { roles, error };
};

export default useBusinessRepresentativeRoles;
