import theme from "style/theme";
import {
  getPasswordRulesStatus,
  validatePassword,
} from "common/helpers/validators";

export enum PasswordStrengths {
  NONE = "NONE",
  INVALID = "INVALID",
  WEAK = "WEAK",
  MEDIUM = "MEDIUM",
  STRONG = "STRONG",
}

export const strengthColor = {
  [PasswordStrengths.NONE]: theme.color.foreground.noticeInactive,
  [PasswordStrengths.INVALID]: theme.color.foreground.noticeInactive,
  [PasswordStrengths.WEAK]: theme.color.foreground.action,
  [PasswordStrengths.MEDIUM]: theme.color.foreground.noticeMedium,
  [PasswordStrengths.STRONG]: theme.color.foreground.noticeStrong,
};

const getPasswordStrength = (password: string): PasswordStrengths => {
  if (!password) {
    return PasswordStrengths.NONE;
  }

  if (!validatePassword(password)) {
    return PasswordStrengths.INVALID;
  }
  const { uppercase, digit, special, lowercase } = getPasswordRulesStatus(
    password,
  );

  if (
    password.length === 8 &&
    (password.match(/[A-Z\p{Lu}]/gu) || []).length === 1 &&
    !digit &&
    !special
  ) {
    return PasswordStrengths.WEAK;
  }
  if (password.length >= 8 && uppercase && lowercase && digit && special) {
    return PasswordStrengths.STRONG;
  }
  return PasswordStrengths.MEDIUM;
};

export default getPasswordStrength;
