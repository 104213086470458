import React, { useCallback, useEffect, useState } from "react";
import { curry } from "ramda";
import { useHistory, useLocation } from "react-router";
import { debounce } from "lodash";

import strings from "localisation/strings";
import {
  getLastPathSegment,
  getTotalPagesFromHeaders,
  getTotalItemsCount,
  mapTagsToIdArray,
  dateReviver,
} from "utils";

import SearchFilters from "api/models/Filters";
import {
  activateRepresentativeById,
  deactivateRepresentativeById,
  postRepresentativesAdminSearch,
  updateRepresentativeRole,
} from "api/businessRepresentatives";
import {
  getEventsAdmin,
  getEventsCurator,
  updateEventStatusInList,
} from "api/events";
import { getNews, updateNews } from "api/news";
import {
  getAdminMaps,
  getTags,
  updateMapStatus,
  updateTagsStatus,
} from "api/resources";
import { FileResultType } from "api/APIHelpers";
import {
  getBusinessProfilesAdmin,
  updateBusinessProfileStatusInList,
  exportBusinessProfilesAdmin,
  getBusinessProfilesCurator,
} from "api/businessProfiles";
import ListResultType from "api/models/ListResultType";
import {
  ExtendedRepresentativeInterface,
  UserStatuses,
} from "api/models/RepresentativeInterface";
import { SortRule } from "api/models/SortingInterface";
import EventInterface from "api/models/EventInterface";
import PublicationStatuses from "api/models/PublicationStatuses";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";

import ActionButtonWithPadding from "components/buttons/ActionButtonWithPadding";
import ExportButton from "components/buttons/ExportButton";
import APIRequestMaker, {
  RequestMakerStatus,
} from "components/APIRequestMaker";
import {
  FilterableListLocationState,
  SearchRequest,
} from "components/lists/ListRequestTypes";
import DataTable from "components/table/DataTable";
import {
  HorizontalScroll,
  SpaceBetween,
  Title,
  InlineContainer,
} from "components/generic";
import TableViewWrapper from "components/table/TableViewWrapper";
import usePagination from "common/hooks/usePagination";
import Pagination from "components/pagination/Pagination";
import {
  businessColumns,
  businessRepresentativesColumns,
  publicUsersColumns,
  categoriesColumns,
  eventsColumns,
  newsColumns,
  offersColumns,
  postsColumns,
  curatorPostsColumns,
  curatorOffersColumns,
  curatorEventsColumns,
  curatorBusinessColumns,
  mapsColumns,
} from "components/table/DataTable/constants";
import {
  buildFilterQuery,
  getFiltersUpdate,
  getRepresentativeRolesOptions,
  mapSearchFiltersToFilters,
} from "components/table/DataTable/helpers";
import { ColumnModel, Filter } from "components/table/DataTable/interfaces";
import { SelectItem } from "components/forms/Select";
import OfferInterface from "api/models/OfferInterface";
import {
  getAdminOffers,
  getCuratorOffers,
  updateOfferStatusInList,
} from "api/offers";
import {
  getAdminPosts,
  getCuratorPosts,
  updatePostStatusInList,
} from "api/posts";
import PostInterface from "api/models/PostInterface";
import SearchRequestFilters from "api/models/SearchRequestFilters";
import NewsInterface from "api/models/NewsInterface";
import { PublicUserInterface } from "api/models/PublicUserInterface";
import {
  postPublicUsersAdminSearch,
  activatePublicUserById,
  deactivatePublicUserById,
  exportPublicUsersProfiles,
} from "api/publicUsers";
import emojiDataState from "state/singletons/emojiDataState";
import useQuery from "common/hooks/useQuery";
import { getEmojiData } from "api/imageStorage";
import QueryStatus from "common/api/models/QueryStatus";
import { NEW } from "constants/strings";
import notificationState from "state/singletons/notificationState";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { useEffectAfterMount } from "hooks/useEffectAfterMount";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import MapInterface from "api/models/MapInterface";

interface ViewDetailsType {
  [x: string]: {
    columns: ColumnModel[];
    listRequest: any;
    editRequest?: any;
    title: string;
    activateRequest?: any;
    deactivateRequest?: any;
    exportRequest?: any;
  };
}

const FILTER_DEBOUNCE_TIME = 200;

const postSearchRepresentativeRequest: SearchRequest<ListResultType<
  ExtendedRepresentativeInterface
>> = ({ offset, limit, body, q }) =>
  curry(postRepresentativesAdminSearch)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const postSearchPublicUsersRequest: SearchRequest<ListResultType<
  PublicUserInterface
>> = ({ offset, limit, body, q }) =>
  curry(postPublicUsersAdminSearch)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const adminEventsSearchRequest: SearchRequest<ListResultType<
  EventInterface
>> = ({ offset, limit, body, q }) =>
  curry(getEventsAdmin)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const curatorEventsSearchRequest: SearchRequest<ListResultType<
  EventInterface
>> = ({ offset, limit, body, q }) =>
  curry(getEventsCurator)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const adminNewsSearchRequest: SearchRequest<ListResultType<NewsInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getNews)({ q, filterReq: body, from: offset, size: limit }, undefined);

const adminBUPSearchRequest: SearchRequest<ListResultType<
  BusinessUserProfileInterface
>> = ({ offset, limit, body, q }) =>
  curry(getBusinessProfilesAdmin)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const curatorBUPSearchRequest: SearchRequest<ListResultType<
  BusinessUserProfileInterface
>> = ({ offset, limit, body, q }) =>
  curry(getBusinessProfilesCurator)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const adminOffersSearchRequest: SearchRequest<ListResultType<
  OfferInterface
>> = ({ offset, limit, body, q }) =>
  curry(getAdminOffers)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const curatorOffersSearchRequest: SearchRequest<ListResultType<
  OfferInterface
>> = ({ offset, limit, body, q }) =>
  curry(getCuratorOffers)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const adminBUPExportRequest: SearchRequest<FileResultType> = ({ body, q }) =>
  curry(exportBusinessProfilesAdmin)(
    { q, filterReq: body, from: 0, size: 10 },
    undefined,
  );

const adminPublicUsersExportRequest: SearchRequest<FileResultType> = ({
  body,
  q,
}) =>
  curry(exportPublicUsersProfiles)(
    { q, filterReq: body, from: 0, size: 10 },
    undefined,
  );

const postListRequest: SearchRequest<ListResultType<PostInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getAdminPosts)({ q, filterReq: body, from: offset, size: limit }, {});

const postCuratorListRequest: SearchRequest<ListResultType<PostInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getCuratorPosts)({ q, filterReq: body, from: offset, size: limit }, {});

const adminMapsSearchRequest: SearchRequest<ListResultType<MapInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getAdminMaps)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
  );

const viewDetails: ViewDetailsType = {
  veranstaltungen: {
    columns: eventsColumns,
    listRequest: adminEventsSearchRequest,
    editRequest: curry(updateEventStatusInList),
    title: "Events",
  },
  curatorveranstaltungen: {
    columns: curatorEventsColumns,
    listRequest: curatorEventsSearchRequest,
    editRequest: curry(updateEventStatusInList),
    title: "Events",
  },
  news: {
    columns: newsColumns,
    listRequest: adminNewsSearchRequest,
    editRequest: curry(updateNews),
    title: "News",
  },
  kategorien: {
    columns: categoriesColumns,
    listRequest: curry(getTags),
    editRequest: curry(updateTagsStatus),
    title: "Categories",
  },
  angebote: {
    columns: offersColumns,
    listRequest: adminOffersSearchRequest,
    editRequest: curry(updateOfferStatusInList),
    title: "Offers",
  },
  curatorangebote: {
    columns: curatorOffersColumns,
    listRequest: curatorOffersSearchRequest,
    editRequest: curry(updateOfferStatusInList),
    title: "Offers",
  },
  beitraege: {
    columns: postsColumns,
    listRequest: postListRequest,
    editRequest: curry(updatePostStatusInList),
    title: "Posts",
  },
  curatorbeitraege: {
    columns: curatorPostsColumns,
    listRequest: postCuratorListRequest,
    editRequest: curry(updatePostStatusInList),
    title: "Posts",
  },
  partnervertreter: {
    columns: businessRepresentativesColumns,
    listRequest: postSearchRepresentativeRequest,
    editRequest: curry(updateRepresentativeRole),
    title: "BusinessRepresentatives",
    activateRequest: curry(activateRepresentativeById),
    deactivateRequest: curry(deactivateRepresentativeById),
  },
  endnutzer: {
    columns: publicUsersColumns,
    listRequest: postSearchPublicUsersRequest,
    title: "PublicUsers",
    activateRequest: curry(activatePublicUserById),
    deactivateRequest: curry(deactivatePublicUserById),
    exportRequest: curry(adminPublicUsersExportRequest),
  },
  partnerprofil: {
    columns: businessColumns,
    listRequest: adminBUPSearchRequest,
    editRequest: curry(updateBusinessProfileStatusInList),
    title: "Businesses",
    exportRequest: curry(adminBUPExportRequest),
  },
  curatorpartnerprofil: {
    columns: curatorBusinessColumns,
    listRequest: curatorBUPSearchRequest,
    editRequest: curry(updateBusinessProfileStatusInList),
    title: "Businesses",
    exportRequest: curry(adminBUPExportRequest),
  },
  karten: {
    columns: mapsColumns,
    listRequest: adminMapsSearchRequest,
    editRequest: curry(updateMapStatus),
    title: "Maps",
  },
};

interface Props {
  representativeRoles?: SelectItem[];
  onClickEdit?: (id: string) => void;
  onClickCopy?: (id: string) => void;
  onClickAdd?: (id: string) => void;
}

const PARAM_SEARCH_FILTER = "sfilter";
const PARAM_FILTER = "filter";
const PARAM_PAGE = "page";
const PARAM_SORT = "sort";
const PARAM_ROW_LIMIT = "limit";
const DEFAULT_ROW_LIMIT = 10;

const parseJsonSearchParam = (
  urlSearchParams: URLSearchParams,
  key: string,
) => {
  const searchParam = urlSearchParams.get(key);

  if (!searchParam) {
    return undefined;
  }

  try {
    return JSON.parse(searchParam, dateReviver);
  } catch {
    return undefined;
  }
};

const ListTableView = <T,>(props: Props) => {
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;
  const listType: string = getLastPathSegment(pathname);
  const isCategoriesView = listType === "kategorien";
  const isUsingSearchRequest = !isCategoriesView;
  const isCurator = getUserHasPrivilege(
    PrivilegedComponentsIds.VIEW_CONTENT_CURATOR,
  );
  const { columns, title, exportRequest } = viewDetails[
    isCurator ? "curator" + listType : listType
  ];
  const isRepresentativesView =
    listType === "partnervertreter" || listType === "endnutzer";
  const isBusinessesView = listType === "partnerprofil";
  const isExportable: boolean =
    exportRequest && listType !== "partnervertreter";

  const urlSearchParams = new URLSearchParams(location.search);
  const urlPage: number = Number(urlSearchParams.get(PARAM_PAGE)) || 1;
  const urlLimit: number =
    Number(urlSearchParams.get(PARAM_ROW_LIMIT)) || DEFAULT_ROW_LIMIT;

  const {
    offset,
    limit,
    page,
    updatePage,
    resetPagination,
    onPageSizeChange,
  } = usePagination({
    defaultLimit: urlLimit,
    defaultPage: urlPage,
    defaultOffset: (urlPage - 1) * urlLimit,
  });

  const [filters, setFilters] = useState<Filter[] | undefined>(
    parseJsonSearchParam(urlSearchParams, PARAM_FILTER),
  );
  const [sort, setSort] = useState<string>();
  const [items, setItems] = useState<any[]>();
  const [update, setUpdate] = useState<{ id: string; body: any }>();
  const [refreshTable, setRefreshTable] = useState<number>(0);

  const [searchFilters, setSearchFilters] = useState<
    SearchRequestFilters | undefined
  >(parseJsonSearchParam(urlSearchParams, PARAM_SEARCH_FILTER));

  const [searchSort, setSearchSort] = useState<SortRule | undefined>(
    parseJsonSearchParam(urlSearchParams, PARAM_SORT),
  );

  const mapSearchSortToSort = (): string => {
    return searchSort ? `${searchSort.field},${searchSort.order}` : "";
  };

  const [allSortingRules, setAllSortingRules] = useState<SortRule[]>([]);
  const [allFilters, setAllFilters] = useState<SearchFilters>();
  const [appliedSort, setAppliedSort] = useState<string>(mapSearchSortToSort());
  const [appliedFilters, setAppliedFilters] = useState<Filter[]>(
    mapSearchFiltersToFilters(
      columns,
      searchFilters,
      props.representativeRoles,
    ),
  );
  if (isRepresentativesView) {
    // Workaround to populate the roles with data from API
    const roleColumn = viewDetails[
      isCurator ? "curator" + listType : listType
    ].columns.find(c => c.id === "role");
    if (roleColumn && roleColumn.filter) {
      const rolesOptions = getRepresentativeRolesOptions(
        props.representativeRoles,
      );
      roleColumn.filter.selectList = rolesOptions;
      roleColumn.selectOptions = rolesOptions;
    }
  }

  // On mount, shouldn't react to filter change
  useEffectAfterMount(() => {
    setAppliedFilters(
      mapSearchFiltersToFilters(
        columns,
        searchFilters,
        props.representativeRoles,
      ),
    );

    resetPagination();

    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters]);

  // On mount, shouldn't react to sort change
  useEffectAfterMount(() => {
    setAppliedSort(mapSearchSortToSort());
    resetPagination();

    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSort]);

  useEffectAfterMount(() => {
    updateSearchParams(searchFilters, filters, searchSort, page, limit);
  }, [searchFilters, filters, searchSort, page, limit]);

  /* Avoid mutiple effects in a row triggering query update
   * (e.g. set filters and page to 0 triggers two renders)
   */
  const updateSearchParams = useCallback(
    debounce(
      (
        debounceSearchFilters?: SearchRequestFilters,
        debounceFilters?: Filter[],
        debounceSort?: SortRule,
        debouncePage?: number,
        debounceLimit?: number,
      ) => {
        const searchParams = new URLSearchParams();

        if (debouncePage && debouncePage > 1) {
          searchParams.append(PARAM_PAGE, String(debouncePage));
        }

        if (debounceLimit && debounceLimit !== DEFAULT_ROW_LIMIT) {
          searchParams.append(PARAM_ROW_LIMIT, String(debounceLimit));
        }

        if (debounceSort) {
          searchParams.append(PARAM_SORT, JSON.stringify(debounceSort));
        }

        if (debounceSearchFilters) {
          searchParams.append(
            PARAM_SEARCH_FILTER,
            JSON.stringify(debounceSearchFilters),
          );
        }

        if (debounceFilters) {
          searchParams.append(PARAM_FILTER, JSON.stringify(debounceFilters));
        }

        history.replace({
          search: searchParams.toString(),
        });
      },
      50,
    ),
    [],
  );

  const updateSearchFilters = debounce((updatedFilters?: Filter[]) => {
    if (!updatedFilters) return;
    const newFilters = getFiltersUpdate(updatedFilters, searchFilters);
    setSearchFilters(newFilters);
  }, FILTER_DEBOUNCE_TIME);

  const updateSearchSort = debounce((newSort?: any) => {
    if (!newSort) return;

    const [field, order] = newSort.split(",");

    const newAppliedSort = allSortingRules.find(
      (s: any) => s.field === field && s.order === order,
    );
    setSearchSort(newAppliedSort);
  }, FILTER_DEBOUNCE_TIME);

  // Filtering for News admin table API
  const updateAdminNewsFilters = debounce((newFilters?: Filter[]) => {
    if (filters !== newFilters) {
      setFilters(newFilters);
      resetPagination();
    }
  }, FILTER_DEBOUNCE_TIME);

  const getChange = (body: any, id: string) => {
    setUpdate({
      id,
      body,
    });
  };

  const setDisplayData = (res: { data: any[] }) => setItems(res.data);

  const voidChange = () => setUpdate(undefined);

  const getActionByStatus = (status: string) => {
    if (status === PublicationStatuses.draft) {
      return "draft";
    }
    return status === PublicationStatuses.published ? "publish" : "unpublish";
  };

  const getItemById = (id: string) => {
    if (!items) return {};
    const itemId = isRepresentativesView ? "userId" : "id";
    return { ...items.find(item => item[itemId] === id) };
  };

  const determineCalls = () => {
    const {
      editRequest,
      listRequest,
      activateRequest,
      deactivateRequest,
    } = viewDetails[isCurator ? "curator" + listType : listType];
    // workaround for NEWS PUT method usage
    let newBody = update && update.body;
    let oldBody: any;
    if (update && items && !isCategoriesView) {
      oldBody = getItemById(update.id);
      if (listType === "partnerprofil") {
        newBody = getActionByStatus(update.body.profileState);
      } else if (
        listType === "veranstaltungen" ||
        listType === "beitraege" ||
        listType === "angebote"
      ) {
        newBody = getActionByStatus(update.body.status);
      } else if (isRepresentativesView) {
        // handling representative Role update
        const roleUpdate = update && update.body && update.body.role;
        newBody = roleUpdate
          ? { userId: update.id, role: roleUpdate }
          : newBody;
      } else if (listType === "karten") {
        newBody = update.body;
      } else {
        oldBody.tagIds = mapTagsToIdArray(oldBody.tags);
        newBody = { ...oldBody, ...update.body };
      }
    }

    const filtersForQuery = filters
      ? buildFilterQuery(filters, listType)
      : undefined;

    if (searchSort) {
      searchFilterBody = {
        ...searchFilterBody,
        sort: { sortRules: [searchSort] },
      };
    }

    const getStatusUpdateRequest = () => {
      if (!isRepresentativesView || !oldBody) return undefined;
      const statusUpdate = update && update.body && update.body.status;
      if (
        statusUpdate === UserStatuses.ACTIVE &&
        (oldBody.status === UserStatuses.DEACTIVATED ||
          oldBody.status === UserStatuses.INACTIVE)
      ) {
        return activateRequest;
      }
      if (
        (statusUpdate === UserStatuses.DEACTIVATED ||
          statusUpdate === UserStatuses.INACTIVE) &&
        (oldBody.status === UserStatuses.ACTIVE ||
          oldBody.status === UserStatuses.INACTIVE)
      ) {
        return deactivateRequest;
      }
      return undefined;
    };

    const statusUpdateRequest = getStatusUpdateRequest();
    const getUpdateRequest = (id: string) =>
      statusUpdateRequest
        ? statusUpdateRequest(id) // updating status of a Representative using activate/deactivate endpoint
        : editRequest(id, newBody); // updating an entity by posting the whole new body

    const getSearchOrListRequest = () => {
      return isUsingSearchRequest
        ? listRequest({
            offset,
            limit,
            body: searchFilterBody,
            q: getTitleSearchQuery(searchFilters),
          })
        : listRequest({
            offset,
            sort,
            limit,
            filter: filtersForQuery, // Filtering News (the old BO approach)
          });
    };

    const apiRequest =
      update && update.id
        ? getUpdateRequest(update.id)
        : getSearchOrListRequest();

    const successCallback = update ? voidChange : setDisplayData;

    const failCallback = (error: APIErrorResponse) => {
      if (error.status === 403) {
        notificationState.addErrorNotification(
          `${strings("errors.unknown")}. ${strings(
            "errors.forbidden.adminMessage",
          )}`,
        );
      } else {
        notificationState.addErrorNotification(
          (error && error.detail) || strings("errors.unknown"),
        );
      }
    };

    return {
      failureCallback: failCallback,
      request: apiRequest,
      callback: successCallback,
    };
  };

  // prepare filter values
  const getSearchFilterBody = (allPossibleFilters: any = {}) => {
    const filterBody: any = {};
    Object.keys(allPossibleFilters).forEach((filter: string) => {
      // For multi-select we need to convert the selected options values to filter values
      if (
        Array.isArray(allPossibleFilters[filter]) &&
        allPossibleFilters[filter].length
      ) {
        filterBody[filter] = [];
        allPossibleFilters[filter].forEach((filterValue: any) => {
          if (filterValue.value) {
            filterBody[filter].push({ id: filterValue.value, name: "" });
          } else if (filter === "tagFilter") {
            filterBody[filter].push({ id: filterValue });
          }
        });

        // Set some values as booleanFilter value along with BE implementation
        if (filter === "typeFilter") {
          const hasFilterValue = (id: string): boolean => {
            return allPossibleFilters[filter].some(
              (filterValue: any) => filterValue.data.id === id,
            );
          };

          filterBody.booleanFilter = {
            publishAlert: hasFilterValue("publishAlert"),
            publishPushNotification: hasFilterValue("publishPushNotification"),
            alertToBusiness: hasFilterValue("alertToBusiness"),
            topicOfTheWeek: hasFilterValue("topicOfTheWeek"),
            smartService: hasFilterValue("smartService"),
            jobOffer: hasFilterValue("jobOffer"),
          };
        }
      } else {
        // skip if "title" because this value used in search query and not in filter parameters
        // TODO: remove condition when services beside BUP use title as a nested field
        if (filter !== "title") {
          // if not multi-select use as is
          filterBody[filter] =
            allPossibleFilters[filter] && allPossibleFilters[filter];
        }
      }
    });
    return filterBody;
  };

  const getTitleSearchQuery = (formFilters: typeof searchFilters) => {
    return formFilters && formFilters.title ? formFilters.title : "";
  };

  // combined filters and sorting settings
  let searchFilterBody: Partial<FilterableListLocationState> = {
    filters: searchFilters && getSearchFilterBody(searchFilters),
  };

  const getExportRequest = () => {
    return exportRequest({
      body: searchFilterBody,
      q: getTitleSearchQuery(searchFilters),
    });
  };

  const { request, callback, failureCallback } = determineCalls();

  const {
    result: emojiDataFetchResult,
    status: emojiDataFetchStatus,
  } = useQuery({ request: getEmojiData });

  useEffect(() => {
    if (emojiDataFetchStatus === QueryStatus.SUCCESSFUL) {
      emojiDataState.setEmojiData(emojiDataFetchResult);
    }
  }, [emojiDataFetchStatus]);

  const handleRefresh = () => {
    setRefreshTable(refreshTable + 1);
  };

  return (
    <APIRequestMaker<T>
      key={refreshTable}
      request={request}
      compare={{
        update,
        path: location.pathname,
        search: location.search,
      }}
      successCallback={callback}
      failureCallback={failureCallback}
    >
      {({ result, headers, status }: any) => {
        const isLoading = status === RequestMakerStatus.WAITING;
        if (isLoading && !items) {
          return null;
        }

        if (result && result.filters) {
          // all default filters and sorting rules for search request
          setAllSortingRules(result.sort.sortRules);
          setAllFilters(result.filters);
        }

        const totalNumberOfPages = getTotalPagesFromHeaders(headers, limit);
        const totalItems = getTotalItemsCount(headers);

        const actionButtonText = isCategoriesView
          ? strings("tableView.addMainCategory")
          : strings(
              isBusinessesView
                ? "tableView.addNewBusiness"
                : "tableView.addNew",
            );

        return (
          <TableViewWrapper>
            <SpaceBetween>
              <Title>{strings(`tabBar.${title}`)}</Title>
              <InlineContainer>
                {!isRepresentativesView && (
                  <ActionButtonWithPadding
                    text={actionButtonText}
                    onClick={() => history.push(`${pathname}/${NEW}`)}
                    special="narrow"
                  />
                )}
                {isExportable && (
                  <ExportButton
                    request={getExportRequest()}
                    getPayload={() => getSearchFilterBody(searchFilters)}
                  />
                )}
              </InlineContainer>
            </SpaceBetween>

            <HorizontalScroll minHeight={600}>
              {items && (!isUsingSearchRequest || allFilters) && (
                <DataTable
                  appliedFilters={
                    isUsingSearchRequest ? appliedFilters : filters
                  }
                  sort={isUsingSearchRequest ? appliedSort : sort}
                  setSort={isUsingSearchRequest ? updateSearchSort : setSort}
                  setFilters={
                    isUsingSearchRequest
                      ? updateSearchFilters
                      : updateAdminNewsFilters
                  }
                  columns={columns}
                  allFilters={allFilters}
                  data={items}
                  getChange={getChange}
                  onClickEdit={props.onClickEdit}
                  onClickCopy={props.onClickCopy}
                  onClickAdd={props.onClickAdd}
                  isLoading={isLoading}
                  isCategoriesView={isCategoriesView}
                  isRepresentativesView={isRepresentativesView}
                  refreshTable={handleRefresh}
                />
              )}

              <Pagination
                onPageChange={updatePage}
                onPageSizeChange={onPageSizeChange}
                page={page}
                limit={limit}
                totalNumberOfPages={totalNumberOfPages}
                totalItems={totalItems}
              />
            </HorizontalScroll>
          </TableViewWrapper>
        );
      }}
    </APIRequestMaker>
  );
};

export default ListTableView;
