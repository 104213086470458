import React from "react";
import { RouteComponentProps } from "react-router";
import { curry } from "ramda";

import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import { validateAdminAccountToken } from "api/session";
import { Null } from "components/generic";
import RepresentativeLinkExpiredView from "views/RepresentativeUserActivation/RepresentativeLinkExpiredView";
import ChangePassword, { ChangePasswordViewParams } from "views/ChangePassword";

const ViewByResponseStatusMap = {
  [QueryStatus.ERROR]: RepresentativeLinkExpiredView,
  [QueryStatus.SUCCESSFUL]: ChangePassword,
  [QueryStatus.UNDETERMINED]: Null,
  [QueryStatus.WAITING]: Null,
};

const AdminChangePassword = (
  props: RouteComponentProps<ChangePasswordViewParams>,
) => {
  const { status } = useQuery<void>({
    request: curry(validateAdminAccountToken)(props.match.params.token),
    compare: [props.match.params.token],
  });

  return React.createElement(ViewByResponseStatusMap[status], {
    ...props,
    isAdminForm: true,
  });
};

export default AdminChangePassword;
