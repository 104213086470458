import React, { useEffect, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

const ScrollToTopOnPathChange = ({
  children,
  location,
}: { children: ReactNode } & RouteComponentProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

export default withRouter(ScrollToTopOnPathChange);
