import React from "react";
import { getMarketingPurposes } from "api/marketingPurposes";
import useQuery from "common/hooks/useQuery";
import MarketingPurposesForm from "components/forms/MarketingPurposesForm/MarketingPurposesForm";

const MarketingPurposesForLoggedInUser = () => {
  const { result: currentOptIn } = useQuery({
    request: () => getMarketingPurposes(),
  });
  if (!currentOptIn) return null;

  return <MarketingPurposesForm currentOptIn={currentOptIn} />;
};

export default MarketingPurposesForLoggedInUser;
