import moment from "moment";
import { prop } from "ramda";

import TagInterface from "api/models/TagInterface";
import NewsInterface from "api/models/NewsInterface";
import PublicationStatuses from "api/models/PublicationStatuses";
import NewsPayloadInterface from "api/models/NewsPayloadInterface";
import GeoLocations from "api/models/GeoLocations";
import PictureTypes from "api/models/PictureTypes";
import Picture from "api/models/Picture";

const getPublishDateTime = (publishDateTime?: Date | null) =>
  publishDateTime ? moment(publishDateTime).toISOString() : null;

interface Values {
  [index: string]: any;
}

export const defaultNewsFields = {
  textAreaFields: {
    title: "",
    description: "",
    inFrameUrl: "",
  },
  tagsFields: {
    tags: [] as TagInterface[],
  },
  imageListFields: {},
  settingsFields: {
    enableComments: true,
    enableRatings: true,
    publishDateTime: null as Date | null,
    unpublishDateTime: null as Date | null,
  },
  selectFields: {
    geoLocation: GeoLocations.wuppertal,
  },
  checkboxFields: {
    topicOfTheWeek: false,
  },
};

export const setNewsFields = (news: Partial<NewsInterface>) => {
  const {
    title = "",
    description = "",
    tags = [],
    pictures,
    geoLocation = GeoLocations.wuppertal,
    enableComments: comments,
    enableRatings: ratings,
    publishDateTime: publish,
    unpublishDateTime: unpublish,
    topicOfTheWeek = false,
    inFrameUrl,
    hashtags,
  } = news;

  const picturesList = pictures
    ? pictures.filter(
        o => o.type === PictureTypes.main || o.type === PictureTypes.secondary,
      )
    : null;

  const enableComments = comments === undefined ? true : comments;
  const enableRatings = ratings === undefined ? true : ratings;
  const publishDateTime = publish ? moment(publish).toDate() : null;
  const unpublishDateTime = unpublish ? moment(unpublish).toDate() : null;

  return {
    textAreaFields: {
      title,
      description,
      inFrameUrl,
    },
    tagsFields: { tags },
    imageListFields: {
      pictures: picturesList,
    },
    settingsFields: {
      enableComments,
      enableRatings,
      publishDateTime,
      unpublishDateTime,
    },
    selectFields: {
      geoLocation,
    },
    checkboxFields: {
      topicOfTheWeek,
    },
    hashtagsFields: { hashtags },
  };
};

export const getNewsPayload = (
  status: PublicationStatuses,
  values: Values,
  oldBody: Partial<NewsInterface> = {},
): Partial<NewsPayloadInterface> => {
  const {
    title,
    tags,
    geoLocation,
    description,
    enableComments,
    enableRatings,
    unpublishDateTime: unpublish,
    publishDateTime: publish,
    pictures: pictureValues,
    modifiedDateTime: modified,
    topicOfTheWeek,
    inFrameUrl,
    hashtags,
  } = values;
  const pictures = pictureValues ? [...pictureValues] : [];

  const tagIds = (tags || []).map(prop("id"));
  const unpublishDateTime = unpublish ? moment(unpublish).toISOString() : null;
  const modifiedDateTime = modified || null;
  const publishDateTime = getPublishDateTime(publish);

  const optionalFields: {
    pictures?: Picture[];
  } = {};

  return {
    ...oldBody,
    geoLocation,
    status,
    title,
    description,
    enableComments,
    enableRatings,
    tagIds,
    pictures,
    unpublishDateTime,
    modifiedDateTime,
    publishDateTime,
    topicOfTheWeek,
    inFrameUrl,
    hashtags,
    ...optionalFields,
  } as Partial<NewsPayloadInterface>;
};
