import React from "react";
import { clone } from "ramda";
import strings from "localisation/strings";
import styled, { isMobile } from "style/styled-components";
import appTheme from "style/theme";
import Artist from "api/models/Artist";
import TextButton from "components/buttons/TextButton";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import InlineInput from "components/forms/FormFields/InlineInput";
import FieldErrors from "components/forms/FieldErrors";
import artistIcon from "common/assets/icons/theatre.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import breakpoints from "style/breakpoints";

export interface ArtistsInputProps {
  artists?: Partial<Artist>[];
  onChange: (artists: Partial<Artist>[]) => void;
  errors?: string[];
}

const Container = styled.div`
  line-height: 32px;
`;

const FieldsContainer = styled.div`
  max-width: 330px;
  flex-grow: 1;
  > div:not(:last-child) {
    margin-bottom: 10px;
  }
  @media ${breakpoints.phoneMax} {
    max-width: unset;
  }
`;

const AddButton = styled(TextButton)<{ isMobileDevice: boolean }>`
  align-self: ${({ isMobileDevice }) =>
    isMobileDevice ? "flex-start" : "flex-end"};
  margin: 0
    ${({ isMobileDevice, theme }) =>
      isMobileDevice ? "0" : theme.margin.medium};
  > * {
    padding: 0;
  }
`;

const Wrapper = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  flex-basis: 100%;
  flex-direction: ${({ isMobileDevice }) =>
    isMobileDevice ? "column" : "row"};
`;

const initArtist: Partial<Artist>[] = [{ name: "" }];

const ArtistsInput = ({
  artists = initArtist,
  onChange,
  errors,
}: ArtistsInputProps) => {
  const editableArtistsList = artists.length === 0 ? initArtist : artists;
  const getFields = (artistList: Partial<Artist>[]) =>
    artistList.map((artist, i) => (
      <InlineInput
        key={`artist_${i}`}
        type="text"
        onChange={e => updateArtists(e, i)}
        value={artist.name}
        onBlur={removeEmptyInput}
        autoFocus={true}
        testId={`artists${i}`}
      />
    ));

  const addInputField = () => onChange([...editableArtistsList, ...initArtist]);
  // Get last item by slice(-1)[0] and check if it has name filled in
  const showAddButton =
    editableArtistsList.length === 0
      ? false
      : editableArtistsList.slice(-1)[0].name !== "";

  const updateArtists = (event: React.ChangeEvent<HTMLInputElement>, i = 0) => {
    const newValues = clone(editableArtistsList);
    if (newValues[i]) {
      newValues[i] = { name: event.currentTarget.value };
      onChange(newValues);
    }
  };
  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);

  const removeEmptyInput = () => {
    if (editableArtistsList.length <= 1) return;
    let newArtists = editableArtistsList.filter(a => a.name !== "");
    if (newArtists.length === 0) newArtists = initArtist;
    onChange(newArtists);
  };

  return (
    <Container>
      <InlineInputGroup
        margin={`${appTheme.margin.xsmall} 0`}
        icon={artistIcon}
        placeholder={strings("detailedScreen.artists.label")}
        width={appTheme.forms.leftSide}
      >
        <Wrapper isMobileDevice={isMobileDevice}>
          <FieldsContainer>{getFields(editableArtistsList)}</FieldsContainer>
          {showAddButton && (
            <AddButton
              text={strings("detailedScreen.artists.add")}
              onClick={addInputField}
              isMobileDevice={isMobileDevice}
            />
          )}
        </Wrapper>
      </InlineInputGroup>
      <FieldErrors errors={errors} />
    </Container>
  );
};

export default ArtistsInput;
