import React, { ReactNode } from "react";
import { Subscribe } from "unstated";

import LoginState from "state/LoginState";

const LoggedIn = ({ children }: { children: ReactNode }) => (
  <Subscribe to={[LoginState]}>
    {(loginState: LoginState) =>
      loginState.isUserLoggedIn() ? children : null
    }
  </Subscribe>
);
export default LoggedIn;
