import {
  Conversation,
  Message,
  MessageUser,
  QuickReplyOption,
  QuickReplyType,
  TalkerType,
} from "components/chat/types";
import ChatConversationDTO from "api/models/ChatConversationDTO";
import ChatMessageDTO from "api/models/ChatMessageDTO";
import { isNil } from "ramda";
import ChatSenderDTO from "api/models/ChatSenderDTO";
import ChatStartConversationDTO from "api/models/ChatStartConversationDTO";
import { formatLastMessage, formatSender } from "components/chat/utils";
import ChatConversationUpdateDTO from "api/models/ChatConversationUpdateDTO";

export const transformAPIConversation = (
  conversation:
    | ChatConversationDTO
    | ChatStartConversationDTO
    | ChatConversationUpdateDTO,
): Conversation => ({
  id: conversation.id,
  isUnread: !!conversation.hasNewMessages,
  isSpecial: !!conversation.hasUnreadSummary,
  isDisabled: conversation.enabled === null ? null : !conversation.enabled,
  unreadCount: conversation.unreadCount || 0,
  businessId: conversation.businessProfileId,
  title: conversation.title,
  lastModified: new Date(conversation.modifiedDateTime),
  image: conversation.image,
  isSystem: !!conversation.systemConversation,
  ownerDeleted: conversation.ownerDeleted,
  lastMessage: conversation.lastMessage
    ? formatLastMessage(
        formatSender(
          conversation.lastMessage.senderName,
          conversation.lastMessage.currentUser,
        ),
        conversation.lastMessage.message,
      )
    : null,
  displayLastMessage: conversation.displayLastMessage,
});

const getQuickReplyTypeFromChatMessageDTO = (
  message: ChatMessageDTO,
): QuickReplyType | null => {
  switch (message.quickReplyType) {
    case "BUTTONS":
      return QuickReplyType.BUTTON;
    case "TEXT":
      return QuickReplyType.TEXT;
    case "DATE":
      return QuickReplyType.DATE;
    case "DATE_TIME":
      return QuickReplyType.DATE_TIME;
    default:
      return null;
  }
};

const getTalkerTypeFromChatSenderDTO = (sender: ChatSenderDTO): TalkerType => {
  if (sender.talkerType === "USER") {
    return TalkerType.USER;
  }
  if (sender.talkerType === "BOT") {
    return TalkerType.BOT;
  }
  if (sender.talkerType === "SYSTEM") {
    return TalkerType.SYSTEM;
  }

  return TalkerType.BUSINESS;
};

export const transformAPIUser = (user: ChatSenderDTO): MessageUser => ({
  name: user.name,
  _id: user.id,
  avatar: user.image || undefined,
  talkerType: getTalkerTypeFromChatSenderDTO(user),
  deleted: user.deleted,
});

export const transformAPIChatMessage = (message: ChatMessageDTO): Message => ({
  _id: message.id,
  text: message.messageText,
  selectedValue: message.selectedValue || null,
  createdAt: new Date(message.createdDateTime || Date.now()),
  user: transformAPIUser(message.sender),
  system: message.messageType === "SYSTEM",
  quickReplyType: getQuickReplyTypeFromChatMessageDTO(message),
  quickReplies: (message.quickReplyOptions as QuickReplyOption[]) || [],
  isInteracted: !isNil(message.selectedValue),
  isSent: true,
});

export const transformAPIChatMessageUpdate = (
  message: ChatMessageDTO,
): Partial<Message> => ({
  selectedValue: message.selectedValue || null,
  quickReplyType: getQuickReplyTypeFromChatMessageDTO(message),
  quickReplies: (message.quickReplyOptions as QuickReplyOption[]) || [],
  isInteracted: !isNil(message.selectedValue),
  isSent: true,
});
