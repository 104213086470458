import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";

import useQuery from "common/hooks/useQuery";
import useLoginState from "hooks/useLoginState";
import TopSubNav from "components/topBar/TopSubNav";
import DesktopHeader from "components/topBar/DesktopHeader";
import HeaderOrFooterMedia from "components/topBar/HeaderOrFooterMedia";
import MobileHeader from "components/topBar/MobileHeader";
import MobileMenu from "components/topBar/MobileMenu";
import MobileUserMenu from "components/topBar/MobileUserMenu";
import { getBpPersonalData, getBoPersonalData } from "api/users";
import Backdrop from "components/generic/Backdrop";
import withLocaleDependency from "hocs/withLocaleDependency";
import {
  getTopRootPath,
  getTopSubNavRoutes,
  updateBusinessIdForNavigation,
  getHomePageUrl,
} from "core/navigation";

const Header = (props: RouteComponentProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [isUserMenuOpened, setIsUserMenuOpened] = useState<boolean>(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false);

  const loginState = useLoginState();

  const onMobileUserMenuOpen = () => {
    if (loginState.isUserLoggedIn()) {
      setIsUserMenuOpened(true);
      setIsSettingsVisible(true);
      return;
    }
    return onShowLogin();
  };

  const {
    history,
    location: { pathname },
  } = props;

  // WSAI2-2134: Workaround for authentication buttons being displayed in logged out admin pages
  const isUnauthorisedAdminPage =
    pathname.includes("/admin/") && !loginState.isUserLoggedIn();
  const areButtonsHidden =
    isUnauthorisedAdminPage ||
    pathname === "/admin/anmelden" ||
    pathname === "/admin/wiederherstellen" ||
    pathname.includes("/admin/konto/passwort-zuruecksetzen") ||
    pathname.includes("/admin/partnervertreter-konto/aktivieren") ||
    pathname.includes("/admin/konto/partnervertreter-aktiviert") ||
    pathname.includes("/konto-loeschen");

  const userDataRequest = loginState.hasAdminRights()
    ? getBoPersonalData
    : getBpPersonalData;
  const { result } = useQuery({
    request: userDataRequest,
    compare: [loginState.isUserLoggedIn()],
  });

  useEffect(() => {
    if (result) {
      loginState.setUserName(result.firstName, result.lastName);
    }
  }, [result]);

  useEffect(() => {
    setIsMenuOpened(false);
    setIsUserMenuOpened(false);
  }, [props.history.location.pathname]);

  useEffect(() => {
    if (props.location.hash === "#login" && !isUserMenuOpened) {
      setIsUserMenuOpened(true);
    }
    return () => {
      if (isUserMenuOpened) setIsUserMenuOpened(false);
      setIsSettingsVisible(false);
    };
  }, [props.location.hash, isUserMenuOpened]);

  const onLogout = () => {
    const windowPathname = window.location.pathname;
    let redirectionLink = "/";
    if (windowPathname.includes("/admin/")) redirectionLink = "/admin/anmelden";
    if (windowPathname.includes("onboarding")) redirectionLink = "/anmelden";
    loginState.logOut();
    // 0 timeout prevents showing empty content for a moment before redirecting (as opposed to Promise callback)
    setTimeout(() => history.push(redirectionLink), 0);
  };

  const onShowLogin = () => setTimeout(() => history.push("/anmelden"), 0);

  // we sync the business ID from URL into state
  updateBusinessIdForNavigation(props.location.pathname, loginState);

  const topRoutePath = getTopRootPath(props.location.pathname, loginState);
  const routeSubs = getTopSubNavRoutes(props.location.pathname, loginState);

  const appLogoLink = getHomePageUrl(loginState);

  return (
    <>
      <Backdrop isActive={isMenuOpened || isUserMenuOpened} transparent />
      <HeaderOrFooterMedia desktop>
        <DesktopHeader
          onShowSettings={() => setIsSettingsVisible(true)}
          onHideSettings={() => setIsSettingsVisible(false)}
          isSettingsVisible={isSettingsVisible}
          user={loginState.getUserName()}
          areButtonsHidden={areButtonsHidden}
          onLogout={onLogout}
          appLogoLink={appLogoLink}
        />
      </HeaderOrFooterMedia>
      <HeaderOrFooterMedia mobile>
        <MobileHeader
          areButtonsHidden={areButtonsHidden}
          onOpenMenu={() => setIsMenuOpened(true)}
          onOpenUserMenu={onMobileUserMenuOpen}
          appLogoLink={appLogoLink}
        />
        <MobileMenu
          onHideMobileMenu={() => setIsMenuOpened(false)}
          visible={isMenuOpened}
          appLogoLink={appLogoLink}
        />
        <MobileUserMenu
          visible={isUserMenuOpened}
          onHideMobileMenu={() => setIsUserMenuOpened(false)}
          onLogout={onLogout}
        />
      </HeaderOrFooterMedia>
      <TopSubNav topRoute={topRoutePath} items={routeSubs} />
    </>
  );
};

export default withLocaleDependency(withRouter(Header));
