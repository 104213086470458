import moment, { Moment } from "moment";
import DateTimeRange from "api/models/DateTimeRange";

export const setDateToStartOfDay = (
  date?: Date | Moment,
): string | undefined => {
  if (!date) {
    return undefined;
  }
  if (date instanceof Date) {
    return setDateToStartOfDay(moment(date));
  }
  return date.startOf("day").toISOString();
};

export const setDateToEndOfDay = (date?: Date | Moment): string | undefined => {
  if (!date) {
    return undefined;
  }
  if (date instanceof Date) {
    return setDateToEndOfDay(moment(date));
  }
  return date.endOf("day").toISOString();
};

export const getRestrictedDateRangeByDateFrom = ({
  dateTimeFrom,
  dateTimeTill,
}: DateTimeRange): DateTimeRange => {
  const fromMoment = moment(dateTimeFrom);

  return {
    dateTimeFrom,
    dateTimeTill:
      dateTimeFrom &&
      dateTimeTill &&
      fromMoment.isSameOrAfter(dateTimeTill, "day")
        ? setDateToEndOfDay(fromMoment)
        : dateTimeTill,
  };
};

export const getRestrictedDateRangeByDateTill = ({
  dateTimeFrom,
  dateTimeTill,
}: DateTimeRange): DateTimeRange => {
  const tillMoment = moment(dateTimeTill);

  return {
    dateTimeTill,
    dateTimeFrom:
      dateTimeFrom &&
      dateTimeTill &&
      tillMoment.isSameOrBefore(dateTimeFrom, "day")
        ? setDateToStartOfDay(tillMoment)
        : dateTimeFrom,
  };
};

export const combineDateObjectWithTimeObject = (
  dateObject: Date | null,
  timeObject: Date | null,
) => {
  if (!dateObject || !timeObject) {
    return dateObject || timeObject;
  }

  return new Date(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    timeObject.getHours(),
    timeObject.getMinutes(),
  );
};
