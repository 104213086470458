import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from "core/NotFound";
import Placeholder from "views/Placeholder";
import withLocaleDependency from "hocs/withLocaleDependency";
import { getRoutes, routeNames, SingleRoute } from "core/navigation";
import useLoginState from "hooks/useLoginState";
import withLoginRedirect from "hocs/withLoginRedirect";
import { hasAccess } from "utils";
import UserPrivileges from "constants/UserPrivileges";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import { OnboardingState } from "api/models/UsersInterface";

function addPlaceholderIfNecessary(route: SingleRoute): SingleRoute {
  const { component, render, children, name } = route;
  if (!component && !render && !children) {
    return {
      ...route,
      component: () =>
        route.redirectTo ? (
          <Redirect to={route.redirectTo} />
        ) : (
          <Placeholder name={name} />
        ),
    };
  }

  return route;
}

const LocaleDependentProtectedRoute = withLocaleDependency(
  withLoginRedirect(Route),
);

const Routes = () => {
  const loginState = useLoginState();
  const userHasSettingsBpWritePrivilege = getUserHasPrivilege(
    UserPrivileges.SETTINGS_BP_WRITE,
  );
  const routeList = getRoutes(loginState);

  const showWizard: boolean =
    loginState &&
    loginState.isUserLoggedIn() &&
    !loginState.getProfileCompleted();

  const redirectTo = () => {
    const onboardingState: OnboardingState = loginState.getOnboardingState();
    if (!userHasSettingsBpWritePrivilege) {
      return routeNames.WizardStep.Error;
    }
    if (onboardingState.termsAccepted && !onboardingState.paymentDataProvided) {
      return routeNames.WizardStep.AddPayment;
    }
    return routeNames.WizardStep.First;
  };

  return (
    <Switch>
      {showWizard && <Redirect from="/" exact to={redirectTo()} />}
      {routeList
        .map(route =>
          route.path.length > 0 ? addPlaceholderIfNecessary(route) : route,
        )
        .map((route, i) => {
          const { name, subs, key, path, ...props } = route;
          return subs && subs.length ? (
            <LocaleDependentProtectedRoute
              key={key || i}
              path={path}
              hasAccess={hasAccess(route, loginState)}
            >
              <>
                <LocaleDependentProtectedRoute
                  hasAccess={hasAccess(route, loginState)}
                  path={path}
                  exact
                  key={key}
                  {...props}
                />
                <Switch>
                  {subs
                    .map(subroute => addPlaceholderIfNecessary(subroute))
                    .map(subRoute => (
                      <LocaleDependentProtectedRoute
                        {...subRoute}
                        exact
                        key={subRoute.key || `${path}${subRoute.path}`}
                        path={`${path}${subRoute.path}`}
                        hasAccess={hasAccess(subRoute, loginState)}
                      />
                    ))}
                </Switch>
              </>
            </LocaleDependentProtectedRoute>
          ) : (
            <LocaleDependentProtectedRoute
              exact
              path={path}
              key={key || i}
              hasAccess={hasAccess(route, loginState)}
              {...props}
            />
          );
        })}
      <LocaleDependentProtectedRoute component={NotFound} />
    </Switch>
  );
};

export default Routes;
