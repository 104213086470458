import React, { ReactNode } from "react";
import { dissoc, clone } from "ramda";
import Truncate from "react-truncate";

import sortArrowBlack from "assets/icons/sort-arrow-black.svg";
import sortArrowGray from "assets/icons/sort-arrow-gray.svg";
import {
  CategoryQueryInterface,
  ColumnModel,
  Filter,
} from "components/table/DataTable/interfaces";
import {
  defaultCategoryQuery,
  filterTypes,
  placeholderDash,
} from "components/table/DataTable/constants";
import CellTypes from "components/table/CellTypes";
import { commaList, flatCommaList, formattedDate } from "utils";
import SelectField from "components/table/SelectField";
import TableLink from "components/table/TableLink";
import EmojiTableLink from "components/table/EmojiTableLink";
import strings from "localisation/strings";
import PublicationStatuses from "api/models/PublicationStatuses";
import LevelThreeCategoryButtons from "components/buttons/CategoryButtons/LevelThreeCategoryButtons";
import LevelFourCategoryButtons from "components/buttons/CategoryButtons/LevelFourCategoryButtons";
import { SelectItem } from "components/forms/Select";
import Icon from "components/forms/FormFields/Icon";

import bell from "common/assets/icons/bell.svg";
import date from "common/assets/icons/date.svg";
import pushNotification from "common/assets/icons/pushNotification.svg";
import SearchRequestFilters from "api/models/SearchRequestFilters";

import PublicUserActionButtons, {
  ActionColumnContainer,
} from "components/table/DataTable/PublicUserActionButtons";
import moment from "moment";
import TypeFilterIcons from "components/table/TypeFilterIcons";
import { SUB } from "constants/strings";
import { UserStatuses } from "api/models/RepresentativeInterface";
import InactiveBusinessRepresentativeDeleteButton from "components/table/DataTable/Buttons/InactiveBusinessRepresentativeDeleteButton";
import _ from "lodash";
import styled from "style/styled-components";

const DisabledText = styled.span`
  font-size: ${({ theme }) => theme.font.size.table};
  color: ${({ theme }) => theme.color.foreground.disabledLight};
  flex-shrink: 1;
`;

const getSortFields = (sort?: string) => {
  const arr = sort ? sort.split(",") : [null, null];
  return { type: arr[0], order: arr[1] };
};

export const getArrow = (key?: string, sort?: string) => {
  const { type, order } = getSortFields(sort);

  let rotate = 0;
  let arrow = sortArrowGray;

  if (type === key) {
    arrow = sortArrowBlack;
    if (order === "desc") rotate = 180;
  }

  return `
    background-image: url(${arrow});
    transform: rotate(${rotate}deg);
  `;
};

export const getSort = (key: string, sort?: string) => {
  const { type, order } = getSortFields(sort);

  if (type === key) {
    if (order === "asc") return `${key},desc`;
    return `${key},asc`;
  }
  return `${key},asc`;
};

const getDefaultValue = (value?: string, defaultValue?: string) => {
  return value || (defaultValue ? strings(defaultValue) : "") || "";
};

export const renderCellByType = (
  row: any,
  column: ColumnModel,
  edit: (body: any, id: string) => void,
  refreshTable: () => void,
  onClickEdit?: (id: string) => void,
  onClickCopy?: (id: string) => void,
  onClickAdd?: (id: string) => void,
  isCategoriesView?: boolean,
  isRepresentativesView?: boolean,
): ReactNode => {
  if (column.type) {
    const rowId = isCategoriesView
      ? row.tagId
      : isRepresentativesView
      ? row.userId
      : row.id;

    switch (column.type) {
      case CellTypes.action:
        if (row.level3 && onClickEdit && onClickCopy && onClickAdd) {
          const questionId = row.questionId ? `${row.questionId}` : undefined;
          return (
            <LevelThreeCategoryButtons
              tagId={rowId}
              questionId={questionId}
              onClickEdit={onClickEdit}
              onClickCopy={onClickCopy}
              onClickAdd={onClickAdd}
            />
          );
        }

        if (!row.level3 && onClickEdit) {
          return (
            <LevelFourCategoryButtons
              parentId={row.parentId || rowId}
              questionId={row.questionId}
              onClickEdit={onClickEdit}
            />
          );
        }

        if (column.id === "publicUserActions") {
          return (
            <PublicUserActionButtons
              creatingInProgress={row.creatingInProgress}
              logFileId={row.logFileId}
              userId={row.userId}
              refreshTable={refreshTable}
            />
          );
        }

        if (getIsBusinessRepresentativeDeletable(column, row)) {
          return (
            <ActionColumnContainer>
              <InactiveBusinessRepresentativeDeleteButton userId={row.userId} />
            </ActionColumnContainer>
          );
        }
        break;
      case CellTypes.categoryLevel: {
        const levelTag = strings(`tableView.lvlTag`);
        return row.level3 ? `${levelTag} 3` : `${levelTag} 4`;
      }
      case CellTypes.date:
        if (column.id === "dateOfBirth") {
          return formattedDate(row[column.id], "DD. MMM YYYY");
        }
        return formattedDate(row[column.id], "DD.MM.YYYY HH:mm");

      case CellTypes.dateRange:
        if (column.dateRangeFrom) {
          const dateFrom = column.dateRangeFrom
            ? _.get(row, column.dateRangeFrom)
            : undefined;
          const dateTo = column.dateRangeTo
            ? _.get(row, column.dateRangeTo)
            : undefined;

          if (dateTo) {
            return `${formattedDate(dateFrom, "DD.MM.YYYY")} - ${formattedDate(
              dateTo,
            )}`;
          }

          return formattedDate(dateFrom, "DD.MM.YYYY HH:mm");
        }
        if (row[column.id] && row[column.id].dateTimeFrom) {
          if (row[column.id].dateTimeTill) {
            return `${formattedDate(
              row[column.id].dateTimeFrom,
              "DD.MM.",
            )} - ${formattedDate(row[column.id].dateTimeTill)}`;
          }
          return formattedDate(row[column.id].dateTimeFrom);
        }
        return placeholderDash;

      case CellTypes.deepList:
        return row[column.id] && row[column.id].length ? (
          <Truncate lines={2} ellipsis={"..."} trimWhitespace>
            {flatCommaList(row[column.id], column.successorField)}
          </Truncate>
        ) : (
          ""
        );

      case CellTypes.dropdown: {
        const options = column.selectOptions ? column.selectOptions : [];
        let optionsWithoutSelected = options.filter(
          o => o.value !== row[column.id],
        );

        if (column.filter && column.filter.id === "tagStatus") {
          optionsWithoutSelected = optionsWithoutSelected.filter(
            o => o.value !== "",
          );
        }

        const isDraft = row[column.id] === PublicationStatuses.draft;

        const isSubCategoryColumn = !!row.parentId;

        const isParentActive: boolean = !!row.parentActive && row.parentActive;

        const isNotChangeable =
          isDraft || (isSubCategoryColumn && !isParentActive);

        if (column.filter && column.filter.id === "typeFilter") {
          return <TypeFilterIcons row={row} />;
        }

        return (
          <SelectField
            notChangable={isNotChangeable}
            onChange={({ value }: { value: string }) => {
              edit({ [column.id]: value }, rowId);
            }}
            options={optionsWithoutSelected}
            value={row[column.id]}
          />
        );
      }

      case CellTypes.adminStatusChange: {
        // custom dropdown logic for admin status change
        let adminStatusOptions: SelectItem[] = [];
        let locked = false;

        if (row[column.id] === PublicationStatuses.published) {
          adminStatusOptions = [
            {
              label: strings("tableView.options.unpublished"),
              value: PublicationStatuses.unpublished,
            },
          ];
        } else if (row[column.id] === PublicationStatuses.prePublished) {
          adminStatusOptions = [
            {
              label: strings("tableView.options.published"),
              value: PublicationStatuses.published,
            },
          ];
        } else if (row[column.id] === PublicationStatuses.unpublished) {
          adminStatusOptions = [
            {
              label: strings("tableView.options.published"),
              value: PublicationStatuses.published,
            },
            {
              label: strings("tableView.options.draft"),
              value: PublicationStatuses.draft,
            },
          ];
        } else {
          locked = true;
        }

        return (
          <SelectField
            notChangable={locked}
            onChange={({ value }: { value: string }) => {
              edit({ [column.id]: value }, rowId);
            }}
            options={adminStatusOptions}
            value={row[column.id]}
          />
        );
      }

      case CellTypes.link: {
        const isHidden =
          row.subCode && (column.id === "mainCode" || column.id === "question");

        if (isHidden) {
          return (
            <DisabledText>
              {getDefaultValue(row[column.id], column.defaultValue)}
            </DisabledText>
          );
        }

        let link = `${column.linkPrefix}/${rowId}`;
        if (column.linkBuilder) {
          link = column.linkBuilder(row, column);
        } else if (column.id === "subCode") {
          link = `${column.linkPrefix}/${SUB}/${row.parentId}`;
          if (row.questionId) {
            const queryParameter = `?qid=${row.questionId}`;
            link = `${link}${queryParameter}`;
          }
        } else if (column.id === "fullName" && row.userId && row.businessId) {
          // Navigating to Representative detail page
          link = `${column.linkPrefix}/${row.businessId}/partnervertreter/${row.userId}`;
        }

        return (
          <EmojiTableLink
            title={
              isHidden
                ? ""
                : getDefaultValue(row[column.id], column.defaultValue)
            }
            key={row[column.id]}
            link={link}
          />
        );
      }

      case CellTypes.list:
        return row[column.id] && row[column.id].length
          ? commaList(row[column.id])
          : "";

      case CellTypes.businessName:
        return row.businessId ? (
          <TableLink
            key={row[column.id]}
            to={`${column.linkPrefix}/${row.businessId}/partnerprofil`}
          >
            {getDefaultValue(row[column.id], column.defaultValue)}
          </TableLink>
        ) : null;

      case CellTypes.createdBy:
        return row.createdById && row.createdByBusinessId ? (
          <TableLink
            key={row[column.id]}
            to={`${column.linkPrefix}/${row.createdByBusinessId}/partnervertreter/${row.createdById}`}
          >
            {getDefaultValue(row[column.id], column.defaultValue)}
          </TableLink>
        ) : null;

      case CellTypes.location:
        return row[column.id] ? row[column.id].venue : "";

      case CellTypes.parent:
        if (row.parent) return row.parent.code;
        return "";

      case CellTypes.text: {
        let val = row[column.id];
        if (column.selectOptions) {
          const matchingOption = column.selectOptions.find(
            opt => opt.value === val,
          );
          if (matchingOption) {
            return matchingOption.label;
          }
        }
        if (column.id === "permissionToAnalyzeData") {
          val = row[column.id]
            ? strings("tableView.options.yes")
            : strings("tableView.options.no");
        }
        return val;
      }

      case CellTypes.type:
        return row.level3 ? (
          strings(`tableView.options.${row[column.id].toLowerCase()}`)
        ) : (
          <DisabledText>
            {strings(`tableView.options.${row[column.id].toLowerCase()}`)}
          </DisabledText>
        );
      case CellTypes.businessType:
        return row.businessType
          ? strings(`tableView.options.${row[column.id].toLowerCase()}`)
          : "";
      case CellTypes.alertNotification:
        return row[column.id] ? <Icon backgroundImage={bell} /> : null;
      case CellTypes.pushNotification:
        return row[column.id] ? (
          <Icon backgroundImage={pushNotification} />
        ) : null;
      case CellTypes.topicOfTheWeekNotification:
        return row[column.id] ? <Icon backgroundImage={date} /> : null;
      default:
        return row[column.id] || null;
    }
  }

  const isButtonColumn = column.id === "buttons";
  return isButtonColumn ? "" : row[column.id];
};

const getIsBusinessRepresentativeDeletable = (
  column: ColumnModel,
  row: any,
): boolean => {
  return (
    column.id === "businessRepresentativeActions" &&
    row.status === UserStatuses.INACTIVE &&
    row.businessId === null
  );
};

const getNewQuery = (filter: Filter, query: string): string | void => {
  switch (filter.type) {
    case filterTypes.searchField:
      return addSearchField(filter, query);
    case filterTypes.selectList:
    case filterTypes.simpleSelect:
      return addSelectList(filter, query);
    case filterTypes.dateRange:
      return addDateRange(filter, query);
    case filterTypes.categoriesList:
      if (filter.value && filter.value.length) {
        let values = "";

        filter.value.forEach((v: string) => {
          values += `${v},`;
        });

        values = values.substr(0, values.length - 1);

        return `${query}tags=in=(${values});`;
      }
      break;
    default:
      return query;
  }
};

const assignCategoryQuery = (
  filter: Filter,
  query: CategoryQueryInterface,
): void => {
  switch (filter.type) {
    case filterTypes.searchField:
      addSearchField(filter, query);
      break;
    case filterTypes.selectList:
    case filterTypes.simpleSelect:
      addSelectList(filter, query);
      break;
    case filterTypes.dateRange:
      addDateRange(filter, query);
      break;
    case filterTypes.type:
      if (filter.value && filter.value.length > 0) {
        query[filter.id] = filter.value;
      }
      break;
  }
};

function addSearchField(
  filter: Filter,
  query: string | CategoryQueryInterface,
): string | void {
  if (filter.value && filter.value.length > 0) {
    /*
     * escaping single quotes and backslashes as per agreed usage of RSQL
     * syntax: https://github.com/jirutka/rsql-parser
     */
    const escapedValue = filter.value
      .replace(/\\/g, "\\\\")
      .replace(/'/g, "\\'");

    if (typeof query === "string") {
      const newQuery = `${query}${filter.id}=='${escapedValue}';`;
      return newQuery;
    }

    query[filter.id] = escapedValue;
  }

  if (typeof query === "string") return query;
  if (filter.value === "") query[filter.id] = undefined;
}

const getFilterValue = (filter: Filter) => {
  if (Array.isArray(filter.value) && filter.value.length > 0) {
    return filter.value.map(appliedFilter => appliedFilter.value);
  }
  if (filter.id === "tagTypes") {
    return filter.value.value && [filter.value.value];
  }
  return filter.value.value;
};

function addSelectList(
  filter: Filter,
  query: string | CategoryQueryInterface,
): string | void {
  const isLevel3Selected =
    filter.id === "isLevel3" && filter.value !== undefined;

  if (isLevel3Selected) {
    if (typeof query === "string") {
      const newQuery = `${query}${filter.id}==${filter.value};`;
      return newQuery;
    }
    // Special case for Category level filter.
    // We have 3 options for the filter on BE
    // - true (only level 3 categories)
    // - false (only level 4 categories)
    // - null (all categories)
    if (Array.isArray(filter.value)) {
      const requestValue =
        !filter.value.length || filter.value.length === 2
          ? null
          : filter.value[0].value === 3;
      query[filter.id] = requestValue;
    }
    return;
  }

  if (filter.value) {
    if (typeof query === "string") {
      let newQuery;
      if (Array.isArray(filter.value)) {
        newQuery = filter.value
          .map(appliedFilter => `${filter.id}==${appliedFilter.value}`)
          .join(",");
      } else {
        newQuery = `${query}${filter.id}==${filter.value}`;
      }
      return `${newQuery};`;
    }

    const filterValue = getFilterValue(filter);
    query[filter.id] = filterValue || undefined;
    return;
  }

  if (typeof query === "string") return query;
}

function addDateRange(
  filter: Filter,
  query: string | CategoryQueryInterface,
): string | void {
  if (filter.value) {
    const hasStart =
      filter.value.hasOwnProperty.call("start") && filter.value.start;
    const hasEnd = filter.value.hasOwnProperty.call("end") && filter.value.end;

    if (typeof query === "string") {
      let dateQuery = "";
      if (hasStart) {
        dateQuery += `${filter.id}=ge=${filter.value.start.toISOString()};`;
      }
      if (hasEnd) {
        dateQuery += `${filter.id}=le=${filter.value.end.toISOString()};`;
      }
      return `${query}${dateQuery}`;
    }

    query.dateTimeFrom = hasStart ? filter.value.start : undefined;
    query.dateTimeTo = hasEnd ? filter.value.end : undefined;
  }

  if (typeof query === "string") return query;
}

export const buildFilterQuery = (filters: Filter[], listType: string) => {
  if (listType === "kategorien") {
    const categoryQuery: CategoryQueryInterface = clone(defaultCategoryQuery);
    filters.forEach(filter => {
      assignCategoryQuery(filter, categoryQuery);
    });
    return categoryQuery;
  }

  let query = "";

  filters.forEach(filter => {
    const newQuery = getNewQuery(filter, query);
    query = typeof newQuery === "string" ? newQuery : query;
  });
  if (query.length > 0) return query.substr(0, query.length - 1);

  return undefined;
};

export const getRepresentativeRolesOptions = (roles: SelectItem[] = []) => [
  ...roles,
];

export const getFiltersUpdate = (
  updatedFilters: Filter[],
  searchFilters?: SearchRequestFilters,
): SearchRequestFilters => {
  let currentFilters = searchFilters;

  const newFilters = {} as SearchRequestFilters;

  for (const filter of updatedFilters) {
    const { id, value } = filter;
    switch (id) {
      case "fullName":
      case "fullAddress":
      case "email":
      case "businessName":
      case "fullPhone":
      case "qrCode":
      case "nestedTitle":
      case "createdBy":
      case "venue": {
        if (!value && value !== "") break;

        // handling BUP special case where title is a part of termsMapFilter
        // TODO: remove when all searches expect title under termsMapFilter
        const fieldId = id === "nestedTitle" ? "title" : id;

        const oldTermsMap =
          searchFilters && searchFilters.termsMapFilter
            ? { ...searchFilters.termsMapFilter }
            : newFilters.termsMapFilter
            ? { ...newFilters.termsMapFilter }
            : {};

        newFilters.termsMapFilter = {
          ...oldTermsMap,
          ...newFilters.termsMapFilter,
          [fieldId]: value,
        };
        break;
      }

      case "representativeRoleFilter":
      case "profileStateFilter":
      case "userStatusFilter":
      case "statusFilter":
      case "offerTypeFilter":
      case "tagFilter":
      case "typeFilter": {
        let newValue = value;
        if (value && value.hasOwnProperty.call("value")) {
          newValue = value.value;
          newFilters[id] = [{ id: newValue, name: "" }] as any;
        }
        if (Array.isArray(value) && value.length > 0) {
          newFilters[id] = newValue;
        }
        if (value === undefined || !value.length) {
          // removing current filter if deselected
          currentFilters = dissoc(id, currentFilters);
        }
        break;
      }

      case "dateFilter":
        if (value) {
          newFilters[id] = {
            dateFrom: value.start && moment(value.start).format("YYYY-MM-DD"),
            dateTill: value.end && moment(value.end).format("YYYY-MM-DD"),
          };
        }
        break;

      case "dateTimeFilter":
      case "dateTimeRangeFilter":
      case "modifiedDateTimeFilter":
      case "publishDateTimeFilter":
        if (value) {
          newFilters[id] = {
            dateTimeFrom: value.start,
            dateTimeTill: value.end,
          };
        }
        break;
      case "title":
        newFilters[id] = value || "";
        break;

      case "permissionToAnalyzeData":
      case "publishAlert":
      case "publishPushNotification":
      case "alertToBusiness":
      case "topicOfTheWeek":
      case "smartService":
      case "jobOffer":
        if (!newFilters.booleanFilter) {
          newFilters.booleanFilter = {};
        }
        if (!value || value.value === "all") {
          // We dont use "booleanFilterValue=false" filter values. If it's not set, clear that specific filter
          delete newFilters.booleanFilter[id];
        } else {
          newFilters.booleanFilter[id] = value;
          if (id === "permissionToAnalyzeData") {
            value.value === "no"
              ? (newFilters.booleanFilter[id] = false)
              : (newFilters.booleanFilter[id] = true);
          }
        }
        break;
    }
  }
  return { ...currentFilters, ...newFilters } as SearchRequestFilters;
};

// Workaround to convert search request filter object into plain Filter[] type
export const mapSearchFiltersToFilters = (
  columns: ColumnModel[],
  searchFilters?: SearchRequestFilters,
  roles?: SelectItem[],
): Filter[] => {
  if (!searchFilters) return [];
  const filtersToDisplay: Filter[] = [];
  for (const [key, value] of Object.entries(searchFilters)) {
    switch (key) {
      case "termsMapFilter":
        for (const [termsKey, termsValue] of Object.entries(
          searchFilters[key],
        )) {
          // handling BUP special case where title is a part of termsMapFilter
          // TODO: remove when all searches expect title under termsMapFilter
          const id = termsKey === "title" ? "nestedTitle" : termsKey;
          filtersToDisplay.push({ id, value: termsValue });
        }
        break;

      case "representativeRoleFilter": {
        if (value) {
          const roleOptions = getRepresentativeRolesOptions(roles);
          const roleValue = roleOptions.find(r => r.value === value[0].id);
          if (Array.isArray(value)) {
            filtersToDisplay.push({ value, id: key });
          } else {
            filtersToDisplay.push({
              value: roleValue,
              id: key,
            });
          }
        }
        break;
      }

      case "profileStateFilter":
      case "userStatusFilter":
      case "statusFilter":
      case "typeFilter": {
        const statusColumn = columns.find(
          c => !!c.filter && c.filter.id === key,
        );
        const statusFilterOptions =
          statusColumn && statusColumn.filter
            ? statusColumn.filter.selectList || []
            : [];
        const statusValue = statusFilterOptions.find(
          s => s.value === value[0].id,
        );

        if (value) {
          if (Array.isArray(value)) {
            filtersToDisplay.push({ value, id: key });
          } else {
            filtersToDisplay.push({
              value: statusValue,
              id: key,
            });
          }
        }

        // Push some values as boolean type value along with BE implementation
        if (key === "typeFilter") {
          value.forEach((element: { data: { id: string } }) => {
            if (element.data.id === "publishAlert") {
              filtersToDisplay.push({ id: "publishAlert", value: true });
            }
            if (element.data.id === "publishPushNotification") {
              filtersToDisplay.push({
                id: "publishPushNotification",
                value: true,
              });
            }
            if (element.data.id === "alertToBusiness") {
              filtersToDisplay.push({
                id: "alertToBusiness",
                value: true,
              });
            }
            if (element.data.id === "topicOfTheWeek") {
              filtersToDisplay.push({
                id: "topicOfTheWeek",
                value: true,
              });
            }
            if (element.data.id === "smartService") {
              filtersToDisplay.push({
                id: "smartService",
                value: true,
              });
            }
            if (element.data.id === "jobOffer") {
              filtersToDisplay.push({
                id: "jobOffer",
                value: true,
              });
            }
          });
        }

        break;
      }

      case "dateFilter":
        if ((value && value.dateFrom) || value.dateTill) {
          filtersToDisplay.push({
            value: { start: value.dateFrom, end: value.dateTill },
            id: key,
          });
        }
        break;

      case "dateTimeFilter":
      case "dateTimeRangeFilter":
      case "modifiedDateTimeFilter":
      case "publishDateTimeFilter":
        if ((value && value.dateTimeFrom) || value.dateTimeTill) {
          filtersToDisplay.push({
            value: { start: value.dateTimeFrom, end: value.dateTimeTill },
            id: key,
          });
        }
        break;

      case "booleanFilter":
        {
          const {
            publishAlert,
            publishPushNotification,
            alertToBusiness,
            topicOfTheWeek,
            permissionToAnalyzeData,
            smartService,
            jobOffer,
          } = value;
          if (publishAlert !== undefined) {
            filtersToDisplay.push({ id: "publishAlert", value: publishAlert });
          }
          if (publishPushNotification !== undefined) {
            filtersToDisplay.push({
              id: "publishPushNotification",
              value: publishPushNotification,
            });
          }
          if (alertToBusiness !== undefined) {
            filtersToDisplay.push({
              id: "alertToBusiness",
              value: alertToBusiness,
            });
          }
          if (topicOfTheWeek !== undefined) {
            filtersToDisplay.push({
              id: "topicOfTheWeek",
              value: topicOfTheWeek,
            });
          }
          if (permissionToAnalyzeData !== undefined) {
            filtersToDisplay.push({
              id: "permissionToAnalyzeData",
              value: permissionToAnalyzeData,
            });
          }
          if (smartService !== undefined) {
            filtersToDisplay.push({
              id: "smartService",
              value: smartService,
            });
          }
          if (jobOffer !== undefined) {
            filtersToDisplay.push({
              id: "jobOffer",
              value: jobOffer,
            });
          }
        }
        break;
      case "offerTypeFilter":
        if (value) {
          const offerTypeColumn = columns.find(
            c => !!c.filter && c.filter.id === key,
          );
          const offerTypeFilterOptions =
            offerTypeColumn && offerTypeColumn.filter
              ? offerTypeColumn.filter.selectList || []
              : [];
          const offerTypeValue = offerTypeFilterOptions.find(
            r => r.value === value[0].id,
          );
          if (Array.isArray(value)) {
            filtersToDisplay.push({ value, id: key });
          } else {
            filtersToDisplay.push({
              value: offerTypeValue,
              id: key,
            });
          }
        }
        break;
      default:
        filtersToDisplay.push({ value, id: key });
    }
  }
  return filtersToDisplay;
};
