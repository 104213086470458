import React from "react";
import TransparentIconButton from "components/buttons/TransparentIconButton";
import DeleteIcon from "assets/icons/delete.svg";
import styled from "style/styled-components";
import IconActionButton from "components/buttons/IconActionButton";
import strings from "localisation/strings";
import ButtonWithConfirmation from "components/buttons/ButtonWithConfirmation";

interface ClickHandlerProps {
  onClick: () => void;
}
interface IdProps {
  id: string;
}
interface OrderProps {
  order?: number;
}
interface DisabledStatusProps {
  isDisabled?: boolean;
}

const Container = styled(TransparentIconButton)<OrderProps>`
  order: ${({ order }) => order};
  z-index: ${({ theme }) => theme.layers.componentButton};
`;

export const DeleteIconButton = ({ alt, ...props }: any) => (
  <img src={DeleteIcon} alt={alt || ""} {...props} />
);

const ClickableDeleteButton = ({
  onClick,
  id,
  order,
}: ClickHandlerProps & IdProps & OrderProps) => (
  <>
    <ButtonWithConfirmation
      id={id}
      onClick={onClick}
      submitButtonLabel={strings("buttons.delete")}
      actionButton={
        <IconActionButton order={order} tooltipKey="buttons.delete">
          <DeleteIconButton />
        </IconActionButton>
      }
      confirmationMessage={strings("popupMessages.deleteUser")}
    />
  </>
);

const DisabledDelete = styled(DeleteIconButton)`
  opacity: 0.5;
`;

const DisabledContainer = styled(Container)`
  cursor: ${({ theme }) => theme.cursors.notAllowed};
`;

const DisabledDeleteButton = ({ order }: OrderProps) => (
  <DisabledContainer onClick={() => {}} order={order}>
    <DisabledDelete />
  </DisabledContainer>
);

const DeleteButton = ({
  isDisabled,
  ...props
}: IdProps & ClickHandlerProps & DisabledStatusProps & OrderProps) =>
  isDisabled ? (
    <DisabledDeleteButton {...props} />
  ) : (
    <ClickableDeleteButton {...props} />
  );

export default DeleteButton;
