import React from "react";
import OfferInterface from "api/models/OfferInterface";
import OfferTypes from "api/models/OfferTypes";
import BaseListItem from "components/lists/item/BaseListItem";
import CouponQuantity from "components/lists/item/CouponQuantity";
import ParticipationsQuantity from "components/lists/item/ParticipationsQuantity";
import { ListItemProps } from "components/lists/item/BaseListItemComponents";
import { TWO_LINE_TAGS_MAX_DEPTH } from "constants/ListItemConstants";
import PictureTypes from "api/models/PictureTypes";

export interface OfferListItemProps extends ListItemProps {
  offer: OfferInterface;
}

const OfferListItem = ({ offer, onClick }: OfferListItemProps) => {
  const {
    dateTimeRange,
    id,
    pictures,
    lastModifiedBy,
    modifiedDateTime,
    status,
    title,
    tags,
    type,
    participations,
  } = offer;
  const image = pictures
    ? pictures.find(item => item.type === PictureTypes.main)
    : undefined;
  const imageUrl = image ? image.url : undefined;
  return (
    <BaseListItem
      dateTimeRange={dateTimeRange}
      id={id}
      imageUrl={imageUrl}
      lastModifiedBy={lastModifiedBy}
      modifiedDateTime={modifiedDateTime}
      onClick={onClick}
      status={status}
      tags={tags}
      title={title}
      contentType={"OFFER"}
      tagsMaxDepthFromTop={TWO_LINE_TAGS_MAX_DEPTH}
    >
      {type === OfferTypes.coupon && (
        <CouponQuantity
          quantityTotal={offer.quantityTotal}
          participations={participations}
        />
      )}
      {type === OfferTypes.promotion && (
        <ParticipationsQuantity participations={participations} />
      )}
    </BaseListItem>
  );
};

export default OfferListItem;
