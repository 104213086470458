import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "style/styled-components";

import { RegisterPayload } from "api/models/UsersInterface";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { portalCode } from "api/constants";

import useFormFields from "hooks/useFormFields";

import Checkbox from "components/forms/Checkbox";
import getPasswordStrength, {
  PasswordStrengths,
} from "components/forms/RegistrationForm/getPasswordStrength";
import PasswordStrength from "components/forms/RegistrationForm/PasswordStrength";
import { FieldAligner } from "components/forms/FieldContainer";
import TextField from "components/forms/TextField";
import FieldErrors from "components/forms/FieldErrors";
import FieldMargin from "components/forms/FieldMargin";
import RecaptchaWidget from "components/forms/RecaptchaWidget";
import {
  CenteredFieldMessage,
  StyledRegisterButton,
} from "components/forms/RegistrationForm/components";
import useComponentDidMount from "hooks/useComponentDidMount";
import BackToBliggitButton from "components/buttons/BackToBliggitButton";
import { PasswordToggleField } from "components/forms/LoginForm/LoginForm";

import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: ${({ theme }) => theme.forms.xlarge};
  ${inputContainerAutoZoomPrevetionStyles};
`;

const RegistrationForm = () => {
  const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(
    false,
  );
  const [recaptchaTimestamp, setRecaptchaTimestamp] = useState<Date>(
    new Date(),
  );
  const {
    errors,
    values,
    getTextFieldProps,
    getCheckboxFieldProps,
    focusField,
    onChange,
    parseErrors,
  } = useFormFields({
    handleGenericErrors: true,
    translationScope: "registrationScreen.fields",
    textFields: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    hiddenFields: {
      portalCode,
      recaptcha: "",
    },
    checkboxFields: {
      acceptedTermsAndConditions: false,
      acceptedPrivacyPolicy: false,
      permissionToAnalyzeData: false,
    },
  });

  useComponentDidMount(() => {
    focusField("email");
  });

  const getPayload = (): RegisterPayload | undefined => {
    return { ...values, email: values.email.trim() };
  };

  const onRegistrationFailed = (body: APIErrorResponse) => {
    parseErrors(body);
    onChange("recaptcha", "", {
      doNotClearErrors: true,
    });
    reloadRecaptcha();
  };

  const reloadRecaptcha = () => setRecaptchaTimestamp(new Date());

  const onTokenChange = (token: string | null) => onChange("recaptcha", token);

  if (registrationSuccess) return <Redirect to="/registrierung-erfolgreich" />;

  const preventSubmit = (data: any): boolean =>
    !data.email.length ||
    !data.password.length ||
    !data.passwordConfirmation.length ||
    getPasswordStrength(data.password) === PasswordStrengths.INVALID;

  return (
    <>
      <InputFieldsContainer>
        <TextField {...getTextFieldProps("email")} />
        <PasswordToggleField {...getTextFieldProps("password")}>
          <FieldAligner>
            <PasswordStrength password={values.password} />
          </FieldAligner>
        </PasswordToggleField>
        <PasswordToggleField {...getTextFieldProps("passwordConfirmation")} />
      </InputFieldsContainer>
      <FieldMargin>
        <FieldAligner>
          <Checkbox {...getCheckboxFieldProps("acceptedTermsAndConditions")} />
          <FieldErrors errors={errors.acceptedTermsAndConditions || []} />
        </FieldAligner>
      </FieldMargin>
      <FieldMargin>
        <FieldAligner>
          <Checkbox {...getCheckboxFieldProps("acceptedPrivacyPolicy")} />
          <FieldErrors errors={errors.acceptedPrivacyPolicy || []} />
        </FieldAligner>
      </FieldMargin>
      <FieldAligner>
        <Checkbox {...getCheckboxFieldProps("permissionToAnalyzeData")} />
      </FieldAligner>
      <RecaptchaWidget
        onChange={onTokenChange}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
        timestamp={recaptchaTimestamp}
      />
      <CenteredFieldMessage>{errors.recaptcha}</CenteredFieldMessage>

      <StyledRegisterButton
        getPayload={getPayload}
        successfulRequestCallback={() => setRegistrationSuccess(true)}
        failedRequestCallback={onRegistrationFailed}
        disabled={preventSubmit(values)}
      />

      <BackToBliggitButton />
    </>
  );
};

export default RegistrationForm;
