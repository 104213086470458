import React from "react";
import { curry } from "ramda";
import { RouteComponentProps } from "react-router";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import { validateAccountActivationToken } from "api/session";
import { Null } from "components/generic";
import BusinessUserLinkExpired from "views/BusinessUserActivation/BusinessUserLinkExpired";
import BusinessUserActivationConfirmationView from "views/BusinessUserActivation/BusinessUserActivationConfirmationView";
import useLoginState from "hooks/useLoginState";

interface RepresentativeActivationRouteParams {
  token: string;
}

type Props = RouteComponentProps<RepresentativeActivationRouteParams>;

const ViewByResponseStatusMap = {
  [QueryStatus.ERROR]: BusinessUserLinkExpired,
  [QueryStatus.SUCCESSFUL]: BusinessUserActivationConfirmationView,
  [QueryStatus.UNDETERMINED]: Null,
  [QueryStatus.WAITING]: Null,
};

const ActivateRepresentativeView = ({ match }: Props) => {
  const loginState = useLoginState();
  loginState.logOut();
  const { status } = useQuery<void>({
    request: curry(validateAccountActivationToken)(match.params.token),
    compare: [match.params.token],
  });

  return React.createElement(ViewByResponseStatusMap[status]);
};

export default ActivateRepresentativeView;
