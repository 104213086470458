import styled, { css } from "style/styled-components";
import breakpoints from "style/breakpoints";

const xxlargeFont = css`
  font-size: ${({ theme }) => theme.font.size.xxlarge};
`;

const xlargeFont = css`
  font-size: ${({ theme }) => theme.font.size.xlarge};
`;

const Headline = styled.h2`
  ${xxlargeFont}
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: 800;
  color: ${({ theme }) => theme.color.font.onLightBackground};
`;

const LargeFontHeadline = styled(Headline)`
  font-size: ${({ theme }) => theme.font.size.large};
`;

const ResizingHeadline = styled(Headline)`
  @media ${breakpoints.phoneMax} {
    ${xlargeFont}
  }
`;

export { LargeFontHeadline, ResizingHeadline };
export default Headline;
