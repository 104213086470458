import React from "react";
import styled from "style/styled-components";
import AccountChangePasswordForm from "components/forms/AccountChangePasswordForm";
import strings from "localisation/strings";
import {
  CenteredContentViewContainer,
  Title,
  VerticalLargeSpacer,
} from "components/generic";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";
import { BoldText } from "components/generic/SimpleText";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.forms.small};
  ${inputContainerAutoZoomPrevetionStyles};
`;

const AccountChangePassword = () => {
  return (
    <CenteredContentViewContainer>
      <Container>
        <Title>{strings("accountChangePasswordScreen.title")}</Title>
        <VerticalLargeSpacer />
        <BoldText>
          {strings("accountChangePasswordScreen.description")}
        </BoldText>
        <VerticalLargeSpacer />
        <AccountChangePasswordForm />
      </Container>
    </CenteredContentViewContainer>
  );
};

export default AccountChangePassword;
