import styled from "style/styled-components";
import { Tag } from "components/generic";

const TertiaryTag = styled(Tag)`
  background-color: ${({ theme }) => theme.color.background.quinary};
  padding: 0 ${({ theme }) => theme.margin.small};
  color: ${({ theme }) => theme.color.foreground.antiPrimary};
`;

export default TertiaryTag;
