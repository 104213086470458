import React from "react";
import { MarketingPurposesPayload } from "api/models/UsersInterface";
import { postMarketingPurposes } from "api/marketingPurposes";
import strings from "localisation/strings";
import SubmitButton from "components/forms/SubmitButton";
import APIErrorResponse from "common/api/models/APIErrorResponse";

interface MarketingPurposesButtonProps {
  style?: object;
  getPayload: () => MarketingPurposesPayload | undefined;
  failedRequestCallback: (error: APIErrorResponse) => void;
  successfulRequestCallback: (response: object) => void;
  className?: string;
}

const MarketingPurposesButton = (props: MarketingPurposesButtonProps) => (
  <SubmitButton<MarketingPurposesPayload, object>
    request={postMarketingPurposes}
    text={strings("marketingPurposesScreen.buttons.submitForm")}
    loadingText={strings("buttons.states.requestInProgress")}
    {...props}
  />
);

export default MarketingPurposesButton;
