import React from "react";
import styled from "style/styled-components";

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.margin.medium} 0`};
`;

const RadioLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.color.foreground.tertiary
      : theme.color.foreground.primary};
`;

const RadioInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Check = styled.span<{ checked: boolean; disabled?: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: ${({ theme }) => theme.margin.medium};
  border-radius: 100%;
  border: 2px solid
    ${({ checked, theme, disabled }) => {
      const color =
        checked && !disabled
          ? theme.color.foreground.action
          : theme.color.foreground.tertiary;

      return color;
    }};
  &::before {
    display: block;
    content: "";
    position: absolute;
    right: 3px;
    top: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ checked, theme, disabled }) => {
      let color = "transparent";
      if (checked && disabled) {
        color = theme.color.foreground.tertiary;
      } else if (checked) {
        color = theme.color.foreground.action;
      }
      return color;
    }};
`;

export interface RadioButtonProps
  extends React.HTMLAttributes<HTMLInputElement> {
  onChange: () => void;
  checked: boolean;
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
}

const RadioButton = ({
  onChange,
  checked,
  label,
  disabled,
}: RadioButtonProps) => (
  <ButtonContainer>
    <RadioLabel disabled={disabled}>
      <RadioInput
        type="radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <Check checked={checked} disabled={!!disabled} />
      {label}
    </RadioLabel>
  </ButtonContainer>
);

export default RadioButton;
