const removePx = (s: string) => s.replace("px", "");

const parseFloatFromPx = (s: string) => parseFloat(removePx(s));

const removePxUnit = (s: string) => parseInt(removePx(s), 10);

const isPercentageUnit = (s: string) => s.endsWith("%");

const parsePercentageValue = (s: string) =>
  parseInt(s.replace("%", ""), 10) / 100;

const getLineHeight = (lengthValue: string, lineHeightModifier: number) =>
  `${removePxUnit(lengthValue) * lineHeightModifier}px`;

const getLineHeightValue = (
  lengthValue: string,
  lineHeightModifier: number,
): number => removePxUnit(lengthValue) * lineHeightModifier;

export {
  isPercentageUnit,
  removePx,
  removePxUnit,
  parseFloatFromPx,
  getLineHeight,
  getLineHeightValue,
  parsePercentageValue,
};
