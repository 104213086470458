import { useState } from "react";
import { History, Location } from "history";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";

type WasNavigationBlockedIndicator = boolean;

const useCustomModal = (
  history: History,
  shouldBlockNavigation: boolean,
  unblockGlobalNavigation?: boolean,
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { unblockNavigation } = useGlobalFormBlock();

  /**
   * Holds the location to which navigation is pending
   * - Navigates to the location if user clicks confirmation button
   */
  const [pendingLocation, setPendingLocation] = useState(history.location);

  const showModal = (nextLocation: Location) => {
    setModalVisible(true);
    setPendingLocation(nextLocation);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (
    nextLocation: Location,
  ): WasNavigationBlockedIndicator => {
    if (shouldBlockNavigation && !modalVisible) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = async () => {
    closeModal();
    if (unblockGlobalNavigation) {
      await unblockNavigation();
    }
    if (pendingLocation) {
      history.push(pendingLocation);
    }
  };

  return {
    closeModal,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    modalVisible,
  };
};

export default useCustomModal;
