import React from "react";
import styled from "style/styled-components";
import { NavLink } from "react-router-dom";

import strings from "localisation/strings";

import { mobileHeaderWidthMax } from "style/breakpoints";
import {
  UnderlineButtonArea,
  UnderlineNavLink,
} from "components/buttons/UnderlineButton";
import { getTopNavRoutes, SingleRoute } from "core/navigation";
import loginState from "state/singletons/loginState";

const activeClassName = "active-top-nav";

const MobileNav = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;

  @media (max-width: ${mobileHeaderWidthMax}px) {
    display: flex;
  }
`;

const DesktopNav = styled(UnderlineButtonArea).attrs(props => ({
  buttonHeight: props.theme.globalComponents.topNavigationButtonHeight,
}))`
  @media (max-width: ${mobileHeaderWidthMax}px) {
    display: none;
  }
`;

const TopNavLink = styled(UnderlineNavLink).attrs(({ theme }) => ({
  color: theme.color.font.onDarkBackground,
  buttonheight: theme.globalComponents.topNavigationButtonHeight,
}))``;

const NavItem = styled(NavLink)`
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  font-weight: 300;
  margin: 0 ${({ theme }) => theme.margin.sideMin};
  padding: 20px 0;
  align-self: flex-start;
  text-decoration: none;
  white-space: nowrap;

  &.${activeClassName} {
    font-family: ${({ theme }) => theme.font.family.firaSans};
    font-weight: 500;
    border-bottom: 4px solid ${({ theme }) => theme.color.foreground.action};
    padding: 20px 0 12px;
    margin-bottom: 4px;
  }
`;

const TopNav = ({ responsive }: { responsive?: boolean }) => {
  const topRoutes = getTopNavRoutes(loginState);

  const renderLink = ({
    path,
    exact,
    name,
    isMobile,
  }: SingleRoute & { isMobile: boolean }) => {
    const linkProps = {
      activeClassName,
      exact,
      key: path,
      to: path,
      children: strings(`tabBar.${name}`),
    };

    return isMobile ? (
      <NavItem {...linkProps} />
    ) : (
      <TopNavLink {...linkProps} />
    );
  };

  return responsive ? (
    <MobileNav>
      {topRoutes.map(
        item => item.path.length > 0 && renderLink({ ...item, isMobile: true }),
      )}
    </MobileNav>
  ) : (
    <DesktopNav>
      {topRoutes.map(
        item =>
          item.path.length > 0 && renderLink({ ...item, isMobile: false }),
      )}
    </DesktopNav>
  );
};

export default TopNav;
