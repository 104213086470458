import AuthedAPI from "api/AuthedAPI";
import RequestOptions from "api/RequestOptions";
import OfferInterface from "api/models/OfferInterface";
import { FilterAPIResponse } from "api/models/FilterInterface";
import ListParams from "api/models/ListParams";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import ListResultType from "api/models/ListResultType";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
  ReviewResponse,
} from "api/models/ReviewInterface";

const offersAPI = new AuthedAPI({});

const getOffers = (
  body: SimpleSearchInterface | undefined,
  businessId: string | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postListJson<ListResultType<OfferInterface>>({
    queryParams,
    options,
    body,
    path: businessId
      ? `offers/bo/offers/search/${businessId}`
      : "offers/bp/offers/search",
  });

const getCuratorPostsFromBusiness = (
  body: SimpleSearchInterface | undefined,
  businessId: string | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postListJson<ListResultType<OfferInterface>>({
    queryParams,
    options,
    body,
    path: `offers/bp/curator/offers/search/${businessId}`,
  });

const getAdminOffers = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postListJson<ListResultType<OfferInterface>>({
    queryParams,
    options,
    body,
    path: "offers/bo/offers/search",
  });

const getCuratorOffers = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postListJson<ListResultType<OfferInterface>>({
    queryParams,
    options,
    body,
    path: "offers/bp/curator/offers/search",
  });

const getOffer = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.getJson<OfferInterface>({
    queryParams,
    options,
    path: `offers/bp/offers/${id}`,
  });

const getOfferAdmin = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.getJson<OfferInterface>({
    queryParams,
    options,
    path: `offers/bo/offers/${id}`,
  });

const getOfferCurator = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) => {
  return offersAPI.getJson<OfferInterface>({
    queryParams,
    options,
    path: `offers/bp/curator/offers/${id}`,
  });
};

const createDraftOffer = (
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body,
    path: "offers/bp/offers/drafts",
  });

const createDraftOfferAdmin = (
  body: OfferInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "offers/bo/offers/drafts",
  });

const createDraftOfferCurator = (
  body: OfferInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "offers/bp/curator/offers/drafts",
  });

const updateDraftOffer = (
  offersId: string,
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.putJson<OfferInterface>({
    body,
    options,
    path: `offers/bp/offers/drafts/${offersId}`,
  });

const updateDraftOfferAdmin = (
  offersId: string,
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.putJson<OfferInterface>({
    body,
    options,
    path: `offers/bo/offers/drafts/${offersId}`,
  });

const updateDraftOfferCurator = (
  offersId: string,
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.putJson<OfferInterface>({
    body,
    options,
    path: `offers/bp/curator/offers/drafts/${offersId}`,
  });

const createOffer = (
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body,
    path: "offers/bp/offers",
  });

const createOfferAdmin = (
  body: OfferInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "offers/bo/offers",
  });

const createOfferCurator = (
  body: OfferInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "offers/bp/curator/offers",
  });

const updateOffer = (
  offersId: string,
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.putJson<OfferInterface>({
    body,
    options,
    path: `offers/bp/offers/${offersId}`,
  });

const updateOfferAdmin = (
  offersId: string,
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.putJson<OfferInterface>({
    body,
    options,
    path: `offers/bo/offers/${offersId}`,
  });

const updateOfferCurator = (
  offersId: string,
  body: OfferInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.putJson<OfferInterface>({
    body,
    options,
    path: `offers/bp/curator/offers/${offersId}`,
  });

const getOffersFilters = (
  body: Partial<FilterAPIResponse>,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<FilterAPIResponse>({
    options,
    body,
    path: businessId
      ? `offers/bo/offers/filters/${businessId}`
      : "offers/bp/offers/filters",
  });

const getReviewsForOffer = (
  offerId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `offers/bp/offers/${offerId}/reviews`,
  });

const getReviewsForOfferAdmin = (
  offerId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  offersAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `offers/bo/offers/${offerId}/reviews`,
  });

const saveReplyToReviewForOffer = (
  offerId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `offers/bp/offers/${offerId}/reviews/${reviewId}/replies`,
  });

const saveReplyToReviewForOfferAdmin = (
  offerId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `offers/bo/offers/${offerId}/reviews/${reviewId}/replies`,
  });

const deleteReplyToReviewForOffer = (
  offerId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  offersAPI.deleteEntity<void>({
    options,
    path: `offers/bp/offers/${offerId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const deleteReplyToReviewForOfferAdmin = (
  offerId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  offersAPI.deleteEntity<void>({
    options,
    path: `offers/bo/offers/${offerId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const updateOfferStatusInList = (
  id: string,
  status: string,
  options: RequestOptions | undefined,
) =>
  offersAPI.postJson<OfferInterface>({
    options,
    body: {},
    path: `offers/bo/offers/${id}/${status}`,
  });

export {
  offersAPI,
  getOffer,
  getOfferAdmin,
  getOfferCurator,
  getCuratorPostsFromBusiness,
  getCuratorOffers,
  getOffers,
  createDraftOffer,
  createDraftOfferAdmin,
  createDraftOfferCurator,
  updateDraftOffer,
  updateDraftOfferAdmin,
  updateDraftOfferCurator,
  createOffer,
  createOfferAdmin,
  createOfferCurator,
  updateOffer,
  updateOfferAdmin,
  updateOfferCurator,
  getOffersFilters,
  getReviewsForOffer,
  getReviewsForOfferAdmin,
  getAdminOffers,
  updateOfferStatusInList,
  saveReplyToReviewForOffer,
  saveReplyToReviewForOfferAdmin,
  deleteReplyToReviewForOffer,
  deleteReplyToReviewForOfferAdmin,
};
