import Plan, { BusinessPlanHistory } from "api/models/Plan";
import useQuery from "common/hooks/useQuery";
import { AbortableRequest } from "common/api/AbortableRequest";
import { isLoading } from "utils";

interface UseCurrentBusinessPlanParams {
  businessId: string | null;
  request: AbortableRequest<BusinessPlanHistory>;
}

const isCurrentPlan = (plan: Plan) => {
  const hasNoSetExpiryDate = plan.validity.dateTimeTill === null;
  return hasNoSetExpiryDate;
};

const getCurrentlyActivePlan = (plans: Plan[]) => plans.find(isCurrentPlan);

const useCurrentBusinessPlan = ({
  request,
  businessId,
}: UseCurrentBusinessPlanParams) => {
  const { result: businessPlans, status: fetchCurrentPlanStatus } = useQuery({
    request,
    compare: [businessId],
  });

  const currentPlanExpiring =
    businessPlans && businessPlans.currentPlan
      ? !isCurrentPlan(businessPlans.currentPlan)
      : false;

  return {
    loading: isLoading(fetchCurrentPlanStatus),
    currentPlan: businessPlans
      ? currentPlanExpiring
        ? getCurrentlyActivePlan(businessPlans.planHistory)
        : businessPlans.currentPlan
      : null,
  };
};

export default useCurrentBusinessPlan;
