import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { defaultSelectLabel } from "components/table/DataTable/constants";
import { toggleCategory } from "components/forms/CategoryDropdown/helpers";
import { CheckboxListContainer } from "components/table/DataTable/Fields/components";
import { PointerContainer } from "components/generic";

import CategoryDropdown from "components/forms/CategoryDropdown";
import TagInterface from "api/models/TagInterface";
import strings from "localisation/strings";
import SelectComponent from "components/forms/Select";
import styled from "style/styled-components";

const SelectContainer = styled(PointerContainer)`
  width: 90%;
`;

interface Props {
  onChange: (options: any) => void;
  type: string;
  options?: TagInterface[];
  checkedOptions?: string[];
}

interface State {
  isOpened: boolean;
  label?: string;
  checkedOptions: string[];
  openedOptions: string[];
  categories: TagInterface[];
}

class CategoriesList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpened: false,
      checkedOptions: props.checkedOptions || [],
      openedOptions: [],
      categories: props.options || [],
    };
  }

  // react-onclickoutside
  handleClickOutside = () => {
    this.setState({ isOpened: false });
  };

  setCategories = (categories: TagInterface[]) => this.setState({ categories });

  toggleCheckboxWindow = () =>
    this.setState({ isOpened: !this.state.isOpened });

  toggleCheckbox = (option: TagInterface) =>
    this.setState(
      {
        checkedOptions: toggleCategory(
          option,
          this.state.categories,
          this.state.checkedOptions,
        ),
      },
      () => {
        this.props.onChange(this.state.checkedOptions);
      },
    );

  isChecked = (id: string) => {
    if (this.state.checkedOptions.includes(id)) {
      return true;
    }

    // we check, if this is a parent category and all children are selected
    const category = this.state.categories.find(c => c.id === id);
    if (category && category.tagSuccessors && category.tagSuccessors.length) {
      return !!category.tagSuccessors.find(child =>
        this.state.checkedOptions.includes(child.id),
      );
    }

    return false;
  };

  isPartiallySelected = (category: TagInterface) => {
    if (category.tagSuccessors) {
      const tags = category.tagSuccessors.map(t => t.id);

      const selectedChildren = this.state.checkedOptions.filter(c =>
        tags.includes(c),
      );

      if (
        selectedChildren.length > 0 &&
        selectedChildren.length !== tags.length
      ) {
        return true;
      }
    }

    return false;
  };

  render() {
    const { type, options } = this.props;
    const { isOpened, checkedOptions } = this.state;
    const items = checkedOptions.length;

    const categoriesSelectedLabel = strings("tableView.categoriesSelected");
    const label =
      items > 0 ? `${items} ${categoriesSelectedLabel}` : defaultSelectLabel();

    return (
      <CheckboxListContainer>
        <SelectContainer
          onClick={
            this.state.categories && this.state.categories.length
              ? this.toggleCheckboxWindow
              : () => {}
          }
        >
          <SelectComponent
            onChange={() => {}}
            options={[]}
            classNamePrefix="businessRoleSelect"
            placeholder={label}
            disabled
            small
          />
        </SelectContainer>
        <CategoryDropdown
          isPartiallySelected={this.isPartiallySelected}
          type={type}
          isOpened={isOpened}
          setCategories={this.setCategories}
          toggleCheckbox={this.toggleCheckbox}
          isChecked={this.isChecked}
          options={options}
        />
      </CheckboxListContainer>
    );
  }
}

export default onClickOutside(CategoriesList);
