import React from "react";
import strings from "localisation/strings";
import ActivationPageLayout from "components/activation/UserActivationPageLayout";
import RegistrationConfirmationForm from "components/forms/ConfirmationForm/RegistrationConfirmationForm";

const BusinessUserActivationConfirmationView = () => (
  <ActivationPageLayout title={strings("activateAccountScreen.title")}>
    <RegistrationConfirmationForm />
  </ActivationPageLayout>
);

export default BusinessUserActivationConfirmationView;
