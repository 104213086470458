/*
 wrapper function for generating testIds. Can use environment config to modify output
 */
const testId = (id: string) => {
  return { "data-cy": id.toLowerCase() };
};

const checkBoxTestId = (id: string) => testId(`checbkox-${id}`);

const textInputTestId = (id: string) => testId(`textinput-${id}`);

const numberInputTestId = (id: string) => testId(`numberinput-${id}`);

const actionButtonTestId = (id: string) => testId(`actionbtn-${id}`);

const dropZoneTestId = () => testId("dropzone");

const chartTestId = (id: string) => testId(`chart-${id}`);

const typeButtonTestId = (id: string) => testId(`typebtn-${id}`);

const topSubNavButtonTestId = (id: string) => testId(`topsubnav-${id}`);

const richTextTestId = (id: string) => testId(`richtext-${id}`);

const editableRichTextTestId = (id: string) =>
  testId(`richtext-${id}-editable`);

const listTestId = (id: string) => testId(`list-${id}`);

const chatConversationsTestId = () => testId("chat-conversations");

const chatConversationItemTestId = (id: string) =>
  testId(`chat-conversations-item-${id}`);

const chatMessagesTestId = () => testId(`chat-messages`);

export {
  checkBoxTestId,
  textInputTestId,
  numberInputTestId,
  actionButtonTestId,
  dropZoneTestId,
  chartTestId,
  typeButtonTestId,
  topSubNavButtonTestId,
  richTextTestId,
  listTestId,
  editableRichTextTestId,
  chatConversationsTestId,
  chatConversationItemTestId,
  chatMessagesTestId,
};
