import React from "react";
import DateTimeRange from "api/models/DateTimeRange";
import strings from "localisation/strings";
import { moment } from "localisation/i18n";
import DashboardDateInput from "components/forms/DashboardDateInput";
import styled, { isMobile } from "style/styled-components";
import useWindowDimensions from "hooks/useWindowDimensions";
import {
  getRestrictedDateRangeByDateFrom,
  getRestrictedDateRangeByDateTill,
  setDateToEndOfDay,
  setDateToStartOfDay,
} from "components/helpers/dateSelectionUtilities";
import DateTimePicker from "components/inputs/DateTimePicker";

export interface DateRangeProps {
  dateTimeRange: DateTimeRange;
  onDateRangeChange?: (newDateRange: DateTimeRange) => void;
}

const DatePickersContainer = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.color.border.inputBorder};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  ${({ isMobileDevice, theme }) =>
    isMobileDevice
      ? `
      max-width: 303px;
      margin-bottom: ${theme.margin.medium};
      `
      : ""}
`;

const Separator = styled.div`
  height: 37px;
  width: 1px;
  background: rgba(151, 151, 151, 0.3);
  margin: 9px 0;
  display: inline-block;
`;

const DATE_FORMAT = "d.MM";

const DashboardDateRange = ({
  dateTimeRange: { dateTimeFrom, dateTimeTill },
  onDateRangeChange,
}: DateRangeProps) => {
  const onSelectStart = (d?: Date | null) => {
    if (onDateRangeChange) {
      const isDateDiffMoreThanOneYear =
        moment(dateTimeTill).diff(moment(d || undefined), "years", true) > 1;
      const oneYearAfterStartDate = moment(d || undefined).add(1, "year");

      onDateRangeChange(
        getRestrictedDateRangeByDateFrom({
          dateTimeFrom: setDateToStartOfDay(d || undefined),
          dateTimeTill: isDateDiffMoreThanOneYear
            ? setDateToStartOfDay(oneYearAfterStartDate)
            : dateTimeTill,
        }),
      );
    }
  };

  const onSelectEnd = (d?: Date | null) => {
    if (onDateRangeChange) {
      const isDateDiffMoreThanOneYear =
        moment(d || undefined).diff(moment(dateTimeFrom), "years", true) > 1;
      const oneYearBeforeEndDate = moment(d || undefined).subtract(1, "year");
      onDateRangeChange(
        getRestrictedDateRangeByDateTill({
          dateTimeFrom: isDateDiffMoreThanOneYear
            ? setDateToStartOfDay(oneYearBeforeEndDate)
            : dateTimeFrom,
          dateTimeTill: setDateToEndOfDay(d || undefined),
        }),
      );
    }
  };

  const selectedStartDate = dateTimeFrom ? new Date(dateTimeFrom) : undefined;
  const selectedEndDate = dateTimeTill ? new Date(dateTimeTill) : undefined;
  const currentDate = new Date();

  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);

  return (
    <DatePickersContainer isMobileDevice={isMobileDevice}>
      <DateTimePicker
        selected={selectedStartDate}
        customInput={
          <DashboardDateInput
            label={strings("dashboardScreen.dateFilter.from")}
          />
        }
        dateFormat={DATE_FORMAT}
        onChange={onSelectStart}
        maxDate={currentDate}
      />
      <Separator />
      <DateTimePicker
        selected={selectedEndDate}
        customInput={
          <DashboardDateInput
            label={strings("dashboardScreen.dateFilter.to")}
          />
        }
        dateFormat={DATE_FORMAT}
        onChange={onSelectEnd}
        minDate={selectedStartDate}
        maxDate={currentDate}
      />
    </DatePickersContainer>
  );
};

export default DashboardDateRange;
