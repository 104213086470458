import React from "react";
import styled, { css } from "style/styled-components";
import { InlineContainer } from "components/generic";
import ActionButton from "components/buttons/ActionButton";
import strings from "localisation/strings";
import breakpoints from "style/breakpoints";

interface WithBackButtonProps {
  /* Removes unnecessary margins from the back button */
  isActionAreaOneElement?: boolean;
  isBackButtonEnabled: boolean;
  onBackButtonClicked: () => void;
}

export const withBackButton = <TProps extends object>(
  ActionAreaComponent: React.ComponentType<TProps>,
) =>
  class ActionAreaWithBackButton extends React.PureComponent<
    TProps & WithBackButtonProps
  > {
    render() {
      const {
        isActionAreaOneElement,
        isBackButtonEnabled,
        onBackButtonClicked,
        ...componentProps
      } = this.props;

      return (
        <>
          {isBackButtonEnabled ? (
            <InlineContainer spacedOut>
              <ActionArea position="left">
                <ActionButton
                  alone={isActionAreaOneElement}
                  text={strings("generic.back")}
                  onClick={onBackButtonClicked}
                />
              </ActionArea>
              <ActionAreaComponent {...(componentProps as TProps)} />
            </InlineContainer>
          ) : (
            <ActionAreaComponent {...(componentProps as TProps)} />
          )}
        </>
      );
    }
  };

interface PositionProps {
  position?: "left" | "right" | "center";
  sticky?: boolean;
}

const leftPositionCssProps = css`
  justify-content: flex-start;
`;
const rightPositionCssProps = css`
  justify-content: flex-end;
`;
const centerPositionCssProps = css`
  justify-content: center;
`;
const stickyCssProps = css`
  padding-top: ${({ theme }) => theme.margin.large};
  padding-bottom: ${({ theme }) => theme.margin.large};
  margin-bottom: -${({ theme }) => theme.margin.xlarge};
  position: sticky;
  bottom: 0;
  z-index: 2;

  &:before {
    content: "";
    background: #fff;
    position: absolute;
    z-index: -1;

    top: 0;
    bottom: 0;

    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    border-top: 1px solid ${({ theme }) => theme.color.border.light};
  }
`;
const stickyChidrenCssProps = css`
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
  }
`;

const ActionArea = styled.div<PositionProps>`
  display: flex;
  margin-top: ${({ theme }) => theme.margin.large};
  ${({ position }) => position === "center" && centerPositionCssProps}
  ${({ position }) => position === "left" && leftPositionCssProps}
  ${({ position = "right" }) => position === "right" && rightPositionCssProps};
  ${({ sticky = false }) => sticky && stickyCssProps};

  @media ${breakpoints.phoneMax} {
    justify-content: center;

    ${({ sticky = false }) => sticky && stickyChidrenCssProps};
  }
  `;

export default ActionArea;
