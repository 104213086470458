import React from "react";
import {
  ColumnModel,
  DataTableItem,
} from "components/table/DataTable/interfaces";
import { CenteredText, SimpleText } from "components/generic";
import { renderCellByType } from "components/table/DataTable/helpers";
import { TableRow, TableCell } from "components/table/DataTable/components";
import strings from "localisation/strings";

export interface Props {
  columns: ColumnModel[];
  data: DataTableItem[];
  getChange: (body: any, id: string) => void;
  refreshTable: () => void;
  isCategoriesView?: boolean;
  isRepresentativesView?: boolean;
  onClickEdit?: (id: string) => void;
  onClickCopy?: (id: string) => void;
  onClickAdd?: (id: string) => void;
}

const Content = (props: Props) => {
  const {
    columns,
    data,
    getChange,
    refreshTable,
    onClickEdit,
    onClickCopy,
    onClickAdd,
    isCategoriesView,
    isRepresentativesView,
  } = props;

  return (
    <tbody
      data-role="admin-table-body"
      data-present={data.length ? "true" : "false"}
    >
      {data.map((row: DataTableItem, index) => (
        <TableRow key={`${row.id}-${index}`}>
          {columns.map(col => (
            <TableCell key={`${row.id}-${col.id}`}>
              {renderCellByType(
                row,
                col,
                getChange,
                refreshTable,
                onClickEdit,
                onClickCopy,
                onClickAdd,
                isCategoriesView,
                isRepresentativesView,
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
      {!data.length && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <CenteredText>
              <SimpleText>{strings("tableView.noContent")}</SimpleText>
            </CenteredText>
          </TableCell>
        </TableRow>
      )}
    </tbody>
  );
};

export default Content;
