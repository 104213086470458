import ModifiedBy from "api/models/ModifiedBy";
import DateTimeRange from "api/models/DateTimeRange";
import SortingInterface from "api/models/SortingInterface";
import { MapCurationPayload } from "api/models/Plan";

export interface RepresentativeInterface {
  createdDateTime: string;
  currentLoggedIn: boolean;
  email: string;
  imageUrl: string | null;
  lastModifiedBy: ModifiedBy;
  modifiedDateTime: string;
  firstName?: string;
  phone?: string;
  phoneCode?: string;
  role: string;
  status: UserStatuses;
  lastName?: string;
  userId: string;
  mapCuration: MapCurationPayload[];
}

export interface ExtendedRepresentativeInterface
  extends RepresentativeInterface {
  fullName: string;
  fullPhone: string;
  businessId: string;
  businessName: string;
}

export interface AddRepresentativePayload {
  currentLoggedIn?: boolean;
  imageUrl?: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  status?: UserStatuses;
  userId?: string;
}

export enum UserStatuses {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

export const sampleRepresentativeForTable: ExtendedRepresentativeInterface = {
  userId: "fewfwfef efe fewf",
  fullName: "Bill Cates",
  email: "billi@microsoft.com",
  imageUrl: "string",
  fullPhone: "+37235363722",
  businessName: "Shit Co",
  businessId: "43tg4t5hb5y323rgb5yt",
  role: "BUS_ADMIN",
  status: UserStatuses.ACTIVE,
  currentLoggedIn: true,
  lastModifiedBy: { firstName: "ModifiedBy", lastName: "fredt" },
  createdDateTime: "2019-09-13T15:11:38.784938+03:00",
  modifiedDateTime: "2019-09-13T15:11:38.783936+03:00",
  mapCuration: [
    {
      mapId: "12z3435z43e243zxc",
      status: "ACTIVE",
    },
  ],
};

export interface RepresentativeRole {
  id: string;
  name: string;
}

export interface RepresentativesFilters {
  dateTimeFilter: DateTimeRange;
  representativeRoleFilter: RepresentativeRole[];
  termsMapFilter: {
    businessName: string;
    fullName: string;
    fullPhone: string;
    email: string;
  };
  userStatusFilter: {
    id: UserStatuses;
    name: string;
  }[];
}

export interface RepresentativesSort {
  sortRules: SortingInterface[];
}

export interface AssignCuratorPayload {
  email: string;
  businessId: string;
}
