import styled, { css } from "style/styled-components";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";

const focusedStyle = css`
  border: ${({ theme }) => `1px solid ${theme.color.foreground.primary}`};
  z-index: 1;

  ${inputContainerAutoZoomPrevetionStyles};
`;

const erroredStyle = css`
  border: ${({ theme }) => `1px solid ${theme.color.foreground.action}`};
`;

export const FieldAligner = styled.div`
  margin: 0 1px;
  padding: 0 ${({ theme }) => theme.margin.large};
`;

export interface FieldContainerProps {
  focused?: boolean;
  errored?: boolean;
}

const FieldContainer = styled.div<FieldContainerProps>`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => `1px solid ${theme.color.foreground.tertiary}`};
  height: 56px;
  ${({ focused }) => focused && focusedStyle};
  ${({ errored }) => errored && erroredStyle};
`;

export default FieldContainer;
