import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import PostInterface from "api/models/PostInterface";
import BaseListItem from "components/lists/item/BaseListItem";
import { ListItemProps } from "components/lists/item/BaseListItemComponents";
import DarkSecondaryText from "components/lists/item/DarkSecondaryText";
import { HorizontalMediumSpacer } from "components/generic/Spacer";
import { TWO_LINE_TAGS_MAX_DEPTH } from "constants/ListItemConstants";
import PictureTypes from "api/models/PictureTypes";

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface PostListItemProps extends ListItemProps {
  post: PostInterface;
}

const PostListItem = ({ post, onClick }: PostListItemProps) => {
  const {
    dateTimeRange,
    id,
    lastModifiedBy,
    location,
    modifiedDateTime,
    pictures,
    priceMinMax,
    status,
    title,
    tags,
    smartService,
    jobOffer,
  } = post;
  const image = pictures
    ? pictures.find(item => item.type === PictureTypes.main)
    : undefined;
  const imageUrl = image ? image.url : undefined;

  return (
    <BaseListItem
      dateTimeRange={dateTimeRange}
      id={id}
      imageUrl={imageUrl}
      lastModifiedBy={lastModifiedBy}
      location={location}
      modifiedDateTime={modifiedDateTime}
      onClick={onClick}
      priceMinMax={priceMinMax}
      status={status}
      tags={tags}
      title={title}
      contentType={"POST"}
      tagsMaxDepthFromTop={TWO_LINE_TAGS_MAX_DEPTH}
    >
      {(smartService || jobOffer) && (
        <ChildrenContainer>
          {smartService && (
            <DarkSecondaryText>
              {strings("postListScreen.smartService")}
            </DarkSecondaryText>
          )}
          {smartService && jobOffer && (
            <>
              ,
              <HorizontalMediumSpacer />
            </>
          )}
          {jobOffer && (
            <DarkSecondaryText>
              {strings("postListScreen.jobOffer")}
            </DarkSecondaryText>
          )}
        </ChildrenContainer>
      )}
    </BaseListItem>
  );
};

export default PostListItem;
