import React from "react";
import styled from "style/styled-components";
import EditButton from "components/buttons/EditButton";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LevelFourCategoryButtons = ({
  parentId,
  questionId,
  onClickEdit,
}: {
  parentId: string;
  questionId?: string;
  onClickEdit: (parentId: string, questionId?: string, isSub?: boolean) => void;
}) => {
  return (
    <Container>
      <EditButton onClick={() => onClickEdit(parentId, questionId, true)} />
    </Container>
  );
};

export default LevelFourCategoryButtons;
