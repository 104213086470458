import { AddressLocation } from "api/models/Location";
import AddressAutocomplete from "components/forms/FormFields/AddressAutocomplete/AddressAutocomplete";
import PinLocationOnMap, {
  PinnedDescriptionProps,
} from "components/forms/FormFields/AddressAutocomplete/PinLocationOnMap";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  div {
    align-items: flex-start;
  }
`;

interface AddressAutocompleteProps {
  value?: string;
  errors: string[];
  onSelect: (location: AddressLocation) => void;
  onChange: () => void;
}

interface AddressPickerProps {
  autocompleteProps: AddressAutocompleteProps;
  pinnedDescriptionFieldProps: PinnedDescriptionProps;
  location?: AddressLocation;
  showPinLocationOnMapSuggestion?: boolean;
}

const AddressPicker = ({
  autocompleteProps,
  pinnedDescriptionFieldProps,
  location,
  showPinLocationOnMapSuggestion,
}: AddressPickerProps) => {
  const isPinned =
    location && location.pinned && location.pinned.toString() === "true";
  const [isMapDisplayed, setDisplayMap] = useState(isPinned);

  const displayMap = () => {
    setDisplayMap(true);
  };

  return (
    <Container>
      {!isMapDisplayed && (
        <AddressAutocomplete
          {...autocompleteProps}
          displayMap={displayMap}
          showPinLocationOnMapSuggestion={showPinLocationOnMapSuggestion}
        />
      )}
      {isMapDisplayed && (
        <PinLocationOnMap
          {...pinnedDescriptionFieldProps}
          location={location}
        />
      )}
    </Container>
  );
};

export default AddressPicker;
