import { useRef, useEffect } from "react";

const useComponentMountedStatus = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
};

export default useComponentMountedStatus;
