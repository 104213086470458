import React, { ReactNode } from "react";
import { Subscribe } from "unstated";

import LoginState from "state/LoginState";

const LoggedOut = ({ children }: { children: ReactNode }) => (
  <Subscribe to={[LoginState]}>
    {(loginState: LoginState) =>
      loginState.isUserLoggedIn() ? null : children
    }
  </Subscribe>
);

export default LoggedOut;
