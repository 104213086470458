import React from "react";
import Location from "api/models/Location";
import DarkSecondaryText from "components/lists/item/DarkSecondaryText";

export interface PostLocationProps {
  location?: Location;
}

const makeLocationInformationText = ({
  venue,
  street,
  houseNo,
  postalCode,
  city,
}: Location) => {
  const locationInformationTags = [];

  if (venue) {
    locationInformationTags.push(venue);
  }

  if (street && houseNo && postalCode && city) {
    locationInformationTags.push(`${street} ${houseNo}, ${postalCode} ${city}`);
  }

  return locationInformationTags.join(" - ");
};

const ListItemLocation = ({ location }: PostLocationProps) =>
  location ? (
    <DarkSecondaryText>
      {makeLocationInformationText(location)}
    </DarkSecondaryText>
  ) : null;

export default ListItemLocation;
