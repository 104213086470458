import React from "react";
import CKEditor from "components/generic/ckeditor4/ckeditor";
import { PaddedSpacer } from "components/generic/Spacer";
import useLoginState from "hooks/useLoginState";

export interface RichTextEditorProps {
  value?: string;
  onContentChange?: (content: string) => void;
  onReady?: () => void;
  uiLocale?: string;
  config?: any;
  placeholder?: string;
}

const RichTextEditor = (props: RichTextEditorProps) => {
  const {
    onContentChange,
    onReady,
    value,
    uiLocale,
    config,
    placeholder,
  } = props;
  const { hasAdminRights } = useLoginState();
  const adminRights = hasAdminRights();

  const defaultConfig = {
    height: 100,
    placeholder: `${placeholder}`,
    language: uiLocale === "de" ? "de" : "en",
    removePlugins: "elementspath",
    linkShowTargetTab: false,
    linkShowAdvancedTab: false,
    extraAllowedContent: "img{position,top}[src,widht,height,alt,title,style]",
    customConfig: `${process.env.REACT_APP_IMAGESTORAGE_URL}emoji/richtext_custom_config.js`,
    extraPlugins: ["autotag", "blockimagepaste"],
    toolbar: [
      [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "RemoveFormat",
        "-",
        "NumberedList",
        "BulletedList",
        "-",
        "Link",
        "-",
        "Maximize",
      ],
    ],
    isAdmin: adminRights,
    deeplinkProtocol: process.env.REACT_APP_DEEPLINK_PROTOCOL,
  };

  let effectiveConfig = defaultConfig;
  if (config) {
    effectiveConfig = { ...effectiveConfig, ...config };
  }

  return (
    <PaddedSpacer padding="1px">
      <CKEditor
        config={effectiveConfig}
        data={value}
        onChange={(e: any) => {
          if (onContentChange) {
            onContentChange(e.editor.getData());
          }
        }}
        onInstanceReady={() => {
          if (onReady) {
            onReady();
          }
        }}
      />
    </PaddedSpacer>
  );
};

export default RichTextEditor;
