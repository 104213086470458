import React, { useEffect, useContext, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styled, { isMobile } from "style/styled-components";
import { ContentScrollContext } from "components/helpers/ContentScrollPreventer";
import useWindowDimensions from "hooks/useWindowDimensions";
import useConversationsHistory from "components/chat/hooks/useConversationsHistory";
import ChatViewLayout from "views/Chat/ChatViewLayout";
import chatState from "state/singletons/chatState";
import strings from "localisation/strings";
import {
  AbsoluteOverlaySpinner,
  AbsoluteOverlaySpinnerContainer,
} from "components/generic/OverlaySpinner";
import ChatPlaceholder from "components/chat/ChatPlaceholder";
import { makeChatRouteFromParams } from "components/chat/utils";
import ConversationSection from "components/chat/ConversationsSection";
import { MarginlessViewContainer } from "components/generic";
import ChatWelcomePlaceholder from "components/chat/ChatWelcomePlaceholder";
import StartableConversation from "components/chat/ChatMessagesList";
import useChatState from "hooks/useChatState";
import QueryStatus from "common/api/models/QueryStatus";
import useLoginState from "common/hooks/useLoginState";

const ChatBodySection = styled(MarginlessViewContainer)`
  flex-grow: 1;
  overflow: hidden;
`;

export interface ChatPageParams {
  page: string;
  conversationId?: string;
  branchId?: string;
  businessId?: string;
}

const EmptyChatComponentByHistoryStatus = (status: QueryStatus) => {
  switch (status) {
    case QueryStatus.WAITING:
      return <AbsoluteOverlaySpinner />;
    case QueryStatus.UNDETERMINED:
      return <AbsoluteOverlaySpinner />;
    case QueryStatus.ERROR:
      return (
        <ChatPlaceholder
          message={strings("chat.messagesLoadFailedNotification")}
        />
      );
    case QueryStatus.SUCCESSFUL:
      return (
        <ChatPlaceholder message={strings("chat.emptyChatNotification")} />
      );
    default:
      return null;
  }
};

const ChatPageView = ({
  match,
  history,
}: RouteComponentProps<ChatPageParams>) => {
  const { hasCuratorRights } = useLoginState();
  const curatorRights = hasCuratorRights();
  const { enableScrollPrevention, disableScrollPrevention } = useContext(
    ContentScrollContext,
  );
  const isMobileDevice = isMobile(useWindowDimensions().width);

  const { branchId = "", conversationId = "", businessId = "" } = match.params;
  const {
    branches,
    hasMultipleBranches,
    isLoading,
    historyStatus,
    hasMore,
    nextConversationsPage,
  } = useConversationsHistory(branchId, businessId);
  const { conversationsIds } = useChatState();

  const handleConversationSelected = useCallback(
    (selectedConversationId: string) => {
      const nextRoute = makeChatRouteFromParams({
        branchId,
        businessId,
        conversationId: selectedConversationId,
        curatorRights,
      });

      history.push(nextRoute);
    },
    [branchId, businessId, history.push],
  );
  const handleBusinessBranchFilterSelected = useCallback(
    (selectedBranchId: string) => {
      const nextRoute = makeChatRouteFromParams({
        businessId,
        branchId: selectedBranchId,
        curatorRights,
      });
      history.push(nextRoute);
    },
    [businessId, history.push],
  );

  const isLargeScreenDevice = !isMobileDevice;
  const isConversationSelected = !!conversationId;

  useEffect(() => {
    if (isLargeScreenDevice || isConversationSelected) {
      enableScrollPrevention();
      return disableScrollPrevention;
    }
  }, [
    isLargeScreenDevice,
    isConversationSelected,
    enableScrollPrevention,
    disableScrollPrevention,
  ]);

  useEffect(() => {
    chatState.setActive(true);

    return () => {
      chatState.setActive(false);
      chatState.reset();
    };
  }, [chatState]);

  if (conversationsIds.length === 0) {
    return (
      <ChatViewLayout>
        <AbsoluteOverlaySpinnerContainer>
          {EmptyChatComponentByHistoryStatus(historyStatus)}
        </AbsoluteOverlaySpinnerContainer>
      </ChatViewLayout>
    );
  }

  if (isLargeScreenDevice) {
    return (
      <ChatViewLayout>
        <ConversationSection
          selectedBranchId={branchId}
          selectedConversationId={conversationId}
          businessBranches={branches}
          isBranchesSelectDisplayed={hasMultipleBranches}
          isConversationsListLoading={isLoading}
          isMoreConversationsAvailable={hasMore}
          onBusinessBranchSelected={handleBusinessBranchFilterSelected}
          onConversationSelected={handleConversationSelected}
          onMoreConversationsRequested={nextConversationsPage}
        />

        <ChatBodySection>
          {isConversationSelected ? (
            <StartableConversation
              conversationId={conversationId}
              businessId={businessId}
            />
          ) : (
            <ChatWelcomePlaceholder />
          )}
        </ChatBodySection>
      </ChatViewLayout>
    );
  }

  return (
    <ChatViewLayout>
      {isConversationSelected ? (
        <ChatBodySection>
          <StartableConversation
            conversationId={conversationId}
            businessId={businessId}
          />
        </ChatBodySection>
      ) : (
        <ConversationSection
          selectedBranchId={branchId}
          selectedConversationId={conversationId}
          businessBranches={branches}
          isBranchesSelectDisplayed={hasMultipleBranches}
          isConversationsListLoading={isLoading}
          isMoreConversationsAvailable={hasMore}
          onBusinessBranchSelected={handleBusinessBranchFilterSelected}
          onConversationSelected={handleConversationSelected}
          onMoreConversationsRequested={nextConversationsPage}
        />
      )}
    </ChatViewLayout>
  );
};

export default withRouter(ChatPageView);
