import { AddressLocation } from "api/models/Location";
import locationIcon from "assets/icons/location.svg";
import PinnableMapContainer from "components/forms/FormFields/AddressAutocomplete/PinnableMapContainer";
import InlineInput from "components/forms/FormFields/InlineInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import strings from "localisation/strings";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.forms.full};
`;

export interface PinnedDescriptionProps {
  value?: string;
  pinnedDescriptionErrors: string[];
  locationAddressErrors: string[];
  onPinLocation: (location: AddressLocation) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  location?: AddressLocation;
}

const PinLocationOnMap = ({
  value,
  pinnedDescriptionErrors,
  locationAddressErrors,
  onPinLocation,
  onChange,
  location,
}: PinnedDescriptionProps) => {
  return (
    <InlineInputGroup
      icon={locationIcon}
      placeholder={strings("detailedScreen.location.address")}
    >
      <Container>
        <PinnableMapContainer
          onPinLocation={onPinLocation}
          location={location}
          errors={locationAddressErrors}
        />
        <InlineInput
          type="text"
          errors={pinnedDescriptionErrors}
          placeholder={strings("detailedScreen.locationPlaceholder")}
          value={value}
          onChange={onChange}
          data-cy="map-location-input"
        />
      </Container>
    </InlineInputGroup>
  );
};
export default PinLocationOnMap;
