import React, { useLayoutEffect, useRef } from "react";
import styled from "style/styled-components";

const TagArea = styled.div`
  overflow: hidden;
  height: 20px;
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: 0 10px;
  margin-right: ${({ theme }) => theme.margin.xsmall};
  margin-bottom: ${({ theme }) => theme.margin.xsmall};
`;

const TagText = styled.span`
  font-size: ${({ theme }) => theme.font.size.small};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: bold;
  line-height: 20px;
`;

const Tag = ({
  children,
  onOverflow,
  maxDepthFromTop,
  ...rest
}: {
  children: any;
  onOverflow?: (isOverflow: boolean) => void;
  maxDepthFromTop?: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current && onOverflow) {
      if (!maxDepthFromTop) return onOverflow(false);
      const top = ref.current.offsetTop;
      onOverflow(top > maxDepthFromTop);
    }
  });

  return (
    <TagArea {...rest} ref={ref}>
      <TagText>{children}</TagText>
    </TagArea>
  );
};

export default Tag;
