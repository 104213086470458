import styled from "style/styled-components";

import { Title, Headline, SimpleText } from "components/generic";
import ActionButton from "components/buttons/ActionButton";

const lineHeight = "28px";

export const TextScreenContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.section`
  max-width: ${({ theme }) => theme.content.sizes.medium};
  width: 100%;
  margin: 0 ${({ theme }) => theme.margin.medium};
  padding: 0 ${({ theme }) => theme.margin.medium};
  align-self: center;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.content.sizes.medium}) {
    padding: 0 ${({ theme }) => theme.margin.small};
  }
`;

export const LeftTitle = styled(Title)`
  text-align: left;
  margin-top: ${({ theme }) => theme.margin.xlarge};
  font-size: ${({ theme }) => theme.font.size.xxlarge};
`;

export const SubTitle = styled(Headline)`
  text-align: left;
  font-size: ${({ theme }) => theme.font.size.xlarge};
`;

export const Text = styled(SimpleText)`
  line-height: ${lineHeight};
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

export const ConfirmButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.margin.xlarge};
  align-self: flex-end;
`;

export const StyledList = styled.ul`
  line-height: ${lineHeight};
  margin-left: ${({ theme }) => theme.margin.xlarge};
`;
