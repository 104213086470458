import UserPrivileges from "constants/UserPrivileges";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

// Map of privileged components' ids corresponding to user privileges
const PRIVILEGES_TO_COMPONENTS_MAP = new Map<UserPrivileges, string[]>([
  [
    UserPrivileges.BUSINESS_PROFILE_BP_READ,
    [
      PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
      PrivilegedComponentsIds.VIEW_BUSINESS_PROFILE,
    ],
  ],
  [UserPrivileges.BUSINESS_PROFILE_BP_WRITE, []],
  [UserPrivileges.BUSINESS_PROFILE_BO_READ, []],
  [UserPrivileges.BUSINESS_PROFILE_BO_WRITE, []],
  [
    UserPrivileges.CONTENT_BP_READ,
    [
      PrivilegedComponentsIds.MENU_OFFERS,
      PrivilegedComponentsIds.MENU_EVENTS,
      PrivilegedComponentsIds.MENU_POSTS,
      PrivilegedComponentsIds.MENU_KEEP_LOCAL,

      PrivilegedComponentsIds.VIEW_OFFERS,
      PrivilegedComponentsIds.VIEW_EVENTS,
      PrivilegedComponentsIds.VIEW_POSTS,
      PrivilegedComponentsIds.VIEW_KEEP_LOCAL,
    ],
  ],
  [UserPrivileges.CONTENT_BP_WRITE, []],
  [
    UserPrivileges.CONTENT_BO_READ,
    [
      PrivilegedComponentsIds.MENU_CONTENT,
      PrivilegedComponentsIds.MENU_BUSINESSES,
      PrivilegedComponentsIds.MENU_OFFERS,
      PrivilegedComponentsIds.MENU_EVENTS,
      PrivilegedComponentsIds.MENU_POSTS,
      PrivilegedComponentsIds.MENU_NEWS,
      PrivilegedComponentsIds.MENU_KEEP_LOCAL,

      PrivilegedComponentsIds.VIEW_DASHBOARD,

      PrivilegedComponentsIds.VIEW_BUSINESSES_ADMIN,
      PrivilegedComponentsIds.VIEW_OFFERS_ADMIN,
      PrivilegedComponentsIds.VIEW_EVENTS_ADMIN,
      PrivilegedComponentsIds.VIEW_POSTS_ADMIN,
      PrivilegedComponentsIds.VIEW_NEWS_ADMIN,
    ],
  ],
  [UserPrivileges.CONTENT_BO_WRITE, []],
  [UserPrivileges.USERS_BP_READ, [PrivilegedComponentsIds.MENU_PERSONAL_DATA]],
  [
    UserPrivileges.USERS_BP_READ_ADMIN,
    [
      PrivilegedComponentsIds.MENU_REPRESENTATIVES,
      PrivilegedComponentsIds.VIEW_REPRESENTATIVES,
    ],
  ],
  [UserPrivileges.USERS_BO_READ, []],
  [
    UserPrivileges.USERS_BO_READ_ADMIN,
    [
      PrivilegedComponentsIds.VIEW_REPRESENTATIVES_ADMIN_TABLE,
      PrivilegedComponentsIds.VIEW_REPRESENTATIVES,
      PrivilegedComponentsIds.MENU_USERS,
      PrivilegedComponentsIds.MENU_USERS_PUBLIC,
      PrivilegedComponentsIds.MENU_USERS_REPRESENTATIVES,
    ],
  ],
  [UserPrivileges.USERS_BO_WRITE, []],
  [
    UserPrivileges.SETTINGS_BP_READ,
    [
      PrivilegedComponentsIds.MENU_SETTINGS,
      PrivilegedComponentsIds.VIEW_PLAN,
      PrivilegedComponentsIds.MENU_PAYMENT_SETTINGS,
      PrivilegedComponentsIds.VIEW_PAYMENTS,
    ],
  ],
  [UserPrivileges.SETTINGS_BP_WRITE, []],
  [
    UserPrivileges.SETTINGS_BO_READ,
    [
      PrivilegedComponentsIds.VIEW_PLAN_ADMIN,
      PrivilegedComponentsIds.MENU_PAYMENT_SETTINGS,
      PrivilegedComponentsIds.VIEW_PAYMENTS_ADMIN,
    ],
  ],
  [UserPrivileges.SETTINGS_BO_WRITE, []],
  [UserPrivileges.ANALYTICS_READ, [PrivilegedComponentsIds.MENU_STATISTICS]],
  [UserPrivileges.ANALYTICS_WRITE, []],
  [
    UserPrivileges.CONFIG_READ,
    [
      PrivilegedComponentsIds.MENU_CONFIGURATION,
      PrivilegedComponentsIds.MENU_CATEGORIES,
      PrivilegedComponentsIds.MENU_PUBLIC_PORTAL_PAGES,
      PrivilegedComponentsIds.MENU_VENUES,
      PrivilegedComponentsIds.MENU_MAPS,

      PrivilegedComponentsIds.VIEW_CATEGORIES_ADMIN,
      PrivilegedComponentsIds.VIEW_MAPS,
    ],
  ],
  [UserPrivileges.CONFIG_WRITE, []],

  [
    UserPrivileges.TYPE_BUSINESS,
    [
      PrivilegedComponentsIds.MENU_DASHBOARD,
      PrivilegedComponentsIds.VIEW_DASHBOARD,
      PrivilegedComponentsIds.MENU_CHANGE_PASSWORD,
    ],
  ],
  [
    UserPrivileges.TYPE_INTERNAL,
    [PrivilegedComponentsIds.MENU_DEFAULT_COMPANY],
  ],
  [UserPrivileges.CHAT_READ, [PrivilegedComponentsIds.VIEW_CHAT]],
  [UserPrivileges.CHAT_WRITE, [PrivilegedComponentsIds.VIEW_CHAT]],
  [
    UserPrivileges.TYPE_CURATOR,
    [
      PrivilegedComponentsIds.VIEW_BUSINESS_PROFILE_CURATOR,
      PrivilegedComponentsIds.VIEW_EVENTS_CURATOR,
      PrivilegedComponentsIds.VIEW_POSTS_CURATOR,
      PrivilegedComponentsIds.VIEW_OFFERS_CURATOR,
      PrivilegedComponentsIds.VIEW_DASHBOARD_CURATOR,
      PrivilegedComponentsIds.VIEW_CONTENT_CURATOR,
      PrivilegedComponentsIds.MENU_CURATOR_BUSINESS_PROFILE,
      PrivilegedComponentsIds.MENU_CURATOR_OFFERS,
      PrivilegedComponentsIds.MENU_CURATOR_POSTS,
      PrivilegedComponentsIds.MENU_CURATOR_EVENTS,
      PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD,
      PrivilegedComponentsIds.MENU_CONTENT_CURATOR,
    ],
  ],
]);

export default PRIVILEGES_TO_COMPONENTS_MAP;
