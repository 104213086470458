import * as React from "react";
import { SimpleText } from "components/generic";
import styled from "styled-components";

const StyledSeparatorChar = styled(SimpleText)`
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  font-weight: 300;
  margin: 0 ${({ theme }) => theme.margin.sideMin};
  padding: 14px 0;
  align-self: flex-start;
  text-decoration: none;
  white-space: nowrap;
`;

const FooterLinkSeparator = () => {
  return <StyledSeparatorChar>|</StyledSeparatorChar>;
};

export default FooterLinkSeparator;
