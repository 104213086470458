import { v4 } from "uuid";
import StoredState from "state/StoredState";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";
import strings from "localisation/strings";

interface NotificationStateType {
  notifications: GlobalNotification[];
}

interface NotificationData {
  type: NotificationTypes;
  title: string;
  message: string;
}

export interface GlobalNotification {
  id: string;
  type: NotificationTypes;
  title: string;
  message: string;
}

export default class NotificationState extends StoredState<
  NotificationStateType
> {
  state: NotificationStateType = {
    notifications: [],
  };

  constructor(storageKey?: string) {
    super(storageKey || "NotificationState", window.localStorage);
  }

  onStateRestored = (restoredState: Partial<NotificationStateType>) => {
    this.setState(restoredState);
  };

  addNotification = (notification: GlobalNotification): Promise<void> =>
    this.setState({
      notifications: [...this.state.notifications, notification],
    });

  addSuccessNotification = (message: string = strings("generic.success")) =>
    this.addNotification(
      this.makeNotification({ message, type: NotificationTypes.success }),
    );

  addErrorNotification = (message: string = strings("generic.error")) =>
    this.addNotification(
      this.makeNotification({ message, type: NotificationTypes.error }),
    );

  setNotification = (
    message: string,
    type: NotificationTypes = NotificationTypes.success,
  ) => this.addNotification(this.makeNotification({ message, type }));

  setNotifications = (notifications: NotificationData[]): Promise<void> =>
    this.setState({
      notifications: notifications.map(this.makeNotification),
    });

  removeNotification = (notificationId: string) =>
    this.setState({
      notifications: this.state.notifications.filter(
        ({ id }) => id !== notificationId,
      ),
    });

  getNotifications = (): GlobalNotification[] => this.state.notifications;

  clearNotifications = (): Promise<void> =>
    this.setState({
      notifications: [],
    });

  private makeNotification = ({
    title,
    message,
    type,
  }: Partial<GlobalNotification>): GlobalNotification =>
    ({
      title,
      type,
      message,
      id: v4(),
    } as GlobalNotification);
}
