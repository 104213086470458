import styled from "style/styled-components";

const RoundedView = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.small};
  border: solid 1px ${({ theme }) => theme.color.foreground.quaternary};
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: ${({ theme }) => theme.margin.large};
`;

export default RoundedView;
