import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import ContentType from "common/api/models/ContentType";
import useLoginState from "hooks/useLoginState";
import { TypeButton, TypeButtonProps } from "components/dashboard/TypeButton";

interface Props {
  contentType: ContentType;
  setContentType: (contentType: ContentType) => void;
}

const Container = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.color.chat.bg.sidebar};
  padding: ${({ theme }) => theme.margin.xlarge}
    ${({ theme }) => theme.margin.large};
`;

const TypeButtonContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.xlarge};
`;

const SidebarTypeButton = ({
  isSelected,
  typeName,
  onClick,
  typeKey,
}: TypeButtonProps) => (
  <TypeButtonContainer>
    <TypeButton
      isSelected={isSelected}
      typeName={typeName}
      onClick={onClick}
      typeKey={typeKey}
    />
  </TypeButtonContainer>
);

const TypeSelectSidebar = ({ contentType, setContentType }: Props) => {
  const updateContentType = (typeToUpdate: ContentType) => {
    setContentType(typeToUpdate);
  };

  const isDefaultCompanyContext = useLoginState().isDefaultBusinessContext();

  return (
    <Container>
      <SidebarTypeButton
        isSelected={contentType === "BUSINESS"}
        typeName={strings("dashboardScreen.contentType.businessProfiles")}
        onClick={() => updateContentType("BUSINESS")}
        typeKey={"BUSINESS"}
      />
      <SidebarTypeButton
        isSelected={contentType === "OFFER"}
        typeName={strings("dashboardScreen.contentType.offers")}
        onClick={() => updateContentType("OFFER")}
        typeKey={"OFFER"}
      />
      <SidebarTypeButton
        isSelected={contentType === "EVENT"}
        typeName={strings("dashboardScreen.contentType.events")}
        onClick={() => updateContentType("EVENT")}
        typeKey={"EVENT"}
      />
      <SidebarTypeButton
        isSelected={contentType === "POST"}
        typeName={strings("dashboardScreen.contentType.posts")}
        onClick={() => updateContentType("POST")}
        typeKey={"POST"}
      />
      {isDefaultCompanyContext && (
        <SidebarTypeButton
          isSelected={contentType === "NEWS"}
          typeName={strings("dashboardScreen.contentType.news")}
          onClick={() => updateContentType("NEWS")}
          typeKey={"NEWS"}
        />
      )}
    </Container>
  );
};

export default TypeSelectSidebar;
