import styled from "style/styled-components";

export const Hint = styled.div`
  font-weight: 300;
  margin-bottom: ${({ theme }) => theme.margin.large};
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.color.foreground.tertiary};
`;

export default Hint;
