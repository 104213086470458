import React from "react";
import Icon from "components/forms/FormFields/Icon";
import bell from "common/assets/icons/bell.svg";
import date from "common/assets/icons/date.svg";
import pushNotification from "common/assets/icons/pushNotification.svg";
import smartServices from "common/assets/icons/smartServices.svg";
import jobs from "common/assets/icons/jobs.svg";

interface Props {
  row: any;
}

const TypeFilterIcons = ({ row }: Props) => {
  return (
    <>
      {row.publishAlert && <Icon backgroundImage={bell} />}
      {row.publishPushNotification && (
        <Icon backgroundImage={pushNotification} />
      )}
      {row.alertToBusiness && <Icon backgroundImage={bell} />}
      {row.topicOfTheWeek && <Icon backgroundImage={date} />}
      {row.smartService && <Icon backgroundImage={smartServices} />}
      {row.jobOffer && <Icon backgroundImage={jobs} />}
    </>
  );
};

export default TypeFilterIcons;
