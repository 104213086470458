import React from "react";
import strings from "localisation/strings";
import CellTypes from "components/table/CellTypes";
import { ColumnModel } from "components/table/DataTable/interfaces";
import { getSort } from "components/table/DataTable/helpers";
import {
  TableRow,
  HeaderCell,
  HeaderSort,
} from "components/table/DataTable/components";

export interface Props {
  columns: ColumnModel[];
  sort?: string;
  setSort: (sort?: string) => void;
}

const Header = (props: Props) => {
  const { columns, sort, setSort } = props;

  return (
    <TableRow>
      {columns.map(column => {
        if (column.type === CellTypes.action) {
          return <HeaderCell key={column.id} />;
        }

        // if explicit sort key value is not provided, sort by column id
        const sortKey = column.sortKey || column.id;
        const showColumnName =
          column.id === "topicOfTheWeek" ||
          (column.type !== CellTypes.alertNotification &&
            column.type !== CellTypes.pushNotification);
        return (
          <HeaderSort
            colWidth={column.width}
            width={column.width}
            key={column.id}
            id={sortKey}
            sort={sort}
            noSort={column.noSort}
            onClick={() =>
              column.noSort ? null : setSort(getSort(sortKey, sort))
            }
            addRightPadding={column.id === "topicOfTheWeek"}
          >
            {showColumnName && strings(`tableView.${column.id}`)}
          </HeaderSort>
        );
      })}
    </TableRow>
  );
};

export default Header;
