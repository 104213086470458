import React, { RefObject } from "react";
import ReactTooltip from "react-tooltip";
import strings from "localisation/strings";
import ActionButton from "components/buttons/ActionButton";
import SimpleText from "components/generic/SimpleText";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import ActionArea from "components/generic/ActionArea";

const ReactTooltipStyled = styled(ReactTooltip)`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.foreground.quaternary};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);

  &.type-dark {
    background-color: ${({ theme }) => theme.color.foreground.antiPrimary};

    &.place-top {
      ::before,
      ::after {
        top: 100%;
        left: 80%;
        border: solid transparent;
      }
      ::after {
        border-width: 5px;
        border-color: transparent transparent
          ${({ theme }) => theme.color.foreground.antiPrimary}
          ${({ theme }) => theme.color.foreground.antiPrimary};
        transform-origin: 0 0;
        transform: rotate(-45deg);
        box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
  &.show {
    opacity: 1;
  }
  @media ${breakpoints.phoneMax} {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);

    ::before,
    ::after {
      content: none;
    }
  }
`;

interface Props {
  tooltipId: string;
  submitButton?: JSX.Element;
  content?: JSX.Element;
  popupMessage?: string;
  position?: "left" | "right" | "center";
}

type TooltipRef = { tooltipRef: null } | null;

const ButtonWrapper = styled.span<{
  hideReactTooltip?: boolean;
}>``;

const TooltipContainer = styled.div`
  max-width: ${({ theme }) => theme.content.sizes.small};
  padding-top: ${({ theme }) => theme.margin.large};
  padding-bottom: ${({ theme }) => theme.margin.large};
`;

const TooltipText = styled(SimpleText)``;

const confirmTooltipOffset = {
  left: 100,
};

class ConfirmTooltip extends React.Component<Props> {
  private tooltip: RefObject<any> = React.createRef<any>();

  hideTooltip = () => {
    const current: TooltipRef = this.tooltip.current;
    current!.tooltipRef = null; // this is needed because react-tooltip only hides after mouse leaves the tooltip
    ReactTooltip.hide();
  };

  render() {
    const {
      tooltipId,
      submitButton,
      content,
      popupMessage,
      position,
    } = this.props;

    return (
      <ReactTooltipStyled
        place="top"
        effect="solid"
        id={tooltipId}
        event="click"
        clickable
        offset={confirmTooltipOffset}
        ref={this.tooltip}
      >
        <TooltipContainer>
          {popupMessage && <TooltipText>{popupMessage}</TooltipText>}
          {content}
          <ActionArea position={position}>
            <ActionButton
              text={strings("buttons.cancel")}
              onClick={this.hideTooltip}
            />
            {submitButton && (
              <ButtonWrapper hideReactTooltip onClick={this.hideTooltip}>
                {submitButton}
              </ButtonWrapper>
            )}
          </ActionArea>
        </TooltipContainer>
      </ReactTooltipStyled>
    );
  }
}

export default ConfirmTooltip;
