import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router";

import styled, { css } from "style/styled-components";
import strings from "localisation/strings";

import { portalCode } from "api/constants";

import useFormFields from "hooks/useFormFields";
import useMutation from "common/hooks/useMutation";
import useLoginState from "hooks/useLoginState";

import TextButton, { InlineTextButton } from "components/buttons/TextButton";
import Checkbox from "components/forms/Checkbox";
import TextField, { TextFieldProps } from "components/forms/TextField";
import { FieldAligner } from "components/forms/FieldContainer";
import SpreadRow from "components/rows/SpreadRow";
import ActionButton from "components/buttons/ActionButton";
import OverlaySpinner from "components/generic/OverlaySpinner";
import SimpleText from "components/generic/SimpleText";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";

import UserPrivileges from "constants/UserPrivileges";
import notificationState from "state/singletons/notificationState";
import { getBusinessAlerts } from "api/posts";
import useComponentDidMount from "hooks/useComponentDidMount";
import { emailRegex } from "common/helpers/validators";
import RecaptchaWidget from "components/forms/RecaptchaWidget";
import { CenteredFieldMessage } from "components/forms/RegistrationForm/components";
import {
  adminPostLogin,
  adminRecaptchaNeeded,
  postLogin,
  recaptchaNeeded,
} from "api/session";
import {
  OnboardingState,
  RecaptchaNeededPayload,
  RecaptchaNeededResultType,
} from "api/models/UsersInterface";
import BackToBliggitButton from "components/buttons/BackToBliggitButton";
import { routeNames } from "core/navigation";
import { ReactComponent as ShowPasswordIcon } from "assets/icons/openeye.svg";
import { ReactComponent as HidePasswordIcon } from "assets/icons/closeeye.svg";

const mobileLoginOptions = css`
  flex-direction: column-reverse;
  height: 140px;
  justify-content: space-around;
`;

const LoginOptions = styled(SpreadRow)<{ mobile?: boolean }>`
  align-items: center;
  ${({ mobile }) => (mobile ? mobileLoginOptions : "")}
`;

const LoginContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "auto")};
  ${inputContainerAutoZoomPrevetionStyles};
`;

const CenteredText = styled(SimpleText)`
  text-align: center;
  margin: ${({ theme }) => theme.margin.medium} 0;
`;

const LoginButton = styled(ActionButton)`
  margin-top: 20px;
  margin-bottom: ${({ theme }) => theme.margin.large};
  align-self: center;
`;

const PasswordToggleButton = styled.button`
  position: absolute;
  top: 0%;
  right: 0;
  z-index: 2147483657;
  padding: 0 5px;
  transform: translate(0, 70%);
  background-color: white;
  margin-right: 2px;
`;

const PasswordToggleContainer = styled.div`
  position: relative;
  isolation: isolate;
`;

interface LoginFormProps extends RouteComponentProps {
  mobile?: boolean;
  onDone?: () => void;
  isAdminLogin?: boolean;
  width?: string;
}

//  I tried creating a special file for this component
//  but it's got alot of compile error especially importing files

export const PasswordToggleField = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <PasswordToggleContainer>
      <TextField type={showPassword ? "text" : "password"} {...props} />
      <PasswordToggleButton
        onClick={e => {
          e.preventDefault();
          setShowPassword(!showPassword);
        }}
      >
        {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </PasswordToggleButton>
    </PasswordToggleContainer>
  );
};

const LoginForm = ({
  onDone,
  history,
  isAdminLogin,
  mobile,
  width,
}: LoginFormProps) => {
  const {
    values,
    errors,
    parseErrors,
    getTextFieldProps,
    getCheckboxFieldProps,
    focusField,
    onChange,
  } = useFormFields({
    translationScope: "loginForm.fields",
    textFields: {
      email: "",
      password: "",
    },
    hiddenFields: {
      portalCode,
      recaptcha: "",
    },
    checkboxFields: {
      rememberMe: true,
    },
  });

  useComponentDidMount(() => {
    focusField("email");
    checkIfRecaptchaIsNeeded(values.email.trim());
  });

  const loginRequest = isAdminLogin ? adminPostLogin : postLogin;
  const recaptchaNeededRequest = isAdminLogin
    ? adminRecaptchaNeeded
    : recaptchaNeeded;

  const { makeRequest: tryLogin, status: loginStatus } = useMutation(
    loginRequest,
  );
  const { makeRequest: checkBusinessAlerts } = useMutation(getBusinessAlerts);
  const { makeRequest: isRecaptchaNeeded } = useMutation<
    RecaptchaNeededPayload,
    RecaptchaNeededResultType
  >(recaptchaNeededRequest);

  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false);
  const [recaptchaTimestamp, setRecaptchaTimestamp] = useState<Date>(
    new Date(),
  );

  useEffect(() => {
    checkIfRecaptchaIsNeeded(values.email.trim());
  }, [values.email]);

  const isSubmitting = loginStatus === "WAITING";

  const loginState = useLoginState();
  const location = useLocation();

  const logIn = async () => {
    const valuesWithTrimmedEmail = {
      ...values,
      email: values.email.trim(),
    };

    const response = await tryLogin(valuesWithTrimmedEmail);
    const { from } = location.state || { from: undefined };
    setRecaptchaTimestamp(new Date());

    checkIfRecaptchaIsNeeded(values.email.trim());
    if (response && response.error) {
      if (response.error && response.error.detail) {
        notificationState.addErrorNotification(response.error.detail);
      }
      return parseErrors(response.error);
    }

    if (response && response.result && !response.error) {
      loginState.setCorrespondingStorage(values.rememberMe);
      await loginState.logIn(response.result);
      const {
        privileges,
        profileCompleted,
        profilePublished,
        onboardingState,
      } = response.result;
      if (from && from.pathname) {
        goTo(from.pathname);
      } else {
        goTo(
          getRedirectTo(
            privileges,
            profileCompleted,
            profilePublished,
            onboardingState,
          ),
        );
      }
    }
  };

  const getRedirectTo = (
    privileges: UserPrivileges[],
    profileCompleted: boolean,
    profilePublished: boolean,
    onboardingState: OnboardingState,
  ): string => {
    if (privileges.includes(UserPrivileges.TYPE_INTERNAL)) {
      return "/admin/inhalt/partnerprofil";
    }
    if (privileges.includes(UserPrivileges.TYPE_CURATOR)) {
      return "/inhalt/partnerprofil";
    }
    if (profileCompleted) {
      return profilePublished ? "/cockpit" : "/partnerprofil";
    }

    if (!privileges.includes(UserPrivileges.SETTINGS_BP_WRITE)) {
      return routeNames.WizardStep.Error;
    }

    if (onboardingState.termsAccepted && !onboardingState.paymentDataProvided) {
      return routeNames.WizardStep.AddPayment;
    }

    return routeNames.WizardStep.First;
  };

  const checkIfRecaptchaIsNeeded = (email: string) => {
    if (emailRegex.test(email)) {
      isRecaptchaNeeded({ email }).then(response => {
        if (response && response.result) {
          setRecaptchaEnabled(response.result.verificationNeeded);
        }
      });
    } else {
      setRecaptchaEnabled(false);
    }
  };

  const onTokenChange = (token: string | null) => {
    onChange("recaptcha", token);
  };

  const showSystemAlert = async () => {
    if (loginState.isUserLoggedIn() && loginState.getProfileCompleted()) {
      const response = await checkBusinessAlerts({});

      if (response && response.result) {
        notificationState.setNotifications(
          response.result
            .filter(({ description, title }) => description || title)
            .map(({ description, title }) => ({
              title: title as string,
              message: description as string,
              type: NotificationTypes.system,
            })),
        );
      }
    }
  };

  const logInAndShowMessages = async () => {
    await logIn();
    showSystemAlert();
  };

  const goTo = (path: string) => {
    if (onDone) onDone();
    history.push({
      pathname: path,
      state: { recoveryEmail: values.email.trim() },
    });
  };

  return (
    <LoginContainer width={width}>
      <TextField
        {...getTextFieldProps("email")}
        onEnter={() => focusField("password")}
      />

      <PasswordToggleField
        {...getTextFieldProps("password")}
        onEnter={logInAndShowMessages}
      />

      <FieldAligner>
        <Checkbox {...getCheckboxFieldProps("rememberMe")} />
      </FieldAligner>
      {recaptchaEnabled && (
        <>
          <RecaptchaWidget
            onChange={onTokenChange}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
            timestamp={recaptchaTimestamp}
          />
          <CenteredFieldMessage>{errors.recaptcha}</CenteredFieldMessage>
        </>
      )}
      <LoginOptions mobile={mobile}>
        <TextButton
          onClick={() =>
            goTo(isAdminLogin ? "/admin/wiederherstellen" : "/wiederherstellen")
          }
          text={strings("loginForm.forgotPassword")}
        />
        <LoginButton
          text={
            isSubmitting
              ? strings("buttons.states.requestInProgress")
              : strings("buttons.logIn")
          }
          disabled={isSubmitting}
          onClick={logInAndShowMessages}
        />
      </LoginOptions>
      {!mobile && !isAdminLogin && (
        <CenteredText>
          {`${strings("loginForm.newCustomer")} `}
          <InlineTextButton
            onClick={() => goTo("/registrieren")}
            text={strings("loginForm.startHere")}
          />
        </CenteredText>
      )}
      <BackToBliggitButton />
      {isSubmitting && <OverlaySpinner />}
    </LoginContainer>
  );
};

export default withRouter(LoginForm);
