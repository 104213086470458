import { useState, useEffect } from "react";
import chatState from "state/singletons/chatState";

const useChatState = () => {
  const [, update] = useState(Date.now());

  const handleChatStateChange = () => {
    update(Date.now());
  };

  useEffect(() => {
    chatState.subscribe(handleChatStateChange);
    return () => {
      chatState.unsubscribe(handleChatStateChange);
    };
  }, []);

  return chatState.state;
};

export default useChatState;
