import React, { useEffect } from "react";
import strings from "localisation/strings";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import { updateBusinessPlanAdmin } from "api/businessProfiles";
import Plan, { PlanTypes } from "api/models/Plan";
import { generatePath } from "react-router";
import useMutation from "common/hooks/useMutation";
import { routeNames } from "core/navigation";
import PlanChangeConfirmationForm, {
  PlanChangeValues,
} from "views/BusinessSettings/PlanSelection/forms/PlanChangeConfirmationForm";
import BusinessSettingsAdminSidebar from "views/BusinessSettings/BusinessSettingsAdminSidebar";
import useNotificationState from "hooks/useNotification";
import useSelectedBusinessId from "hooks/useSelectedBusinessId";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import { isLoading, isFinished, isFailed } from "utils";
import { RouteComponentProps } from "core/types";

interface BusinessSettingsPlanChangeConfirmationState {
  selectedPlan?: Plan;
}

const BusinessSettingsPlanChangeConfirmationAdmin = ({
  history,
  location: { state: { selectedPlan } = { selectedPlan: undefined } },
}: RouteComponentProps<BusinessSettingsPlanChangeConfirmationState>) => {
  const {
    error: updateBusinessPlanError,
    status: updateBusinessPlanStatus,
    makeRequest: makeUpdateBusinessPlanRequest,
  } = useMutation(updateBusinessPlanAdmin);
  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const {
    unblockNavigation,
    state: { isNavigationBlocked },
  } = useGlobalFormBlock();

  const businessId = useSelectedBusinessId();
  const handleFormSubmitted = (values: PlanChangeValues) => {
    if (selectedPlan && businessId) {
      makeUpdateBusinessPlanRequest({
        businessId,
        planId: selectedPlan.planId,
        packagePrice: values.packagePrice,
      });
    }
  };
  useEffect(() => {
    if (
      businessId &&
      !isNavigationBlocked &&
      isFinished(updateBusinessPlanStatus)
    ) {
      const planSettingsAdminPath = generatePath(
        routeNames.BusinessSettings.PlanAdmin,
        { businessId: businessId as string },
      );
      history.push(planSettingsAdminPath);
    }
  }, [updateBusinessPlanStatus, isNavigationBlocked, businessId]);

  useEffect(() => {
    if (isFinished(updateBusinessPlanStatus)) {
      addSuccessNotification(strings("generic.success"));
      unblockNavigation();
    }

    if (isFailed(updateBusinessPlanStatus)) {
      addErrorNotification(
        updateBusinessPlanError && updateBusinessPlanError.detail,
      );
    }
  }, [updateBusinessPlanStatus, businessId]);

  useEffect(() => {
    if (!selectedPlan && businessId) {
      const planSettingsAdminPath = generatePath(
        routeNames.BusinessSettings.PlanChangeAdmin,
        { businessId: businessId as string },
      );
      history.push(planSettingsAdminPath);
    }
  }, [selectedPlan, businessId]);

  return (
    <BusinessSettingsLayout
      sidebar={<BusinessSettingsAdminSidebar />}
      title={strings("businessSettings.planChangeConfirmation.title")}
    >
      {selectedPlan && (
        <PlanChangeConfirmationForm
          submittable={!isLoading(updateBusinessPlanStatus)}
          customPriceSpecifiable={selectedPlan.planType === PlanTypes.custom}
          selectedPlan={selectedPlan}
          onBackClick={history.goBack}
          onSubmit={handleFormSubmitted}
        />
      )}
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsPlanChangeConfirmationAdmin;
