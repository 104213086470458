import React from "react";
import noMessagesIllustration from "assets/icons/chatNoMessagesIllustration.svg";
import { CenteredContentViewContainer } from "components/generic";
import styled from "style/styled-components";

const FullHeightCenteredContainer = styled(CenteredContentViewContainer)`
  flex-grow: 1;
  justify-content: center;
`;

const StyledArticle = styled.article`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

interface Props {
  message: string;
}

const ChatPlaceholder = ({ message }: Props) => (
  <FullHeightCenteredContainer>
    <img src={noMessagesIllustration} alt={message} />

    <StyledArticle>{message}</StyledArticle>
  </FullHeightCenteredContainer>
);

export default ChatPlaceholder;
