import React, { Component } from "react";
import OfferInterface from "api/models/OfferInterface";
import List from "components/lists/List";
import OfferListItem from "components/lists/offerList/OfferListItem";

export interface OfferListProps {
  emptyIndicator?: string;
  emptyIndicatable?: boolean;
  offers: OfferInterface[];
  onItemClick: (offerId: string) => void;
}

class OfferList extends Component<OfferListProps> {
  render() {
    const {
      offers,
      onItemClick,
      emptyIndicatable,
      emptyIndicator,
    } = this.props;
    return (
      <List<OfferInterface>
        emptyIndicator={emptyIndicator}
        emptyIndicatable={emptyIndicatable}
        data={offers}
        renderItem={(offer, index) => (
          <OfferListItem key={index} offer={offer} onClick={onItemClick} />
        )}
      />
    );
  }
}

export default OfferList;
