import React from "react";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import EditButton from "components/buttons/EditButton";
import CopyButton from "components/buttons/CopyButton";
import DeleteButton from "components/buttons/DeleteButton";
import ListItemStatus from "components/lists/item/ListItemStatus";
import PublicationStatuses from "api/models/PublicationStatuses";
import { UserStatuses } from "api/models/RepresentativeInterface";
import { COPY } from "constants/strings";

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoints.tabletMax} {
    margin-top: ${({ theme }) => theme.margin.medium};
  }

  @media ${breakpoints.phoneMax} {
    align-items: flex-end;
    flex-direction: row;
    height: 45px;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

export const Buttons = styled.div`
  display: flex;

  @media ${breakpoints.phoneMax} {
    order: 2;
    justify-content: space-between;
  }
`;

interface ListItemActionProps {
  id: string;
  isMobile: boolean;
  onClick: (id: string) => void;
  status: PublicationStatuses | UserStatuses;
  deleteAction?: (id: string) => void;
  isDeleteActionDisabled?: boolean;
  toggleDetails: () => void;
  isOpened?: boolean;
}

const ListItemActions = ({
  isDeleteActionDisabled,
  deleteAction,
  id,
  onClick,
  status,
}: ListItemActionProps) => (
  <Actions>
    <Buttons>
      <EditButton onClick={() => onClick(id)} />
      {deleteAction ? (
        <DeleteButton
          onClick={() => deleteAction(id)}
          id={id}
          isDisabled={isDeleteActionDisabled}
        />
      ) : (
        <CopyButton onClick={() => onClick(`${COPY}?id=${id}`)} />
      )}
    </Buttons>
    <ListItemStatus status={status} />
  </Actions>
);

export default ListItemActions;
