import { createGlobalStyle, css } from "style/styled-components";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: "";
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* END OF RESET.CSS */

  button,
  input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    background-color: transparent;
  }

  input, textarea {
    outline: 0;
  }

  button:focus {outline:0;}

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: ${({ theme }) => theme.font.family.firaSans};
    font-size: ${({ theme }) => theme.font.size.medium};
    color: ${({ theme }) => theme.color.font.onLightBackground};
  }
  
  .form-tooltip {
    max-width: 300px;
    color: ${({ theme }) => theme.color.foreground.tertiary};
    background-color: ${({ theme }) => theme.color.foreground.secondaryLight};
    border-radius: ${({ theme }) => theme.border.radius.medium};
    padding: ${({ theme }) => theme.margin.large};
    
    &.place-top {
      margin-top: -${({ theme }) => theme.margin.small};
    }
    &.type-dark {
      background-color: ${({ theme }) => theme.color.foreground.secondaryLight};
    }
    &.show {
      opacity: 1;
      margin-left: 100px;
    }
    ::after {
      display: none;
    }
  }
  
  .button-tooltip {
    color: ${({ theme }) => theme.color.foreground.tertiary};
    background-color: ${({ theme }) => theme.color.foreground.secondaryLight};

    &.place-top {
      margin-top: 0;
    }

    &.type-dark {
      background-color: ${({ theme }) => theme.color.foreground.secondaryLight};

      &.place-top::after {
        border-top-color: ${({ theme }) =>
          theme.color.foreground.secondaryLight};
      }
    }
  }

  // rich text editor
  .rte-has-errors > .cke {
    border: 1px solid ${({ theme }) => theme.color.foreground.action} !important
  }

  // time picker dropdown in business hours should be over rich text editor (comment field)
  .react-datepicker-popper {
    z-index: 10
  }

  // crop area grid
  .crop-area-grid {
    border: 1px solid ${({ theme }) =>
      theme.color.foreground.action} !important;
  }
  .crop-area-grid::before {
    border: 1px solid ${({ theme }) =>
      theme.color.foreground.action} !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .crop-area-grid::after {
    border: 1px solid ${({ theme }) =>
      theme.color.foreground.action} !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
`;

export const inputContainerAutoZoomPrevetionStyles = css`
  // Font 16 prevents Safari mobile autozoom accessibility feature
  font-size: 16px;
  input,
  select,
  input:focus,
  select:focus {
    font-size: inherit;
  }
`;

export default GlobalStyle;
