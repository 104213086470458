import React, { ReactNode } from "react";
import WrappedBackdrop from "components/generic/Backdrop";
import ScreenSizeRestrictingArea from "components/sidebar/ScreenSizeRestrictedArea";
import SideExpandingScreen from "components/sidebar/SideExpandingScreen";
import SidebarTitleRow from "components/sidebar/SidebarTitleRow";
import SidebarContentArea from "components/sidebar/SidebarContentArea";

interface SidebarProps {
  isActive: boolean;
  toggleActive: () => void;
  children?: ReactNode;
  title?: string;
}

const Sidebar = ({ isActive, toggleActive, children, title }: SidebarProps) => (
  <ScreenSizeRestrictingArea isActive={isActive}>
    <WrappedBackdrop isActive={isActive} onClick={toggleActive} />
    <SideExpandingScreen isActive={isActive}>
      {isActive && (
        <SidebarContentArea>
          <SidebarTitleRow title={title} toggleActive={toggleActive} />
          {children}
        </SidebarContentArea>
      )}
    </SideExpandingScreen>
  </ScreenSizeRestrictingArea>
);

export default Sidebar;
