import AuthedAPI from "api/AuthedAPI";
import ListParams from "api/models/ListParams";
import RequestOptions from "api/RequestOptions";
import PostInterface from "api/models/PostInterface";
import { FilterAPIResponse } from "api/models/FilterInterface";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import PostPayloadInterface from "api/models/PostPayloadInterface";
import ListResultType from "api/models/ListResultType";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
  ReviewResponse,
} from "api/models/ReviewInterface";

const postsAPI = new AuthedAPI({});

const getPosts = (
  businessId: string | undefined,
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postListJson<ListResultType<PostInterface>>({
    queryParams,
    options,
    body,
    path: businessId
      ? `posts/bo/posts/search/${businessId}`
      : "posts/bp/posts/search",
  });

const getCuratorPostsFromBusiness = (
  businessId: string | undefined,
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postListJson<ListResultType<PostInterface>>({
    queryParams,
    options,
    body,
    path: `posts/bp/curator/posts/search/${businessId}`,
  });

const getAdminPosts = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postListJson<ListResultType<PostInterface>>({
    queryParams,
    options,
    body,
    path: "posts/bo/posts/search",
  });

const getCuratorPosts = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postListJson<ListResultType<PostInterface>>({
    queryParams,
    options,
    body,
    path: "posts/bp/curator/posts/search",
  });

const getPost = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.getJson<PostInterface>({
    queryParams,
    options,
    path: `posts/bp/posts/${id}`,
  });

const getBusinessAlerts = (
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.getList<PostInterface[]>({
    queryParams,
    options,
    path: "posts/bp/posts/business_alerts",
  });

const getPostAdmin = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.getJson<PostPayloadInterface>({
    queryParams,
    options,
    path: `posts/bo/posts/${id}`,
  });

const getPostCurator = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.getJson<PostPayloadInterface>({
    queryParams,
    options,
    path: `posts/bp/curator/posts/${id}`,
  });

const createDraftPost = (
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    options,
    body,
    path: "posts/bp/posts/drafts",
  });

const createDraftPostAdmin = (
  body: PostPayloadInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "posts/bo/posts/drafts",
  });

const createDraftPostCurator = (
  body: PostPayloadInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "posts/bp/curator/posts/drafts",
  });

const updateDraftPost = (
  id: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.putJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bp/posts/drafts/${id}`,
  });

const updateDraftPostAdmin = (
  id: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.putJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bo/posts/drafts/${id}`,
  });

const updateDraftPostCurator = (
  id: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.putJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bp/curator/posts/drafts/${id}`,
  });

const createPost = (
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    options,
    body,
    path: "posts/bp/posts",
  });

const createPostAdmin = (
  body: PostPayloadInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "posts/bo/posts",
  });

const createPostCurator = (
  body: PostPayloadInterface,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "posts/bp/curator/posts",
  });

const updatePost = (
  id: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.putJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bp/posts/${id}`,
  });

const updatePostAdmin = (
  id: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.putJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bo/posts/${id}`,
  });

const updatePostCurator = (
  id: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.putJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bp/curator/posts/${id}`,
  });

const updatePostStatusInList = (
  id: string,
  status: string,
  body: PostPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<PostPayloadInterface>({
    body,
    options,
    path: `posts/bo/posts/${id}/${status}`,
  });

const getPostsFilters = (
  body: Partial<FilterAPIResponse>,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<FilterAPIResponse>({
    options,
    body,
    path: businessId
      ? `posts/bo/posts/filters/${businessId}`
      : "posts/bp/posts/filters",
  });

const getReviewsForPost = (
  postId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `posts/bp/posts/${postId}/reviews`,
  });

const getReviewsForPostAdmin = (
  postId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  postsAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `posts/bo/posts/${postId}/reviews`,
  });

const saveReplyToReviewForPost = (
  postId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `posts/bp/posts/${postId}/reviews/${reviewId}/replies`,
  });

const saveReplyToReviewForPostAdmin = (
  postId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  postsAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `posts/bo/posts/${postId}/reviews/${reviewId}/replies`,
  });

const deleteReplyToReviewForPost = (
  postId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  postsAPI.deleteEntity<void>({
    options,
    path: `posts/bp/posts/${postId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const deleteReplyToReviewForPostAdmin = (
  postId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  postsAPI.deleteEntity<void>({
    options,
    path: `posts/bo/posts/${postId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

export {
  postsAPI,
  getPosts,
  getPost,
  getPostAdmin,
  getPostCurator,
  createDraftPost,
  createDraftPostAdmin,
  createDraftPostCurator,
  updateDraftPost,
  updateDraftPostAdmin,
  updateDraftPostCurator,
  createPost,
  createPostAdmin,
  createPostCurator,
  updatePost,
  updatePostAdmin,
  updatePostCurator,
  updatePostStatusInList,
  getPostsFilters,
  getReviewsForPost,
  getReviewsForPostAdmin,
  getAdminPosts,
  getBusinessAlerts,
  saveReplyToReviewForPost,
  saveReplyToReviewForPostAdmin,
  deleteReplyToReviewForPost,
  deleteReplyToReviewForPostAdmin,
  getCuratorPosts,
  getCuratorPostsFromBusiness,
};
