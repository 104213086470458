import { routeNames } from "core/navigation";
import { matchPath, generatePath } from "react-router";
import strings from "localisation/strings";

export const isChatSettingsRoute = (path: string) => {
  const matchedPath = matchPath(path, [
    routeNames.ChatSettings,
    routeNames.AdminChatSettings,
    routeNames.CuratorChatSettings,
  ]);
  return !!matchedPath && matchedPath.isExact;
};

export const isChatMessagesRoute = (path: string) => !isChatSettingsRoute(path);

interface ChatRouteParams {
  conversationId?: string;
  businessId?: string;
  branchId?: string;
  curatorRights?: boolean;
}

export const makeChatRouteFromParams = ({
  conversationId,
  businessId,
  branchId,
  curatorRights,
}: ChatRouteParams) => {
  if (conversationId && branchId) {
    const chatBranchConversationPath = curatorRights
      ? routeNames.CuratorChatBranchAndConversationSelected
      : businessId
      ? routeNames.AdminChatBranchAndConversationSelected
      : routeNames.ChatBranchAndConversationSelected;

    return generatePath(chatBranchConversationPath, {
      branchId,
      businessId,
      conversationId,
    });
  }

  if (conversationId) {
    const chatConversationPath = curatorRights
      ? routeNames.CuratorChatConversationSelected
      : businessId
      ? routeNames.AdminChatConversationSelected
      : routeNames.ChatConversationSelected;
    return generatePath(chatConversationPath, {
      conversationId,
      businessId,
    });
  }

  if (branchId) {
    const chatBranchPath = curatorRights
      ? routeNames.CuratorChatBranchSelected
      : businessId
      ? routeNames.AdminChatBranchSelected
      : routeNames.ChatBranchSelected;
    return generatePath(chatBranchPath, {
      branchId,
      businessId,
    });
  }

  const chatHomePath = curatorRights
    ? routeNames.CuratorChat
    : businessId
    ? routeNames.AdminChat
    : routeNames.Chat;
  return generatePath(chatHomePath, { businessId });
};

export const makeChatSettingsRoute = (
  businessId?: string,
  curatorRights?: boolean,
) =>
  generatePath(
    curatorRights
      ? routeNames.CuratorChatSettings
      : businessId
      ? routeNames.AdminChatSettings
      : routeNames.ChatSettings,
    { businessId },
  );

export const makeChatMessagesRoute = (
  businessId?: string,
  curatorRights?: boolean,
) =>
  generatePath(
    curatorRights
      ? routeNames.CuratorChat
      : businessId
      ? routeNames.AdminChat
      : routeNames.Chat,
    {
      businessId,
    },
  );

export const formatSender = (senderFirstName: string, isCurrentUser: boolean) =>
  isCurrentUser ? strings("chat.ownMessageIndicator") : senderFirstName;

export const formatLastMessage = (sender: string, message: string) =>
  sender ? `${sender}: ${message}` : message;
