import styled from "style/styled-components";
import { SmallCloseButton } from "components/buttons/CloseButton";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";

const Container = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.margin.large};
  }
`;

interface BodyProps {
  centered?: boolean;
  type: NotificationTypes;
}

const Body = styled.div<BodyProps>`
  padding: ${({ theme }) => theme.margin.large} ${({ theme }) =>
  theme.margin.xlarge};
  background: ${({ theme, type }) =>
    theme.components.notification.background[type]};
  border: solid 1px
    ${({ theme, type }) => theme.components.notification.foreground[type]};
  border-radius: ${({ theme }) => theme.border.radius.small};
  ${({ centered }) => centered && "text-align: center;"}
  z-index: ${({ theme }) => theme.layers.popover};
`;

interface CloseProps {
  type: NotificationTypes;
}

const Close = styled(SmallCloseButton)<CloseProps>`
  path {
    fill: ${({ theme, type }) =>
      theme.components.notification.foreground[type]};
    stroke: ${({ theme, type }) =>
      theme.components.notification.foreground[type]};
    stroke-width: 5;
  }
`;

const ClosePositioner = styled.div<{ centered?: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.margin.large};
  right: ${({ theme }) => theme.margin.large};
  z-index: ${({ theme }) => theme.layers.popover};

  ${({ centered }) =>
    centered &&
    `
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
    `}
`;

const Toaster = {
  Container,
  Body,
  Close,
  ClosePositioner,
};

export default Toaster;
