import CellTypes from "components/table/CellTypes";
import PublicationStatuses from "api/models/PublicationStatuses";
import strings from "localisation/strings";
import EntityTypes from "api/models/EntityTypes";
import CategoryStatuses from "api/models/CategoryStatuses";
import { ColumnModel } from "components/table/DataTable/interfaces";
import { UserStatuses } from "api/models/RepresentativeInterface";
import { MarketingStatuses } from "api/models/PublicUserInterface";
import OfferTypes from "api/models/OfferTypes";
import { getDateYearsAgo } from "utils";
import NotificationTypes from "api/models/NotificationTypes";

export const filterTypes = {
  searchField: "searchField",
  simpleSelect: "simpleSelect",
  selectList: "selectList",
  categoriesList: "categoriesList",
  dateRange: "dateRange",
  dateRangeWithMonthYear: "dateRangeWithMonthYear",
  type: "type",
  businessType: "businessType",
  boolean: "boolean",
};

export const placeholderDash = "---";

export const defaultSelectLabel = () => strings("tableView.choose");

export const statusFilterOptions = () => [
  {
    label: strings("tableView.options.published"),
    value: PublicationStatuses.published,
  },
  {
    label: strings("tableView.options.unpublished"),
    value: PublicationStatuses.unpublished,
  },
  {
    label: strings("tableView.options.draft"),
    value: PublicationStatuses.draft,
  },
];

export const statusActionOptions = () => [
  {
    label: strings("tableView.options.published"),
    value: PublicationStatuses.published,
  },
  {
    label: strings("tableView.options.unpublished"),
    value: PublicationStatuses.unpublished,
  },
];

const notificationOptions = () => [
  {
    id: "publishAlert",
    label: strings("tableView.notificationsOptions.publishAlert"),
    value: NotificationTypes.publishAlert,
  },
  {
    id: "publishPushNotification",
    label: strings("tableView.notificationsOptions.publishPushNotification"),
    value: NotificationTypes.publishPushNotification,
  },
  {
    id: "alertToBusiness",
    label: strings("tableView.notificationsOptions.alertToBusiness"),
    value: NotificationTypes.alertToBusiness,
  },
  {
    id: "topicOfTheWeek",
    label: strings("tableView.notificationsOptions.topicOfTheWeek"),
    value: NotificationTypes.topicOfTheWeek,
  },
  {
    id: "smartService",
    label: strings("tableView.notificationsOptions.smartService"),
    value: NotificationTypes.smartService,
  },
  {
    id: "jobOffer",
    label: strings("tableView.notificationsOptions.jobOffer"),
    value: NotificationTypes.jobOffer,
  },
];

const adminStatusOptions = () => [
  {
    label: strings("tableView.options.pre_published"),
    value: PublicationStatuses.prePublished,
  },
  ...statusFilterOptions(),
];

const categoryStatusOptions = () => [
  {
    label: strings("tableView.choose"),
    value: "",
  },
  {
    label: strings("tableView.options.active"),
    value: CategoryStatuses.active,
  },
  {
    label: strings("tableView.options.inactive"),
    value: CategoryStatuses.inactive,
  },
];

export const detailCategoryStatusOptions = () => [
  {
    label: strings("tableView.options.active"),
    value: CategoryStatuses.active,
  },
  {
    label: strings("tableView.options.inactive"),
    value: CategoryStatuses.inactive,
  },
];

export const mapStatusOptions = () => [
  {
    label: strings("tableView.options.active"),
    value: CategoryStatuses.active,
  },
  {
    label: strings("tableView.options.inactive"),
    value: CategoryStatuses.inactive,
  },
];

export const typeOptions = () => [
  {
    label: strings("tableView.options.events"),
    value: EntityTypes.events,
  },
  {
    label: strings("tableView.options.news"),
    value: EntityTypes.news,
  },
  {
    label: strings("tableView.options.posts"),
    value: EntityTypes.posts,
  },
  {
    label: strings("tableView.options.offers"),
    value: EntityTypes.offers,
  },
  {
    label: strings("tableView.options.general_category"),
    value: EntityTypes.general,
  },
];

export const businessTypeOptions = () => [
  {
    label: strings("tableView.emptyValuePlaceholder"),
    value: EntityTypes.null,
  },
  {
    label: strings("tableView.options.craftsman"),
    value: EntityTypes.craftsman,
  },
  {
    label: strings("tableView.options.eating_drinking"),
    value: EntityTypes.eating_drinking,
  },
  {
    label: strings("tableView.options.health"),
    value: EntityTypes.health,
  },
  {
    label: strings("tableView.options.hobby"),
    value: EntityTypes.hobby,
  },
  {
    label: strings("tableView.options.home_services"),
    value: EntityTypes.home_services,
  },
  {
    label: strings("tableView.options.professional_services"),
    value: EntityTypes.professional_services,
  },
  {
    label: strings("tableView.options.service"),
    value: EntityTypes.service,
  },
  {
    label: strings("tableView.options.shopping"),
    value: EntityTypes.shopping,
  },
];

export const userStatusOptions = () => [
  {
    label: strings(`statuses.${UserStatuses.ACTIVE}`),
    value: UserStatuses.ACTIVE,
  },
  {
    label: strings(`statuses.${UserStatuses.INACTIVE}`),
    value: UserStatuses.INACTIVE,
  },
  {
    label: strings(`statuses.${UserStatuses.DEACTIVATED}`),
    value: UserStatuses.DEACTIVATED,
  },
];

const userStatusControlsOptions = [
  {
    label: strings(`statuses.${UserStatuses.ACTIVE}`),
    value: UserStatuses.ACTIVE,
  },
  {
    label: strings(`statuses.${UserStatuses.DEACTIVATED}`),
    value: UserStatuses.DEACTIVATED,
  },
];

const publicUserMarketingControlsOptions = () => [
  {
    label: strings("tableView.choose"),
    value: MarketingStatuses.ALL,
  },
  {
    label: strings(`tableView.options.${MarketingStatuses.YES}`),
    value: MarketingStatuses.YES,
  },
  {
    label: strings(`tableView.options.${MarketingStatuses.NO}`),
    value: MarketingStatuses.NO,
  },
];

export const levelOptions = [
  {
    label: `${strings("tableView.lvlTag")} 3`,
    value: 3,
  },
  {
    label: `${strings("tableView.lvlTag")} 4`,
    value: 4,
  },
];

const emptyValuePlaceholder = "tableView.emptyValuePlaceholder";
export const offerTypeOptions = () => [
  {
    label: strings("detailedScreen.promotions"),
    value: OfferTypes.promotion,
  },
  {
    label: strings("detailedScreen.coupons"),
    value: OfferTypes.coupon,
  },
];

// News
export const newsColumns: ColumnModel[] = [
  {
    id: "title",
    type: CellTypes.link,
    defaultValue: emptyValuePlaceholder,
    linkPrefix: "/admin/inhalt/news",
    width: "20%",
    filter: {
      id: "title",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxLength: 100,
    },
  },
  {
    id: "topicOfTheWeek",
    type: CellTypes.topicOfTheWeekNotification,
    width: "7%",
    noSort: true,
    filter: {
      id: "topicOfTheWeek",
      type: filterTypes.boolean,
    },
  },
  {
    id: "tags",
    type: CellTypes.deepList,
    width: "20%",
    noSort: true,
    filter: {
      id: "tagFilter",
      type: filterTypes.categoriesList,
    },
  },
  {
    id: "status",
    type: CellTypes.dropdown,
    width: "10%",
    selectOptions: statusActionOptions(),
    filter: {
      id: "statusFilter",
      type: filterTypes.selectList,
      selectListProducer: statusFilterOptions,
    },
  },
  {
    id: "businessName",
    type: CellTypes.businessName,
    linkPrefix: "/admin/inhalt/partner",
    defaultValue: emptyValuePlaceholder,
    width: "15%",
    filter: {
      id: "businessName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxWidth: "75px",
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    width: "10%",
    filter: {
      id: "modifiedDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "publishPeriod",
    type: CellTypes.dateRange,
    sortKey: "publishDateTime",
    width: "12%",
    dateRangeFrom: "publishDateTime",
    dateRangeTo: "unpublishDateTime",
    filter: {
      id: "publishDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
];

// Events
export const eventsColumns: ColumnModel[] = [
  {
    id: "title",
    type: CellTypes.link,
    defaultValue: emptyValuePlaceholder,
    linkPrefix: "/admin/inhalt/veranstaltungen",
    width: "15%",
    filter: {
      id: "title",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "topicOfTheWeek",
    type: CellTypes.topicOfTheWeekNotification,
    width: "7%",
    noSort: true,
    filter: {
      id: "topicOfTheWeek",
      type: filterTypes.boolean,
    },
  },
  {
    id: "location",
    type: CellTypes.location,
    sortKey: "venue",
    width: "15%",
    filter: {
      id: "venue",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxWidth: "100px",
    },
  },
  {
    id: "tags",
    type: CellTypes.deepList,
    width: "10%",
    noSort: true,
    filter: {
      id: "tagFilter",
      type: filterTypes.categoriesList,
    },
  },
  {
    id: "status",
    type: CellTypes.dropdown,
    width: "10%",
    selectOptions: statusActionOptions(),
    filter: {
      id: "statusFilter",
      type: filterTypes.selectList,
      selectListProducer: statusFilterOptions,
    },
  },
  {
    id: "businessName",
    type: CellTypes.businessName,
    linkPrefix: "/admin/inhalt/partner",
    defaultValue: emptyValuePlaceholder,
    width: "15%",
    filter: {
      id: "businessName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxWidth: "75px",
    },
  },
  {
    id: "eventDateRange",
    type: CellTypes.dateRange,
    sortKey: "dateTimeRange.dateTimeFrom",
    width: "12%",
    dateRangeFrom: "dateTimeRange.dateTimeFrom",
    dateRangeTo: "dateTimeRange.dateTimeTill",
    filter: {
      id: "dateTimeRangeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    width: "10%",
    filter: {
      id: "modifiedDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "publishPeriod",
    type: CellTypes.dateRange,
    sortKey: "publishDateTime",
    width: "12%",
    dateRangeFrom: "publishDateTime",
    dateRangeTo: "unpublishDateTime",
    filter: {
      id: "publishDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
];

export const curatorEventsColumns = eventsColumns.map(column => {
  if (column.id === "title") {
    return { ...column, linkPrefix: "/inhalt/veranstaltungen" };
  }
  if (column.id === "businessName") {
    return { ...column, linkPrefix: "/inhalt/partner" };
  }
  return column;
});

// Business Representatives
export const businessRepresentativesColumns: ColumnModel[] = [
  {
    id: "fullName",
    type: CellTypes.link,
    defaultValue: emptyValuePlaceholder,
    linkPrefix: "/admin/nutzer/partnervertreter/partner",
    sortKey: "fullName",
    width: "15%",
    filter: {
      id: "fullName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "email",
    sortKey: "email",
    width: "15%",
    filter: {
      id: "email",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "fullPhone",
    sortKey: "fullPhone",
    width: "15%",
    filter: {
      id: "fullPhone",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "businessName",
    type: CellTypes.businessName,
    linkPrefix: "/admin/inhalt/partner",
    defaultValue: emptyValuePlaceholder,
    sortKey: "businessName",
    width: "15%",
    filter: {
      id: "businessName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "role",
    sortKey: "role",
    type: CellTypes.dropdown,
    width: "15%",
    filter: {
      id: "representativeRoleFilter", // filter's ids correspond to filters API object key
      type: filterTypes.selectList,
      // selectList: is populated upon separate API call before TableView is rendered,
    },
  },
  {
    id: "status",
    type: CellTypes.dropdown,
    sortKey: "status",
    width: "10%",
    selectOptions: userStatusControlsOptions,
    filter: {
      id: "userStatusFilter",
      type: filterTypes.selectList,
      selectListProducer: userStatusOptions,
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    sortKey: "modifiedDateTime",
    width: "10%",
    filter: {
      id: "dateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "businessRepresentativeActions",
    noSort: true,
    type: CellTypes.action,
    width: "5%",
  },
];

// Public Users
export const publicUsersColumns: ColumnModel[] = [
  {
    id: "fullName",
    type: CellTypes.text,
    defaultValue: emptyValuePlaceholder,
    sortKey: "fullName",
    width: "15%",
    filter: {
      id: "fullName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "email",
    sortKey: "email",
    width: "15%",
    filter: {
      id: "email",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "dateOfBirth",
    type: CellTypes.date,
    sortKey: "dateOfBirth",
    width: "10%",
    filter: {
      id: "dateFilter",
      type: filterTypes.dateRangeWithMonthYear,
      minDate: getDateYearsAgo(110),
      maxDate: new Date(),
    },
  },
  {
    id: "fullAddress",
    type: CellTypes.text,
    defaultValue: emptyValuePlaceholder,
    sortKey: "fullAddress",
    width: "18%",
    filter: {
      id: "fullAddress",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },

  {
    id: "status",
    type: CellTypes.dropdown,
    sortKey: "status",
    width: "8%",
    selectOptions: userStatusControlsOptions,
    filter: {
      id: "userStatusFilter",
      type: filterTypes.selectList,
      selectListProducer: userStatusOptions,
    },
  },
  {
    id: "permissionToAnalyzeData",
    type: CellTypes.text,
    sortKey: "permissionToAnalyzeData",
    width: "8%",
    selectOptions: publicUserMarketingControlsOptions(),
    filter: {
      id: "permissionToAnalyzeData",
      type: filterTypes.simpleSelect,
      selectListProducer: publicUserMarketingControlsOptions,
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    sortKey: "modifiedDateTime",
    width: "12%",
    filter: {
      id: "dateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "publicUserActions",
    noSort: true,
    type: CellTypes.action,
    width: "15%",
  },
];

// Businesses
export const businessColumns: ColumnModel[] = [
  {
    id: "title",
    type: CellTypes.link,
    defaultValue: emptyValuePlaceholder,
    linkBuilder: row => {
      return `/admin/inhalt/partnerprofil/${row.businessId}/${row.id}`;
    },
    sortKey: "title",
    width: "15%",
    filter: {
      id: "nestedTitle",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "topicOfTheWeek",
    type: CellTypes.topicOfTheWeekNotification,
    width: "7%",
    noSort: true,
    filter: {
      id: "topicOfTheWeek",
      type: filterTypes.boolean,
    },
  },
  {
    id: "businessName",
    type: CellTypes.businessName,
    linkPrefix: "/admin/inhalt/partner",
    defaultValue: emptyValuePlaceholder,
    sortKey: "businessName",
    width: "20%",
    filter: {
      id: "businessName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "profileState",
    type: CellTypes.adminStatusChange,
    sortKey: "profileState",
    width: "10%",
    filter: {
      id: "profileStateFilter",
      type: filterTypes.selectList,
      selectListProducer: adminStatusOptions,
    },
  },
  {
    id: "qrCode",
    sortKey: "qrCode",
    width: "20%",
    filter: {
      id: "qrCode",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "createdBy",
    type: CellTypes.createdBy,
    linkPrefix: "/admin/inhalt/partner",
    defaultValue: emptyValuePlaceholder,
    placeholder: "Search",
    width: "10%",
    filter: {
      id: "createdBy",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    sortKey: "modifiedDateTime",
    width: "10%",
    filter: {
      id: "dateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
];

export const curatorBusinessColumns = businessColumns.map(column => {
  if (column.id === "title") {
    return {
      ...column,
      linkBuilder: (row: { businessId: any; id: any }) => {
        return `/inhalt/partnerprofil/${row.id}`;
      },
    };
  }
  if (column.id === "businessName") {
    return { ...column, linkPrefix: "/inhalt/partner" };
  }
  return column;
});

const adminCategoryPrefix = "/admin/konfiguration/kategorien";

// Categories
export const categoriesColumns: ColumnModel[] = [
  {
    id: "tagType",
    noSort: true,
    type: CellTypes.type,
    width: "15%",
    filter: {
      id: "tagTypes",
      type: filterTypes.selectList,
      selectListProducer: typeOptions,
    },
  },
  {
    id: "mainCode",
    noSort: true,
    type: CellTypes.link,
    linkPrefix: adminCategoryPrefix,
    width: "15%",
    filter: {
      id: "mainCode",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxWidth: "50px",
    },
  },
  {
    id: "subCode",
    noSort: true,
    type: CellTypes.link,
    linkPrefix: adminCategoryPrefix,
    width: "15%",
    filter: {
      id: "subCode",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxWidth: "50px",
    },
  },
  {
    id: "businessType",
    noSort: true,
    type: CellTypes.businessType,
    width: "15%",
    filter: {
      id: "businessTypes",
      type: filterTypes.selectList,
      selectListProducer: businessTypeOptions,
    },
  },
  {
    id: "isLevel3",
    noSort: true,
    type: CellTypes.categoryLevel,
    width: "10%",
    filter: {
      id: "isLevel3",
      type: filterTypes.selectList,
      selectList: levelOptions,
    },
  },
  {
    id: "tagStatus",
    noSort: true,
    type: CellTypes.dropdown,
    width: "15%",
    selectOptions: categoryStatusOptions(),
    filter: {
      id: "tagStatus",
      type: filterTypes.simpleSelect,
      selectListProducer: categoryStatusOptions,
    },
  },
  {
    id: "modifiedDateTime",
    noSort: true,
    type: CellTypes.date,
    width: "10%",
    filter: {
      id: "modifiedDateTime",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "buttons",
    noSort: true,
    type: CellTypes.action,
    width: "15%",
  },
];

// Posts
export const postsColumns: ColumnModel[] = [
  {
    id: "title",
    type: CellTypes.link,
    linkPrefix: "/admin/inhalt/beitraege",
    defaultValue: emptyValuePlaceholder,
    width: "20%",
    filter: {
      id: "title",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "type",
    type: CellTypes.dropdown,
    width: "10%",
    selectOptions: notificationOptions(),
    noSort: true,
    filter: {
      id: "typeFilter",
      type: filterTypes.selectList,
      selectListProducer: notificationOptions,
    },
  },
  {
    id: "tags",
    type: CellTypes.deepList,
    successorField: "tagSuccessors",
    width: "20%",
    noSort: true,
    filter: {
      id: "tagFilter",
      type: filterTypes.categoriesList,
    },
  },
  {
    id: "status",
    type: CellTypes.dropdown,
    width: "10%",
    selectOptions: statusActionOptions(),
    filter: {
      id: "statusFilter",
      type: filterTypes.selectList,
      selectListProducer: statusFilterOptions,
    },
  },
  {
    id: "businessName",
    type: CellTypes.businessName,
    linkPrefix: "/admin/inhalt/partner",
    width: "15%",
    defaultValue: emptyValuePlaceholder,
    filter: {
      id: "businessName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
      maxWidth: "75px",
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    width: "8%",
    filter: {
      id: "modifiedDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "publishPeriod",
    type: CellTypes.dateRange,
    sortKey: "publishDateTime",
    dateRangeFrom: "publishDateTime",
    dateRangeTo: "unpublishDateTime",
    width: "12%",
    filter: {
      id: "publishDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
];

export const curatorPostsColumns = postsColumns.map(column => {
  if (column.id === "title") {
    return { ...column, linkPrefix: "/inhalt/beitraege" };
  }
  if (column.id === "businessName") {
    return { ...column, linkPrefix: "/inhalt/partner" };
  }
  return column;
});

// Offers
export const offersColumns: ColumnModel[] = [
  {
    id: "title",
    type: CellTypes.link,
    defaultValue: emptyValuePlaceholder,
    linkPrefix: "/admin/inhalt/angebote",
    sortKey: "title",
    width: "15%",
    filter: {
      id: "nestedTitle",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "topicOfTheWeek",
    type: CellTypes.topicOfTheWeekNotification,
    width: "7%",
    noSort: true,
    filter: {
      id: "topicOfTheWeek",
      type: filterTypes.boolean,
    },
  },
  {
    id: "type",
    type: CellTypes.text,
    selectOptions: offerTypeOptions(),
    sortKey: "type",
    width: "10%",
    filter: {
      id: "offerTypeFilter",
      type: filterTypes.selectList,
      selectListProducer: offerTypeOptions,
    },
  },
  {
    id: "tags",
    type: CellTypes.deepList,
    width: "15%",
    noSort: true,
    filter: {
      id: "tagFilter",
      type: filterTypes.categoriesList,
    },
  },
  {
    id: "status",
    type: CellTypes.dropdown,
    selectOptions: statusActionOptions(),
    width: "10%",
    filter: {
      id: "statusFilter",
      type: filterTypes.selectList,
      selectListProducer: statusFilterOptions,
    },
  },
  {
    id: "businessName",
    type: CellTypes.businessName,
    linkPrefix: "/admin/inhalt/partner",
    sortKey: "businessName",
    width: "15%",
    defaultValue: emptyValuePlaceholder,
    filter: {
      id: "businessName",
      type: filterTypes.searchField,
      placeholder: strings("adminTable.search"),
    },
  },
  {
    id: "modifiedDateTime",
    type: CellTypes.date,
    width: "10%",
    sortKey: "modifiedDateTime",
    filter: {
      id: "modifiedDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
  {
    id: "publishPeriod",
    type: CellTypes.dateRange,
    sortKey: "publishDateTime",
    width: "12%",
    dateRangeFrom: "publishDateTime",
    dateRangeTo: "unpublishDateTime",
    filter: {
      id: "publishDateTimeFilter",
      type: filterTypes.dateRange,
    },
  },
];

export const curatorOffersColumns = offersColumns.map(column => {
  if (column.id === "title") {
    return { ...column, linkPrefix: "/inhalt/angebote" };
  }
  if (column.id === "businessName") {
    return { ...column, linkPrefix: "/inhalt/partner" };
  }
  return column;
});

// Maps
export const mapsColumns: ColumnModel[] = [
  {
    id: "mapName",
    type: CellTypes.link,
    linkPrefix: "/admin/konfiguration/karten",
    width: "25%",
  },
  {
    id: "mapStatus",
    type: CellTypes.dropdown,
    width: "15%",
    selectOptions: mapStatusOptions(),
  },
  {
    id: "createdDateTime",
    type: CellTypes.date,
    width: "15%",
  },
];

export const defaultCategoryQuery = {
  tagType: undefined,
  businessType: undefined,
  mainCode: undefined,
  question: undefined,
  subCode: undefined,
  isLevel3: undefined,
  tagStatus: undefined,
  dateTimeFrom: undefined,
  dateTimeTo: undefined,
};
