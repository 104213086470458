import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { stringify } from "qs";
import strings from "localisation/strings";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { CenteredContentViewContainer } from "components/generic";
import {
  LocationInput,
  Separator,
  TextWithIcon,
} from "components/forms/FormFields";
import websiteIcon from "common/assets/icons/card.svg";
import emailIcon from "common/assets/icons/mail.svg";
import userIcon from "common/assets/icons/user.svg";
import phoneIcon from "common/assets/icons/contact.svg";
import briefcaseIcon from "common/assets/icons/briefcase-black.svg";
import { LargeFontHeadline } from "components/generic/Headline";
import {
  getAdminImprintByBusinessId,
  getImprintByLoggedInBusiness,
  getImprintByLoggedInBusinessAdmin,
} from "api/businessProfiles";
import useQuery from "common/hooks/useQuery";
import { curry } from "ramda";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import useLoginState from "hooks/useLoginState";
import Checkbox from "components/forms/Checkbox";
import ActionButton from "components/buttons/ActionButton";
import breakpoints from "style/breakpoints";
import useRepresentativesList from "views/BusinessRepresentatives/useRepresentativesListView";
import {
  deleteRepresentativeById,
  getRepresentatives,
  getRepresentativesByBusinessId,
} from "api/businessRepresentatives";
import CustomModal from "components/modal/CustomModal";
import Location from "api/models/Location";
import useNotificationState from "hooks/useNotification";
import { ImprintInformation } from "api/models/BusinessUserProfileInterface";
import { transferDataToKeepLocalRegistrationPage } from "api/keepLocal";
import FormSubtitle from "components/forms/FormSubtitle";
import { isEqual } from "lodash";
import { isKeepLocalEnabled } from "utils";

const FormContainer = styled.div`
  margin-top: ${({ theme }) => theme.margin.xlarge};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.phoneMax} {
    width: inherit;
    padding-left: 0;
  }
`;
const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.forms.max};

  margin-top: ${({ theme }) => theme.margin.xlarge};
  justify-content: center;
  align-items: center;
  input {
    padding: 6px 10px;
  }

  @media ${breakpoints.phoneMax} {
    margin-top: 0;
    padding: 25%;
  }
`;
const ConsentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;

  @media (min-height: 900px) and ${breakpoints.desktopMin} {
    position: absolute;
    bottom: 0;
    margin-bottom: 4%;
  }

  width: 100%;
`;

const ConsentMesageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.forms.large};
  margin-bottom: 20px;
`;

const ContinueButton = styled(ActionButton)`
  width: ${({ theme }) => theme.forms.large};
`;

const StyledFormSubtitle = styled(FormSubtitle)`
  font-weight: bold;
  position: relative;
  margin-right: 80%;
  @media ${breakpoints.phoneMax} {
    margin-right: 70%;
  }
`;

const validateImprintToFormDataIsEqual = (
  currentImprint: any,
  formDataToImprint: any,
) => {
  const keysToValidate = [
    "legalName",
    "retailerName",
    "website",
    "location",
    "contactPersonEmail",
  ];
  let isEqual = true;
  keysToValidate.forEach(key => {
    if (currentImprint[key] !== formDataToImprint[key]) {
      isEqual = false;
    }
  });
  return isEqual;
};

interface Props {
  match: {
    params: {
      id: string;
      businessId?: string;
    };
  };
  location: {
    search: string;
  };
  history: History;
}
const textWithIconWidth = "12rem";
const totalFormRowWidth = "100%";
interface KeepLocalFormInterface {
  legalName: string | undefined;
  retailerName: string | undefined;
  website: string | undefined;
  location: Location;
  email: string | undefined;
  contactPersonEmail: string | undefined;
  contactPersonPhone: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
}

const KeepLocal = (props: Props) => {
  const {
    history,
    match: {
      params: { businessId: providedBusinessId },
    },
  } = props;

  const { getBusinessId, hasAdminRights } = useLoginState();
  const { addSuccessNotification } = useNotificationState();

  const [continueButtonDisabled, setContinueButtonDisabled] = useState<boolean>(
    true,
  );
  const [consentCheckboxChecked, setConsentCheckboxChecked] = useState<boolean>(
    false,
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [imprintData, setimprintData] = useState<
    ImprintInformation | undefined
  >();
  const [keepLocalFormData, setKeepLocalFormData] = useState<
    KeepLocalFormInterface
  >({
    legalName: "",
    retailerName: "",
    website: "",
    location: {},
    email: "",
    contactPersonEmail: "",
    contactPersonPhone: "",
    firstName: "",
    lastName: "",
  });

  const adminRights = hasAdminRights();
  const contextBusinessId = getBusinessId();
  const businessId = adminRights
    ? providedBusinessId || contextBusinessId
    : undefined;

  const adminImprintRequest = businessId
    ? curry(getAdminImprintByBusinessId)(businessId)
    : getImprintByLoggedInBusinessAdmin;
  const imprintRequest = adminRights
    ? adminImprintRequest
    : getImprintByLoggedInBusiness;
  const { result: imprint, status: imprintStatus } = useQuery({
    request: imprintRequest,
  });

  const makeRepresentativeRoute = (representativeId: string) =>
    `/partnervertreter/${representativeId}`;

  const makeRepresentativeAdminRoute = (
    businessId: string,
    representativeId: string,
  ) =>
    `/admin/inhalt/partner/${businessId}/partnervertreter/${representativeId}`;

  const { representatives } = useRepresentativesList({
    makeRepresentativeRoute: businessId
      ? curry(makeRepresentativeAdminRoute)(businessId)
      : makeRepresentativeRoute,
    isAdmin: adminRights ? true : false,
    navigate: history.forward,
    listRequest: businessId
      ? curry(getRepresentativesByBusinessId)(businessId)
      : getRepresentatives,
    deleteRequest: deleteRepresentativeById,
    responseSize: 999,
  });
  const firstActiveRepresentativeByCreationDate = representatives
    .sort(
      (a, b) =>
        new Date(a.createdDateTime).valueOf() -
        new Date(b.createdDateTime).valueOf(),
    )
    .find(rep => rep.status === "ACTIVE");

  const { makeRequest: transferDataToKeepLocalRequest } = useMutation(
    curry(transferDataToKeepLocalRegistrationPage),
  );

  useEffect(() => {
    if (imprintStatus === QueryStatus.SUCCESSFUL) {
      setimprintData(imprint);
    }
  }, [imprintStatus]);
  useEffect(() => {
    setKeepLocalFormData({
      legalName: imprintData?.legalName,
      retailerName: imprintData?.businessName,
      website: imprintData?.website,
      location: {
        postalCode: imprintData?.postalCode,
        city: imprintData?.city,
        street: imprintData?.street,
        houseNo: imprintData?.houseNo,
        venue: imprintData?.venue,
      },
      email: firstActiveRepresentativeByCreationDate?.email,
      contactPersonEmail: firstActiveRepresentativeByCreationDate?.email,
      contactPersonPhone: firstActiveRepresentativeByCreationDate?.phoneCode
        ? (
            firstActiveRepresentativeByCreationDate?.phoneCode +
            firstActiveRepresentativeByCreationDate?.phone
          )
            ?.replace("+49", "0")
            ?.replace(/\s+/g, "")
        : firstActiveRepresentativeByCreationDate?.phone

            ?.replace("+49", "0")
            ?.replace(/\s+/g, ""),
      firstName: firstActiveRepresentativeByCreationDate?.firstName,
      lastName: firstActiveRepresentativeByCreationDate?.lastName,
    });
  }, [imprintData, representatives]);

  const keepLocalFormDataToImprint = (): ImprintInformation => {
    const keepLocalDataToImprint: ImprintInformation = {
      ...imprintData,
      businessName: keepLocalFormData.retailerName || "",
      legalName: keepLocalFormData.legalName || "",
      website: keepLocalFormData.website || "",
      venue: keepLocalFormData.location?.venue,
      street: keepLocalFormData.location?.street,
      houseNo: keepLocalFormData.location?.houseNo,
      postalCode: keepLocalFormData.location?.postalCode,
      city: keepLocalFormData.location?.city,
      phone: keepLocalFormData.contactPersonPhone || "",
      email: keepLocalFormData.email || "",
      businessId: imprintData?.businessId || "",
    };

    return keepLocalDataToImprint;
  };

  const updateKeepLocalData = useCallback(
    (
      key: keyof KeepLocalFormInterface,
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value;
      setKeepLocalFormData(prevFormData => ({ ...prevFormData, [key]: value }));
    },
    [],
  );

  const onChangeLocation = useCallback(
    (changedLocation: Location) => {
      if (!isEqual(changedLocation, keepLocalFormData.location)) {
        setKeepLocalFormData(prevFormData => ({
          ...prevFormData,
          location: changedLocation,
        }));
      }
    },
    [keepLocalFormData.location],
  );

  const onConsentClick = useCallback(() => {
    setConsentCheckboxChecked(!consentCheckboxChecked);
    setContinueButtonDisabled(consentCheckboxChecked);
  }, [consentCheckboxChecked]);

  const handleContinueBtnClick = useCallback(() => {
    if (
      !validateImprintToFormDataIsEqual(
        imprintData,
        keepLocalFormDataToImprint(),
      )
    ) {
      setModalVisible(true);
    } else {
      transferDataToKeepLocal();
    }
  }, [imprintData, keepLocalFormDataToImprint]);

  const transferDataToKeepLocal = async () => {
    const queryParams = {
      legal_name: keepLocalFormData.legalName,
      retailer_name: keepLocalFormData.retailerName,
      zip_code: keepLocalFormData.location.postalCode,
      city: keepLocalFormData.location.city,
      street: keepLocalFormData.location.street,
      house_number: keepLocalFormData.location.houseNo,
      email: keepLocalFormData.contactPersonEmail,
      contact_person_email: keepLocalFormData.contactPersonEmail,
      contact_person_phone: keepLocalFormData.contactPersonPhone
        ?.replace("+49", "0")
        ?.replace(/\s+/g, ""),
      contact_person_name:
        keepLocalFormData.firstName || keepLocalFormData.lastName
          ? keepLocalFormData.firstName + " " + keepLocalFormData.lastName
          : "",
      first_name: keepLocalFormData.firstName,
      last_name: keepLocalFormData.lastName,
    };
    await transferDataToKeepLocalRequest(queryParams);

    const fullPath = `https://keeplocal.de/bliggit-wuppertal/${stringify(
      queryParams,
      {
        addQueryPrefix: true,
      },
    )}`;
    addSuccessNotification(
      strings("keepLocalForm.registrationSuccessMessage"),
    ).then(() => setTimeout(() => window.open(fullPath, "_blank"), 1000));
  };

  const onModalCancel = () => {
    transferDataToKeepLocal();
    setModalVisible(false);
  };

  const onModalConfirm = () => {
    const imprintToUpdate = keepLocalFormDataToImprint();
    setimprintData(imprintToUpdate);
    setModalVisible(false);
  };

  return (
    <>
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_KEEP_LOCAL} isView>
        {isKeepLocalEnabled() && (
          <CenteredContentViewContainer>
            <FormContainer>
              <LargeFontHeadline>
                {strings("keepLocalForm.title")}
              </LargeFontHeadline>
              <FormFieldsContainer>
                <TextWithIcon
                  onChange={e => updateKeepLocalData("retailerName", e)}
                  value={keepLocalFormData.retailerName}
                  label={strings("keepLocalForm.fields.name.label")}
                  placeholder={strings("keepLocalForm.fields.name.label")}
                  icon={briefcaseIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
                <TextWithIcon
                  onChange={e => updateKeepLocalData("legalName", e)}
                  value={keepLocalFormData.legalName}
                  label={strings("keepLocalForm.fields.legalName.label")}
                  placeholder={strings("keepLocalForm.fields.legalName.label")}
                  icon={briefcaseIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
                <TextWithIcon
                  optional
                  onChange={e => updateKeepLocalData("website", e)}
                  value={keepLocalFormData.website}
                  placeholder={strings("keepLocalForm.fields.website.label")}
                  icon={websiteIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
                <LocationInput
                  location={imprintData}
                  onChangeLocation={onChangeLocation}
                  hideVenue={true}
                  leftSideWidth={textWithIconWidth}
                />
                <Separator />
                <StyledFormSubtitle>
                  {strings("keepLocalForm.sectionSubtitle")}
                </StyledFormSubtitle>
                <TextWithIcon
                  onChange={e => updateKeepLocalData("firstName", e)}
                  value={keepLocalFormData.firstName}
                  placeholder={strings(
                    "keepLocalForm.fields.contactPersonFirstName.label",
                  )}
                  icon={userIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
                <TextWithIcon
                  onChange={e => updateKeepLocalData("lastName", e)}
                  value={keepLocalFormData.lastName}
                  placeholder={strings(
                    "keepLocalForm.fields.contactPersonSecondName.label",
                  )}
                  icon={userIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
                <TextWithIcon
                  onChange={e => updateKeepLocalData("contactPersonEmail", e)}
                  value={keepLocalFormData.contactPersonEmail}
                  placeholder={strings("keepLocalForm.fields.email.label")}
                  icon={emailIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
                <TextWithIcon
                  onChange={e => updateKeepLocalData("contactPersonPhone", e)}
                  value={keepLocalFormData.contactPersonPhone}
                  placeholder={strings("keepLocalForm.fields.phone.label")}
                  icon={phoneIcon}
                  leftSideWidth={textWithIconWidth}
                  width={totalFormRowWidth}
                />
              </FormFieldsContainer>
            </FormContainer>
          </CenteredContentViewContainer>
        )}

        <ConsentContainer>
          <Separator />
          <ConsentMesageContainer>
            <Checkbox
              name={strings("keepLocalForm.consentMessage")}
              text={strings("keepLocalForm.consentMessage")}
              checked={consentCheckboxChecked}
              onChange={onConsentClick}
            />
          </ConsentMesageContainer>
          <ContinueButton
            text={strings("keepLocalForm.continueButton")}
            special="publish"
            data-event="click"
            disabled={continueButtonDisabled}
            onClick={handleContinueBtnClick}
          />
          <CustomModal
            popupMessage={strings("keepLocalForm.changedImprintPopupMessage")}
            modalVisible={modalVisible}
            onConfirm={onModalConfirm}
            onCancel={onModalCancel}
          />
        </ConsentContainer>
      </PrivilegedComponent>
    </>
  );
};

export default withRouter(KeepLocal);
