import React, { useCallback } from "react";
import strings from "localisation/strings";
import Plan from "api/models/Plan";
import ActionButton from "components/buttons/ActionButton";
import styled from "styled-components";
import BliggBubble, {
  mediumBubbleHeight,
} from "components/generic/BliggBubble";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import { noop } from "utils";

const defaultPlanCardMinWidth = 190;

export const PlanContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.background.light};
  padding-bottom: ${({ theme }) => theme.margin.xlarge};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${defaultPlanCardMinWidth}px;
`;

/**
 * Flexbox can't reuse the width of items in a different row
 * So we need to force the row to have the same amount of hidden placeholder items
 */
const PlaceholderPlanContainer = styled(PlanContainer)`
  visibility: hidden;
  margin-top: 0;
  padding-bottom: 0;
`;

const HeroBackground = styled.div`
  z-index: -1;
  position: absolute;
  background: ${({ theme }) => theme.color.background.light};
`;

const planHeroBackgroundWidthPercent = 125;
const VerticalHeroBackground = styled(HeroBackground)<{
  color?: "light" | "dark" | "flamePea";
}>`
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  top: 0;
  left: ${(100 - planHeroBackgroundWidthPercent) / 2}%;
  width: ${planHeroBackgroundWidthPercent}%;
  height: calc(
    ${mediumBubbleHeight / 2}px + ${({ theme }) => theme.margin.large}
  );
  background: ${({ theme, color = "light" }) => {
    if (color === "flamePea") {
      return theme.color.background.flamePea;
    }

    if (color === "dark") {
      return theme.color.foreground.action;
    }

    return theme.color.background.light;
  }};
`;

const TitleText = styled.span<{ bold?: boolean }>`
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-weight: ${({ bold }) => (bold ? "800" : "400")};
  font-size: ${({ theme }) => theme.font.size.card};
  margin: 0 0 ${({ theme }) => theme.margin.xsmall};
  line-height: 133%;
`;

const SubtitleText = styled.span`
  color: ${({ theme }) => theme.color.foreground.tertiary};
  font-size: ${({ theme }) => theme.font.size.pagination};
  line-height: 133%;
`;

const Description = styled.div`
  margin-top: ${({ theme }) => theme.margin.standart};
  padding: 0 ${({ theme }) => theme.margin.large};
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }) => theme.margin.large};
  padding-top: ${({ theme }) => theme.margin.large};
  position: relative;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.margin.large};
  margin-top: ${({ theme }) => theme.margin.large};
  min-height: 120px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.margin.standart};
  margin-top: ${({ theme }) => theme.margin.standart};
  margin-bottom: -${({ theme }) => theme.margin.xsmall};
`;

interface Props {
  plan: Plan;
  isCurrent?: boolean;
  isSelectable: boolean;
  onSelect?: (plan: Plan) => void;
  onCurrentPlanSelect?: () => void;
}

const PlanCard = ({
  plan,
  isCurrent,
  isSelectable,
  onSelect = noop,
  onCurrentPlanSelect = noop,
}: Props) => {
  const handlePlanSelected = useCallback(() => {
    onSelect(plan);
  }, [plan, onSelect]);

  return (
    <PlanContainer key={plan.planId}>
      <Hero>
        <VerticalHeroBackground color={isCurrent ? "dark" : "light"} />
        <BliggBubble bubbleText={plan.bliggText} />
      </Hero>

      <Heading>
        <TitleText bold>{plan.name}</TitleText>
        <TitleText>{plan.price}</TitleText>
        <SubtitleText>{plan.priceText}</SubtitleText>
      </Heading>
      {isSelectable && (
        <Actions>
          <ActionButton
            alone
            special={isCurrent ? undefined : "publish"}
            text={strings(
              isCurrent
                ? "wizardScreen.planSelection.currentPlanButton"
                : "wizardScreen.planSelection.button",
            )}
            onClick={isCurrent ? onCurrentPlanSelect : handlePlanSelected}
          />
        </Actions>
      )}
      {plan.description && (
        <Description>
          <RichTextRendering cozy value={plan.description} />
        </Description>
      )}
    </PlanContainer>
  );
};

export {
  PlaceholderPlanContainer,
  VerticalHeroBackground,
  HeroBackground,
  Hero,
  TitleText,
  SubtitleText,
  Description,
  Heading,
};

export default PlanCard;
