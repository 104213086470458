import React, { InputHTMLAttributes, useState } from "react";
import SimpleIconedInput from "components/forms/FormFields/SimpleIconedInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import ActionButton from "components/buttons/ActionButton";
import gallerieIcon from "common/assets/icons/gallerie.svg";
import sheetIcon from "common/assets/icons/sheet.svg";
import strings from "localisation/strings";
import styled from "style/styled-components";
import { defaultSubCategorySubTags } from "components/helpers/formHelpers/category";
import Checkbox, { CheckboxProps } from "components/forms/Checkbox";
import InlineNumberInput, {
  InlineInputProps,
} from "components/forms/FormFields/InlineNumberInput";
import SelectComponent, {
  SelectItem,
  SelectPropsInterface,
} from "components/forms/Select";
import {
  detailCategoryStatusOptions,
  defaultSelectLabel,
  businessTypeOptions,
} from "components/table/DataTable/constants";
import CategoryQuestionInterface from "api/models/CategoryQuestionInterface";
import SubCategoryInterface from "api/models/SubCategoryInterface";
import { ResponsiveSpreadRow } from "components/rows/SpreadRow";
import ResponsiveHalfRowColumn from "components/columns/HalfRowColumn";
import { AutoDragAndDrop, Separator } from "components/forms/FormFields";
import ImageUploadType from "api/models/ImageUploadType";

const AddQuestionButton = styled(ActionButton)`
  height: auto;
  padding: 0;
`;

export interface MultipleFieldFormProps {
  fields: (CategoryQuestionInterface | SubCategoryInterface)[];
  onMainCategoryAdd: () => void;
  onSubCategoryAdd: (isParentActive: boolean) => void;
}

export interface CategoryMultipleAnswerProps extends MultipleFieldFormProps {
  isSubCategory: boolean;
  isParentActive?: boolean;
  width?: string;
  inputWidth?: string;
  isWithPicture?: boolean;
  getQuestionInputFieldProps: (
    field: string,
    subField: string,
    index: number,
  ) => QuestionInputFieldProps;
  getQuestionCheckboxFieldProps?: (
    field: string,
    subField: string,
    index: number,
  ) => CheckboxProps;
  getQuestionNumberFieldProps?: (
    field: string,
    subField: string,
    index: number,
  ) => InlineInputProps;
  getQuestionSelectFieldProps?: (
    field: string,
    subField: string,
    index: number,
    options: SelectItem[],
  ) => SelectPropsInterface;
  getQuestionPictureFieldProps?: (
    field: string,
    subField: string,
    index: number,
  ) => {
    imageUrl?: string | null;
    setImageUrl?: (image: string | null) => void;
  };
}

export interface QuestionInputFieldProps
  extends Partial<InputHTMLAttributes<HTMLInputElement>> {
  errors?: string[];
  inputPlaceholder?: string;
}

interface ImageComponentProps {
  width?: string;
}

const CategoryImage = (props: ImageComponentProps) => {
  const { width, ...rest } = props;
  const [slideErrors, setSlideErrors] = useState<string[]>([]);

  return (
    <InlineInputGroup
      icon={gallerieIcon}
      placeholder={strings("categoriesScreen.fields.imageUrl.placeholder")}
      width={width}
    >
      <AutoDragAndDrop
        {...rest}
        placeholder={strings(`dragAndDrop.dropHere`)}
        container={ImageUploadType.TAGS}
        height="100px"
        slideErrors={slideErrors}
        setSlideErrors={setSlideErrors}
        withCropAndZoom={false}
      />
    </InlineInputGroup>
  );
};

const CategoryMultipleAnswerForm = (props: CategoryMultipleAnswerProps) => {
  const {
    isSubCategory,
    isParentActive,
    width,
    inputWidth,
    fields,
    onMainCategoryAdd,
    onSubCategoryAdd,
    getQuestionInputFieldProps,
    isWithPicture,
    getQuestionCheckboxFieldProps = () => ({
      name: "",
      checked: true,
      onChange: () => {},
    }),
    getQuestionPictureFieldProps = () => ({
      imageUrl: null,
      setImageUrl: () => {},
    }),
    getQuestionNumberFieldProps = () => ({
      setValue: () => {},
    }),
    getQuestionSelectFieldProps = () => ({
      onChange: () => {},
      options: [] as SelectItem[],
    }),
  } = props;

  const addNewField = isSubCategory
    ? () => onSubCategoryAdd(isParentActive ? isParentActive : false)
    : onMainCategoryAdd;
  const addNewButtonText = isSubCategory
    ? `+ ${strings("categoriesScreen.fields.subTags.buttonLabel")}`
    : `+ ${strings("categoriesScreen.fields.question.buttonLabel")}`;

  const newSubFields =
    fields.length > 0
      ? fields
      : (defaultSubCategorySubTags as SubCategoryInterface[]);
  const SUB_TAGS = "subTags";

  return (
    <>
      {isSubCategory &&
        newSubFields.map((subField, index: number) => {
          return (
            <div key={index}>
              <Separator />

              <ResponsiveSpreadRow>
                <ResponsiveHalfRowColumn>
                  <SimpleIconedInput
                    icon={sheetIcon}
                    width={width}
                    inputWidth={inputWidth}
                    {...getQuestionInputFieldProps(SUB_TAGS, "code", index)}
                    placeholder={strings(
                      "categoriesScreen.fields.subCategory.label",
                    )}
                    inputPlaceholder={strings(
                      "categoriesScreen.fields.subCategory.placeholder",
                    )}
                  />
                </ResponsiveHalfRowColumn>

                <ResponsiveHalfRowColumn>
                  <InlineInputGroup
                    icon={sheetIcon}
                    placeholder={strings(
                      "categoriesScreen.fields.showAsInterests.label",
                    )}
                    width={width}
                  >
                    <Checkbox
                      {...getQuestionCheckboxFieldProps(
                        SUB_TAGS,
                        "showAsInterests",
                        index,
                      )}
                      text={""}
                    />
                  </InlineInputGroup>
                </ResponsiveHalfRowColumn>
              </ResponsiveSpreadRow>

              <ResponsiveSpreadRow>
                <ResponsiveHalfRowColumn>
                  <InlineInputGroup
                    icon={sheetIcon}
                    placeholder={strings(
                      "categoriesScreen.fields.businessType",
                    )}
                    width={width}
                  >
                    <SelectComponent
                      {...getQuestionSelectFieldProps(
                        SUB_TAGS,
                        "businessType",
                        index,
                        businessTypeOptions(),
                      )}
                      classNamePrefix={"option"}
                      small
                      disabled={!isParentActive}
                    />
                  </InlineInputGroup>
                </ResponsiveHalfRowColumn>

                <ResponsiveHalfRowColumn>
                  <InlineInputGroup
                    icon={sheetIcon}
                    placeholder={strings(
                      "categoriesScreen.fields.weight.placeholder",
                    )}
                    width={width}
                  >
                    <InlineNumberInput
                      maxLength={8}
                      width={inputWidth}
                      placeholder={strings(
                        "categoriesScreen.fields.weight.label",
                      )}
                      isFormattingDisabled={true}
                      {...getQuestionNumberFieldProps(
                        SUB_TAGS,
                        "weight",
                        index,
                      )}
                    />
                  </InlineInputGroup>
                </ResponsiveHalfRowColumn>
              </ResponsiveSpreadRow>

              <ResponsiveSpreadRow>
                <ResponsiveHalfRowColumn>
                  <InlineInputGroup
                    icon={sheetIcon}
                    placeholder={strings("categoriesScreen.fields.tagStatus")}
                    width={width}
                  >
                    <SelectComponent
                      {...getQuestionSelectFieldProps(
                        SUB_TAGS,
                        "tagStatus",
                        index,
                        detailCategoryStatusOptions(),
                      )}
                      placeholder={defaultSelectLabel()}
                      classNamePrefix={"menu"}
                      small
                      disabled={!isParentActive}
                    />
                  </InlineInputGroup>
                </ResponsiveHalfRowColumn>
              </ResponsiveSpreadRow>

              {isWithPicture && (
                <ResponsiveSpreadRow>
                  <ResponsiveHalfRowColumn>
                    <CategoryImage
                      width={width}
                      {...getQuestionPictureFieldProps(
                        SUB_TAGS,
                        "imageUrl",
                        index,
                      )}
                    />
                  </ResponsiveHalfRowColumn>
                </ResponsiveSpreadRow>
              )}
            </div>
          );
        })}
      <InlineInputGroup justify="flex-end">
        <AddQuestionButton
          text={addNewButtonText}
          special="transparent"
          onClick={addNewField}
        />
      </InlineInputGroup>
    </>
  );
};

export default CategoryMultipleAnswerForm;
