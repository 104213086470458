import styled from "styled-components";

interface Props {
  centered?: boolean;
  small?: boolean;
  cozy?: boolean;
}

const BodyText = styled.p<Props>`
  font-size: ${({ theme, small }) =>
    small ? theme.font.size.note : theme.font.size.medium};
  ${({ centered }) => centered && "text-align: center;"}
  ${({ theme, cozy }) => cozy && `line-height: ${theme.font.lineHeight.cozy};`}
`;

export default BodyText;
