import React, { useState, useEffect, useRef, useCallback } from "react";
import { curry } from "ramda";
import { History } from "history";
import useFormFields from "hooks/useFormFields";
import strings from "localisation/strings";
import styled from "style/styled-components";
import SingleMainCategoryInterface from "api/models/SingleMainCategoryInterface";
import SingleSubCategoryInterface from "api/models/SingleSubCategoryInterface";
import SubCategoryInterface from "api/models/SubCategoryInterface";
import { TagStatus } from "api/models/TagInterface";
import {
  defaultMainCategoryFields,
  defaultSubCategoryFields,
  setCategoryFields,
  setSubCategoryFields,
  getCategoryPayload,
  getSubCategoryPayload,
  isBusinessRelated,
  isGeneralTypeValue,
} from "components/helpers/formHelpers/category";
import MainCategoriesForm from "components/forms/MainCategoriesForm";
import SubCategoriesForm from "components/forms/SubCategoriesForm";
import SubmitButton from "components/forms/SubmitButton";
import ActionButton from "components/buttons/ActionButton";
import EntityTypes from "api/models/EntityTypes";

const ControllerContainer = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 0 ${({ theme }) => theme.margin.large};
`;

const isSubTagEmpty = (
  subTag: SubCategoryInterface,
  isParentActive: boolean,
): boolean => {
  if (isParentActive) {
    return (
      subTag.code.length > 0 || !!subTag.weight || subTag.tagStatus.length > 0
    );
  }

  return subTag.code.length > 0 || !!subTag.weight;
};

const getSubTagsWithNoEmptyValues = (
  subTags: SubCategoryInterface[],
  isParentActive: boolean,
): SubCategoryInterface[] => {
  const filteredSubTags: SubCategoryInterface[] = subTags.filter(subTag =>
    isSubTagEmpty(subTag, isParentActive),
  );

  if (!filteredSubTags.length) {
    // This values will be used for validating empty fields
    return [
      {
        tagType: subTags[0].tagType,
        code: "",
        showAsInterests: false,
        showAsDistrict: false,
        tagStatus: "",
        weight: undefined,
        smartServiceGroup: undefined,
      },
    ];
  }

  return filteredSubTags;
};

const CategoriesForm = ({
  createNew,
  success,
  result,
  changedValues,
  setBlockNavigation,
  isSubCategory,
  isNew,
  history,
}: {
  createNew: any;
  success: (message: string, response: any) => void;
  result?: SingleMainCategoryInterface | SingleSubCategoryInterface;
  changedValues: Set<string>;
  setBlockNavigation: (shouldBlockNavigation: boolean) => void;
  isSubCategory: boolean;
  isNew: boolean;
  history: History;
}) => {
  const [formFields, setFormFields] = useState<object>(
    isSubCategory ? defaultSubCategoryFields : defaultMainCategoryFields,
  );
  const [isParentActive, setIsParentActive] = useState<boolean>(false);
  const [isGeneralType, setIsGeneralType] = useState<boolean>(false);
  const [isWithPicture, setIsWithPicture] = useState<boolean>(false);
  const [
    isBusinessRelatedObjectType,
    setIsBusinessRelatedObjectType,
  ] = useState<boolean>(false);

  const {
    values,
    getNumberFieldProps,
    getSingleInputFieldProps,
    getSelectFieldProps,
    getCheckboxFieldProps,
    getQuestionInputFieldProps,
    getQuestionCheckboxFieldProps,
    getQuestionPictureFieldProps,
    getQuestionNumberFieldProps,
    getQuestionSelectFieldProps,
    getMultipleFieldFormProps,
    getSmartServiceLogoPictureFieldProps,
    parseErrors,
    updateValues,
    onChange,
  } = useFormFields({
    ...formFields,
    setBlockNavigation,
    changedValues,
    setIsGeneralType,
    handleGenericErrors: true,
    translationScope: "categoriesScreen.fields",
  });

  const [oldContent, setOldContent] = useState<
    SingleSubCategoryInterface | SingleMainCategoryInterface
  >();

  const updateValuesRef = useRef(updateValues);

  const sortSubTagsByWeight = useCallback(
    (subTags: SubCategoryInterface[]): void => {
      if (hasUndefinedWeight(subTags)) {
        return;
      }

      subTags.sort((a, b) =>
        // @ts-ignore: hasUndefinedWeight() makes sure subTags has no undefined weight
        a.weight > b.weight ? 1 : -1,
      );
    },
    [],
  );

  useEffect(() => {
    if (result) {
      // sort sub-categories because BE respond with random order each time.
      if (isSubCategory) {
        sortSubTagsByWeight((result as SingleSubCategoryInterface).subTags);
        setIsParentActive(getIsParentActive(result.tagStatus));
      }
      setOldContent(result);
      setFormFields(
        isSubCategory
          ? setSubCategoryFields(result)
          : setCategoryFields(result),
      );
      updateValuesRef.current(
        isSubCategory
          ? setSubCategoryFields(result)
          : setCategoryFields(result),
      );
      setIsBusinessRelatedObjectType(isBusinessRelated(result.tagType));
      setIsGeneralType(isGeneralTypeValue(result.tagType));

      const isGeneralWithPicture =
        result.tagType === EntityTypes.general && result.showAsDistrict;
      const isPostWithPicture =
        result.tagType === EntityTypes.posts &&
        (!!result.showAsSmartService || !!result.showAsJobOffer);
      setIsWithPicture(isGeneralWithPicture || isPostWithPicture);
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const hasUndefinedWeight = (subTags: SubCategoryInterface[]): boolean => {
    const noWeightSubTags = subTags.filter(subTag => !subTag.weight);
    return noWeightSubTags.length > 0;
  };

  const getIsParentActive = (tagStatus?: string): boolean => {
    return tagStatus && tagStatus === TagStatus.Active ? true : false;
  };

  const subCategoryProps = {
    getSelectFieldProps,
    getSingleInputFieldProps,
    getQuestionInputFieldProps,
    getQuestionCheckboxFieldProps,
    getQuestionPictureFieldProps,
    getQuestionNumberFieldProps,
    getQuestionSelectFieldProps,
    getMultipleFieldFormProps,
    isParentActive,
    isBusinessRelatedObjectType,
    isWithPicture,
  };

  const mainCategoryProps = {
    getSelectFieldProps,
    getSingleInputFieldProps,
    getCheckboxFieldProps,
    getMultipleFieldFormProps,
    getNumberFieldProps,
    getQuestionInputFieldProps,
    getSmartServiceLogoPictureFieldProps,
  };

  const newGetCategoryPayload = (content: any) => {
    if (isSubCategory) {
      const inheritDistrictValueFromParent = (
        subTag: SubCategoryInterface,
      ) => ({
        ...subTag,
        showAsDistrict: values.showAsDistrict,
      });
      const subTags = getSubTagsWithNoEmptyValues(
        values.subTags,
        isParentActive,
      ).map(inheritDistrictValueFromParent);

      const newValues = {
        ...values,
        subTags,
      };
      onChange("subTags", subTags);
      return getSubCategoryPayload(newValues, content);
    }

    return getCategoryPayload(
      {
        ...values,
      },
      content,
    );
  };

  return (
    <>
      {isSubCategory && <SubCategoriesForm {...subCategoryProps} />}
      {!isSubCategory && (
        <MainCategoriesForm
          isNew={isNew}
          {...mainCategoryProps}
          isGeneralType={isGeneralType}
        />
      )}

      <ControllerContainer>
        <ActionButton
          text={strings("buttons.cancel")}
          onClick={() => history.push("/admin/konfiguration/kategorien")}
        />
        <SubmitButton
          request={createNew}
          text={strings("buttons.save")}
          loadingText={strings("buttons.states.requestInProgress")}
          special="publish"
          getPayload={() => newGetCategoryPayload(oldContent)}
          successfulRequestCallback={curry(success)(
            strings("actionsArea.savedMessage"),
          )}
          failedRequestCallback={parseErrors}
          setShouldBlockNavigation={setBlockNavigation}
        />
      </ControllerContainer>
    </>
  );
};

export default CategoriesForm;
