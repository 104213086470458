import "blueimp-canvas-to-blob/js/canvas-to-blob";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { uploadPictureBo, uploadPictureBp } from "api/resources";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import ParamValidationError from "common/api/models/ParamValidationError";
import useMutation from "common/hooks/useMutation";
import { ImageItem } from "hooks/helpers/Interfaces";
import useNotificationState from "hooks/useNotification";
import strings from "localisation/strings";
import loginState from "state/singletons/loginState";
import styled from "style/styled-components";
import Slider from "components/forms/FormFields/Slider";
import { CenteredContentViewContainer as CenteredContainer } from "components/generic";
import ActionArea from "components/generic/ActionArea";
import Dialog from "components/generic/Dialog";
import If from "components/generic/If";
import OverlaySpinner from "components/generic/OverlaySpinner";
import SimpleText, { BoldText } from "components/generic/SimpleText";
import ActionButton from "components/buttons/ActionButton";
import TransparentIconButton from "components/buttons/TransparentIconButton";
import Icon from "components/forms/FormFields/Icon";
import cropRotateIcon from "assets/icons/crop-rotate.svg";

export interface CropAndZoomDialogProps {
  isVisible: boolean;
  onClose: (imageItem: ImageItem, imageUrl: string) => void;
  imageItem?: ImageItem;
  imageUrl: string;
  setImageUrl?: (imageUrl: string) => void;
  imageType: string;
  imageFileName: string;
  ratio: number;
  minZoom: number;
  maxZoom: number;
  cropShape: "rect" | "round";
  parseErrors?: (response: APIErrorResponse) => void;
  showDistinctError?: boolean;
  container: string;
  setSlideErrors: (errors: string[]) => void;
  showWarning: boolean;
  showPortraitWarning: boolean;
}

const isMobileDevice = (): boolean => {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  } catch (e) {
    return false;
  }
};

const DesktopCropperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 45vh;
`;

const MobileCropperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 35vh;
`;

const CropAndZoomDialog = ({
  isVisible,
  onClose,
  imageItem,
  imageUrl,
  setImageUrl,
  imageType,
  imageFileName,
  ratio,
  minZoom,
  maxZoom,
  cropShape,
  parseErrors,
  showDistinctError,
  container,
  setSlideErrors,
  showWarning,
  showPortraitWarning,
}: CropAndZoomDialogProps) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [initialArea, setInitialArea] = useState<Area | null>(null);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState(0);
  const [pixels, setPixels] = useState<Area | undefined>(undefined);

  useEffect(() => {
    resetSliders();
  }, [isVisible]);

  const onZoomChange = (changedZoom: number) => {
    setZoom(changedZoom);
  };

  const onRotationChange = (changedRotation: number) => {
    setRotation(changedRotation);
  };

  const onCropComplete = (_: Area, changedCroppedAreaPixels: Area) => {
    if (
      !isNaN(changedCroppedAreaPixels.width) &&
      !isNaN(changedCroppedAreaPixels.height) &&
      !isNaN(changedCroppedAreaPixels.x) &&
      !isNaN(changedCroppedAreaPixels.y)
    ) {
      if (!initialArea) {
        setInitialArea(changedCroppedAreaPixels);
      }

      setPixels(changedCroppedAreaPixels);
    }
  };

  const resetSliders = () => {
    if (isVisible) {
      if (initialArea) setPixels(initialArea);
      setPos({ x: 0, y: 0 });
      setZoom(minZoom);
      setRotation(0);
    } else {
      // Reset zoom because otherwise logo uploading will be broken
      setZoom(1);
    }
  };

  const uploadEditedImage = async () => {
    if (!imageUrl) return;
    setShowOverlay(true);

    let fileEnding = "";
    if (imageType === "image/jpeg") {
      fileEnding = "jpg";
    } else if (imageType === "image/png") {
      fileEnding = "png";
    }

    const image = await getCroppedImg();
    const newImageUrl = await uploadImage(
      image,
      `${imageFileName}.${fileEnding}`,
    );

    setShowOverlay(false);
    if (imageItem) onClose(imageItem, newImageUrl);
  };

  const getSafeArea = (width: number, height: number): number => {
    const maxSize = Math.max(width, height);
    return 2 * ((maxSize / 2) * Math.sqrt(2));
  };

  const getCroppedImg = async (): Promise<Blob> => {
    if (!imageUrl) return Promise.reject();

    const image = await createImage(imageUrl);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return Promise.reject();

    const safeArea = getSafeArea(image.width, image.height);

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    if (!data) return Promise.reject();
    if (!pixels) return Promise.reject();

    canvas.width = pixels.width;
    canvas.height = pixels.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixels.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixels.y),
    );

    URL.revokeObjectURL(imageUrl);

    return new Promise(resolve => {
      canvas.toBlob(file => {
        if (file) resolve(file);
      }, "image/png");
    });
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", error => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getRadianAngle = (degrees: number): number => (degrees * Math.PI) / 180;

  const uploadImage = async (file: Blob, fileName: string): Promise<string> => {
    // TODO: isUploading status [optional]
    clearErrors();

    const formData = new FormData();
    formData.append("file", file, fileName);
    formData.append("container", container);
    const response = (await makeRequest(formData)) || {};

    if (parseErrors && response.error) parseErrors(response.error);

    if (response.error && showDistinctError) {
      addErrorNotification(response.error.detail);
    }

    if (response.error && response.error.paramsErrors) {
      const errorMessages: string[] = [];
      response.error.paramsErrors.forEach(
        (paramError: ParamValidationError) => {
          errorMessages.push(paramError.message);
        },
      );

      setSlideErrors(errorMessages);
    }

    const pictureUrl = (response.result && response.result.url) || null;
    if (setImageUrl) setImageUrl(pictureUrl);

    return Promise.resolve(pictureUrl);
  };

  const uploadPicture = loginState.hasAdminRights()
    ? uploadPictureBo
    : uploadPictureBp;
  const { makeRequest } = useMutation(uploadPicture);

  const clearErrors = () => {
    setSlideErrors([]);
  };

  const { addErrorNotification } = useNotificationState();

  return (
    <Dialog
      isVisible={isVisible}
      onClose={() => (imageItem ? onClose(imageItem, imageItem.url || "") : {})}
      showCloseButton={true}
    >
      <CenteredContainer>
        <div
          style={Object.assign(
            {},
            {
              width: "100%",
            },
          )}
        >
          <If isTrue={!isMobileDevice()}>
            <DesktopCropperContainer>
              <Cropper
                image={imageUrl}
                crop={pos}
                zoom={zoom}
                rotation={rotation}
                aspect={ratio}
                minZoom={minZoom}
                maxZoom={maxZoom}
                cropShape={cropShape}
                onCropChange={setPos}
                onZoomChange={onZoomChange}
                onRotationChange={onRotationChange}
                onCropComplete={onCropComplete}
                restrictPosition={false}
                classes={{
                  cropAreaClassName: "crop-area-grid",
                }}
              />
            </DesktopCropperContainer>
            <CenteredContainer>
              {showWarning && (
                <BoldText>{strings("dragAndDrop.warning")}</BoldText>
              )}
              {showPortraitWarning && (
                <BoldText>{strings("dragAndDrop.portrait")}</BoldText>
              )}
              <SimpleText>{strings("dragAndDrop.sliders")}</SimpleText>
            </CenteredContainer>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 20,
              }}
            >
              <TransparentIconButton
                onClick={() => {
                  if (zoom > minZoom) setZoom(zoom - 0.1);
                }}
              >
                <SimpleText
                  style={{
                    fontSize: 32,
                    marginRight: 20,
                    marginTop: -22,
                  }}
                >
                  -
                </SimpleText>
              </TransparentIconButton>
              <div
                style={{
                  width: "100%",
                  height: 21,
                  marginRight: 20,
                }}
              >
                <Slider
                  step={0.1}
                  min={minZoom}
                  max={maxZoom}
                  value={zoom}
                  onChange={onZoomChange}
                />
              </div>
              <TransparentIconButton
                onClick={() => {
                  if (zoom < maxZoom) setZoom(zoom + 0.1);
                }}
              >
                <SimpleText
                  style={{
                    fontSize: 32,
                    marginRight: 40,
                    marginTop: -22,
                  }}
                >
                  +
                </SimpleText>
              </TransparentIconButton>
              <Icon
                active
                backgroundImage={cropRotateIcon}
                style={{
                  width: 48,
                  height: 48,
                  marginRight: 0,
                  marginTop: -20,
                }}
              />
              <div
                style={{
                  width: "100%",
                  height: 21,
                  marginLeft: 20,
                }}
              >
                <Slider
                  step={1}
                  min={-180}
                  max={180}
                  value={rotation}
                  withMarkInStep={180}
                  onChange={onRotationChange}
                />
              </div>
            </div>
          </If>
          <If isTrue={isMobileDevice()}>
            <MobileCropperContainer>
              <Cropper
                image={imageUrl}
                crop={pos}
                zoom={zoom}
                rotation={rotation}
                aspect={ratio}
                minZoom={minZoom}
                maxZoom={maxZoom}
                cropShape={cropShape}
                onCropChange={setPos}
                onZoomChange={onZoomChange}
                onRotationChange={onRotationChange}
                onCropComplete={onCropComplete}
                restrictPosition={false}
                classes={{
                  cropAreaClassName: "crop-area-grid",
                }}
              />
            </MobileCropperContainer>
            <CenteredContainer>
              {showWarning && (
                <BoldText>{strings("dragAndDrop.warning")}</BoldText>
              )}
              {showPortraitWarning && (
                <BoldText>{strings("dragAndDrop.portrait")}</BoldText>
              )}
            </CenteredContainer>
          </If>
        </div>
        <ActionArea>
          <ActionButton text={"Zurücksetzen"} onClick={resetSliders} />
          <ActionButton
            text={strings("buttons.confirm")}
            testId="confirm"
            onClick={uploadEditedImage}
            special={"publish"}
          />
        </ActionArea>
      </CenteredContainer>
      {showOverlay && <OverlaySpinner />}
    </Dialog>
  );
};

export default CropAndZoomDialog;
