import Artist from "api/models/Artist";
import { BusinessHoursInterface } from "api/models/BusinessUserProfileInterface";
import CategoryStatuses from "api/models/CategoryStatuses";
import { EndDateType } from "api/models/EventInterface";
import Location, { AddressLocation } from "api/models/Location";
import OfferTypes from "api/models/OfferTypes";
import { PaymentAddressDTO } from "api/models/Payments";
import { MapCurationPayload } from "api/models/Plan";
import PriceMinMax from "api/models/PriceMinMax";
import TagInterface from "api/models/TagInterface";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { ImprintProps } from "components/forms/BusinessProfile/CollapsibleImprintArea";
import {
  MultipleFieldFormProps,
  QuestionInputFieldProps,
} from "components/forms/CategoryMultipleAnswerForm";
import { CheckboxProps } from "components/forms/Checkbox";
import { ArtistsInputProps } from "components/forms/FormFields/ArtistsInput";
import { BusinessHoursProps } from "components/forms/FormFields/BusinessHours";
import { BusinessTypeProps } from "components/forms/FormFields/BusinessType/BusinessType";
import { DateTimeRangeInterface } from "components/forms/FormFields/DateTime/helpers";
import { DiscountPricesProps } from "components/forms/FormFields/DiscountPrices";
import { InlineInputProps } from "components/forms/FormFields/InlineNumberInput";
import { LocationInputProps } from "components/forms/FormFields/LocationInput";
import { OfferTypeOptionsProps } from "components/forms/FormFields/OfferTypeOptions";
import { PictureGalleryProps } from "components/forms/FormFields/PictureGallery/PictureGallery";
import { PriceRangeProps } from "components/forms/FormFields/PriceRange";
import { SettingsAreaProps } from "components/forms/FormFields/SettingsArea";
import { SingleInputFieldProps } from "components/forms/FormFields/SimpleIconedInput";
import { HashtagsFieldProps } from "components/forms/HashtagsField";
import { SelectItem, SelectPropsInterface } from "components/forms/Select";
import { CategorySelectProps } from "components/forms/TagSelection/TagSelectionField";
import { TextFieldProps } from "components/forms/TextField";
import {
  DateTimeRangePickerProps,
  EndDateTypeFieldProps,
} from "components/forms/ValidatedFields/DateTimeRangePicker";
import { TextAreaProps } from "components/forms/ValidatedFields/TextArea";
import {
  isGeneralTypeValue,
  isQuestionRequiredValue,
} from "components/helpers/formHelpers/category";
import {
  ArtistsFieldsInterface,
  BusinessTypeInterface,
  CheckboxFieldInterface,
  ContactFieldInterface,
  DateTimeRangeFieldInterface,
  EndDateTypeFieldInterface,
  HashtagsFieldInterface,
  HiddenFieldInterface,
  ImageFieldsBUPInterface,
  ImageFieldsInterface,
  ImageItem,
  ImageListInterface,
  LocationFieldInterface,
  TopicsFieldsInterface,
  NumberFieldInterface,
  OfferTypeFieldInterface,
  PriceGroupFieldInterface,
  PriceMinMaxFieldInterface,
  QuestionFieldInterface,
  SettingsFieldInterface,
  SubTagFieldInterface,
  TagsFieldInterface,
  TextAreaFieldInterface,
  TextFieldInterface,
  MapAllowanceFieldsInterface,
  MapAssignmentFieldsInterface,
  MapCurationFieldsInterface,
} from "hooks/helpers/Interfaces";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import useNotificationState from "hooks/useNotification";
import { TranslateOptions } from "i18n-js";
import strings from "localisation/strings";
import { omit, pickBy, unset } from "lodash";
import {
  assoc,
  clone,
  find,
  keys,
  Omit,
  pipe,
  propEq,
  replace,
  startsWith,
} from "ramda";
import React, {
  ChangeEvent,
  createRef,
  RefObject,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styled from "style/styled-components";
import {
  checkBoxTestId,
  numberInputTestId,
  textInputTestId,
} from "testing/testId";
import { flatArray } from "utils";
import { PaymentAddressProps } from "views/BusinessSettings/Payment/shared/PaymentAddressDetails";

const StyledLink = styled(Link)`
  color: inherit;
`;

export interface FormErrorsInterface {
  [key: string]: string[] | undefined;
}

const useFormFields = <
  ArtistsFields extends ArtistsFieldsInterface,
  BusinessTypeFields extends BusinessTypeInterface,
  CheckboxFields extends CheckboxFieldInterface,
  ContactFields extends ContactFieldInterface,
  DateTimeRangeFields extends DateTimeRangeFieldInterface,
  HiddenFields extends HiddenFieldInterface,
  LocationFields extends LocationFieldInterface,
  ImageFields extends ImageFieldsInterface,
  ImageListFields extends ImageListInterface,
  ImageFieldsBUP extends ImageFieldsBUPInterface,
  OfferTypeFields extends OfferTypeFieldInterface,
  PriceGroupFields extends PriceGroupFieldInterface,
  PriceMinMaxFields extends PriceMinMaxFieldInterface,
  SettingsFields extends SettingsFieldInterface,
  SingleInputFields extends TextFieldInterface,
  SpecialFields extends HiddenFieldInterface,
  TagsFields extends TagsFieldInterface,
  EndDateTypeFields extends EndDateTypeFieldInterface,
  HashtagsFields extends HashtagsFieldInterface,
  TextAreaFields extends TextAreaFieldInterface,
  TextFields extends TextFieldInterface,
  NumberFields extends NumberFieldInterface,
  SelectFields extends TextFieldInterface,
  TopicsFields extends TopicsFieldsInterface,
  QuestionFields extends QuestionFieldInterface,
  SubTagFields extends SubTagFieldInterface,
  MapAllowanceFields extends MapAllowanceFieldsInterface,
  MapAssignmentFields extends MapAssignmentFieldsInterface,
  MapCurationFields extends MapCurationFieldsInterface
>({
  artistsFields = {} as ArtistsFields,
  businessTypeFields = {} as BusinessTypeFields,
  checkboxFields = {} as CheckboxFields,
  contactFields = {} as ContactFields,
  dateTimeRangeFields = {} as DateTimeRangeFields,
  hiddenFields = {} as HiddenFields,
  imageFields = {} as ImageFields,
  imageListFields = {} as ImageListFields,
  imageFieldsBUP = {} as ImageFieldsBUP,
  locationFields = {} as LocationFields,
  offerTypeFields = {} as OfferTypeFields,
  priceGroupFields = {} as PriceGroupFields,
  priceMinMaxFields = {} as PriceMinMaxFields,
  settingsFields = {} as SettingsFields,
  singleInputFields = {} as SingleInputFields,
  specialFields = {} as SpecialFields,
  tagsFields = {} as TagsFields,
  endDateTypeFields = {} as EndDateTypeFields,
  hashtagsFields = {} as HashtagsFields,
  textAreaFields = {} as TextAreaFields,
  textFields = {} as TextFields,
  numberFields = {} as NumberFields,
  selectFields = {} as SelectFields,
  questionFields = {} as QuestionFields,
  subTagFields = {} as SubTagFields,
  topicsFields = {} as TopicsFields,
  mapAllowanceFields = {} as MapAllowanceFields,
  mapAssignmentFields = {} as MapAssignmentFields,
  mapCurationFields = {} as MapCurationFields,
  translationScope,
  blockNavigation,
  handleGenericErrors,
  changedValues,
  setBlockNavigation,
  setIsQuestionRequired,
  setIsGeneralType,
}: {
  artistsFields?: ArtistsFields;
  businessTypeFields?: BusinessTypeFields;
  checkboxFields?: CheckboxFields;
  contactFields?: ContactFields;
  dateTimeRangeFields?: DateTimeRangeFields;
  hiddenFields?: HiddenFields;
  imageFields?: ImageFields;
  imageFieldsBUP?: ImageFieldsBUP;
  imageListFields?: ImageListFields;
  locationFields?: LocationFields;
  offerTypeFields?: OfferTypeFields;
  priceGroupFields?: PriceGroupFields;
  priceMinMaxFields?: PriceMinMaxFields;
  settingsFields?: SettingsFields;
  singleInputFields?: SingleInputFields;
  specialFields?: SpecialFields;
  tagsFields?: TagsFields;
  endDateTypeFields?: EndDateTypeFields;
  hashtagsFields?: HashtagsFields;
  textAreaFields?: TextAreaFields;
  textFields?: TextFields;
  numberFields?: NumberFields;
  selectFields?: SelectFields;
  questionFields?: QuestionFields;
  subTagFields?: SubTagFields;
  translationScope: string;
  changedValues?: Set<string>;
  topicsFields?: TopicsFields;
  mapAllowanceFields?: MapAllowanceFields;
  mapAssignmentFields?: MapAssignmentFields;
  mapCurationFields?: MapCurationFields;
  setBlockNavigation?: (shouldBlockNavigation: boolean) => void;
  setIsQuestionRequired?: (isQuestionRequired: boolean) => void;
  setIsGeneralType?: (isGeneralType: boolean) => void;
  blockNavigation?: boolean;
  handleGenericErrors?: boolean;
}) => {
  // Fields declarations
  type Fields = ArtistsFields &
    BusinessTypeFields &
    CheckboxFields &
    ContactFields &
    DateTimeRangeFields &
    HiddenFields &
    ImageFields &
    ImageListFields &
    LocationFields &
    OfferTypeFields &
    PriceGroupFields &
    PriceMinMaxFields &
    SettingsFields &
    SingleInputFields &
    SpecialFields &
    TagsFields &
    EndDateTypeFields &
    HashtagsFields &
    TextAreaFields &
    TextFields &
    NumberFields &
    SelectFields &
    QuestionFields &
    SubTagFields &
    TopicsFields &
    MapAllowanceFields &
    MapAssignmentFields &
    MapCurationFields;

  type FieldsWithRef = TextFields;

  const fields = {
    ...artistsFields,
    ...businessTypeFields,
    ...checkboxFields,
    ...contactFields,
    ...dateTimeRangeFields,
    ...hiddenFields,
    ...imageFields,
    ...imageFieldsBUP,
    ...imageListFields,
    ...locationFields,
    ...offerTypeFields,
    ...priceGroupFields,
    ...priceMinMaxFields,
    ...settingsFields,
    ...singleInputFields,
    ...specialFields,
    ...tagsFields,
    ...endDateTypeFields,
    ...hashtagsFields,
    ...textAreaFields,
    ...textFields,
    ...numberFields,
    ...selectFields,
    ...questionFields,
    ...subTagFields,
    ...topicsFields,
    ...mapAllowanceFields,
    ...mapAssignmentFields,
    ...mapCurationFields,
  };

  // Generic functions and helpers
  const internalChangedValues = useRef(new Set<string>());
  const { setShouldBlockNavigation, unblockNavigation } = useGlobalFormBlock();
  const { addErrorNotification } = useNotificationState();
  const [values, setValues] = useState(fields);
  const [initialValues, setInitialValues] = useState(fields);

  const isFirstUpdate = useRef(true);
  const updateValues = (nextValues: any) => {
    setValues({
      ...nextValues.artistsFields,
      ...nextValues.businessTypeFields,
      ...nextValues.checkboxFields,
      ...nextValues.contactFields,
      ...nextValues.dateTimeRangeFields,
      ...nextValues.hiddenFields,
      ...nextValues.imageFields,
      ...nextValues.imageFieldsBUP,
      ...nextValues.imageListFields,
      ...nextValues.locationFields,
      ...nextValues.offerTypeFields,
      ...nextValues.priceGroupFields,
      ...nextValues.priceMinMaxFields,
      ...nextValues.settingsFields,
      ...nextValues.singleInputFields,
      ...nextValues.specialFields,
      ...nextValues.tagsFields,
      ...nextValues.endDateTypeFields,
      ...nextValues.hashtagsFields,
      ...nextValues.textAreaFields,
      ...nextValues.textFields,
      ...nextValues.numberFields,
      ...nextValues.selectFields,
      ...nextValues.questionFields,
      ...nextValues.subTagFields,
      ...nextValues.subTagFields,
      ...nextValues.topicsFields,
      ...nextValues.mapAllowanceFields,
      ...nextValues.mapAssignmentFields,
      ...nextValues.mapCurationFields,
    });

    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;

      setInitialValues({
        ...nextValues.artistsFields,
        ...nextValues.businessTypeFields,
        ...nextValues.checkboxFields,
        ...nextValues.contactFields,
        ...nextValues.dateTimeRangeFields,
        ...nextValues.hiddenFields,
        ...nextValues.imageFields,
        ...nextValues.imageFieldsBUP,
        ...nextValues.imageListFields,
        ...nextValues.locationFields,
        ...nextValues.offerTypeFields,
        ...nextValues.priceGroupFields,
        ...nextValues.priceMinMaxFields,
        ...nextValues.settingsFields,
        ...nextValues.singleInputFields,
        ...nextValues.specialFields,
        ...nextValues.tagsFields,
        ...nextValues.endDateTypeFields,
        ...nextValues.hashtagsFields,
        ...nextValues.textAreaFields,
        ...nextValues.textFields,
        ...nextValues.numberFields,
        ...nextValues.questionFields,
        ...nextValues.subTagFields,
        ...nextValues.topicsFields,
        ...nextValues.mapAllowanceFields,
        ...nextValues.mapAssignmentFields,
        ...nextValues.mapCurationFields,
      });
    }
  };

  const [errors, setErrors] = useState<{ [key in keyof Fields]?: string[] }>(
    {},
  );
  const [genericError, setGenericErrorMessage] = useState("");

  const setGenericError = (errorMessage: string) => {
    setGenericErrorMessage(errorMessage);

    if (handleGenericErrors) {
      addErrorNotification(errorMessage);
    }
  };

  const getString = (name: string, options: TranslateOptions = {}) =>
    strings(name, { scope: translationScope, ...options });

  const getErrors = (field: keyof Fields): string[] =>
    (errors[field] || []) as string[];

  const getChildErrors = (field: keyof Fields): FormErrorsInterface => {
    const errObj: FormErrorsInterface = {};

    keys(errors).forEach(k => {
      if (startsWith(`${String(field)}`, `${String(k)}`)) {
        const id = replace(`${String(field)}.`, ``, `${String(k)}`);
        errObj[id] = errors[k];
      }
    });

    return errObj;
  };

  const flattenChildErrorsToArray = pipe(
    (childErrors: FormErrorsInterface) => Object.entries(childErrors),
    (entries: [string, string[] | undefined][]) =>
      entries.filter(([, value]) => !!value),
    (entries: [string, string[] | undefined][]) =>
      entries.map(([, value]) => value as string[]),
    flatArray,
  );

  const clearGenericError = () => setGenericError("");
  const clearErrors = () => setErrors({});

  const getCheckboxLabel = (field: keyof CheckboxFields) => {
    const path =
      field === "acceptedTermsAndConditions"
        ? "/agb"
        : field === "permissionToAnalyzeData"
        ? "/marketingzwecke"
        : field === "dataProtection" || field === "acceptedPrivacyPolicy"
        ? "/impressum-und-datenschutz"
        : null;

    if (!path) return getString(`${String(field)}.label`);

    return (
      <span>
        {getString(`${String(field)}.accept`)}{" "}
        <StyledLink target={"_blank"} to={path}>
          {getString(`${String(field)}.link`)}
        </StyledLink>{" "}
        {getString(`${String(field)}.afterLink`, { defaultValue: "" })}
      </span>
    );
  };

  // Fields focus functionality
  type FieldsRefs = { [key in keyof FieldsWithRef]: RefObject<any> };
  const fieldsRefs = {} as FieldsRefs;
  const applyRef = (key: keyof FieldsWithRef) => {
    fieldsRefs[key] = createRef<any>();
  };
  keys(textFields).forEach(applyRef);

  const focusField = (key: keyof FieldsWithRef) => {
    const field = fieldsRefs[key].current;
    if (field) field.focus();
  };

  const clearFieldErrors = (
    keyOrPath: keyof Fields | string,
    clearAllContainingErrorId?: boolean,
  ) => {
    setErrors(prevErrors => {
      const remainingErrors = clone(prevErrors);

      if (clearAllContainingErrorId) {
        for (const k of Object.keys(prevErrors)) {
          if (k.includes(keyOrPath as string)) {
            unset(remainingErrors, k);
          }
        }
      } else {
        unset(remainingErrors, keyOrPath);
      }

      return remainingErrors;
    });
  };

  const clearErrorByItsMessage = (
    errorField: keyof Fields,
    errorMessage: string,
  ) => {
    const remainingErrors = clone(errors);

    remainingErrors[errorField] = remainingErrors[errorField]
      ? remainingErrors[errorField]!.filter(error => error !== errorMessage)
      : [];

    setErrors(remainingErrors);
  };

  const updateInitialValues = () => setInitialValues(values);

  const onBlur = (
    field: keyof Fields,
    value: any,
    {
      trimValOnBlur = false,
    }: {
      trimValOnBlur?: boolean;
    },
  ) => {
    if (trimValOnBlur) setValues(assoc(field as string, value.trim()));
  };

  // On change fields
  const onChange = (
    field: keyof Fields,
    value: any,
    {
      isFirstDataLoad = false,
      clearOnlyTagsErrors = false,
      errorId,
      errorMessageToBeCleared,
      doNotClearErrors = false,
      clearAllContainingErrorId = false,
    }: {
      isFirstDataLoad?: boolean;
      clearOnlyTagsErrors?: boolean;
      errorId?: string; // field name or path for nested field
      errorMessageToBeCleared?: string; // clearing by name :(
      doNotClearErrors?: boolean;
      clearAllContainingErrorId?: boolean;
    } = {},
  ) => {
    if (values[field] === value) {
      return;
    }
    if (!doNotClearErrors) {
      if (errorId && errorMessageToBeCleared) {
        clearErrorByItsMessage(errorId, errorMessageToBeCleared);
      } else if (clearOnlyTagsErrors) {
        clearFieldErrors("tags");
      } else {
        clearFieldErrors(errorId || field, clearAllContainingErrorId);
      }
    }

    if (field === "location") {
      setValues(assoc(field as string, ""));
    }

    setValues(assoc(field as string, value));

    if (isFirstDataLoad) {
      // Do not validate for blockNavigation if the first load of data
      return;
    }

    if (blockNavigation) {
      const isDifferentFromInitialOne =
        JSON.stringify(value) !== JSON.stringify(initialValues[field]);

      isDifferentFromInitialOne
        ? internalChangedValues.current.add(field as string)
        : internalChangedValues.current.delete(field as string);

      const customBlockedNavigationString = getString(
        "blockedNavigationMessage",
        {
          defaultValue: strings("buttons.unsavedFormText"),
        },
      );
      setShouldBlockNavigation(
        internalChangedValues.current.size > 0,
        customBlockedNavigationString,
      );
    }
    if (changedValues) {
      const isDifferentFromInitialOne =
        JSON.stringify(value) !== JSON.stringify(initialValues[field]);

      isDifferentFromInitialOne
        ? changedValues.add(field as string)
        : changedValues.delete(field as string);
      if (setBlockNavigation) setBlockNavigation(changedValues.size > 0);
    }
  };

  // Get Props of the fields
  const getArtistsFieldProps = (
    field: keyof ArtistsFields,
  ): ArtistsInputProps => ({
    errors: [
      ...getErrors(field),
      ...flattenChildErrorsToArray(getChildErrors(field)),
    ],
    artists: values[field],
    onChange: (artists: Partial<Artist>[]) =>
      onChange(field, artists, { clearAllContainingErrorId: true }),
  });

  const getTextFieldProps = (
    field: keyof TextFields,
    errorId?: string,
  ): TextFieldProps => ({
    placeholder: getString(`${String(field)}.label`),
    errors: getErrors(field),
    value: values[field],
    inputRef: fieldsRefs[field],
    testId: String(field),
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      onChange(
        field,
        field === "email" ? e.target.value.trim() : e.target.value,
        {
          errorId,
        },
      ),
    ...textInputTestId(field as string),
  });

  const getNumberFieldProps = (field: keyof TextFields): InlineInputProps => ({
    value: values[field],
    placeholder: getString(`${String(field)}.label`),
    errors: getErrors(field),
    testId: String(field),
    setValue: (value: number | null) => onChange(field, value),
    ...numberInputTestId(field as string),
  });

  const getQuestionNumberFieldProps = (
    field: keyof TextFields,
    subField: keyof TextFields,
    index: number,
  ): InlineInputProps => ({
    value: values[field][index][subField],
    placeholder: getString(`${String(subField)}.label`),
    errors: extractErrorsForEachInput(field, `${String(subField)}`, index),
    setValue: (value: number | null) => {
      // We need to deep-copy the old values, ...
      const newValues = JSON.parse(JSON.stringify(values[field]));
      // ...because it would already change the values variable here...
      newValues[index][subField] = value;
      /// ...and then causes onChange to return without doing anything because old and new variable are equal
      onChange(field, newValues, { clearAllContainingErrorId: true });
    },
  });

  const getQuestionPictureFieldProps = (
    field: keyof TextFields,
    subField: keyof TextFields,
    index: number,
  ): {
    imageUrl?: string | null;
    setImageUrl?: (image: string | null) => void;
  } => ({
    imageUrl: values[field][index][subField],
    setImageUrl: (value: string | null) => {
      // We need to deep-copy the old values, ...
      const newValues = JSON.parse(JSON.stringify(values[field]));
      // ...because it would already change the values variable here...
      newValues[index][subField] = value;
      /// ...and then causes onChange to return without doing anything because old and new variable are equal
      onChange(field, newValues, { clearAllContainingErrorId: true });
    },
  });

  const getSmartServiceLogoPictureFieldProps = (
    field: keyof TextFields,
  ): {
    imageUrl?: string | null;
    setImageUrl?: (image: string | null) => void;
  } => {
    return {
      imageUrl: values[field],
      setImageUrl: (value: string | null) => {
        onChange(field, value, { clearAllContainingErrorId: true });
      },
    };
  };

  const getTextAreaFieldProps = (
    field: keyof TextAreaFields,
  ): TextAreaProps => ({
    placeholder: getString(`${String(field)}.label`),
    errors: getErrors(field),
    value: values[field],
    testId: String(field),
    onContentChange: content => onChange(field, content),
    onChange: e => onChange(field, e.target.value),
    onBlur: e => onBlur(field, e.target.value, { trimValOnBlur: true }),
  });

  const getSingleInputFieldProps = (
    field: keyof SingleInputFields,
  ): SingleInputFieldProps => ({
    placeholder: getString(`${String(field)}.label`),
    errors: getErrors(field),
    value: values[field],
    testId: String(field),
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.validity.valid) {
        onChange(field, e.target.value);
      }
    },
    ...textInputTestId(field as string),
  });

  const getQuestionInputFieldProps = (
    field: keyof TextFields,
    subField: string,
    index: number,
  ): QuestionInputFieldProps => ({
    errors: extractErrorsForEachInput(field, subField, index),
    value: values[field][index][subField],
    placeholder: getString(`${subField}.label`),
    inputPlaceholder: getString(`${subField}.placeholder`),
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      // We need to deep-copy the old values, ...
      const newValues = JSON.parse(JSON.stringify(values));
      // ...because it would already change the values variable here...
      newValues[field][index][subField] = e.target.value;
      /// ...and then causes onChange to return without doing anything because old and new variable are equal
      onChange(field, newValues[field], { clearAllContainingErrorId: true });
    },
  });

  const getMultipleFieldFormProps = (
    field: keyof TextFields,
  ): MultipleFieldFormProps => ({
    fields: values[field],
    onMainCategoryAdd: () => {
      const newValues = [
        ...values.questions,
        {
          question: "",
          label: "",
          answers: [],
        },
      ];
      onChange("questions", [...newValues], {
        clearAllContainingErrorId: true,
      });
    },
    onSubCategoryAdd: (isParentActive: boolean) => {
      const newValues = [
        ...values.subTags,
        {
          code: "",
          showAsInterests: false,
          showAsDistrict: false,
          tagStatus: isParentActive ? "" : CategoryStatuses.inactive,
          weight: undefined,
          tagType: "",
        },
      ];
      onChange("subTags", [...newValues], { clearAllContainingErrorId: true });
    },
  });

  const getTagFieldProps = (field: keyof TagsFields): CategorySelectProps => ({
    type: "", // Default - Empty type
    tags: values[field] ? values[field] : [],
    onTagChange: (
      tags: TagInterface[],
      errorMessage: string,
      isFirstDataLoad = false,
    ) =>
      onChange(field, tags, {
        isFirstDataLoad,
        errorId: "tagIds",
        errorMessageToBeCleared: errorMessage,
      }),
    fieldErrors: getErrors(field),
    buttonText: getString(`${String(field)}.buttonText`),
    title: getString(`${String(field)}.title`),
  });

  const getHashtagsFieldProps = (
    field: keyof HashtagsFields,
  ): HashtagsFieldProps => ({
    value: values[field],
    setValue: (newValue?: string) => onChange(field, newValue),
  });

  const getSinglePictureProps = (): any => ({
    imageUrl: values.imageUrl || null,
    setImageUrl: (imageUrl: string | null) => {
      onChange("imageUrl", imageUrl);
    },
    errors: getErrors("imageUrl"),
  });

  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const getBusinessHoursProps = (): BusinessHoursProps => ({
    weekdays,
    labelText: getString("businessHours.label"),
    businessHours: values.businessHours,
    businessHoursCommentLabel: getString("businessHours.commentLabel"),
    changeBusinessHoursComment: (comment: string) =>
      onChange(
        "businessHours",
        {
          ...values.businessHours,
          comment,
        },
        { errorId: "businessHours.comment" },
      ),
    changeBusinessHoursWeekdayStart: (weekday: string, startTime: string) => {
      const fieldName = `${weekday}Start`;
      onChange(
        "businessHours",
        {
          ...values.businessHours,
          [fieldName]: startTime,
        },
        { errorId: `businessHours.${weekday}Enabled` },
      );
    },
    changeBusinessHoursWeekdayEnd: (weekday: string, endTime: string) => {
      const fieldName = `${weekday}End`;
      onChange(
        "businessHours",
        {
          ...values.businessHours,
          [fieldName]: endTime,
        },
        { errorId: `businessHours.${weekday}Enabled` },
      );
    },
    changeBusinessHours: (value: BusinessHoursInterface) =>
      onChange("businessHours", value),
    toggleBusinessHoursWeekdayEnabled: (weekday: string) => {
      const enabledField = `${weekday}Enabled`;
      const startField = `${weekday}Start`;
      const endField = `${weekday}End`;
      const currentDayEnabledValue = values.businessHours[enabledField];
      onChange(
        "businessHours",
        currentDayEnabledValue
          ? {
              ...values.businessHours,
              [enabledField]: !values.businessHours[enabledField],
              [startField]: null,
              [endField]: null,
            }
          : {
              ...values.businessHours,
              [enabledField]: !values.businessHours[enabledField],
            },
        { errorId: `businessHours.${enabledField}` },
      );
    },
    weekdayFieldErrors: weekdays.reduce(
      (errorsMap, weekday) => ({
        ...errorsMap,
        [weekday]: [
          ...getErrors(`businessHours.${weekday}Enabled`),
          ...getErrors(`businessHours.${weekday}Start`),
          ...getErrors(`businessHours.${weekday}End`),
        ],
      }),
      {},
    ),
    commentErrors: getErrors("businessHours.comment"),
  });

  const getBusinessTypeProps = (): BusinessTypeProps => {
    return {
      setBlockNavigation,
      tags: values.tags ? values.tags : [],
      subTags: values.subTags ? values.subTags : [],
      onChange: (
        types: string[],
        answers: string[],
        isFirstDataLoad?: boolean,
        clearOnlyTagsErrors?: boolean,
        errorId?: string,
        errorMessageToBeCleared?: string,
      ) => {
        onChange("tags", types, { isFirstDataLoad });
        onChange("subTags", answers, {
          isFirstDataLoad,
          clearOnlyTagsErrors,
          errorId,
          errorMessageToBeCleared,
        });
      },
      onChangeTypes: (types: string[], isFirstDataLoad?: boolean) => {
        onChange("tags", types, { isFirstDataLoad });
      },
      tagErrors: getErrors("tagsIds"),
      subTagErrors: getErrors("subTagsIds"),
    };
  };

  const getImageListProps = (): Omit<
    PictureGalleryProps,
    "smallPictureContainer"
  > => {
    return {
      pictures: values.pictures || null,
      setPictures: (p: ImageItem[]) => {
        onChange(
          "pictures",
          p.filter(pic => pic.url !== null),
        );
      },
      smallImageUrl: values.smallImageUrl || null,
      setSmallImage: (p: ImageItem) => {
        onChange("smallImageUrl", p.url);
      },
      smallPictureErrors: getErrors("smallImageUrl"),
      picturesErrors: getErrors("pictures"),
    };
  };

  const getImprintProps = (): ImprintProps => ({
    imprintInformation: values.imprintInformation,
    onChangeImprintField: (field: string, e: ChangeEvent<HTMLInputElement>) =>
      onChange(
        "imprintInformation",
        {
          ...values.imprintInformation,
          [field]: e.target.value,
        },
        { errorId: `imprintInformation.${field}` },
      ),
    onChangeLocation: (location: Location) => {
      const changedFields = Object.keys(
        pickBy(location, (v, k) => v !== values.imprintInformation[k]),
      );

      onChange(
        "imprintInformation",
        {
          ...values.imprintInformation,
          ...location,
        },
        { errorId: `imprintInformation.${changedFields[0]}` }, // should change one field at a time
      );
    },
    onChangeField: (field: string, value: any) =>
      onChange(
        "imprintInformation",
        {
          ...values.imprintInformation,
          [field]: value,
        },
        { errorId: `imprintInformation.${field}` },
      ),
    onCopyContacts: () => {
      const isPinned =
        values.location.pinned && values.location.pinned.toString() === "true";

      onChange(
        "imprintInformation",
        {
          ...values.imprintInformation,
          ...values.location,
          website: values.website,
          email: values.email,
          phone: values.phone,
          legalName: "",
          businessName: values.name,
          venue: values.venue,
          street: isPinned ? undefined : values.location.street,
          houseNo: isPinned ? undefined : values.location.houseNo,
          postalCode: isPinned ? undefined : values.location.postalCode,
          city: isPinned ? undefined : values.location.city,
        },
        { clearAllContainingErrorId: true },
      );
    },
    errors: getChildErrors("imprintInformation"),
  });

  const getPaymentAddressProps = (
    setSavedDetails: (savedPaymentAddress: PaymentAddressDTO) => void,
  ): PaymentAddressProps => ({
    paymentAddress: values.paymentAddress,
    onChangePaymentAddressField: (
      field: string,
      e: ChangeEvent<HTMLInputElement>,
    ) => {
      onChange("paymentAddress", {
        ...values.paymentAddress,
        [field]: e.target.value,
      });
    },
    onChangeLocation: (location: Location) =>
      onChange("paymentAddress", {
        ...values.paymentAddress,
        ...location,
      }),
    errors: getChildErrors("paymentAddress"),
    setSavedPaymentAddressDetails: setSavedDetails,
  });

  const getPriceGroupFieldProps = (): DiscountPricesProps => ({
    price: values.price,
    discountPrice: values.discountPrice,
    discountPercentage: values.discountPercentage,
    changePrice: (value: number | null) => onChange("price", value),
    changeDiscountPrice: (value: number | null) =>
      onChange("discountPrice", value),
    changeDiscountPercentage: (value: number | null) =>
      onChange("discountPercentage", value),
    priceError: getErrors("price"),
    discountPriceError: getErrors("discountPrice"),
    discountPercentageError: getErrors("discountPercentage"),
  });

  const getOfferTypeFieldProps = (): OfferTypeOptionsProps => ({
    disabled: false,
    type: values.type || OfferTypes.promotion,
    promotionCode: values.promotionCode,
    quantityTotal: values.quantityTotal,
    eShopUrl: values.eShopUrl,
    prominentShoppingUrl: values.prominentShoppingUrl,
    prominentShoppingEnabled: values.prominentShoppingEnabled,
    quantityTotalErrors: getErrors("quantityTotal"),
    promotionCodeErrors: getErrors("promotionCode"),
    eShopUrlErrors: getErrors("eShopUrl"),
    prominentShoppingUrlErrors: getErrors("prominentShoppingUrl"),
    changeType: (type: OfferTypes) => onChange("type", type),
    changeCouponQuantity: (value: number | null) =>
      onChange("quantityTotal", value),
    changePromoCode: (e: ChangeEvent<HTMLInputElement>) =>
      onChange("promotionCode", e.target.value),
    changeEShopUrl: (e: ChangeEvent<HTMLInputElement>) =>
      onChange("eShopUrl", e.target.value),
    changeProminentShoppingUrl: (e: ChangeEvent<HTMLInputElement>) =>
      onChange("prominentShoppingUrl", e.target.value),
  });

  const getLocationFieldProps = (
    field: keyof LocationFields,
  ): LocationInputProps => ({
    location: values[field],
    onChangeLocation: (location: Location) => onChange(field, location),
    errors: getChildErrors(field),
  });

  const getAddressName = (
    location: AddressLocation = {} as AddressLocation,
  ) => {
    if (location.pinned === "true") {
      return location.pinnedDescription ? location.pinnedDescription : "";
    }

    let address = "";
    if (location.street) {
      address = location.street;
    }

    if (location.houseNo) {
      address = address.concat(" ").concat(location.houseNo);
    }

    if (location.postalCode) {
      address = address.concat(", ").concat(location.postalCode);
    }

    if (location.city) {
      address = address.concat(", ").concat(location.city);
    }

    return address;
  };

  const getContactInformationAddressFieldProps = () => {
    const locationAddressErrors = getErrors("contactInformationAddress");
    const contactInformationChildErrors = flattenChildErrorsToArray(
      omit(getChildErrors("contactInformation"), ["phone", "email", "venue"]),
    );

    const contactInfoErrors = locationAddressErrors.length
      ? locationAddressErrors
      : contactInformationChildErrors;

    return {
      value: getAddressName(values.location),
      onSelect: (location: AddressLocation) => onChange("location", location),
      onChange: () => {
        clearFieldErrors("contactInformationAddress");

        // Clear address fields, but not the rest of contact info
        clearFieldErrors("contactInformation.city");
        clearFieldErrors("contactInformation.houseNo");
        clearFieldErrors("contactInformation.postalCode");
        clearFieldErrors("contactInformation.street");
        clearFieldErrors("contactInformation.pinned");
        clearFieldErrors("contactInformation.pinnedDescription");
        clearFieldErrors("contactInformation.latitude");
        clearFieldErrors("contactInformation.longitude");

        clearFieldErrors("location", true);
      },
      errors: contactInfoErrors,
    };
  };

  const getLocationAddressFieldProps = () => {
    const locationAddressErrors = getErrors("locationAddress");
    const locationChildErrors = flattenChildErrorsToArray(
      omit(getChildErrors("location"), ["venue"]),
    );

    const relevantErrors = locationAddressErrors.length
      ? locationAddressErrors
      : locationChildErrors;

    return {
      value: getAddressName(values.location),
      onSelect: (location: AddressLocation) => onChange("location", location),
      onChange: () => {
        clearFieldErrors("locationAddress");

        // Clear address fields, but not the rest of contact info
        clearFieldErrors("location.city");
        clearFieldErrors("location.houseNo");
        clearFieldErrors("location.postalCode");
        clearFieldErrors("location.street");
        clearFieldErrors("location.pinned");
        clearFieldErrors("location.pinnedDescription");
        clearFieldErrors("location.latitude");
        clearFieldErrors("location.longitude");
      },
      errors: relevantErrors,
    };
  };

  const getPinnedLocationAddressDescriptionFieldProps = (
    locationAddressFieldName: string,
    addressObjectName: string,
  ) => {
    const addressObjectErrors = getChildErrors(addressObjectName);
    const pinnedDescriptionErrors =
      addressObjectErrors && addressObjectErrors.pinnedDescription
        ? addressObjectErrors.pinnedDescription
        : [];
    const locationAddressErrors = getErrors(locationAddressFieldName);

    return {
      pinnedDescriptionErrors,
      locationAddressErrors,
      value: values.pinnedDescription,
      onPinLocation: (location: AddressLocation) => {
        onChange("location", location);
        onChange("pinnedDescription", location.pinnedDescription);
        clearFieldErrors(locationAddressFieldName);
        clearFieldErrors(addressObjectName);
        clearFieldErrors("location");
      },
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        onChange("pinnedDescription", e.target.value);
        clearFieldErrors(locationAddressFieldName);
        clearFieldErrors(addressObjectName);
        clearFieldErrors("location");
        clearFieldErrors("pinnedDescription");
      },
    };
  };

  const getDateTimeRangeFieldProps = (
    field: keyof DateTimeRangeFields,
  ): DateTimeRangePickerProps => ({
    value: {
      start: values[field].dateTimeFrom || null,
      end: values[field].dateTimeTill || null,
    },
    setValue: (value: DateTimeRangeInterface, position?: "start" | "end") => {
      const newRange = {
        dateTimeFrom: value.start,
        dateTimeTill: value.end,
      };

      onChange(field, newRange, {
        errorId: position
          ? position === "start"
            ? "dateTimeRange.dateTimeFrom"
            : "dateTimeRange.dateTimeTill"
          : undefined,
      });
    },
    errors: getChildErrors(field as string),
  });

  const getEndDateTypeOptionFieldProps = (
    field: keyof EndDateTypeFields,
  ): EndDateTypeFieldProps => {
    return {
      value: values[field],
      setValue: (newValue?: EndDateType) => onChange(field, newValue),
    };
  };

  const getPriceMinMaxFieldProps = (
    field: keyof PriceMinMaxFields,
  ): PriceRangeProps => ({
    value: values[field],
    onChange: (priceMinMax: PriceMinMax) => {
      onChange(field, priceMinMax, { clearAllContainingErrorId: true });
    },
    errors: getChildErrors(field as string),
  });

  const alertToBusinessValue = "alertToBusiness";
  const getCheckboxFieldProps = (
    field: keyof CheckboxFields,
  ): CheckboxProps => {
    const isAlertToBusiness = field === alertToBusinessValue;
    const isCurrentValueTrue = (values[field] as any) === true;
    const isAlertToBusinessChangingToTrue =
      isAlertToBusiness && !isCurrentValueTrue;
    const isAlertToBusinessTrue = values[alertToBusinessValue] === true;

    const onChangeForCheckboxes = isAlertToBusinessChangingToTrue
      ? () => {
          onChange(field, !values[field]);

          // When alertToBusiness becomes true, other values must become false
          onChange("publishAlert", false);
          onChange("publishPushNotification", false);
          onChange("topicOfTheWeek", false);
          onChange("smartService", false);
          onChange("jobOffer", false);
        }
      : isAlertToBusiness
      ? () => onChange(field, !values[field])
      : isAlertToBusinessTrue
      ? () => onChange(field, false) // When alertToBusiness is true, other values must be false
      : () => onChange(field, !values[field]);

    return {
      text: getCheckboxLabel(field),
      checked: values[field],
      name: field as string,
      onChange: onChangeForCheckboxes,
      ...checkBoxTestId(field as string),
    };
  };

  const getQuestionCheckboxFieldProps = (
    field: keyof TextFields,
    subField: keyof CheckboxFields,
    index: number,
  ): CheckboxProps => ({
    text: getCheckboxLabel(subField),
    checked: values[field][index][subField],
    name: subField as string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      // We need to deep-copy the old values, ...
      const newValues = JSON.parse(JSON.stringify(values[field]));
      // ...because it would already change the values variable here...
      newValues[index][subField] = e.target.checked;
      /// ...and then causes onChange to return without doing anything because old and new variable are equal
      onChange(field, newValues);
    },
  });

  // Custom specific hardcoded fields handling
  const getSettingsFieldProps = (): SettingsAreaProps => {
    return {
      setBlockNavigation,
      title: getString(`settingsField.title`),
      enableComments: values.enableComments,
      enableRatings: values.enableRatings,
      publishDateTime: values.publishDateTime,
      unpublishDateTime: values.unpublishDateTime,
      toggleComments: () => onChange("enableComments", !values.enableComments),
      toggleRatings: () => onChange("enableRatings", !values.enableRatings),
      changePublishDateTime: (publishDateTime: Date | null) =>
        onChange("publishDateTime", publishDateTime),
      changeUnpublishDateTime: (unpublishDateTime: Date | null) =>
        onChange("unpublishDateTime", unpublishDateTime),
      publishDateTimeErrors: getErrors("publishDateTime"),
      unpublishDateTimeErrors: getErrors("unpublishDateTime"),
      enableCommentsErrors: getErrors("enableComments"),
      enableRatingsErrors: getErrors("enableRatings"),
    };
  };

  const getSelectFieldProps = (
    field: keyof SelectFields,
    options: SelectItem[] = [],
  ): SelectPropsInterface => ({
    options,
    placeholder: "",
    errors: getErrors(field),
    value: find(propEq("value", values[field]))(options),
    onChange: (e: SelectItem) => {
      if (field === "tagType") {
        if (setIsQuestionRequired) {
          isQuestionRequiredValue(e.value)
            ? setIsQuestionRequired(true)
            : setIsQuestionRequired(false);
        }
        if (setIsGeneralType) {
          const isGeneral = e.value
            ? isGeneralTypeValue(String(e.value))
            : false;
          setIsGeneralType(isGeneral);
          const showAsDistrictField = "showAsDistrict";
          if (!isGeneral && values[showAsDistrictField]) {
            // reset show as district flag
            onChange(showAsDistrictField, false);
          }
        }
      }

      return onChange(field, e.value);
    },
  });

  const getQuestionSelectFieldProps = (
    field: keyof TextFields,
    subField: keyof SelectFields,
    index: number,
    options: SelectItem[] = [],
  ): SelectPropsInterface => ({
    options,
    errors: extractErrorsForEachInput(field, `${String(subField)}`, index),
    placeholder: "",
    value: find(propEq("value", values[field][index][subField]))(options),
    onChange: (e: SelectItem) => {
      // We need to deep-copy the old values, ...
      const newValues = JSON.parse(JSON.stringify(values[field]));
      // ...because it would already change the values variable here...
      newValues[index][subField] = e.value;
      /// ...and then causes onChange to return without doing anything because old and new variable are equal
      return onChange(field, newValues);
    },
  });

  const extractErrorsForEachInput = (
    field: keyof TextFields,
    subField: string,
    index: number,
  ): string[] | undefined => {
    const allErrors = getChildErrors(field);
    const errorsForAnInput = `${String(field)}[${index}].${subField}`;
    return allErrors[errorsForAnInput];
  };

  // Errors handling
  const parseErrors = (response: APIErrorResponse) => {
    if (!response) return;

    const newErrors: { [key in keyof Fields]?: string[] } = {};
    if (response.fieldsErrors) {
      for (const item of response.fieldsErrors) {
        const fieldName = item.field as keyof Fields;
        const message = item.message;
        if (Object.prototype.hasOwnProperty.call(newErrors, fieldName)) {
          newErrors[fieldName]!.push(message);
        } else {
          newErrors[fieldName] = [message];
        }
      }
    }

    delete newErrors.profileState;

    setErrors(newErrors);

    if (response.detail) {
      setGenericError(response.detail);
    }
  };

  const getErrorCount = () =>
    Object.keys(errors).reduce(
      (errorCount, field) => errorCount + (errors[field] || [])!.length,
      0,
    );

  return {
    genericError,
    clearErrors,
    clearGenericError,
    getErrors,
    getChildErrors,
    errors,
    values,
    setErrors,
    setGenericError,
    getArtistsFieldProps,
    getBusinessTypeProps,
    getBusinessHoursProps,
    getImageListProps,
    getImprintProps,
    getTextFieldProps,
    getNumberFieldProps,
    getTextAreaFieldProps,
    getCheckboxFieldProps,
    getTagFieldProps,
    getHashtagsFieldProps,
    getSinglePictureProps,
    getSettingsFieldProps,
    getPriceGroupFieldProps,
    getOfferTypeFieldProps,
    getPriceMinMaxFieldProps,
    getDateTimeRangeFieldProps,
    getEndDateTypeOptionFieldProps,
    getLocationFieldProps,
    getContactInformationAddressFieldProps,
    getLocationAddressFieldProps,
    getPinnedLocationAddressDescriptionFieldProps,
    getSingleInputFieldProps,
    getSelectFieldProps,
    getQuestionInputFieldProps,
    getQuestionCheckboxFieldProps,
    getQuestionNumberFieldProps,
    getQuestionSelectFieldProps,
    getQuestionPictureFieldProps,
    getMultipleFieldFormProps,
    getSmartServiceLogoPictureFieldProps,
    getPaymentAddressProps,
    focusField,
    updateInitialValues,
    onChange,
    parseErrors,
    updateValues,
    unblockNavigation,
    isValid: getErrorCount() === 0,
  };
};

export default useFormFields;
