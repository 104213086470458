import AuthedAPI from "api/AuthedAPI";
import RequestOptions from "api/RequestOptions";
import TopicInterface from "api/models/TopicInterface";

const notificationAPI = new AuthedAPI({});

const getTopicsList = (options: RequestOptions | undefined) =>
  notificationAPI.getJson<{ topics: TopicInterface[] }>({
    options,
    path: `notifications/sai/topics`,
  });

export { notificationAPI, getTopicsList };
