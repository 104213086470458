import { useState, useEffect, useCallback } from "react";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import useMutation from "common/hooks/useMutation";
import strings from "localisation/strings";
import { setNewStatus } from "components/forms/BusinessRepresentative/helpers";
import RequestOptions from "api/RequestOptions";
import { removeEmptyValuesKeepEmptyArrays } from "components/helpers/utils";
import { ExtendedRepresentativeInterface } from "api/models/RepresentativeInterface";
import useBusinessRepresentativeRoles from "hooks/useBusinessRepresentativeRoles";
import useNotificationState from "hooks/useNotification";

interface HookProps<T> {
  representative: Partial<T> | undefined;
  values: Partial<T>;
  parseErrors: (body: APIErrorResponse) => void;
  updateValues: (nextValues: any) => void;
  saveRequest: (
    body: Partial<T>,
    options?: RequestOptions,
  ) => Promise<{ result: Partial<T>; headers: Headers }>;
  addNewRequest: (
    body: Partial<T>,
    options?: RequestOptions,
  ) => Promise<{ result: Partial<T>; headers: Headers }>;
  setBlockNavigation?: (state: boolean) => void;
  businessId?: string;
  isAdminView?: boolean;
}

function useBusinessRepresentativeForm<
  T extends ExtendedRepresentativeInterface
>({
  representative,
  values,
  updateValues,
  parseErrors,
  saveRequest,
  addNewRequest,
  setBlockNavigation,
  businessId,
  isAdminView,
}: HookProps<T>) {
  const { addSuccessNotification } = useNotificationState();
  const [representativeData, setRepresentativeData] = useState(representative);

  const getPayload = (): Partial<T> | undefined => values;

  const onFailed = (body: APIErrorResponse) => parseErrors(body);

  const updateFieldsValues = useCallback(
    (response: Partial<T>) => {
      updateValues({
        hiddenFields: {
          currentLoggedIn: response.currentLoggedIn,
          userId: response.userId,
          status: response.status,
        },
        textFields: {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          phone: response.phone,
        },
        selectFields: {
          role: response.role,
          phoneCode: response.phoneCode,
        },
        mapCurationFields: {
          mapCuration: response.mapCuration,
        },
      });
    },
    [updateValues],
  );

  useEffect(() => {
    if (representative) {
      updateFieldsValues(representative);
      setRepresentativeData(representative);
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representative]);

  // get Roles list
  const { roles, error } = useBusinessRepresentativeRoles();
  if (error) onFailed(error);

  const onSuccess = (responseRepresentative: Partial<T>, message: string) => {
    addSuccessNotification(message);
    updateFieldsValues(responseRepresentative);
    setRepresentativeData(responseRepresentative);
    if (setBlockNavigation) setBlockNavigation(false);
  };

  const addRepresentative = useMutation(addNewRequest);

  const saveEdits = useMutation(saveRequest);

  const isSubmitting =
    addRepresentative.status === "WAITING" || saveEdits.status === "WAITING";

  const submitAction = async (
    request: typeof saveEdits,
    fields: Partial<T>,
    onSuccessMessage: string,
  ) => {
    const response =
      (await request.makeRequest(removeEmptyValuesKeepEmptyArrays(fields))) ||
      {};
    if (response.error) return onFailed(response.error);
    if (response.result) {
      onSuccess(response.result, onSuccessMessage);
    }
  };

  const addNewRepresentative = () => {
    const fields: Partial<T> | undefined = getPayload();
    if (fields) {
      fields.businessId = businessId;
      return submitAction(
        addRepresentative,
        fields,
        strings("addRepresentativeForm.successAddMessage"),
      );
    }
  };

  const saveRepresentative = () => {
    const fields: Partial<T> | undefined = getPayload();
    if (fields) {
      fields.businessId = businessId;
      return submitAction(
        saveEdits,
        fields,
        strings(
          isAdminView
            ? "addRepresentativeForm.successUpdateMessageAdmin"
            : "addRepresentativeForm.successUpdateMessage",
        ),
      );
    }
  };

  const changeRepresentativeStatus = () => {
    const fields: Partial<T> | undefined = getPayload();
    if (fields) {
      setNewStatus(fields);
      fields.businessId = businessId;
      const message =
        representativeData && representativeData.status === "ACTIVE"
          ? strings(
              isAdminView
                ? "addRepresentativeForm.successDeactivationMessageAdmin"
                : "addRepresentativeForm.successDeactivationMessage",
            )
          : strings(
              isAdminView
                ? "addRepresentativeForm.successActivationMessageAdmin"
                : "addRepresentativeForm.successActivationMessage",
            );
      return submitAction(saveEdits, fields, message);
    }
  };

  return {
    addNewRepresentative,
    changeRepresentativeStatus,
    isSubmitting,
    representativeData,
    saveRepresentative,
    roles,
  };
}

export default useBusinessRepresentativeForm;
