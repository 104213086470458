import React, { useEffect, useRef, useState } from "react";
import { curry } from "ramda";

import { getDateLabel } from "utils";
import mainTheme from "style/theme";
import strings from "localisation/strings";
import NewsInterface from "api/models/NewsInterface";
import EntityTypes from "api/models/EntityTypes";
import {
  deleteReplyToReviewForNews,
  getReviewsForNews,
  saveReplyToReviewForNews,
} from "api/news";

import { Title } from "components/generic";
import TitleInput from "components/forms/ValidatedFields/TitleInput";
import TagSelectionField from "components/forms/TagSelection/TagSelectionField";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import PictureGallery from "components/forms/FormFields/PictureGallery";
import {
  SaveStatusDisplay,
  Separator,
  SettingsArea,
} from "components/forms/FormFields";
import ReviewsAndRatings from "components/review/ReviewsAndRatings";
import SelectComponent from "components/forms/Select";
import DescriptionBlock from "components/forms/DescriptionBlock";
import Checkbox from "components/forms/Checkbox";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

import useFormFields from "hooks/useFormFields";
import { getActionArea } from "components/helpers/formHelpers";
import {
  defaultNewsFields,
  getNewsPayload,
  setNewsFields,
} from "components/helpers/formHelpers/news";
import { SubmitRequest } from "components/forms/SubmitButton";

import GeoLocations from "constants/GeoLocationOptions";
import userIcon from "assets/icons/user.svg";
import date from "common/assets/icons/date.svg";
import NewsPayloadInterface from "api/models/NewsPayloadInterface";
import ImageUploadType from "api/models/ImageUploadType";
import InFrameContentFields from "components/forms/FormFields/InFrameContentFields";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import PublicationStatuses from "api/models/PublicationStatuses";
import useMutation from "common/hooks/useMutation";
import { useLocation } from "react-router-dom";
import { COPY } from "constants/strings";
import HashtagsField from "components/forms/HashtagsField";
import { useTagRaceConditionCatcher } from "hooks/useTagRaceConditionCatcher";

// The max-height has aspect ratio scaling applied to keep margins between components same as before image box rescaling
const ImageBox = styled.div`
  max-height: ${({ theme }) =>
    500 + 500 * ((100 - theme.image.aspectRatio) / 100)}px;

  /*
    IE10+ CSS to fix an IE bug that causes flex box overflow.
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 0 auto;
  }

  @media ${breakpoints.tabletMax} {
    max-height: inherit;
  }
`;

const NewsForm = ({
  isNew,
  createNew,
  createDraft,
  success,
  result,
  changedValues,
  setBlockNavigation,
  businessId,
}: {
  isNew: boolean;
  createNew: SubmitRequest<NewsPayloadInterface, NewsInterface>;
  createDraft: SubmitRequest<NewsPayloadInterface, NewsInterface>;
  success: (message: string, response: NewsInterface) => void;
  result?: Partial<NewsInterface>;
  changedValues: Set<string>;
  setBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  businessId?: string;
}) => {
  const [oldContent, setOldContent] = useState<Partial<NewsInterface>>();
  const [isSavingBlocked, setIsSavingBlocked] = useState<boolean>(false);

  const {
    getErrors,
    parseErrors,
    clearErrors,
    values,
    isValid,
    updateValues,
    getImageListProps,
    getTextAreaFieldProps,
    getTagFieldProps,
    getSelectFieldProps,
    getSettingsFieldProps,
    getCheckboxFieldProps,
    getTextFieldProps,
    getHashtagsFieldProps,
  } = useFormFields({
    ...defaultNewsFields,
    changedValues,
    setBlockNavigation,
    translationScope: "newsScreen.fields",
  });

  const updateValuesRef = useRef(updateValues);

  useEffect(() => {
    if (result) {
      setOldContent(result);
      const updatedFormFields = setNewsFields(result);
      updateValuesRef.current(updatedFormFields);
    }
  }, [result]);

  const location = useLocation();
  const isCopied = location.pathname.endsWith(COPY);

  let getReviewsRequest;
  let saveReviewReplyRequest;
  let deleteReviewReplyRequest;
  if (result && result.id) {
    getReviewsRequest = curry(getReviewsForNews)(result.id);
    saveReviewReplyRequest = curry(saveReplyToReviewForNews)(result.id);
    deleteReviewReplyRequest = curry(deleteReplyToReviewForNews)(result.id);
  }

  const { makeRequest } = useMutation(createNew);
  const { onRequestFinished, filterFieldErrors } = useTagRaceConditionCatcher();

  useEffect(() => {
    if (isCopied) return;

    // this makes a publish request on component mount to get all required fields
    makeRequest({
      id: "",
      createdDateTime: "",
      enableComments: values.enableComments,
      enableRatings: values.enableRatings,
      geoLocation: values.geoLocation,
      tagIds: [],
      status: PublicationStatuses.published,
      hideFrameConfirmation: true,
    }).then(e => {
      if (e && e.error) {
        parseErrors({
          ...e.error,
          detail: undefined,
          fieldsErrors: filterFieldErrors(e.error),
        });
      }
    });
  }, [location]);

  return (
    <>
      {!isNew && result && result.status && (
        <SaveStatusDisplay
          status={result.status}
          modifiedDate={result.modifiedDateTime || null}
        />
      )}

      <TitleInput {...getTextAreaFieldProps("title")} focusOnOpen />

      <TagSelectionField
        {...getTagFieldProps("tags")}
        fieldErrors={getErrors("tagIds")}
        type={EntityTypes.news}
        setIsSavingBlocked={setIsSavingBlocked}
        isNew={isNew}
        isCopied={isCopied}
        businessId={businessId}
        onTagsLoaded={onRequestFinished}
      />

      <HashtagsField {...getHashtagsFieldProps("hashtags")} />

      <InlineInputGroup
        icon={userIcon}
        placeholder={strings("newsScreen.fields.geoLocation.label")}
        width={mainTheme.forms.leftSide}
      >
        <SelectComponent
          {...getSelectFieldProps("geoLocation", GeoLocations)}
          classNamePrefix="geoLocationSelect"
          small
        />
      </InlineInputGroup>

      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS_ADMIN}>
        <InFrameContentFields
          textFieldProps={getTextFieldProps("inFrameUrl")}
          frameUrlErrors={getErrors("inFrameUrl")}
        />
      </PrivilegedComponent>

      <ImageBox>
        <PictureGallery
          {...getImageListProps()}
          container={ImageUploadType.NEWS}
          smallPictureContainer={ImageUploadType.NEWS}
          height="330px"
          parseErrors={parseErrors}
          updateKey={result ? result.modifiedDateTime : undefined}
        />
      </ImageBox>

      <DescriptionBlock {...getTextAreaFieldProps("description")} richText />

      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_NEWS_ADMIN}>
        <Separator />
        <Title>{strings("newsScreen.fields.publishAs.title")}</Title>
        <InlineInputGroup icon={date} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("topicOfTheWeek")}
            dateLabel={getDateLabel(result)}
          />
        </InlineInputGroup>
      </PrivilegedComponent>

      <Separator />
      {!!result &&
        !!result.id &&
        !!getReviewsRequest &&
        saveReviewReplyRequest &&
        deleteReviewReplyRequest && (
          <ReviewsAndRatings
            id={result.id}
            getRequest={getReviewsRequest}
            saveReviewReply={saveReviewReplyRequest}
            deleteReviewReply={deleteReviewReplyRequest}
            enableComments={result.enableComments}
            enableRatings={result.enableRatings}
          />
        )}
      <SettingsArea {...getSettingsFieldProps()} />

      {getActionArea({
        values,
        isNew,
        clearErrors,
        parseErrors,
        oldContent,
        isSavingBlocked,
        isSubmitDisabled: !isValid,
        setShouldBlockNavigation: setBlockNavigation,
        successCallback: success,
        content: result,
        createDraftItem: createDraft,
        createNewItem: createNew,
        getContentPayload: getNewsPayload,
      })}
    </>
  );
};

export default NewsForm;
