import breakpoints from "style/breakpoints";
import styled from "styled-components";

const InlineContainer = styled.div<{ spacedOut?: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ spacedOut }) => spacedOut && `justify-content: space-between;`}
  @media ${breakpoints.phoneMax} {
    flex-wrap: wrap;
  }
`;

export default InlineContainer;
