import React from "react";
import { RouteComponentProps } from "react-router";
import { WizardStepThreeHeader } from "components/headers/WizardHeader";
import { BodyText, CenteredContentViewContainer } from "components/generic";
import strings from "localisation/strings";
import useQuery from "common/hooks/useQuery";
import PlanSelection from "views/Wizard/PlanSelection";
import styled from "style/styled-components";
import useLocale from "common/hooks/useLocale";
import Plan from "api/models/Plan";
import OverlaySpinner, {
  AbsoluteOverlaySpinnerContainer,
} from "components/generic/OverlaySpinner";
import { Subsection, WizardTitle } from "views/Wizard/sharedComponents";
import { withBackButton } from "components/generic/ActionArea";
import { routeNames } from "core/navigation";
import { getAvailablePlans } from "api/businessProfiles";

const WizzardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.content.sizes.large};
`;

const WizardStepThreeActionArea = withBackButton(() => null);

const WizardStepThree = ({ history }: RouteComponentProps) => {
  const onSelect = (plan: Plan) => {
    history.push({
      pathname: routeNames.WizardStep.Fourth,
      state: { selectedPlan: plan },
    });
  };

  const handleBackClicked = () => {
    history.push({
      pathname: routeNames.WizardStep.Second,
    });
  };

  const locale = useLocale();
  const { result: plans } = useQuery({
    request: () => getAvailablePlans(true),
    compare: [locale],
  });

  return (
    <>
      <WizardStepThreeHeader />
      <CenteredContentViewContainer>
        <WizzardContainer>
          <WizardTitle>
            {strings("wizardScreen.planSelection.title")}
          </WizardTitle>
          <Subsection>
            <BodyText>
              {strings("wizardScreen.planSelection.subtitle")}
            </BodyText>
          </Subsection>

          <Subsection>
            {plans ? (
              <PlanSelection
                nonProfitPlanSelectable
                plans={plans}
                onSelect={onSelect}
              />
            ) : (
              <AbsoluteOverlaySpinnerContainer>
                <OverlaySpinner />
              </AbsoluteOverlaySpinnerContainer>
            )}
          </Subsection>

          <Subsection>
            <WizardStepThreeActionArea
              isActionAreaOneElement={true}
              isBackButtonEnabled={true}
              onBackButtonClicked={handleBackClicked}
            />
          </Subsection>
        </WizzardContainer>
      </CenteredContentViewContainer>
    </>
  );
};

export default WizardStepThree;
