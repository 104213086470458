import React from "react";
import { ReactComponent as NoConversationsIllustrationSvg } from "assets/icons/noConversationsIllustration.svg";
import { CenteredContentViewContainer } from "components/generic";
import strings from "localisation/strings";
import styled from "style/styled-components";
import Title from "components/generic/Title";

const FullHeightCenteredContainer = styled(CenteredContentViewContainer)`
  height: 100%;
  justify-content: center;
`;

const StyledTitle = styled(Title)`
  font-size: ${({ theme }) => theme.font.size.xxlarge};
  margin-top: ${({ theme }) => theme.margin.larger};
`;

const NoConversationsIllustration = styled(NoConversationsIllustrationSvg)`
  width: 284px;
  height: 66px;
`;

const Message = styled.span``;

const ChatWelcomePlaceholder = () => {
  return (
    <FullHeightCenteredContainer>
      <NoConversationsIllustration />

      <StyledTitle>{strings("chat.welcome")}</StyledTitle>

      <Message>{strings("chat.welcomeMessage")}</Message>
    </FullHeightCenteredContainer>
  );
};

export default ChatWelcomePlaceholder;
