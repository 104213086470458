import React from "react";
import styled, { css } from "style/styled-components";

interface ArrowIconProps {
  direction?: string;
  size?: string;
  offsetTop?: number;
}

/**
 * Arrow icon component supporting multiple arrow sizes.
 */
const ArrowBox = styled.span<Partial<ArrowIconProps>>`
  ${({ size }) => {
    let space = 3;
    let padding = 1;

    switch (size) {
      case "small":
        space = 3;
        padding = 1;
        break;
      case "medium":
        space = 3;
        padding = 2;
        break;
      case "large":
        space = 7;
        padding = 0;
        break;
      default: // NOOP
    }

    return css`
      height: ${space}px;
      width: ${space}px;
      padding: ${padding}px;
    `;
  }}
  
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  position: relative;
  top: ${({ offsetTop }) => (offsetTop ? `${offsetTop}px` : 0)};
  
  transform: rotate(${({ direction }) => {
    switch (direction) {
      case "up":
        return "-135";
      case "right":
        return "-45";
      case "down":
        return "45";
      case "left":
        return "135";
      default:
        return "-45";
    }
  }}deg);
}
`;

const Arrow = ({
  direction = "down",
  size = "small",
  offsetTop,
}: ArrowIconProps) => (
  <ArrowBox direction={direction} size={size} offsetTop={offsetTop} />
);

export default Arrow;
