import styled, { css } from "style/styled-components";
import { HeaderCellProps } from "components/table/DataTable/interfaces";
import { getArrow } from "components/table/DataTable/helpers";

// General
export const TableComponent = styled.table`
  font-size: ${({ theme }) => theme.font.size.table};
  border-spacing: 0;
  width: auto;
  overflow-x: scroll;
  box-sizing: border-box;
  min-width: 100%;
`;

export const TableRow = styled.tr`
  height: 48px;
`;

export const TableCell = styled.td`
  border-bottom: 1px solid ${({ theme }) => theme.color.component.tableBorder};
  word-break: break-word;
  min-width: 30px;
  max-width: 200px;
  vertical-align: middle;
  padding-right: 10px;

  &:last-child {
    padding-right: 0;
  }
`;

export const HeaderCell = styled.th<HeaderCellProps>`
  width: ${({ colWidth }) => (colWidth ? colWidth : "auto")};
  max-width: 200px;
  text-align: left;
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.color.component.tableBorder};
  font-size: 11px;
  white-space: nowrap;
  vertical-align: middle;
`;

export const HeaderSort = styled(HeaderCell)<{ addRightPadding?: boolean }>`
  font-weight: bold;
  ${({ addRightPadding, theme }) =>
    addRightPadding && `padding-right: ${theme.margin.standart};`}
  ${({ noSort, sort, id }) =>
    noSort
      ? ""
      : css`
          cursor: pointer;
          &:after {
            content: "";
            width: 7px;
            height: 4px;
            margin: 0 6px;
            position: relative;
            top: -1px;
            display: inline-block;
            background-repeat: no-repeat;
            ${getArrow(id, sort)}
          }
        `}
`;

// Filters
export const FilterCell = styled.th`
  max-width: 200px;
  text-align: left;
  padding: 0;
  font-weight: normal !important;
  border-bottom: 1px solid ${({ theme }) => theme.color.component.tableBorder};
  vertical-align: middle;
`;
