import React from "react";
import strings from "localisation/strings";
import websiteIcon from "assets/icons/card.svg";
import styled from "styled-components";
import { TextFieldProps } from "components/forms/TextField";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { InlineTextField } from "components/forms/FormFields/InlineTextField";

const FlexedTextWithIcon = styled(InlineTextField)`
  flex: 1;
`;

interface InFrameContentFieldsProps {
  textFieldProps: TextFieldProps;
  frameUrlErrors: string[];
}

const InFrameContentFields = ({
  textFieldProps,
  frameUrlErrors,
}: InFrameContentFieldsProps) => {
  const frameUrlPlaceHolder = strings("detailedScreen.inFrameUrl");
  return (
    <InlineInputGroup icon={websiteIcon} placeholder={frameUrlPlaceHolder}>
      <FlexedTextWithIcon
        inputPlaceholder={`${frameUrlPlaceHolder}${strings(
          "detailedScreen.optional",
        )}`}
        showPlaceholderAsLabel={true}
        type="text"
        onChange={textFieldProps.onChange}
        value={textFieldProps.value}
        disabled={textFieldProps.disabled}
        errors={frameUrlErrors}
      />
    </InlineInputGroup>
  );
};

export default InFrameContentFields;
