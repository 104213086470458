import React, { Component, ReactNode, ReactElement } from "react";
import { equals } from "ramda";

import { Subscribe } from "unstated";
import LocaleState from "state/LocaleState";
import RequestOptions from "api/RequestOptions";
import APIError from "common/api/models/APIError";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import createUnknownError from "common/api/createUnknownError";

export enum RequestMakerStatus {
  WAITING = "WAITING",
  ERROR = "ERROR",
  SUCCESSFUL = "SUCCESSFUL",
  UNDETERMINED = "UNDETERMINED",
}

export enum RequestType {
  QUERY = "QUERY",
  MUTATION = "MUTATION",
}

export interface APIRequestMakerProps<T, R = undefined> {
  successCallback?: (data: any) => void;
  failureCallback?: (error: APIErrorResponse) => void;
  locale?: string;
  compare?: any;
  requestType?: RequestType;
  defaultResult?: T;
  request: (
    options: RequestOptions,
    payload?: R,
  ) => Promise<{ result: T; headers: Headers }>;
  children(
    state: APIRequestMakerState<T>,
    submit: (payload?: R) => void,
  ): ReactNode;
}

export interface EmptyHeaders {
  get: (name: string) => null;
}

interface APIRequestMakerState<T> {
  result?: T;
  error?: APIErrorResponse;
  headers: Headers | EmptyHeaders;
  status: RequestMakerStatus;
}

class APIRequestMakerWithoutLocale<T, R = undefined> extends Component<
  APIRequestMakerProps<T, R>,
  APIRequestMakerState<T>
> {
  state = {
    result: this.props.defaultResult,
    error: undefined,
    headers: { get: () => null },
    status:
      this.props.requestType === RequestType.MUTATION
        ? RequestMakerStatus.UNDETERMINED
        : RequestMakerStatus.WAITING,
  };
  controller = new AbortController();
  mounted = false;

  fetchData = (payload?: R) => {
    this.setState({ status: RequestMakerStatus.WAITING });
    this.controller.abort();
    this.controller = new AbortController();
    this.props
      .request({ signal: this.controller.signal }, payload)
      .then(({ result, headers }) => {
        if (this.mounted) {
          this.setState({
            result,
            headers,
            status: RequestMakerStatus.SUCCESSFUL,
          });
          if (this.props.successCallback) {
            this.props.successCallback(result);
          }
        }
      })
      .catch(err => {
        if (!this.mounted) {
          return;
        }
        if (err instanceof APIError) {
          this.setState({
            error: err.errorBody,
            status: RequestMakerStatus.ERROR,
          });
        } else {
          this.setState({
            error: createUnknownError(err).errorBody,
            status: RequestMakerStatus.ERROR,
          });
        }
        if (this.props.failureCallback) {
          this.props.failureCallback(err);
        }
      });
  };

  componentDidMount() {
    this.mounted = true;
    if (this.props.requestType !== RequestType.MUTATION) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: APIRequestMakerProps<T, R>) {
    if (
      this.props.requestType !== RequestType.MUTATION &&
      (prevProps.request.toString() !== this.props.request.toString() ||
        prevProps.locale !== this.props.locale ||
        !equals(prevProps.compare, this.props.compare))
    ) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.controller.abort();
  }

  render() {
    return this.props.children(this.state, this.fetchData);
  }
}

const APIRequestMaker: <T, R = undefined>(
  props: APIRequestMakerProps<T, R>,
) => ReactElement<any> = props => (
  <Subscribe to={[LocaleState]}>
    {(localeState: LocaleState) => (
      <APIRequestMakerWithoutLocale
        locale={localeState.state.locale}
        {...props}
      />
    )}
  </Subscribe>
);

export default APIRequestMaker;
