import styled from "style/styled-components";

const RoundedImage = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.large};
  width: ${({ theme }) => theme.grid.itemWidth};
  height: 188px;
  overflow: hidden;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`;

export default RoundedImage;
