import OfferTypes from "api/models/OfferTypes";
import cartIcon from "assets/icons/cart.svg";
import promoIcon from "common/assets/icons/promotion.svg";
import couponIcon from "common/assets/icons/quantity.svg";
import { TextWithIcon } from "components/forms/FormFields";
import InlineInput from "components/forms/FormFields/InlineInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import InlineNumberInput from "components/forms/FormFields/InlineNumberInput";
import OfferHint from "components/forms/FormFields/OfferHint";
import useWindowDimensions from "hooks/useWindowDimensions";
import strings from "localisation/strings";
import React, { ChangeEvent } from "react";
import styled, { isMobile } from "style/styled-components";
import themeValues from "style/theme";
import RadioButton from "uiComponents/radioButton/RadioButton";

const RadioBox = styled.div`
  margin: ${({ theme }) =>
    `${theme.margin.small} 0 ${theme.margin.small} ${theme.margin.xlarge}`};
`;

const FieldWrapper = styled.span`
  width: 100%;
`;

export interface OfferTypeOptionsProps {
  changeType: (type: OfferTypes) => void;
  type: OfferTypes;
  promotionCode?: string;
  eShopUrl?: string;
  prominentShoppingUrl?: string;
  prominentShoppingEnabled: boolean;
  changeCouponQuantity: (value: number | null) => void;
  changePromoCode: (e: ChangeEvent<HTMLInputElement>) => void;
  changeEShopUrl: (e: ChangeEvent<HTMLInputElement>) => void;
  changeProminentShoppingUrl: (e: ChangeEvent<HTMLInputElement>) => void;
  quantityTotal?: number;
  disabled: boolean;
  quantityTotalErrors?: string[];
  promotionCodeErrors?: string[];
  eShopUrlErrors?: string[];
  prominentShoppingUrlErrors?: string[];
}

const OfferTypeOptions = ({
  changeCouponQuantity,
  changePromoCode,
  changeEShopUrl,
  changeProminentShoppingUrl,
  changeType,
  eShopUrl,
  prominentShoppingUrl,
  prominentShoppingEnabled,
  promotionCode,
  quantityTotal,
  type,
  disabled,
  quantityTotalErrors,
  promotionCodeErrors,
  eShopUrlErrors,
  prominentShoppingUrlErrors,
}: OfferTypeOptionsProps) => {
  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);
  return (
    <>
      <RadioBox>
        <RadioButton
          onChange={() => changeType(OfferTypes.promotion)}
          checked={type === OfferTypes.promotion}
          label={<OfferHint offerType={OfferTypes.promotion} />}
          value={OfferTypes.promotion}
          disabled={disabled}
        />
        <RadioButton
          onChange={() => changeType(OfferTypes.coupon)}
          checked={type === OfferTypes.coupon}
          label={<OfferHint offerType={OfferTypes.coupon} />}
          value={OfferTypes.coupon}
          disabled={disabled}
        />
      </RadioBox>
      {type === OfferTypes.coupon && (
        <div>
          <InlineInputGroup
            disabled={disabled}
            icon={couponIcon}
            placeholder={strings("detailedScreen.quantityTitle")}
            width={themeValues.forms.leftSide}
          >
            <FieldWrapper>
              <InlineNumberInput
                isFormattingDisabled={true}
                maxLength={8}
                setValue={changeCouponQuantity}
                placeholder={strings("detailedScreen.quantityPlaceholder")}
                value={quantityTotal}
                disabled={disabled}
                errors={quantityTotalErrors}
                width={isMobileDevice ? "100%" : "33%"}
              />
            </FieldWrapper>
          </InlineInputGroup>
        </div>
      )}
      {type === OfferTypes.promotion && (
        <div>
          <InlineInputGroup
            icon={promoIcon}
            placeholder={strings("detailedScreen.promoCodeTitle")}
            width={themeValues.forms.leftSide}
          >
            <FieldWrapper>
              <InlineInput
                type="text"
                onChange={changePromoCode}
                placeholder={strings("detailedScreen.promoCodePlaceholder")}
                value={promotionCode}
                errors={promotionCodeErrors}
                width={"100%"}
              />
            </FieldWrapper>
          </InlineInputGroup>
          <TextWithIcon
            label={strings("detailedScreen.eShopUrl")}
            placeholder={strings("detailedScreen.eShopUrlPlaceholder")}
            value={eShopUrl}
            errors={eShopUrlErrors}
            onChange={changeEShopUrl}
            width={"100%"}
            optional
            icon={cartIcon}
          />
          {prominentShoppingEnabled && (
            <TextWithIcon
              label={strings("detailedScreen.prominentShoppingUrl")}
              placeholder={strings(
                "detailedScreen.prominentShoppingPlaceholder",
              )}
              value={prominentShoppingUrl}
              errors={prominentShoppingUrlErrors}
              onChange={changeProminentShoppingUrl}
              width={"100%"}
              optional
              icon={cartIcon}
            />
          )}
        </div>
      )}
    </>
  );
};

export default OfferTypeOptions;
