import { useState, useEffect } from "react";
import loginState from "state/singletons/loginState";

const useLoginState = () => {
  // This state is just for triggering an update.
  const [, update] = useState(Date.now());

  const loginChangeListener = () => {
    // Trigger a hook state update when something changes in the loginState.
    update(Date.now());
  };

  // Subscribe to loginState changes on mount, unsubscribo on unmount
  useEffect(() => {
    loginState.subscribe(loginChangeListener);
    return () => {
      loginState.unsubscribe(loginChangeListener);
    };
  }, []);

  return loginState;
};

export default useLoginState;
