import React, { useEffect } from "react";
import strings from "localisation/strings";
import styled from "style/styled-components";
import { BodyText, Title } from "components/generic";
import PaymentsFormInlineInputGroup from "views/BusinessSettings/Payment/shared/PaymentsFormInlineInputGroup";
import { InlineTextField } from "components/forms/FormFields/InlineTextField";
import useFormFields from "hooks/useFormFields";
import ActionButton from "components/buttons/ActionButton";
import ActionArea, { withBackButton } from "components/generic/ActionArea";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { noop } from "utils";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";
import useDefaultPaymentMethodStatus from "views/BusinessSettings/Payment/hooks/useDefaultPaymentMethodStatus";
import FieldMargin from "components/forms/FieldMargin";
import Checkbox from "components/forms/Checkbox";
import PaymentAddressDetails from "views/BusinessSettings/Payment/shared/PaymentAddressDetails";
import {
  defaultSepaDataFields,
  setSepaFields,
} from "components/helpers/formHelpers/paymentsDetails";
import { PaymentAddressDTO } from "api/models/Payments";
import appTheme from "style/theme";

const Section = styled.div`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

export interface SepaDataFormValues {
  owner: string;
  iban: string;
  defaultPaymentMethod: boolean;
  paymentAddress?: PaymentAddressDTO;
}

const allWhitespaceRegex = /\s/g;

interface Props {
  errors?: APIErrorResponse;
  isSubmittable: boolean;
  isBackAvailable: boolean;
  onFormLeft: () => void;
  onSubmit: (values: SepaDataFormValues) => void;
  submitButtonText?: string;
}

interface ActionAreaProps {
  isSubmittable: boolean;
  onSubmit: () => void;
  text?: string;
}

const SepaDataFormActionArea = withBackButton(
  ({
    isSubmittable,
    onSubmit,
    text = strings("businessSettings.paymentEdit.sepaForm.submit.label"),
  }: ActionAreaProps) => (
    <ActionArea>
      <ActionButton
        alone
        disabled={!isSubmittable}
        special="publish"
        text={text}
        onClick={onSubmit}
      />
    </ActionArea>
  ),
);

const SepaDataForm = ({
  errors,
  onSubmit,
  onFormLeft,
  isSubmittable,
  isBackAvailable,
  submitButtonText,
}: Props) => {
  const {
    getTextFieldProps,
    getCheckboxFieldProps,
    getPaymentAddressProps,
    getErrors,
    values,
    parseErrors,
    onChange,
    updateValues,
  } = useFormFields({
    ...defaultSepaDataFields,
    blockNavigation: true,
    handleGenericErrors: true,
    translationScope: "businessSettings.paymentEdit.sepaForm",
  });

  useEffect(() => {
    return errors && parseErrors(errors);
  }, [errors]);

  const { onBlur: onIbanBlurred = noop, ...ibanFieldProps } = getTextFieldProps(
    "iban",
  );
  const handleIbanFieldBlurred = () => {
    onIbanBlurred();
    onChange("iban", values.iban.replace(allWhitespaceRegex, ""));
  };

  const handleFormSubmitted = () => {
    onSubmit(values);
  };

  const isPayoneIntegrated = isPayoneIntegrationEnabled();
  const {
    isDefaultPaymentMethodStatusLoading,
    isDefaultPaymentMethod,
    isDefaultPaymentMethodMissing,
  } = useDefaultPaymentMethodStatus("SEPA");

  const precheckDefaultPaymentMethodStatus =
    isDefaultPaymentMethod || isDefaultPaymentMethodMissing;
  useEffect(() => {
    if (!isDefaultPaymentMethodStatusLoading) {
      onChange("defaultPaymentMethod", precheckDefaultPaymentMethodStatus);
    }
  }, [precheckDefaultPaymentMethodStatus, isDefaultPaymentMethodStatusLoading]);

  const setSavedPaymentAddress = (savedPaymentAddress: PaymentAddressDTO) => {
    const updatePaymentAddress = setSepaFields(
      savedPaymentAddress,
      precheckDefaultPaymentMethodStatus,
    );
    updateValues(updatePaymentAddress);
  };

  return (
    <>
      <Title fixedMargins>
        {strings("businessSettings.paymentEdit.sepaTitle")}
      </Title>
      <PaymentsFormInlineInputGroup
        placeholder={getTextFieldProps("owner").placeholder}
        width={appTheme.forms.planPrice}
      >
        <InlineTextField
          {...getTextFieldProps("owner")}
          width={appTheme.forms.full}
        />
      </PaymentsFormInlineInputGroup>

      <PaymentsFormInlineInputGroup
        placeholder={ibanFieldProps.placeholder}
        width={appTheme.forms.planPrice}
      >
        <InlineTextField
          {...ibanFieldProps}
          width={appTheme.forms.full}
          onBlur={handleIbanFieldBlurred}
        />
      </PaymentsFormInlineInputGroup>

      {isPayoneIntegrated && (
        <Section>
          <FieldMargin>
            <Checkbox
              {...getCheckboxFieldProps("defaultPaymentMethod")}
              errors={getErrors("defaultPaymentMethod")}
              disabled={precheckDefaultPaymentMethodStatus}
            />
          </FieldMargin>
        </Section>
      )}

      <Section>
        <BodyText small>
          {strings("businessSettings.paymentEdit.sepaFooter")}
        </BodyText>
      </Section>

      <Section>
        <PaymentAddressDetails
          {...getPaymentAddressProps(setSavedPaymentAddress)}
        />
      </Section>

      <SepaDataFormActionArea
        isBackButtonEnabled={isBackAvailable && isPayoneIntegrated}
        onBackButtonClicked={onFormLeft}
        isActionAreaOneElement={true}
        isSubmittable={isSubmittable}
        text={submitButtonText}
        onSubmit={handleFormSubmitted}
      />
    </>
  );
};

export default SepaDataForm;
