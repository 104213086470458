import React from "react";
import styled, { isMobile } from "style/styled-components";
import strings from "localisation/strings";
import { DateTime } from "components/forms/FormFields/DateTime";
import { FormErrorsInterface } from "hooks/useFormFields";
import {
  DateTimeRangeInterface,
  getValueFromDate,
  getDateFromValue,
  getMinDateTime,
  getMaxTime,
} from "components/forms/FormFields/DateTime/helpers";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import calendarIcon from "assets/icons/calendar.svg";
import appTheme from "style/theme";
import transparentIcon from "assets/icons/transparent.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import {
  EndDateTypeFieldProps,
  EndDateTypePicker,
} from "components/forms/ValidatedFields/DateTimeRangePicker";
import { EndDateType } from "api/models/EventInterface";
import { Placement } from "@floating-ui/react";

interface Props {
  width?: string;
  errors?: FormErrorsInterface;
  groupWidth?: string;
  dateWidth?: string;
  timeWidth?: string;
  timeIntervals?: number;
  dateFormat?: string;
  timeFormat?: string;
  datePlaceholder?: string;
  timePlaceholder?: string;
  popperPlacement?: Placement;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  value?: DateTimeRangeInterface;
  setValue: (value: DateTimeRangeInterface, position?: "start" | "end") => void;
  startLabel?: string;
  endLabel?: string;
  showClearButton?: boolean;
  canSelectInPast?: boolean;
  endDateTypePickerProps?: EndDateTypeFieldProps;
}

//  9999 31. December 23:00 UTC. This limit is used to synchronize the maximum UI date values with the capabilities of momentjs
const MAX_DATE = new Date(253402297200000);

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-basis: ${({ width = "100%" }) => width};
  flex-grow: 1;
  flex-wrap: wrap;
`;

const ClearButton = styled.span`
  padding-left: ${({ theme }) => theme.margin.small};
  color: ${({ theme }) => theme.color.background.secondary};
  cursor: pointer;
`;

const shouldShowEndDate = (endDateTypePickerProps?: EndDateTypeFieldProps) => {
  if (endDateTypePickerProps === undefined) return true;

  return (
    endDateTypePickerProps.value !== EndDateType.fullDay &&
    endDateTypePickerProps.value !== EndDateType.generated
  );
};

const shouldHideStartTime = (
  endDateTypePickerProps?: EndDateTypeFieldProps,
) => {
  if (endDateTypePickerProps === undefined) return false;

  return endDateTypePickerProps.value === EndDateType.fullDay;
};

const DateTimeRange = ({
  width,
  groupWidth,
  errors,
  dateWidth,
  timeWidth,
  timeIntervals,
  dateFormat,
  timeFormat,
  datePlaceholder,
  timePlaceholder,
  popperPlacement,
  startLabel,
  endLabel,
  value: { start, end } = {
    start: null,
    end: null,
  },
  setValue,
  minDate,
  maxDate,
  minTime,
  maxTime,
  showClearButton,
  canSelectInPast,
  endDateTypePickerProps,
}: Props) => {
  const updateValue = (
    newValue: string | undefined,
    position: string,
  ): void => {
    if (position === "start") {
      setValue(
        {
          end,
          start: getDateFromValue(newValue),
        },
        position,
      );
    }

    if (position === "end") {
      setValue(
        {
          start,
          end: getDateFromValue(newValue),
        },
        position,
      );
    }
  };

  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);

  const clearValues = () => {
    setValue({ start: null, end: null });
    if (endDateTypePickerProps) endDateTypePickerProps.setValue();
  };

  const clearDateLimits = () => {
    if (canSelectInPast) {
      return {
        minDate: undefined,
        minTime: undefined,
        maxDate: MAX_DATE,
        maxTime: undefined,
      };
    }

    return {};
  };

  const showEndDate = shouldShowEndDate(endDateTypePickerProps);
  const hideStartTime = shouldHideStartTime(endDateTypePickerProps);

  return (
    <Wrapper width={width}>
      <InlineInputGroup
        icon={calendarIcon}
        placeholder={startLabel}
        width={appTheme.forms.leftSide}
        margin={`${appTheme.margin.xsmall} 0`}
        totalWidth={
          isMobileDevice
            ? "100%"
            : `calc(( 100% - 242px) / 2 + ${appTheme.forms.leftSide} )`
        }
      >
        <DateTime
          value={getValueFromDate(start)}
          setValue={v => updateValue(v, "start")}
          width={groupWidth}
          dateWidth={dateWidth}
          timeWidth={timeWidth}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          datePlaceholder={datePlaceholder}
          timePlaceholder={timePlaceholder}
          popperPlacement={popperPlacement}
          minDate={getMinDateTime(minDate, start)}
          minTime={getMinDateTime(minTime, start)}
          maxTime={getMaxTime()}
          getDateTimeFromValue={getDateFromValue}
          errors={errors && errors.dateTimeFrom}
          hideTime={hideStartTime}
          testId="dateTimeFrom"
          {...clearDateLimits()}
        />
      </InlineInputGroup>
      {showEndDate && (
        <InlineInputGroup
          icon={isMobileDevice ? transparentIcon : undefined}
          placeholder={endLabel}
          width={isMobileDevice ? appTheme.forms.leftSide : "40px"}
          margin={`${appTheme.margin.xsmall} ${isMobileDevice ? "0" : "16px"}`}
          totalWidth={
            isMobileDevice ? "100%" : `calc(( 100% - 242px ) / 2  + 40px)`
          }
        >
          <DateTime
            value={getValueFromDate(end)}
            setValue={v => updateValue(v, "end")}
            width={groupWidth}
            dateWidth={dateWidth}
            timeWidth={timeWidth}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
            datePlaceholder={datePlaceholder}
            timePlaceholder={timePlaceholder}
            popperPlacement={popperPlacement}
            minDate={getMinDateTime(start)}
            minTime={getMinDateTime(start, end)}
            maxDate={maxDate}
            maxTime={getMaxTime(maxTime)}
            getDateTimeFromValue={getDateFromValue}
            errors={errors && errors.dateTimeTill}
            testId="dateTimeTill"
            {...clearDateLimits()}
          />
        </InlineInputGroup>
      )}
      {endDateTypePickerProps && (
        <EndDateTypePicker {...endDateTypePickerProps} />
      )}
      {showClearButton && (
        <InlineInputGroup
          placeholder={isMobileDevice ? undefined : " "}
          totalWidth={isMobileDevice ? "100%" : "40px"}
        >
          <ClearButton onClick={clearValues}>
            {strings("dateTimePicker.clear")}
          </ClearButton>
        </InlineInputGroup>
      )}
    </Wrapper>
  );
};

export default DateTimeRange;
