import { useState, useEffect } from "react";
import { AddressLocation } from "api/models/Location";
import { FormErrorsInterface } from "hooks/useFormFields";
import strings from "localisation/strings";
import { curry } from "ramda";
import useGooglePlacesAPI from "components/forms/FormFields/AddressAutocomplete/useGooglePlacesAPI";
import {
  findFieldInAddressComponents,
  formatAddressAPIErrors,
} from "components/forms/FormFields/AddressAutocomplete/useAddressAutocomplete";

interface UseAddressAutocompleteParams {
  apiErrors?: FormErrorsInterface;
  onPinLocation: (location: AddressLocation) => void;
}

const usePinLocationOnMap = ({
  apiErrors,
  onPinLocation,
}: UseAddressAutocompleteParams) => {
  const [formattedErrors, setFormattedErrors] = useState([] as string[]);
  const {
    isError,
    isGoogleMapsApisLoaded,
    resetResults,
    getPlaceDetailsByCoordinates,
  } = useGooglePlacesAPI();

  useEffect(() => {
    setFormattedErrors(formatAddressAPIErrors(apiErrors));
  }, [apiErrors]);

  useEffect(() => {
    if (isError) {
      setFormattedErrors([strings("addressAutocomplete.addressNotFound")]);
    }
  }, [isError]);

  const pinAddressOnMap = (
    pinnedLocation: google.maps.GeocoderResult | undefined,
    pinCoordinates: google.maps.LatLng,
  ) => {
    if (!pinnedLocation || hasNoLocationDetails(pinnedLocation)) {
      setFormattedErrors([strings("addressAutocomplete.addressNotFound")]);
      return;
    }
    const apiPreparedAddress = mapToAddressLocation(
      pinnedLocation,
      pinCoordinates,
    );
    onPinLocation(apiPreparedAddress);
    resetResults();
  };

  const hasNoLocationDetails = (location: google.maps.GeocoderResult) =>
    !location.geometry || !location.place_id || !location.address_components;

  const mapToAddressLocation = (
    result: google.maps.GeocoderResult,
    pinCoordinates: google.maps.LatLng,
  ): AddressLocation => {
    const findFieldInResultAddressComponents = curry(
      findFieldInAddressComponents,
    )(result.address_components);

    const street = findFieldInResultAddressComponents("route");
    const houseNo = findFieldInResultAddressComponents("street_number");
    const postalCode = findFieldInResultAddressComponents("postal_code");
    const city = findFieldInResultAddressComponents("locality");

    const isFullAddress =
      street !== "" && houseNo !== "" && postalCode !== "" && city !== "";

    return {
      placeId: result.place_id,
      street: isFullAddress ? street : "",
      houseNo: isFullAddress ? houseNo : "",
      postalCode: isFullAddress ? postalCode : "",
      city: isFullAddress ? city : "",
      latitude: pinCoordinates.lat().toString(),
      longitude: pinCoordinates.lng().toString(),
      pinned: "true",
      pinnedDescription: result.formatted_address,
    };
  };

  return {
    pinAddressOnMap,
    getPlaceDetailsByCoordinates,
    isGoogleMapsApisLoaded,
    errors: formattedErrors,
  };
};

export default usePinLocationOnMap;
