import React, { useEffect, useState } from "react";
import TopicInterface, { TopicType } from "api/models/TopicInterface";
import { SelectItem } from "components/forms/Select";
import { SelectList } from "components/table/DataTable/Fields";
import strings from "localisation/strings";
import { DisableMapProps } from "components/forms/FormFields/TopicsSection";

interface TopicsDropdownProps {
  onChange: (selectedField: any, selectedValue: any) => void;
  allTopics: TopicInterface[];
  getTopicById: (topicId: any) => TopicInterface | undefined;
  disableMap: DisableMapProps;
  interestTopicsFromResult: TopicInterface[];
}

interface StringSelectOption {
  value: string;
  label: string;
}

const TopicsDropdown = ({
  onChange,
  allTopics,
  getTopicById,
  disableMap,
  interestTopicsFromResult,
}: TopicsDropdownProps) => {
  const [selectedTopicsNum, setSelectedTopicsNum] = useState<number>(0);
  const [topicSelectionDisabled, setTopicSelectionDisabled] = useState<boolean>(
    false,
  );
  const [selectedTopicsList, setSelectedTopicsList] = useState<
    TopicInterface[]
  >([]);
  const [selectedTopicsForSelect, setSelectedTopicsForSelect] = useState<any>(
    [],
  );

  useEffect(() => {
    setSelectedTopicsList(interestTopicsFromResult);
  }, [interestTopicsFromResult]);

  useEffect(() => {
    selectedTopicsNum >= 5
      ? setTopicSelectionDisabled(true)
      : setTopicSelectionDisabled(false);
  }, [selectedTopicsNum]);

  useEffect(() => {
    setSelectedTopicsForSelect(
      selectedTopicsList.map(topic => ({
        value: topic.topicId,
        label: topic.topicName,
      })),
    );

    if (selectedTopicsList.length > 0) {
      disableMap.dropdownList = true;
      onChange("topics", selectedTopicsList);
    } else {
      disableMap.dropdownList = false;
    }
  }, [selectedTopicsList]);

  const topicsForSelectList: StringSelectOption[] = (
    allTopics
      .filter(topic => topic.topicType === TopicType.Interest)
      .map(topic => ({
        value: topic.topicId,
        label: topic.topicName,
      })) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));

  const onItemSelect = (options: SelectItem[]) => {
    if (options.length > 0) {
      disableMap.dropdownList = true;
    } else {
      disableMap.dropdownList = false;
    }

    setSelectedTopicsNum(options.length);
    const topicList: TopicInterface[] = options
      .map((option: SelectItem) => getTopicById(option.value))
      .filter(
        (topic: TopicInterface | undefined) => topic !== undefined,
      ) as TopicInterface[];

    setSelectedTopicsList(topicList);
  };

  const areCheckboxesDisabled = () =>
    topicSelectionDisabled ||
    disableMap.generalCheckbox ||
    disableMap.alertCheckbox ||
    disableMap.radarCheckbox;

  return (
    <SelectList
      isMulti
      options={topicsForSelectList}
      onChange={onItemSelect}
      placeholder={strings("tableView.topicsChoicePlaceholder")}
      width="350px"
      areCheckboxesDisabled={areCheckboxesDisabled()}
      classNamePrefix="option"
      value={selectedTopicsForSelect}
    />
  );
};

export default TopicsDropdown;
