import React, { useEffect, useState } from "react";
import styled from "style/styled-components";
import ReviewInterface, {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
} from "api/models/ReviewInterface";
import RoundedView from "components/generic/RoundedView";
import SimpleText, { BoldText } from "components/generic/SimpleText";
import { Avatar } from "components/generic";
import CenteredSpreadRow from "components/generic/CenteredSpreadRow";
import endUserAvatar from "common/assets/icons/endUserAvatar.svg";
import StatsRatingStars from "components/review/StatsRatingStars";
import ReviewReply from "components/review/ReviewReply";
import { curry } from "ramda";
import strings from "localisation/strings";
import { AbortableRequest } from "common/api/AbortableRequest";
import { AbortablePayloadRequest } from "common/api/AbortablePayloadRequest";

const CommentContainer = styled(RoundedView)`
  padding: 12px 15px;
  margin-top: ${({ theme }) => theme.margin.larger};
`;

const CommentText = styled(SimpleText)`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  margin-top: ${({ theme }) => theme.margin.small};
  line-height: 24px;
  white-space: pre-wrap;
  word-break: break-word;
`;

const DeletedCommentText = styled(CommentText)`
  font-style: italic;
`;

const NameAndAvatar = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  margin-right: ${({ theme }) => theme.margin.medium};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: bold;
`;

const TitleAndStars = styled.div<{ noRating: boolean }>`
  flex: 1;
  align-self: ${({ noRating }) => (noRating ? "inherit" : "flex-start")};
  padding-right: ${({ theme }) => theme.margin.small};
`;

const ReviewTitle = styled(BoldText)`
  word-break: break-word;
`;

const UserEmailText = styled(SimpleText)`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  line-height: 24px;
  white-space: pre-wrap;
`;

interface ReviewItemProps {
  reviewWithEmail: ReviewInterface;
  saveReviewReply: (
    reviewId: string,
  ) => AbortablePayloadRequest<
    ReviewReplyPayloadInterface,
    ReplyDetailsInterface
  >;
  deleteReviewReply: (
    reviewId: string,
    replyId: string,
  ) => AbortableRequest<void>;
  onDeleteReply: (review: ReviewInterface) => void;
}

const ReviewItem = ({
  reviewWithEmail,
  saveReviewReply,
  deleteReviewReply,
  onDeleteReply,
}: ReviewItemProps) => {
  const {
    id,
    reviewerDetails,
    title,
    comment,
    rating,
    userId,
    deleted,
    userEmail,
    ...rest
  } = reviewWithEmail;
  // assuming 0 is a valid rating
  const noRatingProvided = rating
    ? false
    : rating === undefined || rating === null;
  const { userPictureUrl, firstName } = reviewerDetails || {};
  const saveReply = saveReviewReply(id);
  const deleteReply = curry(deleteReviewReply)(id);

  const [stateReview, setReview] = useState(reviewWithEmail);
  useEffect(() => {
    setReview(reviewWithEmail);
  }, [reviewWithEmail]);

  const handleReplySaved = (result: ReplyDetailsInterface) => {
    setReview({ ...reviewWithEmail, replyDetails: result });
  };

  const handleReplyDeleted = () => {
    setReview({ ...reviewWithEmail, replyDetails: undefined });
    onDeleteReply(reviewWithEmail);
  };

  return (
    <CommentContainer {...rest}>
      <CenteredSpreadRow>
        <TitleAndStars noRating={noRatingProvided}>
          <ReviewTitle>{title}</ReviewTitle>
          {!noRatingProvided && <StatsRatingStars rating={rating || 0} />}
        </TitleAndStars>
        <NameAndAvatar>
          <UserName>{firstName || strings("review.unknownUser")}</UserName>
          {userEmail && !deleted && (
            <UserEmailText>({userEmail}) </UserEmailText>
          )}
          <Avatar picture={userPictureUrl || endUserAvatar} size={40} />
        </NameAndAvatar>
      </CenteredSpreadRow>
      <CenteredSpreadRow>
        {comment && !deleted && <CommentText>{comment}</CommentText>}
        {deleted && (
          <DeletedCommentText>
            {strings("review.deletedComment")}
          </DeletedCommentText>
        )}
      </CenteredSpreadRow>

      <ReviewReply
        saveReply={saveReply}
        deleteReply={deleteReply}
        reply={stateReview.replyDetails}
        onReplySaved={handleReplySaved}
        onReplyDeleted={handleReplyDeleted}
      />
    </CommentContainer>
  );
};

export default ReviewItem;
