import React from "react";
import strings from "localisation/strings";
import { withRouter, RouteComponentProps } from "react-router";
import ConfirmationForm from "components/forms/ConfirmationForm/ConfirmationForm";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import { activateAccount } from "api/session";

interface UserActivationRouteParams {
  token: string;
}

type Props = RouteComponentProps<UserActivationRouteParams>;

const messageByRequestStatus = (status: QueryStatus) => {
  switch (status) {
    case QueryStatus.SUCCESSFUL:
      return strings("activateAccountScreen.successMessage");
    case QueryStatus.ERROR:
      return strings("activateAccountScreen.errorMessage");
    default:
      return null;
  }
};

const RegistrationConfirmationForm = ({ match }: Props) => {
  const { makeRequest, status } = useMutation<string, object>(activateAccount);

  const makeActivationRequest = async () => {
    await makeRequest(match.params.token);
  };

  return React.createElement(ConfirmationForm, {
    message: messageByRequestStatus(status),
    isSubmittable: status !== QueryStatus.SUCCESSFUL,
    isLoading: status === QueryStatus.WAITING,
    onSubmit: makeActivationRequest,
  });
};

export default withRouter(RegistrationConfirmationForm);
