import RequestOptions from "api/RequestOptions";
import { BusinessType } from "api/models/ResourcesInterfaces";
import AuthedAPI from "api/AuthedAPI";
import ListParams, { TagListParams } from "api/models/ListParams";
import ListResultType from "api/models/ListResultType";
import CategoryInterface from "api/models/CategoryInterface";
import GenericContent from "api/models/GenericContent";
import TagInterface from "api/models/TagInterface";
import SingleMainCategoryInterface from "api/models/SingleMainCategoryInterface";
import SingleSubCategoryInterface from "api/models/SingleSubCategoryInterface";
import SmartServiceGroupInterface from "api/models/SmartServiceGroupInterface";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import MapInterface from "api/models/MapInterface";

const resourcesAPI = new AuthedAPI({});

const getBusinessTypes = (
  isAdmin: boolean,
  queryParams: any | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.getList<BusinessType[]>({
    queryParams,
    options,
    path: isAdmin
      ? "resources/bo/resources/tags/tree?scope=BUSINESS&status=ACTIVE"
      : "resources/bp/resources/tags/tree?scope=BUSINESS&status=ACTIVE",
  });

const getTags = (
  body: TagListParams | undefined,
  queryParams: TagListParams | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.postListJson<ListResultType<CategoryInterface>>({
    queryParams,
    options,
    body,
    path: "resources/bo/resources/tags/list",
  });

const getTagTree = (
  isAdmin: boolean,
  params: TagListParams | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.getList<TagInterface[]>({
    options,
    queryParams: params,
    path: isAdmin
      ? "resources/bo/resources/tags/tree"
      : "resources/bp/resources/tags/tree",
  });

const createMainCategory = (
  body: SingleMainCategoryInterface,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.postJson<SingleMainCategoryInterface>({
    body,
    options,
    path: `resources/bo/resources/tags`,
  });

const getMainCategory = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.getJson<SingleMainCategoryInterface>({
    queryParams,
    options,
    path: `resources/bo/resources/tags/${id}`,
  });

const getSubCategory = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.getJson<SingleSubCategoryInterface>({
    queryParams,
    options,
    path: `resources/bo/resources/tags/${id}/sub-tags`,
  });

const updateMainCategory = (
  id: string,
  body: SingleMainCategoryInterface,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.putJson<SingleMainCategoryInterface>({
    body,
    options,
    path: `resources/bo/resources/tags/${id}`,
  });

const updateSubCategory = (
  id: string,
  body: SingleMainCategoryInterface,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.putJson<SingleMainCategoryInterface>({
    body,
    options,
    path: `resources/bo/resources/tags/${id}/sub-tags`,
  });

const updateTagsStatus = (
  id: string,
  body: CategoryInterface,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.putJson<CategoryInterface>({
    body,
    options,
    path: `resources/bo/resources/tags/${id}/status`,
  });

const uploadPictureBo = (body: FormData) =>
  resourcesAPI.sendFormData<any>({
    body,
    path: "file-storage/bo/file-storage/image-upload",
  });

const uploadPictureBp = (body: FormData) =>
  resourcesAPI.sendFormData<any>({
    body,
    path: "file-storage/bp/file-storage/image-upload",
  });

const getGenericContent = (
  key: string,
  isAdmin: boolean,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.getJson<GenericContent>({
    options,
    path: isAdmin
      ? `resources/bo/generic-content/${key}`
      : `resources/bp/generic-content/${key}`,
  });

const updateGenericContent = (body: GenericContent, options?: RequestOptions) =>
  resourcesAPI.putJson<GenericContent>({
    body,
    options,
    path: `resources/bo/generic-content/${body.key}`,
  });

const getSmartServiceGroups = (options?: RequestOptions) =>
  resourcesAPI.getJson<SmartServiceGroupInterface>({
    options,
    path: "resources/bo/resources/smart-service/groups",
  });

const getAdminMaps = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.postListJson<ListResultType<MapInterface>>({
    queryParams,
    options,
    body,
    path: "resources/bo/resources/maps/search",
  });

const updateMapStatus = (
  id: string,
  body: MapInterface,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.putJson<MapInterface>({
    body,
    options,
    path: `resources/bo/resources/maps/${id}/status`,
  });

const createMap = (body: MapInterface, options: RequestOptions | undefined) =>
  resourcesAPI.postJson<MapInterface>({
    body,
    options,
    path: `resources/bo/resources/maps`,
  });

const updateMap = (
  id: string,
  body: MapInterface,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.putJson<MapInterface>({
    body,
    options,
    path: `resources/bo/resources/maps/${id}`,
  });

const getMap = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  resourcesAPI.getJson<MapInterface>({
    queryParams,
    options,
    path: `resources/bp/resources/maps/${id}`,
  });

const getActiveMapsList = (options?: RequestOptions) =>
  resourcesAPI.getJson<{ maps: MapInterface[] }>({
    options,
    path: `resources/bp/resources/maps`,
  });

export {
  updateTagsStatus,
  updateMainCategory,
  getMainCategory,
  createMainCategory,
  getTagTree,
  getSubCategory,
  updateSubCategory,
  getTags,
  getBusinessTypes,
  uploadPictureBo,
  uploadPictureBp,
  getGenericContent,
  updateGenericContent,
  getSmartServiceGroups,
  getAdminMaps,
  updateMapStatus,
  createMap,
  updateMap,
  getMap,
  getActiveMapsList,
};
