import React from "react";
import styled from "styled-components";
import ActionButton from "components/buttons/ActionButton";
import strings from "localisation/strings";
import { BoldTextButton } from "components/buttons/TextButton";
import { HorizontalLargeSpacer } from "components/generic/Spacer";

interface FilteringCommandsProps {
  onConfirm: () => void;
  onClear?: () => void;
}

const CommandArea = styled.div`
  margin-top: 20px;
  align-self: flex-end;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const FilteringCommands = ({ onConfirm, onClear }: FilteringCommandsProps) => (
  <CommandArea>
    {onClear && (
      <BoldTextButton
        onClick={onClear}
        text={strings("filterScreen.clearButton")}
      />
    )}
    <HorizontalLargeSpacer />
    <ActionButton onClick={onConfirm} text={strings("buttons.confirm")} />
  </CommandArea>
);

export default FilteringCommands;
