import React, { Component } from "react";
import { equals } from "ramda";
import theme from "style/theme";
import styled from "style/styled-components";
import strings from "localisation/strings";

import PriceMinMax from "api/models/PriceMinMax";
import Checkbox from "components/forms/Checkbox";
import InlineNumberInput from "components/forms/FormFields/InlineNumberInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { HorizontalSmallSpacer, PaddedSpacer } from "components/generic/Spacer";
import { FormErrorsInterface } from "hooks/useFormFields";

import priceIcon from "assets/icons/price.svg";

export interface PriceRangeProps {
  value?: PriceMinMax;
  onChange?: (priceMinMax: PriceMinMax) => void;
  errors?: FormErrorsInterface;
  placeholder?: string;
}

interface State {
  free?: boolean;
  max?: number | null;
  min?: number | null;
}

export const PriceSymbol = () => (
  <span>{strings("detailedScreen.price.currency")}</span>
);

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`;

const FieldWrapper = styled.span<{ paddingTop?: string }>`
  padding-right: 10px;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "unset")};
  max-width: 40%;
`;

class PriceRange extends Component<PriceRangeProps, State> {
  constructor(props: PriceRangeProps) {
    super(props);
    this.state = {
      free: false,
      min: undefined,
      max: undefined,
    };
  }

  componentDidUpdate(prevProps: PriceRangeProps) {
    if (!equals(this.props.value, prevProps.value)) {
      this.populateState();
    }
  }

  populateState = () => {
    if (this.props.value) {
      const { min, max, free } = this.props.value;
      this.setState({
        min,
        max,
        free,
      });
    }
  };

  updateProps = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state);
    }
  };

  changeMinPrice = (min: number | null) =>
    this.setState({ min }, this.updateProps);

  changeMaxPrice = (max: number | null) =>
    this.setState({ max }, this.updateProps);

  toggleFree = () => {
    const state = !this.state.free
      ? {
          free: true,
          min: undefined,
          max: undefined,
        }
      : { free: false };

    this.setState(state, this.updateProps);
  };

  getErrors = (field: "min" | "max"): string[] | undefined => {
    return this.props.errors && this.props.errors[field];
  };

  render() {
    const { free, max, min } = this.state;
    const minFieldErrors = this.getErrors("min");
    const maxFieldErrors = this.getErrors("max");

    return (
      <Container>
        <InlineInputGroup
          icon={priceIcon}
          placeholder={
            this.props.placeholder
              ? this.props.placeholder
              : strings("detailedScreen.price.price")
          }
          width={theme.forms.leftSide}
          margin={`${theme.margin.xsmall} 0`}
        >
          <InlineNumberInput
            type="text"
            disabled={free}
            errors={minFieldErrors}
            setValue={this.changeMinPrice}
            value={min}
            placeholder={strings("detailedScreen.price.priceFrom")}
            width="25%"
            testId="price-from"
          />
          <HorizontalSmallSpacer />
          <InlineNumberInput
            type="text"
            disabled={free}
            errors={maxFieldErrors}
            setValue={this.changeMaxPrice}
            placeholder={strings("detailedScreen.price.priceTo")}
            value={max}
            width="25%"
            testId="price-to"
          />
          <PaddedSpacer padding={`${theme.margin.medium} !important`}>
            <PriceSymbol />
          </PaddedSpacer>
          <FieldWrapper paddingTop="8px">
            <Checkbox
              name="price"
              text={strings("detailedScreen.price.free")}
              checked={Boolean(free)}
              onChange={this.toggleFree}
            />
          </FieldWrapper>
        </InlineInputGroup>
      </Container>
    );
  }
}

export default PriceRange;
