import React, { HTMLAttributes } from "react";
import styled from "style/styled-components";
import Badge from "components/generic/Badge";

const Count = styled.span`
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  font-weight: bold;
`;

interface CountBadgeProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
}

const CountBadge = ({ text, ...rest }: CountBadgeProps) => (
  <Badge {...rest}>
    <Count>{text}</Count>
  </Badge>
);

export default CountBadge;
