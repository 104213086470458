import { useEffect, useState } from "react";
import useGlobalScript from "views/BusinessSettings/Payment/hooks/useGlobalScript";
import { noop } from "utils";
import theme from "style/theme";
import useQuery from "common/hooks/useQuery";
import { getPayoneRequestConfig } from "api/businessProfiles";
import QueryStatus from "common/api/models/QueryStatus";
import strings from "localisation/strings";

declare global {
  interface PayoneCreditCardData {
    pseudocardpan: string;
    truncatedcardpan?: string;
    cardtype?: string;
    cardexpiredate?: string;
  }

  interface PayoneCheckResponse extends PayoneCreditCardData {
    status: string;
    errorcode: string;
    errormessage: string;
    customermessage: string;
  }

  interface PayoneClient {
    isComplete: () => boolean;
    creditCardCheck: (callbackFunctionName: string) => void;
  }

  interface Window {
    checkCallback: (response: PayoneCheckResponse) => void;
    Payone: any;
    PayoneGlobals: any;
  }
}

const stylesConfig = {
  inputHeight: "35px",
  largeInputWidth: "100%",
  largeInputContainerWidth: "100%",
  smallInputWidth: "54px",
  smallInputContainerWidth: "74px",
};

const defaultInputStyleConfig = `
  padding: ${theme.components.inlineInput.padding};
  border-radius: ${theme.components.inlineInput.borderRadius};
  font-size: ${theme.font.size.medium};
  font-family: ${theme.font.family.unbranded};
  color: ${theme.components.inlineInput.textColor};
  line-height: ${theme.components.inlineInput.lineHeight};
  outline: none;
  height: 100%;
  border: 1px solid ${theme.color.border.inputBorder};
`;
const smallInputStyleConfig = defaultInputStyleConfig.concat(`
  width: ${stylesConfig.smallInputWidth};
`);
const selectStyleConfig = defaultInputStyleConfig.concat(`
  width: "100%";
`);
const largeInputStyleConfig = defaultInputStyleConfig.concat(`
  width: ${stylesConfig.largeInputWidth};
`);
const smallInputIframeConfig = {
  height: stylesConfig.inputHeight,
  width: stylesConfig.smallInputContainerWidth,
};
const largeInputIframeConfig = {
  height: stylesConfig.inputHeight,
  width: stylesConfig.largeInputContainerWidth,
};

interface InitialisePayoneProps {
  requestConfig: any;
  onComplete: () => void;
  onInitialised: () => void;
  onCreditCardTypeDetected: (creditCardType: string) => void;
}

const ininitialisePayone = ({
  requestConfig,
  onComplete,
  onInitialised,
  onCreditCardTypeDetected,
}: InitialisePayoneProps) => {
  window.Payone.ClientApi.Language.en.placeholders.cardpan = "Card number";
  window.Payone.ClientApi.Language.de.placeholders.cardpan = "Kartennummer";
  window.Payone.ClientApi.Language.en.placeholders.expireMonth = "MM";
  window.Payone.ClientApi.Language.de.placeholders.expireMonth = "MM";
  window.Payone.ClientApi.Language.en.placeholders.expireYear = "YY";
  window.Payone.ClientApi.Language.de.placeholders.expireYear = "JJ";
  window.Payone.ClientApi.Language.en.placeholders.cvc = "CVC";
  window.Payone.ClientApi.Language.de.placeholders.cvc = "CVC";

  // required CVC length per cardtype
  const cvcLengthConfig = {
    V: 3,
    M: 3,
    O: 3,
  };

  const config = {
    fields: {
      cardpan: {
        selector: "cardpan",
        type: "text",
        width: stylesConfig.largeInputWidth,
        style: largeInputStyleConfig,
        iframe: largeInputIframeConfig,
      },
      cardcvc2: {
        selector: "cardcvc2",
        type: "password",
        size: "3",
        maxlength: "3",
        length: cvcLengthConfig,
      },
      cardexpiremonth: {
        selector: "cardexpiremonth",
        type: "select",
        size: "2",
        maxlength: "2",
      },
      cardexpireyear: {
        selector: "cardexpireyear",
        type: "select",
        size: "2",
        maxlength: "2",
      },
    },
    defaultStyle: {
      input: smallInputStyleConfig,
      iframe: smallInputIframeConfig,
      select: selectStyleConfig,
      selectFocused: selectStyleConfig,
    },
    autoCardtypeDetection: {
      supportedCardtypes: ["V", "M", "O"],
      callback: onCreditCardTypeDetected,
    },
    events: {
      ready: noop,
      rendered: onInitialised,
      isComplete: onComplete,
    },
    language: window.Payone.ClientApi.Language.de,
  };

  return new window.Payone.ClientApi.HostedIFrames(config, requestConfig);
};

const usePayoneClient = () => {
  const [loaded, error] = useGlobalScript(
    process.env.REACT_APP_PAYONE_SCRIPT as string,
  );
  const { result, status } = useQuery({
    request: getPayoneRequestConfig,
    compare: [],
  });
  const [isInitialised, setInitialised] = useState(false);
  const [isCreditCardValid, setCreditCardValid] = useState(false);
  const [isValidating, setValidating] = useState(false);
  const [isComplete, setSubmittable] = useState(false);
  const [client, setClient] = useState<PayoneClient | null>(null);

  const isPayoneRequestDataAvailalbe = status === QueryStatus.SUCCESSFUL;
  useEffect(() => {
    if (!isPayoneRequestDataAvailalbe || !loaded || error) {
      return;
    }

    const payoneClient = ininitialisePayone({
      requestConfig: result,
      onCreditCardTypeDetected: () => {
        setCreditCardValid(true);
      },
      onComplete: () => {
        setSubmittable(true);
      },
      onInitialised: () => {
        setInitialised(true);
      },
    });

    setClient(payoneClient);
  }, [loaded, error, isPayoneRequestDataAvailalbe]);

  const validateCreditCardData = (): Promise<PayoneCreditCardData> =>
    new Promise((resolve, reject) => {
      if (!client) {
        reject({
          message: strings("generic.error"),
        });
        return;
      }

      setValidating(true);

      window.checkCallback = ({
        status: payoneCheckStatus,
        ...response
      }: PayoneCheckResponse) => {
        setValidating(false);
        if (payoneCheckStatus === "VALID") {
          resolve(response);
        } else {
          reject({
            message: response.customermessage || response.errormessage,
          });
        }
      };

      client.creditCardCheck("checkCallback");
    });

  return {
    client,
    isInitialised,
    isComplete,
    isValidating,
    isCreditCardValid,
    validateCreditCardData,
  };
};

export default usePayoneClient;
