import React from "react";

import BusinessProfileInterface from "api/models/BusinessUserProfileInterface";
import PictureTypes from "api/models/PictureTypes";

import BaseListItem from "components/lists/item/BaseListItem";
import { ListItemProps } from "components/lists/item/BaseListItemComponents";
import { excludeDuplicateTags } from "components/helpers/formHelpers/category";

export interface OfferListItemProps extends ListItemProps {
  businessProfile: BusinessProfileInterface;
}

const BusinessProfileListItem = ({
  businessProfile,
  onClick,
}: OfferListItemProps) => {
  const {
    contactInformation,
    id,
    lastModifiedBy,
    pictures,
    modifiedDateTime,
    profileState,
    name,
    tags = [],
  } = businessProfile;
  const image = pictures
    ? pictures.find(item => item.type === PictureTypes.main)
    : undefined;
  const imageUrl = image ? image.url : undefined;

  return (
    <BaseListItem
      id={id}
      imageUrl={imageUrl}
      lastModifiedBy={lastModifiedBy}
      location={contactInformation}
      modifiedDateTime={modifiedDateTime}
      onClick={onClick}
      status={profileState}
      tags={excludeDuplicateTags(tags)}
      title={name}
      contentType={"BUSINESS"}
    />
  );
};

export default BusinessProfileListItem;
