import React from "react";
import Plan, { PlanTypes } from "api/models/Plan";
import styled from "styled-components";
import PlanCard, {
  PlaceholderPlanContainer,
  PlanContainer,
} from "views/Wizard/PlanCard";

const totalWidthPercent = 100;
const itemsToFit = 5;
const marginPercent = 2;
const bothSidesBorderWidth = 2;

const percentPerItem = totalWidthPercent / itemsToFit;
const cardPercent = percentPerItem - marginPercent;
const cardMinWidth = 190; // do not let percentage based flex-basis make the card smaller than this

/**
 * Contains negative right margin to compensate flexbox last item margin
 */
const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -${marginPercent}%;

  ${PlanContainer} {
    margin-right: calc(${marginPercent}% - ${bothSidesBorderWidth}px);
    margin-top: ${marginPercent}%;
    flex-basis: ${cardPercent}%;
    min-width: ${cardMinWidth}px;
  }
`;

interface PlanSelectionProps {
  plans: Plan[];
  currentPlan?: Plan | null;
  customPlanSelectable?: boolean;
  nonProfitPlanSelectable?: boolean;
  onSelect?: (plan: Plan) => void;
  onCurrentPlanSelect?: () => void;
}

const PlanSelection = ({
  plans,
  currentPlan,
  customPlanSelectable,
  nonProfitPlanSelectable,
  onSelect,
  onCurrentPlanSelect,
}: PlanSelectionProps) => (
  <PlansContainer>
    {plans.map(plan => (
      <PlanCard
        key={plan.planId}
        plan={plan}
        isCurrent={!!currentPlan && plan.planId === currentPlan.planId}
        isSelectable={
          !plan.disabled &&
          ((plan.planType === PlanTypes.nonprofit && nonProfitPlanSelectable) ||
            (plan.planType === PlanTypes.custom && customPlanSelectable) ||
            (plan.planType !== PlanTypes.nonprofit &&
              plan.planType !== PlanTypes.custom))
        }
        onSelect={onSelect}
        onCurrentPlanSelect={onCurrentPlanSelect}
      />
    ))}
    <PlaceholderPlanContainer />
    <PlaceholderPlanContainer />
    <PlaceholderPlanContainer />
    <PlaceholderPlanContainer />
    <PlaceholderPlanContainer />
  </PlansContainer>
);

export default PlanSelection;
