import styled from "style/styled-components";

const Hint = styled.span<{ editable?: boolean }>`
  background-color: ${({ theme }) => theme.color.foreground.antiPrimary};
  color: ${({ theme, editable = true }) =>
    editable
      ? theme.color.foreground.primary
      : theme.color.foreground.tertiary};
  font-size: ${({ theme }) => theme.font.size.small};
  height: 18px;
  line-height: 18px;
  position: absolute;
  left: ${({ theme }) => theme.margin.large};
  padding: 2px;
  user-select: none;
  width: 100%;
`;

export default Hint;
