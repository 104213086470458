import React from "react";
import styled from "style/styled-components";
import TagInterface from "api/models/TagInterface";
import { remapTagSuccessorsToSubItems } from "common/components/filtering/filterTransformUtils";
import NestedCheckbox from "components/generic/NestedCheckbox";

export interface NestedTagListProps {
  tagFilter: TagInterface[];
  isTagSelected: (idPath: string[]) => boolean;
  toggleTag: (idPath: string[]) => void;
  selectTags: (idPaths: string[][]) => void;
}

const TagListArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const NestedTagList = ({
  tagFilter,
  isTagSelected,
  toggleTag,
  selectTags,
}: NestedTagListProps) => (
  <TagListArea>
    {tagFilter
      .map(remapTagSuccessorsToSubItems)
      .map(({ id, name, subItems }) => (
        <NestedCheckbox
          key={id}
          name={name}
          subItems={subItems}
          id={id}
          idPath={[id]}
          selected={isTagSelected([id])}
          isItemSelected={isTagSelected}
          toggleItem={toggleTag}
          selectItems={selectTags}
        />
      ))}
  </TagListArea>
);

export default NestedTagList;
