import RequestOptions from "api/RequestOptions";
import {
  LoginPayload,
  LoginTokens,
  PasswordRecoveryPayload,
  RecaptchaNeededPayload,
  RecaptchaNeededResultType,
  RefreshPayload,
  RegisterPayload,
  ResetPasswordPayload,
} from "api/models/UsersInterface";
import API from "api/API";

interface RefreshResponse {
  headers: Headers;
  result: LoginTokens;
}

const sessionAPI = new API({});

const getRefreshedToken = async (
  body: RefreshPayload,
): Promise<RefreshResponse> => {
  const refreshResponse = await postRefresh(body);
  const newToken = refreshResponse.result;
  if (newToken.serviceToken) {
    return refreshResponse;
  }

  throw new Error("Reauthentication failed");
};

const activateAccount = (token: string, options: RequestOptions) =>
  sessionAPI.getJson<object>({ options, path: `users/bp/activate/${token}` });

const postLogin = (body: LoginPayload, options?: RequestOptions) =>
  sessionAPI.postJson<LoginTokens>({
    options,
    body,
    path: "users/bp/login",
  });

const adminPostLogin = (body: LoginPayload, options?: RequestOptions) =>
  sessionAPI.postJson<LoginTokens>({
    options,
    body,
    path: "users/bo/login",
  });

const recaptchaNeeded = (
  body: RecaptchaNeededPayload,
  options?: RequestOptions,
) =>
  sessionAPI.postJson<RecaptchaNeededResultType>({
    options,
    body,
    path: "users/bp/login-attempts",
  });

const adminRecaptchaNeeded = (
  body: RecaptchaNeededPayload,
  options?: RequestOptions,
) =>
  sessionAPI.postJson<RecaptchaNeededResultType>({
    options,
    body,
    path: "users/bo/login-attempts",
  });

const postRegister = (body: RegisterPayload, options?: RequestOptions) =>
  sessionAPI.postJson<object>({
    options,
    body,
    path: "users/bp/register",
  });

const postPasswordRecovery = (
  options: RequestOptions,
  body?: PasswordRecoveryPayload,
) =>
  sessionAPI.postJson<object>({
    options,
    body,
    path: "users/bp/password-recovery",
  });

const adminPostPasswordRecovery = (
  options: RequestOptions,
  body?: PasswordRecoveryPayload,
) =>
  sessionAPI.postJson<object>({
    options,
    body,
    path: "users/bo/password-recovery",
  });

const postResetPassword = (
  options: RequestOptions,
  body?: ResetPasswordPayload,
) =>
  sessionAPI.postJson<object>({
    options,
    body,
    path: "users/bp/change-password",
  });

const changeAdminPassword = (
  options: RequestOptions,
  body?: ResetPasswordPayload,
) =>
  sessionAPI.postJson<object>({
    options,
    body,
    path: "users/bo/change-password",
  });

const postRequestDeletion = (
  options: RequestOptions,
  body?: PasswordRecoveryPayload,
) =>
  sessionAPI.postJson<object>({
    options,
    body,
    path: "users/request-deletion",
  });

const deleteUser = (token: string, options: RequestOptions) =>
  sessionAPI.getJson<object>({
    options,
    path: `users/delete-user/${token}`,
  });

const postRefresh = async (body: RefreshPayload, options?: RequestOptions) =>
  sessionAPI.postJson<LoginTokens>({
    options,
    body,
    path: "users/refresh",
  });

export interface SetRepresentativePasswordPayload {
  token: string;
  password: string;
  passwordConfirmation: string;
  permissionToAnalyzeData: boolean;
  acceptedTermsAndConditions: boolean;
}

const createRepresentativePassword = (
  body: SetRepresentativePasswordPayload,
  options?: RequestOptions,
) =>
  sessionAPI.postJson<void>({
    options,
    body,
    path: "users/bp/create-representative-password",
  });

const createAdminPassword = (
  body: SetRepresentativePasswordPayload,
  options?: RequestOptions,
) =>
  sessionAPI.postJson<void>({
    options,
    body,
    path: "users/bo/create-representative-password",
  });

const validateAccountActivationToken = (
  token: string,
  options?: RequestOptions,
) =>
  sessionAPI.getJson<void>({
    options,
    path: `users/bp/token-active/${token}`,
  });

const validateAdminAccountToken = (token: string, options?: RequestOptions) =>
  sessionAPI.getJson<void>({
    options,
    path: `users/bo/token-active/${token}`,
  });

const logout = (options?: RequestOptions) =>
  sessionAPI.postJson({
    options,
    path: "users/logout-secure",
  });

export {
  adminPostLogin,
  adminPostPasswordRecovery,
  sessionAPI,
  activateAccount,
  postResetPassword,
  postLogin,
  postRegister,
  getRefreshedToken,
  postPasswordRecovery,
  recaptchaNeeded,
  adminRecaptchaNeeded,
  changeAdminPassword,
  createAdminPassword,
  createRepresentativePassword,
  validateAccountActivationToken,
  validateAdminAccountToken,
  logout,
  postRequestDeletion,
  deleteUser,
};
