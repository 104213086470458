import React, { useEffect } from "react";

import BusinessRepresentativeForm from "components/forms/BusinessRepresentative/BusinessRepresentativeForm";
import strings from "localisation/strings";
import { Title, CenteredContentViewContainer } from "components/generic";
import {
  postRepresentative,
  postRepresentativeCurator,
  putRepresentative,
} from "api/businessRepresentatives";
import {
  postBoRepresentative,
  putBoRepresentative,
} from "api/backOfficeBusinessRepresentatives";

import { RouteComponentProps, withRouter } from "react-router";
import RepresentativeFormContainer from "components/forms/BusinessRepresentative/RepresentativeFormContainer";
import useSelectedRepresentative from "hooks/useSelectedRepresentative";
import useLoginState from "hooks/useLoginState";
import useNotificationState from "hooks/useNotification";
import { includes } from "ramda";
import { ADD_CURATOR } from "views/BusinessRepresentatives/useRepresentativesListView";
import AssignCuratorForm from "components/forms/BusinessRepresentative/AssignCuratorForm";

const RepresentativeFormView = ({
  match,
  history,
}: RouteComponentProps<{ id: string; businessId: string }>) => {
  const LoginState = useLoginState();
  const postRequest = LoginState.hasAdminRights()
    ? postBoRepresentative
    : LoginState.hasCuratorRights()
    ? postRepresentativeCurator
    : postRepresentative;
  const putRequest = LoginState.hasAdminRights()
    ? putBoRepresentative
    : putRepresentative;
  const isCuratorAssignmentForm = includes(match.params.id, ADD_CURATOR);

  const isDefaultCompany = LoginState.isDefaultBusinessContext();

  const businessId =
    match.params.businessId || LoginState.getCurrentUserBusinessId(); // if businessId does not exist in path it means it is business user's main business

  const { representative, error } = useSelectedRepresentative(
    match.params.id,
    businessId,
  );

  const { addErrorNotification } = useNotificationState();
  useEffect(() => {
    if (error) {
      addErrorNotification(error.detail);
    }
  }, [error]);

  return (
    <CenteredContentViewContainer>
      <RepresentativeFormContainer>
        {isCuratorAssignmentForm ? (
          <>
            <Title>{strings("addCuratorForm.title")}</Title>
            <AssignCuratorForm
              businessId={businessId || ""}
              history={history}
            />
          </>
        ) : (
          <>
            <Title>
              {isDefaultCompany
                ? strings("addRepresentativeForm.adminTitle")
                : strings("addRepresentativeForm.title")}
            </Title>
            <BusinessRepresentativeForm
              isAdminView={isDefaultCompany}
              representative={representative}
              addNewRequest={postRequest}
              saveRequest={putRequest}
              mainBusinessId={businessId}
            />
          </>
        )}
      </RepresentativeFormContainer>
    </CenteredContentViewContainer>
  );
};

export default withRouter(RepresentativeFormView);
