import React, { PureComponent } from "react";
import PostInterface from "api/models/PostInterface";
import List from "components/lists/List";
import PostListItem from "components/lists/postList/PostListItem";

export interface PostListProps {
  emptyIndicator?: string;
  emptyIndicatable?: boolean;
  posts: PostInterface[];
  onItemClick: (eventId: string) => void;
}

class PostsList extends PureComponent<PostListProps> {
  render() {
    const { posts, onItemClick, emptyIndicatable, emptyIndicator } = this.props;
    return (
      <List<PostInterface>
        emptyIndicator={emptyIndicator}
        emptyIndicatable={emptyIndicatable}
        data={posts}
        renderItem={(post, index) => (
          <PostListItem key={index} post={post} onClick={onItemClick} />
        )}
      />
    );
  }
}

export default PostsList;
