import React, { RefObject } from "react";
import styled, { css } from "style/styled-components";
import { ThemeInterface } from "style/theme";
import ActionButtonText from "components/buttons/ActionButtonText";
import Testable from "testing/Testable";
import { actionButtonTestId } from "testing/testId";

type ActionButtonSpecialty = "login" | "publish" | "transparent" | "narrow";

export interface ActionButtonProps extends Testable {
  text: string;
  testId?: string;
  loadingText?: string;
  onClick?: () => void;
  alone?: boolean;
  disabled?: boolean;
  className?: string;
  reference?: RefObject<HTMLButtonElement>;
  special?: ActionButtonSpecialty;
}

export const baseButtonStyle = css`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.margin.medium} ${theme.margin.xlarge}`};
  // IE11 specific styles
  @media all and (-ms-high-contrast: none) {
    padding: ${({ theme }) => `${theme.margin.medium} ${theme.margin.large}`};
  }
  height: 46px;
  min-width: 120px;
  white-space: nowrap;
  &:disabled {
    cursor: ${({ theme }) => theme.cursors.notAllowed};
  }
`;

export const actionButtonStyle = css`
  ${baseButtonStyle}
  color: ${({ theme }) => theme.color.foreground.action};
  background-color: ${({ theme }) => theme.color.background.primary};
  margin: ${({ theme }) => theme.margin.xsmall};
  border-radius: ${({ theme }) => theme.border.radius.unlimited};
  border-style: solid;
  border-color: ${({ theme }) => theme.color.foreground.action};
  border-width: 1.5px;
  &:disabled {
    color: ${({ theme }) => theme.color.foreground.disabledNew};
    border-color: ${({ theme }) => theme.color.background.disabled};
    background-color: ${({ theme }) => theme.color.background.disabled};
  }
`;
const getSpecialButtonStyles = (theme: ThemeInterface, special?: string) => {
  if (special) {
    if (special === "login") {
      return css`
        background-color: transparent;
        border-color: ${theme.color.foreground.antiPrimary};
        color: ${theme.color.foreground.antiPrimary};
      `;
    }
    if (special === "publish") {
      return css`
        background-color: ${theme.color.foreground.action};
        border-color: ${theme.color.foreground.action};
        color: ${theme.color.foreground.antiPrimary};
      `;
    }
    if (special === "transparent") {
      return css`
        background-color: transparent;
        border: ${theme.color.foreground.antiPrimary};
        color: ${theme.color.foreground.action};
        &:disabled {
          background-color: transparent;
          color: ${theme.color.foreground.tertiary};
        }
      `;
    }
    if (special === "narrow") {
      return css`
        background-color: ${theme.color.foreground.antiPrimary};
        border-color: ${theme.color.foreground.action};
        color: ${theme.color.foreground.action};
        padding: 0 ${theme.margin.medium};
        height: 30px;
      `;
    }
    return css`
      background-color: ${theme.color.foreground.action};
      border-color: ${theme.color.foreground.action};
      color: ${theme.color.foreground.quaternary};
    `;
  }

  return css`
    background-color: ${theme.color.foreground.antiPrimary};
    border-color: ${theme.color.foreground.action};
    color: ${theme.color.foreground.action};
  `;
};

export const Container = styled.button<Partial<ActionButtonProps>>`
  ${actionButtonStyle}
  ${({ alone }) => (alone ? "margin: 0;" : {})}
  ${({ theme, special }) =>
    special ? getSpecialButtonStyles(theme, special) : {}}
`;

const ActionButton = ({
  text,
  loadingText,
  reference,
  testId,
  ...props
}: ActionButtonProps) => (
  <Container {...props} {...actionButtonTestId(testId || text)}>
    <ActionButtonText>
      {loadingText && props.disabled ? loadingText : text}
    </ActionButtonText>
  </Container>
);

export default ActionButton;
