import React from "react";
import {
  ContentContainer,
  TextScreenContainer,
} from "views/TextScreens/components";
import { MarginlessViewContainer } from "components/generic/ViewContainer";
import GenericRichTextContent from "components/generic/richText/GenericRichTextContent";

const TermsAndConditionsScreen = () => (
  <MarginlessViewContainer>
    <TextScreenContainer>
      <ContentContainer>
        <GenericRichTextContent contentKey="bp-terms-and-conditions" />
      </ContentContainer>
    </TextScreenContainer>
  </MarginlessViewContainer>
);

export default TermsAndConditionsScreen;
