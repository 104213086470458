import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import { DashboardInterface } from "api/models/DashboardInterface";
import StatisticType from "components/dashboard/StatisticType";
import StatisticTypeTab from "components/dashboard/StatisticTypeTab";
import ContentType from "common/api/models/ContentType";

interface Props {
  fetchedResult: DashboardInterface;
  contentType: ContentType;
  statisticType: string;
  setStatisticType: (statisticType: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${({ theme }) => theme.margin.large};
  border-bottom: 1px solid ${({ theme }) => theme.color.background.quaternary};
  height: 50px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

const StatisticTypeTabs = ({
  fetchedResult,
  contentType,
  statisticType,
  setStatisticType,
}: Props) => {
  return (
    <Container>
      <StatisticTypeTab
        name={strings("dashboardScreen.statisticType.totalClicks")}
        total={fetchedResult.totalClicks.total}
        isSelected={statisticType === StatisticType.totalClicks}
        statisticTypeToBeApplied={StatisticType.totalClicks}
        setStatisticType={setStatisticType}
      />
      <StatisticTypeTab
        name={strings("dashboardScreen.statisticType.clicksFromSearch")}
        total={fetchedResult.searchClicks.total}
        isSelected={statisticType === StatisticType.searchClicks}
        statisticTypeToBeApplied={StatisticType.searchClicks}
        setStatisticType={setStatisticType}
      />
      <StatisticTypeTab
        name={strings("dashboardScreen.statisticType.objectsShared")}
        total={fetchedResult.objectsShared.total}
        isSelected={statisticType === StatisticType.objectsShared}
        statisticTypeToBeApplied={StatisticType.objectsShared}
        setStatisticType={setStatisticType}
      />
      {fetchedResult.favoritesAdded && (
        <StatisticTypeTab
          name={strings("dashboardScreen.statisticType.addedToFavourites")}
          total={fetchedResult.favoritesAdded.total}
          isSelected={statisticType === StatisticType.favoritesAdded}
          statisticTypeToBeApplied={StatisticType.favoritesAdded}
          setStatisticType={setStatisticType}
        />
      )}
      {fetchedResult.likesAdded && (
        <StatisticTypeTab
          name={strings("dashboardScreen.statisticType.addedToLikes")}
          total={fetchedResult.likesAdded.total}
          isSelected={statisticType === StatisticType.likesAdded}
          statisticTypeToBeApplied={StatisticType.likesAdded}
          setStatisticType={setStatisticType}
        />
      )}
      <StatisticTypeTab
        name={strings("dashboardScreen.statisticType.ratedOrReviewed")}
        total={fetchedResult.ratedOrReviewed.total}
        isSelected={statisticType === StatisticType.ratedOrReviewed}
        statisticTypeToBeApplied={StatisticType.ratedOrReviewed}
        setStatisticType={setStatisticType}
      />
      {contentType === "EVENT" && fetchedResult.calendarAdded && (
        <StatisticTypeTab
          name={strings("dashboardScreen.statisticType.addedToCalendar")}
          total={fetchedResult.calendarAdded.total}
          isSelected={statisticType === StatisticType.calendarAdded}
          statisticTypeToBeApplied={StatisticType.calendarAdded}
          setStatisticType={setStatisticType}
        />
      )}
      {contentType === "OFFER" && fetchedResult.offersRedeemed && (
        <StatisticTypeTab
          name={strings("dashboardScreen.statisticType.redeemed")}
          total={fetchedResult.offersRedeemed.total}
          isSelected={statisticType === StatisticType.offersRedeemed}
          statisticTypeToBeApplied={StatisticType.offersRedeemed}
          setStatisticType={setStatisticType}
        />
      )}
    </Container>
  );
};

export default StatisticTypeTabs;
