import React from "react";
import styled from "style/styled-components";
import poweredByGoogleLogo from "assets/poweredByGoogle.png";
import { SuggestionContainer } from "components/forms/FormFields/AddressAutocomplete/SuggestionsDropdown";

const PoweredByGoogleLogoContainer = styled(SuggestionContainer)`
  text-align: right;
`;

const PoweredByGoogleLogo = styled.img.attrs({
  src: poweredByGoogleLogo,
})`
  vertical-align: middle;
`;

const PoweredByGoogleIndicator = () => (
  <PoweredByGoogleLogoContainer>
    <PoweredByGoogleLogo />
  </PoweredByGoogleLogoContainer>
);

export default PoweredByGoogleIndicator;
