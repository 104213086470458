import React from "react";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import strings from "localisation/strings";
import PublicationStatuses from "api/models/PublicationStatuses";
import { UserStatuses } from "api/models/RepresentativeInterface";

const Text = styled.div<{ order?: number }>`
  font-size: ${({ theme }) => theme.font.size.pagination};
  font-weight: bold;
  line-height: 15px;
  width: 70px;
  text-align: right;
  order: ${({ order }) => order};

  @media ${breakpoints.phoneMax} {
    width: 100px;
    height: 45px;
    line-height: 45px;
    order: 1;
  }
`;

const Green = styled.span`
  color: ${({ theme }) => theme.color.foreground.noticeStrong};
`;

const Yellow = styled.span`
  color: ${({ theme }) => theme.color.foreground.noticeMedium};
`;

const Default = styled.span`
  color: ${({ theme }) => theme.color.font.onLightBackground};
`;

const getStatusString = (status: PublicationStatuses | UserStatuses) =>
  strings(`statuses.${status}`);

const getStatusComponent = (status: PublicationStatuses | UserStatuses) => {
  switch (status) {
    case PublicationStatuses.published:
      return <Green>{getStatusString(status)}</Green>;
    case UserStatuses.ACTIVE:
      return <Green>{getStatusString(status)}</Green>;
    case UserStatuses.INACTIVE:
      return <Default>{getStatusString(status)}</Default>;
    default:
      return <Yellow>{getStatusString(status)}</Yellow>;
  }
};

const ListItemStatus = ({
  status,
  order,
}: {
  status: PublicationStatuses | UserStatuses;
  order?: number;
}) => <Text order={order}>{getStatusComponent(status)}</Text>;

export default ListItemStatus;
