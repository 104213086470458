import React from "react";
import { withRouter } from "react-router";
import SidenavLayout, { SidebarLink } from "components/generic/SidenavLayout";
import { routeNames } from "core/navigation";
import strings from "localisation/strings";
import {
  MarginlessViewContainer,
  VerticalLargeSpacer,
} from "components/generic";
import { ReactComponent as TermsIcon } from "assets/icons/security.svg";
import { ReactComponent as MarketingIcon } from "assets/icons/marketing.svg";
import { ReactComponent as FaqIcon } from "assets/icons/chat.svg";
import { ReactComponent as AboutIcon } from "assets/icons/bligg.svg";
import { ReactComponent as QrCodeIcon } from "assets/icons/offers.svg";
import GenericRichTextContent from "components/generic/richText/GenericRichTextContent";
import styled from "style/styled-components";
import { ContentContainer } from "views/TextScreens/components";

const textIdsByRoute = new Map([
  ["agb", "terms-and-conditions"],
  ["marketingzwecke", "marketing-purposes"],
  ["fragen-und-antworten", "faq"],
  ["impressum", "imprint"],
  ["datenschutz", "data-protection"],
  ["qr-code", "qr-code"],
]);

const PublicPortalPagesSidebar = ({ selected }: { selected: string }) => {
  return (
    <>
      <SidebarLink
        to={routeNames.PublicPortalPages.getFullPath(
          routeNames.PublicPortalPages.Terms,
        )}
        text={strings("publicPortalPages.terms")}
        icon={TermsIcon}
        isActive={selected === routeNames.PublicPortalPages.Terms}
      />

      <VerticalLargeSpacer />

      <SidebarLink
        to={routeNames.PublicPortalPages.getFullPath(
          routeNames.PublicPortalPages.Marketing,
        )}
        text={strings("publicPortalPages.marketing")}
        icon={MarketingIcon}
        isActive={selected === routeNames.PublicPortalPages.Marketing}
      />

      <VerticalLargeSpacer />

      {/*commented for now, till more content appears in FAQ */}
      {/* <SidebarLink
        to={routeNames.PublicPortalPages.getFullPath(
          routeNames.PublicPortalPages.Faq,
        )}
        text={strings("publicPortalPages.faq")}
        icon={FaqIcon}
        isActive={selected === routeNames.PublicPortalPages.Faq}
      />

      <VerticalLargeSpacer /> */}

      <SidebarLink
        to={routeNames.PublicPortalPages.getFullPath(
          routeNames.PublicPortalPages.Imprint,
        )}
        text={strings("publicPortalPages.imprint")}
        icon={AboutIcon}
        isActive={selected === routeNames.PublicPortalPages.Imprint}
      />

      <VerticalLargeSpacer />

      <SidebarLink
        to={routeNames.PublicPortalPages.getFullPath(
          routeNames.PublicPortalPages.Datenschutz,
        )}
        text={strings("publicPortalPages.dataProtection")}
        icon={AboutIcon}
        isActive={selected === routeNames.PublicPortalPages.Datenschutz}
      />

      <VerticalLargeSpacer />

      <SidebarLink
        to={routeNames.PublicPortalPages.getFullPath(
          routeNames.PublicPortalPages.QrCode,
        )}
        text={strings("publicPortalPages.qrCode")}
        icon={QrCodeIcon}
        isActive={selected === routeNames.PublicPortalPages.QrCode}
      />

      <VerticalLargeSpacer />
    </>
  );
};

const CenteredContentViewContainer = styled(MarginlessViewContainer)`
  flex-grow: 1;
`;

const PublicPortalPages = withRouter(({ match }) => {
  const { id: selected = routeNames.PublicPortalPages.Terms } = match.params;
  const textId = `app-${textIdsByRoute.get(selected)}`;

  return (
    <SidenavLayout sidebar={<PublicPortalPagesSidebar selected={selected} />}>
      <CenteredContentViewContainer>
        <ContentContainer>
          <GenericRichTextContent key={textId} contentKey={textId} />
        </ContentContainer>
      </CenteredContentViewContainer>
    </SidenavLayout>
  );
});

export default PublicPortalPages;
