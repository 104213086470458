import React, { HTMLAttributes } from "react";
import styled from "style/styled-components";
import TagButton from "components/buttons/TagButton";
import RemoveButton from "components/buttons/RemoveButton";

interface CloseableTagButtonProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

const MarginedRemoveButton = styled(RemoveButton)`
  margin-left: ${({ theme }) => theme.margin.xsmall};
`;

const CloseableTagButton = ({
  children,
  onClose,
  ...props
}: CloseableTagButtonProps) => (
  <TagButton {...props}>
    {children}
    <MarginedRemoveButton
      onClick={e => {
        e.stopPropagation();
        onClose();
      }}
    />
  </TagButton>
);

export default CloseableTagButton;
