import React from "react";
import strings from "localisation/strings";
import { SimpleText, Title } from "components/generic";
import useQuery from "common/hooks/useQuery";
import {
  downloadContract,
  getBusinessPlan,
  isContractAvailable,
} from "api/businessProfiles";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import PlanHistoryList from "components/lists/planSettings/PlanHistoryList";
import CurrentPlanHorizontalCardOrLink from "views/BusinessSettings/PlanSelection/components/CurrentPlanHorizontalCardOrLink";
import PlanCard from "views/Wizard/PlanCard";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";
import styled from "style/styled-components";
import { routeNames } from "core/navigation";
import { RouteComponentProps } from "core/types";
import { isLoading } from "utils";
import PlanExpiryNotice from "views/BusinessSettings/PlanSelection/components/PlanActivationNotice";
import DownloadButton from "components/buttons/DownloadButton";
import BusinessSettingsSidebar from "views/BusinessSettings/BusinessSettingsSidebar";

const LegacyCurrentPlanCardContainer = styled.div`
  max-width: ${({ theme }) => theme.forms.small};
`;

const BusinessSettingsPlanSelection = ({ history }: RouteComponentProps) => {
  const { result: contractAvailable } = useQuery({
    request: isContractAvailable,
  });
  const { result: planHistory, status: getBusinessPlanStatus } = useQuery({
    request: getBusinessPlan,
  });

  const handleChangePlanRequested = () =>
    history.push(routeNames.BusinessSettings.PlanChange);

  if (isPayoneIntegrationEnabled()) {
    return (
      <BusinessSettingsLayout
        expand
        size="large"
        title={strings("businessSettings.plan.title")}
        isLoading={isLoading(getBusinessPlanStatus)}
        sidebar={<BusinessSettingsSidebar />}
        titleRowButton={
          contractAvailable ? (
            <DownloadButton
              request={downloadContract}
              text={strings("businessSettings.plan.contract.download")}
            />
          ) : (
            undefined
          )
        }
      >
        {planHistory && <PlanExpiryNotice planHistory={planHistory} />}
        {planHistory ? (
          <CurrentPlanHorizontalCardOrLink
            plan={planHistory.currentPlan}
            onChangePlan={handleChangePlanRequested}
          />
        ) : (
          <SimpleText>{strings("businessSettings.plan.notSet")}</SimpleText>
        )}
        {planHistory && (
          <>
            <Title fixedMargins topMargined>
              {strings("businessSettings.plan.history.title")}
            </Title>
            <PlanHistoryList planHistory={planHistory} />
          </>
        )}
      </BusinessSettingsLayout>
    );
  }

  return (
    <BusinessSettingsLayout
      title={strings("businessSettings.plan.title")}
      isLoading={isLoading(getBusinessPlanStatus)}
      sidebar={<BusinessSettingsSidebar />}
      titleRowButton={
        contractAvailable ? (
          <DownloadButton
            request={downloadContract}
            text={strings("businessSettings.plan.contract.download")}
          />
        ) : (
          undefined
        )
      }
    >
      {planHistory && planHistory.currentPlan ? (
        <LegacyCurrentPlanCardContainer>
          <PlanCard plan={planHistory.currentPlan} isSelectable={false} />
        </LegacyCurrentPlanCardContainer>
      ) : (
        <SimpleText>{strings("businessSettings.plan.notSet")}</SimpleText>
      )}
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsPlanSelection;
