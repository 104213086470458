import { includes } from "ramda";
import TagInterface from "api/models/TagInterface";

export const getValue = (value?: string) =>
  (value && value.trim().toLowerCase()) || "";

export const findSuggestions = (
  items: TagInterface[],
  value: string,
  length: number,
): TagInterface[] => {
  if (length <= 0) {
    return items;
  }

  const filteredItems: TagInterface[] = [];
  items.forEach(item => {
    if (item.tagSuccessors) {
      const filteredTagSuccessors = item.tagSuccessors.filter(i =>
        includes(value, i.name.toLowerCase()),
      );

      if (filteredTagSuccessors.length > 0) {
        filteredItems.push({
          id: item.id,
          shortName: item.shortName,
          name: item.name,
          parentId: item.parentId,
          weight: item.weight,
          tagStatus: item.tagStatus,
          tagSuccessors: filteredTagSuccessors,
        });
      }
    } else if (includes(value, item.name)) {
      filteredItems.push(item);
    }
  });

  return filteredItems;
};
