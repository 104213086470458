// All possible user privileges. Reference: https://confluence.nortal.com/display/WSA/Roles+and+Privileges
enum UserPrivileges {
  BUSINESS_PROFILE_BP_READ = "BUSINESS_PROFILE_BP_READ",
  BUSINESS_PROFILE_BP_WRITE = "BUSINESS_PROFILE_BP_WRITE",
  BUSINESS_PROFILE_BO_READ = "BUSINESS_PROFILE_BO_READ",
  BUSINESS_PROFILE_BO_WRITE = "BUSINESS_PROFILE_BO_WRITE",
  CONTENT_BP_READ = "CONTENT_BP_READ",
  CONTENT_BP_WRITE = "CONTENT_BP_WRITE",
  CONTENT_BO_READ = "CONTENT_BO_READ",
  CONTENT_BO_WRITE = "CONTENT_BO_WRITE",
  USERS_BP_READ = "USERS_BP_READ",
  USERS_BP_WRITE = "USERS_BP_WRITE",
  USERS_BP_READ_ADMIN = "USERS_BP_READ_ADMIN",
  USERS_BP_WRITE_ADMIN = "USERS_BP_WRITE_ADMIN",
  USERS_BO_READ = "USERS_BO_READ",
  USERS_BO_WRITE = "USERS_BO_WRITE",
  USERS_BO_READ_ADMIN = "USERS_BO_READ_ADMIN",
  USERS_BO_WRITE_ADMIN = "USERS_BO_WRITE_ADMIN",
  SETTINGS_BP_READ = "SETTINGS_BP_READ",
  SETTINGS_BP_WRITE = "SETTINGS_BP_WRITE",
  SETTINGS_BO_READ = "SETTINGS_BO_READ",
  SETTINGS_BO_WRITE = "SETTINGS_BO_WRITE",
  ANALYTICS_READ = "ANALYTICS_READ",
  ANALYTICS_WRITE = "ANALYTICS_WRITE",
  CONFIG_READ = "CONFIG_READ",
  CONFIG_WRITE = "CONFIG_WRITE",
  TYPE_BUSINESS = "TYPE_BUSINESS",
  TYPE_INTERNAL = "TYPE_INTERNAL",
  TYPE_CURATOR = "TYPE_CURATOR",
  CHAT_READ = "CHAT_READ",
  CHAT_WRITE = "CHAT_WRITE",
}

export type UserPrivilege = UserPrivileges | string; // TODO: Remove string when BE values will be consistent with the docs

export default UserPrivileges;
