import React from "react";
import { TotalUnreadFloatingCount } from "components/chat/UnreadCount";
import useLoginState from "common/hooks/useLoginState";

const DefaultBusinessContextUnreadCount = () => {
  const { isDefaultBusinessContextOrUnselected } = useLoginState();

  return isDefaultBusinessContextOrUnselected() ? (
    <TotalUnreadFloatingCount size="small" />
  ) : null;
};

export default DefaultBusinessContextUnreadCount;
