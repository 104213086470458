import { pickBy, isEmpty, symmetricDifference } from "ramda";

export const removeEmptyValues = pickBy(value => value && !isEmpty(value));

export const removeEmptyValuesKeepEmptyArrays = pickBy(value => {
  if (Array.isArray(value)) {
    return true;
  }
  return value && !isEmpty(value);
});

export const arraysAreEqual = (arr1: any[], arr2: any[]) =>
  arr1.length === arr2.length && isEmpty(symmetricDifference(arr1, arr2));
