import React, { useEffect, useRef, useState } from "react";
import TopicsDropdown from "components/forms/FormFields/TopicsDropdown";
import styled from "style/styled-components";
import Checkbox from "components/forms/Checkbox";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { BoldText } from "components/generic/SimpleText";
import strings from "localisation/strings";
import Separator from "components/forms/FormFields/Separator";
import TextButton from "components/buttons/TextButton";
import useQuery from "common/hooks/useQuery";
import { getTopicsList } from "api/notifications";
import TopicInterface, { TopicType } from "api/models/TopicInterface";
import FieldErrors from "components/forms/FieldErrors";

interface TopicsSectionProps {
  onChange: (selectedField: any, selectedValue: any) => void;
  topicIdsFromResult: string[] | undefined;
  getErrors: (field: any) => string[];
}

export interface DisableMapProps {
  generalCheckbox: boolean;
  dropdownList: boolean;
  alertCheckbox: boolean;
  radarCheckbox: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 85px;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 40px;
  width: 100%;
`;

const ResetButton = styled(TextButton)`
  margin-left: 10px;
`;

const ErrorContainer = styled.div`
  width: 100%;
  margin-left: 85px;
`;

const GENERAL_ID = strings("postsScreen.fields.topics.general.id");
const ALERT_ID = strings("postsScreen.fields.topics.alert.id");
const ST_RADAR_ID = strings("postsScreen.fields.topics.radar.id");

const TopicsSection = ({
  onChange,
  topicIdsFromResult,
  getErrors,
}: TopicsSectionProps) => {
  const [generalCheckboxChecked, setGeneralCheckboxChecked] = useState(false);
  const [alertCheckboxChecked, setAlertCheckboxChecked] = useState(false);
  const [radarCheckboxChecked, setRadarCheckboxChecked] = useState(false);
  const [interestTopicsFromResult, setInterestTopicsFromResult] = useState<any>(
    [],
  );
  const disableMap = useRef<DisableMapProps>({
    generalCheckbox: false,
    dropdownList: false,
    alertCheckbox: false,
    radarCheckbox: false,
  });

  const response = useQuery({
    request: getTopicsList,
  });

  const allTopics = response.result?.topics ?? [];

  const getTopicById = (topicId: any): TopicInterface | undefined =>
    allTopics.find(topic => topic.topicId === topicId);

  useEffect(() => {
    setInterestTopicsFromResult(
      topicIdsFromResult
        ? topicIdsFromResult
            .map(topicId => getTopicById(topicId))
            .filter(topic => topic?.topicType === TopicType.Interest)
        : [],
    );

    if (topicIdsFromResult?.includes(GENERAL_ID)) {
      setGeneralCheckboxChecked(true);
    } else if (topicIdsFromResult?.includes(ALERT_ID)) {
      setAlertCheckboxChecked(true);
    } else if (topicIdsFromResult?.includes(ST_RADAR_ID)) {
      setRadarCheckboxChecked(true);
    }
  }, [allTopics, topicIdsFromResult]);

  useEffect(() => {
    if (generalCheckboxChecked) {
      onChange("topics", [getTopicById(GENERAL_ID)]);
    } else if (alertCheckboxChecked) {
      onChange("topics", [getTopicById(ALERT_ID)]);
    } else if (radarCheckboxChecked) {
      onChange("topics", [getTopicById(ST_RADAR_ID)]);
    } else {
      onChange("topics", []);
    }
    disableMap.current.generalCheckbox = generalCheckboxChecked;
    disableMap.current.alertCheckbox = alertCheckboxChecked;
    disableMap.current.radarCheckbox = radarCheckboxChecked;
  }, [
    generalCheckboxChecked,
    alertCheckboxChecked,
    radarCheckboxChecked,
    interestTopicsFromResult,
    allTopics,
  ]);

  const handleGeneralCheckboxClick = () => {
    setGeneralCheckboxChecked(!generalCheckboxChecked);
  };

  const handleAlertCheckboxClick = () => {
    setAlertCheckboxChecked(!alertCheckboxChecked);
  };

  const handleRadarCheckboxClick = () => {
    setRadarCheckboxChecked(!radarCheckboxChecked);
  };

  const handleReset = () => {
    setGeneralCheckboxChecked(false);
    setAlertCheckboxChecked(false);
    setRadarCheckboxChecked(false);
    setInterestTopicsFromResult([]);
    disableMap.current.generalCheckbox = false;
    disableMap.current.alertCheckbox = false;
    disableMap.current.radarCheckbox = false;
    disableMap.current.dropdownList = false;
  };

  return (
    <Container>
      <InlineInputGroup width="0">
        <StyledCheckbox
          text={strings("postsScreen.fields.topics.general.label")}
          name={strings("postsScreen.fields.topics.general.label")}
          checked={generalCheckboxChecked}
          onChange={handleGeneralCheckboxClick}
          disabled={
            disableMap.current.dropdownList ||
            disableMap.current.radarCheckbox ||
            disableMap.current.alertCheckbox
          }
        />
        {/* <!-- The ALERT topic currently cannot be subscibed to in the app  -->
        <StyledCheckbox
          text={strings("postsScreen.fields.topics.alert.label")}
          name={strings("postsScreen.fields.topics.alert.label")}
          checked={alertCheckboxChecked}
          onChange={handleAlertCheckboxClick}
          disabled={
            disableMap.current.dropdownList ||
            disableMap.current.generalCheckbox ||
            disableMap.current.radarCheckbox
          }
        />
        */}
        <StyledCheckbox
          text={strings("postsScreen.fields.topics.radar.label")}
          name={strings("postsScreen.fields.topics.radar.label")}
          checked={radarCheckboxChecked}
          onChange={handleRadarCheckboxClick}
          disabled={
            disableMap.current.dropdownList ||
            disableMap.current.generalCheckbox ||
            disableMap.current.alertCheckbox
          }
        />
      </InlineInputGroup>
      <InlineInputGroup width="">
        <DropdownContainer>
          <BoldText style={{ marginRight: "10px" }}>
            {strings("conjunctions.or")}:
          </BoldText>
          <TopicsDropdown
            onChange={onChange}
            allTopics={allTopics}
            getTopicById={getTopicById}
            disableMap={disableMap.current}
            interestTopicsFromResult={interestTopicsFromResult}
          />
          <ResetButton
            text={strings("postsScreen.fields.topics.reset")}
            onClick={handleReset}
          />
        </DropdownContainer>
      </InlineInputGroup>
      {getErrors("topics") && (
        <ErrorContainer>
          <FieldErrors errors={getErrors("topics")} />
        </ErrorContainer>
      )}
      <Separator />
    </Container>
  );
};

export default TopicsSection;
