import React from "react";
import DateTimeRange from "api/models/DateTimeRange";
import moment from "moment";
import { getFullDateFormat } from "constants/DateConstants";
import useLocale from "common/hooks/useLocale";

const sameDayFormat = "HH:mm";

const isSameDay = (dateFrom: string, dateTill: string) =>
  moment(dateFrom).isSame(dateTill, "day");

const isSameYear = (dateFrom: string, dateTill: string) =>
  moment(dateFrom).isSame(dateTill, "year");

const createTodayString = (date: string) =>
  `Today, ${moment(date).format(sameDayFormat)}`;

export const formatShortDateRange = (
  { dateTimeFrom, dateTimeTill }: DateTimeRange,
  locale: string,
): string => {
  const now = moment().toISOString();
  const sameYearFormat =
    locale === "en" ? "MMM D, YYYY HH:mm" : "D MMM YYYY HH:mm";

  const isToday = (date: string) => isSameDay(date, now);

  const isCurrentYear = (date: string) => isSameYear(date, now);

  const formatSingleDate = (date: string) =>
    isToday(date)
      ? createTodayString(date)
      : isCurrentYear(date)
      ? moment(date).format(sameYearFormat)
      : moment(date).format(getFullDateFormat(locale));

  if (dateTimeFrom && dateTimeTill) {
    let fromFormat = getFullDateFormat(locale);
    let toFormat = getFullDateFormat(locale);

    if (isSameYear(dateTimeFrom, dateTimeTill)) {
      toFormat = sameYearFormat;
    }

    if (isSameDay(dateTimeFrom, dateTimeTill)) {
      toFormat = sameDayFormat;
    }

    if (isCurrentYear(dateTimeFrom) && isCurrentYear(dateTimeTill)) {
      fromFormat = sameYearFormat;
    }

    return [
      isToday(dateTimeFrom)
        ? createTodayString(dateTimeFrom)
        : moment(dateTimeFrom).format(fromFormat),
      moment(dateTimeTill).format(toFormat),
    ].join(" - ");
  }

  if (dateTimeFrom) {
    return formatSingleDate(dateTimeFrom);
  }

  if (dateTimeTill) {
    return formatSingleDate(dateTimeTill);
  }

  return "";
};

interface ShortEventDateRange {
  dateTimeRange: DateTimeRange;
}

const ShortEventDateRange = ({
  dateTimeRange,
  ...props
}: ShortEventDateRange) => {
  const locale = useLocale();

  return <span {...props}>{formatShortDateRange(dateTimeRange, locale)}</span>;
};

export default ShortEventDateRange;
