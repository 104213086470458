import React, { ReactNode } from "react";
import { DateTimeRange } from "components/forms/FormFields/DateTime";
import { DateTimeRangeInterface } from "components/forms/FormFields/DateTime/helpers";
import { FormErrorsInterface } from "hooks/useFormFields";
import strings from "localisation/strings";
import styled, { isMobile } from "style/styled-components";
import { EndDateType } from "api/models/EventInterface";
import RadioButton from "uiComponents/radioButton/RadioButton";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import useWindowDimensions from "hooks/useWindowDimensions";
import globalTheme from "style/theme";
import breakpoints from "style/breakpoints";
import Separator from "components/forms/FormFields/Separator";

// For IE
const Container = styled.div`
  width: 100%;
`;
export interface DateTimeRangePickerProps {
  value: DateTimeRangeInterface;
  setValue: (date: DateTimeRangeInterface, position?: "start" | "end") => void;
  errors?: FormErrorsInterface;
  canSelectInPast?: boolean;
  endDateTypePickerProps?: EndDateTypeFieldProps;
}
const DateTimeRangePicker = ({
  value,
  setValue,
  errors,
  canSelectInPast,
  endDateTypePickerProps,
}: DateTimeRangePickerProps) => (
  <Container>
    <DateTimeRange
      value={value}
      setValue={setValue}
      errors={errors}
      groupWidth="100%"
      timeWidth="100%"
      dateWidth="100%"
      startLabel={strings("detailedScreen.dateTime.start")}
      endLabel={strings("detailedScreen.dateTime.end")}
      canSelectInPast={canSelectInPast}
      endDateTypePickerProps={endDateTypePickerProps}
      showClearButton
    />
  </Container>
);

export default DateTimeRangePicker;

export interface EndDateTypeFieldProps {
  value?: EndDateType;
  setValue: (newValue?: EndDateType) => void;
}
const { leftSide } = globalTheme.forms;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-left: ${leftSide};
  @media ${breakpoints.phoneMax} {
    margin-left: 0;
  }
`;

export const Label = styled.span<{
  isBold?: boolean;
}>`
  ${({ isBold }) => (isBold ? "font-weight: bold" : null)};
  margin: 0 10px;
`;

const OptionalLabel = ({
  children,
  isBold,
}: {
  children?: ReactNode;
  isBold?: boolean;
}) => (
  <LabelContainer>
    <Separator />
    <Label isBold={isBold}> {children} </Label>
    <Separator />
  </LabelContainer>
);

export const EndDateTypePicker = ({
  value,
  setValue,
}: EndDateTypeFieldProps) => {
  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);
  return (
    <>
      <OptionalLabel isBold={true}>
        {strings(`detailedScreen.dateTime.or`).toLowerCase()}
        {": "}
      </OptionalLabel>
      <InlineInputGroup placeholder={isMobileDevice ? undefined : " "}>
        <RadioButtonContainer>
          <RadioButton
            onChange={() => setValue(EndDateType.fullDay)}
            checked={value === EndDateType.fullDay}
            label={strings(
              `detailedScreen.dateTime.${EndDateType.fullDay.toLowerCase()}`,
            )}
            value={strings(
              `detailedScreen.dateTime.${EndDateType.fullDay.toLowerCase()}`,
            )}
          />
          <RadioButton
            onChange={() => setValue(EndDateType.generated)}
            checked={value === EndDateType.generated}
            label={strings(
              `detailedScreen.dateTime.${EndDateType.generated.toLowerCase()}`,
            )}
            value={strings(
              `detailedScreen.dateTime.${EndDateType.generated.toLowerCase()}`,
            )}
          />
          <RadioButton
            onChange={() => setValue(EndDateType.appointment)}
            checked={value === EndDateType.appointment}
            label={strings(
              `detailedScreen.dateTime.${EndDateType.appointment.toLowerCase()}`,
            )}
            value={strings(
              `detailedScreen.dateTime.${EndDateType.appointment.toLowerCase()}`,
            )}
          />
        </RadioButtonContainer>
      </InlineInputGroup>
    </>
  );
};

const RadioButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  flex: 1;
`;
