import "babel-polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";
import "resize-observer-polyfill/dist/ResizeObserver.global";

import React from "react";
import ReactDOM from "react-dom";
import "style/index.css";
import objectFitImages from "object-fit-images";
import App from "core/App";

ReactDOM.render(<App />, document.getElementById("root"));

objectFitImages(null);
