import { getContentTypeName } from "utils";
import ContentType from "common/api/models/ContentType";
import AuthedAPI from "api/AuthedAPI";
import RequestOptions from "api/RequestOptions";
import {
  DashboardRequestInterface,
  DashboardInterface,
  ContentItemRequest,
} from "api/models/DashboardInterface";

const searchAPI = new AuthedAPI({});

const isBOAdminOrCurator = (previlegeRoute: string): boolean =>
  previlegeRoute === "bo" || previlegeRoute === "bp/curator";

export interface ContentItemOptionsRequestParams {
  contentType: ContentType;
  dateTimeFrom: string | undefined;
  dateTimeTill: string | undefined;
}

export const selectDashboard = (
  previlegeRoute: string,
  businessId: string,
  body: DashboardRequestInterface,
  options?: RequestOptions,
) => {
  const path = isBOAdminOrCurator(previlegeRoute)
    ? `search/${previlegeRoute}/dashboard/${businessId}`
    : `search/${previlegeRoute}/dashboard`;

  return searchAPI.postJson<DashboardInterface>({
    body,
    options,
    path,
  });
};

export const selectContentItemOptions = (
  previlegeRoute: string,
  businessId: string,
  params: ContentItemOptionsRequestParams,
) => {
  const { contentType, dateTimeFrom, dateTimeTill } = params;
  const contentTypeName = getContentTypeName(contentType);

  let requestParams = "";
  if (
    contentType === "EVENT" &&
    dateTimeFrom !== undefined &&
    dateTimeTill !== undefined
  ) {
    requestParams = `?dateTimeFrom=${dateTimeFrom}&dateTimeTill=${dateTimeTill}`;
  }

  const path = isBOAdminOrCurator(previlegeRoute)
    ? `${contentTypeName}/${previlegeRoute}/${contentTypeName}/select-items/${businessId}${requestParams}`
    : `${contentTypeName}/${previlegeRoute}/${contentTypeName}/select-items${requestParams}`;

  return searchAPI.getJson<ContentItemRequest>({ path });
};
