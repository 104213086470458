import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import ActionButton from "components/buttons/ActionButton";
import FormSubtitle from "components/forms/FormSubtitle";

const CenteredActionButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.margin.medium};
  align-self: center;
`;

const PasswordRecoverySuccess = ({
  navigateToLogin,
}: {
  navigateToLogin: () => void;
}) => (
  <>
    <FormSubtitle>
      {strings("passwordRecoveryScreen.successMessage")}
    </FormSubtitle>

    <CenteredActionButton
      onClick={navigateToLogin}
      text={strings("passwordRecoveryScreen.successButton")}
    />
  </>
);

export default PasswordRecoverySuccess;
