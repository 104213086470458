import React from "react";
import styled, { keyframes } from "style/styled-components";
import FullHeightOverlay from "components/generic/FullHeightOverlay";

const infiniteSpin = keyframes`
  0% {transform: rotate(0deg)};
  100% {transform: rotate(360deg)}; 
`;

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AbsoluteOverlaySpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 1px solid ${({ theme }) => theme.color.foreground.primary};
  border-top: none;
  animation: ${infiniteSpin} 1s linear infinite;
`;

export interface OverlaySpinnerProps {
  className?: string;
}

const OverlaySpinner = ({ className }: OverlaySpinnerProps) => (
  <FullHeightOverlay className={className}>
    <FixedContainer>
      <Spinner />
    </FixedContainer>
  </FullHeightOverlay>
);

const AbsoluteOverlaySpinner = ({ className }: OverlaySpinnerProps) => (
  <FullHeightOverlay className={className}>
    <Spinner />
  </FullHeightOverlay>
);

export default OverlaySpinner;
export {
  Spinner,
  SpinnerContainer,
  AbsoluteOverlaySpinner,
  AbsoluteOverlaySpinnerContainer,
};
