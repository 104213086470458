import React, { useEffect } from "react";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import strings from "localisation/strings";
import styled from "style/styled-components";
import { savePaymentMethod } from "api/businessProfiles";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import { RouteComponentProps } from "react-router";
import { routeNames } from "core/navigation";
import MandateConfirmationForm, {
  MandateConfirmationFormValues,
} from "views/BusinessSettings/Payment/forms/MandateConfirmationForm";
import useNotificationState from "hooks/useNotification";
import { BodyText } from "components/generic";
import BusinessSettingsSidebar from "views/BusinessSettings/BusinessSettingsSidebar";
import { MandateConfirmationDTO } from "api/models/Payments";
import { noop } from "utils";

const SepaCardFormContainer = styled.div`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

const MandateRichText = styled(RichTextRendering)`
  p:not(:last-child) {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  dt {
    float: left;
    clear: left;
    text-align: right;
    font-weight: bold;
  }

  dt::after {
    content: " ";
    white-space: pre;
  }
`;

interface BusinessSettingsConfirmPaymentParams {
  mandateText?: string; // may also be null
  confirmingDefaultMethod?: boolean; // boolean actually, but RouteComponentProps only supports string | undefined
  onNoMandate?: () => void;
  onSuccess?: () => void;
  useSidebar?: boolean;
  isOnboarding?: boolean;
}

export type BusinessSettingsConfirmPaymentProps = RouteComponentProps<
  object,
  object,
  BusinessSettingsConfirmPaymentParams
>;

const BusinessSettingsConfirmPayment = ({
  history,
  location: {
    state: {
      mandateText = "",
      confirmingDefaultMethod = null,
      onNoMandate = () => history.push(routeNames.BusinessSettings.AddPayment),
      onSuccess = () => history.push(routeNames.BusinessSettings.Payment),
      useSidebar = true,
      isOnboarding = false,
    } = {},
  },
}: BusinessSettingsConfirmPaymentProps) => {
  const { addSuccessNotification } = useNotificationState();
  const { makeRequest, error: saveError, status: saveStatus } = useMutation(
    savePaymentMethod,
  );

  const handleFormSubmitted = (formData: MandateConfirmationFormValues) => {
    const payload: MandateConfirmationDTO = {
      confirmation: formData.confirmation,
      defaultPaymentMethod: confirmingDefaultMethod as boolean,
      onboarding: isOnboarding,
    };
    makeRequest(payload);
  };

  const handleMandateConfirmationExited = () => {
    history.goBack();
  };

  useEffect(() => {
    // Navigated directly to this page
    // Note: may also be null, it's ok in that case
    const routeStateHasNoMandate = mandateText === undefined;
    if (routeStateHasNoMandate) {
      onNoMandate();
    }
  }, [mandateText]);

  useEffect(() => {
    if (saveStatus === QueryStatus.SUCCESSFUL) {
      if (!isOnboarding) {
        addSuccessNotification(
          strings("businessSettings.paymentMandate.successMessage"),
        ).then(noop);
      }
      onSuccess();
    }
  }, [saveStatus]);

  return (
    <BusinessSettingsLayout
      title={strings("businessSettings.paymentMandate.title")}
      sidebar={useSidebar ? <BusinessSettingsSidebar /> : undefined}
    >
      {mandateText ? (
        <MandateRichText cozy value={mandateText} />
      ) : (
        <BodyText>
          {strings("businessSettings.paymentMandate.noMandateText")}
        </BodyText>
      )}
      <SepaCardFormContainer>
        <MandateConfirmationForm
          errors={saveError}
          onSubmit={handleFormSubmitted}
          onFormLeft={handleMandateConfirmationExited}
          isSubmittable={saveStatus !== QueryStatus.WAITING}
        />
      </SepaCardFormContainer>
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsConfirmPayment;
