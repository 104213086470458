import React, { useMemo, useCallback } from "react";

import styled from "style/styled-components";
import Input from "components/forms/FormFields/InlineInput";
import strings from "localisation/strings";
import countryCodes from "components/helpers/countryCodes.json";
import FieldErrors from "components/forms/FieldErrors";
import SelectComponent, {
  SelectItem,
  SelectPropsInterface,
  MaterialDropdownIndicator,
} from "components/forms/Select";
import { SingleInputFieldProps } from "components/forms/FormFields/SimpleIconedInput";

interface CellProps {
  mobile?: number;
  desktop?: number;
  paddingRight?: boolean;
  mobileMarginBottom?: boolean;
}

const PhoneInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const StyledSelect = styled(SelectComponent)`
  .countryCodeSelect__control {
    overflow: hidden;
  }
  .countryCodeSelect__indicators {
    padding: 0;
  }
  .countryCodeSelect__menu {
    top: 28px;
    max-width: 288px;
    width: auto;
    border-top: 1px solid ${({ theme }) => theme.color.foreground.tertiary};
  }
  .countryCodeSelect__option {
    white-space: nowrap;
  }
  @media (max-width: ${({ theme }) => theme.forms.max}) {
    .countryCodeSelect__menu {
      right: 0;
    }
  }
`;

const Cell = styled.div<CellProps>`
  box-sizing: border-box;
  flex-basis: ${({ desktop }) => (desktop ? `${desktop}%` : "100%")};
  max-width: ${({ desktop }) =>
    desktop ? `${desktop}%` : "100%"}; // needed for IE
  padding-right: ${({ paddingRight }) => (paddingRight ? "12px" : "0")};
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.forms.max}) {
    flex: 0 ${({ mobile }) => (mobile ? `${mobile}%` : "100%")};
    max-width: 100%;
    padding-right: 0;
    margin-bottom: ${({ mobileMarginBottom }) =>
      mobileMarginBottom ? "20px" : "0"};
  }
`;

const withPlus = ({ country, code }: typeof countryCodes[0]) => ({
  label: `${country} +${code}`,
  value: `+${code}`,
});

const countryCodeOptions = countryCodes.map(withPlus);

type SelectPhoneCodeFieldProps = (
  field: "phoneCode",
  options: SelectItem[],
) => SelectPropsInterface;

interface Props {
  phone: SingleInputFieldProps;
  phoneCodeErrors: string[];
  phoneErrors: string[];
  getSelectProps: SelectPhoneCodeFieldProps;
}

/**
 * Phone code value is displayed in short form when selected
 * Inside the dropdown the format must be unchanged
 */
const formatSelectedCountryCodeValue = (
  selectedItem: SelectItem | null | undefined,
) =>
  selectedItem
    ? {
        value: selectedItem.value,
        label: selectedItem.value as string,
      }
    : undefined;

const InputPhoneNumber = ({
  phone,
  phoneCodeErrors,
  phoneErrors,
  getSelectProps,
}: Props) => {
  const derivedCodeSelectProps: SelectPropsInterface = getSelectProps(
    "phoneCode",
    countryCodeOptions,
  );
  const selectedPhoneCodeValue = useMemo(
    () => formatSelectedCountryCodeValue(derivedCodeSelectProps.value),
    [derivedCodeSelectProps.value],
  );

  const onChangePhoneNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const re = /^[0-9\b]+$/;
      if ((newValue === "" || re.test(newValue)) && phone.onChange) {
        phone.onChange(e);
      }
    },
    [phone.onChange],
  );

  return (
    <PhoneInput>
      <Cell desktop={36} mobile={100} paddingRight mobileMarginBottom>
        <StyledSelect
          {...derivedCodeSelectProps}
          value={selectedPhoneCodeValue}
          options={countryCodeOptions}
          placeholder={strings("profileScreen.contactData.phoneCode")}
          dropDownIndicator={MaterialDropdownIndicator}
          classNamePrefix="countryCodeSelect"
          small
        />
        <FieldErrors errors={phoneCodeErrors} />
      </Cell>
      <Cell desktop={64} mobile={100}>
        <Input
          value={phone.value}
          onChange={onChangePhoneNumber}
          type="text"
          maxLength={25}
        />
        <FieldErrors errors={phoneErrors} />
      </Cell>
    </PhoneInput>
  );
};

export default InputPhoneNumber;
