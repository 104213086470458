import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { dispatchBrowserEvent } from "utils";

export interface ContentScrollContextProps {
  shouldPreventScroll: boolean;
  enableScrollPrevention: () => void;
  disableScrollPrevention: () => void;
}

export const ContentScrollContext = React.createContext<
  ContentScrollContextProps
>({
  shouldPreventScroll: false,
  enableScrollPrevention: () => {},
  disableScrollPrevention: () => {},
});

const scrollConstraintsStyleConfig = {
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  height: "100rvh",
} as React.CSSProperties;

const unsetScrollConstraintsStyleConfig = {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  minHeight: "100vh",
  height: "unset",
} as React.CSSProperties;

function triggerDiv100vhHeightRecalculation() {
  dispatchBrowserEvent("resize");
}

const ContentScrollPreventer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [shouldPreventScroll, setShouldPreventScroll] = useState(false);

  useEffect(triggerDiv100vhHeightRecalculation, [shouldPreventScroll]);

  return (
    <ContentScrollContext.Provider
      value={{
        shouldPreventScroll,
        enableScrollPrevention: () => setShouldPreventScroll(true),
        disableScrollPrevention: () => setShouldPreventScroll(false),
      }}
    >
      <Div100vh
        id="div100vh"
        style={
          shouldPreventScroll
            ? scrollConstraintsStyleConfig
            : unsetScrollConstraintsStyleConfig
        }
      >
        {children}
      </Div100vh>
    </ContentScrollContext.Provider>
  );
};

export default ContentScrollPreventer;
