import React, { useEffect, useState } from "react";

import useQuery from "common/hooks/useQuery";
import usePagination from "common/hooks/usePagination";
import QueryStatus from "common/api/models/QueryStatus";
import { AbortableRequest } from "common/api/AbortableRequest";
import { AbortablePayloadRequest } from "common/api/AbortablePayloadRequest";
import ReviewList from "components/review/ReviewList";
import StatsRatingStars from "components/review/StatsRatingStars";
import RatingStatsBlock, {
  calculatePercentageOfMaxRating,
} from "components/review/RatingStatsBlock";
import { ListLimitedParams } from "api/models/ListParams";
import ReviewInterface, {
  RatingStat,
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
  ReviewResponse,
} from "api/models/ReviewInterface";
import { getTotalItemsCount, getTotalPagesFromHeaders } from "utils";
import styled from "style/styled-components";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import Pagination from "components/pagination/Pagination";
import SpreadRow from "components/generic/SpreadRow";
import { Title } from "components/generic";
import strings from "localisation/strings";
import { putBoPersonalDataList } from "api/users";
import useMutation from "common/hooks/useMutation";
import { PersonalData } from "api/models/UsersInterface";

const OverlayContainer = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.content.sizes.medium};
  margin: 0 auto;
  width: 100%;
`;

const CenteredTitle = styled(Title)`
  text-align: center;
`;

export interface ReviewsAndRatingsProps {
  id: string;
  getRequest: (
    listParams: ListLimitedParams,
  ) => AbortableRequest<ReviewResponse>;
  saveReviewReply: (
    reviewId: string,
  ) => AbortablePayloadRequest<
    ReviewReplyPayloadInterface,
    ReplyDetailsInterface
  >;
  deleteReviewReply: (
    reviewId: string,
    replyId: string,
  ) => AbortableRequest<void>;
  enableComments?: boolean;
  enableRatings?: boolean;
}

const ReviewsAndRatings = ({
  id,
  getRequest,
  saveReviewReply,
  deleteReviewReply,
  enableComments,
  enableRatings,
}: ReviewsAndRatingsProps) => {
  const { offset, limit, page, updatePage, onPageSizeChange } = usePagination();
  const [deletedReviewId, setDeletedReviewIdInitiatingRefresh] = useState("");

  const { result, headers, status } = useQuery({
    request: getRequest({ limit, offset }),
    compare: {
      page,
      limit,
      id,
      enableComments,
      enableRatings,
      deletedReviewId,
    },
  });
  // When ratings and comments are disabled don't display anything
  if (!enableComments && !enableRatings) return null;

  if (!result) {
    return null;
  }

  const { reviews, stats } = result;
  const totalNumberOfPages = getTotalPagesFromHeaders(headers, limit);
  const totalItems = getTotalItemsCount(headers);
  const totalRating = calculatePercentageOfMaxRating(stats);

  const onDeleteReply = (review: ReviewInterface) => {
    if (!review.deleted) {
      // we need to reload the page only if review was deleted
      return;
    }
    if (page === totalNumberOfPages && reviews?.length === 1) {
      // This is last review on last page and it's deleted. We must go back one page
      updatePage(page - 1);
    } else {
      setDeletedReviewIdInitiatingRefresh(review.id);
    }
  };

  return (
    <OverlayContainer>
      {enableRatings && (
        <>
          <SpreadRow>
            <CenteredTitle>
              {strings("reviewList.ratingStats.title")}
            </CenteredTitle>
            <StatsRatingStars rating={totalRating} />
          </SpreadRow>
          <RatingStatsBlock ratingStats={stats} />
        </>
      )}
      {enableComments && (
        <>
          <ReviewList
            reviews={reviews}
            saveReviewReply={saveReviewReply}
            deleteReviewReply={deleteReviewReply}
            onDeleteReply={onDeleteReply}
          />
          <Pagination
            onPageChange={updatePage}
            onPageSizeChange={onPageSizeChange}
            page={page}
            limit={limit}
            totalNumberOfPages={totalNumberOfPages}
            totalItems={totalItems}
            useStandardMargin
          />
        </>
      )}

      {status === QueryStatus.WAITING && <AbsoluteOverlaySpinner />}
    </OverlayContainer>
  );
};

export default ReviewsAndRatings;
