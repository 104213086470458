import React from "react";
import { HintTooltip } from "components/generic";
import OfferTypes from "api/models/OfferTypes";

const offerTooltipTextMap = {
  [OfferTypes.coupon]: {
    id: "coupon-tooltip",
    hint: "detailedScreen.couponHint",
    text: "detailedScreen.coupons",
  },
  [OfferTypes.promotion]: {
    id: "promotion-tooltip",
    hint: "detailedScreen.promotionHint",
    text: "detailedScreen.promotions",
  },
};

const OfferHint = ({ offerType }: { offerType: OfferTypes }) => (
  <HintTooltip tooltipData={offerTooltipTextMap[offerType]} />
);

export default OfferHint;
