import React, { useEffect, useRef } from "react";
import strings from "localisation/strings";
import useFormFields from "hooks/useFormFields";
import FieldMargin from "components/forms/FieldMargin";
import { FieldAligner } from "components/forms/FieldContainer";
import Checkbox from "components/forms/Checkbox";
import FieldErrors from "components/forms/FieldErrors";
import ActionButton from "components/buttons/ActionButton";
import styled from "styled-components";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import PasswordStrength from "components/forms/RegistrationForm/PasswordStrength";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";
import ActionArea from "components/generic/ActionArea";
import { PasswordToggleField } from "components/forms/LoginForm/LoginForm";

interface PasswordCreationFormFields {
  password: string;
  passwordConfirmation: string;
  permissionToAnalyzeData: boolean;
  acceptedTermsAndConditions: boolean;
}

interface Props {
  isSubmittable: boolean;
  formErrors: APIErrorResponse | undefined;
  onSubmit: (values: PasswordCreationFormFields) => void;
  isAdminForm?: boolean;
}

const StretchedFormFields = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.forms.small};
  ${inputContainerAutoZoomPrevetionStyles};
`;

const CenteredActionButton = styled(ActionButton)`
  align-self: center;
`;

const PasswordCreationForm = ({
  isSubmittable,
  formErrors,
  onSubmit,
  isAdminForm = false,
}: Props) => {
  const {
    getTextFieldProps,
    getCheckboxFieldProps,
    errors,
    values,
    parseErrors,
  } = useFormFields({
    handleGenericErrors: true,
    translationScope: "registrationScreen.fields",
    textFields: {
      password: "",
      passwordConfirmation: "",
    },
    checkboxFields: {
      acceptedTermsAndConditions: isAdminForm,
      acceptedPrivacyPolicy: isAdminForm,
      permissionToAnalyzeData: isAdminForm,
    },
  });

  const parseErrorsRef = useRef(parseErrors);

  useEffect(() => {
    return formErrors && parseErrorsRef.current(formErrors);
  }, [formErrors]);

  return (
    <StretchedFormFields>
      <PasswordToggleField {...getTextFieldProps("password")}>
        <FieldAligner>
          <PasswordStrength password={values.password} />
        </FieldAligner>
      </PasswordToggleField>
      <PasswordToggleField {...getTextFieldProps("passwordConfirmation")} />
      {!isAdminForm && (
        <>
          <FieldMargin>
            <FieldAligner>
              <Checkbox
                {...getCheckboxFieldProps("acceptedTermsAndConditions")}
              />
              <FieldErrors errors={errors.acceptedTermsAndConditions || []} />
            </FieldAligner>
          </FieldMargin>
          <FieldMargin>
            <FieldAligner>
              <Checkbox {...getCheckboxFieldProps("acceptedPrivacyPolicy")} />
              <FieldErrors errors={errors.acceptedPrivacyPolicy || []} />
            </FieldAligner>
          </FieldMargin>
          <FieldAligner>
            <Checkbox {...getCheckboxFieldProps("permissionToAnalyzeData")} />
          </FieldAligner>
        </>
      )}
      <ActionArea position="center">
        <CenteredActionButton
          alone
          text={strings("addRepresentativeForm.fields.submit")}
          loadingText={strings("buttons.states.requestInProgress")}
          disabled={!isSubmittable}
          onClick={() => onSubmit(values)}
        />
      </ActionArea>
    </StretchedFormFields>
  );
};

export default PasswordCreationForm;
