import styled from "style/styled-components";
import searchIcon from "assets/icons/search.svg";

// Checkbox components
export const CheckboxListContainer = styled.div`
  position: relative;
`;

export const CheckboxLabel = styled.span`
  margin-right: 8px;
`;

// Date Range
export const DateRangeSeparator = styled.span`
  padding: 3px 8px;
  vertical-align: top;
  display: inline-block;
  line-height: 30px;

  &:after {
    content: "-";
  }
`;

// Search Field
export const SearchInput = styled.input<{ maxWidth?: number }>`
  width: ${({ value }) => (value && value.toString().length ? "auto" : "50px")};
  font-size: ${({ theme }) => theme.font.size.table};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "unset")};
  height: 32px;
  border: 1px solid transparent;

  + span {
    display: ${({ value }) =>
      value && value.toString().length ? "none" : "inline-block"};
  }

  &:focus {
    width: auto;
    border-color: ${({ theme }) => theme.color.background.tertiary};

    + span {
      display: none;
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.background.tertiary};
  }
`;

export const SearchIcon = styled.span`
  position: relative;
  top: 1px;
  height: 26px;
  width: 26px;
  background: url(${searchIcon}) no-repeat center;
  padding: 5px 0;
  display: inline-block;
  vertical-align: top;
`;
