import React, { useEffect } from "react";

import ListTableView from "views/ListTableView";
import { ExtendedRepresentativeInterface } from "api/models/RepresentativeInterface";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import useBusinessRepresentativeRoles from "hooks/useBusinessRepresentativeRoles";
import useNotificationState from "hooks/useNotification";

const BusinessRepresentatives = () => {
  const { roles, error } = useBusinessRepresentativeRoles();
  const { addErrorNotification } = useNotificationState();

  useEffect(() => {
    if (error) {
      addErrorNotification(error.detail);
    }
  }, [error]);

  if (!roles || error) return null;

  return (
    <PrivilegedComponent
      id={PrivilegedComponentsIds.VIEW_REPRESENTATIVES_ADMIN_TABLE}
      isView
    >
      <ListTableView<ExtendedRepresentativeInterface>
        representativeRoles={roles}
      />
    </PrivilegedComponent>
  );
};

export default BusinessRepresentatives;
