import styled from "style/styled-components";

import breakpoints, { screenSizes } from "style/breakpoints";

const Media = styled.div<{
  desktop?: boolean;
  tablet?: boolean;
  phone?: boolean;
}>`
  display: none;

  /* 
    IE10+ CSS to fix an IE bug that causes flex box overflow.
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 0 auto;
  }
  ${({ desktop }) =>
    desktop
      ? `
    @media ${breakpoints.desktopMin} {
      display: block;
    }
  `
      : ""};
  ${({ tablet }) =>
    tablet
      ? `
    @media (min-width: ${screenSizes.phoneWidthMax +
      0.02}px) and (max-width: ${screenSizes.desktopWidthMin - 0.02}px) {
      display: block;
    }
  `
      : ""};
  ${({ phone }) =>
    phone
      ? `
    @media ${breakpoints.phoneMax} {
      display: block;
    }
  `
      : ""};
`;

export default Media;
