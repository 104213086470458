import React, { useEffect, useState } from "react";
import { curry } from "ramda";
import { RouteComponentProps } from "core/types";
import styled from "style/styled-components";
import { WizardStepFourHeader } from "components/headers/WizardHeader";
import { BodyText, CenteredContentViewContainer } from "components/generic";
import strings from "localisation/strings";
import useNotificationState from "hooks/useNotification";
import { getBusinessPlan, updateBusinessPlan } from "api/businessProfiles";
import useMutation from "common/hooks/useMutation";
import Plan, { PlanTypes } from "api/models/Plan";
import PlanContract from "views/Wizard/PlanContract";
import { Subsection, WizardTitle } from "views/Wizard/sharedComponents";
import QueryStatus from "common/api/models/QueryStatus";
import ActionButton from "components/buttons/ActionButton";
import Checkbox from "components/forms/Checkbox";
import ActionArea, { withBackButton } from "components/generic/ActionArea";
import useFormFields from "hooks/useFormFields";
import { noop } from "utils";
import { routeNames } from "core/navigation";

const WizzardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.content.sizes.medium};
`;

interface WizardStepFourActionAreaProps {
  isSubmitDisabled: boolean;
  submitDisabledButtonText: string;
  onWizardStepSubmitted: () => void;
}

const WizardStepFourActionArea = withBackButton(
  (props: WizardStepFourActionAreaProps) => (
    <ActionArea>
      <ActionButton
        alone
        text={strings("wizardScreen.contract.button")}
        special="publish"
        onClick={props.onWizardStepSubmitted}
        disabled={props.isSubmitDisabled}
        loadingText={props.submitDisabledButtonText}
      />
    </ActionArea>
  ),
);

interface WizardStepFourPageParams {
  selectedPlan?: Plan;
}

const WizardStepFour = ({
  location: { state: { selectedPlan } = { selectedPlan: undefined } },
  history,
}: RouteComponentProps<WizardStepFourPageParams>) => {
  const { addErrorNotification } = useNotificationState();
  const [plan, setPlan] = useState(selectedPlan);

  const {
    makeRequest: makeBusinessPlanUpdateRequest,
    status,
    error,
  } = useMutation(curry(updateBusinessPlan));

  const {
    makeRequest: makeGetBusinessPlanRequest,
    status: getPlanStatus,
    result: getPlanResult,
  } = useMutation(curry(getBusinessPlan));

  const { getCheckboxFieldProps, values } = useFormFields({
    setBlockNavigation: noop,
    translationScope: "wizardScreen.fields",
    checkboxFields: {
      acceptedTermsAndConditions: false,
      dataProtection: false,
    },
  });
  const isUpdatingBusinessPlan = status === QueryStatus.WAITING;
  const isTermsAccepted =
    values.acceptedTermsAndConditions && values.dataProtection;
  const isFreePlanSelected = !!plan && plan.planType === PlanTypes.nonprofit;

  const handleContractAgreed = async () => {
    if (plan) {
      return makeBusinessPlanUpdateRequest({
        planId: plan.planId,
        onboarding: true,
      });
    }
  };

  const handleBackClicked = () => {
    history.push(routeNames.WizardStep.Third);
  };

  useEffect(() => {
    if (!plan) {
      makeGetBusinessPlanRequest({}).then(noop);
      history.replace(routeNames.WizardStep.Fourth);
    }
  }, [plan]);

  useEffect(() => {
    if (
      getPlanStatus === QueryStatus.SUCCESSFUL &&
      getPlanResult &&
      getPlanResult.currentPlan
    ) {
      setPlan(getPlanResult.currentPlan);
    }
  }, [getPlanStatus]);

  useEffect(() => {
    if (status === QueryStatus.SUCCESSFUL) {
      history.push({
        pathname: isFreePlanSelected
          ? routeNames.WizardStep.Completed
          : routeNames.WizardStep.AddPayment,
        state: { plan },
      });
    }

    if (status === QueryStatus.ERROR) {
      addErrorNotification(error && error.detail);
    }
  }, [status]);

  return (
    <>
      <WizardStepFourHeader />
      <CenteredContentViewContainer>
        <WizzardContainer>
          <WizardTitle>
            {strings("wizardScreen.contract.title")}:{" "}
            {plan ? plan.name : "Bliggit"}
          </WizardTitle>

          {plan && <PlanContract plan={plan} />}

          <Subsection>
            <BodyText>{strings("wizardScreen.contract.termsNotice")}</BodyText>
            <Subsection indented>
              <Checkbox
                {...getCheckboxFieldProps("acceptedTermsAndConditions")}
              />
            </Subsection>
            <Subsection indented>
              <Checkbox {...getCheckboxFieldProps("dataProtection")} />
            </Subsection>
          </Subsection>

          <WizardStepFourActionArea
            isActionAreaOneElement={true}
            isBackButtonEnabled={true}
            onBackButtonClicked={handleBackClicked}
            isSubmitDisabled={!isTermsAccepted || isUpdatingBusinessPlan}
            onWizardStepSubmitted={handleContractAgreed}
            submitDisabledButtonText={
              isUpdatingBusinessPlan
                ? strings("buttons.states.savingInProgress")
                : strings("wizardScreen.contract.termsNotAccepted")
            }
          />
        </WizzardContainer>
      </CenteredContentViewContainer>
    </>
  );
};

export default WizardStepFour;
