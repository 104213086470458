import styled from "styled-components";
import breakpoints from "style/breakpoints";

const ResponsiveHalfRowColumn = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.margin.standart};
  @media ${breakpoints.desktopMin} {
    max-width: 50%;
  }
`;

export default ResponsiveHalfRowColumn;
