import React from "react";
import useLoginState from "hooks/useLoginState";

interface Props {
  id: string;
  children?: React.ReactNode | React.ReactNode[];
  isView?: boolean;
}

const PrivilegedComponent = ({ id, children, isView }: Props) => {
  const privilegedComponentSet = useLoginState().getPrivilegedComponentSet();

  if (privilegedComponentSet && privilegedComponentSet.has(id)) {
    return <>{children}</>;
  }
  if (isView) {
    // TODO: return a nice message component once we have designs
    return <div>You don't have enough privileges to see this page</div>;
  }
  return null;
};

export default PrivilegedComponent;
