import AuthedAPI from "api/AuthedAPI";
import BusinessUserProfileInterface, {
  ImprintInformation,
} from "api/models/BusinessUserProfileInterface";
import RequestOptions from "api/RequestOptions";
import { FilterAPIResponse } from "api/models/FilterInterface";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import ListParams from "api/models/ListParams";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
  ReviewResponse,
} from "api/models/ReviewInterface";
import Plan, {
  AdditionalSettingsPayload,
  BusinessPlanHistory,
  BusinessPlanPayload,
  BusinessPlanUpdatePayload,
  ExtraContentLimitUpdatePayload,
} from "api/models/Plan";
import {
  CreateCreditCardDTO,
  CreateMandateDTO,
  CreateMandateResponseDTO,
  MandateConfirmationDTO,
  MandateConfirmationResponseDTO,
  PaymentAddressDTO,
  PaymentDataResponseDTO,
} from "api/models/Payments";

const businessUserProfileAPI = new AuthedAPI({});

export interface BusinessUserProfilesListResultType {
  data: BusinessUserProfileInterface[];
  filters?: FilterAPIResponse["filters"];
  sort?: FilterAPIResponse["sort"];
}

const getBusinessProfiles = (
  body: SimpleSearchInterface | undefined,
  businessId: string | undefined,
  isCurator: boolean,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postListJson<BusinessUserProfilesListResultType>({
    queryParams,
    options,
    body,
    path:
      businessId && isCurator
        ? `business-profiles/bp/curator/business-profiles/search/${businessId}`
        : businessId
        ? `business-profiles/bo/business-profiles/search/${businessId}`
        : "business-profiles/bp/business-profiles/search",
  });

const getBusinessProfilesAdmin = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postListJson<BusinessUserProfilesListResultType>({
    queryParams,
    options,
    body,
    path: "business-profiles/bo/business-profiles/search",
  });

const getBusinessProfilesCurator = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postListJson<BusinessUserProfilesListResultType>({
    queryParams,
    options,
    body,
    path: "business-profiles/bp/curator/business-profiles/search",
  });

const getBusinessProfile = (
  businessUserProfileId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<BusinessUserProfileInterface>({
    queryParams,
    options,
    path: `business-profiles/bp/business-profiles/${businessUserProfileId}`,
  });

const getBusinessProfileAdmin = (
  businessUserProfileId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<BusinessUserProfileInterface>({
    queryParams,
    options,
    path: `business-profiles/bo/business-profiles/${businessUserProfileId}`,
  });

const getBusinessProfileCurator = (
  businessUserProfileId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<BusinessUserProfileInterface>({
    queryParams,
    options,
    path: `business-profiles/bp/curator/business-profiles/${businessUserProfileId}`,
  });

// Add Draft version meant for general usage
const createDraftBusinessProfile = (
  body: BusinessUserProfileInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    options,
    // POST requests via admin business-mode require businessId in the body
    body: businessId ? { ...body, businessId } : body,
    path: businessId
      ? "business-profiles/bo/business-profiles/drafts"
      : "business-profiles/bp/business-profiles/drafts",
  });

// Add Draft version meant for special case of new business creation by IA
const createDraftBusinessProfileAdmin = (
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    options,
    body,
    path: "business-profiles/bo/business-profiles/drafts",
  });

const createDraftBusinessProfileCurator = (
  body: BusinessUserProfileInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "business-profiles/bp/curator/business-profiles/drafts",
  });

const updateDraftBusinessProfile = (
  businessUserProfileId: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bp/business-profiles/drafts/${businessUserProfileId}`,
  });

const updateDraftBusinessProfileAdmin = (
  businessUserProfileId: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bo/business-profiles/drafts/${businessUserProfileId}`,
  });

const updateDraftBusinessProfileCurator = (
  businessUserProfileId: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bp/curator/business-profiles/drafts/${businessUserProfileId}`,
  });

// Publish version meant for general usage
const createBusinessProfile = (
  body: BusinessUserProfileInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    options,
    // POST requests via admin business-mode require businessId in the body
    body: businessId ? { ...body, businessId } : body,
    path: businessId
      ? "business-profiles/bo/business-profiles"
      : "business-profiles/bp/business-profiles",
  });

// Publish version meant for special case of new business creation by IA
const createBusinessProfileAdmin = (
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    options,
    body,
    path: "business-profiles/bo/business-profiles",
  });

const createBusinessProfileCurator = (
  body: BusinessUserProfileInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "business-profiles/bp/curator/business-profiles",
  });

const updateBusinessProfile = (
  businessUserProfileId: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bp/business-profiles/${businessUserProfileId}`,
  });

const updateBusinessProfileAdmin = (
  businessUserProfileId: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bo/business-profiles/${businessUserProfileId}`,
  });

const updateBusinessProfileCurator = (
  businessUserProfileId: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bp/curator/business-profiles/${businessUserProfileId}`,
  });

const getBusinessProfileFilters = (
  body: Partial<FilterAPIResponse>,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<FilterAPIResponse>({
    options,
    body,
    path: businessId
      ? `business-profiles/bo/business-profiles/filters/${businessId}`
      : "business-profiles/bp/business-profiles/filters",
  });

const updateBusinessProfileStatusInList = (
  id: string,
  status: string,
  body: BusinessUserProfileInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<BusinessUserProfileInterface>({
    body,
    options,
    path: `business-profiles/bo/business-profiles/${id}/${status}`,
  });

const getAllBusinessProfilesList = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postListJson<BusinessUserProfilesListResultType>({
    queryParams,
    options,
    body,
    path: "business-profiles/bo/business-profiles/list",
  });

const getReviewsForBusinessProfile = (
  businessProfileId: string | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `business-profiles/bp/business-profiles/${businessProfileId}/reviews`,
  });

const getReviewsForBusinessProfileAdmin = (
  businessProfileId: string | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `business-profiles/bo/business-profiles/${businessProfileId}/reviews`,
  });

const saveReplyToReviewForBusinessProfile = (
  businessProfileId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    queryParams: {},
    path: `business-profiles/bp/business-profiles/${businessProfileId}/reviews/${reviewId}/replies`,
  });

const saveReplyToReviewForBusinessProfileAdmin = (
  businessProfileId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    queryParams: {},
    path: `business-profiles/bo/business-profiles/${businessProfileId}/reviews/${reviewId}/replies`,
  });

const deleteReplyToReviewForBusinessProfile = (
  businessProfileId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.deleteEntity<void>({
    options,
    path: `business-profiles/bp/business-profiles/${businessProfileId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const deleteReplyToReviewForBusinessProfileAdmin = (
  businessProfileId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.deleteEntity<void>({
    options,
    path: `business-profiles/bo/business-profiles/${businessProfileId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const getImprintByLoggedInBusiness = (options: RequestOptions | undefined) =>
  businessUserProfileAPI.getJson<ImprintInformation>({
    options,
    path: "business-profiles/bp/business-profiles/imprint",
  });

const getImprintByLoggedInBusinessAdmin = (
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<ImprintInformation>({
    options,
    path: "business-profiles/bo/business-profiles/imprint",
  });

const getImprintByLoggedInBusinessCurator = (
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<ImprintInformation>({
    options,
    path: "business-profiles/bp/curator/business-profiles/imprint",
  });

const getAdminImprintByBusinessId = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<ImprintInformation>({
    options,
    path: `business-profiles/bo/business-profiles/imprint/${businessId}`,
  });

const getCuratorImprintByBusinessId = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<ImprintInformation>({
    options,
    path: `business-profiles/bp/curator/business-profiles/imprint/${businessId}`,
  });

const updateBusinessImprint = (
  businessId: string,
  body: ImprintInformation,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<ImprintInformation>({
    options,
    body,
    path: `business-profiles/bo/business-profiles/imprint/${businessId}`,
  });

const updateBPBusinessImprint = (
  businessId: string,
  body: ImprintInformation,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<ImprintInformation>({
    options,
    body,
    path: `business-profiles/bp/business-profiles/imprint/${businessId}`,
  });

const exportBusinessProfilesAdmin = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.download({
    queryParams,
    options,
    body,
    path: "business-profiles/bo/business-profiles/export",
  });

// Used in new business wizard and business admin "change plan" view(BusinessSettingsPlanChange)
// In case of BusinessSettingsPlanChange, BE repositions the currently active plan into the first position of the returned plans list(based on user's context)
const getAvailablePlans = (onboarding = false, options?: RequestOptions) =>
  businessUserProfileAPI.getJson<Plan[]>({
    options,
    queryParams: onboarding ? { onboarding } : {},
    path: "business-profiles/bp/business-profiles/plans",
  });

const getAvailablePlansAdmin = (
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<Plan[]>({
    options,
    queryParams: businessId ? { businessId } : {},
    path: "business-profiles/bo/business-profiles/plans",
  });

const getBusinessPlan = (options: RequestOptions | undefined) =>
  businessUserProfileAPI.getJson<BusinessPlanHistory>({
    options,
    path: "business-profiles/bp/business-profiles/plan",
  });

const savePaymentMethod = (
  body: MandateConfirmationDTO,
  options: RequestOptions | undefined,
) => {
  return businessUserProfileAPI.postJson<MandateConfirmationResponseDTO>({
    body,
    options,
    path: `business-profiles/bp/business-profiles/payment`,
  });
};

const saveCreditCard = (
  body: CreateCreditCardDTO,
  options: RequestOptions | undefined,
) => {
  return businessUserProfileAPI.postJson<MandateConfirmationResponseDTO>({
    body,
    options,
    path: `business-profiles/bp/business-profiles/payment/credit-card`,
  });
};

const createMandate = (
  body: CreateMandateDTO,
  options: RequestOptions | undefined,
) => {
  return businessUserProfileAPI.postJson<CreateMandateResponseDTO>({
    options,
    body,
    path: `business-profiles/bp/business-profiles/payment/mandate`,
  });
};

const getPaymentMethods = (options: RequestOptions | undefined) => {
  return businessUserProfileAPI.getJson<{ data: PaymentDataResponseDTO[] }>({
    options,
    path: `business-profiles/bp/business-profiles/payment`,
  });
};

const getPaymentAddress = (options: RequestOptions | undefined) => {
  return businessUserProfileAPI.getJson<PaymentAddressDTO>({
    options,
    path: `business-profiles/bp/business-profiles/payment/payment-address`,
  });
};

const deletePaymentMethod = (methodId: string, options?: RequestOptions) => {
  return businessUserProfileAPI.deleteEntity<void>({
    options,
    entityId: methodId,
    path: `business-profiles/bp/business-profiles/payment`,
  });
};

const makePaymentMethodDefault = (
  methodId: string,
  options?: RequestOptions,
) => {
  return businessUserProfileAPI.putJson<void>({
    options,
    path: `business-profiles/bp/business-profiles/payment/${methodId}/set-default`,
  });
};

const getPayoneRequestConfig = (options: RequestOptions | undefined) => {
  return businessUserProfileAPI.getJson<{ data: PaymentDataResponseDTO[] }>({
    options,
    path: `business-profiles/bp/business-profiles/payment/settings`,
  });
};

const getAdminPaymentMethods = (
  businessId: string,
  options: RequestOptions | undefined,
) => {
  return businessUserProfileAPI.getJson<{ data: PaymentDataResponseDTO[] }>({
    options,
    path: `business-profiles/bo/business-profiles/${businessId}/payment`,
  });
};

const getBusinessPlanAdmin = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<BusinessPlanHistory>({
    options,
    path: `business-profiles/bo/business-profiles/${businessId}/plan`,
  });

const updateBusinessPlan = (
  body: BusinessPlanPayload,
  options: RequestOptions | undefined,
) => {
  return businessUserProfileAPI.putJson<BusinessPlanPayload>({
    body,
    options,
    path: `business-profiles/bp/business-profiles/plan`,
  });
};

const updateBusinessPlanAdmin = (
  body: BusinessPlanUpdatePayload,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<BusinessPlanPayload>({
    body,
    options,
    path: `business-profiles/bo/business-profiles/plan`,
  });

const getAdditionalSettings = (options: RequestOptions | undefined) =>
  businessUserProfileAPI.getJson<AdditionalSettingsPayload>({
    options,
    path: `business-profiles/bp/business-profiles/additional-settings`,
  });

const getAdditionalSettingsAdmin = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<AdditionalSettingsPayload>({
    options,
    path: `business-profiles/bo/business-profiles/${businessId}/additional-settings`,
  });

const getAdditionalSettingsCurator = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<AdditionalSettingsPayload>({
    options,
    path: `business-profiles/bp/curator/business-profiles/${businessId}/additional-settings`,
  });

const updateAdditionalSettingsAdmin = (
  body: AdditionalSettingsPayload,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<AdditionalSettingsPayload>({
    body,
    options,
    path: `business-profiles/bo/business-profiles/additional-settings`,
  });

const updateBusinessExtraContentLimitAdmin = (
  businessId: string,
  body: ExtraContentLimitUpdatePayload,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.putJson<void>({
    body,
    options,
    path: `business-profiles/bo/business-profiles/${businessId}/plan/limitations`,
  });

const isContractAvailable = (options: RequestOptions | undefined) =>
  businessUserProfileAPI.getJson<boolean>({
    options,
    path: `business-profiles/bp/business-profiles/contract/availability`,
  });

const downloadContract = () =>
  businessUserProfileAPI.download({
    path: "business-profiles/bp/business-profiles/contract",
    options: {
      method: "GET",
      body: undefined,
      headers: {
        Accept: "application/pdf",
      },
    },
    defaultFileName: "Bliggit-Vertrag.pdf",
  });

const isContractAvailableAdmin = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  businessUserProfileAPI.getJson<boolean>({
    options,
    path: `business-profiles/bo/business-profiles/contract/availability/${businessId}`,
  });

const downloadContractAdmin = (businessId: string) =>
  businessUserProfileAPI.download({
    path: `business-profiles/bo/business-profiles/contract/${businessId}`,
    options: {
      method: "GET",
      body: undefined,
      headers: {
        Accept: "application/pdf",
      },
    },
    defaultFileName: "Bliggit-Vertrag.pdf",
  });

export {
  getBusinessProfiles,
  getBusinessProfilesAdmin,
  getBusinessProfilesCurator,
  getBusinessProfile,
  getBusinessProfileAdmin,
  getBusinessProfileCurator,
  createDraftBusinessProfile,
  createDraftBusinessProfileAdmin,
  createDraftBusinessProfileCurator,
  updateDraftBusinessProfile,
  updateDraftBusinessProfileAdmin,
  updateDraftBusinessProfileCurator,
  createBusinessProfile,
  createBusinessProfileAdmin,
  updateBusinessProfile,
  updateBusinessProfileAdmin,
  updateBusinessProfileCurator,
  updateBusinessProfileStatusInList,
  getBusinessProfileFilters,
  getAllBusinessProfilesList,
  getReviewsForBusinessProfile,
  getReviewsForBusinessProfileAdmin,
  getImprintByLoggedInBusinessAdmin,
  getImprintByLoggedInBusinessCurator,
  getImprintByLoggedInBusiness,
  getAdminImprintByBusinessId,
  getCuratorImprintByBusinessId,
  updateBusinessImprint,
  updateBPBusinessImprint,
  exportBusinessProfilesAdmin,
  getAvailablePlans,
  getAvailablePlansAdmin,
  getBusinessPlan,
  getBusinessPlanAdmin,
  updateBusinessPlan,
  updateBusinessPlanAdmin,
  getAdditionalSettings,
  getAdditionalSettingsAdmin,
  getAdditionalSettingsCurator,
  updateAdditionalSettingsAdmin,
  updateBusinessExtraContentLimitAdmin,
  getPaymentMethods,
  getPaymentAddress,
  getAdminPaymentMethods,
  getPayoneRequestConfig,
  createMandate,
  savePaymentMethod,
  deletePaymentMethod,
  makePaymentMethodDefault,
  saveCreditCard,
  isContractAvailable,
  downloadContract,
  isContractAvailableAdmin,
  downloadContractAdmin,
  saveReplyToReviewForBusinessProfile,
  saveReplyToReviewForBusinessProfileAdmin,
  deleteReplyToReviewForBusinessProfile,
  deleteReplyToReviewForBusinessProfileAdmin,
};
