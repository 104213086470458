import React from "react";
import styled from "styled-components";
import Plan from "api/models/Plan";
import BliggBubble from "components/generic/BliggBubble";
import * as PlanCard from "views/Wizard/PlanCard";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import TextButton from "components/buttons/TextButton";
import strings from "localisation/strings";
import ContentLimitCard from "views/BusinessSettings/PlanSelection/components/ContentLimitCard";
import { Container } from "components/generic";
import { partnerEmailSupportLink } from "core/constants";

const horizontalCardMinHeight = 260;
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: ${horizontalCardMinHeight}px;
  border: 1px solid ${({ theme }) => theme.color.background.light};
`;

const minimumContentWidth = 200;
const CardContentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${minimumContentWidth}px;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.margin.large};
  margin-left: ${({ theme }) => theme.margin.large};
  padding-bottom: ${({ theme }) => theme.margin.large};
`;

const minimumCountersContainerWidth = 200;
const CountersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.margin.large};
  padding-bottom: ${({ theme }) => theme.margin.large};
  flex-basis: ${minimumCountersContainerWidth}px;
  flex-grow: 1;
  flex-shrink: 0;

  ${PlanCard.PlanContainer} {
    margin-top: ${({ theme }) => theme.margin.large};
    margin-right: ${({ theme }) => theme.margin.large};
  }
`;

const horizontalHeroCurveWidth = 184;
const horizontalHeroAdditionalWidth = 56;
const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  flex-basis: ${horizontalHeroCurveWidth + horizontalHeroAdditionalWidth}px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const horizontalHeroOverflowHeightPercent = 50;
const HorizontalHeroBackground = styled(PlanCard.HeroBackground)`
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  left: 0;
  background-color: ${({ theme }) => theme.color.foreground.action};
  height: ${100 + horizontalHeroOverflowHeightPercent}%;
  top: -${horizontalHeroOverflowHeightPercent / 2}%;
  width: ${horizontalHeroCurveWidth}px;
`;

const PlanDescriptionText = styled(RichTextRendering)`
  margin-top: ${({ theme }) => theme.margin.large};
  padding-right: ${({ theme }) => theme.margin.large};
  flex-grow: 1;
  flex-shrink: 0;
`;

const isLimitDepleted = (
  contentLimit: number | null,
  contentCounter: number | null,
) => {
  if (!contentLimit || contentCounter === null) {
    return false;
  }

  return contentCounter >= contentLimit;
};

const formatPlanMonthlyContentLimitBubbleText = (plan: Plan) =>
  plan.monthlyContentLimit === 0
    ? strings("businessSettings.plan.noPosts")
    : plan.monthlyContentLimit === null
    ? strings("businessSettings.plan.unlimitedPosts")
    : `${plan.monthlyContentCounter} / ${plan.monthlyContentLimit}
          ${strings("businessSettings.plan.postsThisMonth")}`;

const formatMonthlyLimitCardDescription = (plan: Plan) =>
  plan.monthlyContentLimit === 0
    ? strings("businessSettings.plan.noMonthlyPosts")
    : plan.monthlyContentLimit === null
    ? strings("businessSettings.plan.postsUnlimitedDescription")
    : strings("businessSettings.plan.postsLimitDescription", {
        postsLimit: plan.monthlyContentLimit,
      });
const formatExtraContentMonthlyLimitBubbleText = (plan: Plan) =>
  plan.extraContentLimit === 0
    ? strings("businessSettings.plan.noExtraPosts")
    : plan.extraContentLimit
    ? `${plan.extraContentCounter} / ${plan.extraContentLimit}
        ${strings("businessSettings.plan.extraPosts")}`
    : strings("businessSettings.plan.noExtraPosts");
const formatExtraMonthlyLimitCardDescription = (plan: Plan) =>
  plan.extraContentLimit
    ? strings("businessSettings.plan.extraPostsLimitDescription", {
        postsLimit: plan.extraContentLimit,
      })
    : strings("businessSettings.plan.noExtraPostsDescription");

const UpgradePackageLink = ({ onChangePlan }: ChangePlanLinkProps) => {
  return (
    <PlanActionLink
      text={strings("businessSettings.plan.noMonthlyPostsUpgrade")}
      onClick={onChangePlan}
    />
  );
};

const OrderExtraPostsLink = () => {
  return (
    <PlanActionLink
      text={strings("businessSettings.plan.extraPostsOrderHere")}
      onClick={() => {
        window.location.href = partnerEmailSupportLink;
      }}
    />
  );
};

const PlanActionLink = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <Container justifyContent="center">
      <TextButton onClick={onClick} text={text} type="inline" small />
    </Container>
  );
};

export interface ChangePlanLinkProps {
  onChangePlan: () => void;
}

export interface Props extends ChangePlanLinkProps {
  plan: Plan;
  extraLimitEditable?: boolean;
  onExtraLimitEdited?: (updatedContentLimit: number) => void;
}

const CurrentPlanHorizontalCard = ({
  plan,
  extraLimitEditable,
  onChangePlan,
  onExtraLimitEdited,
}: Props) => {
  const monthlyLimitDepleted = isLimitDepleted(
    plan.monthlyContentLimit,
    plan.monthlyContentCounter,
  );
  const monthlyContentLimitBubbleText = formatPlanMonthlyContentLimitBubbleText(
    plan,
  );
  const monthlyLimitCardDescription = formatMonthlyLimitCardDescription(plan);
  const extraLimitDepleted = isLimitDepleted(
    plan.extraContentLimit,
    plan.extraContentCounter,
  );
  const extraContentLimitBubbleText = formatExtraContentMonthlyLimitBubbleText(
    plan,
  );
  const extraLimitCardDescription = formatExtraMonthlyLimitCardDescription(
    plan,
  );

  const packageUpgradeAvailable = plan.monthlyContentLimit === 0;

  return (
    <CardContainer>
      <HeroContainer>
        <HorizontalHeroBackground />
        <BliggBubble size="large" bubbleText={plan.bliggText} />
      </HeroContainer>

      <CardContentDetailsContainer>
        <PlanCard.TitleText bold>{plan.name}</PlanCard.TitleText>
        <PlanCard.TitleText>{plan.price}</PlanCard.TitleText>
        <PlanCard.SubtitleText>{plan.priceText}</PlanCard.SubtitleText>
        {plan.description && (
          <PlanDescriptionText cozy value={plan.description} />
        )}
        <TextButton
          onClick={onChangePlan}
          text={strings("businessSettings.plan.changePlan")}
        />
      </CardContentDetailsContainer>
      <CountersContainer>
        <ContentLimitCard
          bubbleText={monthlyContentLimitBubbleText}
          depleted={monthlyLimitDepleted}
          description={monthlyLimitCardDescription}
          link={
            packageUpgradeAvailable ? (
              <UpgradePackageLink onChangePlan={onChangePlan} />
            ) : (
              undefined
            )
          }
        />
        <ContentLimitCard
          secondaryHeroColor
          contentLimitEditable={extraLimitEditable}
          bubbleText={extraContentLimitBubbleText}
          depleted={extraLimitDepleted}
          description={extraLimitCardDescription}
          onContentLimitEdited={onExtraLimitEdited}
          link={<OrderExtraPostsLink />}
        />
      </CountersContainer>
    </CardContainer>
  );
};

export default CurrentPlanHorizontalCard;
