import React, { ChangeEvent, useEffect, useState } from "react";
import { equals } from "ramda";
import strings from "localisation/strings";
import styled from "style/styled-components";

import { ImprintInformation } from "api/models/BusinessUserProfileInterface";
import Location from "api/models/Location";

import TextWithIcon from "components/forms/FormFields/TextWithIcon";
import LocationInput from "components/forms/FormFields/LocationInput";
import TextButton from "components/buttons/TextButton";

import { FormErrorsInterface } from "hooks/useFormFields";
import usePrevious from "hooks/usePrevious";

import websiteIcon from "common/assets/icons/card.svg";
import emailIcon from "common/assets/icons/mail.svg";
import phoneIcon from "common/assets/icons/contact.svg";
import briefcaseIcon from "common/assets/icons/briefcase-black.svg";
import DescriptionBlock from "components/forms/DescriptionBlock";
import { Label } from "components/forms/FormFields/InlineInputGroup";

const ExpansionToggle = styled(TextButton)`
  margin-bottom: ${({ theme }) => theme.margin.medium};
`;

export interface ImprintProps {
  imprintInformation: ImprintInformation;
  onChangeImprintField: (
    field: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  onChangeLocation: (value: Location) => void;
  onChangeField: (field: string, value: any) => void;
  onCopyContacts: () => void;
  errors: FormErrorsInterface;
  shouldExpandFirst?: true;
}

const CollapsibleImprintArea = ({
  imprintInformation,
  onChangeImprintField,
  onChangeLocation,
  onChangeField,
  onCopyContacts,
  errors,
  shouldExpandFirst: shouldExpandFIrst,
}: ImprintProps) => {
  // storing old state of imprint via usePrevious hook
  const oldImprint = usePrevious(imprintInformation);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [expandTriggered, updateTrigger] = useState<boolean>(false);

  const {
    website,
    phone,
    email,
    city,
    street,
    houseNo,
    legalName,
    businessName,
    additionalInformation,
  } = imprintInformation;

  useEffect(() => {
    if (
      !expandTriggered &&
      oldImprint &&
      !equals(oldImprint, imprintInformation)
    ) {
      if (!shouldExpandFIrst) {
        const shouldExpand = !(
          phone &&
          email &&
          city &&
          street &&
          houseNo &&
          legalName &&
          businessName
        );
        setExpanded(shouldExpand);
      }
      // flag making sure that the update happens only once
      updateTrigger(true);
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imprintInformation]);

  return (
    <>
      {!shouldExpandFIrst && (
        <ExpansionToggle
          onClick={() => setExpanded(!expanded)}
          text={strings("businessUserProfileScreen.fields.imprint.title")}
        />
      )}
      {expanded && (
        <div>
          <Label isBold>
            {strings("businessUserProfileScreen.fields.imprint.title")}
          </Label>

          <TextButton
            text={`+ ${strings(
              "businessUserProfileScreen.fields.imprint.copy",
            )}`}
            onClick={onCopyContacts}
          />
          <TextWithIcon
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeImprintField("businessName", event)
            }
            value={businessName}
            label={strings(
              "businessUserProfileScreen.fields.businessName.label",
            )}
            placeholder={strings(
              "businessUserProfileScreen.fields.businessName.placeholder",
            )}
            icon={briefcaseIcon}
            errors={errors.businessName}
            testId="imp-business-name"
          />
          <TextWithIcon
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onChangeImprintField("legalName", event);
            }}
            value={legalName}
            label={strings("businessUserProfileScreen.fields.legalName.label")}
            placeholder={strings(
              "businessUserProfileScreen.fields.legalName.placeholder",
            )}
            icon={briefcaseIcon}
            errors={errors.legalName}
            testId="imp-legal-name"
          />
          <TextWithIcon
            optional
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeImprintField("website", event)
            }
            value={website}
            placeholder={strings(
              "businessUserProfileScreen.fields.website.label",
            )}
            icon={websiteIcon}
            errors={errors.website}
            testId="imp-website"
          />
          <LocationInput
            location={imprintInformation}
            onChangeLocation={onChangeLocation}
            errors={errors}
          />
          <TextWithIcon
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeImprintField("email", event)
            }
            value={email}
            placeholder={strings(
              "businessUserProfileScreen.fields.email.label",
            )}
            icon={emailIcon}
            errors={errors.email}
            testId="imp-email"
          />
          <TextWithIcon
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeImprintField("phone", event)
            }
            value={phone}
            placeholder={strings(
              "businessUserProfileScreen.fields.phone.label",
            )}
            icon={phoneIcon}
            errors={errors.phone}
            testId="imp-phone"
          />
          <DescriptionBlock
            onContentChange={(value: string) =>
              onChangeField("additionalInformation", value)
            }
            value={additionalInformation}
            label={strings(
              "businessUserProfileScreen.fields.additionalInformation.label",
            )}
            placeholder={strings(
              "businessUserProfileScreen.fields.additionalInformation.placeholder",
            )}
            richText={true}
            errors={errors.additionalInformation}
          />
        </div>
      )}
    </>
  );
};

export default CollapsibleImprintArea;
