import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { curry } from "ramda";

import strings from "localisation/strings";

import useLoginState from "hooks/useLoginState";
import ListResultType from "api/models/ListResultType";
import PostInterface from "api/models/PostInterface";
import { getPosts, getPostsFilters } from "api/posts";

import PostsList from "components/lists/postList/PostsList";
import FilterableList from "components/lists/FilterableList";
import { SearchRequest } from "components/lists/ListRequestTypes";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import ListTableView from "views/ListTableView";
import { NEW } from "constants/strings";

const AdminPosts = ({ location, history, match }: RouteComponentProps) => {
  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();

  const postListRequest: SearchRequest<ListResultType<PostInterface>> = ({
    offset,
    limit,
    body,
    q,
  }) =>
    curry(getPosts)(
      businessId,
      { q, filterReq: body, from: offset, size: limit },
      {},
    );

  const postEventFiltersRequest: FiltersRequestType = ({ filters }) =>
    curry(getPostsFilters)({ filters }, businessId);

  const onClick = (id: string) => history.push(`beitraege/${id}`);

  const renderListView = ({
    items,
    onItemClick,
  }: InteractiveListInterface<PostInterface>) => {
    return (
      <PostsList
        emptyIndicatable
        emptyIndicator={strings("listView.noContentFilterable")}
        posts={items}
        onItemClick={onItemClick}
      />
    );
  };

  if (businessId) {
    return (
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS} isView>
        <FilterableList<PostInterface>
          onItemClick={onClick}
          location={location}
          history={history}
          match={match}
          pageTitle={strings("postsScreen.title")}
          filterPageTitle={strings("postsScreen.filterScreenTitle")}
          sortPageTitle={strings("sortScreen.title")}
          searchPageTitle={strings("searchScreen.title")}
          searchRequest={postListRequest}
          postFiltersRequest={postEventFiltersRequest}
          onAddNew={() => onClick(NEW)}
          renderListView={renderListView}
          defaultSort={DEFAULT_SORT}
          redirectOnEmptyResult={true}
        />
      </PrivilegedComponent>
    );
  }
  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS_ADMIN} isView>
      <ListTableView<PostInterface> />
    </PrivilegedComponent>
  );
};

export default withRouter(AdminPosts);
