import RequestSource from "api/RequestSource";
import APIError from "common/api/models/APIError";
import RequestOptions from "api/RequestOptions";
import { LoginTokens } from "api/models/UsersInterface";
import { mergeDeepRight } from "ramda";

declare global {
  // doesn't work in react-app-env.d.ts
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

const addRequestSource = (payload: object) => ({
  source: RequestSource.web,
  ...payload,
});

const isJsonContentType = (response: Response): boolean => {
  const contentTypeHeader = response.headers.get("content-type");
  const isJsonContent = contentTypeHeader
    ? contentTypeHeader.includes("application") &&
      contentTypeHeader.includes("json")
    : false;
  return isJsonContent;
};

const parseResponseWithText = async <ResultType>(
  response: Response,
): Promise<ResultType> => {
  try {
    const textResult = await response.text();

    if (textResult.length === 0) {
      return {} as ResultType;
    }

    throw new APIError({
      status: response.status,
      path: response.url,
      titleCode: "errors.parsing.nonEmptyString",
      detailCode: "errors.parsing.nonEmptyString",
    });
  } catch (error) {
    throw new APIError({
      status: response.status,
      path: response.url,
      titleCode: "errors.parsing.unparsableText",
      detailCode: "errors.parsing.unparsableText",
    });
  }
};

const parseResponseWithJson = async <ResultType>(
  response: Response,
): Promise<ResultType> => {
  try {
    const result = await response.json();
    return result;
  } catch (error) {
    throw new APIError({
      status: response.status,
      path: response.url,
      titleCode: "errors.parsing.unparsableJson",
      detailCode: "errors.parsing.unparsableJson",
    });
  }
};

export interface FileResultType {
  fileName: string;
  contentType: string;
  blob: Blob;
}

const parseResponseWithBlob = async (
  response: Response,
  defaultFileName?: string,
): Promise<FileResultType> => {
  try {
    const blob = await response.blob();
    let fileName = defaultFileName || "download.csv"; // Backend only supports CSV files at the moment
    const contentType = response.headers.get("Content-Type") || "";
    const disposition = response.headers.get("Content-Disposition") || "";
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }

    return { fileName, contentType, blob };
  } catch (error) {
    throw new APIError({
      status: response.status,
      path: response.url,
      titleCode: "errors.parsing.unparsableJson",
      detailCode: "errors.parsing.unparsableJson",
    });
  }
};

const formatToAPILocale = (locale: string) => locale.split("-")[0];

const parseTotalItemCount = (headers: {
  get: (name: string) => string | null;
}) => parseInt(headers.get("X-Total-Count") || "0", 10) || 0;

const addAuthorizationHeader = ({
  options,
  tokens,
}: {
  options: RequestOptions;
  tokens: LoginTokens;
}) =>
  mergeDeepRight(options, {
    headers: {
      Authorization: `Bearer ${tokens.serviceToken}`,
    },
  });

const handleFileDownload = ({ blob, fileName }: FileResultType) => {
  if (navigator.msSaveBlob) {
    // Support IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    if (typeof link.download === "undefined") {
      // Support Safari
      link.setAttribute("target", "_blank");
    }
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
};

export {
  addRequestSource,
  parseResponseWithJson,
  parseResponseWithText,
  parseResponseWithBlob,
  isJsonContentType,
  formatToAPILocale,
  parseTotalItemCount,
  addAuthorizationHeader,
  handleFileDownload,
};
