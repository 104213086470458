import React from "react";
import styled from "style/styled-components";
import SimpleText from "components/generic/SimpleText";
import Toaster from "components/generic/toaster/Toaster";

const Text = styled(SimpleText)`
  flex-grow: 1;
  text-align: center;
`;

export enum NotificationTypes {
  success = "success",
  error = "error",
  system = "system",
}

interface Props {
  type?: NotificationTypes;
  message: string;
  clearNotification: () => void;
}

const GenericToaster = ({
  type = NotificationTypes.success,
  message,
  clearNotification,
}: Props) => {
  return (
    <Toaster.Container>
      <Toaster.Body centered type={type}>
        <Text>{message}</Text>
        <Toaster.ClosePositioner centered>
          <Toaster.Close type={type} onClick={clearNotification} />
        </Toaster.ClosePositioner>
      </Toaster.Body>
    </Toaster.Container>
  );
};

export default GenericToaster;
