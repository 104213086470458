import React from "react";
import { MarginlessBlockTitle, ViewContainer } from "components/generic";

const Placeholder = ({ name }: { name: string }) => (
  <ViewContainer>
    <MarginlessBlockTitle>{`${name} Placeholder`}</MarginlessBlockTitle>
  </ViewContainer>
);

export default Placeholder;
