import React, { RefObject } from "react";
import { InlineInputProps } from "components/forms/FormFields/InlineInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { InlineTextField } from "components/forms/FormFields/InlineTextField";

export interface TextInputProps extends InlineInputProps {
  disabled?: boolean;
  onChange?: (value: any) => void;
  optional?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  testId?: string;
}

export interface TextWithIconProps extends TextInputProps {
  errors?: string[];
  icon?: string;
  label?: string;
  width?: any;
  leftSideWidth?: string;
  id?: string; // TODO: make id prop mandatory to cover all text inputs for cypress testing
}

const TextWithIcon = ({
  errors,
  icon,
  label,
  onChange,
  value,
  placeholder,
  disabled,
  width,
  onBlur,
  maxLength,
  id,
  leftSideWidth,
  testId = "",
}: TextWithIconProps) => {
  return (
    <InlineInputGroup
      width={leftSideWidth}
      icon={icon}
      placeholder={label || placeholder}
    >
      <InlineTextField
        placeholder={placeholder}
        type="text"
        onChange={onChange}
        value={value}
        disabled={disabled}
        errors={errors}
        width={width}
        onBlur={onBlur}
        maxLength={maxLength}
        id={id}
        testId={testId}
      />
    </InlineInputGroup>
  );
};

export default TextWithIcon;
