enum StatisticType {
  totalClicks = "totalClicks",
  searchClicks = "searchClicks",
  objectsShared = "objectsShared",
  favoritesAdded = "favoritesAdded",
  likesAdded = "likesAdded",
  ratedOrReviewed = "ratedOrReviewed",
  calendarAdded = "calendarAdded",
  offersRedeemed = "offersRedeemed",
}

export default StatisticType;
