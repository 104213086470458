import React, { useEffect, useRef } from "react";
import { curry } from "ramda";

import mainTheme from "style/theme";
import strings from "localisation/strings";

import { MarginlessRepresentativeFormContainer } from "components/forms/BusinessRepresentative/RepresentativeFormContainer";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import TextWithIcon from "components/forms/FormFields/TextWithIcon";
import InputPhoneNumber from "components/forms/InputPhoneNumber";
import ActionArea from "components/generic/ActionArea";
import ActionButton from "components/buttons/ActionButton";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import {
  mapUserValuesToFormFields,
  UserDataFormValues,
} from "components/forms/UserDataForm/helpers";

import useFormFields from "hooks/useFormFields";

import userIcon from "assets/icons/user.svg";
import mailIcon from "assets/icons/mail.svg";
import phoneIcon from "assets/icons/contact.svg";

interface Props {
  submitLabel: string;
  isSubmittable: boolean;
  successMessage?: string | null;
  defaultUserData?: Partial<UserDataFormValues>;
  formSubmittedErrors?: APIErrorResponse;
  clearSuccessMessage?: () => void;
  onSubmit: (values: UserDataFormValues) => void;
}

const UserDataForm = ({
  submitLabel,
  defaultUserData = {},
  onSubmit,
  isSubmittable,
  formSubmittedErrors,
}: Props) => {
  const {
    getSingleInputFieldProps,
    getSelectFieldProps,
    getErrors,
    values,
    updateValues,
    parseErrors,
  } = useFormFields({
    blockNavigation: true,
    handleGenericErrors: true,
    translationScope: "wizardScreen.fields",
    ...mapUserValuesToFormFields(defaultUserData),
  });

  const updateValuesRef = useRef(updateValues);
  const parseErrorsRef = useRef(parseErrors);

  useEffect(() => {
    return formSubmittedErrors && parseErrorsRef.current(formSubmittedErrors);
  }, [formSubmittedErrors]);

  useEffect(
    () =>
      defaultUserData &&
      updateValuesRef.current(mapUserValuesToFormFields(defaultUserData)),
    [defaultUserData],
  );

  return (
    <>
      <MarginlessRepresentativeFormContainer>
        <TextWithIcon
          icon={userIcon}
          width="100%"
          maxLength={50}
          errors={getErrors("firstName")}
          {...getSingleInputFieldProps("firstName")}
        />

        <TextWithIcon
          icon={userIcon}
          width="100%"
          maxLength={50}
          errors={getErrors("lastName")}
          {...getSingleInputFieldProps("lastName")}
        />

        <TextWithIcon
          icon={mailIcon}
          width="100%"
          maxLength={255}
          disabled={true}
          className={"disabled"}
          errors={getErrors("email")}
          {...getSingleInputFieldProps("email")}
        />

        <InlineInputGroup
          icon={phoneIcon}
          placeholder={strings("addRepresentativeForm.fields.phone.label")}
          width={mainTheme.forms.leftSide}
        >
          <InputPhoneNumber
            getSelectProps={curry(getSelectFieldProps)}
            phone={getSingleInputFieldProps("phone")}
            phoneCodeErrors={getErrors("phoneCode")}
            phoneErrors={getErrors("phone")}
          />
        </InlineInputGroup>

        <ActionArea>
          <ActionButton
            special="publish"
            text={submitLabel}
            loadingText={strings("buttons.states.requestInProgress")}
            disabled={!isSubmittable}
            onClick={() => onSubmit(values)}
          />
        </ActionArea>
      </MarginlessRepresentativeFormContainer>
    </>
  );
};

export default UserDataForm;
