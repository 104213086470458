import { PaymentMethodType } from "api/models/Payments";
import useQuery from "common/hooks/useQuery";
import { getPaymentMethods } from "api/businessProfiles";
import { useState, useEffect } from "react";
import QueryStatus from "common/api/models/QueryStatus";

const useDefaultPaymentMethodStatus = (paymentMethod: PaymentMethodType) => {
  const [isDefaultPaymentMethod, setDefaultPaymentMethodStatus] = useState(
    false,
  );
  const [
    isDefaultPaymentMethodMissing,
    setDefaultPaymentMethodMissing,
  ] = useState(false);
  const { result: { data = [] } = { data: [] }, status } = useQuery({
    request: getPaymentMethods,
    compare: [],
  });

  useEffect(() => {
    if (status === QueryStatus.SUCCESSFUL) {
      const hasPaymentMethods = data.length > 0;
      setDefaultPaymentMethodMissing(!hasPaymentMethods);

      const isDefault = data.find(
        ({ defaultPaymentMethod, paymentType }) =>
          defaultPaymentMethod && paymentType === paymentMethod,
      );

      setDefaultPaymentMethodStatus(!!isDefault);
    }
  }, [data, status]);

  return {
    isDefaultPaymentMethod,
    isDefaultPaymentMethodMissing,
    isDefaultPaymentMethodStatusLoading: status === QueryStatus.WAITING,
  };
};

export default useDefaultPaymentMethodStatus;
