import { zipObj, prop, keys, isEmpty } from "ramda";
import TagInterface from "api/models/TagInterface";
import {
  SelectedTagFilters,
  SelectionStateInterface,
  SelectedFilters,
  RemappedTagInterface,
} from "components/lists/filtering/SelectionStateInterface";
import Filters from "api/models/Filters";

export const reduceTagFilterToSelected = (
  appliedTagFilters: TagInterface[],
  possibleTagFilters: TagInterface[],
): SelectedTagFilters =>
  possibleTagFilters.reduce((acc: SelectedTagFilters, filter) => {
    const appliedTag = appliedTagFilters.find(f => f.id === filter.id);

    if (appliedTag) {
      return { ...acc, [filter.id]: true };
    }

    if (filter.tagSuccessors) {
      const selectedTagSuccessors = reduceTagFilterToSelected(
        appliedTagFilters,
        filter.tagSuccessors,
      );

      if (!isEmpty(selectedTagSuccessors)) {
        return { ...acc, [filter.id]: selectedTagSuccessors };
      }
    }

    return acc;
  }, {});

export const getSelectedFiltersFromApplied = (
  appliedFilters: Partial<Filters>,
  possibleFilters: Partial<Filters>,
): SelectionStateInterface => {
  const {
    dateTimeFilter,
    tagFilter = [],
    locationFilter = [],
    priceRangeFilter = [],
    offerTypeFilter = [],
    statusFilter = [],
  } = appliedFilters;
  const selectedTagFilters = reduceTagFilterToSelected(
    tagFilter,
    possibleFilters.tagFilter || [],
  );
  const filterIds = [
    ...locationFilter,
    ...priceRangeFilter,
    ...offerTypeFilter,
    ...statusFilter,
  ].map(prop("id"));
  const selectedFilters = zipObj(filterIds, filterIds.map(() => true));

  return {
    selectedTagFilters,
    selectedFilters,
    selectedDateRange: dateTimeFilter,
  };
};

export const reduceSelectedTags = (
  tagFilters: TagInterface[],
  selectedTagFilters: SelectedTagFilters,
): TagInterface[] =>
  tagFilters.reduce((acc: TagInterface[], filter) => {
    const selection = selectedTagFilters[filter.id];

    if (selection) {
      if (!filter.tagSuccessors || isEmpty(filter.tagSuccessors)) {
        return [...acc, filter];
      }

      const selectedSuccessors = reduceSelectedTags(
        filter.tagSuccessors,
        selection as SelectedTagFilters,
      );

      return [...acc, ...selectedSuccessors];
    }

    return acc;
  }, []);

export const getAppliedFiltersFromSelected = (
  selectionState: SelectionStateInterface,
  possibleFilters: Partial<Filters>,
): Partial<Filters> => {
  if (!possibleFilters) {
    return {};
  }

  let dateTimeFilter;
  let tagFilter;

  const {
    selectedTagFilters,
    selectedDateRange,
    selectedFilters,
  } = selectionState;

  if (selectedDateRange) {
    dateTimeFilter = selectedDateRange;
  }

  if (possibleFilters.tagFilter) {
    const tags = reduceSelectedTags(
      possibleFilters.tagFilter,
      selectedTagFilters,
    );
    if (tags.length) {
      tagFilter = tags;
    }
  }

  const priceRangeFilter = filterSelected(
    possibleFilters.priceRangeFilter,
    selectedFilters,
  );

  const locationFilter = filterSelected(
    possibleFilters.locationFilter,
    selectedFilters,
  );

  const offerTypeFilter = filterSelected(
    possibleFilters.offerTypeFilter,
    selectedFilters,
  );

  const statusFilter = filterSelected(
    possibleFilters.statusFilter,
    selectedFilters,
  );

  return {
    dateTimeFilter,
    tagFilter,
    priceRangeFilter,
    locationFilter,
    offerTypeFilter,
    statusFilter,
  };
};

export const filterSelected = <T extends { id: string }>(
  allFilters: T[] | undefined,
  selectedFilters: SelectedFilters,
) => {
  if (!allFilters || !keys(selectedFilters).length) {
    return;
  }
  return allFilters.filter(filter => selectedFilters[filter.id]);
};

export const remapTagSuccessorsToSubItems = ({
  tagSuccessors,
  ...rest
}: TagInterface): RemappedTagInterface => ({
  ...rest,
  subItems: tagSuccessors
    ? tagSuccessors.map(remapTagSuccessorsToSubItems)
    : tagSuccessors,
});

/*
  Iterates over structure: {x: {x_1: true, x_2: true}, y: true}
  Returns key paths in input object: {x_1: ["x", "x_1"], x_2: ["x", "x_2"], y: ["y"]}
  Used for getting idPaths for tag selection functions for flattened tags.
*/
export const getTagIdPaths = (
  selectedTagFilters: SelectionStateInterface["selectedTagFilters"],
  parentIdPath: string[] = [],
): { [key: string]: string[] } =>
  (keys(selectedTagFilters) as string[]).reduce((acc, parentTagId) => {
    const parentTag = selectedTagFilters[parentTagId];
    const subTags = keys(parentTag) as string[];

    if (typeof parentTag !== "boolean" && subTags && subTags.length) {
      const subTagIdPaths = getTagIdPaths(parentTag, [
        ...parentIdPath,
        parentTagId,
      ]);
      return { ...acc, ...subTagIdPaths };
    }
    return { ...acc, [parentTagId]: [...parentIdPath, parentTagId] };
  }, {});
