import styled from "style/styled-components";
import breakpoints from "style/breakpoints";

const SideExpandingScreen = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  width: 424px;
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.color.background.primary};
  transform: ${({ isActive }) =>
    isActive ? "translate(0, 0)" : "translate(100%, 0)"};
  transition: transform 0.2s ease-in-out;
  z-index: ${({ theme }) => theme.layers.modal};
  padding: 40px 40px 0;
  overflow-y: auto;

  @media ${breakpoints.phoneMax} {
    padding: 20px ${({ theme }) => theme.margin.large} 0;
  }
`;

export default SideExpandingScreen;
