import React from "react";
import FilterSection from "components/lists/filtering/FilterSection";
import MultiSelectButton from "components/buttons/MultiSelectButton";
import WrappingMultiSelectRow from "components/rows/WrappingMultiSelectRow";

interface MultiSelectFilterListProps {
  title: string;
  filters: { id: string; name?: string }[];
  isSelected: (id: string) => boolean;
  onSelect: (id: string) => void;
}

const MultiSelectFilterList = ({
  title,
  filters,
  onSelect,
  isSelected,
}: MultiSelectFilterListProps) => (
  <FilterSection title={title}>
    <WrappingMultiSelectRow>
      {filters.map(({ id, name }) => (
        <MultiSelectButton
          key={id}
          selected={isSelected(id)}
          onClick={() => onSelect(id)}
        >
          {name}
        </MultiSelectButton>
      ))}
    </WrappingMultiSelectRow>
  </FilterSection>
);

export default MultiSelectFilterList;
