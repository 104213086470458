import React from "react";
import styled, { isMobile } from "style/styled-components";
import useWindowDimensions from "hooks/useWindowDimensions";
import ContentType from "common/api/models/ContentType";
import {
  DashboardInterface,
  ContentItemOption,
} from "api/models/DashboardInterface";
import Chart from "components/dashboard/Chart";
import TypeSelectSidebar from "components/dashboard/TypeSelectSidebar";
import TypeSelectScrollableTabs from "components/dashboard/TypeSelectScrollableTabs";
import SelectedContentItemOptionsInterface from "components/dashboard/SelectedContentItemOptionsInterface";

interface Props {
  fetchedDashboardResult: DashboardInterface;
  fetchedContentItemOptionsResult: ContentItemOption[] | undefined;
  contentType: ContentType;
  setContentType: (contentType: ContentType) => void;
  selectedContentItemOptions: SelectedContentItemOptionsInterface;
  setSelectedContentItemOptions: (
    selectedContentItemOptions: SelectedContentItemOptionsInterface,
  ) => void;
  statisticType: string;
  setStatisticType: (statisticType: string) => void;
}

const Container = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  flex-direction: ${({ isMobileDevice }) =>
    isMobileDevice ? "column" : "row"};
  ${({ isMobileDevice }) => (isMobileDevice ? "" : "height: 100vh;")}
`;

const DashBoardContent = ({
  fetchedDashboardResult,
  fetchedContentItemOptionsResult,
  contentType,
  setContentType,
  selectedContentItemOptions,
  setSelectedContentItemOptions,
  statisticType,
  setStatisticType,
}: Props) => {
  const screenWidth = useWindowDimensions().width;
  const isMobileDevice = isMobile(screenWidth);

  return (
    <Container isMobileDevice={isMobileDevice}>
      {isMobileDevice ? (
        <TypeSelectScrollableTabs
          contentType={contentType}
          setContentType={setContentType}
        />
      ) : (
        <TypeSelectSidebar
          contentType={contentType}
          setContentType={setContentType}
        />
      )}
      <Chart
        contentType={contentType}
        fetchedDashboardResult={fetchedDashboardResult}
        fetchedContentItemOptionsResult={fetchedContentItemOptionsResult}
        selectedContentItemOptions={selectedContentItemOptions}
        setSelectedContentItemOptions={setSelectedContentItemOptions}
        statisticType={statisticType}
        setStatisticType={setStatisticType}
      />
    </Container>
  );
};

export default DashBoardContent;
