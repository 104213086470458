import React from "react";
import Input, { InputProps } from "components/forms/Input";
import FieldErrors from "components/forms/FieldErrors";
import FieldMargin from "components/forms/FieldMargin";
import styled from "style/styled-components";
import { FieldAligner } from "components/forms/FieldContainer";
import Testable from "testing/Testable";

export interface TextFieldProps extends InputProps, Testable {
  errors: string[];
  autocomplete?: string;
  testId?: string;
}

const MarginlessInput = styled(Input)`
  margin-bottom: 1px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const TextField = ({
  errors,
  className,
  ref,
  children,
  ...props
}: TextFieldProps) => (
  <FieldMargin className={className}>
    <MarginlessInput errored={errors.length > 0} {...props} />
    {children}
    <FieldAligner>
      <FieldErrors errors={errors} />
    </FieldAligner>
  </FieldMargin>
);

export default TextField;
