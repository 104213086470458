import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import ModifiedBy from "api/models/ModifiedBy";

export interface LastModifiedProps {
  modifiedBy?: ModifiedBy;
}

const Author = styled.span`
  color: ${({ theme }) => theme.color.foreground.action};

  &:before {
    content: " ";
  }
`;

const LastModifiedText = styled.span`
  font-size: ${({ theme }) => theme.font.size.pagination};
  color: ${({ theme }) => theme.color.foreground.quinary};
`;

const LastModifiedBy = ({ modifiedBy }: LastModifiedProps) => {
  if (modifiedBy) {
    return (
      <LastModifiedText>
        {strings("postsScreen.modifiedBy")}
        <Author>
          {modifiedBy.firstName} {modifiedBy.lastName}
        </Author>
      </LastModifiedText>
    );
  }

  return null;
};

export default LastModifiedBy;
