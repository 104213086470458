import React from "react";
import { UserStatuses } from "api/models/RepresentativeInterface";
import strings from "localisation/strings";
import ActionButton from "components/buttons/ActionButton";
import {
  getActivationButtonText,
  getActivationConfirmationText,
} from "components/forms/BusinessRepresentative/helpers";
import ActionArea from "components/generic/ActionArea";
import ConfirmTooltip from "components/generic/ConfirmTooltip";

interface ActionButtonsProps {
  isSubmitting: boolean;
  isEditMode: boolean;
  status?: UserStatuses;
  addNewCallback: () => void;
  saveEditCallback: () => void;
  changeStatusCallback: () => void;
  currentLoggedIn?: boolean;
}
const confirmationTooltip = { id: "activation-deactivation-button-tooltip" };
const ActionButtons = ({
  isSubmitting,
  isEditMode,
  status = UserStatuses.INACTIVE,
  addNewCallback,
  saveEditCallback,
  changeStatusCallback,
  currentLoggedIn,
}: ActionButtonsProps) => {
  if (isEditMode) {
    return (
      <ActionArea>
        <ActionButton
          text={
            isSubmitting
              ? strings("buttons.states.requestInProgress")
              : strings("addRepresentativeForm.fields.submit")
          }
          onClick={saveEditCallback}
        />
        <ActionButton
          text={
            isSubmitting
              ? strings("buttons.states.requestInProgress")
              : getActivationButtonText(status)
          }
          disabled={
            !isEditMode || status === UserStatuses.INACTIVE || currentLoggedIn
          }
          special="publish"
          data-event="click"
          data-tip
          data-for={confirmationTooltip.id}
        />
        <ConfirmTooltip
          tooltipId={confirmationTooltip.id}
          submitButton={
            <ActionButton
              onClick={changeStatusCallback}
              text={
                isSubmitting
                  ? strings("buttons.states.requestInProgress")
                  : getActivationButtonText(status)
              }
            />
          }
          popupMessage={getActivationConfirmationText(status)}
        />
      </ActionArea>
    );
  }

  return (
    <ActionArea>
      <ActionButton
        text={
          isSubmitting
            ? strings("buttons.states.requestInProgress")
            : strings("addRepresentativeForm.fields.sendAccess")
        }
        onClick={addNewCallback}
        special="publish"
      />
    </ActionArea>
  );
};

export default ActionButtons;
