import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import breakpoints from "style/breakpoints";

import PageSizeSelect from "components/pagination/PageSizeSelect";
import PageSelection from "components/pagination/PageSelection";
import { getPaginationParameters } from "components/pagination/helpers";

const PaginationContainer = styled.div<{ useStandardMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.margin.medium};
  margin-bottom: ${({ theme, useStandardMargin }) =>
    useStandardMargin ? theme.margin.medium : "150px"};

  @media ${breakpoints.phoneMax} {
    flex-direction: column-reverse;
  }
`;
const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${breakpoints.phoneMax} {
    margin-top: ${({ theme }) => theme.margin.large};
  }
`;

const TotalPagesContainer = styled.span`
  margin-left: ${({ theme }) => theme.margin.large};
  font-size: ${({ theme }) => theme.font.size.pagination};
  color: ${({ theme }) => theme.color.component.paginationText};
`;

const MarginedPageSizeSelect = styled(PageSizeSelect)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

interface Props {
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  limit: number;
  page: number;
  totalNumberOfPages: number;
  totalItems: number;
  useStandardMargin?: boolean;
}

const Pagination = ({
  onPageChange,
  onPageSizeChange,
  limit,
  page,
  totalNumberOfPages,
  totalItems,
  useStandardMargin,
}: Props) => {
  if (!totalNumberOfPages) return null;
  const { beginning, end } = getPaginationParameters(page, totalNumberOfPages);
  return (
    <PaginationContainer useStandardMargin={useStandardMargin}>
      <SettingsContainer>
        <MarginedPageSizeSelect
          pageSize={limit}
          onPageSizeChange={onPageSizeChange}
        />
        <TotalPagesContainer>
          {`${strings("pagination.of")} ${totalItems}`}
        </TotalPagesContainer>
      </SettingsContainer>
      <PageSelection
        page={page}
        totalNumberOfPages={totalNumberOfPages}
        onPageChange={onPageChange}
        beginning={beginning}
        end={end}
      />
    </PaginationContainer>
  );
};

export default Pagination;
