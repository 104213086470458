import React from "react";
import Filters from "api/models/Filters";
import TagFilterList from "components/lists/filtering/TagFilterList";
import DateRangeFilter from "components/lists/filtering/DateRangeFilter";
import { SelectionStateInterface } from "components/lists/filtering/SelectionStateInterface";
import {
  getAppliedFiltersFromSelected,
  getSelectedFiltersFromApplied,
} from "common/components/filtering/filterTransformUtils";
import {
  isTagSelected as isTagSelectedUtility,
  isFilterSelected as isFilterSelectedUtility,
  toggleTag,
  selectTags,
  toggleFilter,
} from "common/components/filtering/filterSelectUtils";
import MultiSelectFilterList from "components/lists/filtering/MultiSelectFilterList";
import strings from "localisation/strings";
import DateTimeRange from "api/models/DateTimeRange";

interface FilterSelectionProps {
  possibleFilters: Partial<Filters>;
  appliedFilters: Partial<Filters>;
  setAppliedFilters: (filters: Partial<Filters>) => void;
}

const FilterSelection = ({
  appliedFilters,
  possibleFilters,
  setAppliedFilters,
}: FilterSelectionProps) => {
  const selectionState = getSelectedFiltersFromApplied(
    appliedFilters,
    possibleFilters,
  );

  const setSelection = (newSelectionState: SelectionStateInterface): void =>
    setAppliedFilters(
      getAppliedFiltersFromSelected(newSelectionState, possibleFilters),
    );

  const onTagToggle = (idPath: string[]) =>
    setSelection(toggleTag(idPath, selectionState));

  const onTagMultiSelect = (idPaths: string[][]) =>
    setSelection(selectTags(idPaths, selectionState));

  const isTagSelected = (idPath: string[]) =>
    isTagSelectedUtility(idPath, selectionState);

  const isFilterSelected = (id: string) =>
    isFilterSelectedUtility(id, selectionState);

  const onFilterToggle = (id: string) =>
    setSelection(toggleFilter(id, selectionState));

  const onDateRangeChange = (dateRange: DateTimeRange) =>
    setSelection({ ...selectionState, selectedDateRange: dateRange });

  return (
    <>
      {possibleFilters.tagFilter && (
        <TagFilterList
          toggleTag={onTagToggle}
          isTagSelected={isTagSelected}
          selectTags={onTagMultiSelect}
          tagFilter={possibleFilters.tagFilter}
        />
      )}
      {possibleFilters.locationFilter && (
        <MultiSelectFilterList
          title={strings("filterScreen.locationFilter")}
          filters={possibleFilters.locationFilter}
          onSelect={onFilterToggle}
          isSelected={isFilterSelected}
        />
      )}
      {possibleFilters.priceRangeFilter && (
        <MultiSelectFilterList
          title={strings("filterScreen.priceRangeFilter")}
          filters={possibleFilters.priceRangeFilter}
          onSelect={onFilterToggle}
          isSelected={isFilterSelected}
        />
      )}
      {possibleFilters.offerTypeFilter && (
        <MultiSelectFilterList
          title={strings("filterScreen.offerTypeFilter")}
          filters={possibleFilters.offerTypeFilter}
          onSelect={onFilterToggle}
          isSelected={isFilterSelected}
        />
      )}
      {possibleFilters.statusFilter && (
        <MultiSelectFilterList
          title={strings("filterScreen.statusFilter")}
          filters={possibleFilters.statusFilter}
          onSelect={onFilterToggle}
          isSelected={isFilterSelected}
        />
      )}
      <DateRangeFilter
        dateTimeRange={selectionState.selectedDateRange || {}}
        onDateRangeChange={onDateRangeChange}
      />
    </>
  );
};

export default FilterSelection;
