import styled from "style/styled-components";

export const MarginlessRepresentativeFormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.forms.xlarge};
  padding-bottom: ${({ theme }) => theme.margin.xlarge};

  input {
    padding: 6px 10px;
  }
`;

const RepresentativeFormContainer = styled(
  MarginlessRepresentativeFormContainer,
)`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

export default RepresentativeFormContainer;
