import React from "react";
import styled from "style/styled-components";
import useChatState from "hooks/useChatState";

interface UnreadCounterSizeProps {
  size: "small" | "medium";
}

const UnreadNotificationsCount = styled.span<UnreadCounterSizeProps>`
  border-radius: 50px;
  padding: ${({ size }) => (size === "small" ? "1px 4px" : "2px 6px")};
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  background: ${({ theme }) => theme.color.background.secondary};
  text-align: center;
`;

interface Props extends UnreadCounterSizeProps {
  unreadCount: number;
}

const InlineUnreadCountContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.margin.medium};
`;

const FloatingUnreadCountContainer = styled.div`
  position: relative;
  width: 0;
  top: 6px;
  right: 25px;
`;

const UnreadCount = (props: Props) => (
  <UnreadNotificationsCount size={props.size}>
    {props.unreadCount}
  </UnreadNotificationsCount>
);

const InlineUnreadCount = (props: Props) => {
  return props.unreadCount > 0 ? (
    <InlineUnreadCountContainer>
      <UnreadNotificationsCount size={props.size}>
        {props.unreadCount}
      </UnreadNotificationsCount>
    </InlineUnreadCountContainer>
  ) : null;
};

const FloatingUnreadCount = (props: Props) => {
  return props.unreadCount > 0 ? (
    <FloatingUnreadCountContainer>
      <UnreadNotificationsCount size={props.size}>
        {props.unreadCount}
      </UnreadNotificationsCount>
    </FloatingUnreadCountContainer>
  ) : null;
};

const TotalUnreadFloatingCount = (props: UnreadCounterSizeProps) => {
  const { unreadMessagesCount } = useChatState();

  return <FloatingUnreadCount {...props} unreadCount={unreadMessagesCount} />;
};

export { InlineUnreadCount, FloatingUnreadCount, TotalUnreadFloatingCount };

export default UnreadCount;
