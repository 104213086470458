import React from "react";
import styled from "style/styled-components";
import { ImageItem } from "hooks/helpers/Interfaces";
import { AutoDragAndDrop } from "components/forms/FormFields";
import APIErrorResponse from "common/api/models/APIErrorResponse";

const Container = styled.div`
  position: relative;
`;

const SmallPicture = ({
  picture,
  updateSlide,
  container,
  height,
  width,
  parseErrors,
  setWaitingStatus,
  hasFormErrors,
  slideErrors,
  setSlideErrors,
  placeholder,
  onInitialUpload,
  setImageType,
  setImageFileName,
}: {
  placeholder?: string;
  picture: ImageItem;
  updateSlide: (picture: ImageItem) => void;
  container: string;
  height?: string;
  width?: string;
  parseErrors?: (response: APIErrorResponse) => void;
  setWaitingStatus?: (isWaiting: boolean) => void;
  hasFormErrors: boolean;
  slideErrors: string[];
  setSlideErrors: (errors: string[]) => void;
  onInitialUpload?: (imageUrl: string) => void;
  setImageType?: (imageType: string) => void;
  setImageFileName?: (imageFileName: string) => void;
}) => {
  const setImage = (image: string | null) => {
    updateSlide({
      type: picture.type,
      url: image,
    });
  };

  return (
    <Container key={`picture-#${picture.id}`}>
      <AutoDragAndDrop
        placeholder={placeholder}
        imageUrl={picture.url}
        setImageUrl={setImage}
        container={container}
        height={height}
        width={width}
        parseErrors={parseErrors}
        setWaitingStatus={setWaitingStatus}
        slideErrors={slideErrors}
        setSlideErrors={setSlideErrors}
        hasFormErrors={hasFormErrors}
        fixedRatio
        isSmall
        withCropAndZoom={true}
        onInitialUpload={onInitialUpload}
        setImageType={setImageType}
        setImageFileName={setImageFileName}
      />
    </Container>
  );
};

export default SmallPicture;
