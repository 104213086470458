import React, { useCallback, useMemo } from "react";
import Select, { SelectItem } from "components/forms/Select";
import styled from "style/styled-components";
import { BusinessBranch } from "components/chat/types";
import strings from "localisation/strings";

interface Props {
  branchesList: BusinessBranch[];
  selectedBranchId: string;
  onBranchSelected: (businessProfileId: string) => void;
}

const BusinessBranchFilterContainer = styled.header`
  padding: ${({ theme }) => `${theme.margin.large} ${theme.margin.large}`};
  z-index: ${({ theme }) => theme.layers.outsideFixedAreaDropdown};
  .business-branch-filter__control {
    height: 40px;
    border-radius: ${({ theme }) => theme.border.radius.small};
    border-color: ${({ theme }) => theme.color.border.inputBorder};
  }
  .business-branch-filter__menu {
    top: 25px;
  }
`;

const ConversationBusinessFilter = ({
  branchesList,
  selectedBranchId,
  onBranchSelected,
}: Props) => {
  const options = useMemo(
    () =>
      branchesList.map(({ businessProfileName, businessProfileId }) => ({
        label: businessProfileName,
        value: businessProfileId,
      })),
    [branchesList],
  );
  const selectedOption = useMemo(
    () => options.find(option => option.value === selectedBranchId),
    [options, selectedBranchId],
  );
  const handleOptionSelected = useCallback(
    (option: SelectItem) => {
      onBranchSelected(option.value as string);
    },
    [onBranchSelected],
  );

  return (
    <BusinessBranchFilterContainer>
      <Select
        value={selectedOption}
        onChange={handleOptionSelected}
        options={options}
        classNamePrefix={"business-branch-filter"}
        placeholder={strings("chat.branchFilterPlaceholder")}
      />
    </BusinessBranchFilterContainer>
  );
};
export default ConversationBusinessFilter;
