import React, { useEffect, useState } from "react";
import styled from "style/styled-components";
import TagInterface from "api/models/TagInterface";
import { TertiaryTag } from "components/generic";
import useWindowDimensions from "hooks/useWindowDimensions";
import { THREE_LINE_TAGS_MAX_DEPTH } from "constants/ListItemConstants";

const TagContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
`;

const More = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.color.background.quinary};
  font-size: ${({ theme }) => theme.font.size.xlarge};
  line-height: 24px;
`;

export interface TagListProps {
  tags?: TagInterface[];
  className?: string;
  maxDepthFromTop?: number;
}

interface OverflowableTag extends TagInterface {
  overflow: boolean;
}

const TagList = ({ tags, className, maxDepthFromTop }: TagListProps) => {
  const [overflowable, setOverflowable] = useState(
    tags ? tags.map(tag => ({ ...tag, overflow: false })) : [],
  );
  const { width } = useWindowDimensions();

  useEffect(() => {
    setOverflowable(prevState =>
      prevState.map(tag => ({ ...tag, overflow: false })),
    );
  }, [width]);

  const handleOverflow = (isOverflow: boolean, index: number) => {
    const currentStatus = overflowable[index].overflow;
    if (currentStatus !== isOverflow) {
      setOverflowable(prevState =>
        prevState.map((tag, idx) =>
          idx === index ? { ...tag, overflow: isOverflow } : tag,
        ),
      );
    }
  };

  const lastNotOverflowingIndex = overflowable.findIndex(el => el.overflow) - 1;

  const renderingLogic = (tag: OverflowableTag, index: number) => {
    if (!tag.overflow) {
      if (index === lastNotOverflowingIndex) {
        return <More>...</More>;
      }
      return (
        <TertiaryTag
          key={tag.id}
          onOverflow={isOverflow => handleOverflow(isOverflow, index)}
          maxDepthFromTop={
            maxDepthFromTop ? maxDepthFromTop : THREE_LINE_TAGS_MAX_DEPTH
          }
        >
          {tag.name || tag.shortName}
        </TertiaryTag>
      );
    }
  };

  return (
    <TagContainer className={className}>
      {overflowable.map(renderingLogic)}
    </TagContainer>
  );
};

export default TagList;
