import React from "react";
import { RegisterPayload } from "api/models/UsersInterface";
import { postRegister } from "api/session";
import strings from "localisation/strings";
import SubmitButton from "components/forms/SubmitButton";
import APIErrorResponse from "common/api/models/APIErrorResponse";

interface RegisterButtonProps {
  style?: object;
  getPayload: () => RegisterPayload | undefined;
  failedRequestCallback: (error: APIErrorResponse) => void;
  successfulRequestCallback: (response: object) => void;
  className?: string;
  disabled?: boolean;
}

const RegisterButton = (props: RegisterButtonProps) => (
  <SubmitButton<RegisterPayload, object>
    request={postRegister}
    text={strings("registrationScreen.submitForm")}
    loadingText={strings("buttons.states.requestInProgress")}
    {...props}
  />
);

export default RegisterButton;
