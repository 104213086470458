import React from "react";
import EventInterface from "api/models/EventInterface";
import BaseListItem from "components/lists/item/BaseListItem";
import { ListItemProps } from "components/lists/item/BaseListItemComponents";
import { TWO_LINE_TAGS_MAX_DEPTH } from "constants/ListItemConstants";
import PictureTypes from "api/models/PictureTypes";

export interface EventListItemProps extends ListItemProps {
  event: EventInterface;
}

const EventListItem = ({ event, onClick }: EventListItemProps) => {
  const {
    dateTimeRange,
    id,
    lastModifiedBy,
    location,
    modifiedDateTime,
    pictures,
    priceMinMax,
    status,
    title,
    tags,
  } = event;
  const image = pictures
    ? pictures.find(item => item.type === PictureTypes.main)
    : undefined;
  const imageUrl = image ? image.url : undefined;
  return (
    <BaseListItem
      dateTimeRange={dateTimeRange}
      id={id}
      imageUrl={imageUrl}
      lastModifiedBy={lastModifiedBy}
      location={location}
      modifiedDateTime={modifiedDateTime}
      onClick={onClick}
      priceMinMax={priceMinMax}
      status={status}
      tags={tags}
      title={title}
      contentType={"EVENT"}
      tagsMaxDepthFromTop={TWO_LINE_TAGS_MAX_DEPTH}
    />
  );
};

export default EventListItem;
