import React from "react";
import { useParams, withRouter } from "react-router-dom";
import { curry } from "ramda";
import styled from "style/styled-components";
import { deleteUser } from "api/session";
import QueryStatus from "common/api/models/QueryStatus";
import useQuery from "common/hooks/useQuery";
import {
  CenteredBlockTitle,
  CenteredContentViewContainer,
} from "components/generic";
import FormSubtitle from "components/forms/FormSubtitle";
import strings from "localisation/strings";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";

type Params = {
  token: string;
};

const NowrapBlockTitle = styled(CenteredBlockTitle)`
  white-space: nowrap;
  margin-bottom: 0;
`;

const RecoveryForm = styled.div`
  max-width: ${({ theme }) => theme.forms.small};
  margin: 20px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  ${inputContainerAutoZoomPrevetionStyles};
`;

const DeletionRequestConfirmed = () => {
  const { token } = useParams<Params>();

  const { status } = useQuery({ request: curry(deleteUser)(token) });
  const isLoading = status === QueryStatus.WAITING;

  if (isLoading) {
    return (
      <CenteredContentViewContainer>
        <NowrapBlockTitle>{strings("messages.loading")}</NowrapBlockTitle>
      </CenteredContentViewContainer>
    );
  }

  return (
    <CenteredContentViewContainer>
      <NowrapBlockTitle>
        {strings("deletionRequestConfirmedScreen.title")}
      </NowrapBlockTitle>
      <RecoveryForm>
        <FormSubtitle>
          {strings("deletionRequestConfirmedScreen.subtitle")}
        </FormSubtitle>
      </RecoveryForm>
    </CenteredContentViewContainer>
  );
};

export default withRouter(DeletionRequestConfirmed);
