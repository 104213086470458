import React, { ReactElement } from "react";
import { curry } from "ramda";
import { History } from "history";
import { Prompt, RouteComponentProps, withRouter } from "react-router";

import { mapTagsToIdArray } from "utils";
import useQuery from "common/hooks/useQuery";
import strings from "localisation/strings";

import PublicationStatuses from "api/models/PublicationStatuses";
import { ImprintInformation } from "api/models/BusinessUserProfileInterface";
import PrivilegedComponent from "components/PrivilegedComponent";
import { DetailsViewContainer as Container } from "components/generic/ViewContainer";
import CustomModal from "components/modal/CustomModal";
import { getContentStatus } from "components/helpers/formHelpers";
import { defaultBusinessUserProfileFields } from "components/helpers/formHelpers/businessUserProfile";
import useCustomModal from "components/modal/useCustomModal";
import CategoryQuestionInterface from "api/models/CategoryQuestionInterface";
import Picture from "api/models/Picture";

interface Props {
  privilegedId: string;
  form: ReactElement;
  copy: ReactElement;
  details: ReactElement;
  shouldBlockNavigation: boolean;
  isCopy: boolean;
  isNew: boolean;
  fullWidth?: boolean;
}

const DetailedView = (props: RouteComponentProps & Props) => {
  const {
    form,
    copy,
    details,
    shouldBlockNavigation,
    isCopy,
    isNew,
    history,
    fullWidth,
  } = props;

  const {
    closeModal,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    modalVisible,
  } = useCustomModal(history, shouldBlockNavigation);

  return (
    <>
      <Prompt when={shouldBlockNavigation} message={handleBlockedNavigation} />
      <CustomModal
        popupMessage={strings("buttons.unsavedFormText")}
        modalVisible={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
      <PrivilegedComponent id={props.privilegedId} isView>
        <Container fullWidth={fullWidth}>
          {isNew && form}
          {isCopy && copy}
          {!isNew && !isCopy && details}
        </Container>
      </PrivilegedComponent>
    </>
  );
};

export const Copy = ({
  id,
  success,
  changedValues,
  setBlockNavigation,
  form,
  getDetailsRequest,
  imprint,
  createNew,
  createDraft,
  isNewProfile,
  history,
  businessId,
}: {
  id: string;
  success: (message: string, response: any) => void;
  changedValues?: Set<string>;
  setBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  form: any;
  getDetailsRequest: any;
  imprint?: ImprintInformation;
  createNew: any;
  createDraft?: any;
  isNewProfile?: boolean;
  history?: History;
  businessId?: string;
}) => {
  // Getting the data from donor item
  const { result } = useQuery<any>({
    request: getDetailsRequest(id, {}),
  });

  let newResult = result;

  // Remove id, so new id can be set
  if (result) {
    if (isNewProfile && id) {
      newResult = defaultBusinessUserProfileFields;
      if (result.subTags) {
        newResult.businessTypeFields.subTags = mapTagsToIdArray(result.subTags);
      }
      if (result.tags) {
        newResult.businessTypeFields.tags = mapTagsToIdArray(result.tags);
      }
      if (result.imprintInformation) {
        newResult.contactFields.imprintInformation = result.imprintInformation;
      }
    }

    if (result.artists) {
      newResult.artists = result.artists.map(
        (artist: { id: string; name: string }) => ({ name: artist.name }),
      );
    }

    if (newResult.id) delete newResult.id;
    if (newResult.status) delete newResult.status;
    if (newResult.profileState) delete newResult.profileState;
    if (newResult.publishDateTime) delete newResult.publishDateTime;
    if (newResult.unpublishDateTime) delete newResult.unpublishDateTime;
    if (newResult.questions) {
      newResult.questions.forEach((item: CategoryQuestionInterface) => {
        if (item.id) delete item.id;
      });
    }

    // TODO: Currently we have problem in BE with saving locations with same ID
    // We don't know yet if we need to reuse the location for the new event entirely
    if (newResult.location && newResult.location.id) {
      delete newResult.location.id;
    }

    // Clear children object IDs
    if (newResult.imprintInformation) delete newResult.imprintInformation.id;
    if (newResult.contactInformation) delete newResult.contactInformation.id;
    if (newResult.businessHours) delete newResult.businessHours.id;
    if (newResult.pictures) {
      // clear IDs in pictures of copied business profile
      newResult.pictures.forEach((item: Picture) => {
        if (item.id) delete item.id;
      });
    }
  }

  if (!id && imprint) {
    newResult = defaultBusinessUserProfileFields;
    newResult.contactFields.imprintInformation = imprint;
  }

  return form({
    createNew,
    createDraft,
    success,
    changedValues,
    setBlockNavigation,
    isNewProfile,
    history,
    imprint,
    businessId,
    result: newResult,
  });
};

export const Details = ({
  id,
  questionId,
  update,
  success,
  changedValues,
  setBlockNavigation,
  form,
  getDetailsRequest,
  updateItem,
  updateDraftItem,
  isSubCategory,
  specialCompare,
  history,
  businessId,
  imprint,
}: {
  id: string;
  questionId?: string;
  update: any;
  success: (message: string, response: any) => void;
  changedValues?: Set<string>;
  setBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  form: any;
  getDetailsRequest: any;
  updateItem: any;
  updateDraftItem?: any;
  isSubCategory?: boolean;
  specialCompare?: any;
  history?: History;
  businessId?: string;
  imprint?: ImprintInformation;
}) => {
  const { result } = useQuery<any>({
    request: getDetailsRequest(id, {}),
    compare: specialCompare ? specialCompare : { id, update },
  });

  if (!result || id !== result.id) return null;

  const isDraft = getContentStatus(result) === PublicationStatuses.draft;

  return (
    <>
      {form({
        id,
        questionId,
        result,
        isDraft,
        success,
        changedValues,
        setBlockNavigation,
        isSubCategory,
        history,
        businessId,
        imprint,
        updateItem: curry(updateItem),
        updateDraftItem: updateDraftItem
          ? curry(updateDraftItem)
          : updateDraftItem,
      })}
    </>
  );
};

export default withRouter(DetailedView);
