import React, { ReactNode } from "react";
import styled from "style/styled-components";

const WrappingRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -${({ theme }) => theme.margin.small};
  overflow: hidden;
`;

const FlexLineFiller = styled.div`
  height: 0;
  width: 75px;
  flex-grow: 10000;
`;

interface WrappingMultiSelectRowProps {
  children: ReactNode;
  className?: string;
}

const WrappingMultiSelectRow = ({
  children,
  className,
}: WrappingMultiSelectRowProps) => (
  <WrappingRow className={className}>
    {children}
    <FlexLineFiller />
  </WrappingRow>
);

export default WrappingMultiSelectRow;
