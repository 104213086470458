import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider as StateProvider } from "unstated";
import { ThemeProvider } from "style/styled-components";
import GlobalStyle from "style/GlobalStyle";
import theme from "style/theme";
import localeState from "state/singletons/localeState";
import notificationState from "state/singletons/notificationState";
import Routes from "core/Routes";
import GlobalNotification from "core/GlobalNotification";
import Header from "components/topBar/Header";
import ContentScrollPreventer from "components/helpers/ContentScrollPreventer";
import ScrollToTopOnPathChange from "components/helpers/ScrollToTopOnPathChange";
import Footer from "components/footer/Footer";
import styled from "styled-components";
import PullToRefreshPreventer from "components/helpers/PullToRefreshPreventer";
import TotalUnreadCountChangeListener from "components/chat/TotalUnreadCountChangeListener";
import useLoginState from "hooks/useLoginState";
import GlobalFormNavigationExitPrompt from "core/GlobalFormNavigationExitPrompt";

const StretchedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /*
   * Do not allow the content to shrink when content container height is reduced (i.e. scroll preventer is activated) 
  */
  flex-shrink: 0;

  /* 
    IE10+ CSS to fix an IE bug that causes flex box overflow. Flex grow kept at 1 to keep footer at the bottom of viewport
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 auto;
  }
`;

const App = () => {
  // Dont display anything until the loginState is restored(has to be done upon page refresh)
  const [isLoginRestored, setLoginRestored] = useState(false);
  const loginState = useLoginState();
  loginState.isRestored.then(() => setLoginRestored(true));
  return isLoginRestored ? (
    <StateProvider inject={[localeState, loginState, notificationState]}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTopOnPathChange>
            <PullToRefreshPreventer>
              <ContentScrollPreventer>
                <Header />
                <StretchedContentWrapper>
                  <Routes />
                </StretchedContentWrapper>
                <Footer />
                <GlobalFormNavigationExitPrompt />
              </ContentScrollPreventer>
            </PullToRefreshPreventer>
          </ScrollToTopOnPathChange>
          <GlobalNotification />
        </BrowserRouter>
      </ThemeProvider>
      <TotalUnreadCountChangeListener />
    </StateProvider>
  ) : null;
};

export default App;
