import styled from "style/styled-components";
import { Link } from "react-router-dom";
import React from "react";
import globalTheme from "style/theme";
import { Media } from "components/generic";

const Logo = styled(globalTheme.icons.Logo)`
  display: block;
  width: 92px;
  height: 25px;
`;

const MobileLogo = styled(globalTheme.icons.MobileLogo)`
  display: block;
  width: 24px;
  height: 24px;
`;

const SecondaryBanner = styled(globalTheme.icons.SecondaryBanner)`
  display: block;
  width: 92px;
  height: 25px;
  margin-left: 10px;
`;

const NavLogo = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.color.foreground.antiPrimary};
  text-decoration: none;
`;

const AppLogoContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.margin.large};
  margin-right: 20px;
  align-items: center;
`;

export interface AppLogoProps {
  link: string;
}

const AppLogo = ({ link }: AppLogoProps) => (
  <AppLogoContainer>
    <NavLogo to={link}>
      <Media desktop>
        <Logo />
      </Media>
      <Media tablet phone>
        <MobileLogo />
      </Media>
    </NavLogo>
    <SecondaryBanner />
  </AppLogoContainer>
);

export default AppLogo;
