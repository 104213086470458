import React, { ReactNode, HTMLAttributes } from "react";
import styled, { css } from "style/styled-components";
import SpreadRow from "components/generic/SpreadRow";
import { NavLink, NavLinkProps } from "react-router-dom";
import breakpoints from "style/breakpoints";

interface ButtonHeightProp {
  buttonheight?: number;
}

interface UnderlineButtonSharedProps extends ButtonHeightProp {
  activeClassName: string;
  color?: string;
}

interface UnderlineButtonAreaProps extends ButtonHeightProp {
  children: ReactNode;
  className?: string;
  rightAlignedChildren?: ReactNode;
}

const hiddenAreaHeight = 50;
const defaultButtonHeight = 48;

const underlineActiveStyle = css`
  font-weight: 600;
  &::before {
    left: 0;
    right: 0;
  }
`;

const underlineDisabledStyle = css`
  font-weight: normal;
  &::before {
    left: 51%; /* IE needs more to fix subpixel */
    right: 50%;
  }
`;

const UnderlineButtonRowScrollHider = styled.div<ButtonHeightProp>`
  height: ${({ buttonheight }) =>
    buttonheight ? buttonheight : defaultButtonHeight}px;
  overflow: hidden;
  position: relative;
  flex: 1 0 auto;
`;

const UnderlineButtonRowParent = styled.div<ButtonHeightProp>`
  position: relative;
  height: ${({ buttonheight }) =>
    buttonheight ? buttonheight : defaultButtonHeight}px;
  box-sizing: content-box; /* necessary for the scroll hider */
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: ${hiddenAreaHeight}px;
`;

const UnderlineButtonRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box; /* restore proper box sizing for all children as it's inherited due to global style */
`;

const UnderlineButtonWrapper = styled.a<Partial<UnderlineButtonSharedProps>>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 20px;
  line-height: ${({ buttonheight }) =>
    buttonheight ? buttonheight : defaultButtonHeight}px;
  color: ${({ theme, color }) =>
    color ? color : theme.color.font.onLightBackground};

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: ${({ theme }) => theme.color.foreground.action};
    transition: left 0.2s ease-in-out, right 0.2s ease-in-out,
      font-weight 0.2s ease-in-out;
  }

  ${underlineDisabledStyle};

  &.${({ activeClassName }) => activeClassName} {
    ${underlineActiveStyle};
  }

  &:hover {
    ${underlineActiveStyle};
  }

  ${UnderlineButtonRow}:hover &:not(:hover) {
    ${underlineDisabledStyle};
  }

  &.new-post {
    display: none;
  }
  @media ${breakpoints.phoneMax} {
    &.dashboard {
      display: none;
    }
    &.new-post {
      display: inherit;
    }
  }
`;

const UnderlineNavLinkWrapper = UnderlineButtonWrapper.withComponent(NavLink);

const RoomReserverSpan = styled.span`
  opacity: 0;
  font-weight: 600;
`;

const ActualLabel = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
`;

const UnderlineButton = ({
  children,
  ...props
}: HTMLAttributes<HTMLAnchorElement> & UnderlineButtonSharedProps) => (
  <UnderlineButtonWrapper {...props}>
    <RoomReserverSpan>{children}</RoomReserverSpan>
    <ActualLabel>{children}</ActualLabel>
  </UnderlineButtonWrapper>
);

const UnderlineNavLink = ({
  children,
  ...props
}: NavLinkProps & UnderlineButtonSharedProps) => (
  <UnderlineNavLinkWrapper {...props}>
    <RoomReserverSpan>{children}</RoomReserverSpan>
    <ActualLabel>{children}</ActualLabel>
  </UnderlineNavLinkWrapper>
);

const UnderlineButtonArea = ({
  children,
  className,
  buttonheight,
  rightAlignedChildren,
}: UnderlineButtonAreaProps) => (
  <UnderlineButtonRowScrollHider
    className={className}
    buttonheight={buttonheight}
  >
    <UnderlineButtonRowParent buttonheight={buttonheight}>
      <SpreadRow>
        <UnderlineButtonRow data-role="nav-area">{children}</UnderlineButtonRow>
        {rightAlignedChildren}
      </SpreadRow>
    </UnderlineButtonRowParent>
  </UnderlineButtonRowScrollHider>
);

const UnderlineButtonAreaWithThinLine = styled(UnderlineButtonArea)`
  &::before {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.color.background.quaternary};
    z-index: 1;
  }
`;

export {
  UnderlineButtonArea,
  UnderlineButtonAreaWithThinLine,
  UnderlineNavLink,
};

export default UnderlineButton;
