import React from "react";
import styled, { isMobile } from "style/styled-components";
import useWindowDimensions from "hooks/useWindowDimensions";
import strings from "localisation/strings";
import DateTimeRange from "api/models/DateTimeRange";
import DashboardDateRange from "components/forms/DashboardDateRange";
import ActionButton from "components/buttons/ActionButton";
import { HorizontalSmallSpacer, Headline } from "components/generic";

interface Props {
  dateTimeRange: DateTimeRange;
  setDateTimeRange: (newDateRange: DateTimeRange) => void;
  onClick: () => void;
}

const HeaderContainer = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  ${({ isMobileDevice, theme }) =>
    isMobileDevice
      ? "flex-direction: column;"
      : `
      justify-content: space-between;
      border-bottom: 1px solid ${theme.color.border.medium};
    `}
  padding: ${({ theme }) => theme.margin.large};
`;

const TitleContainer = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ isMobileDevice, theme }) =>
    isMobileDevice ? `margin-bottom: ${theme.margin.medium};` : ""}
`;

const DateFilterContainter = styled.div<{
  isMobileDevice: boolean;
  isWideEnoughToPutEverythingInARow: boolean;
}>`
  display: flex;
  ${({ isMobileDevice, isWideEnoughToPutEverythingInARow }) =>
    isMobileDevice
      ? isWideEnoughToPutEverythingInARow
        ? `
          flex-direction: row;
          justify-content: flex-end;
          `
        : "flex-direction: column;"
      : "flex-direction: row"}
`;

const ActionButtonContainer = styled.div<{ isMobileDevice: boolean }>`
  ${({ isMobileDevice }) =>
    isMobileDevice
      ? `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      `
      : ""}
`;

const ConfirmButton = styled(ActionButton)`
  max-width: 128px;
`;

const DashboardHeader = ({
  dateTimeRange,
  setDateTimeRange,
  onClick,
}: Props) => {
  const screenWidth = useWindowDimensions().width;
  const isMobileDevice = isMobile(screenWidth);
  const isWideEnoughToPutEverythingInARow = screenWidth > 466;

  return (
    <HeaderContainer isMobileDevice={isMobileDevice}>
      <TitleContainer isMobileDevice={isMobileDevice}>
        <Headline>{strings("dashboardScreen.title")}</Headline>
      </TitleContainer>
      <DateFilterContainter
        isMobileDevice={isMobileDevice}
        isWideEnoughToPutEverythingInARow={isWideEnoughToPutEverythingInARow}
      >
        <DashboardDateRange
          dateTimeRange={dateTimeRange}
          onDateRangeChange={setDateTimeRange}
        />
        <HorizontalSmallSpacer />
        <ActionButtonContainer isMobileDevice={isMobileDevice}>
          <ConfirmButton
            text={strings("buttons.confirm")}
            special="publish"
            onClick={onClick}
          />
        </ActionButtonContainer>
      </DateFilterContainter>
    </HeaderContainer>
  );
};

export default DashboardHeader;
