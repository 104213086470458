import React, { ReactNode } from "react";
import styled from "style/styled-components";
import { range, intersperse } from "ramda";
import StatsRatingStar from "components/review/StatsRatingStar";

const FlexLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: ${({ theme }) => theme.margin.small};
`;

const Spacer = styled.div`
  width: ${({ theme }) => theme.margin.small};
`;

const addSeparator = (
  separator: (index: number) => ReactNode,
  list: ((index: number) => ReactNode)[],
) => {
  return intersperse(separator, list).map((item, index) => item(index));
};

const getStarProgress = (starNumber: number, rating: number) => {
  const flooredRating = Math.floor(rating);
  let progress = starNumber <= flooredRating ? 1 : 0;
  if (starNumber === flooredRating + 1) {
    progress = flooredRating === 0 ? rating : rating % flooredRating;
  }
  return progress;
};

const StatsRatingStars = ({
  rating,
  className,
}: {
  rating: number;
  className?: string;
}) => (
  <FlexLine className={className}>
    {addSeparator(
      index => (
        <Spacer key={index} />
      ),
      range(1, 6).map(starNumber => {
        // eslint-disable-next-line react/display-name
        return (index: number) => (
          <StatsRatingStar
            key={index}
            progress={getStarProgress(starNumber, rating)}
          />
        );
      }),
    )}
  </FlexLine>
);

export default StatsRatingStars;
