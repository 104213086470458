import React from "react";
import { ReactComponent as CardIcon } from "assets/icons/plans.svg";
import { ReactComponent as BankIcon } from "assets/icons/account-balance.svg";
import styled from "style/styled-components";
import strings from "localisation/strings";
import { PaymentDataResponseDTO } from "api/models/Payments";

const cardIconAttrs = {
  width: 18,
  height: 18,
};

const PaymentListBankIcon = styled(BankIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentListCardIcon = styled(CardIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentMethodListItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.medium};
  padding-bottom: ${({ theme }) => theme.margin.large};
  padding-top: ${({ theme }) => theme.margin.large};
`;

const PaymentMethodListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.margin.large};
`;

const CardDetailsContainer = styled.div<{ expand?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ expand }) => expand && "flex-grow: 1;"}
  margin-right: ${({ theme }) => theme.margin.large};
`;

interface InfoTextProps {
  type?: "success" | "error" | "info";
  bold?: boolean;
  stacked?: boolean;
}

const CardInfoText = styled.span<InfoTextProps>`
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ theme, type = "info" }) =>
      ({
        success: theme.color.foreground.success,
        error: theme.color.foreground.error,
        info: theme.color.foreground.primary,
      }[type])}
    ${({ theme, stacked }) => stacked && `margin-top: ${theme.margin.xsmall};`};
`;

const ExpiredCardOverlay = styled.div`
  position: absolute;
  background: white;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface PaymentMethodListItemProps {
  data: PaymentDataResponseDTO;
}

const PaymentMethodListItem = ({ data }: PaymentMethodListItemProps) => {
  const {
    cardExpireDate,
    cardExpired,
    maskedIban,
    truncatedCardPan,
    owner,
    paymentType,
  } = data;
  return (
    <PaymentMethodListItemContainer>
      {paymentType === "CREDIT_CARD" && <PaymentListCardIcon />}
      {paymentType === "SEPA" && <PaymentListBankIcon />}

      <CardDetailsContainer expand>
        {paymentType === "SEPA" && (
          <CardInfoText>
            {strings("businessSettings.payment.bankAccountLabel", {
              iban: maskedIban,
            })}
          </CardInfoText>
        )}
        {paymentType === "CREDIT_CARD" && (
          <CardInfoText>
            {strings("businessSettings.payment.creditCardLabel", {
              iban: truncatedCardPan,
            })}
          </CardInfoText>
        )}
        {cardExpireDate && (
          <CardInfoText stacked>
            {strings("businessSettings.payment.expiryLabel", {
              expiry: cardExpireDate,
            })}
          </CardInfoText>
        )}
        {owner && <CardInfoText stacked>{owner}</CardInfoText>}
      </CardDetailsContainer>

      {cardExpired && (
        <CardDetailsContainer>
          <CardInfoText bold type="info">
            {strings("businessSettings.payment.expiredIndicator")}
          </CardInfoText>
        </CardDetailsContainer>
      )}

      {cardExpired && <ExpiredCardOverlay />}
    </PaymentMethodListItemContainer>
  );
};

interface Props {
  paymentMethods: PaymentDataResponseDTO[];
  onCreatePaymentRequested: () => void;
  onPaymentMethodDeleted: (method: PaymentDataResponseDTO) => void;
  onPaymentMethodMadeDefault: (method: PaymentDataResponseDTO) => void;
}

const PaymentMethodsList = ({ paymentMethods }: Props) => {
  return (
    <>
      <PaymentMethodListContainer>
        {paymentMethods
          .filter(({ paymentType }) => paymentType === "SEPA")
          .map(method => (
            <PaymentMethodListItem key={method.paymentId} data={method} />
          ))}
      </PaymentMethodListContainer>
    </>
  );
};

export default PaymentMethodsList;
