import React from "react";
import strings from "localisation/strings";
import InlineInput, {
  InlineInputProps,
} from "components/forms/FormFields/InlineInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import locationIcon from "assets/icons/location.svg";

interface AddressSearchBarProps extends InlineInputProps {
  errors: string[];
}

const SearchBar = ({ errors, ...inputProps }: AddressSearchBarProps) => (
  <InlineInputGroup
    icon={locationIcon}
    placeholder={strings("detailedScreen.location.address")}
  >
    <InlineInput
      type="text"
      errors={errors}
      placeholder={strings("detailedScreen.locationPlaceholder")}
      {...inputProps}
    />
  </InlineInputGroup>
);

export default SearchBar;
