import { AddressLocation } from "api/models/Location";
import { ReactComponent as MarkerIcon } from "assets/icons/Bliggit_eyedown.svg";
import useLocale from "common/hooks/useLocale";
import FieldErrors from "components/forms/FieldErrors";
import usePinLocationOnMap from "components/forms/FormFields/AddressAutocomplete/usePinLocationOnMap";
import GoogleMapReact, {
  BootstrapURLKeys,
  ClickEventValue,
  Coords,
  MapOptions,
} from "google-map-react";
import React, { useEffect, useState } from "react";
import styled from "style/styled-components";
import { noop } from "utils";

const WuppertalLocation: Coords = {
  lat: 51.255,
  lng: 7.15,
};

const DefaultMapConfigurations = {
  center: WuppertalLocation,
  zoom: 12,
};

const ErrorContainer = styled.div`
  width: 100%;
`;

const MapViewContainer = styled.div`
  width: ${({ theme }) => theme.forms.full};
  height: ${({ theme }) => theme.forms.xlarge};
  border-radius: ${({ theme }) => theme.components.inlineInput.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border.inputBorder};
  margin: 10px 0 20px;
`;

const StyledIcon = styled(MarkerIcon)`
  width: 40px;
  height: 40px;
  position: absolute;
  transform: translate(-50%, -100%);
  fill: ${({ theme }) => theme.color.foreground.secondaryDark};
  path {
    fill: ${({ theme }) => theme.color.foreground.secondaryDark};
  }
`;

// Coords input is required to be present by `google-map-react` library
const Marker = (coords: Coords) => <StyledIcon />;

interface Props {
  onPinLocation: (location: AddressLocation) => void;
  location?: AddressLocation;
  errors?: string[];
}

const PinnableMapContainer = ({ onPinLocation, location, errors }: Props) => {
  const {
    pinAddressOnMap,
    getPlaceDetailsByCoordinates,
    isGoogleMapsApisLoaded,
  } = usePinLocationOnMap({ onPinLocation });

  const locale = useLocale();
  const [markerPosition, setMarkerPosition] = useState<Coords | undefined>(
    undefined,
  );
  const [zoomLevel, setZoomLevel] = useState(DefaultMapConfigurations.zoom);

  useEffect(() => {
    if (
      location &&
      location.pinned &&
      location.pinned.toString() === "true" &&
      location.latitude &&
      location.longitude
    ) {
      const lat = Number(location.latitude);
      const lng = Number(location.longitude);
      setMarkerPosition({ lat, lng });
      setZoomLevel(18);
    }
  }, [location]);

  const GoogleMapOptions: MapOptions = {
    fullscreenControl: false,
  };

  const handleMapOnClick = (value: ClickEventValue) => {
    setMarkerPosition(value);
    const coords = new google.maps.LatLng(value.lat, value.lng, false);
    getPlaceDetailsByCoordinates(coords)
      .then(result => pinAddressOnMap(result, coords))
      .catch(noop);
  };

  const bootstrapURLKeys = (): BootstrapURLKeys => {
    return {
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
      language: locale,
      libraries: ["places"],
    };
  };

  const getDefaultCenter = () =>
    markerPosition || DefaultMapConfigurations.center;

  return (
    <MapViewContainer>
      {isGoogleMapsApisLoaded && (
        <GoogleMapReact
          bootstrapURLKeys={bootstrapURLKeys()}
          defaultCenter={getDefaultCenter()}
          defaultZoom={zoomLevel}
          options={GoogleMapOptions}
          onClick={handleMapOnClick}
        >
          {markerPosition && <Marker {...markerPosition} />}
        </GoogleMapReact>
      )}
      {errors && (
        <ErrorContainer>
          <FieldErrors errors={errors} />
        </ErrorContainer>
      )}
    </MapViewContainer>
  );
};

export default PinnableMapContainer;
