import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
} from "api/models/ReviewInterface";

interface Values {
  comment: string;
}

export const defaultReplyToReviewFields = {
  textAreaFields: {
    comment: "",
  },
};

export const setReplyFields = (reply: Partial<ReplyDetailsInterface>) => {
  const { comment = "" } = reply;

  return {
    textAreaFields: {
      comment,
    },
  };
};

export const getReplyPayload = (
  values: Values,
): ReviewReplyPayloadInterface => {
  const { comment } = values;

  return {
    comment,
  };
};
