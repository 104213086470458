import React from "react";
import { RouteComponentProps } from "react-router";
import { curry } from "ramda";
import strings from "localisation/strings";

import ListResultType from "api/models/ListResultType";
import OfferInterface from "api/models/OfferInterface";
import {
  getOffers,
  getCuratorPostsFromBusiness,
  getOffersFilters,
} from "api/offers";

import { SearchRequest } from "components/lists/ListRequestTypes";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import FilterableList from "components/lists/FilterableList";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import OfferList from "components/lists/offerList/OfferList";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { NEW } from "constants/strings";
import { listTestId } from "testing/testId";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import ListTableView from "views/ListTableView";
import useLoginState from "hooks/useLoginState";

const offerListRequest: SearchRequest<ListResultType<OfferInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getOffers)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
    {},
  );

const postOffersFiltersRequest: FiltersRequestType = ({ filters }) =>
  curry(getOffersFilters)({ filters }, undefined);

const Offers = ({ location, history, match }: RouteComponentProps) => {
  const onClick = (id: string) => history.push(`angebote/${id}`);

  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();

  const offerListRequestCurator: SearchRequest<ListResultType<
    OfferInterface
  >> = ({ offset, limit, body, q }) =>
    curry(getCuratorPostsFromBusiness)(
      { q, filterReq: body, from: offset, size: limit },
      businessId,
      {},
    );

  const renderListView = ({
    items,
    onItemClick,
  }: InteractiveListInterface<OfferInterface>) => {
    return (
      <OfferList
        emptyIndicatable
        emptyIndicator={strings("listView.noContentFilterable")}
        offers={items}
        onItemClick={onItemClick}
      />
    );
  };

  if (getUserHasPrivilege(PrivilegedComponentsIds.VIEW_OFFERS_CURATOR)) {
    if (businessId) {
      return (
        <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_OFFERS} isView>
          <FilterableList<OfferInterface>
            onItemClick={onClick}
            location={location}
            history={history}
            match={match}
            pageTitle={strings("offersScreen.title")}
            filterPageTitle={strings("offersScreen.filterScreenTitle")}
            sortPageTitle={strings("sortScreen.title")}
            searchPageTitle={strings("searchScreen.title")}
            searchRequest={offerListRequestCurator}
            postFiltersRequest={postOffersFiltersRequest}
            onAddNew={() => onClick(NEW)}
            renderListView={renderListView}
            defaultSort={DEFAULT_SORT}
            redirectOnEmptyResult={true}
            {...listTestId("offers")}
          />
        </PrivilegedComponent>
      );
    }
    return (
      <PrivilegedComponent
        id={PrivilegedComponentsIds.VIEW_OFFERS_CURATOR}
        isView
      >
        <ListTableView<OfferInterface> />
      </PrivilegedComponent>
    );
  }

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_OFFERS} isView>
      <FilterableList<OfferInterface>
        onItemClick={onClick}
        location={location}
        history={history}
        match={match}
        pageTitle={strings("offersScreen.title")}
        filterPageTitle={strings("offersScreen.filterScreenTitle")}
        sortPageTitle={strings("sortScreen.title")}
        searchPageTitle={strings("searchScreen.title")}
        searchRequest={offerListRequest}
        postFiltersRequest={postOffersFiltersRequest}
        onAddNew={() => onClick(NEW)}
        renderListView={renderListView}
        defaultSort={DEFAULT_SORT}
        redirectOnEmptyResult={true}
        {...listTestId("offers")}
      />
    </PrivilegedComponent>
  );
};

export default Offers;
