import React from "react";
import { RouteComponentProps } from "react-router";
import { noop } from "utils";
import { curry } from "ramda";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import strings from "localisation/strings";
import PaymentMethodsList from "views/BusinessSettings/Payment/PaymentMethodsList";
import useQuery from "common/hooks/useQuery";
import { getAdminPaymentMethods } from "api/businessProfiles";
import QueryStatus from "common/api/models/QueryStatus";
import BusinessSettingsAdminSidebar from "views/BusinessSettings/BusinessSettingsAdminSidebar";
import PaymentMethodsConfig from "views/BusinessSettings/Payment/PaymentMethodsConfig";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";

interface AdminBusinessPaymentSettingsParams {
  businessId: string;
}

const BusinessPaymentSettingsAdmin = ({
  match: { params },
}: RouteComponentProps<AdminBusinessPaymentSettingsParams>) => {
  const { result: { data = [] } = { data: [] }, status } = useQuery({
    request: curry(getAdminPaymentMethods)(params.businessId),
    compare: [],
  });

  return (
    <BusinessSettingsLayout
      sidebar={<BusinessSettingsAdminSidebar />}
      title={strings("businessSettings.payment.title")}
      subtitle={strings("businessSettings.payment.comments")}
      isLoading={status === QueryStatus.WAITING}
    >
      {isPayoneIntegrationEnabled() ? (
        <PaymentMethodsConfig
          paymentMethods={data}
          onEditPaymentRequested={noop}
          onPaymentMethodDeleted={noop}
          onPaymentMethodMadeDefault={noop}
        />
      ) : (
        <PaymentMethodsList
          paymentMethods={data}
          onCreatePaymentRequested={noop}
          onPaymentMethodDeleted={noop}
          onPaymentMethodMadeDefault={noop}
        />
      )}
    </BusinessSettingsLayout>
  );
};

export default BusinessPaymentSettingsAdmin;
