import React, { useEffect, useState } from "react";
import { includes, curry } from "ramda";
import onClickOutside from "react-onclickoutside";

import strings from "localisation/strings";

import TagInterface from "api/models/TagInterface";
import CategoryStatuses from "api/models/CategoryStatuses";
import { getTagTree } from "api/resources";
import EntityTypes from "api/models/EntityTypes";
import QueryStatus from "common/api/models/QueryStatus";
import useQuery from "common/hooks/useQuery";

import { SelectionStateInterface } from "components/lists/filtering/SelectionStateInterface";
import TagList from "components/forms/DropdownTagList";
import FieldErrors from "components/forms/FieldErrors";
import GeneralTagSelectionField from "components/forms/TagSelection/GeneralTagSelectionField";
import {
  AddTag,
  DropdownContainer,
  DropdownWindow,
  SearchInput,
  SpacedCloseableTagButton,
  TagButtonArea,
  TagSelectionFieldContainer,
  TagSelectionTitle,
} from "components/forms/TagSelection/TagSelectionStyles";
import {
  isTagSelected as isTagSelectedUtility,
  toggleTag as toggleTagUtility,
} from "common/components/filtering/filterSelectUtils";
import {
  getTagIdPaths,
  reduceSelectedTags,
  reduceTagFilterToSelected,
} from "common/components/filtering/filterTransformUtils";
import {
  findSuggestions,
  getValue,
} from "components/helpers/formHelpers/tagSelection";
import useLoginState from "hooks/useLoginState";
import useNotificationState from "hooks/useNotification";
import {
  BusinessUserProfilesListResultType,
  getBusinessProfiles,
} from "api/businessProfiles";
import { SearchRequest } from "components/lists/ListRequestTypes";

const getTagTreeRequest = curry(getTagTree);

export interface CategorySelectProps {
  buttonText: string;
  title: string;
  type: string;
  tags?: TagInterface[];
  onTagChange?: (
    tags: TagInterface[],
    errorMessage: string,
    isFirstDataLoad?: boolean,
  ) => void;
  onTagsLoaded?: () => void;
  fieldErrors?: string[];
  setIsSavingBlocked?: (isSavingBlocked: boolean) => void;
  isBusinessProfile?: boolean;
  isPostForm?: boolean;
  isNew?: boolean;
  isCopied?: boolean;
  businessId?: string;
}

const TagSelectionField = ({
  buttonText,
  title,
  tags = [],
  type,
  onTagChange = () => {},
  onTagsLoaded = () => {},
  fieldErrors,
  setIsSavingBlocked,
  isBusinessProfile,
  isPostForm = false,
  isNew,
  isCopied,
  businessId,
}: CategorySelectProps) => {
  const loginState = useLoginState();
  const [open, setOpen] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(true);
  const [queryTimestamp, setQueryTimestamp] = useState(0);
  const toggleDropdown = () => setOpen(!open);
  const isCurator = loginState.hasCuratorRights();

  (TagSelectionField as any).handleClickOutside = () => setOpen(false);

  const { result: tagResult = [], error, status: tagFetchStatus } = useQuery({
    request: getTagTreeRequest(loginState.hasAdminRights(), {
      type,
      status: CategoryStatuses.active,
    }),
    compare: [queryTimestamp],
  });

  const { result: generalTagResult = [], status: generalTagStatus } = useQuery<
    TagInterface[]
  >({
    request: getTagTreeRequest(loginState.hasAdminRights(), {
      type: EntityTypes.general,
      status: CategoryStatuses.active,
    }),
    compare: [queryTimestamp],
  });
  const allTagResults = isBusinessProfile
    ? generalTagResult
    : tagResult.concat(generalTagResult);

  const selectionState: SelectionStateInterface = {
    selectedFilters: {},
    selectedTagFilters: reduceTagFilterToSelected(tags, allTagResults),
  };

  const toggleTag = (idPath: string[], errorMessage: string) => {
    const tagFilters = allTagResults;
    const toggleTagDayo = toggleTagUtility(idPath, selectionState);
    const selectedTagFilters = toggleTagDayo.selectedTagFilters;

    const reducedSelectedTags = reduceSelectedTags(
      tagFilters,
      selectedTagFilters,
    );

    onTagChange(reducedSelectedTags, errorMessage);

    setInputValue("");
  };

  const isTagSelected = (idPath: string[]) =>
    isTagSelectedUtility(idPath, selectionState);

  let hasTagFetchError = false;
  useEffect(() => {
    if (
      tagFetchStatus === QueryStatus.ERROR ||
      generalTagStatus === QueryStatus.ERROR
    ) {
      hasTagFetchError = true;
      setQueryTimestamp(Date.now());
    }
  }, [tagFetchStatus, generalTagStatus]);

  const { addErrorNotification } = useNotificationState();
  useEffect(() => {
    if (hasTagFetchError && error) {
      addErrorNotification(error.detail);
    }
  }, [error]);

  const hasTagFieldError = Boolean(fieldErrors && fieldErrors.length);
  const tagIdPaths = getTagIdPaths(selectionState.selectedTagFilters);

  const tagResultsWithoutGenerals: TagInterface[] = allTagResults.filter(
    result => !includes(result, generalTagResult),
  );

  const displayableTags = reduceSelectedTags(
    tagResultsWithoutGenerals,
    selectionState.selectedTagFilters,
  ).map(({ id, name }) => (
    <SpacedCloseableTagButton
      key={id}
      onClick={toggleDropdown}
      onClose={() =>
        toggleTag(
          tagIdPaths[id],
          strings("gneralTagSelector.noCategorySelectedErrorMessage"),
        )
      }
    >
      {name}
    </SpacedCloseableTagButton>
  ));

  const [inputValue, setInputValue] = useState<string>("");

  let value = getValue(inputValue);
  let length = value.trim().length;
  let suggestions = findSuggestions(tagResultsWithoutGenerals, value, length);
  const { hasAdminRights } = useLoginState();
  const isAdmin = hasAdminRights();

  useEffect(() => {
    value = getValue(inputValue);
    length = value.trim().length;
    suggestions = findSuggestions(tagResultsWithoutGenerals, value, length);
    if (tagFetchStatus === QueryStatus.SUCCESSFUL) {
      setHasSuggestions(suggestions.length > 0);
    }
  }, [inputValue]);

  const isDropDownWindowReady = open && !hasTagFetchError && hasSuggestions;

  const mainTagErrorMessageList =
    fieldErrors &&
    fieldErrors.filter(
      errorMessage =>
        errorMessage !== strings("gneralTagSelector.districtTagErrorMessage"),
    );
  const generalTagErrorMessage =
    fieldErrors &&
    fieldErrors.find(
      errorMessage =>
        errorMessage === strings("gneralTagSelector.districtTagErrorMessage"),
    );
  const categoryTagErrorMessage =
    fieldErrors &&
    fieldErrors.find(
      errorMessage =>
        errorMessage === strings("gneralTagSelector.districtTagErrorMessage"),
    );

  const getBusinessProfilesRequest: SearchRequest<BusinessUserProfilesListResultType> = ({
    offset,
    limit,
    body,
    q,
  }) =>
    curry(getBusinessProfiles)(
      { q, filterReq: body, from: offset, size: limit },
      businessId,
      isCurator,
      {},
    );

  const {
    result: primaryProfileResult = [],
    status: primaryProfileStatus,
  } = useQuery({
    request: getBusinessProfilesRequest({
      limit: 1,
      offset: 0,
      body: {
        sort: {
          sortRules: [
            {
              id: "createdDateTime",
              name: "createdDateTime",
              field: "createdDateTime",
              order: "asc",
            },
          ],
        },
      },
    }),
    compare: [businessId],
  });

  useEffect(() => {
    if (
      isNew &&
      !isCopied &&
      generalTagStatus === QueryStatus.SUCCESSFUL &&
      primaryProfileStatus === QueryStatus.SUCCESSFUL
    ) {
      // The request was successful, cast to the correct type
      const profile = primaryProfileResult as BusinessUserProfilesListResultType;

      // Only general tags are needed, also the parentId field needs to be set for each tag, otherwise the tags field can't be set
      const generalTagsWithParentId = profile.data[0].tags
        .filter(tag => tag.tagType === "GENERAL")
        .map(tag => {
          return {
            ...tag,
            parentId: generalTagResult
              .filter(generalTag =>
                generalTag.tagSuccessors
                  ? generalTag.tagSuccessors.filter(
                      tagSuccessor => tagSuccessor.id === tag.id,
                    ).length === 1
                  : false,
              )
              .map(generalTag => generalTag.id)[0],
          };
        });

      // Set the general tags
      if (generalTagsWithParentId.length) {
        onTagsLoaded();
        onTagChange(
          generalTagsWithParentId,
          strings("gneralTagSelector.districtTagErrorMessage"),
          true,
        );
      }
    }
  }, [generalTagStatus, primaryProfileStatus]);

  const checkIfShouldBeDisplayed = () => {
    if (!isBusinessProfile && !isPostForm) {
      return true;
    }
    if (isPostForm && isAdmin) {
      return true;
    }
    return false;
  };

  return (
    <>
      {checkIfShouldBeDisplayed() && (
        <>
          <TagSelectionFieldContainer>
            <TagSelectionTitle
              hasErrors={
                mainTagErrorMessageList && mainTagErrorMessageList.length > 0
              }
            >
              {title}
            </TagSelectionTitle>
            <DropdownContainer>
              <TagButtonArea>
                {displayableTags.length > 0 && displayableTags}
                {open ? (
                  <SearchInput
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    autoFocus={true}
                    placeholder={strings("autosizeInputPlaceholder")}
                  />
                ) : (
                  <AddTag
                    onClick={toggleDropdown}
                    hasErrors={
                      mainTagErrorMessageList &&
                      mainTagErrorMessageList.length > 0
                    }
                  >
                    {strings("categoryForm.addCategory")}
                  </AddTag>
                )}
              </TagButtonArea>
              {isDropDownWindowReady && (
                <DropdownWindow isOpened={open}>
                  <TagList
                    tagFilter={suggestions}
                    isTagSelected={isTagSelected}
                    toggleTag={toggleTag}
                  />
                </DropdownWindow>
              )}
            </DropdownContainer>
          </TagSelectionFieldContainer>
          {mainTagErrorMessageList && (
            <FieldErrors testId="tags" errors={mainTagErrorMessageList} />
          )}
        </>
      )}

      <GeneralTagSelectionField
        buttonText={buttonText}
        tags={tags}
        generalTagResult={generalTagResult}
        generalTagStatus={generalTagStatus}
        allTagResults={allTagResults}
        onTagChange={onTagChange}
        hasTagFetchError={hasTagFetchError}
        hasTagFieldError={hasTagFieldError}
        toggleTag={toggleTag}
        isTagSelected={isTagSelected}
        generalTagErrorMessage={
          isPostForm ? categoryTagErrorMessage : generalTagErrorMessage
        }
        setIsSavingBlocked={setIsSavingBlocked}
      />
    </>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => (TagSelectionField as any).handleClickOutside,
};

export default onClickOutside(TagSelectionField, clickOutsideConfig);
