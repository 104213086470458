import React, { useEffect } from "react";
import strings from "localisation/strings";
import useFormFields from "hooks/useFormFields";
import ActionButton from "components/buttons/ActionButton";
import ActionArea, { withBackButton } from "components/generic/ActionArea";
import FieldMargin from "components/forms/FieldMargin";
import Checkbox from "components/forms/Checkbox";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";

interface ActionAreaProps {
  isSubmittable: boolean;
  onSubmit: () => void;
}

const MandateConfirmationFormActionArea = withBackButton(
  ({ isSubmittable, onSubmit }: ActionAreaProps) => (
    <ActionArea>
      <ActionButton
        alone
        disabled={!isSubmittable}
        special="publish"
        text={strings("businessSettings.paymentMandate.form.submit.label")}
        onClick={onSubmit}
      />
    </ActionArea>
  ),
);

export interface MandateConfirmationFormValues {
  confirmation: boolean;
}

interface Props {
  errors?: APIErrorResponse;
  isSubmittable: boolean;
  onSubmit: (values: MandateConfirmationFormValues) => void;
  onFormLeft: () => void;
}

const MandateConfirmationForm = ({
  errors,
  onSubmit,
  onFormLeft,
  isSubmittable,
}: Props) => {
  const {
    getCheckboxFieldProps,
    values,
    parseErrors,
    getErrors,
  } = useFormFields({
    checkboxFields: {
      confirmation: false,
    },
    handleGenericErrors: true,
    translationScope: "businessSettings.paymentMandate.form",
  });

  useEffect(() => {
    return errors && parseErrors(errors);
  }, [errors]);

  const handleMandateConfirmationSubmitted = () => {
    onSubmit(values);
  };

  return (
    <>
      <FieldMargin>
        <Checkbox
          {...getCheckboxFieldProps("confirmation")}
          errors={getErrors("confirmation")}
        />
      </FieldMargin>

      <MandateConfirmationFormActionArea
        isActionAreaOneElement={true}
        isBackButtonEnabled={isPayoneIntegrationEnabled()}
        isSubmittable={isSubmittable}
        onBackButtonClicked={onFormLeft}
        onSubmit={handleMandateConfirmationSubmitted}
      />
    </>
  );
};

export default MandateConfirmationForm;
