import React, { ReactNode } from "react";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import { CloseButton } from "components/topBar/HeaderComponents";
import styled from "style/styled-components";
import Backdrop from "components/generic/Backdrop";

const Exit = styled(ExitIcon)`
  width: 24px;
  height: 24px;
`;

const Modal = styled.div<{ isBig?: boolean }>`
  width: ${({ isBig }) => (isBig ? 400 : 200)}px;
  border-radius: ${({ isBig }) => (isBig ? "13px 0 13px 13px" : "6px")};
  background-color: ${({ theme }) => theme.color.foreground.antiPrimary};
  border: 1px solid ${({ theme }) => theme.color.foreground.quaternary};
  color: ${({ theme }) => theme.color.foreground.primary};
  display: flex;
  flex-direction: column;
  padding: ${({ isBig }) => (isBig ? "20px 40px 25px" : "25px")};
  position: absolute;
  top: ${({ isBig }) => (isBig ? 75 : 90)}px;
  right: ${({ isBig }) => (isBig ? 130 : 15)}px;
  z-index: ${({ theme }) => theme.layers.modal};
  box-shadow: ${({ isBig }) =>
    isBig ? "0 2px 8px 0 rgba(0, 0, 0, 0.16)" : "none"};
`;

export interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  children?: ReactNode;
  showCloseButton?: boolean;
  isBig?: boolean;
  transparentBackdrop?: boolean;
  className?: string;
}

const ModalComponent = ({
  isVisible,
  showCloseButton,
  onClose,
  children,
  isBig,
  transparentBackdrop,
  className,
}: ModalProps) => (
  <>
    <Backdrop
      onClick={onClose}
      isActive={isVisible}
      transparent={transparentBackdrop}
    />
    {isVisible && (
      <Modal isBig={isBig} className={className}>
        {showCloseButton && (
          <CloseButton onClick={onClose}>
            <Exit />
          </CloseButton>
        )}
        {children}
      </Modal>
    )}
  </>
);

export default ModalComponent;
