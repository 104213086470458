import React, { useEffect } from "react";
import { curry } from "ramda";
import useQuery from "common/hooks/useQuery";
import useMutation from "common/hooks/useMutation";
import useLoginState from "hooks/useLoginState";
import UserPrivileges from "constants/UserPrivileges";
import { getGenericContent, updateGenericContent } from "api/resources";
import EditableRichText from "components/generic/richText/EditableRichText";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import strings from "localisation/strings";
import { noop, isFinished, isFailed } from "utils";
import useNotificationState from "hooks/useNotification";
import { editableRichTextTestId, richTextTestId } from "testing/testId";

export interface GenericRichTextContentProps {
  contentKey: string;
  onContentLoaded?: () => void;
  onContentFailedToLoad?: () => void;
}

const apiGetRequest = curry(getGenericContent);
const apiUpdateRequest = curry(updateGenericContent);

const GenericRichTextContent = ({
  contentKey,
  onContentLoaded = noop,
  onContentFailedToLoad = noop,
}: GenericRichTextContentProps) => {
  const loginState = useLoginState();
  const allowEditing = loginState
    .getUserPrivileges()
    .includes(UserPrivileges.CONFIG_WRITE);

  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const {
    makeRequest: updateRequest,
    status: updateStatus,
    error: updateError,
  } = useMutation(apiUpdateRequest);

  const handleSaveContent = (content: string) => {
    updateRequest({ content, key: contentKey });
  };

  useEffect(() => {
    if (isFinished(updateStatus)) {
      addSuccessNotification(strings("generic.success"));
      onContentLoaded();
    }

    if (isFailed(updateStatus)) {
      addErrorNotification(updateError && updateError.detail);
      onContentFailedToLoad();
    }
  }, [updateStatus]);

  const { result, status: fetchContentStatus } = useQuery({
    request: apiGetRequest(contentKey, allowEditing),
    compare: [contentKey, allowEditing],
  });

  useEffect(() => {
    if (isFinished(fetchContentStatus)) {
      onContentLoaded();
    }

    if (isFailed(fetchContentStatus)) {
      onContentFailedToLoad();
    }
  }, [fetchContentStatus]);

  if (!result) {
    return null;
  }

  return allowEditing ? (
    <EditableRichText
      value={result.content}
      onSave={handleSaveContent}
      {...editableRichTextTestId(contentKey)}
    />
  ) : (
    <RichTextRendering
      cozy
      value={result.content}
      {...richTextTestId(contentKey)}
    />
  );
};

export default GenericRichTextContent;
