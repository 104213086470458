import React from "react";
import strings from "localisation/strings";
import styled from "style/styled-components";

export interface RichTextProps {
  cozy?: boolean;
  value?: string;
  defaultValue?: string;
}

const HtmlRendering = styled.div`
  strong,
  b,
  strong *,
  b * {
    font-weight: bold;
  }

  em,
  i,
  em *,
  i * {
    font-style: italic;
  }

  h1 {
    color: ${({ theme }) => theme.color.font.onLightBackground};
    font-family: ${({ theme }) => theme.font.family.firaSans};
    font-weight: 800;
    font-size: ${({ theme }) => theme.font.size.xxlarge};
    line-height: 30px;
    margin: ${({ theme }) => theme.margin.xlarge} 0
      ${({ theme }) => theme.margin.standart};
  }

  h2 {
    font-family: ${({ theme }) => theme.font.family.firaSans};
    font-weight: 800;
    font-size: ${({ theme }) => theme.font.size.xlarge};
    color: ${({ theme }) => theme.color.font.onLightBackground};
  }

  h3 {
    font-family: ${({ theme }) => theme.font.family.firaSans};
    font-weight: 800;
    font-size: ${({ theme }) => theme.font.size.large};
    color: ${({ theme }) => theme.color.font.onLightBackground};
  }

  h1 strong,
  h2 strong,
  h3 strong,
  h1 b,
  h2 b,
  h3 b {
    font-weight: 900;
  }

  p,
  div {
    font-size: ${({ theme }) => theme.font.size.medium};
    color: ${({ theme }) => theme.color.font.onLightBackground};
    flex-shrink: 1;
    ${({ theme }) => `line-height: ${theme.font.lineHeight.cozy}`};
  }

  ul,
  ol {
    ${({ theme }) => `line-height: ${theme.font.lineHeight.cozy}`};
    margin-left: ${({ theme }) => theme.margin.xlarge};
  }

  a {
    color: ${({ theme }) => theme.color.foreground.action};
    text-decoration: none;
  }
`;

const RichTextRendering = ({
  value,
  defaultValue,
  ...props
}: RichTextProps) => (
  <HtmlRendering
    {...props}
    dangerouslySetInnerHTML={{
      __html: value || defaultValue || strings("richText.noContent"),
    }}
  />
);

export default RichTextRendering;
