import React, { useState } from "react";
import ButtonWithConfirmation from "components/buttons/ButtonWithConfirmation";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import InlineNumberInput from "components/forms/FormFields/InlineNumberInput";
import strings from "localisation/strings";
import { ReactComponent as EditIcon } from "assets/icons/edit2.svg";
import styled from "style/styled-components";

const EditButton = styled(EditIcon)`
  cursor: pointer;

  g path {
    fill: ${({ theme }) => theme.color.foreground.antiPrimary} !important;
  }
`;

interface ContentLimitEditButtonProps {
  onEdited: (updatedContentLimit: number) => void;
}

const ContentLimitEditButton = (props: ContentLimitEditButtonProps) => {
  const [limit, setLimit] = useState<number | null | undefined>();

  const handleConfirmed = () => {
    setLimit(undefined);
    props.onEdited(limit || 0); // make type error go away by falling back to 0
  };

  return (
    <ButtonWithConfirmation
      id="content-limit-edit-button"
      confirmationContent={
        <InlineInputGroup
          width="60%"
          placeholder={strings(
            "businessSettings.plan.fields.extraContentLimit.label",
          )}
        >
          <InlineNumberInput
            value={limit}
            isFormattingDisabled
            setValue={setLimit}
          />
        </InlineInputGroup>
      }
      submitButtonLabel={strings("buttons.confirm")}
      actionButton={<EditButton />}
      onClick={handleConfirmed}
    />
  );
};

export default ContentLimitEditButton;
