import React from "react";
import { range } from "ramda";
import strings from "localisation/strings";
import ReviewBar from "components/review/ReviewBar";
import { RatingStat } from "api/models/ReviewInterface";

interface RatingStatsBlockProps {
  ratingStats: RatingStat[];
}

const countTotalRatings = (ratingStats: RatingStat[]) =>
  ratingStats.reduce((acc, { count }) => acc + count, 0);

const sumTotalRatings = (ratingStats: RatingStat[]) =>
  ratingStats.reduce((acc, { rating, count }) => acc + rating * count, 0);

const calculatePercentageOfMaxRating = (ratingStats: RatingStat[]) => {
  const totalRatingCount = countTotalRatings(ratingStats);
  if (totalRatingCount === 0) {
    return 0;
  }
  return sumTotalRatings(ratingStats) / totalRatingCount;
};

const createFullRatingStats = (ratingStats: RatingStat[]) =>
  range(1, 6)
    .reverse()
    .map(rating => {
      const sameStat = ratingStats.find(
        ratingStat => ratingStat.rating === rating,
      );

      return { rating, count: sameStat ? sameStat.count : 0 };
    });

const RatingStatsBlock = ({ ratingStats }: RatingStatsBlockProps) => {
  const totalRatingsCount = countTotalRatings(ratingStats);

  if (totalRatingsCount === 0) {
    return null;
  }

  return (
    <>
      {createFullRatingStats(ratingStats).map(({ rating, count }) => (
        <ReviewBar
          key={rating}
          title={strings("reviewList.reviews.barTitle", { howMany: rating })}
          count={count}
          total={totalRatingsCount}
        />
      ))}
    </>
  );
};

export default RatingStatsBlock;
export {
  countTotalRatings,
  sumTotalRatings,
  calculatePercentageOfMaxRating,
  createFullRatingStats,
};
