import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";

import { BusinessHoursInterface } from "api/models/BusinessUserProfileInterface";

import TextAreaBlock from "components/forms/ValidatedFields/TextArea";
import DayOfTheWeekBlock from "components/forms/FormFields/DayOfTheWeekBlock";

export interface BusinessHoursProps {
  weekdays: string[];
  weekdayFieldErrors: Record<string, string[]>;
  labelText: string;
  businessHours: BusinessHoursInterface;
  changeBusinessHoursComment: (value: any) => void;
  businessHoursCommentLabel: string;
  toggleBusinessHoursWeekdayEnabled: (weekday: string) => void;
  changeBusinessHours: (value: BusinessHoursInterface) => void;
  changeBusinessHoursWeekdayStart: (weekday: string, startTime: string) => void;
  changeBusinessHoursWeekdayEnd: (weekday: string, endTime: string) => void;
  commentErrors?: string[];
}

const Label = styled.h2`
  font-weight: bold;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.family.firaSans};
`;
const Container = styled.div`
  padding-top: ${({ theme }) => theme.margin.large};

  div {
    align-items: flex-start;
  }
`;

const BusinessHours = ({
  weekdays,
  weekdayFieldErrors,
  commentErrors,
  labelText,
  businessHours,
  changeBusinessHoursComment,
  businessHoursCommentLabel,
  toggleBusinessHoursWeekdayEnabled,
  changeBusinessHours,
  changeBusinessHoursWeekdayStart,
  changeBusinessHoursWeekdayEnd,
}: BusinessHoursProps) => {
  const cloneDate = (startTime: string, endTime: string, enabled: boolean) => {
    const newValue: Partial<BusinessHoursInterface> = {};

    weekdays.forEach(weekday => {
      const enabledField = `${weekday}Enabled`;
      const startField = `${weekday}Start`;
      const endField = `${weekday}End`;

      newValue[startField] = startTime;
      newValue[endField] = endTime;
      newValue[enabledField] = enabled;
    });

    const newBusinessHours: BusinessHoursInterface = {
      ...businessHours,
      ...newValue,
    };

    changeBusinessHours(newBusinessHours);
  };

  return (
    <Container>
      <Label>{labelText}</Label>
      {weekdays.map(weekday => {
        const enabledField = `${weekday}Enabled`;
        const startField = `${weekday}Start`;
        const endField = `${weekday}End`;
        return (
          <DayOfTheWeekBlock
            key={`business-hours-${weekday}`}
            fieldErrors={weekdayFieldErrors[weekday]}
            dayOfTheWeek={strings(
              `businessUserProfileScreen.fields.businessHours.dayOfTheWeek.${weekday}`,
            )}
            enabled={businessHours[enabledField]}
            start={businessHours[startField]}
            end={businessHours[endField]}
            cloneDate={() => {
              cloneDate(
                businessHours[startField],
                businessHours[endField],
                businessHours[enabledField],
              );
            }}
            toggleBusinessHoursWeekdayEnabled={() =>
              toggleBusinessHoursWeekdayEnabled(weekday)
            }
            changeBusinessHoursWeekdayStart={value =>
              changeBusinessHoursWeekdayStart(weekday, value)
            }
            changeBusinessHoursWeekdayEnd={value =>
              changeBusinessHoursWeekdayEnd(weekday, value)
            }
          />
        );
      })}
      <InlineInputGroup
        placeholder={businessHoursCommentLabel}
        wrapText
        margin="0"
        additionalLabelTopPadding
      >
        <TextAreaBlock
          testId="business-hours"
          placeholder={strings(
            "businessUserProfileScreen.fields.businessHours.comment",
          )}
          value={businessHours.comment}
          onContentChange={changeBusinessHoursComment}
          errors={commentErrors}
          richText
        />
      </InlineInputGroup>
    </Container>
  );
};

export default BusinessHours;
