import styled from "style/styled-components";
import theme from "style/theme";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";

const PaymentsFormInlineInputGroup = styled(InlineInputGroup).attrs(props => ({
  width: props.width
    ? props.width
    : isPayoneIntegrationEnabled()
    ? theme.forms.planPriceWide
    : theme.forms.planPrice,
}))``;

export default PaymentsFormInlineInputGroup;
