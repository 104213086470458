import React from "react";
import styled from "style/styled-components";
import { QuickReplyOption, QuickReplyType } from "components/chat/types";
import InteractiveDateTimePicker from "components/chat/messagesList/interactiveItems/InteractiveDateTimePicker";
import InteractiveButtons from "components/chat/messagesList/interactiveItems/InteractiveButtons";

interface InteractiveItemProps {
  selectedValue: string | null;
  isDisabled: boolean;
  type: QuickReplyType;
  options: QuickReplyOption[];
  onInteractionFinished: (option: QuickReplyOption, value?: string) => void;
}

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-right: 60px;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.color.chat.message.background.left};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
  overflow: hidden;

  @media all and (-ms-high-contrast: none) {
    flex-basis: auto;
  }
`;

const InteractiveItem = (props: InteractiveItemProps) => {
  if (props.type === QuickReplyType.BUTTON) {
    return (
      <ColumnContainer>
        <InteractiveButtons
          options={props.options}
          isDisabled={props.isDisabled}
          selectedValue={props.selectedValue}
          onInteractionFinished={props.onInteractionFinished}
        />
      </ColumnContainer>
    );
  }

  if (
    props.type === QuickReplyType.DATE ||
    props.type === QuickReplyType.DATE_TIME
  ) {
    return (
      <ColumnContainer>
        <InteractiveDateTimePicker
          showTimeSelect={props.type === QuickReplyType.DATE_TIME}
          selectedValue={props.selectedValue}
          isDisabled={props.isDisabled}
          options={props.options}
          onInteractionFinished={props.onInteractionFinished}
        />
      </ColumnContainer>
    );
  }

  return null;
};

export default InteractiveItem;
