import React from "react";
import { WizardStepOneHeader } from "components/headers/WizardHeader";
import strings from "localisation/strings";
import WizardUserDataForm from "components/forms/UserDataForm/WizardUserForm";
import HeaderArea from "components/lists/header/HeaderArea";
import { CenteredContentViewContainer } from "components/generic/ViewContainer";
import { MarginlessBlockTitle } from "components/generic";

const WizardStepOne = () => {
  return (
    <>
      <WizardStepOneHeader />
      <CenteredContentViewContainer>
        <HeaderArea>
          <MarginlessBlockTitle>
            {strings("wizardScreen.header.businessAdministratorData")}
          </MarginlessBlockTitle>
        </HeaderArea>
        <WizardUserDataForm />
      </CenteredContentViewContainer>
    </>
  );
};

export default WizardStepOne;
