import { merge } from "lodash";
import baseTheme from "style/baseTheme";
import de from "localisation/locales/de.json";
import { Profile } from "profiles/types/Profile";
import { ProfileOverrides } from "profiles/types/ProfileOverrides";
import { ThemeInterface } from "style/types/ThemeInterface";
import { TranslationsInterface } from "localisation/types/TranslationsInterface";

export class AppProfile {
  private profile: Profile;

  constructor(profile: Profile) {
    if (!profile) {
      throw new Error("App profile not provided.");
    }

    this.profile = profile;
  }

  public getTheme(): ThemeInterface {
    const { theme } = this.getProfileOverrides();
    return merge(baseTheme, theme);
  }

  public getTranslations(): TranslationsInterface {
    const { locales } = this.getProfileOverrides();
    return {
      de: merge(de, locales.de),
    };
  }

  public getAppProfileName(): Profile {
    return this.profile;
  }

  private getProfileOverrides(): ProfileOverrides {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { profileOverrides } = require(`profiles/${this.profile}`);
    return profileOverrides;
  }
}

export const appProfile = new AppProfile(
  process.env.REACT_APP_APP_PROFILE as Profile,
);
