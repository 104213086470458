import React, { InputHTMLAttributes } from "react";
import { Omit } from "ramda";
import styled from "style/styled-components";
import FieldErrors from "components/forms/FieldErrors";
import Testable from "testing/Testable";
import { textInputTestId } from "testing/testId";

const Container = styled.div<{ width?: string | number }>`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  width: ${({ width }) => (width ? width : "100%")};
  align-items: flex-start;
`;

interface InputElementProps {
  isErrored?: boolean;
  isUnbranded?: boolean;
  disabled?: boolean;
}

const Input = styled.input<InputElementProps>`
  font-size: ${({ theme }) => theme.font.size.medium};
  width: 100%;
  height: ${({ theme }) => theme.formElements.text.height}px;
  border: 1px solid
    ${({ theme, isErrored }) =>
      isErrored
        ? theme.color.foreground.action
        : theme.color.border.inputBorder};
  border-radius: ${({ theme }) => theme.components.inlineInput.borderRadius};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.color.foreground.tertiary
      : theme.components.inlineInput.textColor};
  line-height: ${({ theme }) => theme.components.inlineInput.lineHeight};
  padding: ${({ theme }) => theme.components.inlineInput.padding};

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.color.foreground.tertiary};
    -webkit-text-fill-color: ${({ theme }) =>
      theme.color.foreground.tertiary}; /* required on iOS */
    opacity: 1; /* required on iOS */
  }
  &:focus {
    outline: none;
  }
  ${({ theme, isUnbranded }) =>
    isUnbranded
      ? `
        font-family: ${theme.font.family.unbranded};
      `
      : `
      &::placeholder {
        color: ${theme.color.foreground.tertiary};
      }`}
  // hide clear input icon for IE11
  ::-ms-clear {
    display: none;
  }
`;

const Label = styled.span`
  line-height: ${({ theme }) => theme.formElements.text.height}px;
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.components.inlineInput.textColor};
  white-space: nowrap;
  padding-right: 12px;
  padding-left: 8px;
`;

const InnerContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

interface NullableInputElement
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value">,
    Testable {
  value?: string | number | readonly string[] | null;
}

export interface InlineInputProps extends Partial<NullableInputElement> {
  showPlaceholderAsLabel?: boolean;
  inputPlaceholder?: string;
  width?: string | number;
  errors?: string[];
  disabled?: boolean;
  isUnbranded?: boolean;
  testId?: string;
}

const ErrorContainer = styled.div`
  width: 100%;
`;

const InlineInput = ({
  showPlaceholderAsLabel = false,
  placeholder,
  width,
  value,
  inputPlaceholder,
  errors,
  testId = "",
  ...props
}: InlineInputProps) => {
  let inputTextPlaceholder;
  if (!showPlaceholderAsLabel) {
    inputTextPlaceholder = placeholder;
  } else {
    inputTextPlaceholder = inputPlaceholder;
  }

  return (
    <Container width={width}>
      {showPlaceholderAsLabel && placeholder && <Label>{placeholder}</Label>}
      <InnerContainer>
        <Input
          placeholder={inputTextPlaceholder}
          value={value || ""}
          isErrored={!!(errors && errors.length)}
          {...textInputTestId(testId)}
          {...props}
        />
        {errors && (
          <ErrorContainer>
            <FieldErrors testId={testId} errors={errors} />
          </ErrorContainer>
        )}
      </InnerContainer>
    </Container>
  );
};

export default InlineInput;
