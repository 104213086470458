import React, { useState, useEffect } from "react";
import { curry, includes, replace, __ } from "ramda";
import { RouteComponentProps, withRouter } from "react-router";

import {
  getPost,
  createPost,
  createDraftPost,
  updatePost,
  updateDraftPost,
  updateDraftPostAdmin,
  updatePostAdmin,
  getPostAdmin,
  getPostCurator,
  updatePostCurator,
  updateDraftPostCurator,
  createDraftPostAdmin,
  createDraftPostCurator,
  createPostAdmin,
  createPostCurator,
} from "api/posts";

import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { COPY, NEW } from "constants/strings";

import PostForm from "components/forms/PostForm";
import DetailedView, { Copy, Details } from "views/DetailedView";
import { getPathForContentItem } from "core/navigation";
import useLoginState from "hooks/useLoginState";
import useNotification from "hooks/useNotification";
import emojiDataState from "state/singletons/emojiDataState";
import useQuery from "common/hooks/useQuery";
import { getEmojiData } from "api/imageStorage";
import QueryStatus from "common/api/models/QueryStatus";

const CopyForm = (props: any) => <PostForm isNew {...props} />;

const DetailsForm = ({
  isDraft,
  updateItem,
  updateDraftItem,
  ...props
}: any) => (
  <PostForm
    isNew={false}
    createNew={updateItem}
    createDraft={isDraft ? updateDraftItem : updateItem}
    {...props}
  />
);

const changedValues = new Set<string>();

const PostDetailed = (props: RouteComponentProps<{ id: string }>) => {
  const { addSuccessNotification } = useNotification();
  // grabbing businessId to address BO instead of BP when necessary
  const {
    getBusinessId,
    getBusinessIdForNewContent,
    hasAdminRights,
    hasCuratorRights,
  } = useLoginState();
  const adminRights = hasAdminRights();
  const isCurator = hasCuratorRights();
  const contextBusinessId = getBusinessId();
  const businessId = getBusinessIdForNewContent();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );
  const [forceDetailsUpdate, setForceDetailsUpdate] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(props.match.params.id === NEW);
  const isCopy = includes(props.match.params.id, COPY);
  const copyId = replace("?id=", "", props.location.search);

  useEffect(() => {
    return setIsNew(props.match.params.id === NEW);
  }, [props.match.params.id]);

  const successCallback = (message: string, response: any) => {
    addSuccessNotification(message);
    if (isNew || isCopy) {
      props.history.push(
        getPathForContentItem(
          "/beitraege",
          response.id,
          adminRights,
          isCurator,
          contextBusinessId,
        ),
      );
    } else {
      setForceDetailsUpdate(!forceDetailsUpdate);
    }
  };

  // assigning calls depending on user's access rights
  const getPostRequest = isCurator
    ? getPostCurator
    : adminRights
    ? getPostAdmin
    : getPost;
  const updatePostRequest = isCurator
    ? updatePostCurator
    : adminRights
    ? updatePostAdmin
    : updatePost;
  const updateDraftPostRequest = isCurator
    ? updateDraftPostCurator
    : adminRights
    ? updateDraftPostAdmin
    : updateDraftPost;

  const mainProps = {
    changedValues,
    success: successCallback,
    setBlockNavigation: setShouldBlockNavigation,
    getDetailsRequest: curry(getPostRequest),
  };

  const mainRequests = {
    createNew: isCurator
      ? // @ts-ignore
        curry(createPostCurator)(__, businessId)
      : adminRights
      ? // @ts-ignore
        curry(createPostAdmin)(__, businessId)
      : curry(createPost),
    createDraft: isCurator
      ? // @ts-ignore
        curry(createDraftPostCurator)(__, businessId)
      : adminRights
      ? // @ts-ignore
        curry(createDraftPostAdmin)(__, businessId)
      : curry(createDraftPost),
  };

  const {
    result: emojiDataFetchResult,
    status: emojiDataFetchStatus,
  } = useQuery({ request: getEmojiData });
  useEffect(() => {
    if (emojiDataFetchStatus === QueryStatus.SUCCESSFUL) {
      emojiDataState.setEmojiData(emojiDataFetchResult);
    }
  }, [emojiDataFetchStatus]);

  return (
    <DetailedView
      {...props}
      privilegedId={PrivilegedComponentsIds.VIEW_POSTS}
      shouldBlockNavigation={shouldBlockNavigation}
      isCopy={isCopy}
      isNew={isNew}
      form={
        <PostForm
          isNew
          businessId={businessId}
          {...mainRequests}
          {...mainProps}
        />
      }
      copy={
        <Copy id={copyId} form={CopyForm} {...mainRequests} {...mainProps} />
      }
      details={
        <Details
          id={props.match.params.id}
          form={DetailsForm}
          updateItem={updatePostRequest}
          updateDraftItem={updateDraftPostRequest}
          update={forceDetailsUpdate}
          {...mainProps}
        />
      }
    />
  );
};

export default withRouter(PostDetailed);
