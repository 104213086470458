import React from "react";
import strings from "localisation/strings";
import { formattedDate } from "utils";
import Plan, { BusinessPlanHistory } from "api/models/Plan";
import styled from "style/styled-components";
import { Snackbar } from "components/generic";

const isPlanExpiringNextMonth = (plan: Plan | null) => {
  if (!plan) {
    return false;
  }

  const planHasExpiryDate = plan.validity.dateTimeTill !== null;
  return planHasExpiryDate;
};

const Section = styled.div`
  margin-bottom: 16px;
`;

interface Props {
  planHistory: BusinessPlanHistory;
}

const PlanExpiryNotice = ({ planHistory }: Props) => {
  if (!isPlanExpiringNextMonth(planHistory.currentPlan)) {
    return null;
  }

  const [nextPlan] = planHistory.planHistory;
  return (
    <Section>
      <Snackbar>
        {strings("businessSettings.plan.planChangePending", {
          planName: nextPlan.name,
          planChangeDate: formattedDate(
            nextPlan.validity!.dateTimeFrom as string,
          ),
        })}
      </Snackbar>
    </Section>
  );
};

export default PlanExpiryNotice;
