import { ProfileOverrides } from "profiles/types/ProfileOverrides";
import { ReactComponent as Logo } from "profiles/wuppertal/assets/icons/logo.svg";
import { ReactComponent as MobileLogo } from "profiles/wuppertal/assets/icons/mobile-logo.svg";
import { ReactComponent as SecondaryBanner } from "profiles/wuppertal/assets/icons/sparkasse-logo.svg";

export const profileOverrides: ProfileOverrides = {
  locales: {},
  theme: {
    icons: {
      Logo,
      MobileLogo,
      SecondaryBanner,
    },
  },
};
