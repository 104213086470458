import React from "react";
import styled from "styled-components";
import Truncate from "react-truncate";
import theme from "style/theme";

interface EnlargeableProps {
  size?: "small" | "medium" | "large";
}

interface BliggBubbleProps extends EnlargeableProps {
  bubbleText?: string | null;
}

interface BliggBubbleTextProps {
  tailHeight: number;
  squareSize: number;
  fontSize: string;
}

const smallBubbleDiameter = 124;
const smallBubbleHeight = 138;
const smallBubbleTailHeightAdjustment = 4;

const mediumBubbleDiameter = 138;
const mediumBubbleHeight = 154;
const mediumBubbleTailHeightAdjustment = 4;

const largeBubbleDiameter = 182;
const largeBubbleHeight = 203;
const largeBubbleTailHeightAdjustment = 4;

const calculateMaxInsideCircleSquareSize = (circleDiameter: number) =>
  Math.sqrt((circleDiameter * circleDiameter) / 2);

const bubbleConfig = {
  small: {
    diameter: smallBubbleDiameter,
    height: smallBubbleHeight,
    tailHeight:
      smallBubbleHeight - smallBubbleDiameter + smallBubbleTailHeightAdjustment,
    squareSize: calculateMaxInsideCircleSquareSize(smallBubbleDiameter),
    fontSize: theme.font.size.medium,
  },
  medium: {
    diameter: mediumBubbleDiameter,
    height: mediumBubbleHeight,
    tailHeight:
      mediumBubbleHeight -
      mediumBubbleDiameter +
      mediumBubbleTailHeightAdjustment,
    squareSize: calculateMaxInsideCircleSquareSize(mediumBubbleDiameter),
    fontSize: theme.font.size.medium,
  },
  large: {
    diameter: largeBubbleDiameter,
    height: largeBubbleHeight,
    tailHeight:
      largeBubbleHeight - largeBubbleDiameter + largeBubbleTailHeightAdjustment,
    squareSize: calculateMaxInsideCircleSquareSize(largeBubbleDiameter),
    fontSize: theme.font.size.xlarge,
  },
};

const BliggBubbleText = styled.div<BliggBubbleTextProps>`
  position: absolute;
  text-align: center;
  top: ${({ tailHeight }) => tailHeight}px;
  height: ${({ squareSize }) => squareSize}px;
  width: ${({ squareSize }) => squareSize}px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;

  span {
    color: ${theme.color.foreground.antiPrimary};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: bold;
  }

  // IE11 specific styles
  @media all and (-ms-high-contrast: none) {
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const BliggBubbleContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const BliggBubbleSvgPath = styled.path`
  fill: ${theme.color.background.dark};
`;

const BliggBubbleIcon = ({ size = "small" }: EnlargeableProps) => {
  const dimensions = bubbleConfig[size];
  return (
    <svg
      width={dimensions.diameter}
      height={dimensions.height}
      viewBox="0 0 124 138"
      fill="none"
    >
      <BliggBubbleSvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 62.2826C0 91.0441 19.4385 115.183 45.8183 122.344L56.5378 135.411C59.369 138.863 64.633 138.863 67.4644 135.411L78.1837 122.344C104.561 115.183 124 91.0441 124 62.2826C124 27.8836 96.2424 0 62.0011 0C27.7576 0 0 27.8836 0 62.2826Z"
      />
    </svg>
  );
};

const BliggBubble = ({ size = "small", bubbleText = "" }: BliggBubbleProps) => (
  <BliggBubbleContainer>
    <BliggBubbleIcon size={size} />
    {bubbleText && (
      <BliggBubbleText
        squareSize={bubbleConfig[size].squareSize}
        tailHeight={bubbleConfig[size].tailHeight}
        fontSize={bubbleConfig[size].fontSize}
      >
        <Truncate lines={3}>{bubbleText}</Truncate>
      </BliggBubbleText>
    )}
  </BliggBubbleContainer>
);

export { mediumBubbleHeight, largeBubbleHeight };

export default BliggBubble;
