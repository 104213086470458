import React from "react";
import strings from "localisation/strings";
import theme from "style/theme";
import sheetIcon from "common/assets/icons/sheet.svg";
import { typeOptions } from "components/table/DataTable/constants";
import BlockTitle from "components/generic/BlockTitle";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { InlineInputProps } from "components/forms/FormFields/InlineNumberInput";
import CategoryMultipleAnswerForm, {
  MultipleFieldFormProps,
  QuestionInputFieldProps,
} from "components/forms/CategoryMultipleAnswerForm";
import SimpleIconedInput, {
  SingleInputFieldProps,
} from "components/forms/FormFields/SimpleIconedInput";
import SelectComponent, {
  SelectItem,
  SelectPropsInterface,
} from "components/forms/Select";
import { CheckboxProps } from "components/forms/Checkbox";
import { ResponsiveSpreadRow } from "components/rows/SpreadRow";
import ResponsiveHalfRowColumn from "components/columns/HalfRowColumn";
import { MarginedArea } from "components/generic/ViewContainer";

const SubCategoriesForm = ({
  getSelectFieldProps,
  getSingleInputFieldProps,
  getQuestionInputFieldProps,
  getQuestionCheckboxFieldProps,
  getQuestionPictureFieldProps,
  getQuestionNumberFieldProps,
  getQuestionSelectFieldProps,
  getMultipleFieldFormProps,
  isParentActive,
  isBusinessRelatedObjectType,
  isWithPicture,
}: {
  getSelectFieldProps: (
    field: string,
    options: SelectItem[],
  ) => SelectPropsInterface;
  getSingleInputFieldProps: (field: string) => SingleInputFieldProps;
  getQuestionInputFieldProps: (
    field: string,
    subField: string,
    index: number,
  ) => QuestionInputFieldProps;
  getQuestionCheckboxFieldProps: (
    field: string,
    subField: string,
    index: number,
  ) => CheckboxProps;
  getQuestionPictureFieldProps: (
    field: string,
    subField: string,
    index: number,
  ) => {
    imageUrl?: string | null;
    setImageUrl?: (image: string | null) => void;
  };
  getQuestionNumberFieldProps: (
    field: string,
    subField: string,
    index: number,
  ) => InlineInputProps;
  getQuestionSelectFieldProps?: (
    field: string,
    subField: string,
    index: number,
    options: SelectItem[],
  ) => SelectPropsInterface;
  getMultipleFieldFormProps: (field: string) => MultipleFieldFormProps;
  isParentActive: boolean;
  isBusinessRelatedObjectType: boolean;
  isWithPicture?: boolean;
}) => {
  const { xsmall, full } = theme.forms;

  return (
    <>
      <BlockTitle>
        {strings("categoriesScreen.title.editSubCategory")}
      </BlockTitle>
      <MarginedArea>
        <ResponsiveSpreadRow>
          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings("categoriesScreen.fields.tagType")}
              width={xsmall}
            >
              <SelectComponent
                {...getSelectFieldProps("tagType", typeOptions())}
                disabled={true}
                classNamePrefix={"option"}
                small
              />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>

          <ResponsiveHalfRowColumn>
            <SimpleIconedInput
              disabled={true}
              icon={sheetIcon}
              width={xsmall}
              inputWidth={full}
              inputPlaceholder={strings(
                "categoriesScreen.fields.tagName.placeholder",
              )}
              {...getSingleInputFieldProps("tagName")}
            />
          </ResponsiveHalfRowColumn>
        </ResponsiveSpreadRow>

        <CategoryMultipleAnswerForm
          width={xsmall}
          inputWidth={full}
          getQuestionInputFieldProps={getQuestionInputFieldProps}
          getQuestionCheckboxFieldProps={getQuestionCheckboxFieldProps}
          getQuestionPictureFieldProps={getQuestionPictureFieldProps}
          getQuestionNumberFieldProps={getQuestionNumberFieldProps}
          getQuestionSelectFieldProps={getQuestionSelectFieldProps}
          isSubCategory={true}
          isParentActive={isParentActive}
          isWithPicture={isWithPicture}
          {...getMultipleFieldFormProps("subTags")}
        />
      </MarginedArea>
    </>
  );
};

export default SubCategoriesForm;
