import React, { useEffect, useState } from "react";
import styled from "style/styled-components";
import DownloadButton from "components/table/DataTable/Buttons/DownloadButton";
import CopyLinkButton from "components/table/DataTable/Buttons/CopyLinkButton";
import UserDeleteButton from "components/table/DataTable/Buttons/UserDeleteButton";

export const ActionColumnContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
`;

interface Props {
  creatingInProgress: boolean;
  logFileId?: string;
  userId: string;
  refreshTable: () => void;
}

const PublicUserActionButtons = ({
  creatingInProgress,
  logFileId,
  userId,
  refreshTable,
}: Props) => {
  const [userLogDownloadDisabled, setUserLogDownloadDisabled] = useState<
    boolean
  >(creatingInProgress);

  useEffect(() => {
    setUserLogDownloadDisabled(creatingInProgress);
  }, [userId]);

  return (
    <ActionColumnContainer>
      {logFileId && !userLogDownloadDisabled && (
        <CopyLinkButton logFileId={logFileId} />
      )}
      <DownloadButton
        userId={userId}
        disabled={userLogDownloadDisabled}
        setDisabled={setUserLogDownloadDisabled}
        onButtonClick={refreshTable}
      />
      <UserDeleteButton userId={userId} />
    </ActionColumnContainer>
  );
};

export default PublicUserActionButtons;
