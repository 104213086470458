import React, { useState } from "react";
import styled from "style/styled-components";
import ContentType from "common/api/models/ContentType";

import eventImage from "common/assets/images/placeholder-event.svg";
import businessProfileImage from "common/assets/images/placeholder-business.svg";
import offerImage from "common/assets/images/placeholder-offers.svg";
import postImage from "common/assets/images/placeholder-posts.svg";
import newsImage from "common/assets/images/placeholder-news.svg";

export interface ImageWithPlaceholderProps {
  src?: string | null;
  alt?: string;
  className?: string;
  contentType?: ContentType;
  onClick?: () => void;
}

const Image = styled.img`
  object-fit: cover;
  font-family: "object-fit:cover", inherit;
`;

const getPlaceholderImageByContentType = (
  contentType: ContentType | undefined,
) => {
  switch (contentType) {
    case "EVENT":
      return eventImage;
    case "NEWS":
      return newsImage;
    case "BUSINESS":
      return businessProfileImage;
    case "OFFER":
      return offerImage;
    case "POST":
      return postImage;
    case "SERVICE":
      return businessProfileImage;
    case "EAT_AND_DRINK":
      return businessProfileImage;
    case "SHOPPING":
      return businessProfileImage;
    case "HOBBY":
      return eventImage;
    case "EATING_DRINKING":
      return businessProfileImage;
    default:
      return newsImage;
  }
};

const ImageWithPlaceholder = ({
  src,
  contentType,
  onClick,
  ...props
}: ImageWithPlaceholderProps) => {
  const [pictureBroken, setPictureBroken] = useState(false);

  const placeholderImage = getPlaceholderImageByContentType(contentType);

  return (
    <span onClick={onClick} className={`${props.className} listItemImage`}>
      {!!src && !pictureBroken ? (
        <Image
          alt={props.alt}
          src={src}
          {...props}
          onError={() => setPictureBroken(true)}
        />
      ) : (
        <Image alt={props.alt} src={placeholderImage} {...props} />
      )}
    </span>
  );
};

export default ImageWithPlaceholder;
