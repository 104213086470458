import React, { InputHTMLAttributes } from "react";
import Autosuggest from "react-autosuggest";
import { AddressLocation } from "api/models/Location";
import useAddressAutocomplete from "components/forms/FormFields/AddressAutocomplete/useAddressAutocomplete";
import SearchBar from "components/forms/FormFields/AddressAutocomplete/SearchBar";
import SuggestionsDropdown, {
  PinLocationOnMapSuggestionContainer,
} from "components/forms/FormFields/AddressAutocomplete/SuggestionsDropdown";
import Suggestion from "components/forms/FormFields/AddressAutocomplete/Suggestion";
import styled from "style/styled-components";
import LoadingIndicator from "components/forms/FormFields/AddressAutocomplete/LoadingIndicator";
import PoweredByGoogleIndicator from "components/forms/FormFields/AddressAutocomplete/PoweredByGoogleIndicator";
import NoLocationFoundSuggestion from "components/forms/FormFields/AddressAutocomplete/NoLocationFoundSuggestion";
import PinLocationOnMapSuggestion from "components/forms/FormFields/AddressAutocomplete/PinLocationOnMapSuggestion";

interface AddressAutoCompleteInputProps {
  value?: string | undefined;
  errors: string[] | undefined;
  showPinLocationOnMapSuggestion?: boolean;
  onSelect: (location: AddressLocation) => void;
  onChange: () => void;
  displayMap: () => void;
}

const AddressAutocompleteContainer = styled.div`
  position: relative;

  .react-autosuggest__suggestions-list {
    list-style: none;
  }
`;

const trimLeadingTrailingCommasRegex = /^,|,$/g;

const AddressAutocomplete = ({
  errors,
  onSelect,
  onChange,
  displayMap,
  value: initialValue = "",
  showPinLocationOnMapSuggestion,
}: AddressAutoCompleteInputProps) => {
  const {
    addressValue,
    autocompleteErrors,
    selectAddress,
    suggestions,
    resetAddress,
    changeAddress,
    isLoading,
    clearSuggestions,
    isNoAddressFound,
  } = useAddressAutocomplete({
    onSelect,
    onChange,
    initialValue: initialValue
      .replace(trimLeadingTrailingCommasRegex, "")
      .trim(),
  });

  const allErrors = errors
    ? errors.concat(autocompleteErrors)
    : autocompleteErrors;

  const handlePinLocationOnMapSuggestionOnClick = () => {
    displayMap();
    changeAddress();
  };

  return (
    <AddressAutocompleteContainer>
      <Autosuggest<typeof suggestions[0]>
        suggestions={suggestions}
        onSuggestionSelected={(event, { suggestion }) =>
          selectAddress(suggestion.id)
        }
        alwaysRenderSuggestions={true}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={clearSuggestions}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={suggestion =>
          [suggestion.primaryText, suggestion.secondaryText].join(", ")
        }
        renderInputComponent={inputProps => (
          <SearchBar
            {...(inputProps as InputHTMLAttributes<HTMLInputElement>)}
            errors={allErrors}
            testId="search"
          />
        )}
        renderSuggestionsContainer={({ containerProps, children }) =>
          children ? (
            <SuggestionsDropdown {...containerProps}>
              {children}
              <LoadingIndicator isLoading={isLoading} />
              <PoweredByGoogleIndicator />
            </SuggestionsDropdown>
          ) : isNoAddressFound ? (
            <SuggestionsDropdown {...containerProps}>
              <NoLocationFoundSuggestion />
              <PinLocationOnMapSuggestion
                onClick={handlePinLocationOnMapSuggestionOnClick}
              />
            </SuggestionsDropdown>
          ) : showPinLocationOnMapSuggestion ? (
            <PinLocationOnMapSuggestionContainer>
              <PinLocationOnMapSuggestion
                onClick={handlePinLocationOnMapSuggestionOnClick}
              />
            </PinLocationOnMapSuggestionContainer>
          ) : null
        }
        renderSuggestion={(suggestion, { isHighlighted }) => (
          <Suggestion
            id={suggestion.id}
            isActive={isHighlighted}
            primaryText={suggestion.primaryText}
            secondaryText={suggestion.secondaryText}
          />
        )}
        inputProps={{
          value: addressValue,
          onChange: (event, { method, newValue }) => {
            if (method === "type") {
              changeAddress(newValue);
            }
          },
          onBlur: resetAddress,
        }}
      />
    </AddressAutocompleteContainer>
  );
};

export default AddressAutocomplete;
