import React, { HTMLAttributes } from "react";
import styled from "style/styled-components";

const totalHeight = "28px";

interface MultiSelectButtonProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: string;
  selected?: boolean;
}

const Button = styled.button`
  flex-grow: 1;
  min-width: 75px;
  height: ${totalHeight};
  margin: ${({ theme }) => theme.margin.small};
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

const Text = styled.span<{ selected?: boolean }>`
  display: block;
  width: 100%;
  padding: 0 20px;
  height: ${({ selected }) => (selected ? "25px" : totalHeight)};
  line-height: ${({ selected }) => (selected ? "25px" : totalHeight)};
  font-size: ${({ theme }) => theme.font.size.small};
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.color.foreground.primary};
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.foreground.primary : "transparent"};
  color: ${({ theme, selected }) =>
    selected
      ? theme.color.foreground.antiPrimary
      : theme.color.foreground.primary};
`;

const MultiSelectButton = ({
  selected,
  children,
  ...props
}: MultiSelectButtonProps) => (
  <Button {...props}>
    <Text selected={selected}>{children}</Text>
  </Button>
);

export default MultiSelectButton;
