import { useCallback, useEffect, useState, useRef } from "react";
import { curry } from "ramda";
import useFormFields from "hooks/useFormFields";
import useMutation from "common/hooks/useMutation";
import { saveChatSettings, getChatSettings } from "api/chat";
import QueryStatus from "common/api/models/QueryStatus";
import useQuery from "common/hooks/useQuery";
import {
  mapChatSettingsDTOToChatSettings,
  ChatSettings,
  mapChatFormValuesToChatSettingsDTO,
  mapChatSettingsToChatFormValues,
} from "views/Chat/dataMappers";
import useNotificationState from "hooks/useNotification";
import strings from "localisation/strings";
import useLoginState from "hooks/useLoginState";

const useChatSettingsForm = (businessId?: string) => {
  const changedValues = useRef(new Set<string>());
  const { addSuccessNotification } = useNotificationState();
  const [chatSettings, setChatSettings] = useState<ChatSettings>(
    mapChatSettingsDTOToChatSettings(),
  );
  const { hasAdminRights } = useLoginState();
  const isAdmin = hasAdminRights();

  const chatSettingsQuery = useQuery({
    request: curry(getChatSettings)({ businessId, isAdmin }),
    compare: [businessId],
  });
  const settingsMutator = useMutation(
    curry(saveChatSettings)(businessId, isAdmin),
  );

  useEffect(() => {
    if (settingsMutator.status === QueryStatus.SUCCESSFUL) {
      addSuccessNotification(
        strings("chatSettingsScreen.settingsSavedMessage"),
      );
    }
  }, [settingsMutator.status, addSuccessNotification]);

  useEffect(() => {
    if (chatSettingsQuery.result) {
      const transformedChatSettings = mapChatSettingsDTOToChatSettings(
        chatSettingsQuery.result,
      );
      setChatSettings(transformedChatSettings);
      updateValues(mapChatSettingsToChatFormValues(transformedChatSettings));
    }
  }, [
    chatSettingsQuery.result,
    setChatSettings,
    mapChatSettingsToChatFormValues,
    mapChatSettingsDTOToChatSettings,
  ]);

  const {
    getCheckboxFieldProps,
    getTextAreaFieldProps,
    values,
    updateValues,
    errors,
    parseErrors,
    unblockNavigation,
  } = useFormFields({
    changedValues: changedValues.current,
    translationScope: "chatSettingsScreen.fields",
    textAreaFields: {} as Record<string, string>,
    checkboxFields: {
      chatActivated: false,
    } as Record<string, boolean>,
    blockNavigation: true,
    handleGenericErrors: true,
  });

  useEffect(() => {
    if (settingsMutator.error) {
      parseErrors(settingsMutator.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsMutator.error]);

  const handleChatSettingsFormSubmitted = useCallback(async () => {
    type FormDynamicSettings = any; // type depends on what server injects into the form
    const response = await settingsMutator.makeRequest(
      mapChatFormValuesToChatSettingsDTO(values as FormDynamicSettings),
    );

    if (response && response.result) {
      await unblockNavigation();
    }
  }, [
    values,
    unblockNavigation,
    settingsMutator.makeRequest,
    mapChatFormValuesToChatSettingsDTO,
  ]);

  return {
    errors,
    values,
    getCheckboxFieldProps,
    getTextAreaFieldProps,

    handleChatSettingsFormSubmitted,
    presefinedFlows: chatSettings.flowMapping,
    isInitialChatSettingsLoading:
      chatSettingsQuery.status === QueryStatus.WAITING,
    isChatSettingsFormSubmittable:
      settingsMutator.status !== QueryStatus.WAITING,
  };
};

export default useChatSettingsForm;
