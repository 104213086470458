import React, { useEffect, useState } from "react";
import styled from "style/styled-components";
import ReviewInterface, {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
} from "api/models/ReviewInterface";
import ReviewItem from "components/review/ReviewItem";
import strings from "localisation/strings";
import Title from "components/generic/Title";
import { AbortableRequest } from "common/api/AbortableRequest";
import { AbortablePayloadRequest } from "common/api/AbortablePayloadRequest";
import { PersonalData } from "api/models/UsersInterface";
import useMutation from "common/hooks/useMutation";
import { putBoPersonalDataList } from "api/users";

const ListedReviewItem = styled(ReviewItem)`
  margin: 0px 0px;
`;

const StyledTitle = styled(Title)`
  text-align: left;
  flex-grow: 1;
  margin-top: ${({ theme }) => theme.margin.larger};
  margin-bottom: ${({ theme }) => theme.margin.larger};
`;

const Container = styled.div`
  max-width: ${({ theme }) => theme.content.sizes.medium};
  margin: 0 auto;
`;

interface ReviewListProps {
  reviews: ReviewInterface[];
  saveReviewReply: (
    reviewId: string,
  ) => AbortablePayloadRequest<
    ReviewReplyPayloadInterface,
    ReplyDetailsInterface
  >;
  deleteReviewReply: (
    reviewId: string,
    replyId: string,
  ) => AbortableRequest<void>;
  onDeleteReply: (review: ReviewInterface) => void;
}

const mapReviewsToGetIdList = (reviews: ReviewInterface[]): string[] => {
  const userIds: string[] = reviews.map(review => review.userId);
  return userIds;
};

function addEmailsToReviews(
  reviews: ReviewInterface[],
  users: { users: PersonalData[] },
): ReviewInterface[] {
  const reviewsWithUserEmails = reviews.map(review => {
    const matchingUser = users?.users.find(
      user => user.userId === review.userId,
    );
    if (matchingUser) {
      return { ...review, userEmail: matchingUser.email };
    }
    return review;
  });
  return reviewsWithUserEmails;
}

const ReviewList = ({
  reviews,
  saveReviewReply,
  deleteReviewReply,
  onDeleteReply,
}: ReviewListProps) => {
  const [reviewWithEmails, setReviewWithEmails] = useState<ReviewInterface[]>(
    reviews,
  );
  const fetchUserListData = useMutation(putBoPersonalDataList);
  const fetchUserDataList = async (idList: string[]) => {
    const response = (await fetchUserListData.makeRequest(idList)) || {};
    if (response.result) {
      setReviewWithEmails(addEmailsToReviews(reviews, response.result));
    }
  };

  useEffect(() => {
    if (reviews != undefined && reviews != null) {
      const idList = mapReviewsToGetIdList(reviews);
      if (idList.length > 0) fetchUserDataList(idList);
    }
  }, []);

  return reviewWithEmails.length === 0 ? null : (
    <Container>
      <StyledTitle>{strings("reviewList.reviews.title")}</StyledTitle>
      {reviewWithEmails.map(review => (
        <ListedReviewItem
          key={review.id}
          reviewWithEmail={review}
          saveReviewReply={saveReviewReply}
          deleteReviewReply={deleteReviewReply}
          onDeleteReply={onDeleteReply}
        />
      ))}
    </Container>
  );
};
export default ReviewList;
