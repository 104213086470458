import React from "react";
import styled from "style/styled-components";
import { BoldText } from "components/generic/SimpleText";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import Toaster from "components/generic/toaster/Toaster";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";

const Text = styled(RichTextRendering)`
  flex-grow: 1;
  text-align: left;
`;

const TitleText = styled(BoldText)`
  flex-grow: 1;
  text-align: left;
  font-size: 20px;
  padding-bottom: ${({ theme }) => theme.margin.small};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

interface Props {
  title: string;
  message: string;
  clearNotification: () => void;
}

const SystemAlertToaster = ({ title, message, clearNotification }: Props) => (
  <Toaster.Container>
    <Toaster.Body type={NotificationTypes.system}>
      <FlexRow>
        <TitleText>{title}</TitleText>
        <Text cozy value={message} defaultValue="" />
      </FlexRow>
    </Toaster.Body>
    <Toaster.ClosePositioner>
      <Toaster.Close
        type={NotificationTypes.system}
        onClick={clearNotification}
      />
    </Toaster.ClosePositioner>
  </Toaster.Container>
);

export default SystemAlertToaster;
