import React from "react";
import { __, curry } from "ramda";
import { History } from "history";
import { withRouter, RouteComponentProps } from "react-router";
import strings from "localisation/strings";
import useQuery from "common/hooks/useQuery";

import { tryRefreshing } from "api/AuthedAPI";
import {
  createBusinessProfile,
  createDraftBusinessProfile,
  getBusinessProfile,
  updateBusinessProfile,
  updateDraftBusinessProfile,
} from "api/businessProfiles";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import PublicationStatuses from "api/models/PublicationStatuses";

import BusinessProfileForm from "components/forms/BusinessProfileForm";
import { DetailsViewContainer as Container } from "components/generic/ViewContainer";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { WizardStepTwoHeader } from "components/headers/WizardHeader";
import PrivilegedComponent from "components/PrivilegedComponent";

import useLoginState from "hooks/useLoginState";
import useNotificationState from "hooks/useNotification";
import { routeNames } from "core/navigation";
import { saveChatSettings } from "api/chat";

const getDetails = curry(getBusinessProfile);
const defaultImprint: any = {};

interface UpdatingProfileDetailsProps {
  profileId: string;
  history: History;
  isBackButtonEnabled: boolean;
  onBackButtonClicked: () => void;
  successCallback: (
    message: string,
    response: BusinessUserProfileInterface,
  ) => void;
}

// this component only used if user receives businessProfileId value with login
const UpdatingProfileDetails = ({
  successCallback,
  history,
  profileId,
  isBackButtonEnabled,
  onBackButtonClicked,
}: UpdatingProfileDetailsProps) => {
  const { result } = useQuery({
    request: getDetails(profileId, {}),
  });

  const saveDraftRequest = curry(updateDraftBusinessProfile);
  const saveRequest = curry(updateBusinessProfile);
  return (
    <>
      <WizardStepTwoHeader />
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_WIZARD} isView>
        <Container>
          <BusinessProfileForm
            isNew={false}
            createNew={saveRequest}
            createDraft={saveDraftRequest}
            success={successCallback}
            changedValues={changedValues}
            setBlockNavigation={() => {}}
            result={result}
            history={history}
            isBackButtonEnabled={isBackButtonEnabled}
            onBackButtonClicked={onBackButtonClicked}
            publishButtonLabel={strings("buttons.publishAndGo")}
            suppressButtonConfirmation={true}
            expandImprint={true}
            isStickyFooterEnabled={false}
            // Send empty imprint because we're not fetching imprint in step2
            imprint={defaultImprint}
          />
        </Container>
      </PrivilegedComponent>
    </>
  );
};

interface CreatingProfileDetailsProps {
  history: History;
  isBackButtonEnabled: boolean;
  onBackButtonClicked: () => void;
  successCallback: (
    message: string,
    response: BusinessUserProfileInterface,
  ) => void;
}

const CreatingProfileDetails = ({
  successCallback,
  history,
  isBackButtonEnabled,
  onBackButtonClicked,
}: CreatingProfileDetailsProps) => {
  const { getBusinessProfileEntity } = useLoginState();

  // if no businessProfileId value is stored - operate on the stored BUP entity
  const entity = getBusinessProfileEntity();
  const profileId = entity && entity.id;
  const isNew = !profileId;

  // @ts-ignore
  const createDraftForBa = curry(createDraftBusinessProfile)(__, undefined);
  // @ts-ignore
  const createForBa = curry(createBusinessProfile)(__, undefined);

  const saveDraftRequest = isNew
    ? createDraftForBa
    : curry(updateDraftBusinessProfile);
  const saveRequest = isNew ? createForBa : curry(updateBusinessProfile);

  return (
    <>
      <WizardStepTwoHeader />
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_WIZARD} isView>
        <Container>
          <BusinessProfileForm
            isNew={isNew}
            createNew={saveRequest}
            createDraft={saveDraftRequest}
            success={successCallback}
            changedValues={changedValues}
            setBlockNavigation={() => {}}
            result={entity}
            history={history}
            isBackButtonEnabled={isBackButtonEnabled}
            onBackButtonClicked={onBackButtonClicked}
            suppressButtonConfirmation={true}
            isStickyFooterEnabled={false}
            // Send empty imprint because we're not fetching imprint in step2
            imprint={defaultImprint}
          />
        </Container>
      </PrivilegedComponent>
    </>
  );
};

const changedValues = new Set<string>();

const WizardStepTwo = ({ history }: RouteComponentProps) => {
  const { addSuccessNotification } = useNotificationState();
  const {
    getIncompleteBusinessProfileId,
    setBusinessProfileEntity,
    getCurrentUserBusinessId,
    hasAdminRights,
  } = useLoginState();

  // retrieve the businessProfileId value passed from login
  const businessProfileId = getIncompleteBusinessProfileId();

  const handleBusinessProfileSaved = (
    message: string,
    response: BusinessUserProfileInterface,
  ) => {
    // if no businessProfileId value is stored store the whole BUP entity locally
    if (!businessProfileId) {
      setBusinessProfileEntity(response);
    }

    // the business ID of the current user
    const currentUserBusinessId = getCurrentUserBusinessId();
    const currentUserHasNoBusinessAccount = !currentUserBusinessId;

    if (currentUserHasNoBusinessAccount) {
      // after saving business profile for the first time a business account is getting created, so we need to do
      // a service token refresh to get proper business ID into login state
      tryRefreshing()
        .then(() => {
          // sends request to chat settings to enable chat on profile creation
          saveChatSettings(currentUserBusinessId, hasAdminRights(), {
            chatEnabled: true,
            flowMapping: [],
          }).catch(() => console.log("couldn't enable chat"));
        })
        .catch((error: Error) => console.error(error));
    }

    if (response.profileState === PublicationStatuses.draft) {
      addSuccessNotification(strings("wizardScreen.confirmation.saved"));
    } else {
      history.push(routeNames.WizardStep.Third);
    }
  };

  const handleBackClicked = () => history.push(routeNames.WizardStep.First);

  /*
   * if a businessProfileId value is present user should have an associated
   * businessId hence this RetrievedDetails component is used
   */
  if (businessProfileId) {
    return (
      <UpdatingProfileDetails
        profileId={businessProfileId}
        successCallback={handleBusinessProfileSaved}
        history={history}
        isBackButtonEnabled={true}
        onBackButtonClicked={handleBackClicked}
      />
    );
  }

  return (
    <CreatingProfileDetails
      successCallback={handleBusinessProfileSaved}
      history={history}
      isBackButtonEnabled={true}
      onBackButtonClicked={handleBackClicked}
    />
  );
};

export default withRouter(WizardStepTwo);
