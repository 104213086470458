import React, { Component } from "react";
import strings from "localisation/strings";
import styled from "style/styled-components";
import Icon from "components/forms/FormFields/Icon";
import calendar from "assets/icons/calendar.svg";

interface FieldProps {
  isDateField?: boolean | null;
  hasContent?: boolean | null;
}

interface HasContent {
  hasContent?: boolean | null;
}

interface HasDate {
  hasDate?: boolean | null;
}

interface DateInputProps {
  dateLabel: string;
  onClick?: (event: any) => void;
  value?: string;
}

const CustomInputContainer = styled.div`
  max-width: 97%;
  height: 55px;
  border: 1px solid ${({ theme }) => theme.color.foreground.tertiary};
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Field = styled.div<FieldProps>`
  align-self: ${({ hasContent }) => (hasContent ? "flex-start" : "center")};
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: ${({ isDateField }) => (isDateField ? "200px" : "100px")};
`;

const FieldWithIcon = styled.div<FieldProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: ${({ hasContent }) => (hasContent ? "flex-start" : "center")};
  width: ${({ isDateField }) => (isDateField ? "200px" : "100px")};
`;

const LabelDateContainer = styled.div<HasContent>`
  display: flex;
  flex-direction: column;
  width: 163px;
`;

const Label = styled.div<HasContent>`
  color: ${({ theme, hasContent }) =>
    hasContent
      ? theme.color.foreground.primary
      : theme.color.foreground.tertiary};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ hasContent, theme }) =>
    hasContent ? theme.font.size.small : theme.font.size.medium};
  font-weight: ${({ hasContent }) => (hasContent ? "bold" : "normal")};
  line-height: 13px;
  padding: ${({ theme, hasContent }) =>
    `${hasContent ? theme.margin.medium : "0"} 0 0 ${theme.margin.large}`};
  display: inline-block;
  text-transform: ${({ hasContent }) => (hasContent ? "uppercase" : "none")};
`;

const Date = styled.div<HasContent>`
  display: ${({ hasContent }) => (hasContent ? "inline-block" : "none")};
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 18px;
  padding: ${({ theme }) => `${theme.margin.xsmall} ${theme.margin.large}`};
`;

const Time = styled.div<HasContent>`
  display: ${({ hasContent }) => (hasContent ? "block" : "none")};
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 18px;
  padding: ${({ theme }) => `${theme.margin.xsmall} ${theme.margin.large}`};
`;

const Separator = styled.div`
  height: 37px;
  width: 1px;
  background: rgba(151, 151, 151, 0.3);
  margin: 9px 0;
  display: inline-block;
`;

const CalendarIconContainer = styled.div`
  width: 22px;
  height: 22px;
`;

const CalendarIcon = styled(Icon)<HasDate>`
  margin-top: ${({ theme, hasDate }) => (hasDate ? theme.margin.xsmall : "0")};
  margin-right: 20px;
`;

class DateTimeInput extends Component<DateInputProps> {
  getTime = (value?: string) => {
    if (value) return value.substring(value.length - 5, value.length);
    return "";
  };

  getDate = (value?: string) => {
    if (value) return value.substring(0, value.length - 6);
    return "";
  };

  render() {
    const { dateLabel, value, onClick } = this.props;

    const time = this.getTime(value);
    const date = this.getDate(value);

    const hasDate = date.length > 0;
    const hasTime = time.length > 0;

    return (
      <CustomInputContainer onClick={onClick}>
        <FieldWithIcon isDateField hasContent={hasDate}>
          <LabelDateContainer hasContent={hasDate}>
            <Label hasContent={hasDate}>{dateLabel}</Label>
            <Date hasContent={hasDate}>{date}</Date>
          </LabelDateContainer>
          <CalendarIconContainer>
            <CalendarIcon
              hasDate={hasDate}
              backgroundImage={calendar}
              active={true}
            />
          </CalendarIconContainer>
        </FieldWithIcon>

        <Separator />

        <Field hasContent={hasTime}>
          <Label hasContent={hasTime}>{strings("dateTimePicker.time")}</Label>
          <Time hasContent={hasTime}>{time}</Time>
        </Field>
      </CustomInputContainer>
    );
  }
}

export default DateTimeInput;
