import React, { Component } from "react";
import SearchFilters from "api/models/Filters";
import { FilterCell, TableRow } from "components/table/DataTable/components";
import { ColumnModel, Filter } from "components/table/DataTable/interfaces";
import Field from "components/table/DataTable/Filters/Field";
import { SelectItem } from "components/forms/Select";

interface Props {
  allFilters?: SearchFilters;
  columns: ColumnModel[];
  appliedFilters?: Filter[];
  setFilters: (filter?: Filter[]) => void;
}

interface State {
  allFilters?: SearchFilters;
  filters: Filter[];
}

class Filters extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      allFilters: props.allFilters,
      filters: this.getFiltersState(),
    };
  }

  getFiltersState = (): Filter[] => {
    const { columns, appliedFilters } = this.props;

    if (!columns || !columns.length) return [];
    return columns.map(c => {
      if (appliedFilters) {
        const newFilter = appliedFilters.find(
          f => !!c.filter && f.id === c.filter.id,
        );
        return { ...newFilter, ...c.filter } as Filter;
      }
      return { ...c.filter } as Filter;
    });
  };

  updateFilter = (
    id: string,
    value?: string | number | object[] | SelectItem[] | boolean,
  ) => {
    this.setState(
      previous => {
        return {
          filters: previous.filters.map(filter => {
            if (filter.id !== id) return filter;
            return { ...filter, value };
          }),
        };
      },
      () => {
        this.props.setFilters(this.state.filters);
      },
    );
  };

  render() {
    return (
      <TableRow>
        {this.state.filters.map((filter, i) => {
          return (
            <FilterCell key={filter.id || i}>
              <Field
                filter={filter}
                onChange={this.updateFilter}
                allFilters={this.state.allFilters}
              />
            </FilterCell>
          );
        })}
      </TableRow>
    );
  }
}

export default Filters;
