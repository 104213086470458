import { useState, useEffect } from "react";
import useLocale from "common/hooks/useLocale";
import EmptyHeaders from "common/api/EmptyHeaders";
import AbortController from "api/AbortController";
import { AbortableRequest } from "common/api/AbortableRequest";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import APIError from "common/api/models/APIError";
import createUnknownError from "common/api/createUnknownError";
import QueryStatus from "common/api/models/QueryStatus";

interface QueryProps<T> {
  request: AbortableRequest<T>;
  compare?: any;
  condition?: boolean;
}

interface QueryResult<T = any> {
  result?: T;
  error?: APIErrorResponse;
  headers: Headers | EmptyHeaders;
  status: QueryStatus;
  refreshDetector: boolean;
  refresh: (refreshDetector: boolean) => void;
}

const emptyHeaders: EmptyHeaders = {
  get: () => null,
};

const defaultResult: QueryResult = {
  result: undefined,
  error: undefined,
  headers: emptyHeaders,
  status: QueryStatus.UNDETERMINED,
  refreshDetector: true,
  refresh: () => {},
};

const useQuery: <T>(props: QueryProps<T>) => QueryResult<T> = ({
  request,
  compare = [],
  condition = true,
}) => {
  const locale = useLocale();
  const [queryResult, setQueryResult] = useState(defaultResult);
  const [refreshDetector, refresh] = useState(false);
  let controller = new AbortController();
  let mounted = true;

  const fetchData = () => {
    setQueryResult({
      ...queryResult,
      status: QueryStatus.WAITING,
    });

    controller.abort();
    controller = new AbortController();

    request({ signal: controller.signal })
      .then(({ result, headers }) => {
        if (mounted) {
          setQueryResult({
            result,
            headers,
            refreshDetector,
            refresh,
            error: undefined,
            status: QueryStatus.SUCCESSFUL,
          });
        }
      })
      .catch(err => {
        if (!mounted) {
          return;
        }
        if (err instanceof APIError) {
          setQueryResult({
            ...queryResult,
            refreshDetector,
            refresh,
            error: err.errorBody,
            status: QueryStatus.ERROR,
          });
        } else {
          setQueryResult({
            ...queryResult,
            refreshDetector,
            refresh,
            error: createUnknownError(err).errorBody,
            status: QueryStatus.ERROR,
          });
        }
      });
  };

  useEffect(() => {
    if (!condition) {
      return;
    }

    fetchData();
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [locale, JSON.stringify(compare), refreshDetector]);

  return queryResult;
};

interface QueryInitialLoadingStatusProps {
  status: QueryStatus;
  result: any;
}

const useQueryInitialLoadingStatus = ({
  status,
  result,
}: QueryInitialLoadingStatusProps) => {
  return status === QueryStatus.WAITING && !result;
};

interface QueryManualRefreshProps {
  status: QueryStatus;
  refresh: (refreshDetector: boolean) => void;
  refreshDetector: boolean;
}

const useQueryManualRefresh = ({
  status,
  refresh,
  refreshDetector,
}: QueryManualRefreshProps) => {
  const [manuallyRefreshing, setManuallyRefreshing] = useState(false);

  const manuallyRefresh = () => {
    setManuallyRefreshing(true);
    refresh(!refreshDetector);
  };

  const requestFinished =
    status === QueryStatus.SUCCESSFUL || status === QueryStatus.ERROR;
  useEffect(() => {
    if (requestFinished && manuallyRefreshing) {
      setManuallyRefreshing(false);
    }
  }, [requestFinished, manuallyRefreshing]);

  return {
    manuallyRefresh,
    manuallyRefreshing,
  };
};

export { useQueryInitialLoadingStatus, useQueryManualRefresh };

export default useQuery;
