import StoredState from "state/StoredState";
import EmojiInterface from "api/models/EmojiInterface";

interface EmojiDataStateType {
  emojiData: EmojiInterface[];
}

export default class EmojiDataState extends StoredState<EmojiDataStateType> {
  state: EmojiDataStateType = {
    emojiData: [],
  };

  constructor(storageKey?: string) {
    super(storageKey || "EmojiDataState", window.localStorage);
  }

  getEmojiData = () => this.state.emojiData;

  setEmojiData = (emojiData: EmojiInterface[] = []) =>
    this.setState({ emojiData });

  onStateRestored = (restoredState: Partial<EmojiDataStateType>) => {
    const { emojiData } = restoredState;

    if (emojiData !== null) {
      this.setState({ emojiData });
    }
  };
}
