import React from "react";
import strings from "localisation/strings";
import ActivationPageLayout from "components/activation/UserActivationPageLayout";
import { CenteredText } from "components/generic";

const RepresentativeLinkExpiredView = (props: any) => {
  const messageScope =
    props &&
    props.location &&
    props.location.pathname.includes("/reset-password/")
      ? "changePasswordScreen"
      : "activateAccountScreen";

  return (
    <ActivationPageLayout
      title={strings("linkExpired.title", {
        scope: messageScope,
      })}
    >
      <CenteredText>
        {strings("linkExpired.contactBusinessAdmin", { scope: messageScope })}
      </CenteredText>
    </ActivationPageLayout>
  );
};

export default RepresentativeLinkExpiredView;
