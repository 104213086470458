import { stringify } from "qs";
import { mergeDeepRight } from "ramda";
import APIError from "common/api/models/APIError";
import { baseUrl as defaultBaseUrl } from "api/constants";
import localeState from "state/singletons/localeState";
import {
  FileResultType,
  formatToAPILocale,
  isJsonContentType,
  parseResponseWithBlob,
  parseResponseWithJson,
  parseResponseWithText,
} from "api/APIHelpers";
import RequestParameters from "api/RequestParameters";
import PostRequestParameters, {
  PostFormDataRequestParameters,
} from "api/PostRequestParameters";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import strings from "localisation/strings";

const baseHeaders = { "Content-Type": "application/json; charset=utf-8" };

export interface APIProps {
  baseUrl?: string;
  isWithoutHeader?: boolean;
}

export default class API {
  baseUrl: string;
  isWithoutHeader: boolean;

  constructor({ baseUrl, isWithoutHeader }: APIProps) {
    this.baseUrl = baseUrl ? baseUrl : defaultBaseUrl;
    this.isWithoutHeader = isWithoutHeader ? isWithoutHeader : false;
  }

  async getJson<ResultType>({
    path,
    queryParams = {},
    options = {},
  }: RequestParameters): Promise<{ result: ResultType; headers: Headers }> {
    const fullPath = `${this.baseUrl}${path}${stringify(queryParams, {
      addQueryPrefix: true,
    })}`;

    const defaultOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Accept-Language": formatToAPILocale(localeState.getLocale()),
      },
    };

    const mergedOptions = mergeDeepRight(defaultOptions, options);

    const response = await fetch(
      fullPath,
      this.isWithoutHeader ? { method: "GET" } : mergedOptions,
    );

    const isJson = isJsonContentType(response);

    if (response.ok) {
      const result = isJson
        ? await parseResponseWithJson<ResultType>(response)
        : await parseResponseWithText<ResultType>(response);

      return {
        result,
        headers: response.headers,
      };
    } else if (
      response.status === 403 &&
      window.location.pathname.includes("/admin/")
    ) {
      const forbiddenError: APIErrorResponse = {
        status: response.status,
        path: response.url,
        titleCode: "errors.forbidden.adminMessage",
        detail: `${strings("errors.unknown")}. ${strings(
          "errors.forbidden.adminMessage",
        )}`,
        detailCode: "errors.forbidden.adminMessage",
      };
      throw new APIError(forbiddenError);
    }

    const error: APIErrorResponse = isJson
      ? await parseResponseWithJson<APIErrorResponse>(response)
      : {
          status: response.status,
          path: response.url,
          titleCode: "errors.parsing.unparsable",
          detail: strings("errors.parsing.unparsable"),
          detailCode: "errors.parsing.unparsable",
        };

    throw new APIError(error);
  }

  async getList<ResultType>(params: RequestParameters) {
    const {
      result: { data },
      ...rest
    } = await this.getJson<{ data: ResultType }>(params);
    return { result: data, ...rest };
  }

  async postJson<ResultType>({
    path,
    body = {},
    queryParams = {},
    options = {},
  }: PostRequestParameters) {
    return this.getJson<ResultType>({
      path,
      queryParams,
      options: {
        method: "POST",
        body: JSON.stringify(body),
        headers: baseHeaders,
        ...options,
      },
    });
  }

  async sendFormData<ResultType>({
    path,
    body,
    queryParams = {},
    options = {},
  }: PostFormDataRequestParameters) {
    return this.getJson<ResultType>({
      path,
      queryParams,
      options: {
        body,
        method: "POST",
        ...options,
      },
    });
  }

  async download({
    path,
    body = {},
    queryParams = {},
    options = {},
    defaultFileName,
  }: PostRequestParameters): Promise<{
    result: FileResultType;
    headers: Headers;
  }> {
    const fullPath = `${this.baseUrl}${path}${stringify(queryParams, {
      addQueryPrefix: true,
    })}`;

    const defaultOptions = {
      method: "POST",
      body: JSON.stringify(body),
      responseType: "blob", // important
      headers: {
        Accept: "application/json",
        "Accept-Language": formatToAPILocale(localeState.getLocale()),
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    const mergedOptions = mergeDeepRight(defaultOptions, options);

    const response = await fetch(fullPath, mergedOptions);

    const isJson = isJsonContentType(response);

    if (response.ok) {
      const result = await parseResponseWithBlob(response, defaultFileName);

      return {
        result,
        headers: response.headers,
      };
    }

    const error: APIErrorResponse = isJson
      ? await parseResponseWithJson<APIErrorResponse>(response)
      : {
          status: response.status,
          path: response.url,
          titleCode: "errors.parsing.unparsable",
          detail: strings("errors.parsing.unparsable"),
          detailCode: "errors.parsing.unparsable",
        };

    throw new APIError(error);
  }

  async putJson<ResultType>({
    path,
    body = {},
    queryParams = {},
    options = {},
  }: PostRequestParameters) {
    return this.getJson<ResultType>({
      path,
      queryParams,
      options: {
        method: "PUT",
        body: JSON.stringify(body),
        headers: baseHeaders,
        ...options,
      },
    });
  }

  async postListJson<ResultType>({
    path,
    body = {},
    queryParams = {},
    options = {},
  }: PostRequestParameters) {
    const { result, headers } = await this.postJson<ResultType>({
      path,
      body,
      queryParams,
      options,
    });
    return { result, headers };
  }

  async deleteEntity<ResultType>({
    path,
    entityId,
    queryParams = {},
    options = {},
  }: RequestParameters & { entityId: string }) {
    return this.getJson<ResultType>({
      queryParams,
      path: `${path}/${entityId}`,
      options: {
        method: "DELETE",
        ...options,
      },
    });
  }
}
