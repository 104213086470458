import AuthedAPI from "api/AuthedAPI";
import RequestOptions from "api/RequestOptions";
import ListParams from "api/models/ListParams";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import ListResultType from "api/models/ListResultType";
import SearchInterface from "api/models/SearchInterface";

const searchAPI = new AuthedAPI({});

const getBusinessesStream = (
  businessId: string | undefined,
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  searchAPI.postJson<ListResultType<SearchInterface>>({
    queryParams,
    body,
    options,
    path: `search/bp/stream/businesses/${businessId}`,
  });

export { searchAPI, getBusinessesStream };
