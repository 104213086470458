import React from "react";
import styled from "style/styled-components";
import { curry } from "ramda";
import {
  getAdminImprintByBusinessId,
  getCuratorImprintByBusinessId,
  getImprintByLoggedInBusiness,
} from "api/businessProfiles";
import useQuery from "common/hooks/useQuery";
import useLoginState from "common/hooks/useLoginState";

const SettingsLabel = styled.span`
  position: relative;
  margin-top: ${({ theme }) => theme.margin.large};
  margin-right: ${({ theme }) => theme.margin.medium};
  text-align: center;
  width: max-content;
`;

interface Props {
  businessId: string;
  isCurator?: boolean;
}

const BusinessContextSettingsAreaForAdminOrCurator = ({
  businessId,
  isCurator,
}: Props) => {
  const imprintRequest = isCurator
    ? curry(getCuratorImprintByBusinessId)
    : curry(getAdminImprintByBusinessId);
  const { result: imprint } = useQuery({
    request: imprintRequest(businessId),
    compare: [businessId],
  });
  const businessName = imprint ? imprint.businessName : undefined;

  return <SettingsLabel>{businessName}</SettingsLabel>;
};

const BusinessContextSettingsAreaForBusinessUser = () => {
  const { result: imprint } = useQuery({
    request: getImprintByLoggedInBusiness,
  });
  const businessName = imprint ? imprint.businessName : undefined;
  return <SettingsLabel>{businessName}</SettingsLabel>;
};

const BusinessContextSettingsArea = () => {
  const { getBusinessId, hasAdminRights, hasCuratorRights } = useLoginState();
  const isAdmin = hasAdminRights();
  const isCurator = hasCuratorRights();
  const businessId = getBusinessId();

  return isCurator || isAdmin ? (
    businessId ? (
      <BusinessContextSettingsAreaForAdminOrCurator
        businessId={businessId}
        isCurator={isCurator}
      />
    ) : null
  ) : (
    <BusinessContextSettingsAreaForBusinessUser />
  );
};

export default BusinessContextSettingsArea;
