import React, { Component } from "react";
import { debounce } from "lodash";
import strings from "localisation/strings";
import appTheme from "style/theme";
import styled, { isMobile } from "style/styled-components";
import Location from "api/models/Location";
import InlineInput from "components/forms/FormFields/InlineInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import transparentIcon from "assets/icons/transparent.svg";
import locationIcon from "assets/icons/location.svg";
import zooIcon from "assets/icons/zoo.svg";
import { FormErrorsInterface } from "hooks/useFormFields";
import { getWindowDimensions } from "utils";

export interface LocationInputProps {
  location?: Partial<Location> | null;
  onChangeLocation: (location: Location) => void;
  errors?: FormErrorsInterface;
  hideVenue?: boolean;
  leftSideWidth?: string;
}

interface State extends Partial<Location> {
  windowWidth: number;
}

const Container = styled.div`
  display: flex;
  position: relative;
  line-height: 32px;
  flex-wrap: wrap;
`;

class LocationInput extends Component<LocationInputProps, State> {
  changeHandler = debounce(() => {
    const { id, postalCode, street, houseNo, venue, city } = this.state;
    const { onChangeLocation } = this.props;

    onChangeLocation({ id, postalCode, street, houseNo, venue, city });
  }, 200);

  constructor(props: LocationInputProps) {
    super(props);
    const { location } = this.props;

    const getInitValue = (key: keyof Location) =>
      location && location[key] ? location[key] : undefined;

    this.state = {
      id: getInitValue("id"),
      street: getInitValue("street"),
      houseNo: getInitValue("houseNo"),
      postalCode: getInitValue("postalCode"),
      city: getInitValue("city"),
      venue: getInitValue("venue"),
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: LocationInputProps) {
    if (prevProps.location !== this.props.location) {
      this.populateState();
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: getWindowDimensions().width });
  };

  getValue = (field: keyof Location) => {
    const { location } = this.props;
    return location ? location[field] : undefined;
  };

  populateState = () => {
    this.setState({
      id: this.getValue("id"),
      street: this.getValue("street"),
      houseNo: this.getValue("houseNo"),
      postalCode: this.getValue("postalCode"),
      city: this.getValue("city"),
      venue: this.getValue("venue"),
    });
  };

  updateLocation = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const v = event.target.value;
    this.setState(
      {
        ...this.state,
        [key]: v != null && v.length > 0 ? v : null,
      },
      this.changeHandler,
    );
  };

  render() {
    const { venue, postalCode, street, houseNo, city } = this.state;
    const { errors, hideVenue, leftSideWidth } = this.props;
    const { leftSide } = appTheme.forms;
    const inlineInputGroupWidth = leftSideWidth ? leftSideWidth : leftSide;
    const getErrors = (field: string) => errors && errors[field];
    const isMobileDevice = isMobile(this.state.windowWidth);

    return (
      <Container>
        {!hideVenue && (
          <InlineInputGroup
            icon={zooIcon}
            placeholder={strings("detailedScreen.location.venue")}
            width={inlineInputGroupWidth}
            margin={`${appTheme.margin.xsmall}  0`}
          >
            <InlineInput
              type="text"
              onChange={e => this.updateLocation("venue", e)}
              value={venue}
              errors={getErrors("venue")}
              testId="imp-venue"
            />
          </InlineInputGroup>
        )}
        <InlineInputGroup
          icon={locationIcon}
          placeholder={strings("detailedScreen.location.street")}
          width={inlineInputGroupWidth}
          margin={`${appTheme.margin.xsmall}  0`}
        >
          <InlineInput
            type="text"
            onChange={e => this.updateLocation("street", e)}
            value={street}
            errors={getErrors("street")}
            testId="imp-street"
          />
        </InlineInputGroup>

        <InlineInputGroup
          icon={transparentIcon}
          placeholder={strings("detailedScreen.location.houseNo")}
          width={inlineInputGroupWidth}
          totalWidth={isMobileDevice ? "100%" : "40%"}
          margin={`${appTheme.margin.xsmall}  0`}
        >
          <InlineInput
            type="text"
            onChange={e => this.updateLocation("houseNo", e)}
            value={houseNo}
            width="100%"
            errors={getErrors("houseNo")}
            testId="imp-houseno"
          />
        </InlineInputGroup>

        <InlineInputGroup
          icon={isMobileDevice ? transparentIcon : ""}
          placeholder={
            isMobileDevice ? strings("detailedScreen.location.postalCode") : ""
          }
          totalWidth={isMobileDevice ? "100%" : "25%"}
          width={isMobileDevice ? inlineInputGroupWidth : "0px"}
          margin={`${appTheme.margin.xsmall}  0`}
        >
          <InlineInput
            type="text"
            placeholder={
              isMobileDevice
                ? ""
                : strings("detailedScreen.location.postalCode")
            }
            onChange={e => this.updateLocation("postalCode", e)}
            value={postalCode}
            width={"100%"}
            showPlaceholderAsLabel={!isMobileDevice}
            errors={getErrors("postalCode")}
            testId="imp-postal"
          />
        </InlineInputGroup>
        <InlineInputGroup
          icon={isMobileDevice ? transparentIcon : ""}
          placeholder={
            isMobileDevice ? strings("detailedScreen.location.city") : ""
          }
          totalWidth={isMobileDevice ? "100%" : "35%"}
          width={isMobileDevice ? inlineInputGroupWidth : "0px"}
          margin={`${appTheme.margin.xsmall}  0`}
        >
          <InlineInput
            type="text"
            placeholder={
              isMobileDevice ? "" : strings("detailedScreen.location.city")
            }
            onChange={e => this.updateLocation("city", e)}
            value={city}
            width="100%"
            showPlaceholderAsLabel={!isMobileDevice}
            errors={getErrors("city")}
            testId="imp-city"
          />
        </InlineInputGroup>
      </Container>
    );
  }
}

export default LocationInput;
