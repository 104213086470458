import { useEffect, useState, useRef, useCallback } from "react";
import useChatState from "hooks/useChatState";
import { ChatConnectionStatus } from "components/chat/types";
import { noop } from "utils";

/**
 * Subscribes to a socket topic when connection to socket is toggled to active,
 * resubscribes when connection is reestablished and removes subscription on unmount
 */

type UnsubscribeFn = () => void;
type SubscribeFn = () => UnsubscribeFn;

function useSocketSubscription(subscribeFn: SubscribeFn) {
  const chatState = useChatState();
  const unsubscribeRef = useRef(noop);
  const [subscribeEnabled, setSubscribeEnabled] = useState(false);

  const isChatConnected = chatState.status === ChatConnectionStatus.Connected;
  useEffect(() => {
    if (!isChatConnected) {
      return;
    }

    if (subscribeEnabled) {
      unsubscribeRef.current = subscribeFn();

      return () => {
        unsubscribeRef.current();
        unsubscribeRef.current = noop;
      };
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatConnected, subscribeEnabled]);

  useEffect(() => {
    if (!subscribeEnabled) {
      unsubscribeRef.current();
      unsubscribeRef.current = noop;
    }
  }, [subscribeEnabled]);

  const subscribe = useCallback(() => setSubscribeEnabled(true), [
    setSubscribeEnabled,
  ]);
  const unsubscribe = useCallback(() => setSubscribeEnabled(false), [
    setSubscribeEnabled,
  ]);

  return {
    subscribe,
    unsubscribe,
  };
}

export default useSocketSubscription;
