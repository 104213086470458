import RequestOptions from "api/RequestOptions";
import AuthedAPI from "api/AuthedAPI";
import { BackOfficeRepresentativeInterface } from "api/models/BackOfficeRepresentativeInterface";

const clientAPI = new AuthedAPI({});

const postBoRepresentative = (
  body: Partial<BackOfficeRepresentativeInterface>,
  options?: RequestOptions,
) =>
  clientAPI.postJson<BackOfficeRepresentativeInterface>({
    options,
    body,
    path: `business-profiles/bo/business-profiles/${body.businessId}/representatives`,
  });

const putBoRepresentative = (
  body: Partial<BackOfficeRepresentativeInterface>,
  options?: RequestOptions,
) =>
  clientAPI.putJson<BackOfficeRepresentativeInterface>({
    options,
    body,
    path: `business-profiles/bo/business-profiles/${body.businessId}/representatives/${body.userId}`,
  });

const getBoRepresentativeById = (
  param: { id: string; businessId?: string },
  options?: RequestOptions,
) =>
  clientAPI.getJson<BackOfficeRepresentativeInterface>({
    options,
    path: `business-profiles/bo/business-profiles/${param.businessId}/representatives/${param.id}`,
  });

const deleteBoRepresentativeById = (
  businessId: string,
  entityId: string,
  queryParams?: RequestOptions,
) =>
  clientAPI.deleteEntity<void>({
    queryParams,
    entityId,
    path: `business-profiles/bo/business-profiles/${businessId}/representatives`,
  });

const getAdminRoles = () =>
  clientAPI.getJson<string[]>({
    path:
      "business-profiles/bo/business-profiles/representatives/internal-roles",
  });

const getRepresentativeRolesAdmin = () =>
  clientAPI.getJson<string[]>({
    path:
      "business-profiles/bo/business-profiles/representatives/business-roles",
  });

export {
  getAdminRoles,
  getRepresentativeRolesAdmin,
  postBoRepresentative,
  putBoRepresentative,
  getBoRepresentativeById,
  deleteBoRepresentativeById,
};
