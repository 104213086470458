import React from "react";
import { Subscribe } from "unstated";

import strings from "localisation/strings";
import LoginState from "state/LoginState";
import LocaleState from "state/LocaleState";
import {
  CenteredBlockTitle,
  CenteredText,
  MarginlessViewContainer,
} from "components/generic";
import { CenteredContent } from "components/activation/CenteredContent";

const RegistrationSuccessScreen = () => (
  <Subscribe to={[LoginState, LocaleState]}>
    {() => (
      <MarginlessViewContainer>
        <CenteredBlockTitle>
          {strings("registrationSuccessScreen.title")}
        </CenteredBlockTitle>
        <CenteredContent>
          <CenteredText>
            {strings("registrationSuccessScreen.subtitle")}
          </CenteredText>
        </CenteredContent>
      </MarginlessViewContainer>
    )}
  </Subscribe>
);

export default RegistrationSuccessScreen;
