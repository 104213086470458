import React from "react";

import useQuery from "common/hooks/useQuery";
import usePagination from "common/hooks/usePagination";
import { getTotalItemsCount, getTotalPagesFromHeaders, isLoading } from "utils";
import styled from "style/styled-components";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import Pagination from "components/pagination/Pagination";
import { Title } from "components/generic";
import ListResultType from "api/models/ListResultType";
import { SearchRequest } from "components/lists/ListRequestTypes";
import SearchInterface from "api/models/SearchInterface";
import BusinessTimelineList from "components/lists/businessTimelineList/BusinessTimelineList";
import strings from "localisation/strings";
import { History } from "history";
import EmptyTimeline from "components/lists/businessTimelineList/EmptyTimeline";

const OverlayContainer = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.content.sizes.medium};
  margin: 0 auto;
  width: 100%;
`;

export interface BusinessUserTimelineProps {
  id: string;
  getRequest: SearchRequest<ListResultType<SearchInterface>>;
  history: History;
  adminRights: boolean;
}

const BusinessUserTImeline = ({
  id,
  getRequest,
  history,
  adminRights,
}: BusinessUserTimelineProps) => {
  const { offset, limit, page, updatePage, onPageSizeChange } = usePagination();

  const { result, headers, status } = useQuery({
    request: getRequest({ limit, offset, body: {} }),
    compare: { page, limit, id },
  });

  if (!result) {
    return <></>;
  }

  if (!result.data || result.data.length === 0) {
    return (
      <OverlayContainer>
        <Title>{strings("businessTimelineList.title")}</Title>
        <EmptyTimeline />
      </OverlayContainer>
    );
  }

  const totalNumberOfPages = getTotalPagesFromHeaders(headers, limit);
  const totalItems = getTotalItemsCount(headers);

  const onClick = (itemId: string, contentType: string) => {
    let objectPath;
    switch (contentType) {
      case "OFFER":
        objectPath = "angebote";
        break;
      case "POST":
        objectPath = "beitraege";
        break;
      case "EVENT":
        objectPath = "veranstaltungen";
        break;
      default:
        return;
    }
    history.push(
      adminRights
        ? `/admin/inhalt/${objectPath}/${itemId}`
        : `/${objectPath}/${itemId}`,
    );
  };
  const loading = isLoading(status);

  return (
    <OverlayContainer>
      <Title>{strings("businessTimelineList.title")}</Title>
      <BusinessTimelineList
        emptyIndicatable={!loading}
        listItems={result.data}
        onItemClick={onClick}
      />
      <Pagination
        onPageChange={updatePage}
        onPageSizeChange={onPageSizeChange}
        page={page}
        limit={limit}
        totalNumberOfPages={totalNumberOfPages}
        totalItems={totalItems}
        useStandardMargin
      />
      {loading && <AbsoluteOverlaySpinner />}
    </OverlayContainer>
  );
};

export default BusinessUserTImeline;
