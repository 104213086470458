import React, { ChangeEvent, Component, ReactNode } from "react";
import { assoc, dissoc, isEmpty } from "ramda";

import styled from "style/styled-components";
import strings from "localisation/strings";
import { RequestDeletionPayload } from "api/models/UsersInterface";
import TextField from "components/forms/TextField";
import RecaptchaWidget from "components/forms/RecaptchaWidget";
import { CenteredFieldMessage } from "components/forms/RegistrationForm/components";
import ActionButton from "components/buttons/ActionButton";
import { emailRegex } from "common/helpers/validators";
import { removeEmptyValues } from "components/helpers/utils";
import { portalCode } from "api/constants";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Text } from "views/TextScreens/components";
import { BoldText } from "components/generic/SimpleText";

const MarginedTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.margin.medium};
  width: 100%;
`;

const CenteredActionButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.margin.medium};
  align-self: center;
`;

enum RequestDeletionFields {
  portalCode = "portalCode",
  email = "email",
  recaptcha = "recaptcha",
}

type StateValues = { [key in RequestDeletionFields]?: string };

type Errors = { [key in RequestDeletionFields]?: string[] };

interface RequestDeletionFormProps {
  navigateToLogin: () => void;
  onSubmit: (payload: RequestDeletionPayload) => void;
  errorMessage: ReactNode | null;
  location: {
    state: {
      recoveryEmail: string;
    };
  };
}

interface RequestDeletionFormState {
  values: StateValues;
  errors: Errors;
  recaptchaTimestamp: Date;
}

const validateRequestDeletionForm = (values: StateValues): Errors => {
  const t = (translationKey: string) =>
    strings(translationKey, { scope: "requestDeletionScreen.fields" });

  let errors: Errors = {};

  const { email = "" } = values;

  let emailError = null;

  if (!email) {
    emailError = t(`email.errors.required`);
  } else if (!emailRegex.test(email)) {
    emailError = t(`email.errors.invalid`);
  }

  if (emailError) {
    errors = assoc("email", [emailError], errors);
  }

  return errors;
};

class RequestDeletionForm extends Component<
  RequestDeletionFormProps & RouteComponentProps,
  RequestDeletionFormState
> {
  state: RequestDeletionFormState = {
    errors: {},
    values: {
      portalCode,
      email: this.props.location.state?.recoveryEmail,
    },
    recaptchaTimestamp: new Date(),
  };

  onChange = (key: RequestDeletionFields, value?: string) => {
    this.setState(({ values, errors }) => {
      return {
        values: { ...values, [key]: value },
        errors: dissoc(key, errors),
      };
    });
  };

  getValue = (key: RequestDeletionFields) => this.state.values[key];

  getErrors = (key: RequestDeletionFields) => this.state.errors[key] || [];

  getFieldProps = (field: RequestDeletionFields) => ({
    key: field,
    placeholder: strings(`requestDeletionScreen.fields.${field}.title`),
    errors: this.getErrors(field),
  });

  getTextFieldProps = (field: RequestDeletionFields) => ({
    ...this.getFieldProps(field),
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      this.onChange(field, event.target.value),
    onEnter: () =>
      this.props.onSubmit(this.state.values as RequestDeletionPayload),
    value: this.getValue(field),
  });

  validateFields = () => {
    const errors = validateRequestDeletionForm(this.state.values);
    this.setState({ errors });
    return errors;
  };

  onSubmit = () => {
    const errors = this.validateFields();
    if (isEmpty(removeEmptyValues(errors))) {
      this.props.onSubmit(this.state.values as RequestDeletionPayload);
    }
  };

  onTokenChange = (token: string | null) => {
    this.onChange(RequestDeletionFields.recaptcha, token ? token : undefined);
  };

  render() {
    const { errorMessage } = this.props;

    return (
      <>
        <Text>{strings("requestDeletionScreen.text0")}</Text>
        <Text>
          <BoldText>{strings("requestDeletionScreen.text1")}</BoldText>
        </Text>
        <Text>
          <ol>
            <li>{strings("requestDeletionScreen.list0.entry0")}</li>
            <li>{strings("requestDeletionScreen.list0.entry1")}</li>
            <li>{strings("requestDeletionScreen.list0.entry2")}</li>
            <li>{strings("requestDeletionScreen.list0.entry3")}</li>
          </ol>
        </Text>
        <Text>
          <BoldText>{strings("requestDeletionScreen.text2")}</BoldText>
        </Text>
        <Text>
          <ul>
            <li>{strings("requestDeletionScreen.list1.entry0")}</li>
            <li>{strings("requestDeletionScreen.list1.entry1")}</li>
            <li>{strings("requestDeletionScreen.list1.entry2")}</li>
          </ul>
        </Text>
        <Text>
          <BoldText>{strings("requestDeletionScreen.text3")}</BoldText>
        </Text>
        <Text>
          <ul>
            <li>{strings("requestDeletionScreen.list2.entry0")}</li>
            <li>{strings("requestDeletionScreen.list2.entry1")}</li>
          </ul>
        </Text>
        <Text>
          <BoldText>{strings("requestDeletionScreen.text4")}</BoldText>
        </Text>
        <Text>
          <ul>
            <li>{strings("requestDeletionScreen.list3.entry0")}</li>
            <li>{strings("requestDeletionScreen.list3.entry1")}</li>
            {/* <li>{strings("requestDeletionScreen.list3.entry2")}</li> */}
          </ul>
        </Text>

        <MarginedTextField
          {...this.getTextFieldProps(RequestDeletionFields.email)}
        />

        <RecaptchaWidget
          onChange={this.onTokenChange}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
          timestamp={this.state.recaptchaTimestamp}
        />
        <CenteredFieldMessage>
          {this.getErrors(RequestDeletionFields.recaptcha)}
        </CenteredFieldMessage>

        <CenteredActionButton
          onClick={this.onSubmit}
          text={strings("requestDeletionScreen.submitButton")}
        />

        {errorMessage}
      </>
    );
  }
}

export default withRouter(RequestDeletionForm);
