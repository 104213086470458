import React from "react";
import moment from "moment";
import styled from "style/styled-components";
import { getFullDateFormat } from "constants/DateConstants";
import strings from "localisation/strings";
import useLocale from "common/hooks/useLocale";

export interface LastModifiedProps {
  modifiedDateTime?: string;
}

const LastModifiedText = styled.div`
  font-size: ${({ theme }) => theme.font.size.pagination};
  color: ${({ theme }) => theme.color.foreground.quinary};
`;

const LastModifiedDateTime = ({ modifiedDateTime }: LastModifiedProps) => {
  const locale = useLocale();

  if (modifiedDateTime) {
    return (
      <LastModifiedText>
        {strings("tableView.lastModified")}{" "}
        {moment(modifiedDateTime).format(getFullDateFormat(locale))}
      </LastModifiedText>
    );
  }

  return null;
};

export default LastModifiedDateTime;
