import React from "react";
import styled from "style/styled-components";

import SortButton from "components/lists/header/SortButton";
import FilterButton from "components/lists/header/FilterButton";
import Search from "components/lists/header/Search/SearchField";
import strings from "localisation/strings";
import { SearchBoxType } from "components/lists/header/Search/SearchBox";
import ListHeaderActionButton from "components/lists/header/ListHeaderActionButton";

const ButtonRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-color: ${({ theme }) => theme.color.foreground.tertiary};
  border-bottom-width: 1px;
  border-top-width: 1px;
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export interface SortAndFilterHeaderProps {
  filterCount: number;
  hideFilter?: boolean;
  hideSort?: boolean;
  gridViewActive: boolean;
  toggleSorting: () => void;
  toggleFiltering: () => void;
  toggleGridView: () => void;
  onSearch: (query: string) => void;
  onAddNew: () => void;
  sortName: string;
}

const SortAndFilterHeader = ({
  filterCount,
  hideFilter,
  hideSort,
  toggleSorting,
  toggleFiltering,
  onAddNew,
  onSearch,
  sortName,
}: SortAndFilterHeaderProps) => (
  <ButtonRow>
    <Buttons>
      {!hideFilter && (
        <Search type={SearchBoxType.Compact} onSearch={onSearch} />
      )}
      {!hideSort && (
        <SortButton toggleSorting={toggleSorting} sortName={sortName} />
      )}
      {!hideFilter && (
        <FilterButton
          filterCount={filterCount}
          toggleFiltering={toggleFiltering}
        />
      )}
      <ListHeaderActionButton
        text={strings("filterableLists.addNewButton")}
        onClick={onAddNew}
      />
    </Buttons>
  </ButtonRow>
);

export default SortAndFilterHeader;
