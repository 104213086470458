import { useEffect } from "react";
import { curry } from "ramda";
import { startConversation } from "api/chat";
import {
  transformAPIConversation,
  transformAPIUser,
} from "components/chat/dataMappers";
import chatState from "state/singletons/chatState";
import useQuery from "common/hooks/useQuery";
import useLoginState from "hooks/useLoginState";

const useConversationStarter = (
  conversationId: string,
  businessId?: string,
) => {
  const { hasAdminRights } = useLoginState();
  const isAdmin = hasAdminRights();
  const { status, result: conversation } = useQuery({
    request: curry(startConversation)(businessId, isAdmin)(conversationId),
    compare: [conversationId, businessId],
  });

  useEffect(() => {
    if (conversation) {
      chatState.updateConversation(
        conversation.id,
        transformAPIConversation(conversation),
      );
      chatState.setUnreadMessagesCount(conversation.totalUnreadCount);
      chatState.setUser(transformAPIUser(conversation.currentUser));
    }
  }, [conversation, chatState.updateConversation, chatState.setUser]);

  return {
    status,
    conversation,
  };
};

export default useConversationStarter;
