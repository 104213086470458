import AuthedAPI from "api/AuthedAPI";
import { MarketingPurposesPayload } from "api/models/UsersInterface";
import RequestOptions from "api/RequestOptions";
import MarketingPurposesInterface from "api/models/MarketingPurposesInterface";

const marketingPurposesAPI = new AuthedAPI({});

const getMarketingPurposes = (options?: RequestOptions) =>
  marketingPurposesAPI.getJson<MarketingPurposesInterface>({
    options,
    path: "users/bp/user-data/marketing",
  });

const postMarketingPurposes = (
  body: MarketingPurposesPayload,
  options?: RequestOptions,
) =>
  marketingPurposesAPI.postJson<MarketingPurposesPayload>({
    options,
    body,
    path: "users/bp/user-data/marketing",
  });

export { getMarketingPurposes, postMarketingPurposes };
