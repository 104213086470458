import React from "react";
import styled from "style/styled-components";
import { SuggestionContainer } from "components/forms/FormFields/AddressAutocomplete/SuggestionsDropdown";
import strings from "localisation/strings";

interface Props {
  isLoading: boolean;
}

const LoadingTextContainer = styled(SuggestionContainer)`
  text-align: center;
`;

const LoadingIndicator = ({ isLoading }: Props) =>
  isLoading ? (
    <LoadingTextContainer>{strings("messages.loading")}</LoadingTextContainer>
  ) : null;

export default LoadingIndicator;
