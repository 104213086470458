import React from "react";
import List from "components/lists/List";
import BusinessRepresentativeListItem from "components/lists/businessRepresentativesList/BusinessRepresentativeListItem";
import { RepresentativeInterface as BusinessRepresentativeInterface } from "api/models/RepresentativeInterface";
import ListWidth from "components/lists/ListWidth";
import { listTestId } from "testing/testId";

export interface Props {
  emptyIndicator?: string;
  emptyIndicatable?: boolean;
  representatives: BusinessRepresentativeInterface[];
  onItemClick: (businessProfileId: string) => void;
  onDeleteClick: (businessProfileId: string) => void;
}

const BusinessRepresentativeList = ({
  emptyIndicatable,
  representatives,
  emptyIndicator,
  onItemClick,
  onDeleteClick,
}: Props) => (
  <ListWidth isGridMode={false} {...listTestId("representatives")}>
    <List<BusinessRepresentativeInterface>
      emptyIndicator={emptyIndicator}
      emptyIndicatable={emptyIndicatable}
      data={representatives}
      renderItem={(representative, index) => (
        <BusinessRepresentativeListItem
          key={index}
          representative={representative}
          onClick={onItemClick}
          onDeleteClick={onDeleteClick}
        />
      )}
    />
  </ListWidth>
);

export default BusinessRepresentativeList;
