import React from "react";
import onClickOutside from "react-onclickoutside";
import styled from "style/styled-components";
import PublicationStatuses from "api/models/PublicationStatuses";
import strings from "localisation/strings";
import { SelectItem } from "components/forms/Select";
import { SelectList } from "components/table/DataTable/Fields";
import { Arrow } from "components/generic";
import { UserStatuses } from "api/models/RepresentativeInterface";

interface Props {
  notChangable?: boolean;
  onChange: (option: any) => void;
  value: string;
  options: SelectItem[];
  // status: string;
}

interface State {
  focused: boolean;
}

interface SelectBoxProps {
  notChangable?: boolean;
  value?: string;
}

const SelectBox = styled.div<SelectBoxProps>`
  color: ${({ value, theme }) => {
    switch (value) {
      case PublicationStatuses.draft:
        return theme.color.foreground.warning;
      case UserStatuses.DEACTIVATED:
        return theme.color.foreground.warning;
      case PublicationStatuses.published:
        return theme.color.foreground.success;
      case UserStatuses.ACTIVE:
        return theme.color.foreground.success;
      case PublicationStatuses.unpublished:
        return theme.color.foreground.tertiary;
      case UserStatuses.INACTIVE:
        return theme.color.foreground.tertiary;
      default: // NOOP
    }
  }};
  padding: 10px 0;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  cursor: ${({ notChangable }) => (notChangable ? "auto" : "pointer")};
  font-weight: bold;

  &:hover {
    .wrap-arrow {
      visibility: visible;
    }
  }
`;

const FakeArrow = styled.div`
  margin-left: 10px;
  visibility: hidden;
`;

const SelectFieldWrapper = styled.div`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.table};
  text-align: left;
`;

class SelectField extends React.Component<Props, State> {
  state = { focused: false };

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>): void {
    if (nextProps.value !== this.props.value) {
      this.onBlur();
    }
  }

  onFocus = () => {
    if (this.props.notChangable) return;
    this.setState({ focused: true });
  };

  onBlur = () => this.setState({ focused: false });

  // react-onclickoutside
  handleClickOutside = () => this.onBlur();

  onItemSelect = (option: SelectItem) => {
    this.props.onChange(option);
    this.onBlur();
  };

  render() {
    const { value, options, notChangable } = this.props;
    const { focused } = this.state;

    return (
      <SelectFieldWrapper onClick={this.onFocus}>
        {!notChangable && focused && (
          <SelectList
            onChange={this.onItemSelect}
            options={options}
            isOpen={focused}
            value={options.find(item => item.value === value)}
          />
        )}
        {!focused && (
          <SelectBox value={value} notChangable={notChangable}>
            {strings(`tableView.options.${value && value.toLowerCase()}`)}
            {!notChangable && (
              <FakeArrow className="wrap-arrow">
                <Arrow size="medium" offsetTop={-1} />
              </FakeArrow>
            )}
          </SelectBox>
        )}
      </SelectFieldWrapper>
    );
  }
}

export default onClickOutside(SelectField);
