import styled, { css } from "style/styled-components";
import RegisterButton from "components/forms/RegistrationForm/RegisterButton";
import FieldMessage from "components/forms/FieldMessage";

const errorMargin = css`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

const MarginedFieldMessage = styled(FieldMessage)`
  ${errorMargin};
`;

const CenteredFieldMessage = styled(FieldMessage)`
  text-align: center;
`;

const StyledRegisterButton = styled(RegisterButton)`
  margin-top: 20px;
  margin-bottom: ${({ theme }) => theme.margin.large};
  align-self: center;
  width: 134px;
`;

export { MarginedFieldMessage, CenteredFieldMessage, StyledRegisterButton };
