export enum TalkerType {
  BUSINESS = "BUSINESS",
  USER = "USER",
  BOT = "BOT",
  SYSTEM = "SYSTEM",
}

export interface MessageUser {
  _id: string;
  name?: string;
  avatar?: string;
  talkerType: TalkerType;
  deleted?: boolean;
}

export interface BusinessBranch {
  businessProfileId: string;
  businessProfileName: string;
}

export interface SingleConversationViewProps {
  conversationId: string;
  conversationTitle: string;
}
export interface BusinessConversationViewProps {
  businessProfileId: string;
}

export enum QuickReplyButtonType {
  ACTION = "ACTION",
}

type ButtonContext = any;
type ButtonValue = string;

export interface QuickReplyOption {
  label: string;
  value: {
    reply: string;
    buttonType?: QuickReplyButtonType;
    buttonValue?: ButtonValue;
    buttonContext?: ButtonContext;
    minDate?: string;
    maxDate?: string;
    [key: string]: any;
  };
}

export interface Message {
  _id: string;
  text: string;
  createdAt: Date | number;
  user: MessageUser;
  selectedValue: string | null;
  system: boolean;
  quickReplyType: QuickReplyType | null;
  quickReplies: QuickReplyOption[];
  isInteracted: boolean;
  isSent: boolean;
}

export enum QuickReplyType {
  BUTTON = "BUTTON",
  TEXT = "TEXT",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
}

export interface Conversation {
  id: string;
  isUnread: boolean;
  isDisabled: boolean | null;
  isSpecial: boolean;
  unreadCount: number;
  businessId: string;
  title: string;
  lastModified: Date;
  image: string;
  isSystem?: boolean;
  ownerDeleted: boolean;
  lastMessage: string | null;
  displayLastMessage: boolean;
}

export enum ChatConnectionStatus {
  Connecting,
  Connected,
  Disconnected,
}

export interface ChatState {
  active: boolean;
  status: ChatConnectionStatus;
  user: MessageUser;
  unreadMessagesCount: number;
  refreshKey: string;
  conversationsIds: string[];
  conversationsById: Record<string, Conversation>;
  messagesByConversationId: Record<string, Message[]>;
}

export type InteractiveItemPayload = QuickReplyOption;
