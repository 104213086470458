import { Container } from "unstated";

abstract class StoredState<State extends object> extends Container<State> {
  abstract state: State;
  storageKey: string;
  storage: Storage;
  isRestored: Promise<void>;

  constructor(storageKey: string, storage: Storage) {
    super();
    if (!storageKey) {
      throw new Error("No storage key provided for StoredState in constructor");
    }
    this.storageKey = storageKey;
    this.storage = storage;
    this.isRestored = this.restoreState();
  }

  onStateRestored: (restoredState: Partial<State>) => void = () => {};

  postStateSetAndStringified: (newStringifiedState: string) => void = () => {};

  restoreState = async () => {
    // async call guarantees that all methods are available by the time of usage
    const state = await this.storage.getItem(this.storageKey);

    let restoredState: Partial<State> = {};
    try {
      restoredState = JSON.parse(state || "");
    } catch {
      // do nothing
    }

    this.onStateRestored(restoredState);
  };

  // override
  setState(
    state:
      | ((prevState: Readonly<State>) => Partial<State> | State | null)
      | (Partial<State> | State | null),
    callback?: () => void,
  ): Promise<void> {
    const newState = { ...(this.state as object), ...(state as object) };
    const newStringifiedState = JSON.stringify(newState);
    this.storage.setItem(this.storageKey, newStringifiedState);
    this.postStateSetAndStringified(newStringifiedState);
    return super.setState(state, callback);
  }
}

export default StoredState;
