import React, { useEffect, useState } from "react";
import moment from "moment";
import strings from "localisation/strings";
import { Wrapper, DateTimeField } from "components/forms/FormFields/DateTime";
import { defaultDateTimeFormat } from "components/forms/FormFields/DateTime/helpers";
import DateTimePicker from "components/inputs/DateTimePicker";
import { combineDateObjectWithTimeObject } from "components/helpers/dateSelectionUtilities";
import { Placement } from "@floating-ui/react";

interface Props {
  value?: string;
  setValue?: (value?: string) => void;
  valueFormat?: string;
  getDateFromValue?: (value?: string) => Date | null;
  width?: string;
  timeIntervals?: number;
  isErrored?: boolean;
  dateFormat?: string;
  timeFormat?: string;
  placeholder?: string;
  popperPlacement?: Placement;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  isDisabled?: boolean;
}

const Time = (props: Props) => {
  const {
    value,
    setValue,
    width,
    valueFormat = defaultDateTimeFormat,
    getDateFromValue,
    isErrored,
    timeIntervals = 15,
    dateFormat = "HH:mm",
    timeFormat = "HH:mm",
    placeholder = "",
    minTime,
    isDisabled,
    ...rest
  } = props;

  const initialDate = getDateFromValue ? getDateFromValue(value) : null;
  const [date, setDate] = useState<Date | null>(initialDate);

  // In new content forms, the end date time component tends not to fire the onChange event on direct input. The onChangeRaw function is a workaround for that issue.
  // Issue possibly related to https://github.com/Hacker0x01/react-datepicker/issues/1182
  const onChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === null) {
      onChange(null);
      return;
    }
    const momentDate = moment(event.target.value, timeFormat);
    if (momentDate.isValid()) {
      onChange(momentDate.toDate());
    }
  };

  const onChange = (dateValue: Date | null) => {
    // Date picker onChange provides current date with changed time if showTimeSelectOnly is set. We should only update the time of the initial date
    const timeAdjustedInitialDate = combineDateObjectWithTimeObject(
      initialDate,
      dateValue,
    );

    setDate(timeAdjustedInitialDate);

    if (setValue) {
      const dateValueOrUndefined = timeAdjustedInitialDate
        ? moment(timeAdjustedInitialDate).format(valueFormat)
        : undefined;
      setValue(dateValueOrUndefined);
    }
  };

  const inputPlaceholder = placeholder || strings("dateTimePicker.time");

  useEffect(() => {
    const newValue = getDateFromValue ? getDateFromValue(value) : null;

    if (newValue && minTime) {
      if (moment(newValue).isBefore(moment(minTime))) {
        return setDate(minTime);
      }
    }

    return setDate(newValue);
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Wrapper width={width}>
      <DateTimePicker
        showTimeSelect
        showTimeSelectOnly
        selected={date}
        onChangeRaw={onChangeRaw}
        onChange={onChange}
        timeIntervals={timeIntervals}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        customInput={
          <DateTimeField
            inputPlaceholder={inputPlaceholder}
            isDisabled={isDisabled}
            isErrored={isErrored}
          />
        }
        minTime={minTime}
        {...rest}
      />
    </Wrapper>
  );
};

export default Time;
