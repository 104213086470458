import { RepresentativeInterface } from "api/models/RepresentativeInterface";
import ListResultType from "api/models/ListResultType";
import ListParams from "api/models/ListParams";
import useQuery from "common/hooks/useQuery";
import usePagination from "common/hooks/usePagination";
import useMutation from "common/hooks/useMutation";
import { curry } from "ramda";
import strings from "localisation/strings";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import RequestOptions from "api/RequestOptions";
import {
  getTotalItemsCount,
  getTotalPagesFromHeaders,
  isFailed,
  isFinished,
  isLoading,
} from "utils";
import { useState, useEffect } from "react";
import { NEW } from "constants/strings";
import useNotificationState from "hooks/useNotification";

export const ADD_CURATOR = "kurator-hinzufuegen";

type RepresentativesList = ListResultType<RepresentativeInterface>;

interface Props {
  navigate: (path: string) => void;
  makeRepresentativeRoute: (representativeId: string) => string;
  listRequest: (
    body: SimpleSearchInterface | undefined,
    queryParams: ListParams | undefined,
    options: RequestOptions | undefined,
  ) => Promise<{
    result: RepresentativesList;
    headers: Headers;
  }>;
  deleteRequest: (
    representativeId: string,
    options: RequestOptions | undefined,
  ) => Promise<{
    result: void;
    headers: Headers;
  }>;
  isAdmin?: boolean;
  responseSize?: number;
}

const useRepresentativesList = ({
  navigate,
  makeRepresentativeRoute,
  listRequest,
  deleteRequest,
  isAdmin,
  responseSize,
}: Props) => {
  const [isItemDeleted, setIsItemDeleted] = useState<boolean>();

  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const {
    status: deleteStatus,
    makeRequest: makeDeleteRequest,
    error: deleteError,
  } = useMutation(curry(deleteRequest));

  useEffect(() => {
    if (isFailed(deleteStatus)) {
      addErrorNotification(
        (deleteError && deleteError.detail) || strings("errors.generic"),
      );
    }

    if (isFinished(deleteStatus)) {
      addSuccessNotification(
        isAdmin
          ? strings("businessRepresentatives.deletedAdminMessage")
          : strings("businessRepresentatives.deletedMessage"),
      );
      setIsItemDeleted(!isItemDeleted);
    }
  }, [deleteStatus]);

  const handleDeleteItemClicked = (representativeId: string) => {
    makeDeleteRequest(representativeId);
  };

  const pagination = usePagination();
  const fetchRepresentativesList = curry(listRequest)(
    {
      from: pagination.offset,
      size: responseSize ? responseSize : pagination.limit,
    },
    {},
  );

  const {
    result,
    headers,
    status: fetchListStatus,
    error: fetchListError,
  } = useQuery<RepresentativesList>({
    request: fetchRepresentativesList,
    compare: [
      pagination.offset,
      pagination.page,
      pagination.limit,
      isItemDeleted,
    ],
  });

  const fetchFailed = isFailed(fetchListStatus);
  useEffect(() => {
    if (fetchFailed) {
      addErrorNotification(fetchListError && fetchListError.detail);
    }
  }, [fetchFailed, fetchListError]);

  const totalNumberOfPages = getTotalPagesFromHeaders(
    headers,
    pagination.limit,
  );
  const totalItems = getTotalItemsCount(headers);

  return {
    totalNumberOfPages,
    totalItems,
    handleDeleteItemClicked,
    loading: isLoading(fetchListStatus),
    representatives: result ? result.data : [],
    addRepresentative: () => navigate(makeRepresentativeRoute(NEW)),
    addCurator: () => navigate(makeRepresentativeRoute(ADD_CURATOR)),
    editRepresentative: (representativeId: string) =>
      navigate(makeRepresentativeRoute(representativeId)),
    page: pagination.page,
    updatePage: pagination.updatePage,
    onPageSizeChange: pagination.onPageSizeChange,
    limit: pagination.limit,
  };
};

export default useRepresentativesList;
