import React from "react";
import styled from "style/styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import strings from "localisation/strings";

import useLoginState from "hooks/useLoginState";
import { Title, CenteredContentViewContainer } from "components/generic";
import LoginForm from "components/forms/LoginForm/LoginForm";
import styleTheme from "style/theme";

const LoginTitle = styled(Title)`
  margin: ${({ theme }) => `30px 0 ${theme.margin.large}`};
`;

const AdminLogin = (props: RouteComponentProps) => {
  const { hasAdminRights } = useLoginState();
  if (hasAdminRights()) {
    props.history.push("/admin/inhalt/partnerprofil");
  }

  return (
    <CenteredContentViewContainer>
      <LoginTitle>{`${strings("adminPrefix")} ${strings(
        "loginForm.title",
      )}`}</LoginTitle>
      <LoginForm isAdminLogin width={styleTheme.forms.small} />
    </CenteredContentViewContainer>
  );
};

export default withRouter(AdminLogin);
