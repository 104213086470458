import React from "react";
import { ReactComponent as EditIcon } from "assets/icons/edit2.svg";
import IconActionButton from "components/buttons/IconActionButton";

const EditButton = ({
  onClick,
  order,
  tooltipKey,
}: {
  onClick: () => void;
  order?: number;
  tooltipKey?: string;
}) => (
  <IconActionButton
    onClick={onClick}
    order={order}
    tooltipKey={tooltipKey ? tooltipKey : "buttons.edit"}
  >
    <EditIcon />
  </IconActionButton>
);

export default EditButton;
