import { assocPath, dissocPath, slice, isEmpty, path } from "ramda";
import {
  SelectedTagFilters,
  SelectionStateInterface,
} from "components/lists/filtering/SelectionStateInterface";

export const selectTag = (
  idPath: string[],
  selectionState: SelectionStateInterface,
): SelectionStateInterface =>
  idPath.length
    ? assocPath(["selectedTagFilters", ...idPath], true, selectionState)
    : selectionState;

export const deselectTag = (
  idPath: string[],
  selectionState: SelectionStateInterface,
): SelectionStateInterface => {
  const { selectedTagFilters } = selectionState;

  const newTagFilters: SelectedTagFilters = dissocPath(
    idPath,
    selectedTagFilters,
  );

  const parentPath = slice(0, -1, idPath);

  if (parentPath.length && isEmpty(path(parentPath, newTagFilters))) {
    return deselectTag(parentPath, selectionState);
  }
  return {
    ...selectionState,
    selectedTagFilters: newTagFilters,
  };
};

export const isTagSelected = (
  idPath: string[],
  selectionState: SelectionStateInterface,
): boolean =>
  !!idPath.length && !!path(["selectedTagFilters", ...idPath], selectionState);

export const toggleTag = (
  idPath: string[],
  selectionState: SelectionStateInterface,
): SelectionStateInterface =>
  isTagSelected(idPath, selectionState)
    ? deselectTag(idPath, selectionState)
    : selectTag(idPath, selectionState);

export const selectTags = (
  idPaths: string[][],
  selectionState: SelectionStateInterface,
): SelectionStateInterface =>
  idPaths.reduce((acc, idPath) => selectTag(idPath, acc), selectionState);

export const selectFilter = (
  id: string,
  selectionState: SelectionStateInterface,
): SelectionStateInterface =>
  id.length
    ? assocPath(["selectedFilters", id], true, selectionState)
    : selectionState;

export const isFilterSelected = (
  id: string,
  selectionState: SelectionStateInterface,
) => !!path(["selectedFilters", id], selectionState);

export const deselectFilter = (
  id: string,
  selectionState: SelectionStateInterface,
): SelectionStateInterface =>
  dissocPath(["selectedFilters", id], selectionState);

export const toggleFilter = (
  id: string,
  selectionState: SelectionStateInterface,
): SelectionStateInterface =>
  isFilterSelected(id, selectionState)
    ? deselectFilter(id, selectionState)
    : selectFilter(id, selectionState);
