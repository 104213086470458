import React from "react";
import { withRouter } from "react-router-dom";

import ListTableView from "views/ListTableView";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import MapInterface from "api/models/MapInterface";

const AdminMaps = () => {
  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_MAPS} isView>
      <ListTableView<MapInterface> />
    </PrivilegedComponent>
  );
};

export default withRouter(AdminMaps);
