import React from "react";
import strings from "localisation/strings";
import styled from "style/styled-components";
import globalTheme from "style/theme";
import SpreadRow from "components/rows/SpreadRow";
import getPasswordStrength, {
  strengthColor,
  PasswordStrengths,
} from "components/forms/RegistrationForm/getPasswordStrength";
import PasswordStrengthLine from "components/forms/RegistrationForm/PasswordStrengthLine";

const Container = styled(SpreadRow)`
  align-items: center;
  margin-top: ${({ theme }) => theme.margin.small};
`;

const Row = styled.div`
  display: flex;
`;

const textColors = {
  ...strengthColor,
  [PasswordStrengths.NONE]: globalTheme.color.foreground.tertiary,
  [PasswordStrengths.INVALID]: globalTheme.color.foreground.tertiary,
};

const StrengthText = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

const InfoIcon = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border: solid 1px ${({ color }) => color};
  border-radius: ${({ theme }) => theme.border.radius.unlimited};
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.margin.small};
  background-color: ${({ theme }) => theme.color.background.primary};
  display: flex;
  cursor: pointer;
`;

interface PasswordStrengthProps {
  password: string;
  className?: string;
}

export const showPasswordStrengthInfo = () =>
  alert(strings("registrationScreen.fields.password.strengthMessage"));

const PasswordStrength = ({ password, className }: PasswordStrengthProps) => {
  const strength = getPasswordStrength(password);
  const textColor = textColors[strength];
  return (
    <Container className={className}>
      <Row>
        <div>
          <StrengthText color={textColor}>
            {strings(`registrationScreen.fields.password.strength.${strength}`)}
          </StrengthText>
        </div>
        <InfoIcon onClick={showPasswordStrengthInfo} color={textColor}>
          <StrengthText color={textColor}>i</StrengthText>
        </InfoIcon>
      </Row>
      <PasswordStrengthLine strength={strength} />
    </Container>
  );
};

export default PasswordStrength;
