import React, { useEffect, useMemo, useState } from "react";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import strings from "localisation/strings";
import styled from "style/styled-components";
import { createMandate, saveCreditCard } from "api/businessProfiles";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import { RouteComponentProps } from "react-router";
import { routeNames } from "core/navigation";
import SepaDataForm, {
  SepaDataFormValues,
} from "views/BusinessSettings/Payment/forms/SepaDataForm";
import CreditCardDataForm, {
  CreditCardFormValues,
} from "views/BusinessSettings/Payment/forms/CreditCardDataForm";
import { SelectItem } from "components/forms/Select";
import PaymentMethodSelect from "views/BusinessSettings/Payment/forms/PaymentMethodSelectField";
import useLocale from "common/hooks/useLocale";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";
import {
  CreateCreditCardDTO,
  CreateMandateDTO,
  PaymentMethodType,
} from "api/models/Payments";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import { makeCancelable, noop } from "utils";
import useNotificationState from "hooks/useNotification";
import BusinessSettingsSidebar from "views/BusinessSettings/BusinessSettingsSidebar";

const SepaCardFormContainer = styled.div`
  margin-top: ${({ theme }) => theme.margin.larger};
`;

interface AddPaymentParams {
  methodType?: PaymentMethodType;
  isBackAvailable?: boolean;
  useSidebar?: boolean;
  submitButtonText?: string;
  submitRedirect?: string;
  paymentConfirmationRedirect?: string;
  exitRedirect?: string;
  isOnboarding?: boolean;
}

export type AddPaymentRouteProps = RouteComponentProps<
  object,
  object,
  AddPaymentParams
>;

const BusinessSettingsAddPayment = ({
  history,
  location: {
    state: {
      methodType = "",
      submitButtonText = "",
      isBackAvailable = true,
      submitRedirect = routeNames.BusinessSettings.Payment,
      paymentConfirmationRedirect = routeNames.BusinessSettings
        .PaymentConfirmation,
      exitRedirect = routeNames.BusinessSettings.Payment,
      useSidebar = true,
      isOnboarding = false,
    } = {},
  },
}: AddPaymentRouteProps) => {
  const payoneIntegrationEnabled = isPayoneIntegrationEnabled();
  const { addSuccessNotification } = useNotificationState();
  const defaultPaymentType = payoneIntegrationEnabled ? "CREDIT_CARD" : "SEPA";
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(
    methodType || defaultPaymentType,
  );
  const {
    makeRequest: makeCreateMandateRequest,
    result: createMandateRequestResult,
    error: createMandateRequestError,
    status: createMandateRequestStatus,
  } = useMutation(createMandate);
  const {
    makeRequest: makeSaveCreditCardRequest,
    error: saveCreditCardError,
    status: saveCreditCardStatus,
  } = useMutation(saveCreditCard);
  const locale = useLocale();
  const isMethodTypePreselected = !!methodType;
  const { setShouldBlockNavigation, unblockNavigation } = useGlobalFormBlock();
  const paymentMethods: SelectItem<PaymentMethodType>[] = useMemo(
    () => [
      {
        value: "CREDIT_CARD",
        label: strings("businessSettings.paymentEdit.paymentOptions.card"),
        isDisabled: false,
      },
      {
        value: "SEPA",
        label: strings("businessSettings.paymentEdit.paymentOptions.bank"),
        isDisabled: false,
      },
    ],
    [locale],
  );

  const handleCreditCardFormSubmitted = (formData: CreditCardFormValues) => {
    const payload: CreateCreditCardDTO = {
      ...formData,
      onboarding: isOnboarding,
    };
    makeSaveCreditCardRequest(payload).then(noop);
  };

  useEffect(() => {
    const handleCreditCardDataSaveSuccess = async () => {
      await unblockNavigation();
      history.push(submitRedirect);
    };

    if (saveCreditCardStatus === QueryStatus.SUCCESSFUL) {
      const { cancel, promise } = makeCancelable(
        handleCreditCardDataSaveSuccess(),
      );
      promise.catch(noop);
      return cancel;
    }
  }, [saveCreditCardStatus, unblockNavigation]);

  const handleFormSubmitted = (formData: SepaDataFormValues) => {
    const payload: CreateMandateDTO = {
      iban: formData.iban,
      owner: formData.owner,
      defaultPaymentMethod: formData.defaultPaymentMethod,
      onboarding: isOnboarding,
      paymentAddress: formData.paymentAddress,
    };
    makeCreateMandateRequest(payload).then(noop);
  };

  const handleValidCreditCardDetected = () => {
    setShouldBlockNavigation(true);
  };

  const handlePaymentMethodChanged = (method: SelectItem) => {
    setPaymentMethodType(method.value as PaymentMethodType);
  };

  const handlePaymentMethodFormExited = () => {
    history.push(exitRedirect);
  };

  useEffect(() => {
    const handleMandateCreationSuccess = async () => {
      if (createMandateRequestResult) {
        await unblockNavigation();

        if (createMandateRequestResult.mandateConfirmed) {
          // Mandate has already been confirmed previously, go directly to payment screen
          if (!isOnboarding) {
            addSuccessNotification(
              strings("businessSettings.paymentMandate.successMessage"),
            ).then(noop);
          }
          history.push(submitRedirect);
        } else {
          history.push({
            pathname: paymentConfirmationRedirect,
            state: {
              mandateText: createMandateRequestResult.mandateText,
              confirmingDefaultMethod:
                createMandateRequestResult.defaultPaymentMethod,
            },
          });
        }
      }
    };

    if (
      createMandateRequestResult &&
      createMandateRequestStatus === QueryStatus.SUCCESSFUL
    ) {
      const { cancel, promise } = makeCancelable(
        handleMandateCreationSuccess(),
      );
      promise.catch(noop);
      return cancel;
    }
  }, [
    createMandateRequestResult,
    createMandateRequestStatus,
    unblockNavigation,
  ]);

  return (
    <BusinessSettingsLayout
      title={strings("businessSettings.paymentEdit.title")}
      subtitle={strings("businessSettings.paymentEdit.comments")}
      sidebar={useSidebar ? <BusinessSettingsSidebar /> : undefined}
    >
      <SepaCardFormContainer>
        {payoneIntegrationEnabled && !isMethodTypePreselected && (
          <PaymentMethodSelect
            paymentMethods={paymentMethods}
            onPaymentMethodChanged={handlePaymentMethodChanged}
          />
        )}
        {paymentMethodType === "CREDIT_CARD" && (
          <CreditCardDataForm
            submitButtonText={submitButtonText}
            onValidCardDetected={handleValidCreditCardDetected}
            onSubmit={handleCreditCardFormSubmitted}
            isSubmittable={saveCreditCardStatus !== QueryStatus.WAITING}
            isBackAvailable={isBackAvailable}
            onFormLeft={handlePaymentMethodFormExited}
            errors={saveCreditCardError}
          />
        )}
        {paymentMethodType === "SEPA" && (
          <SepaDataForm
            submitButtonText={submitButtonText}
            errors={createMandateRequestError}
            onSubmit={handleFormSubmitted}
            onFormLeft={handlePaymentMethodFormExited}
            isBackAvailable={isBackAvailable}
            isSubmittable={createMandateRequestStatus !== QueryStatus.WAITING}
          />
        )}
      </SepaCardFormContainer>
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsAddPayment;
