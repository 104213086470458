import styled from "style/styled-components";

export const CenteredContent = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.large};
  max-width: ${({ theme }) => theme.content.sizes.small};
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
`;
