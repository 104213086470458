import "mdn-polyfills/Node.prototype.remove";
import { useEffect, useRef } from "react";

const createRootElement = (id: string) => {
  const root = document.createElement("div");
  root.setAttribute("id", id);
  return root;
};

const addRootElement = (root: Element) => {
  const lastElementChild = document.body.lastElementChild;
  if (!lastElementChild) {
    return;
  }

  if (!lastElementChild.nextElementSibling) {
    return;
  }

  document.body.insertBefore(root, lastElementChild.nextElementSibling);
};

const usePortal = (id: string) => {
  const rootRef = useRef<Element | null>(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parent = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parent);
    }

    if (!rootRef.current) {
      return () => {
        if (!parent.childElementCount) {
          parent.remove();
        }
      };
    }

    parent.appendChild(rootRef.current);

    return () => {
      // Don't use the following, otherwise portals will then only load once!
      // if (rootRef.current) {
      //   rootRef.current.remove();
      // }
      // if (!parent.childElementCount) {
      //   parent.remove();
      // }
    };
  }, [id]);

  const getRootElement = () => {
    if (!rootRef.current) {
      rootRef.current = document.createElement("div");
    }

    return rootRef.current;
  };

  return getRootElement();
};

export default usePortal;
