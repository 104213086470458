import React from "react";
import styled from "style/styled-components";
import getEmojiTextComponents from "components/helpers/getEmojiTextComponents";

const LINE_HEIGHT = 30;

export const StyledTitle = styled.p`
  color: ${({ theme }) => theme.color.foreground.secondaryDark};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: 800;
  font-size: ${({ theme }) => theme.font.size.xlarge};
  line-height: ${LINE_HEIGHT}px;
  margin: 0;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/*
  When a user type a string like //smile//, the value will automatically be converted to custum emoji.
*/
const EmojiTitle = ({ title }: { title: string }) => {
  return (
    <StyledTitle>{getEmojiTextComponents(title, LINE_HEIGHT)}</StyledTitle>
  );
};

export default EmojiTitle;
