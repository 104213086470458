import styled from "styled-components";
import ActionButton from "components/buttons/ActionButton";

const ListHeaderActionButton = styled(ActionButton)<{
  smallMargin?: boolean;
}>`
  height: 30px;
  min-width: 100px;
  padding: 0;
  margin: 0
    ${({ theme, smallMargin }) =>
      smallMargin ? theme.margin.xsmall : theme.margin.large};
`;

export default ListHeaderActionButton;
