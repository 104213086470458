import React, { ReactNode } from "react";
import styled from "style/styled-components";
import Icon from "components/forms/FormFields/Icon";
import globalTheme from "style/theme";
import breakpoints from "style/breakpoints";

const { leftSide } = globalTheme.forms;

const boldLabelStyles = `
font-weight: bold;
font-size: 18px;
`;

const Main = styled.div<{
  leftWidth?: string | number;
  newLine?: boolean;
  justify?: string;
}>`
  display: flex;
  width: ${({ leftWidth, newLine }) =>
    leftWidth && !newLine ? `calc(100% - ${leftWidth})` : "100%"};
  margin-left: ${({ newLine }) => (newLine ? "46px" : "unset")};
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};

  > div {
    padding-top: 0;
  }
`;

const Container = styled.div<{
  margin?: string;
  newLine?: boolean;
  totalWidth: string;
}>`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-wrap: ${({ newLine }) => (newLine ? "wrap" : "nowrap")};
  width: ${({ totalWidth }) => totalWidth};
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : "10px 0")};
  .disabled {
    color: ${({ theme }) => theme.color.foreground.disabled};
    background-color: ${({ theme }) => theme.color.background.primary};
  }
  @media ${breakpoints.phoneMax} {
    flex-direction: column;
    align-items: flex-start;
    ${Main} {
      width: 100%;
      margin-left: unset;
    }
  }
`;

export const Label = styled.span<{
  isBold?: boolean;
  disabled?: boolean;
  wrapText?: boolean;
  additionalTopPadding?: boolean;
}>`
  ${({ wrapText, theme }) =>
    wrapText
      ? ""
      : `
    height: ${theme.formElements.text.height}px;
    line-height: ${theme.formElements.text.height}px;
    white-space: nowrap;
  `}
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.color.foreground.tertiary
      : theme.components.inlineInput.textColor};
  padding-right: ${({ theme }) => theme.margin.medium};
  ${({ isBold }) => (isBold ? boldLabelStyles : null)};

  padding-top: ${({ additionalTopPadding, theme }) =>
    additionalTopPadding ? theme.margin.medium : "0"};
`;

const OffsetContainer = styled.div`
  height: ${({ theme }) => theme.formElements.text.height}px;
  line-height: ${({ theme }) => theme.formElements.text.height}px;

  > span {
    vertical-align: middle;
  }
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-left: ${({ theme }) => theme.margin.medium};
`;

const Left = styled.span<{ width?: string | number; noPlaceholder: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "auto")};
  @media ${breakpoints.phoneMax} {
    display: ${({ noPlaceholder }) => noPlaceholder && "none"};
  }
`;

const InlineInputGroup = ({
  children,
  icon,
  offsetContent,
  placeholder,
  margin,
  contentOnNewLine,
  boldLabel,
  width = leftSide,
  totalWidth = "100%",
  className,
  disabled,
  justify,
  wrapText,
  active,
  additionalLabelTopPadding,
}: {
  children?: ReactNode;
  icon?: string;
  offsetContent?: ReactNode;
  placeholder?: string;
  width?: string | number;
  margin?: string;
  contentOnNewLine?: boolean;
  boldLabel?: boolean;
  className?: string;
  disabled?: boolean;
  totalWidth?: string;
  justify?: string;
  wrapText?: boolean;
  active?: boolean;
  additionalLabelTopPadding?: boolean;
}) => (
  <Container
    margin={margin}
    newLine={contentOnNewLine}
    className={className}
    totalWidth={totalWidth}
  >
    <Left noPlaceholder={!placeholder} width={width}>
      {icon ? (
        <OffsetContainer>
          <StyledIcon backgroundImage={icon} active={active} />
        </OffsetContainer>
      ) : (
        offsetContent && <OffsetContainer>{offsetContent}</OffsetContainer>
      )}
      {placeholder && (
        <Label
          isBold={boldLabel}
          className={className}
          disabled={disabled}
          wrapText={wrapText}
          additionalTopPadding={additionalLabelTopPadding}
        >
          {placeholder}
        </Label>
      )}
    </Left>
    <Main
      leftWidth={!contentOnNewLine ? width : undefined}
      newLine={contentOnNewLine}
      justify={justify}
    >
      {children}
    </Main>
  </Container>
);

export default InlineInputGroup;
