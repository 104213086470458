import moment, { Moment } from "moment";

const currentMoment = moment().startOf("minute");

const format = "YYYY-MM-DD";

export const getMinute = (min: number): number => {
  if (min > 0 && min <= 15) return 15;
  if (min > 15 && min <= 30) return 30;
  if (min > 30 && min <= 45) return 45;
  return 0;
};

export const validateDateTime = (
  date: Date,
  minDate: Moment = currentMoment,
): Date => {
  // Don't mutate date
  const newDate = moment(date).startOf("minute");

  const dateMoment = moment(date).startOf("minute");

  // is same day?
  if (dateMoment.format(format) === minDate.format(format)) {
    const dateMomentHour = dateMoment.get("hour");
    const minDateHour = minDate.get("hour");
    const hourInPast = dateMomentHour < minDateHour;

    const dateMomentMinute = dateMoment.get("minute");
    const minDateMinute = minDate.get("minute");
    const minuteInPast = dateMomentMinute < minDateMinute;

    // If hour in past (10 < 23) use minDateHour
    if (hourInPast) newDate.set("hour", minDateHour);

    // If both hour and minute in past use minDateHour
    const minute =
      hourInPast && minuteInPast ? minDateMinute : dateMomentMinute;
    const validMin = getMinute(minute);

    // Set valid minute
    newDate.set("minute", validMin);

    // If minute > 0, then it's next hour
    if (minute > 45) newDate.add(1, "hour");
  }

  return newDate.toDate();
};
