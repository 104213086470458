import React from "react";
import useLoginState from "hooks/useLoginState";
import { generatePath, Redirect } from "react-router";
import { routeNames } from "core/navigation";

const BusinessSettingsAdminEntryPoint = () => {
  const businessId = useLoginState().getBusinessId();
  const isDefaultCompanyContext = useLoginState().isDefaultBusinessContext();

  if (!businessId) {
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: generatePath(
          isDefaultCompanyContext
            ? routeNames.BusinessSettings.AdditionalSettingsAdmin
            : routeNames.BusinessSettings.PlanAdmin,
          {
            businessId,
          },
        ),
      }}
    />
  );
};

export default BusinessSettingsAdminEntryPoint;
