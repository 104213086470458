import React from "react";
import placeholder from "assets/imgPlaceholder.png";
import strings from "localisation/strings";

export default class ItemImage extends React.Component<
  Partial<HTMLImageElement>,
  { imageError: boolean }
> {
  state = { imageError: false };

  render() {
    return (
      <img
        src={
          this.state.imageError || !this.props.src
            ? placeholder
            : this.props.src
        }
        onError={e => this.setState({ imageError: true })}
        alt={strings("event.imageAltTexts.event")}
      />
    );
  }
}
