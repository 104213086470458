import React from "react";
import strings from "localisation/strings";
import { HintTooltip } from "components/generic";
import SimpleText, { BoldText } from "components/generic/SimpleText";

export interface ParticipationsForCouponProps {
  participations?: number;
}

const promotionTooltip = {
  id: "promotion-tooltip",
  hint: "detailedScreen.promoParticipationsHint",
};

const ParticipationsQuantity = ({
  participations,
}: ParticipationsForCouponProps) => {
  if (participations) {
    return (
      <HintTooltip tooltipData={promotionTooltip}>
        <SimpleText>
          {strings("offersScreen.offerList.participations")}
          {": "}
          <BoldText>{participations}</BoldText>
        </SimpleText>
      </HintTooltip>
    );
  }

  return null;
};

export default ParticipationsQuantity;
