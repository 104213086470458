import React from "react";
import { curry } from "ramda";
import strings from "localisation/strings";
import useMutation from "common/hooks/useMutation";
import { deleteUserData } from "api/users";
import DeleteButton from "components/buttons/DeleteButton";
import useNotification from "hooks/useNotification";

interface Props {
  userId: string;
}

const UserDeleteButton = ({ userId }: Props) => {
  const userLogCreatingRequest = curry(deleteUserData);
  const { makeRequest } = useMutation(userLogCreatingRequest);
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleClick = async () => {
    const response = (await makeRequest(userId)) || {};

    if (response.error) {
      addErrorNotification(strings("adminPublicUsersPage.errors.generic"));
    } else {
      addSuccessNotification(strings("adminPublicUsersPage.alert.userDeleted"));
      location.reload();
    }
  };

  return <DeleteButton onClick={handleClick} id={userId} />;
};

export default UserDeleteButton;
