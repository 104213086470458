import React, { useEffect } from "react";
import strings from "localisation/strings";
import PlanSelectionForm from "components/forms/PlanSelectionForm";
import useQuery from "common/hooks/useQuery";
import {
  getAvailablePlansAdmin,
  getBusinessPlanAdmin,
  updateBusinessPlanAdmin,
  updateBusinessExtraContentLimitAdmin,
  isContractAvailableAdmin,
  downloadContractAdmin,
} from "api/businessProfiles";
import Plan from "api/models/Plan";
import useNotificationState from "hooks/useNotification";
import { curry } from "ramda";
import useMutation from "common/hooks/useMutation";
import BusinessSettingsAdminSidebar from "views/BusinessSettings/BusinessSettingsAdminSidebar";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import PlanHistoryList from "components/lists/planSettings/PlanHistoryList";
import Title from "components/generic/Title";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";
import CurrentPlanHorizontalCardOrLink from "views/BusinessSettings/PlanSelection/components/CurrentPlanHorizontalCardOrLink";
import { useHistory, generatePath } from "react-router";
import { routeNames } from "core/navigation";
import { isFinished, isFailed, isLoading } from "utils";
import PlanExpiryNotice from "views/BusinessSettings/PlanSelection/components/PlanActivationNotice";
import DownloadButton from "components/buttons/DownloadButton";

const getAvailablePlans = (plans: Plan[], currentPlan: Plan | null): Plan[] => {
  if (!currentPlan) {
    return plans;
  }
  // add current plan into list of available plans as there is a possibility that it is not longer available (date validity)
  if (plans.filter(p => p.planId === currentPlan.planId).length === 0) {
    return [currentPlan].concat(plans);
  }
  return plans;
};

const ContractDownloadButton = ({ businessId }: Props) => (
  <DownloadButton
    request={() => downloadContractAdmin(businessId)}
    text={strings("businessSettings.plan.contract.download")}
  />
);

interface Props {
  businessId: string;
}

const BusinessSettingsPlanSelectionAdmin = ({ businessId }: Props) => {
  const { result: plans } = useQuery({
    request: curry(getAvailablePlansAdmin)(businessId),
  });
  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const { makeRequest: makeUpdateBusinessPlanRequest } = useMutation(
    curry(updateBusinessPlanAdmin),
  );
  const {
    makeRequest: makeUpdateExtraPostsLimitRequest,
    status: updateExtraPostsLimitStatus,
    error: updateExtraPostsLimitError,
    result: updateBusinessExtraContentLimitResult,
  } = useMutation(curry(updateBusinessExtraContentLimitAdmin)(businessId));
  const { result: planHistory, status: planHistoryStatus } = useQuery({
    request: curry(getBusinessPlanAdmin)(businessId),
    compare: [businessId, updateBusinessExtraContentLimitResult],
  });
  const { result: contractAvailable } = useQuery({
    request: curry(isContractAvailableAdmin)(businessId),
  });

  const handleSave = async (planId: string) => {
    if (!planId) {
      addErrorNotification(
        strings("businessSettings.plan.error.noPlanSelected"),
      );
      return;
    }
    const response =
      (await makeUpdateBusinessPlanRequest({
        businessId,
        planId,
        packagePrice: 0,
      })) || {};

    if (response && response.error) {
      addErrorNotification(response.error.detail);
    } else {
      addSuccessNotification(strings("generic.success"));
    }
  };

  const history = useHistory();
  const handleChangePlanRequested = () => {
    const changePlanPath = generatePath(
      routeNames.BusinessSettings.PlanChangeAdmin,
      {
        businessId,
      },
    );
    history.push(changePlanPath);
  };

  const handleExtraLimitEdited = (updatedContentLimit: number) => {
    makeUpdateExtraPostsLimitRequest({
      extraContentLimit: updatedContentLimit,
    });
  };

  useEffect(() => {
    if (isFinished(updateExtraPostsLimitStatus)) {
      addSuccessNotification(strings("generic.success"));
    }

    if (isFailed(updateExtraPostsLimitStatus)) {
      addErrorNotification(
        updateExtraPostsLimitError && updateExtraPostsLimitError.detail,
      );
    }
  }, [updateExtraPostsLimitStatus]);

  if (isPayoneIntegrationEnabled()) {
    return (
      <BusinessSettingsLayout
        expand
        size="large"
        sidebar={<BusinessSettingsAdminSidebar />}
        title={strings("businessSettings.plan.title")}
        isUpdating={isLoading(planHistoryStatus, updateExtraPostsLimitStatus)}
        titleRowButton={
          contractAvailable ? (
            <ContractDownloadButton businessId={businessId} />
          ) : (
            undefined
          )
        }
      >
        {planHistory && (
          <>
            <PlanExpiryNotice planHistory={planHistory} />
            <CurrentPlanHorizontalCardOrLink
              extraLimitEditable
              plan={planHistory.currentPlan as Plan}
              onChangePlan={handleChangePlanRequested}
              onExtraLimitEdited={handleExtraLimitEdited}
            />
            <Title fixedMargins topMargined>
              {strings("businessSettings.plan.history.title")}
            </Title>
            <PlanHistoryList planHistory={planHistory} />
          </>
        )}
      </BusinessSettingsLayout>
    );
  }

  return (
    <BusinessSettingsLayout
      sidebar={<BusinessSettingsAdminSidebar />}
      title={strings("businessSettings.plan.title")}
      isLoading={isLoading(planHistoryStatus)}
      titleRowButton={
        contractAvailable ? (
          <ContractDownloadButton businessId={businessId} />
        ) : (
          undefined
        )
      }
    >
      {plans && planHistory && (
        <PlanSelectionForm
          plans={getAvailablePlans(plans, planHistory.currentPlan)}
          selectedPlanId={
            planHistory.currentPlan ? planHistory.currentPlan.planId : undefined
          }
          onSave={handleSave}
        />
      )}
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsPlanSelectionAdmin;
