import React from "react";
import PaymentsFormInlineInputGroup from "views/BusinessSettings/Payment/shared/PaymentsFormInlineInputGroup";
import useFormFields from "hooks/useFormFields";
import SelectComponent, {
  SelectItem,
  MaterialDropdownIndicator,
} from "components/forms/Select";
import languageIcon from "assets/icons/language.svg";
import strings from "localisation/strings";
import appTheme from "style/theme";

interface Props {
  paymentMethods: SelectItem[];
  onPaymentMethodChanged: (selectedMethod: SelectItem) => void;
}

const PaymentMethodSelect = ({
  paymentMethods,
  onPaymentMethodChanged,
}: Props) => {
  const { getSelectFieldProps } = useFormFields({
    selectFields: {
      methodType: "CREDIT_CARD",
    },
    translationScope: "businessSettings.paymentEdit.methodForm",
  });

  const methodTypeFieldProps = getSelectFieldProps(
    "methodType",
    paymentMethods,
  );

  const handleSelectValueChanged = (value: SelectItem) => {
    methodTypeFieldProps.onChange(value);
    onPaymentMethodChanged(value);
  };

  return (
    <PaymentsFormInlineInputGroup
      icon={languageIcon}
      placeholder={strings(
        "businessSettings.paymentEdit.methodForm.methodType.label",
      )}
      width={appTheme.forms.planPrice}
    >
      <SelectComponent
        {...methodTypeFieldProps}
        onChange={handleSelectValueChanged}
        dropDownIndicator={MaterialDropdownIndicator}
        classNamePrefix="credit-card-select"
        small
      />
    </PaymentsFormInlineInputGroup>
  );
};

export default PaymentMethodSelect;
