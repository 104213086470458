import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import ListTableView from "views/ListTableView";
import CategoryInterface from "api/models/CategoryInterface";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { SUB } from "constants/strings";

const Categories = ({ history }: RouteComponentProps) => {
  const onClickCopy = (id: string) => history.push(`kategorien/${id}`);
  const onClickEdit = (id: string, questionId?: string, isSub?: boolean) => {
    const linkPrefix = "/admin/konfiguration/kategorien";
    let link = `${linkPrefix}/${id}`;
    if (isSub) {
      link = questionId
        ? `${linkPrefix}/${SUB}/${id}?qid=${questionId}`
        : `${linkPrefix}/${SUB}/${id}`;
    }
    history.push(link);
  };

  const onClickAdd = (id: string) => history.push(`kategorien/${SUB}/${id}`);

  return (
    <PrivilegedComponent
      id={PrivilegedComponentsIds.VIEW_CATEGORIES_ADMIN}
      isView
    >
      <ListTableView<CategoryInterface>
        onClickEdit={onClickEdit}
        onClickCopy={onClickCopy}
        onClickAdd={onClickAdd}
      />
    </PrivilegedComponent>
  );
};

export default withRouter(Categories);
