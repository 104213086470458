import React, { useEffect } from "react";
import styled, { isMobile } from "style/styled-components";
import cardIcon from "assets/icons/plans.svg";
import strings from "localisation/strings";
import ActionButton from "components/buttons/ActionButton";
import ActionArea, { withBackButton } from "components/generic/ActionArea";
import { BodyText, Title } from "components/generic";
import { InlineTextField } from "components/forms/FormFields/InlineTextField";
import useFormFields from "hooks/useFormFields";
import FieldMargin from "components/forms/FieldMargin";
import Checkbox from "components/forms/Checkbox";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import PaymentsFormInlineInputGroup from "views/BusinessSettings/Payment/shared/PaymentsFormInlineInputGroup";
import usePayoneClient from "views/BusinessSettings/Payment/hooks/usePayoneClient";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import useDefaultPaymentMethodStatus from "views/BusinessSettings/Payment/hooks/useDefaultPaymentMethodStatus";
import PaymentAddressDetails from "views/BusinessSettings/Payment/shared/PaymentAddressDetails";
import {
  defaultCreditCardDataFields,
  setCreditCardFields,
} from "components/helpers/formHelpers/paymentsDetails";
import { PaymentAddressDTO } from "api/models/Payments";
import appTheme from "style/theme";
import transparentIcon from "assets/icons/transparent.svg";
import useWindowDimensions from "hooks/useWindowDimensions";

const Section = styled.div`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

const OffsetContentDiv = styled.div`
  width: 46px;
`;

const ExpiryDateCVCSeparator = styled.div`
  width: ${({ theme }) => theme.margin.xxlarge};
`;

const CardPanInput = styled.span`
  width: ${({ theme }) => theme.forms.full};
`;

const PaymentsInlineTextField = styled(InlineTextField).attrs({
  isUnbranded: true,
})``;

const ExpiryDateCVCContainer = styled.div`
  display: flex;
  position: relative;
  line-height: 32px;
  flex-wrap: wrap;
`;

interface ActionAreaProps {
  isSubmittable: boolean;
  onSubmit: () => void;
  text?: string;
}

const CreditCardDataFormActionArea = withBackButton(
  ({
    isSubmittable,
    onSubmit,
    text = strings("businessSettings.paymentEdit.sepaForm.submit.label"),
  }: ActionAreaProps) => (
    <ActionArea>
      <ActionButton
        alone
        disabled={!isSubmittable}
        special="publish"
        text={text}
        onClick={onSubmit}
      />
    </ActionArea>
  ),
);

export interface CreditCardFormValues extends PayoneCreditCardData {
  owner: string;
  defaultPaymentMethod: boolean;
  paymentAddress?: PaymentAddressDTO;
}

interface Props {
  errors?: APIErrorResponse;
  isSubmittable: boolean;
  submitButtonText?: string;
  isBackAvailable: boolean;
  onSubmit: (values: CreditCardFormValues) => void;
  onValidCardDetected: () => void;
  onFormLeft: () => void;
}

const CreditCardDataForm = ({
  errors,
  onSubmit,
  onFormLeft,
  onValidCardDetected,
  isSubmittable,
  submitButtonText,
  isBackAvailable,
}: Props) => {
  const {
    validateCreditCardData,
    isValidating,
    isCreditCardValid,
    isInitialised,
  } = usePayoneClient();
  const {
    values,
    getCheckboxFieldProps,
    getTextFieldProps,
    getPaymentAddressProps,
    getErrors,
    onChange,
    setGenericError,
    parseErrors,
    updateValues,
  } = useFormFields({
    ...defaultCreditCardDataFields,
    blockNavigation: true,
    handleGenericErrors: true,
    translationScope: "businessSettings.paymentEdit.creditCardForm",
  });

  const {
    isDefaultPaymentMethodStatusLoading,
    isDefaultPaymentMethod,
    isDefaultPaymentMethodMissing,
  } = useDefaultPaymentMethodStatus("CREDIT_CARD");

  const precheckDefaultPaymentStatus =
    isDefaultPaymentMethod || isDefaultPaymentMethodMissing;
  useEffect(() => {
    if (!isDefaultPaymentMethodStatusLoading) {
      onChange("defaultPaymentMethod", precheckDefaultPaymentStatus);
    }
  }, [precheckDefaultPaymentStatus, isDefaultPaymentMethodStatusLoading]);

  const setSavedPaymentAddress = (savedPaymentAddress: PaymentAddressDTO) => {
    const updatePaymentAddress = setCreditCardFields(
      savedPaymentAddress,
      precheckDefaultPaymentStatus,
    );
    updateValues(updatePaymentAddress);
  };

  useEffect(() => {
    return errors && parseErrors(errors);
  }, [errors]);

  useEffect(() => {
    if (isCreditCardValid) {
      onValidCardDetected();
    }
  }, [
    isCreditCardValid,
    // onValidCardDetected // can safely be skipped due to independence from state
  ]);

  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);

  const handleFormSubmitted = () => {
    const { owner, defaultPaymentMethod, paymentAddress } = values;

    validateCreditCardData()
      .then(validationResult => {
        onSubmit({
          owner,
          defaultPaymentMethod,
          paymentAddress,
          ...validationResult,
        });
      })
      .catch(error => {
        setGenericError(error.message);
      });
  };

  return (
    <>
      <Title fixedMargins>
        {strings("businessSettings.paymentEdit.creditCardTitle")}
      </Title>
      <input type="hidden" name="pseudocardpan" id="pseudocardpan" />
      <input type="hidden" name="truncatedcardpan" id="truncatedcardpan" />

      <PaymentsFormInlineInputGroup
        icon={cardIcon}
        placeholder={strings(
          "businessSettings.paymentEdit.creditCardForm.owner.placeholder",
        )}
        width={appTheme.forms.planPrice}
      >
        <PaymentsInlineTextField
          {...getTextFieldProps("owner")}
          width={appTheme.forms.full}
        />
      </PaymentsFormInlineInputGroup>

      <PaymentsFormInlineInputGroup
        icon={cardIcon}
        placeholder={strings(
          "businessSettings.paymentEdit.methodForm.cardpan.label",
        )}
        width={appTheme.forms.planPrice}
      >
        <CardPanInput id="cardpan" />
      </PaymentsFormInlineInputGroup>

      <ExpiryDateCVCContainer>
        <PaymentsFormInlineInputGroup
          offsetContent={<OffsetContentDiv />}
          placeholder={strings(
            "businessSettings.paymentEdit.methodForm.expiryDate.label",
          )}
          width={appTheme.forms.planPrice}
          totalWidth={isMobileDevice ? appTheme.forms.full : "40px"}
        >
          <span id="cardexpiremonth" />
          <span id="cardexpireyear" />
        </PaymentsFormInlineInputGroup>
        {!isMobileDevice && <ExpiryDateCVCSeparator />}
        <PaymentsFormInlineInputGroup
          icon={isMobileDevice ? transparentIcon : ""}
          placeholder={strings(
            "businessSettings.paymentEdit.methodForm.cardcvc2.label",
          )}
          totalWidth={"80px"}
          width={isMobileDevice ? appTheme.forms.planPrice : "60px"}
          margin={"0px"}
        >
          <span id="cardcvc2" />
        </PaymentsFormInlineInputGroup>
      </ExpiryDateCVCContainer>

      {!isInitialised && <AbsoluteOverlaySpinner />}

      <Section>
        <FieldMargin>
          <Checkbox
            {...getCheckboxFieldProps("defaultPaymentMethod")}
            errors={getErrors("defaultPaymentMethod")}
            disabled={precheckDefaultPaymentStatus}
          />
        </FieldMargin>
      </Section>

      <Section>
        <BodyText small>
          {strings("businessSettings.paymentEdit.creditCardFooter")}
        </BodyText>
      </Section>

      <Section>
        <PaymentAddressDetails
          {...getPaymentAddressProps(setSavedPaymentAddress)}
        />
      </Section>

      <CreditCardDataFormActionArea
        text={submitButtonText}
        isBackButtonEnabled={isBackAvailable}
        onBackButtonClicked={onFormLeft}
        isActionAreaOneElement={true}
        isSubmittable={isInitialised && !isValidating && isSubmittable}
        onSubmit={handleFormSubmitted}
      />
    </>
  );
};

export default CreditCardDataForm;
