import useNotification from "hooks/useNotification";
import strings from "localisation/strings";
import React from "react";
import TextButton from "components/buttons/TextButton";
import ButtonWithConfirmation from "components/buttons/ButtonWithConfirmation";
import { AbortableRequest } from "common/api/AbortableRequest";

interface Props {
  replyId: string;
  deleteReply: (replyId: string) => AbortableRequest<void>;
  onReplyDeleted: () => void;
}
const ReviewReplyDeleteButton = ({
  replyId,
  deleteReply,
  onReplyDeleted,
}: Props) => {
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnClickToDelete = async () => {
    deleteReply(replyId)({})
      .then(() => {
        addSuccessNotification();
        onReplyDeleted();
      })
      .catch(addErrorNotification);
  };

  return (
    <ButtonWithConfirmation
      id={replyId}
      onClick={handleOnClickToDelete}
      submitButtonLabel={strings("buttons.delete")}
      actionButton={
        <TextButton text={strings("reviewReply.removeCommentButton")} />
      }
      confirmationMessage={strings("reviewReply.removeCommentConfirmation")}
      position={"center"}
    />
  );
};

export default ReviewReplyDeleteButton;
