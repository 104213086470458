import { Container } from "unstated";
import strings from "localisation/strings";

export interface GlobalFormExitPromptStateType {
  isNavigationBlocked: boolean;
  message: string;
}

const initialGlobalFormExitPromptState: GlobalFormExitPromptStateType = {
  isNavigationBlocked: false,
  message: strings("buttons.unsavedFormText"),
};

export default class GlobalFormExitPromptState extends Container<
  GlobalFormExitPromptStateType
> {
  state = initialGlobalFormExitPromptState;

  setShouldBlockNavigation = (
    isNavigationBlocked: boolean,
    message: string = strings("buttons.unsavedFormText"),
  ) =>
    this.setState({
      isNavigationBlocked,
      message,
    });

  /**
   * Unblocks navigation upon request
   * Typical usage includes:
   *  - User agreed to exit the form losing all changes made
   *  - API request finished and form data has been saved
   */
  unblockNavigation = () => this.setShouldBlockNavigation(false);
}
