import styled from "styled-components";
import SimpleText from "components/generic/SimpleText";
import ActionButton from "components/buttons/ActionButton";
import React from "react";
import breakpoints from "style/breakpoints";
import { CenteredContentViewContainer as CenteredContainer } from "components/generic";
import ActionArea from "components/generic/ActionArea";
import strings from "localisation/strings";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

const PopupArea = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.foreground.quaternary};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.color.foreground.antiPrimary};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  z-index: 20;

  @media ${breakpoints.phoneMax} {
    left: 50% !important;
    top: 50% !important;

    ::before,
    ::after {
      content: none;
    }
  }
`;

interface CustomModalProps {
  popupMessage: string;
  modalVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const CustomModal = ({
  popupMessage,
  modalVisible,
  onConfirm,
  onCancel,
}: CustomModalProps) => {
  return modalVisible ? (
    <Overlay>
      <PopupArea>
        <CenteredContainer>
          <SimpleText>{popupMessage}</SimpleText>
          <ActionArea>
            <ActionButton text={strings("buttons.cancel")} onClick={onCancel} />
            <ActionButton
              text={strings("buttons.confirm")}
              onClick={onConfirm}
              special="publish"
            />
          </ActionArea>
        </CenteredContainer>
      </PopupArea>
    </Overlay>
  ) : null;
};

export default CustomModal;
