import React from "react";
import styled, { isMobile } from "style/styled-components";
import { clone } from "ramda";
import sheetIcon from "common/assets/icons/sheet.svg";
import strings from "localisation/strings";
import { TextWithIcon } from "components/forms/FormFields";
import TextButton from "components/buttons/TextButton";
import { EventVenue } from "api/models/EventInterface";
import { DeleteIconButton } from "components/buttons/DeleteButton";
import IconActionButton from "components/buttons/IconActionButton";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
`;

const Row = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
`;

const AddButton = styled(TextButton)<{ isMobileDevice: boolean }>`
  align-self: flex-end;
  margin-right: ${({ theme }) => theme.margin.standart};
`;

interface EventVenueInputProps {
  currentExclusions: EventVenue[];
  onExclusionsChanged: (exclusions: EventVenue[]) => void;
}

const EventVenueInput = ({
  currentExclusions,
  onExclusionsChanged,
}: EventVenueInputProps) => {
  const isMobileDevice = isMobile(screen.width);
  const addNewEntry = () => {
    const newEntry = { venueId: "", name: "" };
    onExclusionsChanged([...currentExclusions, newEntry]);
  };

  const removeVenue = (venueIndex: number) => {
    const newExclusions = [...currentExclusions];
    newExclusions.splice(venueIndex, 1);
    onExclusionsChanged(newExclusions);
  };

  const updateVenues = (
    event: React.ChangeEvent<HTMLInputElement>,
    index = 0,
  ) => {
    const newValues = clone(currentExclusions);
    newValues[index] = { venueId: event.currentTarget.value };
    onExclusionsChanged(newValues);
  };

  return (
    <>
      <ColumnWrapper>
        {currentExclusions.map(({ venueId, name }, index) => (
          <Row key="venue_container">
            <React.Fragment key={index}>
              <div>
                <TextWithIcon
                  type="text"
                  value={venueId}
                  label={strings("adminVenuesScreen.venueId")}
                  icon={sheetIcon}
                  width="100%"
                  maxLength={10}
                  onChange={event => updateVenues(event, index)}
                />
              </div>
              <div>
                <TextWithIcon
                  type="text"
                  width="100%"
                  value={name}
                  label={strings("adminVenuesScreen.venueName")}
                  icon={sheetIcon}
                  disabled={true}
                  placeholder={strings(
                    "adminVenuesScreen.venueNamePlaceholder",
                  )}
                />
              </div>
              <div>
                <IconActionButton
                  tooltipKey="buttons.delete"
                  onClick={() => removeVenue(index)}
                >
                  <DeleteIconButton />
                </IconActionButton>
              </div>
            </React.Fragment>
          </Row>
        ))}

        <AddButton
          text={`+${strings("detailedScreen.artists.add")}`}
          onClick={addNewEntry}
          isMobileDevice={isMobileDevice}
        />
      </ColumnWrapper>
    </>
  );
};

export default EventVenueInput;
