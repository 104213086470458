import React from "react";
import ConfirmTooltip from "components/generic/ConfirmTooltip";
import ActionButton from "components/buttons/ActionButton";
import TextButton from "components/buttons/TextButton";

interface ClickHandlerProps {
  id: string;
  confirmationContent?: JSX.Element;
  confirmationMessage?: string;
  submitButtonLabel: string;
  actionButtonLabel?: string;
  actionButton?: JSX.Element;
  onClick: () => void;
  position?: "left" | "right" | "center";
}

const ButtonWithConfirmation = ({
  onClick,
  id,
  confirmationContent,
  confirmationMessage,
  submitButtonLabel,
  actionButtonLabel = "",
  actionButton = <TextButton text={actionButtonLabel} />,
  position,
}: ClickHandlerProps) => {
  const actionButtonElement = React.cloneElement(actionButton, {
    "data-tip": true,
    "data-place": "top",
    "data-event": "click",
    "data-for": id,
  });

  return (
    <>
      {/* NB: ReactTooltip does not trigger with onClick on wrapper */}
      {actionButtonElement}
      <ConfirmTooltip
        tooltipId={id}
        content={confirmationContent}
        position={position}
        submitButton={
          <ActionButton
            special="publish"
            onClick={onClick}
            text={submitButtonLabel}
          />
        }
        popupMessage={confirmationMessage}
      />
    </>
  );
};

export default ButtonWithConfirmation;
