import React from "react";
import styled from "styled-components";
import { SuggestionContainer } from "components/forms/FormFields/AddressAutocomplete/SuggestionsDropdown";

interface Props {
  id: string;
  isActive: boolean;
  primaryText: string;
  secondaryText: string;
}

const PrimarySuggestionLabel = styled.span`
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-weight: bold;
`;

const SecondarySuggestionLabel = styled.span`
  color: ${({ theme }) => theme.color.foreground.tertiary};
  margin-left: ${({ theme }) => theme.margin.xsmall};
`;

const Suggestion = ({
  id,
  isActive,
  primaryText,
  secondaryText,
  ...props
}: Props) => (
  <SuggestionContainer {...props} isActive={isActive} key={id}>
    <PrimarySuggestionLabel>{primaryText}</PrimarySuggestionLabel>
    <SecondarySuggestionLabel>{secondaryText}</SecondarySuggestionLabel>
  </SuggestionContainer>
);

export default Suggestion;
