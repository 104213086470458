import React from "react";
import BusinessContextSettingsArea from "components/topBar/menu/components/BusinessContextSettingsArea";
import {
  BusinessAdminTopBarMenuSubscriptionsLink,
  DrilledDownBusinessMenuChatLink,
} from "components/topBar/menu/components/links";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { Container, Gradient } from "components/topBar/menu/MenuComponents";

interface Props {
  hideBusinessName?: boolean;
  fillBackground?: boolean;
}

const DrilledDownBusinessAccountMenuAdmin = ({
  hideBusinessName,
  fillBackground,
}: Props) => (
  <Container>
    {!hideBusinessName && (
      <Container paddingLeft="16px">
        <BusinessContextSettingsArea />
      </Container>
    )}
    <Gradient />
    <Container fillBackground={fillBackground}>
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_CHAT}>
        <DrilledDownBusinessMenuChatLink />
      </PrivilegedComponent>
      <BusinessAdminTopBarMenuSubscriptionsLink />
    </Container>
  </Container>
);

export default DrilledDownBusinessAccountMenuAdmin;
