import React, { ComponentType } from "react";
import strings from "localisation/strings";
import {
  UnderlineButtonAreaWithThinLine,
  UnderlineNavLink,
} from "components/buttons/UnderlineButton";
import { SingleRoute } from "core/navigation";
import SubNavBusinessMenu from "components/topBar/TopSubNavBusinessMenu";
import styled from "style/styled-components";
import { topSubNavButtonTestId } from "testing/testId";
import { View } from "react-native";
import { isIE } from "components/helpers/browser-check";
import { NEW } from "constants/strings";

interface TopSubNavProps {
  items: SingleRoute[];
  topRoute: string;
}

const StyledUnderlineButtonAreaWithThinLine = styled(
  UnderlineButtonAreaWithThinLine,
)`
  flex: none;
`;

const SubNavBusinessMenuWrapper = styled<ComponentType<any>>(View)`
  width: max-content;
  display: flex;
  float: right;
  position: absolute;
  right: 0;
  top: 50px;
`;

const TopSubNav = ({ items, topRoute }: TopSubNavProps) =>
  !items.length ? null : (
    <>
      <StyledUnderlineButtonAreaWithThinLine
        rightAlignedChildren={<SubNavBusinessMenu />}
      >
        {items.map(({ name, path }) =>
          name.toLowerCase() === "posts" ? (
            <UnderlineNavLink
              className={`new-post`}
              activeClassName="sub-nav-active"
              key={`${topRoute}${path}/${NEW}`}
              to={`${topRoute}${path}/${NEW}`}
            >
              {strings(`tabBar.${name}_create`)}
            </UnderlineNavLink>
          ) : null,
        )}

        {items.map(({ name, path }) => (
          <UnderlineNavLink
            className={`${name.toLowerCase()}`}
            activeClassName="sub-nav-active"
            key={`${topRoute}${path}`}
            to={`${topRoute}${path}`}
            {...topSubNavButtonTestId(name)}
          >
            {strings(`tabBar.${name}`)}
          </UnderlineNavLink>
        ))}
      </StyledUnderlineButtonAreaWithThinLine>
    </>
  );

export default TopSubNav;
