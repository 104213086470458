import React, { useEffect, useRef } from "react";
import { MarketingPurposesPayload } from "api/models/UsersInterface";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import useFormFields from "hooks/useFormFields";
import Checkbox from "components/forms/Checkbox";
import { FieldAligner } from "components/forms/FieldContainer";
import { StyledMarketingPurposesButton } from "components/forms/MarketingPurposesForm/components";
import useNotification from "hooks/useNotification";
import strings from "localisation/strings";
import MarketingPurposesInterface from "api/models/MarketingPurposesInterface";

interface MarketingPurposesProps {
  currentOptIn: Partial<MarketingPurposesInterface>;
}

const MarketingPurposesForm = (props: MarketingPurposesProps) => {
  const { currentOptIn } = props;
  const {
    values,
    getCheckboxFieldProps,
    parseErrors,
    updateValues,
  } = useFormFields({
    handleGenericErrors: true,
    translationScope: "marketingPurposesScreen.fields",
    checkboxFields: {
      optIn: false,
    },
  });

  const updateValuesRef = useRef(updateValues);

  useEffect(() => {
    if (currentOptIn) {
      updateValuesRef.current({
        checkboxFields: {
          optIn: currentOptIn.optIn,
        },
      });
    }
  }, [currentOptIn]);

  const getPayload = (): MarketingPurposesPayload | undefined => values;

  const onMarketingPurposesFailed = (body: APIErrorResponse) =>
    parseErrors(body);

  const { addSuccessNotification } = useNotification();

  return (
    <>
      <FieldAligner>
        <Checkbox {...getCheckboxFieldProps("optIn")} />
      </FieldAligner>

      <StyledMarketingPurposesButton
        getPayload={getPayload}
        successfulRequestCallback={() =>
          addSuccessNotification(strings("marketingPurposesScreen.success"))
        }
        failedRequestCallback={onMarketingPurposesFailed}
      />
    </>
  );
};

export default MarketingPurposesForm;
