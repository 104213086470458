import styled from "style/styled-components";
import React, { ReactNode } from "react";

const Container = styled.div`
  box-sizing: border-box;
  height: 80px;
  border: 1px solid ${({ theme }) => theme.color.background.emptyBorder};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.background.empty};
  display: flex;
  align-items: center;
`;

const Illustration = styled.img`
  width: 57px;
  height: 43px;
  margin-left: ${({ theme }) => theme.margin.standart};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.color.foreground.emptyText};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  margin-left: ${({ theme }) => theme.margin.standart};
`;

interface Props {
  illustration?: string;
  children: ReactNode;
}

interface ISnackbar extends React.FC<Props> {
  Container: typeof Container;
  Illustration: typeof Illustration;
}

const Snackbar: ISnackbar = ({ children, illustration }) => (
  <Container>
    {illustration && <Illustration src={illustration} />}
    <TextContainer>
      <Text>{children}</Text>
    </TextContainer>
  </Container>
);

Snackbar.Container = Container;
Snackbar.Illustration = Illustration;

export default Snackbar;
