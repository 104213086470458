import styled from "style/styled-components";
import themeConfig from "style/theme";

export enum SearchBoxType {
  Default,
  Compact,
}

interface Props {
  type: SearchBoxType;
}

const getVerticalMargin = (type: SearchBoxType) =>
  ({
    [SearchBoxType.Default]: themeConfig.margin.sideMin,
    [SearchBoxType.Compact]: themeConfig.margin.small,
  }[type]);

const SearchBox = styled.div<Props>`
  position: relative;
  display: flex;
  height: ${({ theme }) => theme.formElements.text.height}px;
  width: 314px;
  border: solid 1px ${({ theme }) => theme.color.border.inputBorder};
  background-color: ${({ theme }) => theme.color.background.primary};
  border-radius: ${({ theme }) => theme.border.radius.small};
  margin: ${({ theme, type }) =>
    `${getVerticalMargin(type)} ${theme.margin.large}`};
  align-items: center;
  padding: ${({ theme }) => theme.margin.large};
`;

export default SearchBox;
