import { RouteProps } from "react-router-dom";
import AccessControlInterface from "api/models/AccessControlInterface";
import OfferDetailed from "views/OfferDetailed";
import Registration from "views/Registration";
import RegistrationSuccess from "views/RegistrationSuccess";
import BusinessChangePassword from "views/Business/BusinessChangePassword";
import AdminChangePassword from "views/Admin/AdminChangePassword";
import PasswordRecovery from "views/PasswordRecovery";
import TermsAndConditions from "views/TextScreens/TermsAndConditions";
import MarketingPurposes from "views/TextScreens/MarketingPurposes";
import Offers from "views/Offers";
import PersonalData from "views/Account/PersonalData";
import Posts from "views/Posts";
import Events from "views/Events";
import BusinessUserProfileDetailed from "views/BusinessUserProfileDetailed";
import PostDetailed from "views/PostDetailed";
import EventDetailed from "views/EventDetailed";
import NewsDetailed from "views/NewsDetailed";
import AdminCategoriesDetailed from "views/AdminCategoriesDetailed";
import BusinessUserProfiles from "views/BusinessUserProfiles";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { hasAccess, isKeepLocalEnabled } from "utils";
import LoginState from "state/LoginState";
import AdminOffers from "views/Admin/AdminOffers";
import AdminEvents from "views/Admin/AdminEvents";
import AdminPosts from "views/Admin/AdminPosts";
import AdminNews from "views/Admin/AdminNews";
import AdminBusinessRepresentativesByBusiness from "views/Admin/AdminRepresentatives";
import BusinessRepresentatives from "views/BusinessRepresentatives/index";
import AdminBusinessRepresentatives from "views/Admin/AdminBusinessRepresentatives";
import RepresentativeUserActivationView from "views/RepresentativeUserActivation";
import RepresentativeUserActivatedView from "views/RepresentativeUserActivatedView";
import AdminRepresentativeUserActivatedView from "views/Admin/AdminRepresentativeUserActivatedView";
import RepresentativeFormView from "views/BusinessRepresentativeFormView";
import AdminBusinessProfiles from "views/Admin/AdminBusinessProfiles";
import AdminCategories from "views/Admin/AdminCategories";
import WizardStepOne from "views/Wizard/WizardStepOne";
import WizardStepTwo from "views/Wizard/WizardStepTwo";
import WizardStepThree from "views/Wizard/WizardStepThree";
import WizardStepFour from "views/Wizard/WizardStepFour";
import UserActivationConfirmationView from "views/UserActivationConfirmationView";
import BusinessUserActivationView from "views/BusinessUserActivation";
import AdminLogin from "views/Admin/AdminLogin";
import AdminRecovery from "views/Admin/AdminRecovery";
import AccountActivation from "views/Admin/AccountActivation";
import AdminAllPublicUsers from "views/Admin/AdminAllPublicUsers";
import Dashboard from "views/Dashboard";
import Chat from "views/Chat/ChatMessagesView";
import ChatSettingsView from "views/Chat/ChatSettingsView";
import FAQ from "views/Footer/FAQ";
import Imprint from "views/Footer/Imprint";
import Licenses from "views/Footer/Licenses";
import PublicPortalPages from "views/PublicPortalPages";
import AccountChangePassword from "views/Account/AccountChangePassword";
import Login from "views/Login";
import BusinessSettingsPlanSelection from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanSelection";
import BusinessSettingsPayment from "views/BusinessSettings/Payment/BusinessSettingsPayment";
import BusinessSettingsAddPayment from "views/BusinessSettings/Payment/BusinessSettingsAddPayment";
import BusinessSettingsConfirmPayment from "views/BusinessSettings/Payment/BusinessSettingsConfirmPayment";
import BusinessSettingsPlanSelectionAdminEntryPoint from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanSelectionAdminEntryPoint";
import BusinessSettingsAdminEntryPoint from "views/BusinessSettings/BusinessSettingsAdminEntryPoint";
import BusinessSettingsAdditionalSettingsAdminEntryPoint from "views/BusinessSettings/AdditionalSettings/BusinessSettingsAdditionalSettingsAdminEntryPoint";
import WizardComplete from "views/Wizard/WizardComplete";
import AdminVenues from "views/Admin/AdminVenues";
import BusinessVenues from "views/BusinessSettings/BusinessVenues";
import BusinessPaymentSettingsAdmin from "views/BusinessSettings/Payment/BusinessSettingsPaymentAdmin";
import { SUB } from "constants/strings";
import UserPrivileges from "constants/UserPrivileges";
import BusinessSettingsPlanChange from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanChange";
import BusinessSettingsPlanChangeConfirmation from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanChangeConfirmation";
import BusinessSettingsPlanChangeConfirmationAdmin from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanChangeConfirmationAdmin";
import BusinessSettingsPlanChangeAdmin from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanChangeAdmin";
import AdminAppStatistics from "views/Admin/AdminAppStatistics";
import GenericError from "core/GenericError";
import {
  AddPaymentInWizard,
  PaymentConfirmationInWizard,
  PaymentInWizard,
} from "views/Wizard/WizardStepFive";
import KeepLocal from "views/KeepLocal";
import RequestDeletion from "views/RequestDeletion";
import DeletionRequestConfirmed from "views/DeletionRequestConfirmed";
import AdminMaps from "views/Admin/AdminMaps";
import AdminMapDetailed from "views/AdminMapDetailed";

export interface SingleRoute extends RouteProps, AccessControlInterface {
  name: string;
  path: string;
  key?: string;
  showNav?: boolean;
  subs?: SingleRoute[];
  redirectTo?: string;
}

export const routeNames = {
  Chat: "/chat",
  ChatSettings: "/chat/einstellungen",
  ChatBranchSelected: "/chat/partnerprofil/:branchId",
  ChatConversationSelected: "/chat/chatverlaeufe/:conversationId",
  ChatBranchAndConversationSelected:
    "/chat/partnerprofil/:branchId/chatverlaeufe/:conversationId",

  CuratorChat: "/inhalt/partner/:businessId/chat",
  CuratorChatSettings: "/inhalt/partner/:businessId/chat/einstellungen",
  CuratorChatBranchSelected:
    "/inhalt/partner/:businessId/chat/partnerprofil/:branchId",
  CuratorChatConversationSelected:
    "/inhalt/partner/:businessId/chat/chatverlaeufe/:conversationId",
  CuratorChatBranchAndConversationSelected:
    "/inhalt/partner/:businessId/chat/partnerprofil/:branchId/chatverlaeufe/:conversationId",

  AdminChat: "/admin/inhalt/partner/:businessId/chat",
  AdminChatSettings: "/admin/inhalt/partner/:businessId/chat/einstellungen",
  AdminChatBranchSelected:
    "/admin/inhalt/partner/:businessId/chat/partnerprofil/:branchId",
  AdminChatConversationSelected:
    "/admin/inhalt/partner/:businessId/chat/chatverlaeufe/:conversationId",
  AdminChatBranchAndConversationSelected:
    "/admin/inhalt/partner/:businessId/chat/partnerprofil/:branchId/chatverlaeufe/:conversationId",

  Configuration: "/admin/konfiguration",
  Statistics: "/admin/statistiken",

  PublicPortalPages: {
    Parent: "/public-portal-seiten",
    Terms: "agb",
    Marketing: "marketingzwecke",
    Faq: "fragen-und-antworten",
    About: "impressum-und-datenschutz",
    Imprint: "impressum",
    Datenschutz: "datenschutz",
    QrCode: "qr-code",
    getFullPath: (item: string) =>
      `/admin/konfiguration/public-portal-seiten/${item}`,
  },

  BusinessSettings: {
    Plan: "/einstellungen/paket",
    PlanChange: "/einstellungen/paket-aendern",
    PlanChangeAdmin:
      "/admin/inhalt/partner/:businessId/einstellungen/paket-aendern",

    PlanChangeConfirmation: "/einstellungen/paketbestaetigung-aendern",
    PlanChangeConfirmationAdmin:
      "/admin/inhalt/partner/:businessId/einstellungen/paketbestaetigung-aendern",

    Payment: "/einstellungen/zahlung",
    AddPayment: "/einstellungen/zahlung/erstellen",
    PaymentConfirmation: "/einstellungen/zahlung/bestaetigen",

    PaymentAdmin: "/admin/inhalt/partner/:businessId/einstellungen/zahlung",
    ParentAdmin: "/admin/inhalt/partner/:businessId/einstellungen",
    PlanAdmin: "/admin/inhalt/partner/:businessId/einstellungen/paket",
    AdditionalSettingsAdmin:
      "/admin/inhalt/partner/:businessId/einstellungen/zusaetzliche-einstellungen",
    BusinessVenues:
      "/admin/inhalt/partner/:businessId/einstellungen/veranstaltungsorte",
  },

  WizardStep: {
    First: "/onboarding-profil",
    Second: "/onboarding-partnerprofil",
    Third: "/onboarding-paketauswahl",
    Fourth: "/onboarding-vertrag",
    Payment: "/onboarding-zahlung",
    AddPayment: "/onboarding-zahlung/erstellen",
    PaymentConfirmation: "/onboarding-zahlung/bestaetigen",
    Completed: "/onboarding-abgeschlossen",
    Error: "/fehler",
  },
};

// Routes, which also describe top and sub navigation menu structure
const navMenuRoutes = (loginState?: LoginState): SingleRoute[] => {
  // has business profile been completed for this account?
  const isProfileCompleted = !!(loginState && loginState.getProfileCompleted());
  // are we "navigating" in context of a particular business?
  const isDrillDownByBusiness = !!(loginState && loginState.getBusinessId());
  // is this a context of a default business?
  const isDrillDownForDefaultBusiness = !!(
    isDrillDownByBusiness &&
    loginState &&
    loginState.isDefaultBusinessContext()
  );
  const isDrillDownForMainBusiness = !!(
    isDrillDownByBusiness &&
    loginState &&
    loginState.isMainBusinessContext()
  );

  const getUserHasPrivilege = (id: string) => {
    // eslint-disable-next-line
    const privilegedComponentSet = loginState?.getPrivilegedComponentSet();
    return !!privilegedComponentSet && privilegedComponentSet.has(id);
  };

  const bpScopeSubs = [
    {
      name: "Dashboard",
      path: "/cockpit",
      exact: true,
      showNav:
        !isDrillDownByBusiness &&
        !getUserHasPrivilege(PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD),
      privilegeId: PrivilegedComponentsIds.MENU_DASHBOARD,
      component: Dashboard,
    },
    {
      exact: true,
      name: "Offers",
      path: "/angebote",
      showNav: !isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
      component: Offers,
    },
    {
      name: "OffersDetailed",
      path: "/angebote/:id",
      privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
      component: OfferDetailed,
    },
    {
      name: "Events",
      path: "/veranstaltungen",
      privilegeId: PrivilegedComponentsIds.MENU_EVENTS,
      showNav: !isDrillDownByBusiness,
      component: Events,
    },
    {
      name: "Event",
      path: "/veranstaltungen/:id",
      privilegeId: PrivilegedComponentsIds.MENU_EVENTS,
      exact: true,
      component: EventDetailed,
    },
    {
      name: "Posts",
      path: "/beitraege",
      showNav: !isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_POSTS,
      component: Posts,
    },
    {
      name: "Post",
      path: "/beitraege/:id",
      privilegeId: PrivilegedComponentsIds.MENU_POSTS,
      exact: true,
      component: PostDetailed,
    },
    {
      name: "BusinessProfile",
      path: "/partnerprofil",
      showNav: !isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
      component: BusinessUserProfiles,
    },
    {
      name: "KeepLocal",
      path: "/keeplocal",
      showNav:
        isKeepLocalEnabled() &&
        !isDrillDownByBusiness &&
        !getUserHasPrivilege(PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD),
      privilegeId: PrivilegedComponentsIds.MENU_KEEP_LOCAL,
      component: KeepLocal,
    },
    {
      name: "BusinessProfileDetailed",
      path: "/partnerprofil/:id",
      privilegeId: PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
      component: BusinessUserProfileDetailed,
    },
    {
      name: "Representatives",
      path: "/partnervertreter",
      showNav:
        !getUserHasPrivilege(PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD) &&
        !isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_REPRESENTATIVES,
      component: BusinessRepresentatives,
    },
    {
      name: "RepresentativeDetailed",
      path: "/partnervertreter/:id",
      privilegeId: PrivilegedComponentsIds.MENU_REPRESENTATIVES,
      component: RepresentativeFormView,
    },
    {
      name: "RepresentativeDetailed",
      path: "/partner/:businessId/partnervertreter/:id",
      privilegeId: PrivilegedComponentsIds.VIEW_REPRESENTATIVES,
      component: RepresentativeFormView,
    },
    {
      name: "Dashboard",
      path: "/partner/:businessId/cockpit",
      exact: true,
      showNav: isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD,
      component: Dashboard,
    },
    {
      name: "Offers",
      path: "/partner/:businessId/angebote",
      showNav: isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_OFFERS,
      component: Offers,
    },
    {
      name: "OffersDetailed",
      path: "/partner/:businessId/angebote/:id",
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_OFFERS,
      component: OfferDetailed,
    },
    {
      name: "Events",
      path: "/partner/:businessId/veranstaltungen",
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_EVENTS,
      showNav: isDrillDownByBusiness,
      component: Events,
    },
    {
      name: "Event",
      path: "/partner/:businessId/veranstaltungen/:id",
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_EVENTS,
      exact: true,
      component: EventDetailed,
    },
    {
      name: "Posts",
      path: "/partner/:businessId/beitraege",
      showNav: isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_POSTS,
      component: Posts,
    },
    {
      name: "Post",
      path: "/partner/:businessId/beitraege/:id",
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_POSTS,
      exact: true,
      component: PostDetailed,
    },
    {
      name: "BusinessProfile",
      path: "/partner/:businessId/partnerprofil",
      showNav: isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_BUSINESS_PROFILE,
      component: BusinessUserProfiles,
    },
    {
      name: "BusinessProfileDetailed",
      path: "/partner/:businessId/partnerprofil/:id",
      privilegeId: PrivilegedComponentsIds.MENU_CURATOR_BUSINESS_PROFILE,
      component: BusinessUserProfileDetailed,
    },
    {
      name: "KeepLocal",
      path: "/partner/:businessId/keeplocal",
      showNav:
        getUserHasPrivilege(PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD) &&
        isDrillDownForMainBusiness &&
        isKeepLocalEnabled(),
      privilegeId: PrivilegedComponentsIds.MENU_KEEP_LOCAL,
      component: KeepLocal,
    },
    {
      name: "Representatives",
      path: "/partner/:businessId/partnervertreter",
      showNav:
        getUserHasPrivilege(PrivilegedComponentsIds.MENU_CURATOR_DASHBOARD) &&
        isDrillDownByBusiness,
      privilegeId: PrivilegedComponentsIds.MENU_REPRESENTATIVES,
      component: BusinessRepresentatives,
    },
  ];

  return [
    {
      name: "Main",
      // This route is a dummy one to have a sub-menu for business administrators, who have no top menu
      // We show it only if business profile is completed (otherwise, we show wizard only)
      path: "",
      showNav: isProfileCompleted,
      privilegeId: PrivilegedComponentsIds.MENU_DASHBOARD,
      subs: [
        ...bpScopeSubs,
        {
          name: "ChatSettings",
          key: "ChatSettings",
          path: routeNames.ChatSettings,
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: ChatSettingsView,
        },
        {
          name: "Chat",
          key: "Chat",
          path: routeNames.Chat,
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "ChatFilteredBusinessBranch",
          key: "Chat",
          path: routeNames.ChatBranchSelected,
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "ChatFilteredConversation",
          key: "Chat",
          path: routeNames.ChatConversationSelected,
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "ChatFilteredBusinessBranchConversation",
          key: "Chat",
          path: routeNames.ChatBranchAndConversationSelected,
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "BusinessSettingsPlan",
          path: "/einstellungen/paket",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN,
          component: BusinessSettingsPlanSelection,
        },
        {
          name: "BusinessSettingsPlanChange",
          path: "/einstellungen/paket-aendern",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN,
          component: BusinessSettingsPlanChange,
        },
        {
          name: "BusinessSettingsPlanChangeConfirmation",
          path: "/einstellungen/paketbestaetigung-aendern",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN,
          component: BusinessSettingsPlanChangeConfirmation,
        },
        {
          name: "BusinessSettingsPayment",
          path: routeNames.BusinessSettings.Payment,
          privilegeId: PrivilegedComponentsIds.VIEW_PAYMENTS,
          component: BusinessSettingsPayment,
        },
        {
          name: "BusinessSettingsAddPayment",
          path: routeNames.BusinessSettings.AddPayment,
          privilegeId: PrivilegedComponentsIds.EDIT_PAYMENTS,
          component: BusinessSettingsAddPayment,
        },
        {
          name: "BusinessSettingsPaymentConfirmation",
          path: routeNames.BusinessSettings.PaymentConfirmation,
          privilegeId: PrivilegedComponentsIds.EDIT_PAYMENTS,
          component: BusinessSettingsConfirmPayment,
        },
      ],
    },
    {
      name: "Content",
      path: "/inhalt",
      showNav: true,
      privilegeId: PrivilegedComponentsIds.MENU_CONTENT_CURATOR,
      redirectTo: "/inhalt/partnerprofil",
      subs: [
        ...bpScopeSubs,
        {
          name: "ChatSettings",
          key: "ChatSettings",
          path: "/partner/:businessId/chat/einstellungen",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: ChatSettingsView,
        },
        {
          name: "Chat",
          key: "Chat",
          path: "/partner/:businessId/chat",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "ChatFilteredBusinessBranch",
          key: "Chat",
          path: "/partner/:businessId/chat/partnerprofil/:branchId",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "ChatFilteredConversation",
          key: "Chat",
          path: "/partner/:businessId/chat/chatverlaeufe/:conversationId",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
        {
          name: "ChatFilteredBusinessBranchConversation",
          key: "Chat",
          path:
            "/partner/:businessId/chat/partnerprofil/:branchId/chatverlaeufe/:conversationId",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT,
          component: Chat,
        },
      ],
    },
    {
      name: "AdminLogin",
      path: "/admin/anmelden",
      component: AdminLogin,
    },
    {
      name: "AdminLoginRedirect",
      path: "/admin",
      exact: true,
      redirectTo: "/admin/anmelden",
    },
    {
      name: "AdminLoginRedirectToAnmelden",
      path: "/admin/login",
      exact: true,
      redirectTo: "/admin/anmelden",
    },
    {
      name: "AdminRecovery",
      path: "/admin/wiederherstellen",
      component: AdminRecovery,
    },
    {
      name: "Content",
      path: "/admin/inhalt",
      showNav: true,
      privilegeId: PrivilegedComponentsIds.MENU_CONTENT,
      redirectTo: "/admin/inhalt/partnerprofil",
      subs: [
        {
          name: "Businesses",
          path: "/partnerprofil",
          showNav: !isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_BUSINESSES,
          component: AdminBusinessProfiles,
        },
        {
          name: "BusinessProfileDetailed",
          path: "/partnerprofil/:businessId/:id",
          privilegeId: PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
          component: BusinessUserProfileDetailed,
        },
        {
          name: "BusinessProfileDetailed",
          path: "/partnerprofil/:id",
          privilegeId: PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
          component: BusinessUserProfileDetailed,
        },
        {
          name: "Offers",
          path: "/angebote",
          showNav: !isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
          component: AdminOffers,
        },
        {
          name: "OffersDetailed",
          path: "/angebote/:id",
          privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
          component: OfferDetailed,
        },
        {
          name: "Events",
          path: "/veranstaltungen",
          privilegeId: PrivilegedComponentsIds.MENU_EVENTS,
          showNav: !isDrillDownByBusiness,
          component: AdminEvents,
        },
        {
          name: "Event",
          path: "/veranstaltungen/:id",
          privilegeId: PrivilegedComponentsIds.MENU_EVENTS,
          exact: true,
          component: EventDetailed,
        },
        {
          name: "Posts",
          path: "/beitraege",
          showNav: !isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_POSTS,
          component: AdminPosts,
        },
        {
          name: "Post",
          path: "/beitraege/:id",
          privilegeId: PrivilegedComponentsIds.MENU_POSTS,
          exact: true,
          component: PostDetailed,
        },
        {
          name: "News",
          path: "/news",
          privilegeId: PrivilegedComponentsIds.MENU_NEWS,
          showNav: !isDrillDownByBusiness,
          component: AdminNews,
        },
        {
          name: "News",
          path: "/news/:id",
          privilegeId: PrivilegedComponentsIds.MENU_NEWS,
          component: NewsDetailed,
        },
        // business account specific menu items
        {
          name: "Dashboard",
          path: "/partner/:businessId/cockpit",
          exact: true,
          showNav: isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
          component: Dashboard,
        },
        {
          name: "Offers",
          path: "/partner/:businessId/angebote",
          showNav: isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
          component: AdminOffers,
        },
        {
          name: "OffersDetailed",
          path: "/partner/:businessId/angebote/:id",
          privilegeId: PrivilegedComponentsIds.MENU_OFFERS,
          component: OfferDetailed,
        },
        {
          name: "Events",
          path: "/partner/:businessId/veranstaltungen",
          privilegeId: PrivilegedComponentsIds.MENU_EVENTS,
          showNav: isDrillDownByBusiness,
          component: AdminEvents,
        },
        {
          name: "Event",
          path: "/partner/:businessId/veranstaltungen/:id",
          privilegeId: PrivilegedComponentsIds.MENU_EVENTS,
          exact: true,
          component: EventDetailed,
        },
        {
          name: "Posts",
          path: "/partner/:businessId/beitraege",
          showNav: isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_POSTS,
          component: AdminPosts,
        },
        {
          name: "Post",
          path: "/partner/:businessId/beitraege/:id",
          privilegeId: PrivilegedComponentsIds.MENU_POSTS,
          exact: true,
          component: PostDetailed,
        },
        {
          name: "BusinessProfile",
          path: "/partner/:businessId/partnerprofil",
          showNav: isDrillDownByBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
          component: BusinessUserProfiles,
        },
        {
          name: "BusinessProfileDetailed",
          path: "/partner/:businessId/partnerprofil/:id",
          privilegeId: PrivilegedComponentsIds.MENU_BUSINESS_PROFILE,
          component: BusinessUserProfileDetailed,
        },
        {
          name: "KeepLocal",
          path: "/partner/:businessId/keeplocal",
          showNav: isDrillDownByBusiness && isKeepLocalEnabled(),
          privilegeId: PrivilegedComponentsIds.MENU_KEEP_LOCAL,
          component: KeepLocal,
        },
        {
          name: "Representatives",
          path: "/partner/:businessId/partnervertreter",
          showNav: isDrillDownByBusiness && !isDrillDownForDefaultBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_REPRESENTATIVES,
          component: AdminBusinessRepresentativesByBusiness,
        },
        {
          name: "Users",
          path: "/partner/:businessId/partnervertreter",
          showNav: isDrillDownForDefaultBusiness,
          privilegeId: PrivilegedComponentsIds.MENU_USERS_REPRESENTATIVES,
          component: AdminBusinessRepresentativesByBusiness,
        },
        {
          name: "RepresentativeDetailed",
          path: "/partner/:businessId/partnervertreter/:id",
          privilegeId: PrivilegedComponentsIds.VIEW_REPRESENTATIVES,
          component: RepresentativeFormView,
        },
        {
          name: "BusinessSettingsAdmin",
          path: "/partner/:businessId/einstellungen",
          component: BusinessSettingsAdminEntryPoint,
        },
        {
          name: "BusinessSettingsPlanChangeConfirmationAdmin",
          path: "/partner/:businessId/einstellungen/paketbestaetigung-aendern",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN_ADMIN,
          component: BusinessSettingsPlanChangeConfirmationAdmin,
        },
        {
          name: "BusinessSettingsPlanChangeAdmin",
          path: "/partner/:businessId/einstellungen/paket-aendern",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN_ADMIN,
          component: BusinessSettingsPlanChangeAdmin,
        },
        {
          name: "BusinessSettingsAdminPlan",
          path: "/partner/:businessId/einstellungen/paket",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN_ADMIN,
          component: BusinessSettingsPlanSelectionAdminEntryPoint,
        },
        {
          name: "BusinessSettingsAdminAdditional",
          path: "/partner/:businessId/einstellungen/zusaetzliche-einstellungen",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN_ADMIN,
          component: BusinessSettingsAdditionalSettingsAdminEntryPoint,
        },
        {
          name: "BusinessVenues",
          path: "/partner/:businessId/einstellungen/veranstaltungsorte",
          privilegeId: PrivilegedComponentsIds.VIEW_PLAN_ADMIN,
          component: BusinessVenues,
        },
        {
          name: "BusinessAdminSettingsPayment",
          path: "/partner/:businessId/einstellungen/zahlung",
          privilegeId: PrivilegedComponentsIds.VIEW_PAYMENTS_ADMIN,
          component: BusinessPaymentSettingsAdmin,
        },
        {
          name: "BusinessAdminChat",
          key: "AdminChat",
          path: "/partner/:businessId/chat",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT_ADMIN,
          component: Chat,
        },
        {
          name: "BusinessAdminChatBranchSelected",
          key: "AdminChat",
          path: "/partner/:businessId/chat/partnerprofil/:branchId",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT_ADMIN,
          component: Chat,
        },
        {
          name: "BusinessAdminChatConversationSelected",
          key: "AdminChat",
          path: "/partner/:businessId/chat/chatverlaeufe/:conversationId",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT_ADMIN,
          component: Chat,
        },
        {
          name: "BusinessAdminChatBranchConversationSelected",
          key: "AdminChat",
          path:
            "/partner/:businessId/chat/partnerprofil/:branchId/chatverlaeufe/:conversationId",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT_ADMIN,
          component: Chat,
        },
        {
          name: "BusinessAdminChatSettings",
          key: "AdminChatSettings",
          path: "/partner/:businessId/chat/einstellungen",
          privilegeId: PrivilegedComponentsIds.VIEW_CHAT_ADMIN,
          component: ChatSettingsView,
        },
      ],
    },
    {
      name: "Users",
      path: "/admin/nutzer",
      showNav: true,
      privilegeId: PrivilegedComponentsIds.MENU_USERS,
      redirectTo: "/admin/nutzer/partnervertreter",
      subs: [
        {
          name: "PublicUsers",
          path: "/endnutzer",
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_USERS_PUBLIC,
          component: AdminAllPublicUsers,
        },
        {
          name: "RepresentativeUsers",
          path: "/partnervertreter",
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_USERS_REPRESENTATIVES,
          component: AdminBusinessRepresentatives,
        },
        {
          name: "RepresentativeDetailed",
          path: "/partnervertreter/partner/:businessId/partnervertreter/:id",
          privilegeId: PrivilegedComponentsIds.VIEW_REPRESENTATIVES,
          component: RepresentativeFormView,
        },
        {
          name: "PublicUserDetailed",
          path: "/endnutzer/:id",
          privilegeId: PrivilegedComponentsIds.VIEW_REPRESENTATIVES,
        },
      ],
    },
    {
      name: "Configuration",
      path: routeNames.Configuration,
      showNav: true,
      privilegeId: PrivilegedComponentsIds.MENU_CONFIGURATION,
      redirectTo: `${routeNames.Configuration}/kategorien`,
      subs: [
        {
          name: "Categories",
          path: "/kategorien",
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_CATEGORIES,
          component: AdminCategories,
        },
        {
          name: "Categories",
          path: "/kategorien/:id",
          privilegeId: PrivilegedComponentsIds.MENU_CATEGORIES,
          component: AdminCategoriesDetailed,
        },
        {
          name: "Sub Categories",
          path: `/kategorien/${SUB}/:id`,
          privilegeId: PrivilegedComponentsIds.MENU_CATEGORIES,
          component: AdminCategoriesDetailed,
        },
        {
          name: "PublicPortalPages",
          path: routeNames.PublicPortalPages.Parent,
          redirectTo: routeNames.PublicPortalPages.getFullPath(
            routeNames.PublicPortalPages.Terms,
          ),
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_PUBLIC_PORTAL_PAGES,
          component: PublicPortalPages,
        },
        {
          name: "PublicPortalPagesSubs",
          path: `${routeNames.PublicPortalPages.Parent}/:id(${routeNames.PublicPortalPages.Terms}|${routeNames.PublicPortalPages.Marketing}|${routeNames.PublicPortalPages.Faq}|${routeNames.PublicPortalPages.About}|${routeNames.PublicPortalPages.Imprint}|${routeNames.PublicPortalPages.Datenschutz}|${routeNames.PublicPortalPages.QrCode})`,
          showNav: false,
          privilegeId: PrivilegedComponentsIds.MENU_PUBLIC_PORTAL_PAGES,
          component: PublicPortalPages,
        },
        {
          name: "Venues",
          path: "/veranstaltungsorte",
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_VENUES,
          component: AdminVenues,
        },
        {
          name: "Maps",
          path: "/karten",
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_MAPS,
          component: AdminMaps,
        },
        {
          name: "Categories",
          path: "/karten/:id",
          privilegeId: PrivilegedComponentsIds.MENU_MAPS,
          component: AdminMapDetailed,
        },
      ],
    },
    {
      name: "Statistics",
      path: routeNames.Statistics,
      showNav: true,
      privilegeId: PrivilegedComponentsIds.MENU_STATISTICS,
      redirectTo: `${routeNames.Statistics}/app`,
      subs: [
        {
          name: "AppStats",
          path: "/app",
          showNav: true,
          privilegeId: PrivilegedComponentsIds.MENU_STATISTICS,
          component: AdminAppStatistics,
        },
      ],
    },
  ];
};

// @ToDo: implement real navigation routes
const getFooterRoutes = (): SingleRoute[] => {
  return [
    {
      name: "faq",
      path: "/fragen-und-antworten",
      showNav: true,
      component: FAQ,
    },
    {
      exact: true,
      name: "imprint",
      path: "/impressum-und-datenschutz",
      showNav: true,
      component: Imprint,
    },
    {
      name: "licenses",
      path: "/lizenzen",
      component: Licenses,
    },
  ];
};

// Other routes, which are not displayed as navigation menu items
const routes = (loginState: LoginState): SingleRoute[] => {
  const homePageUrl = getHomePageUrl(loginState);
  return [
    {
      name: "LandingPage",
      path: "/",
      exact: true,
      onlyIfUnauthenticated: true,
      redirectTo: "/anmelden",
    },
    {
      name: "HomePage",
      path: "/",
      exact: true,
      requireAuthenticatedUser: true,
      redirectTo: homePageUrl,
    },
    {
      name: "Login",
      path: "/anmelden",
      component: Login,
    },
    {
      name: "Registration",
      path: "/registrieren",
      component: Registration,
    },
    {
      name: "RegistrationSuccess",
      path: "/registrierung-erfolgreich",
      component: RegistrationSuccess,
    },
    {
      name: "ChangePassword",
      path: "/konto/passwort-zuruecksetzen/:token",
      component: BusinessChangePassword,
    },
    {
      name: "AdminChangePassword",
      path: "/admin/konto/passwort-zuruecksetzen/:token",
      component: AdminChangePassword,
    },
    {
      name: "PasswordRecovery",
      path: "/wiederherstellen",
      component: PasswordRecovery,
    },
    {
      name: "RequestDeletion",
      path: "/konto-loeschen",
      component: RequestDeletion,
    },
    {
      name: "DeletionRequestConfirmed",
      path: "/kontoloeschungsanfrage-bestaetigt/:token",
      component: DeletionRequestConfirmed,
    },
    {
      name: "TermsAndConditions",
      path: "/agb",
      component: TermsAndConditions,
    },
    {
      name: "MarketingPurposes",
      path: "/marketingzwecke",
      component: MarketingPurposes,
    },
    {
      name: "AccountActivation",
      path: "/konto/aktivieren/:token",
      component: UserActivationConfirmationView,
    },
    {
      name: "BusinessUserActivation",
      path: "/partner-konto/aktivieren/:token",
      component: BusinessUserActivationView,
    },
    {
      name: "AccountPersonalData",
      path: "/konto/persoenliche-angaben",
      privilegeId: PrivilegedComponentsIds.MENU_PERSONAL_DATA,
      component: PersonalData,
    },
    {
      name: "AccountChangePassword",
      path: "/konto/passwort-aendern",
      component: AccountChangePassword,
    },
    {
      name: "RepresentativeActivation",
      path: "/partnervertreter-konto/aktivieren/:token",
      component: RepresentativeUserActivationView,
    },
    {
      name: "WizardStepOne",
      path: routeNames.WizardStep.First,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: WizardStepOne,
    },
    {
      name: "WizardStepTwo",
      path: routeNames.WizardStep.Second,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: WizardStepTwo,
    },
    {
      name: "WizardStepThree",
      path: routeNames.WizardStep.Third,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: WizardStepThree,
    },
    {
      name: "WizardStepFour",
      path: routeNames.WizardStep.Fourth,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: WizardStepFour,
    },
    {
      name: "PaymentInWizard",
      path: routeNames.WizardStep.Payment,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: PaymentInWizard,
    },
    {
      name: "AddPaymentInWizard",
      path: routeNames.WizardStep.AddPayment,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: AddPaymentInWizard,
    },
    {
      name: "PaymentConfirmationInWizard",
      path: routeNames.WizardStep.PaymentConfirmation,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: PaymentConfirmationInWizard,
    },
    {
      name: "WizardComplete",
      path: routeNames.WizardStep.Completed,
      privilegeId: PrivilegedComponentsIds.VIEW_WIZARD,
      component: WizardComplete,
    },
    {
      name: "RepresentativeActivated",
      path: "/konto/partnervertreter-aktiviert",
      component: RepresentativeUserActivatedView,
    },
    {
      name: "InternalAdminActivation",
      path: "/admin/partnervertreter-konto/aktivieren/:token",
      component: AccountActivation,
    },
    {
      name: "InternalAdminActivated",
      path: "/admin/konto/partnervertreter-aktiviert",
      component: AdminRepresentativeUserActivatedView,
    },
    {
      name: "GenericError",
      path: routeNames.WizardStep.Error,
      component: GenericError,
    },

    ...getFooterRoutes(),
  ];
};
const getRoutes = (loginState: LoginState): SingleRoute[] => {
  return loginState.isUserLoggedIn()
    ? getRoutesLoggedIn(loginState)
    : getRoutesNotLoggedIn(loginState);
};

const getRoutesLoggedIn = (loginState: LoginState): SingleRoute[] => {
  return routes(loginState)
    .concat(navMenuRoutes(loginState))
    .filter(route => hasAccess(route, loginState));
};

const getRoutesNotLoggedIn = (loginState: LoginState): SingleRoute[] => {
  const mainSubRoutes: SingleRoute[] = [];
  const navRoutes = navMenuRoutes(loginState);

  // If user is not logged in, then pull the subRoutes out from under "Main" to make them visible to the route path matcher
  // while the dummy "Main" route will be removed to avoid a wildcard matcher for all route paths(because any path starts with "")
  navRoutes.forEach(route => {
    if (route.name === "Main" && route.subs) {
      mainSubRoutes.push(...route.subs);
    }
  });

  return routes(loginState)
    .concat(navRoutes)
    .filter(route => route.name !== "Main")
    .concat(mainSubRoutes);
};

const getTopNavRoutes = (loginState?: LoginState): SingleRoute[] => {
  return navMenuRoutes(loginState).filter(
    route => route.showNav && hasAccess(route, loginState),
  );
};

const getCurrentTopRoot = (
  pathname: string,
  loginState?: LoginState,
): SingleRoute | null => {
  return (
    getTopNavRoutes(loginState).find(item => pathname.startsWith(item.path)) ||
    null
  );
};

const getTopRootPath = (pathname: string, loginState?: LoginState): string => {
  const currentTopRoute = getCurrentTopRoot(pathname, loginState);
  return currentTopRoute ? currentTopRoute.path : "";
};

const interpolatePath = (
  route: SingleRoute,
  businessId: string,
): SingleRoute => {
  const interpolatedRoute: SingleRoute = Object.assign({}, route);
  interpolatedRoute.path = route.path.replace(":businessId", businessId);

  return interpolatedRoute;
};

const getTopSubNavRoutes = (
  pathname: string,
  loginState?: LoginState,
): SingleRoute[] => {
  const currentTopRoute = getCurrentTopRoot(pathname, loginState);
  let topSubNavRoutes: SingleRoute[] = [];
  if (
    currentTopRoute &&
    hasAccess(currentTopRoute, loginState) &&
    currentTopRoute.subs &&
    currentTopRoute.subs.length
  ) {
    topSubNavRoutes = currentTopRoute.subs;
  }

  topSubNavRoutes = topSubNavRoutes.filter(
    route => route.showNav && hasAccess(route, loginState),
  );
  if (topSubNavRoutes && topSubNavRoutes.length > 0) {
    const businessId = loginState && loginState.getBusinessId();
    if (businessId) {
      // routes, which contain business ID placeholder in the path, should be interpolated with current businessId value
      topSubNavRoutes = topSubNavRoutes.map(route => {
        return route.path.includes(":businessId")
          ? interpolatePath(route, businessId)
          : route;
      });
    }
  }

  return topSubNavRoutes;
};

const getPathForContentItem = (
  pathPrefix: string,
  itemId: string,
  adminRights: boolean,
  curatorRights: boolean,
  contextBusinessId?: string,
): string => {
  let pathStart = "";
  if (adminRights) {
    pathStart = contextBusinessId
      ? `/admin/inhalt/partner/${contextBusinessId}`
      : "/admin/inhalt";
  } else if (curatorRights) {
    pathStart = contextBusinessId
      ? `/inhalt/partner/${contextBusinessId}`
      : "/inhalt";
  }

  return `${pathStart}${pathPrefix}/${itemId}`;
};

const updateBusinessIdForNavigation = (
  pathname: string,
  loginState?: LoginState,
): void => {
  if (loginState) {
    let id = "";
    const startPos = pathname.indexOf("/partner/");
    if (startPos !== -1) {
      id = pathname.substr(startPos + "/partner/".length);
      const endPos = id.indexOf("/");
      if (endPos !== -1) {
        id = id.substr(0, endPos);
      }
    }
    if (loginState.getBusinessId() !== id) {
      // We are switching in context of the business (or exiting it, if ID is undefined)
      loginState.setBusinessId(id);
    }
  }
};

const getRedirectTo = (
  privileges: UserPrivileges[],
  profileCompleted: boolean,
  profilePublished: boolean,
): string => {
  if (privileges.includes(UserPrivileges.TYPE_INTERNAL)) {
    return "/admin/inhalt/partnerprofil";
  }
  if (privileges.includes(UserPrivileges.TYPE_CURATOR)) {
    return "/inhalt/partnerprofil";
  }
  if (profileCompleted) {
    return profilePublished ? "/cockpit" : "/partnerprofil";
  }
  return privileges.includes(UserPrivileges.SETTINGS_BP_WRITE)
    ? routeNames.WizardStep.First
    : routeNames.WizardStep.Error;
};

const getHomePageUrl = (loginState: LoginState): string => {
  const {
    isUserLoggedIn,
    getProfileCompleted,
    getProfilePublished,
    getUserPrivileges,
  } = loginState;

  if (isUserLoggedIn()) {
    return getRedirectTo(
      getUserPrivileges(),
      getProfileCompleted(),
      getProfilePublished(),
    );
  }
  return window.location.pathname.includes("/admin/")
    ? "/admin/anmelden"
    : "/anmelden";
};

export {
  getPathForContentItem,
  getRoutes,
  getTopNavRoutes,
  getTopRootPath,
  getTopSubNavRoutes,
  updateBusinessIdForNavigation,
  getFooterRoutes,
  getHomePageUrl,
};
