import React from "react";
import { BusinessTopBarMenuChatLink } from "components/topBar/menu/components/links";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import BusinessContextSettingsArea from "components/topBar/menu/components/BusinessContextSettingsArea";
import { Container, Gradient } from "components/topBar/menu/MenuComponents";

interface Props {
  hideBusinessName?: boolean;
  fillBackground?: boolean;
}

const BusinessAccountMenu = ({ hideBusinessName, fillBackground }: Props) => (
  <Container>
    {!hideBusinessName && (
      <Container paddingLeft="16px">
        <BusinessContextSettingsArea />
      </Container>
    )}
    <Container>
      <Gradient />
    </Container>
    <Container fillBackground={fillBackground}>
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_CHAT}>
        <BusinessTopBarMenuChatLink />
      </PrivilegedComponent>
    </Container>
  </Container>
);

export default BusinessAccountMenu;
