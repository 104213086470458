import styled from "style/styled-components";

const SimpleText = styled.span`
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.color.font.onLightBackground};
  flex-shrink: 1;
`;

export const BoldText = styled(SimpleText)`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: bold;
`;

export default SimpleText;
