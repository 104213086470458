import styled from "style/styled-components";

const InputListTag = styled.div`
  height: 18px;
  color: ${({ theme }) => theme.color.background.primary};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.07px;
  line-height: 18px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.component.tagBackground};
  display: inline-block;
  padding: 0 ${({ theme }) => theme.margin.xsmall};
  margin: ${({
    theme: {
      margin: { xsmall },
    },
  }) => `0 ${xsmall} ${xsmall} 0`};
  cursor: pointer;
`;

const TransparentTag = styled(InputListTag)`
  border: 1px solid ${({ theme }) => theme.color.component.tagBorder};
  background: transparent;
  color: ${({ theme }) => theme.color.component.tagBorder};
`;

const RemoveButton = styled.span`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.component.removeButtonBg};
  display: inline-block;
  margin-left: ${({ theme }) => theme.margin.medium};
  position: relative;
  top: 3px;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 7px;
    background: ${({ theme }) => theme.color.component.removeButtonIcon};
    position: absolute;
    top: 3px;
    left: 6px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export { TransparentTag, RemoveButton };
export default InputListTag;
