import React, { useState } from "react";
import styled from "styled-components";
import Checkbox from "components/forms/Checkbox";
import { ReactComponent as TristateIcon } from "assets/icons/tristate.svg";
import DropdownArrow from "components/generic/DropdownArrow";

const SubList = styled.div<{ depth: number }>`
  margin-left: ${({ theme, depth }) =>
    `calc(${depth} * ${theme.margin.large})`};
`;

const StyledRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

const ItemArea = styled.div`
  display: flex;
  flex-flow: column;
`;

const Spacer = styled.div`
  width: 50px;
  margin-right: ${({ theme }) => theme.margin.small};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

interface NestedItemInterface {
  name: string;
  id: string;
  subItems?: NestedItemInterface[] | null;
  subItemsKey?: string;
}

interface TristateCheckboxProps {
  selected: boolean;
  onToggle: () => void;
  name: string;
  partiallySelected?: boolean;
}

interface RowProps extends TristateCheckboxProps {
  isParentTag: boolean;
  subItemsVisible: boolean;
  onToggleSubItemsVisible: () => void;
}

interface NestedCheckboxProps extends NestedItemInterface {
  selected?: boolean;
  idPath: string[];
  isItemSelected: (idPath: string[]) => boolean;
  toggleItem: (idPath: string[]) => void;
  selectItems: (idPaths: string[][]) => void;
}

const TristateCheckbox = ({
  selected,
  onToggle,
  name,
  partiallySelected,
}: TristateCheckboxProps) => (
  <Checkbox
    checked={selected}
    name={name}
    onChange={onToggle}
    text={name}
    checkComponent={partiallySelected ? <TristateIcon /> : undefined}
  />
);

const Row = ({
  isParentTag,
  subItemsVisible,
  onToggleSubItemsVisible,
  ...props
}: RowProps) => (
  <StyledRow>
    <Spacer onClick={isParentTag ? onToggleSubItemsVisible : undefined}>
      {isParentTag ? (
        subItemsVisible ? (
          <DropdownArrow direction="down" />
        ) : (
          <DropdownArrow direction="right" />
        )
      ) : null}
    </Spacer>
    <TristateCheckbox {...props} />
  </StyledRow>
);

const NestedCheckbox = ({
  name,
  selected,
  isItemSelected,
  subItems,
  idPath,
  toggleItem,
  selectItems,
}: NestedCheckboxProps) => {
  const [subItemsVisible, setSubItemsVisible] = useState(false);
  const onToggleSubItemsVisible = () => setSubItemsVisible(!subItemsVisible);

  const onToggle = () => toggleItem(idPath);

  // return early if not parent tag
  if (!subItems || !subItems.length) {
    return (
      <ItemArea>
        <Row
          isParentTag={false}
          subItemsVisible={subItemsVisible}
          onToggleSubItemsVisible={onToggleSubItemsVisible}
          name={name}
          onToggle={onToggle}
          selected={!!selected}
        />
      </ItemArea>
    );
  }

  const subItemIdPaths = subItems.map(t => [...idPath, t.id]);
  const selectedSubItemsCount = subItemIdPaths
    .map(isItemSelected)
    .filter(result => !!result).length;

  const shouldSelectAll = selectedSubItemsCount === 0;

  const partiallySelected =
    !shouldSelectAll && selectedSubItemsCount < subItems.length;

  const onSelectAll = () => selectItems(subItemIdPaths);

  return (
    <ItemArea>
      <Row
        name={name}
        selected={!!selected}
        partiallySelected={partiallySelected}
        isParentTag={true}
        onToggle={shouldSelectAll ? onSelectAll : onToggle}
        subItemsVisible={subItemsVisible}
        onToggleSubItemsVisible={onToggleSubItemsVisible}
      />
      {subItemsVisible && (
        <SubList depth={idPath.length}>
          {subItems.map(subItem => (
            <NestedCheckbox
              {...subItem}
              key={subItem.id}
              idPath={[...idPath, subItem.id]}
              isItemSelected={isItemSelected}
              selected={isItemSelected([...idPath, subItem.id])}
              toggleItem={toggleItem}
              selectItems={selectItems}
            />
          ))}
        </SubList>
      )}
    </ItemArea>
  );
};

export default NestedCheckbox;
