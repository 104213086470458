import DateTimeRange from "api/models/DateTimeRange";

export enum PlanTypes {
  regular = "REGULAR",
  nonprofit = "NONPROFIT",
  custom = "CUSTOM",
}

export default interface Plan {
  planId: string;
  name: string;
  planType: PlanTypes;
  price: string;
  description: string | null;
  contract: string | null;
  contractTermsKey: string | null;
  bliggText: string | null;
  priceText: string | null;
  validity: DateTimeRange;
  monthlyContentLimit: number | null;
  monthlyContentCounter: number | null;
  extraContentLimit: number | null;
  extraContentCounter: number | null;
  disabled: boolean;
}

export interface BusinessPlanHistory {
  currentPlan: Plan | null;
  planHistory: Plan[];
}

export interface BusinessPlanPayload {
  businessId?: string;
  planId: string;
  onboarding?: boolean;
}

export interface BusinessPlanUpdatePayload {
  planId: string;
  businessId: string;
  packagePrice: number | null;
}

export interface AdditionalSettingsPayload {
  businessId?: string;
  enableProminentShopping: boolean;
  isTreuweltPartner: boolean;
  isKeeplocalPartner: boolean;
  mapAllowances: MapAllowancePayload[];
  imageUrl: string;
}

export interface MapPayload {
  mapId: string;
}

export interface MapAllowancePayload extends MapPayload {
  businessId: string;
}

export interface MapAssignmentPayload extends MapPayload {
  contentId: string;
  status: string;
}

export interface MapCurationPayload extends MapPayload {
  status: string;
}

export interface ExtraContentLimitUpdatePayload {
  extraContentLimit: number;
}
