import React, { ReactNode } from "react";

const If = ({ isTrue, children }: { isTrue: any; children: ReactNode }) => {
  if (isTrue) {
    return <>{children}</>;
  }
  return null;
};

export default If;
