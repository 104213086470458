import React from "react";
import styled from "style/styled-components";
import { RepresentativeInterface } from "api/models/RepresentativeInterface";
import BaseListItem from "components/lists/item/BaseListItem";
import { ListItemProps } from "components/lists/item/BaseListItemComponents";
import { SimpleText } from "components/generic";
import strings from "localisation/strings";
import ListItemContentRow from "components/lists/item/ListItemContentRow";

const BoldText = styled(SimpleText)`
  font-weight: bold;
`;

export interface RepresentativesListItemProps extends ListItemProps {
  representative: RepresentativeInterface;
  onClick: (businessProfileId: string) => void;
  onDeleteClick: (businessProfileId: string) => void;
}

const BusinessRepresentativeListItem = ({
  representative,
  onClick,
  onDeleteClick,
}: RepresentativesListItemProps) => {
  const {
    imageUrl,
    email,
    firstName,
    phone,
    phoneCode,
    role,
    status,
    lastName,
    userId,
    lastModifiedBy,
    modifiedDateTime,
    currentLoggedIn,
  } = representative;
  const hasFullPhoneNumber = !!(phoneCode && phone);

  return (
    <BaseListItem
      id={userId}
      email={email}
      phone={phone}
      role={role}
      imageUrl={imageUrl}
      lastModifiedBy={lastModifiedBy}
      noImage
      modifiedDateTime={modifiedDateTime}
      onClick={onClick}
      status={status}
      title={`${firstName} ${lastName}`}
      deleteAction={onDeleteClick}
      isDeleteActionDisabled={currentLoggedIn}
    >
      <ListItemContentRow>
        <BoldText>
          {strings(
            `businessRepresentativeRoles.${role ? role.toLowerCase() : "null"}`,
          )}
        </BoldText>
      </ListItemContentRow>
      <ListItemContentRow>
        <SimpleText>{email}</SimpleText>
      </ListItemContentRow>
      {hasFullPhoneNumber && (
        <ListItemContentRow>
          <SimpleText>
            {phoneCode} {phone}
          </SimpleText>
        </ListItemContentRow>
      )}
    </BaseListItem>
  );
};

export default BusinessRepresentativeListItem;
