import SingleMainCategoryInterface from "api/models/SingleMainCategoryInterface";
import SingleSubCategoryInterface from "api/models/SingleSubCategoryInterface";
import EntityTypes from "api/models/EntityTypes";
import TagInterface from "api/models/TagInterface";
import { uniqWith } from "ramda";
import MapInterface from "api/models/MapInterface";

interface Values {
  [index: string]: any;
}

export const mapFields = {
  singleInputFields: {
    mapName: "",
  },
  selectFields: {
    mapStatus: "",
  },
};

export const getMapPayload = (
  values: Values,
  oldBody: MapInterface | undefined,
) => {
  const { mapName, mapStatus } = values;
  return {
    ...oldBody,
    mapName,
    mapStatus,
  } as MapInterface;
};

export const setMapFields = (map: Partial<MapInterface>) => {
  const { mapName = "", mapStatus = "" } = map;

  return {
    singleInputFields: {
      mapName,
    },
    selectFields: {
      mapStatus,
    },
  };
};
