import React, { ReactNode } from "react";
import Tooltip from "react-tooltip";
import styled from "style/styled-components";
import { Icon } from "components/forms/FormFields";
import strings from "localisation/strings";
import hintIcon from "common/assets/icons/hint.svg";
import hintDarkIcon from "common/assets/icons/hint-dark.svg";

interface TooltipData {
  id: string;
  hint: string; // path for strings() method
  text?: string; // path for strings() method, optional label
}

const HintIcon = styled(Icon)`
  width: 17px;
  height: 17px;
  opacity: 1;
  margin-left: 5px;
  flex-shrink: 0;
`;

interface Props {
  children?: ReactNode;
  tooltipData: TooltipData;
  dark?: boolean;
  translateHint?: boolean;
}

const HintTooltip = ({
  children,
  tooltipData,
  dark = false,
  translateHint = true,
}: Props) => {
  const text = tooltipData.text ? strings(tooltipData.text) : null;

  return (
    <>
      <Tooltip className="form-tooltip" effect="solid" id={tooltipData.id}>
        {translateHint ? strings(tooltipData.hint) : tooltipData.hint}
      </Tooltip>
      {children ? children : text}
      <HintIcon
        backgroundImage={dark ? hintDarkIcon : hintIcon}
        data-event="click mouseover"
        data-event-off="blur mouseout"
        data-place="top"
        data-tip
        data-for={tooltipData.id}
      />
    </>
  );
};

export default HintTooltip;
