import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import TagInterface from "api/models/TagInterface";
import TagButton from "components/buttons/TagButton";
import CategoryTitle from "components/forms/CategoryTitle";
import { TransparentTag } from "components/generic/InputListTag";

export interface DropdownTagListProps {
  tagFilter: TagInterface[];
  isTagSelected: (idPath: string[]) => boolean;
  toggleTag: (idPath: string[], errorMessage: string) => void;
}

const TagListArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const TagRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => `2px 0 ${theme.margin.medium}`};
`;

const ActiveTag = styled(TagButton)`
  margin: ${({ theme }) => `0 ${theme.margin.xsmall} ${theme.margin.xsmall} 0`};
`;

const renderTag = (
  tag: TagInterface,
  isSelected: boolean,
  toggleTag: (idPath: string[], errorMessage: string) => void,
  parentId?: string,
) => {
  const toggleTagArguments = parentId ? [parentId, tag.id] : [tag.id];

  const handleToggleTag = () => {
    toggleTag(
      toggleTagArguments,
      strings("gneralTagSelector.noCategorySelectedErrorMessage"),
    );
  };

  if (isSelected) {
    return (
      <ActiveTag key={tag.id} onClick={handleToggleTag}>
        {tag.name}
      </ActiveTag>
    );
  }
  return (
    <TransparentTag key={tag.id} onClick={handleToggleTag}>
      {tag.name}
    </TransparentTag>
  );
};

const DropdownTagList = ({
  tagFilter,
  isTagSelected,
  toggleTag,
}: DropdownTagListProps) => {
  return (
    <TagListArea>
      {tagFilter.map(({ id, name, tagSuccessors }) => {
        return (
          <React.Fragment key={id}>
            <CategoryTitle>{name}</CategoryTitle>
            <TagRow>
              <>
                {tagSuccessors
                  ? tagSuccessors.map((tag: TagInterface) => {
                      const isSelected = isTagSelected([id, tag.id]);
                      return renderTag(tag, isSelected, toggleTag, id);
                    })
                  : renderTag(
                      { id, name } as TagInterface,
                      isTagSelected([id]),
                      toggleTag,
                    )}
              </>
            </TagRow>
          </React.Fragment>
        );
      })}
    </TagListArea>
  );
};

export default DropdownTagList;
