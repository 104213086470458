import React, { ReactNode } from "react";
import styled from "style/styled-components";
import { Omit } from "types";
import TextButtonBase, {
  TextButtonBaseProps,
} from "components/buttons/TextButtonBase";
import { HorizontalSmallSpacer } from "components/generic/Spacer";
import breakpoints from "style/breakpoints";

interface TextProps {
  type?: "responsive" | "static" | "inline";
  small?: boolean;
}

const Text = styled.span<TextProps>`
  font-size: ${({ theme, small }) =>
    small ? theme.font.size.note : theme.font.size.medium};
  color: ${({ theme }) => theme.color.foreground.action};
  ${({ theme, type }) =>
    type !== "inline" &&
    `
    padding-top: ${theme.margin.medium};
    padding-bottom: ${theme.margin.medium};
  `}

  ${({ theme, type }) =>
    type === "responsive" &&
    `
    @media ${breakpoints.tabletMax} {
      padding-top: ${theme.margin.small};
      font-size: ${theme.font.size.pagination};
    }
  `}
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const IconButtonText = styled(Text)`
  color: ${({ theme }) => theme.color.foreground.primary};
`;

const SmallIconButtonText = styled(Text)`
  color: ${({ theme }) => theme.color.foreground.primary};
  float: right;
  padding-top: 10px;
  padding-left: ${({ theme }) => theme.margin.xsmall};
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
`;

interface TextButtonProps
  extends Omit<TextButtonBaseProps, "children">,
    TextProps {
  text: string;
}

interface IconedTextButtonProps extends TextButtonProps {
  icon: ReactNode;
}

const IconedTextButton = ({
  icon,
  text,
  type,
  ...props
}: IconedTextButtonProps) => (
  <TextButtonBase {...props}>
    {icon}
    <HorizontalSmallSpacer as="span" />
    {text.length ? <IconButtonText type={type}>{text}</IconButtonText> : null}
  </TextButtonBase>
);

const SmallIconedTextButton = ({
  icon,
  text,
  type,
  ...props
}: IconedTextButtonProps) => (
  <TextButtonBase {...props}>
    {icon}
    <HorizontalSmallSpacer as="span" />
    {text.length ? (
      <SmallIconButtonText type={type}>{text}</SmallIconButtonText>
    ) : null}
  </TextButtonBase>
);

const TextButton = ({ text, onClick, ...props }: TextButtonProps) => (
  <TextButtonBase onClick={onClick} {...props}>
    <Text {...props}>{text}</Text>
  </TextButtonBase>
);

const BoldTextButton = ({ text, ...props }: TextButtonProps) => (
  <TextButtonBase {...props}>
    <BoldText>{text}</BoldText>
  </TextButtonBase>
);

const InlineTextButton = styled(TextButton)`
  display: inline-block;
`;

export {
  BoldTextButton,
  IconedTextButton,
  SmallIconedTextButton,
  InlineTextButton,
};
export default TextButton;
