import React, { Component } from "react";
import TextArea from "react-textarea-autosize";
import styled from "style/styled-components";
import RichTextEditor from "components/generic/richText/RichTextEditor";

const TextAreaContainer = styled.div`
  align-items: baseline;
  display: flex;
  padding: ${({ theme }) => theme.margin.medium} 0 20px;
`;

const AutosizeTextarea = styled(TextArea)`
  padding: 0;
  width: 100%;
  border: 0;
  border-radius: 4px;
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  line-height: 24px;
  height: 75px;
  resize: none;
  overflow: hidden;
  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
  }
`;

export interface TextAreaBlockProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  validator?: (value: any) => any;
  richText?: boolean;
}

interface TextAreaBlockState {
  value: string;
}

class TextAreaBlock extends Component<TextAreaBlockProps, TextAreaBlockState> {
  constructor(props: TextAreaBlockProps) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: TextAreaBlockProps) {
    if (!this.props.value && nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { validator, onChange } = this.props;

    const inputValue = event.currentTarget.value;
    let value = inputValue;
    if (validator && !validator(inputValue)) {
      value = this.state.value;
    }
    this.setState({ value }, () => onChange(value));
  };

  changeHandlerContent = (content: string) => {
    const { validator, onChange } = this.props;

    const inputValue = content;
    let value = inputValue;
    if (validator && !validator(inputValue)) {
      value = this.state.value;
    }
    this.setState({ value }, () => onChange(value));
  };

  render() {
    const { placeholder, richText } = this.props;
    const { value } = this.state;
    return (
      <TextAreaContainer>
        {richText ? (
          <RichTextEditor
            onContentChange={this.changeHandlerContent}
            value={value}
          />
        ) : (
          <AutosizeTextarea
            onChange={this.changeHandler}
            placeholder={placeholder}
            value={value}
          >
            {value}
          </AutosizeTextarea>
        )}
      </TextAreaContainer>
    );
  }
}

export default TextAreaBlock;
