import React, { ReactNode } from "react";
import Dotdotdot from "react-dotdotdot";
import styled from "style/styled-components";

const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.small};
`;

const Text = styled.span`
  font-weight: bold;
  margin: ${({ theme }) => theme.margin.small} 0;
  word-break: break-all;
`;

export interface ListItemTitleProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  maxLines?: number;
}

const ListItemTitle = ({
  className,
  children,
  onClick,
  maxLines = 2,
}: ListItemTitleProps) => (
  <TitleContainer className={className} onClick={onClick}>
    <Dotdotdot clamp={maxLines}>
      <Text>{children}</Text>
    </Dotdotdot>
  </TitleContainer>
);

export default ListItemTitle;
