import { Client, IMessage } from "@stomp/stompjs";
import { curry } from "ramda";
import SockJS from "sockjs-client";
import EventEmitter from "events";
import localeState from "state/singletons/localeState";
import { formatToAPILocale } from "api/APIHelpers";

const socketEventEmitter = new EventEmitter();
const EVENT_ERROR = "error";
const EVENT_CONNECTED = "connected";
const EVENT_CONNECTING = "connecting";
const EVENT_DISCONNECTED = "disconnected";

const emitError = () => socketEventEmitter.emit(EVENT_ERROR);
const emitConnected = () => socketEventEmitter.emit(EVENT_CONNECTED);
const emitConnecting = () => socketEventEmitter.emit(EVENT_CONNECTING);
const emitDisconnected = () => socketEventEmitter.emit(EVENT_DISCONNECTED);

const makeListenerRegistrator = (
  eventName: string,
  eventHandler: () => void,
) => {
  socketEventEmitter.addListener(eventName, eventHandler);
  return () => socketEventEmitter.removeListener(eventName, eventHandler);
};

const activeSubscriptionsMap = new Map<string, () => void>();

export default class Socket {
  registerConnectingListener = curry(makeListenerRegistrator)(EVENT_CONNECTING);
  registerConnectListener = curry(makeListenerRegistrator)(EVENT_CONNECTED);
  registerErrorListener = curry(makeListenerRegistrator)(EVENT_ERROR);
  registerDisconnectListener = curry(makeListenerRegistrator)(
    EVENT_DISCONNECTED,
  );

  protected client: Client;

  constructor(url: string) {
    this.client = new Client({
      appendMissingNULLonIncoming: true,
      heartbeatIncoming: 1000,
      heartbeatOutgoing: 1000,
      webSocketFactory: () => new SockJS(url),
      beforeConnect: () => {
        emitConnecting();
      },
      onConnect: () => {
        emitConnected();
      },
      onDisconnect: () => {
        emitDisconnected();
      },
      onWebSocketClose: () => {
        emitDisconnected();
      },
      onStompError: error => {
        // eslint-disable-next-line no-console
        console.log(`Received STOMP error: ${error.body}`, error);
        emitError();
      },
      onWebSocketError: error => {
        emitError();
      },
    });
  }

  setConnectHeaders(headers: Record<string, string>) {
    this.client.connectHeaders = headers;
  }

  connect() {
    this.client.activate();
  }

  disconnect() {
    this.client.deactivate();
  }

  publish(topic: string, payload: any, headers: Record<string, string>) {
    const message = {
      headers: {
        "accept-language": formatToAPILocale(localeState.getLocale()),
        ...headers,
      },
      destination: topic,
      body: JSON.stringify(payload),
    };

    if (this.client.connected) {
      // eslint-disable-next-line no-console
      console.log(
        `Published message during connected state to topic: ${topic}`,
        message,
      );
      this.client.publish(message);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `Prevented message from publishing during disconnected state to topic: ${topic}`,
      );
      const publishWhenConnectedAndUnsubscribe = this.registerConnectListener(
        () => {
          // eslint-disable-next-line no-console
          console.log(
            `Published message after establishing a connection to topic: ${topic}`,
            message,
          );
          this.client.publish(message);
          publishWhenConnectedAndUnsubscribe();
        },
      );
    }
  }

  subscribe<TMessagePayload>(
    topic: string,
    messageHandler: (messagePayload: TMessagePayload) => void,
  ) {
    const handleMessageReceived = (message: IMessage) => {
      const payload: TMessagePayload = JSON.parse(message.body);
      // eslint-disable-next-line no-console
      console.log(`Received new message from topic: ${topic}`, payload);
      return messageHandler(payload);
    };

    if (this.client.connected) {
      const subscription = this.client.subscribe(topic, handleMessageReceived);
      // eslint-disable-next-line no-console
      console.log(`Subscribed during connected state to topic: ${topic}`);
      activeSubscriptionsMap.set(topic, subscription.unsubscribe);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `Prevented subscribe during non connected state to topic: ${topic}`,
      );
    }

    const removeResubscriptionDuringReconnect = this.registerConnectListener(
      () => {
        if (!activeSubscriptionsMap.get(topic)) {
          // eslint-disable-next-line no-console
          console.log(`Subscribed once connected to topic: ${topic}`);
        } else {
          // eslint-disable-next-line no-console
          console.log(`Resubscribed during reconnect to topic: ${topic}`);
        }

        // Resubscribe in case connection to socket is lost and recovered
        const subscription = this.client.subscribe(
          topic,
          handleMessageReceived,
        );
        activeSubscriptionsMap.set(topic, subscription.unsubscribe);
      },
    );

    const removeActiveSubscriptionAndReconnectHandler = () => {
      const activeTopicSubscription = activeSubscriptionsMap.get(topic);

      if (activeTopicSubscription) {
        // eslint-disable-next-line no-console
        console.log(`Unsubscribed from topic: ${topic}`);
        activeTopicSubscription();
      }

      removeResubscriptionDuringReconnect();
    };

    return removeActiveSubscriptionAndReconnectHandler;
  }
}
