import React, { Component } from "react";
import TextArea from "react-textarea-autosize";

import styled from "style/styled-components";
import Title, { StyledTitle } from "components/generic/Title";

const InputContainer = styled.div`
  align-items: baseline;
  display: flex;
`;

const Input = styled(TextArea)`
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-size: ${({ theme }) => theme.font.size.xlarge};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: 800;
  width: 100%;
  border: 0;
  resize: none;
  padding: 0 1px 2px 1px;
  overflow: hidden;
  margin-top: 14px;
  margin-bottom: 17px;
  margin-left: -1px;
  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
  }
`;

const PlaceholderTitle = styled(StyledTitle)`
  color: ${({ theme }) => theme.color.foreground.tertiary};
`;

interface TitleInputProps {
  value: string;
  onChange: (title: string) => void;
  validator?: (value: any) => any;
  placeholder: string;
}

interface TitleInputState {
  focused?: boolean;
  value: string;
}

class InputField extends Component<TitleInputProps, TitleInputState> {
  private inputRef: HTMLTextAreaElement | null;
  constructor(props: TitleInputProps) {
    super(props);

    this.state = {
      focused: !props.value,
      value: props.value,
    };
    this.inputRef = null;
  }

  componentDidMount() {
    const node = this.inputRef;
    if (node && this.state.focused && !this.props.value) {
      node.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: TitleInputProps) {
    const node = this.inputRef;
    if (!this.props.value && nextProps.value) {
      const newState: TitleInputState = { value: nextProps.value };
      if (node !== document.activeElement) {
        newState.focused = false;
      }
      this.setState(newState);
    }
  }

  changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { validator, onChange } = this.props;
    const inputValue = event.currentTarget.value;
    let value = inputValue;
    if (validator) {
      if (validator(inputValue)) {
        value = inputValue;
      } else {
        value = this.state.value;
      }
    }
    this.setState({ value }, () => onChange(value));
  };

  focus = () =>
    this.setState({ focused: true }, () => {
      const node = this.inputRef;
      if (node) {
        node.focus();
      }
    });

  blur = () => this.setState({ focused: false });

  render() {
    const { focused, value } = this.state;
    const { placeholder } = this.props;

    return (
      <InputContainer onClick={this.focus} onBlur={this.blur}>
        {focused ? (
          <>
            <Input
              onChange={this.changeHandler}
              placeholder={placeholder}
              value={value}
              inputRef={tag => (this.inputRef = tag)}
            />
          </>
        ) : value ? (
          <Title>{value}</Title>
        ) : (
          <PlaceholderTitle>{placeholder}</PlaceholderTitle>
        )}
      </InputContainer>
    );
  }
}

export default InputField;
