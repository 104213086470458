import React from "react";
import { DefaultBusinessTopBarMenuChatLink } from "components/topBar/menu/components/links";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { Gradient, Container } from "components/topBar/menu/MenuComponents";

interface Props {
  fillBackground?: boolean;
}

const DefaultBusinessAccountMenuAdmin = ({ fillBackground }: Props) => (
  <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_CHAT}>
    <Container>
      <Gradient />
      <Container paddingLeft="16px" fillBackground={fillBackground}>
        <DefaultBusinessTopBarMenuChatLink />
      </Container>
    </Container>
  </PrivilegedComponent>
);

export default DefaultBusinessAccountMenuAdmin;
