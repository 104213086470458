import React, { useEffect, useState } from "react";
import strings from "localisation/strings";
import theme from "style/theme";
import sheetIcon from "common/assets/icons/sheet.svg";
import gallerieIcon from "common/assets/icons/gallerie.svg";
import {
  businessTypeOptions,
  defaultSelectLabel,
  detailCategoryStatusOptions,
  typeOptions,
} from "components/table/DataTable/constants";
import BlockTitle from "components/generic/BlockTitle";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import InlineNumberInput, {
  InlineInputProps,
} from "components/forms/FormFields/InlineNumberInput";
import SimpleIconedInput, {
  SingleInputFieldProps,
} from "components/forms/FormFields/SimpleIconedInput";
import SelectComponent, {
  SelectItem,
  SelectPropsInterface,
} from "components/forms/Select";
import Checkbox, { CheckboxProps } from "components/forms/Checkbox";
import {
  MultipleFieldFormProps,
  QuestionInputFieldProps,
} from "components/forms/CategoryMultipleAnswerForm";
import { ResponsiveSpreadRow } from "components/rows/SpreadRow";
import ResponsiveHalfRowColumn from "components/columns/HalfRowColumn";
import { MarginedArea } from "components/generic/ViewContainer";
import { AutoDragAndDrop } from "components/forms/FormFields";
import EntityTypes from "api/models/EntityTypes";
import ImageUploadType from "api/models/ImageUploadType";
import { getSmartServiceGroups } from "api/resources";
import useQuery from "common/hooks/useQuery";
import SmartServiceGroupInterface from "api/models/SmartServiceGroupInterface";

const MainCategoriesForm = ({
  isNew,
  getSelectFieldProps,
  getSingleInputFieldProps,
  getCheckboxFieldProps,
  getMultipleFieldFormProps,
  getNumberFieldProps,
  getQuestionInputFieldProps,
  getSmartServiceLogoPictureFieldProps,
  isQuestionRequired,
  isGeneralType,
}: {
  isNew?: boolean;
  getSelectFieldProps: (
    field: string,
    options: SelectItem[],
  ) => SelectPropsInterface;
  getSingleInputFieldProps: (field: string) => SingleInputFieldProps;
  getCheckboxFieldProps: (field: string) => CheckboxProps;
  getQuestionInputFieldProps: (
    field: string,
    subField: string,
    index: number,
  ) => QuestionInputFieldProps;
  getSmartServiceLogoPictureFieldProps: (
    field: string,
  ) => {
    imageUrl?: string | null;
    setImageUrl?: (image: string | null) => void;
  };
  getMultipleFieldFormProps: (field: string) => MultipleFieldFormProps;
  getNumberFieldProps: (field: string) => InlineInputProps;
  isQuestionRequired?: boolean;
  isGeneralType?: boolean;
}) => {
  const { xsmall, full } = theme.forms;
  const tagTypeSelectFieldProps = getSelectFieldProps("tagType", typeOptions());
  const businessTypeSelectFieldsProps = getSelectFieldProps(
    "businessType",
    businessTypeOptions(),
  );
  const showAsSmartServiceCheckBoxFieldProps = getCheckboxFieldProps(
    "showAsSmartService",
  );
  const showAsJobOfferCheckBoxFieldProps = getCheckboxFieldProps(
    "showAsJobOffer",
  );
  const [slideErrors, setSlideErrors] = useState<string[]>([]);

  const postSelectedAsCategory =
    tagTypeSelectFieldProps.value &&
    tagTypeSelectFieldProps.value.value === EntityTypes.posts;
  const showUploadImage =
    showAsSmartServiceCheckBoxFieldProps.checked ||
    showAsJobOfferCheckBoxFieldProps.checked;
  const showSmartServiceGroup = showAsSmartServiceCheckBoxFieldProps.checked;

  const { result: smartServiceGroupsResult } = useQuery<
    SmartServiceGroupInterface
  >({
    request: getSmartServiceGroups,
  });

  const getSmartServiceGroupsOptions = () => {
    return (
      smartServiceGroupsResult?.smartServiceGroups.map(option => ({
        label: strings(
          `categoriesScreen.fields.smartServiceGroup.options.${option}` ||
            option,
        ),
        value: option,
      })) || []
    );
  };

  return (
    <>
      <BlockTitle
        children={
          isNew
            ? strings("categoriesScreen.title.newMainCategory")
            : strings("categoriesScreen.title.editMainCategory")
        }
      />
      <MarginedArea>
        <ResponsiveSpreadRow>
          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings("categoriesScreen.fields.tagType")}
              width={xsmall}
            >
              <SelectComponent
                {...tagTypeSelectFieldProps}
                placeholder={defaultSelectLabel()}
                disabled={!isNew}
                classNamePrefix={"option"}
                small
              />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>

          <ResponsiveHalfRowColumn>
            <SimpleIconedInput
              icon={sheetIcon}
              width={xsmall}
              inputWidth={full}
              inputPlaceholder={strings(
                "categoriesScreen.fields.code.placeholder",
              )}
              {...getSingleInputFieldProps("code")}
            />
          </ResponsiveHalfRowColumn>
        </ResponsiveSpreadRow>

        <ResponsiveSpreadRow>
          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings("categoriesScreen.fields.businessType")}
              width={xsmall}
            >
              <SelectComponent
                {...businessTypeSelectFieldsProps}
                placeholder={defaultSelectLabel()}
                disabled={!isNew}
                classNamePrefix={"option"}
                small
              />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>

          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings(
                "categoriesScreen.fields.weight.placeholder",
              )}
              width={xsmall}
            >
              <InlineNumberInput
                maxLength={8}
                width={full}
                placeholder={strings("categoriesScreen.fields.weight.label")}
                isFormattingDisabled={true}
                {...getNumberFieldProps("weight")}
              />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>
        </ResponsiveSpreadRow>

        <ResponsiveSpreadRow>
          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings("categoriesScreen.fields.tagStatus")}
              width={xsmall}
            >
              <SelectComponent
                {...getSelectFieldProps(
                  "tagStatus",
                  detailCategoryStatusOptions(),
                )}
                placeholder={defaultSelectLabel()}
                classNamePrefix={"menu"}
                small
              />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>
        </ResponsiveSpreadRow>

        <ResponsiveSpreadRow>
          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings(
                "categoriesScreen.fields.showAsInterests.label",
              )}
              width={xsmall}
            >
              <Checkbox {...getCheckboxFieldProps("showAsInterests")} />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>

          <ResponsiveHalfRowColumn>
            {isGeneralType && (
              <InlineInputGroup
                icon={sheetIcon}
                placeholder={strings(
                  "categoriesScreen.fields.showAsDistrict.label",
                )}
                width={xsmall}
              >
                <Checkbox {...getCheckboxFieldProps("showAsDistrict")} />
              </InlineInputGroup>
            )}
          </ResponsiveHalfRowColumn>
        </ResponsiveSpreadRow>

        {postSelectedAsCategory && (
          <ResponsiveSpreadRow>
            <ResponsiveHalfRowColumn>
              <InlineInputGroup
                icon={sheetIcon}
                placeholder={strings(
                  "categoriesScreen.fields.showAsSmartService.label",
                )}
                width={xsmall}
              >
                <Checkbox {...showAsSmartServiceCheckBoxFieldProps} />
              </InlineInputGroup>
            </ResponsiveHalfRowColumn>
            <ResponsiveHalfRowColumn>
              <InlineInputGroup
                icon={sheetIcon}
                placeholder={strings(
                  "categoriesScreen.fields.showAsJobOffer.label",
                )}
                width={xsmall}
              >
                <Checkbox {...showAsJobOfferCheckBoxFieldProps} />
              </InlineInputGroup>
            </ResponsiveHalfRowColumn>
          </ResponsiveSpreadRow>
        )}

        <ResponsiveHalfRowColumn>
          {showSmartServiceGroup && (
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings(
                "categoriesScreen.fields.smartServiceGroup.placeholder",
              )}
              width={xsmall}
            >
              <SelectComponent
                {...getSelectFieldProps(
                  "smartServiceGroup",
                  getSmartServiceGroupsOptions(),
                )}
                placeholder={defaultSelectLabel()}
                classNamePrefix={"menu"}
                small
              />
            </InlineInputGroup>
          )}
          {showUploadImage && (
            <InlineInputGroup
              icon={gallerieIcon}
              placeholder={strings("categoriesScreen.fields.imageField.label")}
              width={xsmall}
              wrapText={true}
            >
              <AutoDragAndDrop
                {...getSmartServiceLogoPictureFieldProps("imageUrl")}
                placeholder={strings(`dragAndDrop.dropHere`)}
                container={ImageUploadType.TAGS}
                height="350px"
                width="500px"
                fixedRatio={true}
                slideErrors={slideErrors}
                setSlideErrors={setSlideErrors}
                withCropAndZoom={false}
              />
            </InlineInputGroup>
          )}
        </ResponsiveHalfRowColumn>
      </MarginedArea>
    </>
  );
};

export default MainCategoriesForm;
