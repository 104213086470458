import strings from "localisation/strings";
import React from "react";
import { FileResultType, handleFileDownload } from "api/APIHelpers";
import useNotificationState from "hooks/useNotification";
import SubmitButton, { SubmitRequest } from "components/forms/SubmitButton";

interface EmptyType {
  type?: boolean;
}

interface DownloadButtonProps {
  request: SubmitRequest<EmptyType, FileResultType>;
  text: string;
}

const DownloadButton = ({ request, text }: DownloadButtonProps) => {
  const { addErrorNotification } = useNotificationState();

  return (
    <SubmitButton
      special="narrow"
      text={text}
      loadingText={strings("buttons.states.requestInProgress")}
      request={request}
      failedRequestCallback={error => addErrorNotification(error.detail)}
      getPayload={() => ({} as EmptyType)}
      successfulRequestCallback={handleFileDownload}
    />
  );
};

export default DownloadButton;
