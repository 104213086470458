import RequestOptions from "api/RequestOptions";
import ChatConversationDTO from "api/models/ChatConversationDTO";
import ChatMessageDTO from "api/models/ChatMessageDTO";
import AuthedAPI from "api/AuthedAPI";
import { ListLimitedParams } from "api/models/ListParams";
import ListResultType from "api/models/ListResultType";
import ChatStartConversationDTO from "api/models/ChatStartConversationDTO";
import ChatSettingsDTO, {
  UpdateChatSettingsDTO,
} from "api/models/ChatSettingsDTO";
import OutgoingMessageDTO, {
  OutgoingFlowMessageDTO,
} from "api/models/OutgoingMessageDTO";
import ChatSocket from "api/ChatSocket";
import ChatSocketMessageDTO from "api/models/ChatSocketMessageDTO";
import ChatNotificationsDTO from "api/models/ChatNotificationsDTO";
import ChatConversationUpdateDTO from "api/models/ChatConversationUpdateDTO";
import ChatBusinessBranchesDTO from "api/models/ChatBusinessBranchesDTO";

const chatAPI = new AuthedAPI({});
const chatSocketClient = new ChatSocket();

const getRoutePrefix = (businessId?: string, isAdmin?: boolean) =>
  businessId && isAdmin ? `chat/bo/business/${businessId}` : "chat/bp";

const getConversationsHistory = (
  businessId: string | undefined,
  isAdmin: boolean,
  businessProfileId: string | undefined,
  body: ListLimitedParams,
  options: RequestOptions,
) => {
  return chatAPI.postJson<ListResultType<ChatConversationDTO>>({
    options,
    body,
    path: businessProfileId
      ? `${getRoutePrefix(
          businessId,
          isAdmin,
        )}/branches/${businessProfileId}/conversations`
      : `${getRoutePrefix(businessId, isAdmin)}/conversations`,
  });
};

const getChatSettings = (
  body: { businessId?: string; isAdmin?: boolean },
  options?: RequestOptions,
) => {
  return chatAPI.getJson<ChatSettingsDTO>({
    options,
    path: `${getRoutePrefix(body.businessId, body.isAdmin)}/settings`,
  });
};

const saveChatSettings = (
  businessId: string | undefined,
  isAdmin: boolean,
  body: UpdateChatSettingsDTO,
  options?: RequestOptions,
) => {
  return chatAPI.postJson<void>({
    options,
    body,
    path: `${getRoutePrefix(businessId, isAdmin)}/settings`,
  });
};

const getBusinessBranches = (
  businessId: string | undefined,
  isAdmin: boolean,
  body: ListLimitedParams,
  options: RequestOptions,
) => {
  return chatAPI.postJson<ChatBusinessBranchesDTO>({
    options,
    body,
    path: `${getRoutePrefix(businessId, isAdmin)}/conversations/branches`,
  });
};

const startConversation = (
  businessId: string | undefined,
  isAdmin: boolean,
  conversationId: string,
  options: RequestOptions,
) => {
  return chatAPI.postJson<ChatStartConversationDTO>({
    options,
    path: `${getRoutePrefix(
      businessId,
      isAdmin,
    )}/conversations/${conversationId}/start`,
  });
};

const getAdminNotifications = (
  businessId: string | undefined,
  options?: RequestOptions,
) => {
  return chatAPI.getJson<ChatNotificationsDTO>({
    options,
    path: businessId
      ? `chat/bo/business/${businessId}/notifications`
      : "chat/bo/notifications",
  });
};

const getNotifications = (options?: RequestOptions) => {
  /**
   * Getting total notifications for current drilled down business is not supported
   * Only support for internal admin business is supported
   */
  return chatAPI.getJson<ChatNotificationsDTO>({
    options,
    path: "chat/bp/notifications",
  });
};

const getConversationMessages = (
  businessId: string | undefined,
  isAdmin: boolean,
  conversationId: string,
  body: ListLimitedParams,
  options: RequestOptions,
) => {
  return chatAPI.postJson<ListResultType<ChatMessageDTO>>({
    options,
    body,
    path: `${getRoutePrefix(
      businessId,
      isAdmin,
    )}/conversations/${conversationId}/messages`,
  });
};

const subscribeToChatMessageUpdates = (
  conversationId: string,
  onMessageReceived: (message: ChatSocketMessageDTO) => void,
) => {
  const unsubscribe = chatSocketClient.subscribe<ChatSocketMessageDTO>(
    `/topic/private.bp.conversations.${conversationId}`,
    onMessageReceived,
  );
  return unsubscribe;
};

const subscribeToConversationUpdates = (
  onConversationUpdateReceived: (message: ChatConversationUpdateDTO) => void,
) => {
  const unsubscribe = chatSocketClient.subscribe<ChatConversationUpdateDTO>(
    `/user/exchange/chat/conversations`,
    onConversationUpdateReceived,
  );
  return unsubscribe;
};

const publishChatMessage = (
  conversationId: string,
  message: OutgoingMessageDTO,
) => {
  chatSocketClient.publish(
    `/chat/private/bp/${conversationId}/send-message`,
    message,
    {
      "message-type": "MESSAGE",
    },
  );
};

const publishChatFlowMessage = (
  conversationId: string,
  message: OutgoingFlowMessageDTO,
) => {
  chatSocketClient.publish(
    `/chat/private/bp/${conversationId}/send-message`,
    message,
    {
      "message-type": "FLOW",
    },
  );
};

export {
  getChatSettings,
  getNotifications,
  getAdminNotifications,
  saveChatSettings,
  startConversation,
  getBusinessBranches,
  getConversationsHistory,
  getConversationMessages,
  subscribeToChatMessageUpdates,
  subscribeToConversationUpdates,
  publishChatMessage,
  publishChatFlowMessage,
};
