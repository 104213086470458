import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import strings from "localisation/strings";
import ActivationPageLayout from "components/activation/UserActivationPageLayout";
import { CenteredText } from "components/generic";
import TextButton from "components/buttons/TextButton";

const RepresentativeUserActivatedView = ({
  isAdmin,
  history,
}: RouteComponentProps & {
  isAdmin?: boolean;
}) => (
  <ActivationPageLayout title={strings("activateAccountScreen.successTitle")}>
    <CenteredText>
      {strings("activateAccountScreen.successMessage")}
    </CenteredText>
    {isAdmin ? (
      <TextButton
        onClick={() => history.push("/admin/anmelden")}
        text={strings("buttons.goToLogin")}
      />
    ) : (
      <TextButton
        onClick={() => history.push("/anmelden")}
        text={strings("buttons.goToLogin")}
      />
    )}
  </ActivationPageLayout>
);

export default withRouter(RepresentativeUserActivatedView);
