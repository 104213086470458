import React from "react";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import { Headline, CenteredContentViewContainer } from "components/generic";
import strings from "localisation/strings";
import { getBpPersonalData, getBoPersonalData } from "api/users";

import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import RepresentativeFormContainer from "components/forms/BusinessRepresentative/RepresentativeFormContainer";
import AdminPersonalDataForm from "components/forms/UserDataForm/AdminPersonalDataForm";

import { VerticalLargeSpacer } from "components/generic/Spacer";
import useLoginState from "hooks/useLoginState";

const PersonalDataView = () => {
  const loginState = useLoginState();

  const getRequest = loginState.hasAdminRights()
    ? getBoPersonalData
    : getBpPersonalData;
  const { result: initialPersonalData, status: initialDataStatus } = useQuery({
    request: getRequest,
  });

  const initialDataLoading = initialDataStatus === QueryStatus.WAITING;

  return (
    <CenteredContentViewContainer>
      <RepresentativeFormContainer>
        <Headline>{strings("profileScreen.personalData.pageTitle")}</Headline>

        <AdminPersonalDataForm initialData={initialPersonalData} />
      </RepresentativeFormContainer>

      {initialDataLoading && <AbsoluteOverlaySpinner />}
      <VerticalLargeSpacer />
    </CenteredContentViewContainer>
  );
};

export default PersonalDataView;
