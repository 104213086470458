import styled from "style/styled-components";
import { Title } from "components/generic";

const WizardTitle = styled(Title)`
  margin-bottom: 0;
`;

/**
 * Separates unrelated components
 */
const Section = styled.div`
  margin-top: ${({ theme }) => theme.margin.xlarge};
`;

/**
 * Usually separates related components in a `Section`
 */
const Subsection = styled.div<{ indented?: boolean }>`
  margin-top: ${({ theme }) => theme.margin.large};
  ${({ theme, indented }) => indented && `margin-left: ${theme.margin.large};`}
`;

export { WizardTitle, Section, Subsection };
