import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import strings from "localisation/strings";
import { ReactComponent as FolderIcon } from "assets/icons/messageFolder.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/chatSettings.svg";
import { VerticalLargeSpacer } from "components/generic";
import { SidebarLink } from "components/generic/SidenavLayout";
import {
  isChatMessagesRoute,
  isChatSettingsRoute,
  makeChatMessagesRoute,
  makeChatSettingsRoute,
} from "components/chat/utils";
import { ChatPageParams } from "views/Chat/ChatMessagesView";
import useLoginState from "hooks/useLoginState";

const ChatSidebar: React.FC<RouteComponentProps<ChatPageParams>> = ({
  match: {
    path,
    params: { businessId },
  },
}) => {
  const { hasCuratorRights } = useLoginState();
  const isCurator = hasCuratorRights();

  return (
    <>
      <SidebarLink
        to={makeChatMessagesRoute(businessId, isCurator)}
        text={strings("chat.messages")}
        icon={FolderIcon}
        isActive={isChatMessagesRoute(path)}
      />

      <VerticalLargeSpacer />

      <SidebarLink
        to={makeChatSettingsRoute(businessId, isCurator)}
        text={strings("chat.settings")}
        icon={SettingsIcon}
        isActive={isChatSettingsRoute(path)}
      />
    </>
  );
};

export default withRouter(ChatSidebar);
