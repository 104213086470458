import { MarginlessViewContainer } from "components/generic";
import {
  ContentContainer,
  TextScreenContainer,
} from "views/TextScreens/components";
import GenericRichTextContent from "components/generic/richText/GenericRichTextContent";
import React from "react";

const FAQ = () => {
  return (
    <MarginlessViewContainer>
      <TextScreenContainer>
        <ContentContainer>
          <GenericRichTextContent contentKey="bp-faq" />
        </ContentContainer>
      </TextScreenContainer>
    </MarginlessViewContainer>
  );
};

export default FAQ;
