import React from "react";
import PasswordCreationForm from "components/forms/BusinessRepresentativePassword/PasswordCreationForm";
import {
  createRepresentativePassword,
  createAdminPassword,
  SetRepresentativePasswordPayload,
} from "api/session";
import { withRouter, RouteComponentProps } from "react-router";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import { Omit } from "types";

interface RepresentativeActivationRouteParams {
  token: string;
}

type Props = RouteComponentProps<RepresentativeActivationRouteParams> & {
  isAdminForm?: boolean;
};

const BusinessRepresentativePasswordForm = ({
  history,
  location,
  match,
  isAdminForm = false,
}: Props) => {
  const request = isAdminForm
    ? createAdminPassword
    : createRepresentativePassword;

  const redirectPath = isAdminForm
    ? "/admin/konto/partnervertreter-aktiviert"
    : "/konto/partnervertreter-aktiviert";

  const { makeRequest, status, error } = useMutation<
    SetRepresentativePasswordPayload,
    void
  >(request);

  const handleRepresentativePasswordSubmit = async (
    formData: Omit<SetRepresentativePasswordPayload, "token">,
  ) => {
    const response = await makeRequest({
      ...formData,
      token: match.params.token,
    });

    if (response && response.result) {
      history.replace({
        ...location,
        pathname: redirectPath,
      });
    }
  };

  return React.createElement(PasswordCreationForm, {
    isAdminForm,
    isSubmittable: status !== QueryStatus.WAITING,
    formErrors: error,
    onSubmit: handleRepresentativePasswordSubmit,
  });
};

export default withRouter(BusinessRepresentativePasswordForm);
