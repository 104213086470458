import React from "react";
import useLoginState from "hooks/useLoginState";
import BusinessSettingsAdditionalSettingsAdmin from "views/BusinessSettings/AdditionalSettings/BusinessSettingsAdditionalSettingsAdmin";

const BusinessSettingsAdditionalSettingsAdminEntryPoint = () => {
  const businessId = useLoginState().getBusinessId();

  if (!businessId) {
    return null;
  }

  return <BusinessSettingsAdditionalSettingsAdmin businessId={businessId} />;
};

export default BusinessSettingsAdditionalSettingsAdminEntryPoint;
