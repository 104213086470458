import React, { useEffect, useRef, useState } from "react";
import { curry } from "ramda";

import strings from "localisation/strings";
import { getDateLabel } from "utils";

import {
  deleteReplyToReviewForPost,
  deleteReplyToReviewForPostAdmin,
  getReviewsForPost,
  getReviewsForPostAdmin,
  saveReplyToReviewForPost,
  saveReplyToReviewForPostAdmin,
} from "api/posts";
import PostInterface from "api/models/PostInterface";
import EntityTypes from "api/models/EntityTypes";
import useFormFields from "hooks/useFormFields";
import useLoginState from "hooks/useLoginState";

import TitleInput from "components/forms/ValidatedFields/TitleInput";
import TagSelectionField from "components/forms/TagSelection/TagSelectionField";
import PriceRange from "components/forms/FormFields/PriceRange";
import {
  Media,
  Title,
  VerticalLargeSpacer,
  VerticalMediumSpacer,
} from "components/generic";
import { getActionArea } from "components/helpers/formHelpers";
import {
  defaultPostFields,
  getPostPayload,
  setPostFields,
} from "components/helpers/formHelpers/post";

import {
  SaveStatusDisplay,
  Separator,
  SettingsArea,
  TextWithIcon,
} from "components/forms/FormFields";
import PictureGallery from "components/forms/FormFields/PictureGallery";
import DateTimeRangePicker from "components/forms/ValidatedFields/DateTimeRangePicker";
import DescriptionBlock from "components/forms/DescriptionBlock";
import ReviewsAndRatings from "components/review/ReviewsAndRatings";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import Checkbox from "components/forms/Checkbox";
import PrivilegedComponent from "components/PrivilegedComponent";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import AddressPicker from "components/forms/FormFields/AddressAutocomplete/AddressPicker";
import zooIcon from "assets/icons/zoo.svg";
import locationIcon from "assets/icons/location.svg";

import smartServices from "common/assets/icons/smartServices.svg";
import jobs from "common/assets/icons/jobs.svg";
import bell from "common/assets/icons/bell.svg";
import pushNotification from "common/assets/icons/pushNotification.svg";
import date from "common/assets/icons/date.svg";
import ImageUploadType from "api/models/ImageUploadType";
import InFrameContentFields from "components/forms/FormFields/InFrameContentFields";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import useMutation from "common/hooks/useMutation";
import { useLocation } from "react-router-dom";
import { COPY } from "constants/strings";
import HashtagsField from "components/forms/HashtagsField";
import { useTagRaceConditionCatcher } from "hooks/useTagRaceConditionCatcher";
import TopicsSection from "components/forms/FormFields/TopicsSection";
import {
  getAdditionalSettingsAdmin,
  getAdditionalSettingsCurator,
} from "api/businessProfiles";
import useQuery from "common/hooks/useQuery";
import MapsSelectList from "components/forms/FormFields/MapsSelectList";
import { getCuratedMaps } from "api/users";

// The max-height has aspect ratio scaling applied to keep margins between components same as before image box rescaling
const ImageBox = styled.div`
  max-height: ${({ theme }) =>
    500 + 500 * ((100 - theme.image.aspectRatio) / 100)}px;

  /*
    IE10+ CSS to fix an IE bug that causes flex box overflow.
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 0 auto;
  }

  @media ${breakpoints.tabletMax} {
    max-height: inherit;
  }
`;

const OptionalFieldsButton = styled.button`
  color: ${({ theme }) => theme.color.foreground.action};
  cursor: pointer;
  align-self: flex-start;
  margin-block: ${({ theme }) => theme.margin.medium};
  margin-left: ${({ theme }) => theme.margin.medium};
  display: flex;
  gap: ${({ theme }) => theme.margin.medium};
`;

const shouldShowMoreOptional = (resultParam?: Partial<PostInterface>) => {
  if (!resultParam) {
    return false;
  }
  if (
    resultParam.location ||
    resultParam.priceMinMax ||
    resultParam.dateTimeRange
  ) {
    return true;
  }
  return false;
};

const PostForm = ({
  isNew,
  createNew,
  createDraft,
  success,
  result,
  changedValues,
  setBlockNavigation,
  businessId,
}: {
  isNew: boolean;
  createNew: any;
  createDraft: any;
  success: (message: string, response: PostInterface) => void;
  result?: Partial<PostInterface>;
  changedValues: Set<string>;
  setBlockNavigation: (shouldBlockNavigation: boolean) => void;
  businessId?: string;
}) => {
  const { hasAdminRights, hasCuratorRights } = useLoginState();
  const adminRights = hasAdminRights();
  const curatorRights = hasCuratorRights();
  const [oldContent, setOldContent] = useState<Partial<PostInterface>>();
  const [isSavingBlocked, setIsSavingBlocked] = useState<boolean>(false);
  const [showOptionalFields, setShowOptionalFields] = useState<boolean>(
    !isNew || shouldShowMoreOptional(result),
  );

  const canAssignMap = () => adminRights || curatorRights;

  let id;
  let enableComments;
  let enableRatings;
  const { result: additionalSettingsResult } = useQuery({
    request: curry(
      adminRights ? getAdditionalSettingsAdmin : getAdditionalSettingsCurator,
    )(result?.businessId || businessId || ""),
  });
  const getCuratedMapsResult = useQuery({
    request: getCuratedMaps,
  });

  if (result) {
    id = result.id;
    enableComments = result.enableComments;
    enableRatings = result.enableRatings;
  }
  const mapAllowances = adminRights
    ? additionalSettingsResult?.mapAllowances
    : additionalSettingsResult?.mapAllowances.filter(allowedMap =>
        getCuratedMapsResult.result?.some(
          curatedMap => curatedMap.mapId === allowedMap.mapId,
        ),
      );

  let getReviewsRequest;
  let saveReviewReplyRequest;
  let deleteReviewReplyRequest;
  if (id) {
    if (adminRights) {
      getReviewsRequest = curry(getReviewsForPostAdmin)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForPostAdmin)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForPostAdmin)(id);
    } else {
      getReviewsRequest = curry(getReviewsForPost)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForPost)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForPost)(id);
    }
  }

  const {
    getErrors,
    parseErrors,
    clearErrors,
    values,
    isValid,
    updateValues,
    getCheckboxFieldProps,
    getTextAreaFieldProps,
    getTagFieldProps,
    getImageListProps,
    getTextFieldProps,
    getLocationAddressFieldProps,
    getPinnedLocationAddressDescriptionFieldProps,
    getDateTimeRangeFieldProps,
    getPriceMinMaxFieldProps,
    getSettingsFieldProps,
    getHashtagsFieldProps,
    onChange,
    setErrors,
  } = useFormFields({
    ...defaultPostFields,
    changedValues,
    setBlockNavigation,
    handleGenericErrors: true,
    translationScope: "postsScreen.fields",
  });

  const updateValuesRef = useRef(updateValues);

  useEffect(() => {
    if (result) {
      setShowOptionalFields(shouldShowMoreOptional(result));
      setOldContent(result);
      const updatedFormFields = setPostFields(result);
      updateValuesRef.current(updatedFormFields);
    }
  }, [result]);

  const location = useLocation();
  const isCopied = location.pathname.endsWith(COPY);

  const { checked: isAlertToBusinessTrue } = getCheckboxFieldProps(
    "alertToBusiness",
  );

  const { makeRequest } = useMutation(createNew);
  const { onRequestFinished, filterFieldErrors } = useTagRaceConditionCatcher();

  useEffect(() => {
    if (isCopied) return;

    // this makes a publish request on component mount to get all required fields
    makeRequest({
      tagIds: [],
      status: "PUBLISHED",
    }).then(e => {
      if (e && e.error) {
        parseErrors({
          ...e.error,
          detail: undefined,
          fieldsErrors: filterFieldErrors(e.error),
        });
      }
    });
  }, [location]);

  return (
    <>
      {!isNew && result && result.status && (
        <SaveStatusDisplay
          status={result.status}
          modifiedDate={result.modifiedDateTime || null}
        />
      )}
      <TitleInput {...getTextAreaFieldProps("title")} focusOnOpen />
      <TagSelectionField
        {...getTagFieldProps("tags")}
        fieldErrors={getErrors("tagIds")}
        type={EntityTypes.posts}
        setIsSavingBlocked={setIsSavingBlocked}
        isNew={isNew}
        isCopied={isCopied}
        isPostForm={true}
        businessId={businessId}
        onTagsLoaded={onRequestFinished}
      />

      <HashtagsField {...getHashtagsFieldProps("hashtags")} />
      <Media tablet phone>
        <VerticalLargeSpacer />
      </Media>
      <ImageBox>
        <PictureGallery
          {...getImageListProps()}
          container={ImageUploadType.POSTS}
          smallPictureContainer={ImageUploadType.POSTS}
          height="330px"
          parseErrors={parseErrors}
          updateKey={result ? result.modifiedDateTime : undefined}
        />
      </ImageBox>
      {
        <OptionalFieldsButton
          onClick={() => setShowOptionalFields(!showOptionalFields)}
        >
          {showOptionalFields ? (
            <>
              <span>&#9660;</span>
              {strings("detailedScreen.hideOptionalFields")}
            </>
          ) : (
            <>
              <span>&#9654;</span>
              {strings("detailedScreen.showOptionalFields")}
            </>
          )}
        </OptionalFieldsButton>
      }
      {showOptionalFields && (
        <>
          <TextWithIcon
            {...getTextFieldProps("venue", "location.venue")}
            placeholder={strings("detailedScreen.location.venue")}
            errors={getErrors("location.venue")}
            optional
            icon={zooIcon}
          />
          {canAssignMap() && mapAllowances && mapAllowances.length > 0 && (
            <InlineInputGroup
              wrapText
              placeholder={strings("detailedScreen.showOnMap")}
              icon={locationIcon}
            >
              <MapsSelectList
                allowedMaps={mapAllowances}
                values={result?.mapAssignments || []}
                entityId={id}
                fieldName="mapAssignments"
                style=".mapDropdown__control {
                  padding: 0 10px 0 5px;
                }"
                onChange={onChange}
              />
            </InlineInputGroup>
          )}
          <AddressPicker
            autocompleteProps={getLocationAddressFieldProps()}
            pinnedDescriptionFieldProps={getPinnedLocationAddressDescriptionFieldProps(
              "locationAddress",
              "location",
            )}
            location={result ? result.location : undefined}
            showPinLocationOnMapSuggestion={values.mapAssignments.length > 0}
          />
          <DateTimeRangePicker
            {...getDateTimeRangeFieldProps("dateTimeRange")}
            canSelectInPast={!isNew}
          />
          <PriceRange
            {...getPriceMinMaxFieldProps("priceMinMax")}
            placeholder={strings("detailedScreen.price.sum")}
          />
        </>
      )}
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS_ADMIN}>
        <InFrameContentFields
          textFieldProps={getTextFieldProps("inFrameUrl")}
          frameUrlErrors={getErrors("inFrameUrl")}
        />
      </PrivilegedComponent>
      <VerticalMediumSpacer />
      <DescriptionBlock {...getTextAreaFieldProps("description")} richText />
      {id &&
        getReviewsRequest &&
        saveReviewReplyRequest &&
        deleteReviewReplyRequest && (
          <ReviewsAndRatings
            id={id}
            getRequest={getReviewsRequest}
            saveReviewReply={saveReviewReplyRequest}
            deleteReviewReply={deleteReviewReplyRequest}
            enableComments={enableComments}
            enableRatings={enableRatings}
          />
        )}
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS_ADMIN}>
        <Separator />
        <Title>{strings("postsScreen.fields.publishAs.title")}</Title>
        <InlineInputGroup icon={pushNotification} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("publishPushNotification")}
            disabled={isAlertToBusinessTrue}
          />
        </InlineInputGroup>
        <TopicsSection
          onChange={onChange}
          topicIdsFromResult={result?.topicIds}
          getErrors={getErrors}
        />
        <InlineInputGroup icon={bell} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("publishAlert")}
            disabled={isAlertToBusinessTrue}
          />
        </InlineInputGroup>
        <InlineInputGroup icon={date} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("topicOfTheWeek")}
            disabled={isAlertToBusinessTrue}
            dateLabel={getDateLabel(result)}
          />
        </InlineInputGroup>
        <InlineInputGroup icon={bell} margin="0" width="50px">
          <Checkbox {...getCheckboxFieldProps("alertToBusiness")} />
        </InlineInputGroup>
        <InlineInputGroup icon={smartServices} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("smartService")}
            disabled={isAlertToBusinessTrue}
          />
        </InlineInputGroup>
        <InlineInputGroup icon={jobs} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("jobOffer")}
            disabled={isAlertToBusinessTrue}
          />
        </InlineInputGroup>
      </PrivilegedComponent>
      <Separator />
      <SettingsArea {...getSettingsFieldProps()} />
      {getActionArea({
        values,
        isNew,
        clearErrors,
        parseErrors,
        oldContent,
        isSavingBlocked,
        isSubmitDisabled: !isValid,
        setShouldBlockNavigation: setBlockNavigation,
        successCallback: success,
        content: result,
        createDraftItem: createDraft,
        createNewItem: createNew,
        getContentPayload: getPostPayload,
      })}
    </>
  );
};

export default PostForm;
