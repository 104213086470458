import React from "react";
import styled, { css } from "style/styled-components";
import { Link, LinkProps } from "react-router-dom";
import { actionButtonStyle } from "components/buttons/ActionButton";
import ActionButtonText from "components/buttons/ActionButtonText";

interface ActionButtonLinkProps extends LinkProps {
  to: string;
  text: string;
  forceExternalLink?: boolean;
  className?: string;
}

const linkStyle = css`
  ${actionButtonStyle};
  text-align: center;
  text-decoration: none;
`;

const InternalLinkContainer = styled(Link)`
  ${linkStyle}
`;

const ExternalLinkContainer = styled.a`
  ${linkStyle}
`;

const ActionButtonLink = ({
  to,
  text,
  forceExternalLink,
  ...rest
}: ActionButtonLinkProps) =>
  /^($|\/|\?|#)/.test(to) && !forceExternalLink ? (
    <InternalLinkContainer to={to} {...rest}>
      <ActionButtonText>{text}</ActionButtonText>
    </InternalLinkContainer>
  ) : (
    <ExternalLinkContainer
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <ActionButtonText>{text}</ActionButtonText>
    </ExternalLinkContainer>
  );

export default ActionButtonLink;
