import React from "react";

import strings from "localisation/strings";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import styled from "style/styled-components";
import { IconedTextButton } from "components/buttons/TextButton";
import CountBadge from "components/generic/CountBadge";

const StyledFilterButton = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;

const MarginedCounterBadge = styled(CountBadge)`
  margin-left: ${({ theme }) => theme.margin.small};
`;

export interface FilterButtonProps {
  filterCount: number;
  toggleFiltering: () => void;
}

const FilterButton = ({ filterCount, toggleFiltering }: FilterButtonProps) => (
  <StyledFilterButton onClick={toggleFiltering}>
    <IconedTextButton
      icon={<FilterIcon width={14} height={14} />}
      text={strings("filterableLists.filterButton")}
    />
    {!!filterCount && <MarginedCounterBadge text={`${filterCount}`} />}
  </StyledFilterButton>
);

export default FilterButton;
