import styled from "styled-components";
import InlineInput from "components/forms/FormFields/InlineInput";
import breakpoints from "style/breakpoints";

export const InlineTextField = styled(InlineInput)`
  padding: ${({ theme }) => theme.components.inlineInput.padding};
  border-radius: ${({ theme }) => theme.components.inlineInput.borderRadius};
  font-size: ${({ theme }) => theme.font.size.medium};
  width: ${({ width }) => width || "50%"};
  line-height: ${({ theme }) => theme.components.inlineInput.lineHeight};
  
  ${({ theme, isUnbranded }) =>
    !isUnbranded &&
    `
    &::placeholder {
      color: ${theme.color.foreground.tertiary};
    }
  `}

  @media ${breakpoints.phoneMax} {
    width: 100%;
  }
`;
