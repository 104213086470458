import React, { useCallback, useState, useEffect } from "react";
import { QuickReplyOption } from "components/chat/types";
import { moment } from "localisation/i18n";
import styled from "style/styled-components";
import { InteractiveButton } from "components/chat/messagesList/interactiveItems/InteractiveButtons";
import strings from "localisation/strings";
import DateTimePicker from "components/inputs/DateTimePicker";
import { Placement } from "@floating-ui/react";

interface InteractiveItemProps {
  selectedValue: string | null;
  showTimeSelect?: boolean;
  isDisabled: boolean;
  options: QuickReplyOption[];
  onInteractionFinished: (option: QuickReplyOption, value: string) => void;
}

const DateTimePickerButtonContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const InteractiveDateTimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;

  .react-datepicker {
    display: flex;
    flex-direction: row;

    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.color.foreground.antiPrimary};

    .react-datepicker__month-container {
      flex-grow: 1;
    }

    .react-datepicker__time-container {
      border-left: 1px solid
        ${({ theme }) => theme.color.chat.message.background.left};

      .react-datepicker__time {
        border-bottom-right-radius: 50px;
      }
    }

    .react-datepicker__header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: none;
      background-color: ${({ theme }) => theme.color.background.dark};

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: ${({ theme }) => theme.color.foreground.antiPrimary};
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: ${({ theme }) => theme.color.foreground.antiPrimary};
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      background-color: ${({ theme }) => theme.color.background.dark};

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .react-datepicker.react-datepicker--time-only {
    flex-direction: column;

    .react-datepicker__time-container,
    .react-datepicker__time-box {
      width: initial;
    }
  }
`;

type InteractionStatus = "initial" | "date" | "time" | "complete";

const InteractiveDateTimePicker = (props: InteractiveItemProps) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(props.selectedValue || Date.now()),
  );
  const [interactionStatus, setInteractionStatus] = useState<InteractionStatus>(
    "initial",
  );

  useEffect(() => {
    if (props.selectedValue !== null) {
      setSelectedDate(new Date(props.selectedValue));
    }
  }, [props.selectedValue]);

  const handleDateChanged = useCallback(
    (date: Date | null) => {
      if (!date) {
        setInteractionStatus("initial");
        return;
      }

      setSelectedDate(date);

      const nextInteractionStatus =
        (interactionStatus === "date" && !props.showTimeSelect) ||
        interactionStatus === "time"
          ? "complete"
          : "time";
      setInteractionStatus(nextInteractionStatus);

      if (nextInteractionStatus === "complete") {
        props.onInteractionFinished(
          props.options[0],
          moment(date).toISOString(true),
        );
      }
    },
    [setSelectedDate, interactionStatus, setInteractionStatus],
  );

  const handleStartSelectionClicked = useCallback(() => {
    setInteractionStatus("date");
  }, [setInteractionStatus]);

  const { minDate, maxDate } = props.options[0].value;

  if (interactionStatus === "initial") {
    return (
      <DateTimePickerButtonContainer>
        <InteractiveButton
          bordered={false}
          selected={false}
          disabled={props.isDisabled}
          onClick={handleStartSelectionClicked}
        >
          {props.showTimeSelect
            ? strings("chat.buttons.dateTimePicker.selectDateTime")
            : strings("chat.buttons.dateTimePicker.selectDate")}
        </InteractiveButton>
      </DateTimePickerButtonContainer>
    );
  }

  if (interactionStatus === "complete") {
    return (
      <DateTimePickerButtonContainer>
        <InteractiveButton bordered={false} selected={false} disabled={true}>
          {strings("chat.buttons.dateTimePicker.success")}
        </InteractiveButton>
      </DateTimePickerButtonContainer>
    );
  }

  const defaultDatePickerProps = {
    selected: selectedDate,
    dateFormat: props.showTimeSelect ? "d. MMM yyyy HH:mm" : "d. MMM yyyy",
    inline: true,
    disabled: props.isDisabled,
    popperPlacement: "top-end" as Placement,
    onChange: handleDateChanged,
    ...(minDate && {
      minDate: new Date(minDate),
    }),
    ...(maxDate && {
      maxDate: new Date(maxDate),
    }),
  };

  if (interactionStatus === "date") {
    return (
      <InteractiveDateTimePickerContainer>
        <DateTimePicker {...defaultDatePickerProps} />
      </InteractiveDateTimePickerContainer>
    );
  }

  return (
    <InteractiveDateTimePickerContainer>
      <DateTimePicker
        {...defaultDatePickerProps}
        showTimeSelect={interactionStatus === "time"}
        showTimeSelectOnly={interactionStatus === "time"}
      />
    </InteractiveDateTimePickerContainer>
  );
};

export default InteractiveDateTimePicker;
