import { useEffect, useState } from "react";

import { getBoRepresentativeById } from "api/backOfficeBusinessRepresentatives";
import {
  getBpRepresentativeById,
  getCuratorRepresentativeById,
} from "api/businessRepresentatives";
import useMutation from "common/hooks/useMutation";
import useLoginState from "hooks/useLoginState";
import { RepresentativeInterface } from "api/models/RepresentativeInterface";
import { BackOfficeRepresentativeInterface } from "api/models/BackOfficeRepresentativeInterface";
import { NEW } from "constants/strings";
import { ADD_CURATOR } from "views/BusinessRepresentatives/useRepresentativesListView";

const useSelectedRepresentative = (id: string, businessId?: string) => {
  const [representative, setRepresentative] = useState<
    RepresentativeInterface | BackOfficeRepresentativeInterface
  >();
  const LoginState = useLoginState();
  const getRequest = LoginState.hasAdminRights()
    ? getBoRepresentativeById
    : LoginState.hasCuratorRights()
    ? getCuratorRepresentativeById
    : getBpRepresentativeById;

  const { makeRequest, error } = useMutation<
    { id: string; businessId?: string },
    RepresentativeInterface | BackOfficeRepresentativeInterface
  >(getRequest);

  useEffect(() => {
    if (id !== ADD_CURATOR) {
      const fetchRepresentativeData = async () => {
        const response = await makeRequest({ id, businessId });
        if (response && response.result) setRepresentative(response.result);
      };
      if (id && id !== NEW) {
        fetchRepresentativeData();
      }
      // TODO: Fix to match eslint rules
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  return { representative, error };
};

export default useSelectedRepresentative;
