import React, { Component, ComponentType } from "react";
import { Location } from "history";
import loginState from "state/singletons/loginState";
import { Redirect } from "react-router";

interface LoginRedirectProps {
  hasAccess?: boolean;
  path?: string;
  location?: Location;
}

const withLoginRedirect = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  class WithLoginRedirect extends Component<P & LoginRedirectProps> {
    render() {
      const isUserLoggedIn = loginState.isUserLoggedIn();
      if (this.props.location && !isUserLoggedIn && !this.props.hasAccess) {
        return (
          <Redirect
            to={{
              pathname: this.props.location.pathname.startsWith("/admin")
                ? "/admin/anmelden"
                : "/anmelden",
              state: { from: { pathname: this.props.location.pathname } },
            }}
          />
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  (WithLoginRedirect as any).displayName = `WithLoginRedirect(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`;

  return WithLoginRedirect;
};

export default withLoginRedirect;
