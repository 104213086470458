import styled from "style/styled-components";

interface IconProps {
  backgroundImage: string;
  backgroundSize?: string;
  offsetTop?: number;
  offsetLeft?: number;
  active?: boolean;
}

const Icon = styled.span<IconProps>`
  background-position: center;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  display: inline-block;
  margin-right: ${({ theme }) => theme.margin.large};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url("${backgroundImage}")` : "none"};
  background-size: ${({ backgroundSize }) =>
    backgroundSize ? backgroundSize : "contain"};
  position: relative;
  top: ${({ offsetTop }) => (offsetTop ? `${offsetTop}px` : 0)};
  left: ${({ offsetLeft }) => (offsetLeft ? `${offsetLeft}px` : 0)};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
`;

export const NoMarginIcon = styled(Icon)`
  margin: 0;
`;

export default Icon;
