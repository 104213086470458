import React, { useEffect, useState } from "react";
import {
  DropdownContainer,
  SearchInput,
  SpacedCloseableTagButton,
  TagButtonArea,
} from "components/forms/TagSelection/TagSelectionStyles";
import strings from "localisation/strings";
import styled from "style/styled-components";

export const HashtagsFieldContainer = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.border.light};
  margin-top: ${({ theme }) => theme.margin.standart};
  padding: ${({ theme }) =>
    `0 ${theme.margin.standart} ${theme.margin.standart}`};
`;

export const HashtagsFieldTitle = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: ${({ theme }) => theme.font.size.xlarge};
  color: ${({ theme }) => theme.color.foreground.secondary};
`;

export interface HashtagsFieldProps {
  value?: string;
  setValue: (newValue?: string) => void;
}

const HashtagsField = ({ value, setValue }: HashtagsFieldProps) => {
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (typeof value !== "string") {
      return;
    }

    if (value.length === 0) {
      return;
    }

    setHashtags(value.split(","));
  }, [value]);

  useEffect(() => {
    setValue(hashtags.join(","));
  }, [hashtags]);

  useEffect(() => {
    if (inputValue.length === 0) {
      return;
    }

    // Add a new hashtag if the user enters a space or a comma
    const lastChar = inputValue.charAt(inputValue.length - 1);
    if (lastChar === " " || lastChar === ",") {
      const hashtagToAdd = inputValue.substring(0, inputValue.length - 1);
      addHashtag(hashtagToAdd);
    }
  }, [inputValue]);

  const formatHashtag = (newValue: string) => {
    return newValue
      .toLowerCase()
      .replace(/[^\w-\u00E4\u00F6\u00FC\u00DF]/g, "");
  };

  const addHashtag = (hashtagToAdd: string) => {
    if (hashtagToAdd.length === 0) {
      return;
    }

    const newHashtag = formatHashtag(hashtagToAdd);

    const hashtagsWithoutDuplicate = getHashtagsWithoutDuplicate(newHashtag);

    setHashtags([...hashtagsWithoutDuplicate, newHashtag]);
    setInputValue("");
  };

  const removeHashtag = (hashtagToRemove: string) => {
    setHashtags(getHashtagsWithoutDuplicate(hashtagToRemove));
  };

  const getHashtagsWithoutDuplicate = (possibleDuplicate: string) =>
    hashtags.filter(hashtag => hashtag !== possibleDuplicate);

  return (
    <HashtagsFieldContainer>
      <HashtagsFieldTitle>{strings("hashtags.title")}</HashtagsFieldTitle>
      <DropdownContainer>
        <TagButtonArea>
          {hashtags.map(hashtag => (
            <SpacedCloseableTagButton
              key={hashtag}
              onClose={() => removeHashtag(hashtag)}
            >
              {hashtag}
            </SpacedCloseableTagButton>
          ))}
          <SearchInput
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onKeyPress={e => e.key === "Enter" && addHashtag(inputValue)}
            autoFocus={true}
            placeholder={strings("hashtags.placeholder")}
          />
        </TagButtonArea>
      </DropdownContainer>
    </HashtagsFieldContainer>
  );
};

export default HashtagsField;
