enum NotificationTypes {
  publishAlert = "PUBLISH_ALERT",
  publishPushNotification = "PUBLISH_PUSH_NOTIFICATION",
  alertToBusiness = "ALERT_TO_BUSINESS",
  topicOfTheWeek = "TOPIC_OF_THE_WEEK",
  smartService = "SMART_SERVICE",
  jobOffer = "JOB_OFFER",
}

export default NotificationTypes;
