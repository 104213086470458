import styled from "style/styled-components";

const ScreenSizeRestrictingArea = styled.div<{ isActive?: boolean }>`
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  left: ${({ isActive }) => (isActive ? "0" : "100%")};
  bottom: ${({ isActive }) => (isActive ? "0" : "100%")};
`;

export default ScreenSizeRestrictingArea;
