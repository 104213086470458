import React, { useEffect, useState } from "react";
import styled from "style/styled-components";

interface SizeProps {
  size: number;
}

const ImageFrame = styled.div<SizeProps>`
  flex-shrink: 0;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: ${({ theme }) => theme.border.radius.unlimited};
  background-color: ${({ theme }) => theme.color.foreground.tertiary};
  overflow: hidden;
`;

const AvatarContainer = styled.div<{ url?: string | null; size?: number }>`
  flex-shrink: 0;
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  overflow: hidden;
  background: ${({ theme }) => theme.color.chat.bg.avatar};
  margin-right: ${({ theme }) => theme.margin.medium};
  ${({ url }) => url && `background-image: url("${url}");`};
  background-size: cover;
  background-position: center center;
`;

const PlaceholderImage = styled.div<SizeProps>`
  flex-shrink: 0;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  background-color: ${({ theme }) => theme.color.foreground.tertiary};
`;

interface Props {
  picture?: string;
  size?: number;
}

const Avatar = ({ picture, size = 60 }: Props) => {
  const [pictureBroken, setPictureBroken] = useState(false);
  const [pictureLoaded, setPictureLoaded] = useState(false);
  useEffect(() => {
    if (picture) {
      let image: HTMLImageElement | undefined = new Image();
      const handleImageLoaded = () => {
        setPictureLoaded(true);
        image = undefined;
      };
      const handleImageLoadFailed = () => {
        setPictureBroken(true);
        image = undefined;
      };

      image.onload = handleImageLoaded;
      image.onerror = handleImageLoadFailed;
      image.src = picture;

      return () => {
        if (image) {
          image.removeEventListener("load", handleImageLoaded);
          image.removeEventListener("error", handleImageLoadFailed);
          image.src = ""; // cancel download
          image = undefined;
        }
      };
    }
  }, [picture]);
  return (
    <ImageFrame size={size}>
      {!!picture && !pictureBroken && pictureLoaded ? (
        <AvatarContainer url={picture} size={size} />
      ) : (
        <PlaceholderImage size={size} />
      )}
    </ImageFrame>
  );
};

export default Avatar;
