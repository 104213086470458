import React, { ReactElement } from "react";
import styled from "styled-components";
import Select, { OptionProps, components } from "react-select";
import { Arrow, Container } from "components/generic";
import FieldErrors from "components/forms/FieldErrors";
import Checkbox from "components/forms/Checkbox";
import { CommonProps } from "react-select/src/types";
import getEmojiTextComponents from "components/helpers/getEmojiTextComponents";
import RemoveButton from "components/buttons/RemoveButton";
import { Link } from "react-router-dom";
import theme from "style/theme";

export interface SelectItem<TValue = string | number | boolean> {
  value?: TValue;
  label?: string;
  isDisabled?: boolean;
}

export interface SelectPropsInterface {
  classNamePrefix?: string;
  defaultMenuIsOpen?: boolean;
  disabled?: boolean;
  dropDownIndicator?: () => ReactElement;
  onChange: (option: SelectItem) => void;
  value?: SelectItem | null;
  options: SelectItem[];
  placeholder?: string | number | boolean;
  small?: boolean;
  valueAsText?: boolean;
  errors?: string[];
  width?: string;
  isMulti?: boolean;
  customSelectOption?: (option: any) => React.ReactElement;
  hideSelectedOptions?: boolean;
  isSearchable?: boolean;
  onDashboard?: boolean;
  areCheckboxesDisabled?: boolean;
}

export const SelectBody = styled(Select)<{
  classNamePrefix: string;
  small: boolean;
  isDisabled: boolean;
  width: string;
  onDashboard: boolean;
  isErrored?: boolean;
}>`
  flex: 1;
  max-width: 100%;

  .${({ classNamePrefix }) => classNamePrefix}__indicators {
    padding: ${({ small }) => (small ? "0" : "0 10px 0 0")};

    > span {
      border-color: ${({ theme }) => theme.color.foreground.tertiary};
    }
  }

  .${({ classNamePrefix }) => classNamePrefix}__control {
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    height: ${({ small, theme }) =>
      small ? `${theme.formElements.text.height}px` : "55px"};
    min-height: ${({ theme }) => theme.formElements.text.height}px;
    padding: 0 10px 0 15px;
    width: 100%;
    flex: 1;

    border: 1px solid
      ${({ theme, isErrored }) =>
        isErrored ? theme.color.border.error : theme.color.border.inputBorder};

    border-radius: ${({ theme, small }) =>
      small ? theme.border.radius.small : theme.border.radius.medium};

    /* This is set for avoiding automatic zoom on iOS. Currently it's only applied for Dashboard screen. */
    /* For more detail, see the comments on: https://jira.nortal.com/browse/WSAI3-957 */
    ${({ onDashboard }) => (onDashboard ? "font-size: 16px;" : "")}
  }
  .${({ classNamePrefix }) => classNamePrefix}__control--is-disabled {
    .heroSelect__indicators .wup-arrow-icon {
      border-color: ${({ theme }) => theme.color.foreground.tertiary};
    }

    .heroSelect__single-value {
      color: ${({ theme }) => theme.color.foreground.tertiary};
    }
  }

  .${({ classNamePrefix }) => classNamePrefix}__value-container {
    padding: 0;
    vertical-align: top;
    height: 100%;
    display: flex;
    align-items: center;

    > input {
      position: absolute;
      height: 0;
    }
  }

  .${({ classNamePrefix }) => classNamePrefix}__placeholder {
    display: flex;
    align-items: center;
    margin: 0;
    position: relative;
    transform: none;
    top: auto;
    font-size: ${({ theme }) => theme.font.size.medium};
    color: ${({ theme }) => theme.color.background.tertiary};
  }

  .${({ classNamePrefix }) => classNamePrefix}__single-value {
    color: ${({ theme, isDisabled }) =>
      isDisabled
        ? theme.color.foreground.disabled
        : theme.color.foreground.primary};
    font-size: ${({ theme }) => theme.font.size.medium};
    font-weight: normal;
    position: inherit;
    top: auto;
    transform: none;
    padding: 0 10px 0 0;
    margin: 0;
    text-overflow: unset;
  }

  .${({ classNamePrefix }) => classNamePrefix}__placeholder,
    .${({ classNamePrefix }) => classNamePrefix}__single-value {
    // IE11 specific styles
    @media all and (-ms-high-contrast: none) {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      overflow: visible;
    }
  }

  .${({ classNamePrefix }) => classNamePrefix}__menu {
    top: ${({ small }) => (small ? "25px" : "40px")};
    box-shadow: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid ${({ theme }) => theme.color.foreground.tertiary};
    border-top: none;
    max-width: 100%;
    overflow: hidden;
    z-index: 10;
  }

  .selectList__menu {
    top: ${({ small }) => (small ? "30px" : "45px")};
    max-width: initial;
    border-top: 1px solid ${({ theme }) => theme.color.foreground.tertiary};
    border-top-right-radius: ${({ theme, small }) =>
      small ? theme.border.radius.small : theme.border.radius.medium};
    border-top-left-radius: ${({ theme, small }) =>
      small ? theme.border.radius.small : theme.border.radius.medium};
    width: auto;
    min-width: 100%;
    ${({ onDashboard, width }) =>
      onDashboard && width ? `max-width: ${width}` : ""};
    white-space: nowrap;

    // IE11 specific styles
    @media all and (-ms-high-contrast: none) {
      max-width: none;
      width: none;
    }
  }

  .${({ classNamePrefix }) => classNamePrefix}__option {
    width: 100%;
    padding: ${({ small }) => (small ? "5px 10px" : "5px 20px")};
  }

  .${({ classNamePrefix }) => classNamePrefix}__option--is-selected {
    background-color: ${({ theme }) => theme.color.foreground.secondaryDark};
    font-weight: bold;
  }

  .${({ classNamePrefix }) => classNamePrefix}__single-value::before {
    content: ${({ placeholder }) => placeholder || ""};
  }

  .${({ classNamePrefix }) => classNamePrefix}__control--is-focused,
    .${({ classNamePrefix }) => classNamePrefix}__control--menu-is-open:hover {
    border-color: ${({ theme }) => theme.color.foreground.tertiary};
  }
`;

const SelectContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-wrap: wrap;
  flex-basis: ${({ width }) => (width ? width : "100%")};
  max-width: ${({ width }) => (width ? width : "100%")};
`;

const Errors = styled(FieldErrors)`
  flex-basis: 100%;
`;

export const MultiOptionItemContainer = styled.div`
  padding: ${({ theme }) => `${theme.margin.medium} ${theme.margin.large}`};
  @-moz-document url-prefix() {
    padding: ${({ theme }) =>
      `${theme.margin.medium} ${theme.margin.xlarge} ${theme.margin.medium} 0`};
  }
  &:hover {
    background: ${({ theme }) => theme.color.background.option};
    font-weight: bold;
  }
`;

const MultiValueSelectedValueContainer = styled.div`
  display: flex;
  overflow: hidden;

  > span {
    font-size: ${({ theme }) => theme.font.size.medium};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
  }
`;

const CustomValueContainer = ({ getValue, children, ...props }: any) => (
  <components.ValueContainer {...props}>
    <MultiValueSelectedValueContainer>
      <span>
        {getValue()
          .map(({ label }: SelectItem) => label)
          .join(", ")}
      </span>
      {children}
    </MultiValueSelectedValueContainer>
  </components.ValueContainer>
);

// rewrite select components for multi-select checkbox layout
const getOtherComponents = (props: SelectPropsInterface) => {
  if (props.valueAsText) {
    return {
      SingleValue: () => (
        <div>
          {props.value
            ? typeof props.value.value === "string"
              ? getEmojiTextComponents(props.value.value, 15)
              : props.value.value
            : ""}
        </div>
      ),
    };
  } else if (props.customSelectOption) {
    return {
      ValueContainer: CustomValueContainer,
      Option: props.customSelectOption,
      MultiValue: () => null,
      ClearIndicator: () => null,
    };
  } else if (props.isMulti) {
    return {
      ValueContainer: CustomValueContainer,
      Option: (option: any) => {
        return option.value ? (
          <MultiOptionItemContainer>
            <Checkbox
              name={option.value}
              onChange={() => option.selectOption(option)}
              checked={option.isSelected}
              text={option.label}
              disabled={props.areCheckboxesDisabled && !option.isSelected}
            />
          </MultiOptionItemContainer>
        ) : (
          <></>
        );
      },
      MultiValue: () => null,
      ClearIndicator: () => null,
    };
  }
  return {
    SingleValue: () => (
      <div>
        {props.value
          ? typeof props.value.label === "string"
            ? getEmojiTextComponents(props.value.label, 15)
            : props.value.label
          : ""}
      </div>
    ),
    Option: (options: OptionProps<any, any>) => {
      return (
        <components.Option {...options}>
          {getEmojiTextComponents(options.label, 15)}
        </components.Option>
      );
    },
  };
};

const onDashboardStyleOverrides = {
  singleValue: (provided: any, state: CommonProps<any, any>) => {
    const display = state.selectProps.menuIsOpen ? "none !important" : "block";
    return { ...provided, display };
  },
};

const defaultDropdownIndicator = () => <Arrow size="large" />;

const MaterialDropdownSymbol = styled.span`
  width: 0;
  height: 0;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-top: 4px solid ${({ theme }) => theme.color.foreground.arrowIcon} !important;
  // IE11 specific styles
  @media all and (-ms-high-contrast: none) {
    display: inline-block;
    width: auto;
  }
`;

export const MaterialDropdownIndicator = () => <MaterialDropdownSymbol />;

const SelectComponent = (props: SelectPropsInterface) => {
  const {
    dropDownIndicator,
    disabled,
    value,
    errors,
    options,
    width,
    isSearchable,
    onDashboard,
    ...rest
  } = props;

  return (
    <SelectContainer width={width}>
      <SelectBody
        closeMenuOnSelect={!props.isMulti}
        isDisabled={disabled}
        components={{
          ...getOtherComponents(props),
          IndicatorSeparator: null,
          DropdownIndicator: dropDownIndicator || defaultDropdownIndicator,
        }}
        isSearchable={isSearchable || false}
        value={value}
        options={options}
        width={width}
        onDashboard={onDashboard}
        {...(props.onDashboard && {
          styles: onDashboardStyleOverrides,
        })}
        isErrored={!!(errors && errors.length)}
        {...rest}
      />
      <Errors errors={errors} />
    </SelectContainer>
  );
};

export default SelectComponent;
