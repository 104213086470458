import React from "react";
import { CuratorTopBarMenuChatLink } from "components/topBar/menu/components/links";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import BusinessContextSettingsArea from "components/topBar/menu/components/BusinessContextSettingsArea";
import { Container, Gradient } from "components/topBar/menu/MenuComponents";
import useLoginState from "hooks/useLoginState";

interface Props {
  hideBusinessName?: boolean;
  fillBackground?: boolean;
}

const CuratorMainBusinessAccountMenu = ({
  hideBusinessName,
  fillBackground,
}: Props) => {
  const { isMainBusinessContext } = useLoginState();
  const isMainBusiness = isMainBusinessContext();

  return (
    <Container>
      {!hideBusinessName && (
        <Container paddingLeft="16px">
          <BusinessContextSettingsArea />
        </Container>
      )}
      <Container>
        <Gradient />
      </Container>
      {isMainBusiness && (
        <Container fillBackground={fillBackground}>
          <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_CHAT}>
            <CuratorTopBarMenuChatLink />
          </PrivilegedComponent>
        </Container>
      )}
    </Container>
  );
};

export default CuratorMainBusinessAccountMenu;
