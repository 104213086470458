import React from "react";

import styled from "style/styled-components";
import { ReactComponent as BurgerIcon } from "assets/icons/burger.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { HeaderBody } from "components/topBar/HeaderComponents";
import loginState from "state/singletons/loginState";
import { getTopNavRoutes } from "core/navigation";
import AppLogo from "components/topBar/AppLogo";

const MobileSearch = styled.div`
  width: 100%;
  margin: 0;
  flex-grow: 1;
  justify-content: center;
`;

const Icon = styled.span`
  display: flex;
  flex-grow: 1;
  min-width: 64px;
  flex-basis: 80px;

  svg {
    margin: ${({ theme }) => theme.margin.sideMin};
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  path {
    fill: ${({ theme }) => theme.color.background.primary};
  }
`;

export interface MobileHeaderProps {
  areButtonsHidden?: boolean;
  onOpenMenu: () => void;
  onOpenUserMenu: () => void;
  appLogoLink: string;
}

const MobileHeader = ({
  areButtonsHidden,
  onOpenMenu,
  onOpenUserMenu,
  appLogoLink,
}: MobileHeaderProps) => {
  const topRoutes = getTopNavRoutes(loginState);
  return (
    <HeaderBody>
      <AppLogo link={appLogoLink} />
      {!areButtonsHidden && topRoutes.length !== 0 && (
        <Icon>
          <BurgerIcon onClick={onOpenMenu} />
        </Icon>
      )}
      <MobileSearch />
      {!areButtonsHidden && (
        <Icon>
          <UserIcon onClick={onOpenUserMenu} />
        </Icon>
      )}
    </HeaderBody>
  );
};

export default MobileHeader;
