import React, { forwardRef, Ref } from "react";
import styled from "style/styled-components";
import calendar from "assets/icons/calendar.svg";

interface Props {
  label: string;
  onClick?: (event: any) => void;
  value?: string;
  width?: string;
  small?: boolean;
}

interface HasContent {
  hasContent?: boolean | null;
}

const CustomInputContainer = styled.div<{
  width?: string;
  small?: boolean;
}>`
  width: 150px;
  height: ${({ small, theme }) =>
    small ? `${theme.formElements.text.height}px` : "55px"};
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`;

const Text = styled.div`
  align-self: flex-start;
  margin: auto 0;
`;

const Label = styled.div<HasContent & { small?: boolean }>`
  display: ${({ small, hasContent }) =>
    small && hasContent ? "none" : "inline-block"};
  color: ${({ theme, hasContent }) =>
    hasContent
      ? theme.color.font.onLightBackground
      : theme.color.foreground.tertiary};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ hasContent, theme }) =>
    hasContent ? theme.font.size.small : theme.font.size.medium};
  text-transform: capitalize;
  line-height: 13px;
  padding: ${({ theme }) => `0 ${theme.margin.medium}`};
  font-weight: normal;
`;

const DateValue = styled.div<HasContent>`
  display: ${({ hasContent }) => (hasContent ? "block" : "none")};
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 18px;
  padding: ${({ theme }) => `${theme.margin.xsmall} ${theme.margin.medium}`};
`;

const Icon = styled.span<{ src: string }>`
  display: inline-block;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translate(0, -50%);
`;

const DashboardDateInput = forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    const { label, value, onClick, small, width } = props;
    const hasDate = Boolean(value && value.length > 0);

    return (
      <CustomInputContainer
        width={width}
        onClick={onClick}
        small={small}
        ref={ref}
      >
        <Text>
          <Label hasContent={hasDate} small={small}>
            {label}
          </Label>
          <DateValue hasContent={hasDate}>{value}</DateValue>
        </Text>
        {!small && <Icon src={calendar} />}
      </CustomInputContainer>
    );
  },
);

export default DashboardDateInput;
