import React from "react";
import { RouteComponentProps } from "react-router";
import { curry } from "ramda";
import strings from "localisation/strings";

import useLoginState from "hooks/useLoginState";
import ListResultType from "api/models/ListResultType";
import OfferInterface from "api/models/OfferInterface";
import { getOffers, getOffersFilters } from "api/offers";

import { SearchRequest } from "components/lists/ListRequestTypes";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import FilterableList from "components/lists/FilterableList";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import OfferList from "components/lists/offerList/OfferList";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import ListTableView from "views/ListTableView";
import { NEW } from "constants/strings";

const AdminOffers = ({ location, history, match }: RouteComponentProps) => {
  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();

  const offerListRequest: SearchRequest<ListResultType<OfferInterface>> = ({
    offset,
    limit,
    body,
    q,
  }) =>
    curry(getOffers)(
      { q, filterReq: body, from: offset, size: limit },
      businessId,
      {},
    );

  const postOffersFiltersRequest: FiltersRequestType = ({ filters }) =>
    curry(getOffersFilters)({ filters }, businessId);

  const onClick = (id: string) => history.push(`angebote/${id}`);

  const renderListView = ({
    items,
    onItemClick,
  }: InteractiveListInterface<OfferInterface>) => {
    return (
      <OfferList
        emptyIndicatable
        emptyIndicator={strings("listView.noContentFilterable")}
        offers={items}
        onItemClick={onItemClick}
      />
    );
  };

  if (businessId) {
    return (
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_OFFERS} isView>
        <FilterableList<OfferInterface>
          onItemClick={onClick}
          location={location}
          history={history}
          match={match}
          pageTitle={strings("offersScreen.title")}
          filterPageTitle={strings("offersScreen.filterScreenTitle")}
          sortPageTitle={strings("sortScreen.title")}
          searchPageTitle={strings("searchScreen.title")}
          searchRequest={offerListRequest}
          postFiltersRequest={postOffersFiltersRequest}
          onAddNew={() => onClick(NEW)}
          renderListView={renderListView}
          defaultSort={DEFAULT_SORT}
          redirectOnEmptyResult={true}
        />
      </PrivilegedComponent>
    );
  }

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_OFFERS_ADMIN} isView>
      <ListTableView<OfferInterface> />
    </PrivilegedComponent>
  );
};

export default AdminOffers;
