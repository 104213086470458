import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { curry } from "ramda";
import { History } from "history";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { NEW } from "constants/strings";
import DetailedView, { Details } from "views/DetailedView";
import { createMap, getMap, updateMap } from "api/resources";
import useNotification from "hooks/useNotification";
import MapForm from "components/forms/MapForm";
import MapInterface from "api/models/MapInterface";

interface DetailsFormProps {
  updateItem: any;
  id: string;
  success: (message: string, response: any) => void;
  result?: MapInterface;
  changedValues: Set<string>;
  setBlockNavigation: (shouldBlockNavigation: boolean) => void;
  history: History;
}

const DetailsForm = ({ updateItem, id, ...props }: DetailsFormProps) => {
  return <MapForm isNew={false} createNew={updateItem(id)} {...props} />;
};

const changedValues = new Set<string>();

const AdminMapDetailed = (props: RouteComponentProps<{ id: string }>) => {
  const {
    history,
    match: {
      params: { id },
    },
  } = props;
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );
  const [forceDetailsUpdate, setForceDetailsUpdate] = useState<boolean>(true);
  const isNew = id === NEW;

  const { addSuccessNotification } = useNotification();
  const successCallback = (message: string, response: any) => {
    addSuccessNotification(message);
    if (isNew) {
      history.push(`/admin/konfiguration/karten`);
    } else {
      setForceDetailsUpdate(!forceDetailsUpdate);
    }
  };

  const mainProps = {
    changedValues,
    history,
    success: successCallback,
    setBlockNavigation: setShouldBlockNavigation,
    getDetailsRequest: curry(getMap),
  };

  const mainRequests = {
    createNew: createMap,
  };

  return (
    <DetailedView
      fullWidth
      privilegedId={PrivilegedComponentsIds.VIEW_MAPS}
      shouldBlockNavigation={shouldBlockNavigation}
      isCopy={false}
      isNew={isNew}
      form={<MapForm isNew={isNew} {...mainProps} {...mainRequests} />}
      copy={<></>}
      details={
        <Details
          id={id}
          form={DetailsForm}
          updateItem={updateMap}
          update={forceDetailsUpdate}
          {...mainProps}
        />
      }
    />
  );
};

export default withRouter(AdminMapDetailed);
