import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import { CenteredContentViewContainer, Title } from "components/generic";
import LoginForm from "components/forms/LoginForm/LoginForm";
import styleTheme from "style/theme";
import useLoginState from "hooks/useLoginState";
import { RouteComponentProps } from "react-router";

const LoginTitle = styled(Title)`
  margin: ${({ theme }) => `30px 0 ${theme.margin.large}`};
`;

const Login = (props: RouteComponentProps) => {
  const { hasCuratorRights } = useLoginState();
  if (hasCuratorRights()) {
    props.history.push("/inhalt/partnerprofil");
  }

  return (
    <CenteredContentViewContainer>
      <LoginTitle>{strings("loginForm.title")}</LoginTitle>
      <LoginForm width={styleTheme.forms.small} />
    </CenteredContentViewContainer>
  );
};

export default Login;
