import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { curry } from "ramda";

import strings from "localisation/strings";

import ListResultType from "api/models/ListResultType";
import PostInterface from "api/models/PostInterface";
import {
  getPosts,
  getCuratorPostsFromBusiness,
  getPostsFilters,
} from "api/posts";

import PostsList from "components/lists/postList/PostsList";
import FilterableList from "components/lists/FilterableList";
import { SearchRequest } from "components/lists/ListRequestTypes";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { NEW } from "constants/strings";
import { listTestId } from "testing/testId";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import ListTableView from "views/ListTableView";
import useLoginState from "hooks/useLoginState";

const postListRequest: SearchRequest<ListResultType<PostInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getPosts)(
    undefined,
    { q, filterReq: body, from: offset, size: limit },
    {},
  );

const postFiltersRequest: FiltersRequestType = ({ filters }) =>
  curry(getPostsFilters)({ filters }, undefined);

const Posts = ({ location, history, match }: RouteComponentProps) => {
  const onClick = (id: string) => history.push(`beitraege/${id}`);

  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();

  const postListRequestCurator: SearchRequest<ListResultType<
    PostInterface
  >> = ({ offset, limit, body, q }) =>
    curry(getCuratorPostsFromBusiness)(
      businessId,
      { q, filterReq: body, from: offset, size: limit },
      {},
    );

  const renderListView = ({
    items,
    onItemClick,
  }: InteractiveListInterface<PostInterface>) => (
    <PostsList
      emptyIndicatable
      emptyIndicator={strings("listView.noContentFilterable")}
      posts={items}
      onItemClick={onItemClick}
    />
  );

  if (getUserHasPrivilege(PrivilegedComponentsIds.VIEW_POSTS_CURATOR)) {
    if (businessId) {
      return (
        <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS} isView>
          <FilterableList<PostInterface>
            onItemClick={onClick}
            location={location}
            history={history}
            match={match}
            pageTitle={strings("postsScreen.title")}
            filterPageTitle={strings("postsScreen.filterScreenTitle")}
            sortPageTitle={strings("sortScreen.title")}
            searchPageTitle={strings("searchScreen.title")}
            searchRequest={postListRequestCurator}
            postFiltersRequest={postFiltersRequest}
            onAddNew={() => onClick(NEW)}
            renderListView={renderListView}
            defaultSort={DEFAULT_SORT}
            redirectOnEmptyResult={true}
            {...listTestId("posts")}
          />
        </PrivilegedComponent>
      );
    }
    return (
      <PrivilegedComponent
        id={PrivilegedComponentsIds.VIEW_POSTS_CURATOR}
        isView
      >
        <ListTableView<PostInterface> />
      </PrivilegedComponent>
    );
  }

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS} isView>
      <FilterableList<PostInterface>
        onItemClick={onClick}
        location={location}
        history={history}
        match={match}
        pageTitle={strings("postsScreen.title")}
        filterPageTitle={strings("postsScreen.filterScreenTitle")}
        sortPageTitle={strings("sortScreen.title")}
        searchPageTitle={strings("searchScreen.title")}
        searchRequest={postListRequest}
        postFiltersRequest={postFiltersRequest}
        onAddNew={() => onClick(NEW)}
        renderListView={renderListView}
        defaultSort={DEFAULT_SORT}
        redirectOnEmptyResult={true}
        {...listTestId("posts")}
      />
    </PrivilegedComponent>
  );
};

export default withRouter(Posts);
