import React from "react";
import { CenteredBlockTitle, CenteredText } from "components/generic";
import strings from "localisation/strings";
import { InlineTextButton } from "components/buttons/TextButton";
import { DetailsViewContainer } from "components/generic/ViewContainer";
import {
  partnerEmailSupportLinkText,
  partnerEmailSupportLink,
} from "core/constants";

const GenericError = () => (
  <>
    <DetailsViewContainer>
      <CenteredBlockTitle>{strings("genericError.title")}</CenteredBlockTitle>

      <CenteredText>
        {strings("genericError.message")}
        <InlineTextButton
          onClick={() => {
            window.location.href = partnerEmailSupportLink;
          }}
          text={partnerEmailSupportLinkText}
          type="inline"
        />
      </CenteredText>
    </DetailsViewContainer>
  </>
);

export default GenericError;
