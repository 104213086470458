import React, { useState, useEffect, useRef, useCallback } from "react";
import { moment } from "localisation/i18n";
import { curry } from "ramda";
import { RouteComponentProps, withRouter } from "react-router";
import ContentType from "common/api/models/ContentType";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { selectDashboard, selectContentItemOptions } from "api/dashboard";
import OverlaySpinner, {
  AbsoluteOverlaySpinnerContainer,
} from "components/generic/OverlaySpinner";
import {
  DashboardRequestInterface,
  DashboardInterface,
  DataWithTotal,
  ContentItemOption,
} from "api/models/DashboardInterface";
import DateTimeRange from "api/models/DateTimeRange";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import DashBoardContent from "components/dashboard/DashboardContent";
import StatisticType from "components/dashboard/StatisticType";
import DashboardHeader from "components/dashboard/DashboardHeader";
import { SelectItem } from "components/forms/Select";
import { initialSelectedOption } from "components/dashboard/ContentItemOption";
import SelectedContentItemOptionsInterface from "components/dashboard/SelectedContentItemOptionsInterface";
import { getOptionString } from "components/dashboard/DashboardUtils";
import useLoginState from "hooks/useLoginState";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";

const DATE_FORMAT = "YYYY-MM-DD";

const oneMonthAgo = moment().subtract(1, "month");
const INITIAL_FROM_DATE = oneMonthAgo.format(DATE_FORMAT);

const now = moment();
const INITIAL_TILL_DATE = now.format(DATE_FORMAT);

const Dashboard = (props: RouteComponentProps) => {
  const hasAdminRights = useLoginState().hasAdminRights();
  const isCurator = getUserHasPrivilege(
    PrivilegedComponentsIds.VIEW_DASHBOARD_CURATOR,
  );
  const previlegeRoute = isCurator
    ? "bp/curator"
    : hasAdminRights
    ? "bo"
    : "bp";

  const [contentType, setContentType] = useState<ContentType>("BUSINESS");

  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
    dateTimeFrom: INITIAL_FROM_DATE,
    dateTimeTill: INITIAL_TILL_DATE,
  });

  const [body, setBody] = useState<DashboardRequestInterface>({
    contentType,
    dateFrom: INITIAL_FROM_DATE,
    dateTill: INITIAL_TILL_DATE,
  });

  const [
    fetchedContentItemOptionsResult,
    setFetchedContentItemOptionsResult,
  ] = useState<ContentItemOption[] | undefined>(undefined);

  const [selectedContentItemOptions, setSelectedContentItemOptions] = useState<
    SelectedContentItemOptionsInterface
  >(defaultSelectedOptions);

  const isFirstContentItemOptionLoading = useRef<boolean>(true);
  useEffect(() => {
    if (isFirstContentItemOptionLoading.current) {
      isFirstContentItemOptionLoading.current = false;
      return;
    }

    const value: string = getSelectedOptionValue(
      selectedContentItemOptions,
      contentType,
    );

    // When "All" is selected
    if (value === "") {
      setBody({
        contentType,
        dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
        dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
      });
      setContentTypeUpdateBody({
        contentType,
        dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
        dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
      });
    }

    // When a content is selected
    const contentId: string = value;
    setBody({
      contentId,
      contentType,
      dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
      dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
    });
    setContentTypeUpdateBody({
      contentId,
      contentType,
      dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
      dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
    });
  }, [selectedContentItemOptions, contentType]);

  useEffect(() => {
    setBody(oldBody => ({
      ...oldBody,
      contentType,
      dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
      dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
    }));
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTimeRange]);

  // This is needed for detecting when only content type is updated on the body
  // so that it could prevent to make a request when date range is selected (before clicking confirm button)
  const [contentTypeUpdateBody, setContentTypeUpdateBody] = useState<
    DashboardRequestInterface
  >(body);

  const isFirstContentTypeLoading = useRef<boolean>(true);

  const [fetchedDashboardResult, setFetchedDashboardResult] = useState<
    DashboardInterface | undefined
  >(undefined);

  const businessId = getBusinessIdForBOsOrCurators(
    hasAdminRights || isCurator,
    props.location.pathname,
  );
  const selectDashboardRequest = curry(selectDashboard)(previlegeRoute)(
    businessId,
  );
  const {
    makeRequest: dashBoardMakeRequest,
    status: dashBoardStatus,
  } = useMutation(selectDashboardRequest);
  const dashBoardMakeRequestRef = useRef(dashBoardMakeRequest);
  const onReadyToUpdateGraph = useCallback(
    async (requestBody: DashboardRequestInterface) => {
      const response = await dashBoardMakeRequestRef.current(requestBody);
      if (response && response.result) {
        setFetchedDashboardResult(response.result);
      } else {
        setFetchedDashboardResult(defaultDashboardFetchedResult);
      }
    },
    [],
  );
  const selectContentItemOptionsRequest = curry(selectContentItemOptions)(
    previlegeRoute,
  )(businessId);
  const {
    makeRequest: contentItemOptionsMakeRequest,
    status: contentItemOptionsStatus,
  } = useMutation(selectContentItemOptionsRequest);

  const contentItemOptionsMakeRequesttRef = useRef(
    contentItemOptionsMakeRequest,
  );

  const [statisticType, setStatisticType] = useState<string>(
    StatisticType.totalClicks,
  );

  const updateContentItemOptions = async (
    contentTypeToBeFetched: ContentType,
    dateTimeFrom: string | undefined,
    dateTimeTill: string | undefined,
  ) => {
    const response = await contentItemOptionsMakeRequesttRef.current({
      dateTimeFrom,
      dateTimeTill,
      contentType: contentTypeToBeFetched,
    });
    if (response && response.result && response.result.data.length > 0) {
      setFetchedContentItemOptionsResult(response.result.data);
    } else {
      setFetchedContentItemOptionsResult([]);
    }
  };

  useEffect(() => {
    updateContentItemOptions(
      contentType,
      formatStartDateForSelectItemRequestBody(dateTimeRange.dateTimeFrom),
      formatEndDateForSelectItemRequestBody(dateTimeRange.dateTimeTill),
    );

    if (isFirstContentTypeLoading.current) {
      isFirstContentTypeLoading.current = false;
      return;
    }

    const value = getSelectedOptionValue(
      selectedContentItemOptions,
      contentType,
    );

    if (value === "") {
      // When "All" is selected
      setBody({
        contentType,
        dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
        dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
      });
      setContentTypeUpdateBody({
        contentType,
        dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
        dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
      });
    } else {
      // When a content is selected
      const contentId: string = value;
      setBody({
        contentId,
        contentType,
        dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
        dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
      });
      setContentTypeUpdateBody({
        contentId,
        contentType,
        dateFrom: formatDateForRequestBody(dateTimeRange.dateTimeFrom),
        dateTill: formatDateForRequestBody(dateTimeRange.dateTimeTill),
      });
    }
  }, [contentType, selectedContentItemOptions]);

  useEffect(() => {
    const typeOnlyForEventIsSelected =
      contentTypeUpdateBody.contentType !== "EVENT" &&
      statisticType === StatisticType.calendarAdded;

    const typeOnlyForOfferIsSelected =
      contentTypeUpdateBody.contentType !== "OFFER" &&
      statisticType === StatisticType.offersRedeemed;

    const isPostOrNews =
      contentTypeUpdateBody.contentType === "POST" ||
      contentTypeUpdateBody.contentType === "NEWS";
    const likesOrFavoritesAddedSelectedWhenUnavailable =
      isPostOrNews &&
      (statisticType === StatisticType.favoritesAdded ||
        statisticType === StatisticType.likesAdded);

    if (
      typeOnlyForEventIsSelected ||
      typeOnlyForOfferIsSelected ||
      likesOrFavoritesAddedSelectedWhenUnavailable
    ) {
      setStatisticType(StatisticType.totalClicks);
    }

    onReadyToUpdateGraph(contentTypeUpdateBody);
  }, [contentTypeUpdateBody]);

  useEffect(() => {
    if (
      fetchedDashboardResult &&
      fetchedDashboardResult.contentType !== "EVENT" &&
      statisticType === StatisticType.calendarAdded
    ) {
      setStatisticType(StatisticType.totalClicks);
    }
    updateContentItemOptions(
      contentType,
      formatStartDateForSelectItemRequestBody(dateTimeRange.dateTimeFrom),
      formatEndDateForSelectItemRequestBody(dateTimeRange.dateTimeTill),
    );
  }, [statisticType, fetchedDashboardResult]);

  if (
    dashBoardStatus !== QueryStatus.SUCCESSFUL ||
    fetchedDashboardResult === undefined ||
    contentItemOptionsStatus !== QueryStatus.SUCCESSFUL ||
    fetchedContentItemOptionsResult === undefined
  ) {
    return (
      <AbsoluteOverlaySpinnerContainer>
        <OverlaySpinner />
      </AbsoluteOverlaySpinnerContainer>
    );
  }

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_DASHBOARD} isView>
      <DashboardHeader
        dateTimeRange={dateTimeRange}
        setDateTimeRange={setDateTimeRange}
        onClick={() => onReadyToUpdateGraph(body)}
      />
      <DashBoardContent
        fetchedDashboardResult={fetchedDashboardResult}
        fetchedContentItemOptionsResult={fetchedContentItemOptionsResult}
        contentType={contentType}
        setContentType={setContentType}
        selectedContentItemOptions={selectedContentItemOptions}
        setSelectedContentItemOptions={setSelectedContentItemOptions}
        statisticType={statisticType}
        setStatisticType={setStatisticType}
      />
    </PrivilegedComponent>
  );
};

const formatDateForRequestBody = (date: string | undefined): string => {
  return moment(date).format(DATE_FORMAT);
};

const formatStartDateForSelectItemRequestBody = (
  date: string | undefined,
): string => {
  return moment(date)
    .startOf("day")
    .toISOString();
};

const formatEndDateForSelectItemRequestBody = (
  date: string | undefined,
): string => {
  return moment(date)
    .endOf("day")
    .toISOString();
};

const defaultSelectedOptions: SelectedContentItemOptionsInterface = {
  businessProfile: initialSelectedOption,
  offer: initialSelectedOption,
  event: initialSelectedOption,
  post: initialSelectedOption,
  news: initialSelectedOption,
};

const getBusinessIdForBOsOrCurators = (
  hasAdminRights: boolean,
  pathname: string,
): string => {
  if (!hasAdminRights) {
    return "";
  }

  const splittedPathname = pathname.split("/").filter(item => !!item);
  return splittedPathname.length > 2 ? splittedPathname.slice(-2)[0] : "";
};

const getSelectedOptionValue = (
  selectedContentItemOptions: SelectedContentItemOptionsInterface,
  contentType: ContentType,
): string => {
  const optionString = getOptionString(contentType);

  return extractSelectedOptionValueString(
    selectedContentItemOptions[optionString],
  );
};

const extractSelectedOptionValueString = (
  selectedContentItemOption: SelectItem,
): string => {
  const value = selectedContentItemOption.value;

  if (typeof value === "string") {
    return value;
  }

  return "";
};

const defaultDataWithTotal: DataWithTotal = {
  total: 0,
  data: [],
};

const defaultDashboardFetchedResult: DashboardInterface = {
  contentType: "BUSINESS",
  totalClicks: defaultDataWithTotal,
  searchClicks: defaultDataWithTotal,
  objectsShared: defaultDataWithTotal,
  favoritesAdded: defaultDataWithTotal,
  likesAdded: defaultDataWithTotal,
  ratedOrReviewed: defaultDataWithTotal,
};

export default withRouter(Dashboard);
