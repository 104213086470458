import React, { useState, useEffect } from "react";
import { curry, includes, replace, __ } from "ramda";
import { History } from "history";
import { withRouter } from "react-router";
import strings from "localisation/strings";
import styled from "style/styled-components";

import {
  getBusinessProfile,
  createBusinessProfile,
  createDraftBusinessProfile,
  createDraftBusinessProfileAdmin,
  updateBusinessProfile,
  updateDraftBusinessProfile,
  getImprintByLoggedInBusiness,
  getImprintByLoggedInBusinessAdmin,
  getBusinessProfileAdmin,
  updateBusinessProfileAdmin,
  updateDraftBusinessProfileAdmin,
  getAdminImprintByBusinessId,
  createBusinessProfileAdmin,
  getBusinessProfileCurator,
  updateBusinessProfileCurator,
  updateDraftBusinessProfileCurator,
  createDraftBusinessProfileCurator,
} from "api/businessProfiles";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import ListParams from "api/models/ListParams";
import RequestOptions from "api/RequestOptions";

import BusinessProfileForm, {
  BusinessProfileFormProps,
} from "components/forms/BusinessProfileForm";
import ActionButton from "components/buttons/ActionButton";
import { DetailsViewContainer } from "components/generic/ViewContainer";
import ActionArea from "components/generic/ActionArea";

import DetailedView, { Copy, Details } from "views/DetailedView";
import useQuery from "common/hooks/useQuery";
import useNotification from "hooks/useNotification";
import useLoginState from "hooks/useLoginState";
import { COPY, NEW } from "constants/strings";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { AbortableRequest } from "common/api/AbortableRequest";
import emojiDataState from "state/singletons/emojiDataState";
import { getEmojiData } from "api/imageStorage";
import QueryStatus from "common/api/models/QueryStatus";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";

const CopyForm = (props: any) => <BusinessProfileForm isNew {...props} />;

const DetailsForm = ({
  isDraft,
  updateItem,
  updateDraftItem,
  ...props
}: any) => (
  <BusinessProfileForm
    isNew={false}
    createNew={updateItem}
    createDraft={isDraft ? updateDraftItem : updateItem}
    {...props}
  />
);

interface NewCopyFormProps extends BusinessProfileFormProps {
  getDetailsRequest: (
    id: string,
    queryParams?: ListParams,
    options?: RequestOptions,
  ) => AbortableRequest<any>;
  profileId: string;
}

/**
 * NewCopyForm is a wrapper that handles the special case of "Add new" view
 * opened from an existing BUP entity. It makes a call to the existing BUP,
 * using it as a tag donor, and pre-sets the tags/subTags fields
 */
const NewCopyForm = ({
  getDetailsRequest,
  profileId,
  ...props
}: NewCopyFormProps) => {
  const [result, setResult] = useState<any>();
  const { result: tagDonor } = useQuery({
    request: getDetailsRequest(profileId, {}),
  });

  useEffect(() => {
    if (tagDonor) {
      const { tags = [], subTags = [] } = tagDonor;

      // pre-set fields required for initial render
      setResult({
        tags,
        subTags,
        imprintInformation: {},
        contactInformation: {},
      });
    }
  }, [tagDonor]);

  if (!result) return null;

  return (
    <BusinessProfileForm
      {...props}
      isNew={true}
      isNewCopy={true}
      result={result}
    />
  );
};

const changedValues = new Set<string>();

const ProfileActionArea = styled(DetailsViewContainer)`
  padding-top: ${({ theme }) => theme.margin.large};
  width: 100%;
`;

const ProfileActionButton = styled(ActionButton)`
  height: 30px;
  min-width: auto;
  padding: 0 ${({ theme }) => theme.margin.large};
  margin: 0 ${({ theme }) => theme.margin.small};
`;

interface Props {
  match: {
    params: {
      id: string;
      businessId?: string;
    };
  };
  location: {
    search: string;
  };
  history: History;
}

const BusinessProfileDetailed = (props: Props) => {
  const {
    history,
    location: { search },
    match: {
      params: { id, businessId: providedBusinessId },
    },
  } = props;

  const { addSuccessNotification } = useNotification();
  // grabbing businessId to address BO instead of BP when necessary
  const { getBusinessId, hasAdminRights, hasCuratorRights } = useLoginState();
  const adminRights = hasAdminRights();
  const isCurator = hasCuratorRights();
  const contextBusinessId = getBusinessId();
  const businessId =
    adminRights || isCurator
      ? providedBusinessId || contextBusinessId
      : undefined;

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );
  const [forceDetailsUpdate, setForceDetailsUpdate] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(id === NEW);
  const isCopy = includes(id, COPY);
  const copyId = replace("?id=", "", search);
  const isNewCopy = isNew && copyId;

  const adminImprintRequest = businessId
    ? curry(getAdminImprintByBusinessId)(businessId)
    : getImprintByLoggedInBusinessAdmin;

  const imprintRequest = adminRights
    ? adminImprintRequest
    : getImprintByLoggedInBusiness;

  const { result: imprint } = useQuery({
    request: imprintRequest,
  });

  useEffect(() => {
    return setIsNew(id === NEW);
  }, [id]);

  const goTo = (path: string) => history.push(path);

  const successCallback = (
    message: string,
    response: BusinessUserProfileInterface,
  ) => {
    addSuccessNotification(message);
    if (isNew || isCopy) {
      props.history.push(
        adminRights
          ? contextBusinessId
            ? `/admin/inhalt/partner/${contextBusinessId}/partnerprofil/${response.id}`
            : `/admin/inhalt/partnerprofil/${response.businessId}/${response.id}`
          : `/partnerprofil/${response.id}`,
      );
    } else {
      setForceDetailsUpdate(!forceDetailsUpdate);
    }
  };

  const profileActions =
    !adminRights || businessId ? (
      <ActionArea>
        <ProfileActionButton
          text={strings("buttons.copy")}
          onClick={() => goTo(`${COPY}?id=${id}`)}
        />
        <ProfileActionButton
          text={strings("buttons.addNew")}
          onClick={() => goTo(`${NEW}?id=${id}`)}
        />
      </ActionArea>
    ) : null;

  // assigning calls depending on user's access rights
  const getBusinessProfileRequest = isCurator
    ? getBusinessProfileCurator
    : adminRights
    ? getBusinessProfileAdmin
    : getBusinessProfile;
  const updateBusinessProfileRequest = isCurator
    ? updateBusinessProfileCurator
    : adminRights
    ? updateBusinessProfileAdmin
    : updateBusinessProfile;
  const updateDraftBusinessProfileRequest = isCurator
    ? updateDraftBusinessProfileCurator
    : adminRights
    ? updateDraftBusinessProfileAdmin
    : updateDraftBusinessProfile;

  const mainProps = {
    businessId,
    imprint,
    changedValues,
    history,
    success: successCallback,
    setBlockNavigation: setShouldBlockNavigation,
    getDetailsRequest: curry(getBusinessProfileRequest),
  };

  // prettier-ignore
  const mainRequests = {
    createNew:
    isCurator 
      ? // @ts-ignore
        curry(createDraftBusinessProfileCurator)(__, businessId)
      :
      adminRights && !businessId
        ? curry(createBusinessProfileAdmin)
        // @ts-ignore
        : curry(createBusinessProfile)(__, businessId),
    createDraft:
    isCurator 
      ? // @ts-ignore
        curry(createDraftBusinessProfileCurator)(__, businessId)
      :
      adminRights && !businessId
        ? curry(createDraftBusinessProfileAdmin)
        // @ts-ignore
        : curry(createDraftBusinessProfile)(__, businessId),
  };

  // if we are creating a new BUP from an existing BUP view, use NewCopyForm
  const formComponent = isNewCopy ? (
    <NewCopyForm isNew profileId={copyId} {...mainRequests} {...mainProps} />
  ) : (
    <BusinessProfileForm isNew {...mainRequests} {...mainProps} />
  );

  const {
    result: emojiDataFetchResult,
    status: emojiDataFetchStatus,
  } = useQuery({ request: getEmojiData });
  useEffect(() => {
    if (emojiDataFetchStatus === QueryStatus.SUCCESSFUL) {
      emojiDataState.setEmojiData(emojiDataFetchResult);
    }
  }, [emojiDataFetchStatus]);

  return (
    <>
      {!isNew && !isCopy && (
        <ProfileActionArea>{profileActions}</ProfileActionArea>
      )}
      <DetailedView
        {...props}
        privilegedId={PrivilegedComponentsIds.VIEW_BUSINESS_PROFILE}
        shouldBlockNavigation={shouldBlockNavigation}
        isCopy={isCopy}
        isNew={isNew}
        form={formComponent}
        copy={
          <Copy
            id={copyId}
            form={CopyForm}
            {...mainRequests}
            {...mainProps}
            isNewProfile={isNew}
          />
        }
        details={
          <Details
            id={props.match.params.id}
            form={DetailsForm}
            updateItem={updateBusinessProfileRequest}
            updateDraftItem={updateDraftBusinessProfileRequest}
            update={forceDetailsUpdate}
            {...mainProps}
          />
        }
      />
    </>
  );
};

export default withRouter(BusinessProfileDetailed);
