import styled from "style/styled-components";

const ActionButtonText = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.font.size.medium};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: bold;
`;

export default ActionButtonText;
