import React from "react";
import styled from "style/styled-components";
import { typeButtonTestId } from "testing/testId";

const Container = styled.button<{ isSelected: boolean }>`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  white-space: nowrap;
`;

export interface TypeButtonProps {
  isSelected: boolean;
  typeName: string;
  onClick: () => void;
  typeKey: string;
}

export const TypeButton = ({
  isSelected,
  typeName,
  onClick,
  typeKey,
}: TypeButtonProps) => (
  <Container
    isSelected={isSelected}
    onClick={onClick}
    {...typeButtonTestId(typeKey)}
  >
    {typeName}
  </Container>
);
