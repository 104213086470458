import styled from "style/styled-components";
import breakpoints from "style/breakpoints";

const lineHeight = 20;

const SpreadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ResponsiveSpreadRow = styled(SpreadRow)`
  @media ${breakpoints.tabletMax} {
    flex-direction: column;
  }
`;

const Row = styled(SpreadRow)`
  margin-top: ${({ theme }) => theme.margin.small};
`;

const HighRow = styled(SpreadRow)<{ tagAreaMinLines: number }>`
  position: relative;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.margin.medium};
  min-height: ${({ tagAreaMinLines }) =>
    `${tagAreaMinLines * lineHeight +
      (tagAreaMinLines >= 1 ? tagAreaMinLines - 1 : 0) * 8}px`};
`;

export { SpreadRow as default, Row, HighRow, ResponsiveSpreadRow };
