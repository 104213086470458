import { useState, useEffect } from "react";
import loadGoogleMapsApi from "load-google-maps-api";
import useLocale from "common/hooks/useLocale";

export enum GoogleAutocompleteAddressType {
  GEOCODE = "geocode",
  ADDRESS = "address",
  ESTABLISHMENT = "establishment",
  REGIONS = "(regions)",
  CITIES = "(cities)",
}

export type GoogleAddressComponentType =
  | "route"
  | "postal_code"
  | "street_number"
  | "locality";

export interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface UseGooglePlacesParams {
  query?: string;
  queryValidator?: (query: string) => boolean;
}

const useGooglePlacesAPI = ({
  query = "",
  queryValidator = queryToValidate => queryToValidate !== "",
}: UseGooglePlacesParams = {}) => {
  const [autocompleteService, setAutocompleteService] = useState<
    google.maps.places.AutocompleteService
  >();
  const [geocoderService, setGeocoderService] = useState<
    google.maps.Geocoder
  >();
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNoAddressFound, setIsNoAddressFound] = useState(false);
  const [isGoogleMapsApisLoaded, setIsGoogleMapsApisLoaded] = useState(false);

  const locale = useLocale();

  useEffect(() => {
    loadGoogleMapsApi({
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      language: locale,
      libraries: ["places"],
    }).then(() => {
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
      setGeocoderService(new google.maps.Geocoder());
      setAutocompleteService(new google.maps.places.AutocompleteService());
      setIsGoogleMapsApisLoaded(true);
    });
  }, []);

  useEffect(() => {
    // Reset session token every 3 minutes
    window.setInterval(
      () => setSessionToken(new google.maps.places.AutocompleteSessionToken()),
      3 * 60 * 1000,
    );
  }, []);

  const fetchGeocodeByPlaceId = (placeId: string) =>
    new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      if (!geocoderService) {
        return reject(google.maps.GeocoderStatus.ERROR);
      }

      return geocoderService.geocode({ placeId }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results) {
          resolve(results);
        }
        reject(status);
      });
    });

  const fetchPredictionsForCurrentQuery = () =>
    new Promise<google.maps.places.AutocompletePrediction[]>(
      (resolve, reject) => {
        if (!autocompleteService) {
          return reject(google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR);
        }

        return autocompleteService.getPlacePredictions(
          {
            input: query,
            types: [GoogleAutocompleteAddressType.ADDRESS],
            sessionToken: sessionToken as google.maps.places.AutocompleteSessionToken,
          },
          (placePredictions, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK ||
              status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              resolve(placePredictions || []);
            } else {
              reject(status);
            }
          },
        );
      },
    );

  useEffect(() => {
    const handleQueryChanged = () => {
      const isQueryValid = queryValidator(query);
      if (!isQueryValid) {
        return;
      }

      setIsError(false);
      setIsLoading(true);

      fetchPredictionsForCurrentQuery()
        .then(fetchedPredictions => {
          setIsLoading(false);
          setPredictions(fetchedPredictions);
          setIsNoAddressFound(fetchedPredictions.length === 0);
        })
        .catch(() => {
          setIsLoading(false);
          setPredictions([]);
          setIsError(true);
        });
    };

    const queryChangeTimerHandle = window.setTimeout(handleQueryChanged, 300);
    return () => window.clearTimeout(queryChangeTimerHandle);
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteService, query]);

  const getPlaceDetails = async (placeId: string) => {
    try {
      setIsError(false);
      const geocodeAddressesDetails = await fetchGeocodeByPlaceId(placeId);
      const [firstGeocodeAddress] = geocodeAddressesDetails;
      return firstGeocodeAddress;
    } catch (e) {
      setIsError(true);
    }
  };

  const getPlaceDetailsByCoordinates = async (coords: google.maps.LatLng) =>
    new Promise<google.maps.GeocoderResult | undefined>((resolve, reject) => {
      if (!geocoderService) {
        return reject(google.maps.GeocoderStatus.UNKNOWN_ERROR);
      }
      return geocoderService.geocode(
        { location: coords },
        (results, status) => {
          let processingResult;
          if (status === google.maps.GeocoderStatus.OK) {
            if (results) {
              const [firstGeocodeAddress] = results;
              processingResult = resolve(firstGeocodeAddress);
            }
          } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            processingResult = resolve(undefined);
          } else {
            processingResult = reject(status);
          }
          return processingResult;
        },
      );
    });

  return {
    isError,
    isLoading,
    isNoAddressFound,
    isGoogleMapsApisLoaded,
    getPlaceDetails,
    getPlaceDetailsByCoordinates,
    results: predictions,
    resetResults: () => setPredictions([]),
  };
};

export default useGooglePlacesAPI;
