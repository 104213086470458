import React from "react";
import FilterSection from "components/lists/filtering/FilterSection";
import NestedTagList, {
  NestedTagListProps,
} from "components/forms/NestedTagList";

const TagFilterList = (props: NestedTagListProps) => (
  <FilterSection>
    <NestedTagList {...props} />
  </FilterSection>
);

export default TagFilterList;
