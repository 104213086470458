import strings from "localisation/strings";

export interface StringSelectOption {
  value: string;
  label: string;
}

const GeoLocations: StringSelectOption[] = [
  {
    value: "Lokal",
    label: strings("geoLocations.wuppertal"),
  },
  {
    value: "Nordrhein-Westfalen",
    label: strings("geoLocations.nordrheinWestfalen"),
  },
  {
    value: "Deutschland",
    label: strings("geoLocations.deutschland"),
  },
  {
    value: "Weltweit",
    label: strings("geoLocations.weltweit"),
  },
];

export default GeoLocations;
