import React from "react";
import { curry } from "ramda";
import { RouteComponentProps } from "react-router";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import { validateAdminAccountToken } from "api/session";
import { Null } from "components/generic";
import RepresentativeLinkExpiredView from "views/RepresentativeUserActivation/RepresentativeLinkExpiredView";
import RepresentativePasswordCreationView from "views/RepresentativeUserActivation/RepresentativePasswordCreationView";
import useLoginState from "hooks/useLoginState";

interface RepresentativeActivationRouteParams {
  token: string;
}

type Props = RouteComponentProps<RepresentativeActivationRouteParams>;

const ViewByResponseStatusMap = {
  [QueryStatus.ERROR]: RepresentativeLinkExpiredView,
  [QueryStatus.SUCCESSFUL]: RepresentativePasswordCreationView,
  [QueryStatus.UNDETERMINED]: Null,
  [QueryStatus.WAITING]: Null,
};

const AccountActivation = ({ match }: Props) => {
  const loginState = useLoginState();
  loginState.logOut();
  const { status } = useQuery<void>({
    request: curry(validateAdminAccountToken)(match.params.token),
    compare: [match.params.token],
  });

  return React.createElement(ViewByResponseStatusMap[status], {
    isAdminForm: true,
  });
};

export default AccountActivation;
