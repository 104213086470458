import React, { Fragment } from "react";
import styled, { isMobile, isTablet } from "style/styled-components";
import { NavLink } from "react-router-dom";
import strings from "localisation/strings";
import { UnderlineButtonArea } from "components/buttons/UnderlineButton";
import { getFooterRoutes, SingleRoute } from "core/navigation";
import FooterLinkSeparator from "components/footer/FooterLinkSeparator";
import useWindowDimensions from "hooks/useWindowDimensions";
import globalTheme from "style/theme";
import breakpoints from "style/breakpoints";

const activeClassName = "active-footer";

const MobileFooter = styled.div`
  display: grid;
  flex-flow: row nowrap;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.color.foreground.secondary};
  align-items: center;
`;

// Desktop footer has a left margin equal to the header logo area width, to align it with the header menu
const DesktopFooter = styled(UnderlineButtonArea).attrs(props => ({
  buttonHeight: props.theme.globalComponents.footerNavigationButtonHeight,
}))`
  max-width: fit-content;
  margin: auto;
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  background-color: ${({ theme }) => theme.color.foreground.secondary};
  position: sticky;
  bottom: 0;
  @media ${breakpoints.tabletMax} {
    position: static;
  }
`;

const NavItem = styled(NavLink)`
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  font-weight: 400;
  margin: 0 ${({ theme }) => theme.margin.sideMin};
  padding: 14px 0;
  align-self: flex-start;
  text-decoration: none;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.font.family.firaSans};
  line-height: 18px;
  font-size: 15px;

  &.${activeClassName} {
    font-weight: 400;
  }
`;

const Logo = styled(globalTheme.icons.Logo)`
  display: block;
  margin: 32px 18px 14px;
  width: 74px;
  height: 20px;
`;

const Footer = () => {
  const footerRoutes = getFooterRoutes();

  const renderLink = ({
    path,
    exact,
    name,
    isLastLink,
    hideSeparator,
  }: SingleRoute & { isLastLink: boolean; hideSeparator?: boolean }) => {
    const linkProps = {
      activeClassName,
      exact,
      key: `footer.${name}`,
      to: path,
      children: strings(`footer.${name}`),
    };

    return (
      <Fragment key={`separator.container.${name}`}>
        <NavItem {...linkProps} />
        {!isLastLink && !hideSeparator && (
          <FooterLinkSeparator key={`separator.${name}`} />
        )}
      </Fragment>
    );
  };

  const screen = useWindowDimensions();
  const mobileOrTablet = isMobile(screen.width) || isTablet(screen.width);

  return (
    <FooterWrapper>
      {mobileOrTablet ? (
        <MobileFooter>
          <Logo />
          {footerRoutes.map(
            (item, index) =>
              item.path.length > 0 &&
              renderLink({
                ...item,
                isLastLink: index === footerRoutes.length - 1,
                hideSeparator: true,
              }),
          )}
        </MobileFooter>
      ) : (
        <DesktopFooter>
          {footerRoutes.map(
            (item, index) =>
              item.path.length > 0 &&
              renderLink({
                ...item,
                isLastLink: index === footerRoutes.length - 1,
              }),
          )}
        </DesktopFooter>
      )}
    </FooterWrapper>
  );
};

export default Footer;
