import useLoginState from "hooks/useLoginState";
import styled from "styled-components";

interface Props {
  fillBackground?: boolean;
  noContent?: boolean;
  paddingLeft?: string;
}

export const Container = styled.div<Props>`
  display: flex;
  position: relative;
  height: 47px;
  ${({ fillBackground }) => (fillBackground ? "background-color: white;" : "")}
  ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft};` : "")}
  ${({ noContent }) => (noContent ? "visibility: hidden;" : "")}
`;

export const Gradient = styled.div`
  width: 25px;
  height: 47px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

export const getUserHasPrivilege = (id: string) => {
  // eslint-disable-next-line
  const privilegedComponentSet = useLoginState().getPrivilegedComponentSet();
  return !!privilegedComponentSet && privilegedComponentSet.has(id);
};
