import React from "react";
import { changeAdminPassword, postResetPassword } from "api/session";
import {
  CenteredBlockTitle,
  CenteredContentViewContainer,
} from "components/generic";
import strings from "localisation/strings";
import { RouteComponentProps } from "react-router";
import APIRequestMaker, {
  RequestMakerStatus,
  RequestType,
} from "components/APIRequestMaker";
import { ResetPasswordPayload } from "api/models/UsersInterface";
import ChangePasswordForm from "components/forms/ChangePasswordForm";
import FormMessage from "components/forms/FormMessage";
import ActionButtonLink from "components/buttons/ActionButtonLink";

export interface ChangePasswordViewParams {
  token: string;
}

interface Props extends RouteComponentProps<ChangePasswordViewParams> {
  isAdminForm?: boolean;
}

const ChangePassword = ({
  match: {
    params: { token },
  },
  isAdminForm,
}: Props) => (
  <APIRequestMaker<object, ResetPasswordPayload>
    requestType={RequestType.MUTATION}
    request={isAdminForm ? changeAdminPassword : postResetPassword}
  >
    {({ status }, onSubmit) => (
      <CenteredContentViewContainer>
        <CenteredBlockTitle>
          {strings("changePasswordScreen.title")}
        </CenteredBlockTitle>
        {status === RequestMakerStatus.ERROR && (
          <FormMessage>
            {strings("changePasswordScreen.errorMessage")}
          </FormMessage>
        )}
        {status === RequestMakerStatus.SUCCESSFUL && (
          <>
            <FormMessage>
              {strings("changePasswordScreen.successMessage")}
            </FormMessage>
            <ActionButtonLink
              to={isAdminForm ? "/admin/anmelden" : "/"}
              text={strings("changePasswordScreen.successButton")}
            />
          </>
        )}
        {status !== RequestMakerStatus.SUCCESSFUL && (
          <ChangePasswordForm
            token={token}
            status={status}
            onSubmit={onSubmit}
          />
        )}
      </CenteredContentViewContainer>
    )}
  </APIRequestMaker>
);

export default ChangePassword;
