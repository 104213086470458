import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { curry } from "ramda";
import strings from "localisation/strings";

import ListResultType from "api/models/ListResultType";
import EventInterface from "api/models/EventInterface";
import {
  getEvents,
  getCuratorEventsFromBusiness,
  getEventsFilters,
} from "api/events";

import EventsList from "components/lists/eventList/EventsList";
import FilterableList from "components/lists/FilterableList";
import { SearchRequest } from "components/lists/ListRequestTypes";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { NEW } from "constants/strings";
import { listTestId } from "testing/testId";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import ListTableView from "views/ListTableView";
import useLoginState from "hooks/useLoginState";

const postListRequest: SearchRequest<ListResultType<EventInterface>> = ({
  offset,
  limit,
  body,
  q,
}) =>
  curry(getEvents)(
    { q, filterReq: body, from: offset, size: limit },
    undefined,
    {},
  );

const postEventFiltersRequest: FiltersRequestType = ({ filters }) =>
  curry(getEventsFilters)({ filters }, undefined);

const Events = ({ location, history, match }: RouteComponentProps) => {
  const onClick = (id: string) => history.push(`veranstaltungen/${id}`);

  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();

  const eventsListRequestCurator: SearchRequest<ListResultType<
    EventInterface
  >> = ({ offset, limit, body, q }) =>
    curry(getCuratorEventsFromBusiness)(
      { q, filterReq: body, from: offset, size: limit },
      businessId,
      {},
    );

  const renderListView = ({
    items = [],
    onItemClick,
  }: InteractiveListInterface<EventInterface>) => {
    return (
      <EventsList
        emptyIndicatable
        emptyIndicator={strings("listView.noContentFilterable")}
        events={items}
        onItemClick={onItemClick}
      />
    );
  };

  if (getUserHasPrivilege(PrivilegedComponentsIds.VIEW_EVENTS_CURATOR)) {
    if (businessId) {
      return (
        <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_EVENTS} isView>
          <FilterableList<EventInterface>
            onItemClick={onClick}
            location={location}
            history={history}
            match={match}
            pageTitle={strings("eventsScreen.title")}
            filterPageTitle={strings("eventsScreen.filterScreenTitle")}
            sortPageTitle={strings("sortScreen.title")}
            searchPageTitle={strings("searchScreen.title")}
            searchRequest={eventsListRequestCurator}
            postFiltersRequest={postEventFiltersRequest}
            onAddNew={() => onClick(NEW)}
            renderListView={renderListView}
            defaultSort={DEFAULT_SORT}
            redirectOnEmptyResult={true}
            {...listTestId("events")}
          />
        </PrivilegedComponent>
      );
    }
    return (
      <PrivilegedComponent
        id={PrivilegedComponentsIds.VIEW_EVENTS_CURATOR}
        isView
      >
        <ListTableView<ElementInternals> />
      </PrivilegedComponent>
    );
  }

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_EVENTS} isView>
      <FilterableList<EventInterface>
        onItemClick={onClick}
        location={location}
        history={history}
        match={match}
        pageTitle={strings("eventsScreen.title")}
        filterPageTitle={strings("eventsScreen.filterScreenTitle")}
        sortPageTitle={strings("sortScreen.title")}
        searchPageTitle={strings("searchScreen.title")}
        searchRequest={postListRequest}
        postFiltersRequest={postEventFiltersRequest}
        onAddNew={() => onClick(NEW)}
        renderListView={renderListView}
        defaultSort={DEFAULT_SORT}
        redirectOnEmptyResult={true}
        {...listTestId("events")}
      />
    </PrivilegedComponent>
  );
};

export default withRouter(Events);
