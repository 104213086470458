import React, { useEffect } from "react";
import strings from "localisation/strings";
import { PublishRangePicker } from "components/forms/FormFields";
import Checkbox from "components/forms/Checkbox";
import {
  Title,
  CheckboxContainer,
  VerticalLargeSpacer,
} from "components/generic";
import useLoginState from "hooks/useLoginState";

export interface SettingsAreaProps {
  title: string;
  changePublishDateTime: (publishDateTime: Date | null) => void;
  changeUnpublishDateTime: (unpublishDateTime: Date | null) => void;
  enableComments: boolean;
  enableRatings: boolean;
  publishDateTime: Date | null;
  unpublishDateTime: Date | null;
  toggleComments: () => void;
  toggleRatings: () => void;
  publishDateTimeErrors?: string[];
  unpublishDateTimeErrors?: string[];
  enableCommentsErrors?: string[];
  enableRatingsErrors?: string[];
  setBlockNavigation?: (shouldBlockNavigation: boolean) => void;
}

const SettingsArea = ({
  title,
  changePublishDateTime,
  changeUnpublishDateTime,
  enableComments,
  enableRatings,
  publishDateTime,
  unpublishDateTime,
  toggleComments,
  toggleRatings,
  publishDateTimeErrors,
  unpublishDateTimeErrors,
  enableCommentsErrors,
  enableRatingsErrors,
  setBlockNavigation,
}: SettingsAreaProps) => {
  const { hasAdminRights } = useLoginState();

  useEffect(() => {
    if (!publishDateTime && setBlockNavigation) {
      changePublishDateTime(new Date());
      setBlockNavigation(false);
    }
  }, []);

  return (
    <>
      <Title>{title}</Title>

      <PublishRangePicker
        publishDateTime={publishDateTime}
        unpublishDateTime={unpublishDateTime}
        changePublishDateTime={changePublishDateTime}
        changeUnpublishDateTime={changeUnpublishDateTime}
        publishDateTimeErrors={publishDateTimeErrors}
        unpublishDateTimeErrors={unpublishDateTimeErrors}
      />
      {hasAdminRights() && (
        <CheckboxContainer>
          <Checkbox
            name="comments"
            text={strings("detailedScreen.disableComments")}
            checked={!enableComments}
            onChange={toggleComments}
            errors={enableCommentsErrors}
          />
          <VerticalLargeSpacer />
          <Checkbox
            name="ratings"
            text={strings("detailedScreen.disableRatings")}
            checked={!enableRatings}
            onChange={toggleRatings}
            errors={enableRatingsErrors}
          />
        </CheckboxContainer>
      )}
    </>
  );
};

export default SettingsArea;
