enum PrivilegedComponentsIds {
  MENU_BUSINESSES = "MENU_BUSINESSES",
  MENU_BUSINESS_PROFILE = "MENU_BUSINESS_PROFILE",
  MENU_CURATOR_BUSINESS_PROFILE = "MENU_CURATOR_BUSINESS_PROFILE",
  MENU_CURATOR_OFFERS = "MENU_CURATOR_OFFERS",
  MENU_CURATOR_POSTS = "MENU_CURATOR_POSTS",
  MENU_CURATOR_EVENTS = "MENU_CURATOR_EVENTS",
  MENU_CURATOR_DASHBOARD = "MENU_CURATOR_DASHBOARD",
  MENU_CONTENT_CURATOR = "MENU_CONTENT_CURATOR",
  MENU_CHANGE_PASSWORD = "MENU_CHANGE_PASSWORD",
  MENU_CATEGORIES = "MENU_CATEGORIES",
  MENU_CONFIGURATION = "MENU_CONFIGURATION",
  MENU_CONTENT = "MENU_CONTENT",
  MENU_DASHBOARD = "MENU_DASHBOARD",
  MENU_DEFAULT_COMPANY = "MENU_DEFAULT_COMPANY",
  MENU_VENUES = "VENUES",
  MENU_MAPS = "MAPS",
  MENU_EVENTS = "MENU_EVENTS",
  MENU_NEWS = "MENU_NEWS",
  MENU_OFFERS = "MENU_OFFERS",
  MENU_POSTS = "MENU_POSTS",
  MENU_KEEP_LOCAL = "MENU_KEEP_LOCAL",
  MENU_REPRESENTATIVES = "MENU_REPRESENTATIVES",
  MENU_PERSONAL_DATA = "MENU_PERSONAL_DATA",
  MENU_PUBLIC_PORTAL_PAGES = "PUBLIC_PORTAL_PAGES",
  MENU_SETTINGS = "MENU_SETTINGS",
  MENU_STATISTICS = "MENU_STATISTICS",
  MENU_USERS = "MENU_USERS",
  MENU_USERS_PUBLIC = "MENU_USERS_PUBLIC",
  MENU_USERS_REPRESENTATIVES = "MENU_USERS_REPRESENTATIVES",
  MENU_PAYMENT_SETTINGS = "MENU_PAYMENT_SETTINGS",

  VIEW_BUSINESS_PROFILE = "VIEW_BUSINESS_PROFILE",
  VIEW_BUSINESSES_ADMIN = "VIEW_BUSINESSES_ADMIN",
  VIEW_REPRESENTATIVES = "VIEW_REPRESENTATIVES",
  VIEW_BUSINESS_PROFILE_ADMIN = "VIEW_BUSINESS_PROFILE_ADMIN",
  VIEW_BUSINESS_PROFILE_CURATOR = "VIEW_BUSINESS_PROFILE_CURATOR",
  VIEW_REPRESENTATIVES_ADMIN_TABLE = "VIEW_REPRESENTATIVES_ADMIN_TABLE",
  VIEW_DASHBOARD = "VIEW_DASHBOARD",
  VIEW_DASHBOARD_CURATOR = "VIEW_DASHBOARD_CURATOR",
  VIEW_EVENTS = "VIEW_EVENTS",
  VIEW_EVENTS_ADMIN = "VIEW_EVENTS_ADMIN",
  VIEW_EVENTS_CURATOR = "VIEW_EVENTS_CURATOR",
  VIEW_NEWS_ADMIN = "VIEW_NEWS_ADMIN",
  VIEW_OFFERS = "VIEW_OFFERS",
  VIEW_OFFERS_ADMIN = "VIEW_OFFERS_ADMIN",
  VIEW_OFFERS_CURATOR = "VIEW_OFFERS_CURATOR",
  VIEW_POSTS = "VIEW_POSTS",
  VIEW_POSTS_ADMIN = "VIEW_POSTS_ADMIN",
  VIEW_POSTS_CURATOR = "VIEW_POSTS_CURATOR",
  VIEW_CATEGORIES_ADMIN = "VIEW_CATEGORIES_ADMIN",
  VIEW_WIZARD = "VIEW_WIZARD",
  VIEW_CHAT = "VIEW_CHAT",
  VIEW_CHAT_ADMIN = "VIEW_CHAT_ADMIN",
  VIEW_PLAN = "VIEW_PLAN",
  VIEW_PAYMENTS = "VIEW_PAYMENTS",
  VIEW_PAYMENTS_ADMIN = "VIEW_PAYMENTS_ADMIN",
  VIEW_PLAN_ADMIN = "VIEW_PLAN_ADMIN",
  VIEW_KEEP_LOCAL = "VIEW_KEEP_LOCAL",
  VIEW_CONTENT_CURATOR = "VIEW_CONTENT_CURATOR",
  VIEW_MAPS = "VIEW_MAPS",

  EDIT_PAYMENTS = "EDIT_PAYMENT_PLAN",
}
export default PrivilegedComponentsIds;
