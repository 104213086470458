import React, { useEffect, useState, useRef } from "react";
import { curry } from "ramda";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import strings from "localisation/strings";
import { getDateLabel, noop } from "utils";

import {
  deleteReplyToReviewForBusinessProfile,
  deleteReplyToReviewForBusinessProfileAdmin,
  getAdditionalSettingsAdmin,
  getAdditionalSettingsCurator,
  getReviewsForBusinessProfile,
  getReviewsForBusinessProfileAdmin,
  saveReplyToReviewForBusinessProfile,
  saveReplyToReviewForBusinessProfileAdmin,
} from "api/businessProfiles";
import BusinessUserProfileInterface, {
  ImprintInformation,
} from "api/models/BusinessUserProfileInterface";
import PublicationStatuses from "api/models/PublicationStatuses";

import {
  SaveStatusDisplay,
  Separator,
  SettingsArea,
  TextWithIcon,
} from "components/forms/FormFields";
import TitleInput from "components/forms/ValidatedFields/TitleInput";
import CollapsibleImprintArea from "components/forms/BusinessProfile/CollapsibleImprintArea";
import BusinessHours from "components/forms/FormFields/BusinessHours";
import QRCodeDownloadable from "components/forms/QRCodeDownloadable";
import DescriptionBlock from "components/forms/DescriptionBlock";
import { CheckboxContainer, Title } from "components/generic";
import { ContentActionArea } from "components/helpers/formHelpers";
import ReviewsAndRatings from "components/review/ReviewsAndRatings";
import {
  defaultBusinessUserProfileFields,
  getBusinessUserProfilePayload,
  setBusinessUserProfileFields,
} from "components/helpers/formHelpers/businessUserProfile";
import useFormFields from "hooks/useFormFields";
import Checkbox from "components/forms/Checkbox";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";

import websiteIcon from "assets/icons/card.svg";
import emailIcon from "assets/icons/mail.svg";
import phoneIcon from "assets/icons/contact.svg";
import AddressPicker from "components/forms/FormFields/AddressAutocomplete/AddressPicker";
import zooIcon from "assets/icons/zoo.svg";
import locationIcon from "assets/icons/location.svg";
import date from "common/assets/icons/date.svg";
import useLoginState from "hooks/useLoginState";
import { SearchRequest } from "components/lists/ListRequestTypes";
import ListResultType from "api/models/ListResultType";
import BusinessUserTImeline from "components/forms/BusinessProfile/BusinessUserTImeline";
import { getBusinessesStream } from "api/search";
import SearchInterface from "api/models/SearchInterface";
import { History } from "history";
import PictureGallery from "components/forms/FormFields/PictureGallery";
import ImageUploadType from "api/models/ImageUploadType";
import { withBackButton } from "components/generic/ActionArea";
import useMutation from "common/hooks/useMutation";
import { Link, useLocation } from "react-router-dom";
import { COPY } from "constants/strings";
import HashtagsField from "components/forms/HashtagsField";
import TagSelectionField from "components/forms/TagSelection/TagSelectionField";
import EntityTypes from "api/models/EntityTypes";
import { useTagRaceConditionCatcher } from "hooks/useTagRaceConditionCatcher";
import { ciwLinkPrefix } from "core/constants";
import useQuery from "common/hooks/useQuery";
import MapsSelectList from "components/forms/FormFields/MapsSelectList";
import { getCuratedMaps } from "api/users";

export interface BusinessProfileFormProps {
  isNew: boolean;
  createNew: any;
  createDraft: any;
  success: (message: string, response: BusinessUserProfileInterface) => void;
  result?: BusinessUserProfileInterface;
  imprint?: ImprintInformation;
  changedValues: Set<string>;
  setBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  businessUserProfile?: BusinessUserProfileInterface;
  isNewCopy?: boolean; // special case when we add new entity from another open BUP
  isStickyFooterEnabled?: boolean;
  history: History;
  businessId?: string;
  isBackButtonEnabled?: boolean;
  publishButtonLabel?: string;
  suppressButtonConfirmation?: boolean;
  onBackButtonClicked?: () => void;
  expandImprint?: true;
}

const InputsBox = styled.div`
  padding-left: ${({ theme }) => theme.margin.large};
  @media ${breakpoints.phoneMax} {
    padding-left: 0;
  }
`;

// The max-height has aspect ratio scaling applied to keep margins between components same as before image box rescaling
const ImageBox = styled.div`
  max-height: ${({ theme }) =>
    500 + 500 * ((100 - theme.image.aspectRatio) / 100)}px;

  /*
    IE10+ CSS to fix an IE bug that causes flex box overflow.
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 0 auto;
  }

  @media ${breakpoints.tabletMax} {
    max-height: inherit;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 36px;
  color: ${({ theme }) => theme.color.foreground.action};
  text-decoration: none;
`;

const BusinessProfileFormStatusDisplay = ({
  result,
}: {
  result?: BusinessUserProfileInterface;
}) =>
  (result && result.modifiedDateTime && result.profileState && (
    <SaveStatusDisplay
      status={result!.profileState}
      modifiedDate={result!.modifiedDateTime || null}
    />
  )) ||
  null;

const BusinessProfileFormActionArea = withBackButton(ContentActionArea);

const BusinessProfileForm = ({
  isNew,
  isNewCopy,
  createNew,
  createDraft,
  success,
  result,
  imprint,
  changedValues,
  setBlockNavigation,
  history,
  businessId,
  publishButtonLabel,
  suppressButtonConfirmation,
  isBackButtonEnabled = false,
  onBackButtonClicked = noop,
  expandImprint,
  isStickyFooterEnabled = true,
}: BusinessProfileFormProps) => {
  const { hasAdminRights, hasCuratorRights } = useLoginState();
  const adminRights = hasAdminRights();
  const curatorRights = hasCuratorRights();

  const [oldContent, setOldContent] = useState<BusinessUserProfileInterface>();
  const [isSavingBlocked, setIsSavingBlocked] = useState<boolean>(false);

  const canAssignMap = () => adminRights || curatorRights;

  const isImprintPrefillNeeded =
    (!adminRights || businessId) && imprint && isNew;

  const contentInWebUrl = result?.webUrlSlug
    ? ciwLinkPrefix + result.webUrlSlug
    : "";

  let id;
  let enableComments;
  let enableRatings;
  const { result: additionalSettingsResult } = useQuery({
    request: curry(
      adminRights ? getAdditionalSettingsAdmin : getAdditionalSettingsCurator,
    )(businessId || result?.businessId || ""),
  });
  const getCuratedMapsResult = useQuery({
    request: getCuratedMaps,
  });

  if (result) {
    if (!isNew) {
      id = result.id;
      enableComments = result.enableComments;
      enableRatings = result.enableRatings;
    }
  }
  const mapAllowances = adminRights
    ? additionalSettingsResult?.mapAllowances
    : additionalSettingsResult?.mapAllowances.filter(allowedMap =>
        getCuratedMapsResult.result?.some(
          curatedMap => curatedMap.mapId === allowedMap.mapId,
        ),
      );

  let getReviewsRequest;
  let saveReviewReplyRequest;
  let deleteReviewReplyRequest;
  if (id) {
    if (adminRights) {
      getReviewsRequest = curry(getReviewsForBusinessProfileAdmin)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForBusinessProfileAdmin)(
        id,
      );
      deleteReviewReplyRequest = curry(
        deleteReplyToReviewForBusinessProfileAdmin,
      )(id);
    } else {
      getReviewsRequest = curry(getReviewsForBusinessProfile)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForBusinessProfile)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForBusinessProfile)(
        id,
      );
    }
  }

  const businessProfileListRequest: SearchRequest<ListResultType<
    SearchInterface
  >> = ({ offset, limit }) =>
    curry(getBusinessesStream)(
      result ? result.businessId : "",
      { from: offset, size: limit },
      undefined,
    );

  const {
    isValid,
    onChange,
    getErrors,
    parseErrors,
    clearErrors,
    values,
    updateValues,
    getTextAreaFieldProps,
    getTextFieldProps,
    getBusinessHoursProps,
    getTagFieldProps,
    getImageListProps,
    getImprintProps,
    getSettingsFieldProps,
    getChildErrors,
    getContactInformationAddressFieldProps,
    getPinnedLocationAddressDescriptionFieldProps,
    getCheckboxFieldProps,
    getHashtagsFieldProps,
  } = useFormFields({
    handleGenericErrors: true,
    ...defaultBusinessUserProfileFields,
    setBlockNavigation,
    changedValues,
    translationScope: "businessUserProfileScreen.fields",
  });

  const updateValuesRef = useRef(updateValues);

  useEffect(() => {
    if (result) {
      if (!isNew) {
        setOldContent(result);
      }
      const updatedFormFields = setBusinessUserProfileFields(result);
      updateValuesRef.current(updatedFormFields);
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    // imprint only set for adding new entities as BA or IA in BA mode
    if (isImprintPrefillNeeded) {
      onChange("imprintInformation", imprint);
      setBlockNavigation(false);
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imprint]);

  const location = useLocation();
  const isCopied = location.pathname.endsWith(COPY);

  const contactInformationErrors = getChildErrors("contactInformation");

  const { makeRequest } = useMutation(createNew);
  const { filterFieldErrors, onRequestFinished } = useTagRaceConditionCatcher();

  useEffect(() => {
    if (isCopied || !imprint) return;

    // this makes a publish request on component mount to get all required fields
    makeRequest({
      ...values,
      pictures: [],
      tagIds: [],
      profileState: isNew
        ? PublicationStatuses.prePublished
        : PublicationStatuses.published,
      imprintInformation: isImprintPrefillNeeded
        ? imprint
        : values.imprintInformation,
      contactInformation: {},
    }).then(e => {
      if (e && e.error) {
        parseErrors({
          ...e.error,
          detail: undefined,
          fieldsErrors: filterFieldErrors(e.error),
        });
      }
    });
  }, [location, imprint, isNew]);

  return (
    <>
      <BusinessProfileFormStatusDisplay result={result} />
      <TitleInput {...getTextAreaFieldProps("name")} focusOnOpen />
      <TagSelectionField
        {...getTagFieldProps("tags")}
        fieldErrors={getErrors("tagIds")}
        type={EntityTypes.general}
        setIsSavingBlocked={setIsSavingBlocked}
        isBusinessProfile
        isNew={isNew}
        isCopied={isCopied}
        businessId={businessId}
        onTagsLoaded={onRequestFinished}
      />
      <HashtagsField {...getHashtagsFieldProps("hashtags")} />
      <ImageBox>
        <PictureGallery
          {...getImageListProps()}
          container={ImageUploadType.BUSINESS}
          smallPictureContainer={ImageUploadType.BUSINESS}
          height="330px"
          parseErrors={parseErrors}
          updateKey={result ? result.modifiedDateTime : undefined}
          hasSmallImage={true}
        />
        <QRCodeDownloadable value={values.qrCode} />
      </ImageBox>

      <InputsBox>
        <BusinessHours {...getBusinessHoursProps()} />
        <TextWithIcon
          {...getTextFieldProps("website")}
          errors={contactInformationErrors.website}
          optional
          icon={websiteIcon}
        />
        <TextWithIcon
          {...getTextFieldProps("venue", "contactInformation.venue")}
          placeholder={strings("detailedScreen.location.venue")}
          errors={contactInformationErrors.venue}
          optional
          icon={zooIcon}
        />
        {canAssignMap() && mapAllowances && mapAllowances.length > 0 && (
          <InlineInputGroup
            wrapText
            placeholder={strings("detailedScreen.showOnMap")}
            icon={locationIcon}
          >
            <MapsSelectList
              allowedMaps={mapAllowances}
              values={result?.mapAssignments || []}
              entityId={id}
              fieldName="mapAssignments"
              style=".mapDropdown__control {
                padding: 0 10px 0 5px;
              }"
              onChange={onChange}
            />
          </InlineInputGroup>
        )}
        <AddressPicker
          autocompleteProps={getContactInformationAddressFieldProps()}
          pinnedDescriptionFieldProps={getPinnedLocationAddressDescriptionFieldProps(
            "contactInformationAddress",
            "contactInformation",
          )}
          location={result ? result.contactInformation : undefined}
          showPinLocationOnMapSuggestion={values.mapAssignments.length > 0}
        />
        <TextWithIcon
          {...getTextFieldProps("email", "contactInformation.email")}
          errors={contactInformationErrors.email}
          icon={emailIcon}
        />
        <TextWithIcon
          {...getTextFieldProps("phone", "contactInformation.phone")}
          errors={contactInformationErrors.phone}
          icon={phoneIcon}
        />
        <DescriptionBlock
          label={strings(
            "businessUserProfileScreen.fields.businessDescription.label",
          )}
          {...getTextAreaFieldProps("businessDescription")}
          testId="business-desc"
          richText
        />
        <DescriptionBlock
          label={strings(
            "businessUserProfileScreen.fields.serviceDescription.label",
          )}
          {...getTextAreaFieldProps("serviceDescription")}
          richText
        />
        <CollapsibleImprintArea
          shouldExpandFirst={expandImprint}
          {...getImprintProps()}
        />
      </InputsBox>

      {id && result && result.businessId && (
        <>
          <Separator />
          <BusinessUserTImeline
            id={id}
            getRequest={businessProfileListRequest}
            history={history}
            adminRights={adminRights}
          />
        </>
      )}
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_BUSINESSES_ADMIN}>
        <Separator />
        <Title>{strings("newsScreen.fields.publishAs.title")}</Title>
        <InlineInputGroup icon={date} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("topicOfTheWeek")}
            dateLabel={getDateLabel(result)}
          />
        </InlineInputGroup>
      </PrivilegedComponent>
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_BUSINESSES_ADMIN}>
        <Separator />
        <Title>{strings("businessUserProfileScreen.fields.misc.title")}</Title>
        <CheckboxContainer>
          <Checkbox {...getCheckboxFieldProps("pushToTopOfStream")} />
        </CheckboxContainer>
      </PrivilegedComponent>
      <Separator />
      {id &&
        getReviewsRequest &&
        saveReviewReplyRequest &&
        deleteReviewReplyRequest && (
          <ReviewsAndRatings
            id={id}
            getRequest={getReviewsRequest}
            saveReviewReply={saveReviewReplyRequest}
            deleteReviewReply={deleteReviewReplyRequest}
            enableComments={enableComments}
            enableRatings={enableRatings}
          />
        )}
      <SettingsArea {...getSettingsFieldProps()} />
      {result?.webUrlSlug && (
        <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_BUSINESSES_ADMIN}>
          <Separator />
          <Title>
            {strings("businessUserProfileScreen.fields.webUrlField.title")}
          </Title>
          <StyledLink
            target={"_blank"}
            to={{
              pathname: contentInWebUrl,
            }}
          >
            {contentInWebUrl}
          </StyledLink>
        </PrivilegedComponent>
      )}
      <BusinessProfileFormStatusDisplay result={result} />
      <BusinessProfileFormActionArea
        isSticky={isStickyFooterEnabled}
        isSubmitDisabled={!isValid}
        isBackButtonEnabled={isBackButtonEnabled}
        onBackButtonClicked={onBackButtonClicked}
        values={values}
        isNew={isNew}
        parseErrors={parseErrors}
        clearErrors={clearErrors}
        oldContent={oldContent}
        setShouldBlockNavigation={setBlockNavigation}
        successCallback={success}
        content={result}
        createDraftItem={createDraft}
        createNewItem={createNew}
        getContentPayload={getBusinessUserProfilePayload}
        targetPublicationStatus={
          adminRights || curatorRights
            ? PublicationStatuses.published
            : PublicationStatuses.prePublished
        }
        publishButtonLabel={
          publishButtonLabel || adminRights || curatorRights
            ? undefined
            : strings("buttons.sendToReview")
        }
        suppressButtonConfirmation={suppressButtonConfirmation}
        isSavingBlocked={isSavingBlocked}
      />
    </>
  );
};

export default BusinessProfileForm;
