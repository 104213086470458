import React from "react";
import styled from "style/styled-components";
import { Link } from "react-router-dom";
import { MarginlessBlockTitle, ViewContainer } from "components/generic";
import strings from "localisation/strings";

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.foreground.action};
  font-size: ${({ theme }) => theme.font.size.large};
  margin-top: ${({ theme }) => theme.margin.large};
`;

const NotFound = () => (
  <ViewContainer>
    <MarginlessBlockTitle>
      {strings("generic.pageNotFound")}
    </MarginlessBlockTitle>
    <StyledLink to="/" onClick={() => (window.location.href = "/")}>
      {strings("generic.goBack")}
    </StyledLink>
  </ViewContainer>
);

export default NotFound;
