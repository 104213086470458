import React from "react";
import SimpleText from "components/generic/SimpleText";
import styled from "style/styled-components";

const Message = styled(SimpleText)`
  display: block;
  text-align: center;
  padding-top: ${({ theme }) => theme.margin.large};
`;

const NoContent = ({ message }: { message: string }) => {
  return <Message>{message}</Message>;
};

export default NoContent;
