import React from "react";
import styled from "style/styled-components";
import useChatState from "hooks/useChatState";
import { ReactComponent as BackIcon } from "assets/icons/arrow-small.svg";
import breakpoints from "style/breakpoints";
import Avatar from "components/generic/Avatar";
import deletedEndUserAvatar from "common/assets/icons/deletedEndUserAvatar.svg";
import endUserAvatar from "common/assets/icons/endUserAvatar.svg";
import botAvatar from "common/assets/icons/botAvatar.svg";
import { useHistory } from "react-router";
import strings from "localisation/strings";
import { EllipticText } from "components/generic";

const ConversationViewHeaderContainer = styled.header`
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: ${({ theme }) => theme.margin.large};
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: 900;
  line-height: 1;
  border-bottom: 1px solid ${({ theme }) => theme.color.chat.bg.sidebar};

  @media ${breakpoints.phoneMax} {
    height: ${({ theme }) => theme.globalComponents.headerHeight}px;
    background: ${({ theme }) => theme.color.foreground.secondary};
    color: ${({ theme }) => theme.color.font.onDarkBackground};
  }
`;

const GoBackButton = styled(BackIcon)`
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
  fill: ${({ theme }) => theme.color.foreground.antiPrimary};
  margin-right: ${({ theme }) => theme.margin.medium};
  cursor: pointer;
  display: none;

  @media ${breakpoints.phoneMax} {
    display: inline-block;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.margin.large};

  @media ${breakpoints.phoneMax} {
    display: none;
  }
`;

const ConversationTitle = EllipticText;

interface ConversationHeaderProps {
  conversationId: string;
}
const ConversationHeader = ({ conversationId }: ConversationHeaderProps) => {
  const { conversationsById } = useChatState();
  const { goBack } = useHistory();
  const { title, image, isSystem, ownerDeleted } =
    conversationsById[conversationId] || {};
  const userAvatar = ownerDeleted ? deletedEndUserAvatar : endUserAvatar;

  return (
    <ConversationViewHeaderContainer>
      <GoBackButton onClick={goBack} />
      <AvatarContainer>
        {image ? (
          <Avatar size={40} picture={image} />
        ) : (
          <img
            width={40}
            height={40}
            src={isSystem ? botAvatar : userAvatar}
            alt={strings("chat.avatarAlt")}
          />
        )}
      </AvatarContainer>
      <ConversationTitle>{title}</ConversationTitle>
    </ConversationViewHeaderContainer>
  );
};

export default ConversationHeader;
