import React from "react";
import strings from "localisation/strings";
import Arrow from "components/generic/Arrow";
import { PaddedSpacer } from "components/generic/Spacer";
import Checkbox from "components/forms/Checkbox";
import { ArrowContainer } from "components/forms/CategoryDropdown/components";
import TagInterface from "api/models/TagInterface";
import theme from "style/theme";
import { ReactComponent as TristateIcon } from "assets/icons/tristate.svg";

interface Props {
  option: TagInterface;
  toggleCheckbox: (id: string) => void;
  toggleOpen: (id: string) => void;
  arrowDirection: string;
  isChecked: boolean;
  partiallySelected: boolean;
}

const DropdownCheckbox = (props: Props) => {
  const {
    option,
    toggleCheckbox,
    toggleOpen,
    arrowDirection,
    isChecked,
    partiallySelected,
  } = props;
  const { id, shortName, name, tagSuccessors } = option;

  return (
    <PaddedSpacer padding={theme.margin.medium}>
      <Checkbox
        name={id}
        text={shortName || name || strings("dropdownSelect.noName")}
        checked={isChecked}
        onChange={() => toggleCheckbox(id)}
        checkComponent={partiallySelected ? <TristateIcon /> : undefined}
      >
        <ArrowContainer onClick={() => toggleOpen(id)}>
          {tagSuccessors && tagSuccessors.length ? (
            <Arrow direction={arrowDirection} size="medium" />
          ) : null}
        </ArrowContainer>
      </Checkbox>
    </PaddedSpacer>
  );
};

export default DropdownCheckbox;
