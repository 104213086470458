import React, { useEffect, HTMLAttributes } from "react";
import styled from "style/styled-components";
import {
  ContentScrollContext,
  ContentScrollContextProps,
} from "components/helpers/ContentScrollPreventer";

interface ActiveBackdropInterface {
  isActive: boolean;
  transparent?: boolean;
}
interface BackdropProps
  extends HTMLAttributes<HTMLDivElement>,
    ActiveBackdropInterface {
  className?: string;
}

const StyledBackdrop = styled.div<Partial<ActiveBackdropInterface>>`
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: ${({ isActive }) => (isActive ? "100%" : "0")};
  bottom: ${({ isActive }) => (isActive ? "0" : "100%")};
  background-color: ${({ theme, transparent }) =>
    transparent ? "transparent" : theme.color.foreground.primary};
  opacity: ${({ isActive }) => (isActive ? 0.4 : 0)};
  transition: opacity 0.2s ease-in-out;
  z-index: ${({ theme }) => theme.layers.modalBackdrop};
`;

const Backdrop = ({
  isActive,
  enableScrollPrevention,
  disableScrollPrevention,
  ...props
}: BackdropProps & ContentScrollContextProps) => {
  useEffect(() => {
    if (isActive) {
      enableScrollPrevention();
    } else {
      disableScrollPrevention();
    }
    return () => {
      disableScrollPrevention();
    };
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return <StyledBackdrop isActive={isActive} {...props} />;
};

const WrappedBackdrop = (props: BackdropProps) => (
  <ContentScrollContext.Consumer>
    {context => <Backdrop {...props} {...context} />}
  </ContentScrollContext.Consumer>
);

export default WrappedBackdrop;
