import styled from "style/styled-components";

const FormSubtitle = styled.div`
  text-align: center;
  align-self: center;
  margin-bottom: ${({ theme }) => theme.margin.large};
  max-width: 100%;
`;

export default FormSubtitle;
