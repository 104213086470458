import React, { Component } from "react";
import styled from "style/styled-components";
import { PointerContainer } from "components/generic";
import strings from "localisation/strings";

interface DateInputProps {
  inputPlaceholder: string;
  onClick?: (event: any) => void;
  value?: string;

  type?: string;
  longFormat?: boolean;
}

const Date = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.color.font.onLightBackground};
`;

const Placeholder = styled.span`
  color: ${({ theme }) => theme.color.foreground.tertiary};
  cursor: pointer;
`;

const getDateTime = (value?: string) => {
  // value field is provided in "DD.MM.YYYY HH.mm" format by react-datepicker
  // splitting date from time for display purposes
  const dateParts = value ? value.split(" ") : undefined;
  return {
    date: dateParts ? dateParts[0] : undefined,
    time: dateParts ? dateParts[1] : undefined,
  };
};

const getValue = (value?: string, type?: string, longFormat?: boolean) => {
  const { date, time } = getDateTime(value);

  const translation = `detailedScreen.dateTime.${type}${
    longFormat ? "Long" : ""
  }`;

  return strings(translation, { date, time });
};

// react-datepicker cannot use stateless component properly
class DateField extends Component<DateInputProps> {
  render() {
    const { value, onClick, inputPlaceholder, type, longFormat } = this.props;
    const hasDate = Boolean(value && value.length > 0);

    if (inputPlaceholder && !hasDate) {
      return <Placeholder onClick={onClick}>{inputPlaceholder}</Placeholder>;
    }

    return (
      <PointerContainer onClick={onClick}>
        <Date>{getValue(value, type, longFormat)}</Date>
      </PointerContainer>
    );
  }
}

export default DateField;
