import React from "react";
import RepresentativeUserActivatedView from "views/RepresentativeUserActivatedView";

const Component = () =>
  React.createElement(RepresentativeUserActivatedView, {
    isAdmin: true,
  });

Component.displayName = "AdminRepresentativeUserActivatedView";

export default Component;
