import React, { useState, useEffect } from "react";
import { filter } from "ramda";
import { moment } from "localisation/i18n";
import styled from "style/styled-components";
import globalTheme from "style/theme";
import {
  VictoryChart,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryArea,
  VictoryScatter,
  VictoryTooltip,
  VictoryAxis,
} from "victory";
import { Data } from "api/models/DashboardInterface";
import StatisticType from "components/dashboard/StatisticType";
import strings from "localisation/strings";

export const Positioner = styled.div`
  margin: ${({ theme }) => theme.margin.large};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  data: Data[];
  statisticType: string;
}

const AreaChart = ({ data, statisticType }: Props) => {
  const [chartWidth, setChartWidth] = useState<number>(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setChartWidth(window.innerWidth));
  });

  const isNotZero = (datum: Data): boolean => datum.value !== 0;
  const isAllValueZero = filter(isNotZero, data).length === 0;

  const isOnlyOneDaySelected = data.length === 1;

  const valueName = getValueName(statisticType);

  return (
    <VictoryChart
      domain={isAllValueZero ? { y: [0, 4] } : undefined}
      width={chartWidth}
      theme={VictoryTheme.material}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => `${datum.date}\n${valueName}: ${datum.value}`}
          labelComponent={
            <VictoryTooltip
              style={{ fill: globalTheme.color.foreground.antiPrimary }}
              flyoutStyle={{
                fill: globalTheme.color.foreground.darkAccent,
                stroke: globalTheme.color.foreground.darkAccent,
              }}
            />
          }
          voronoiBlacklist={["area"]}
        />
      }
    >
      <VictoryArea
        x="date"
        y="value"
        data={getFormattedChartData(data)}
        name="area"
        style={{
          data: {
            stroke: globalTheme.color.foreground.action,
            strokeWidth: 5,
            fill: "#96BFF4",
            width: 20,
          },
        }}
      />
      <VictoryScatter
        x="date"
        y="value"
        data={getFormattedChartData(data)}
        size={({ active }) => (active ? 8 : isOnlyOneDaySelected ? 8 : 0)}
        style={{
          data: { fill: globalTheme.color.foreground.action },
        }}
      />
      <VictoryAxis fixLabelOverlap={true} />
      <VictoryAxis dependentAxis={true} />
    </VictoryChart>
  );
};

const getFormattedChartData = (data: Data[]): Data[] => {
  const formattedData: Data[] = [];

  data.forEach(datum => {
    formattedData.push({
      date: moment(datum.date).format("DD.MM.YYYY"),
      value: datum.value,
    });
  });

  return formattedData;
};

const getValueName = (statisticType: string): string => {
  switch (statisticType) {
    case StatisticType.totalClicks:
      return strings("dashboardScreen.statisticType.totalClicks");
    case StatisticType.searchClicks:
      return strings("dashboardScreen.statisticType.clicksFromSearch");
    case StatisticType.objectsShared:
      return strings("dashboardScreen.statisticType.objectsShared");
    case StatisticType.favoritesAdded:
      return strings("dashboardScreen.statisticType.addedToFavourites");
    case StatisticType.likesAdded:
      return strings("dashboardScreen.statisticType.addedToLikes");
    case StatisticType.ratedOrReviewed:
      return strings("dashboardScreen.statisticType.ratedOrReviewed");
    case StatisticType.calendarAdded:
      return strings("dashboardScreen.statisticType.addedToCalendar");
    case StatisticType.offersRedeemed:
      return strings("dashboardScreen.statisticType.redeemed");
    default:
      return strings("dashboardScreen.statisticType.default");
  }
};

export default AreaChart;
