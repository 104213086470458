import { Container } from "unstated";
import {
  Conversation,
  ChatState,
  MessageUser,
  Message,
  ChatConnectionStatus,
  TalkerType,
} from "components/chat/types";
import { difference } from "ramda";
import { formatLastMessage, formatSender } from "components/chat/utils";

export default class ChatStateContainer extends Container<ChatState> {
  state: ChatState = {
    /**
     * Indicates chat being actively used
     * General use case is connecting to chat socket while active and disconnecting when inactive
     */
    active: false,
    status: ChatConnectionStatus.Disconnected,
    unreadMessagesCount: 0,
    conversationsIds: [],
    conversationsById: {},
    messagesByConversationId: {},
    refreshKey: Date.now().toString(),
    user: {
      _id: "",
      name: undefined,
      avatar: undefined,
      talkerType: TalkerType.BUSINESS,
    },
  };

  setDisconnected() {
    return this.setState(
      (prevState): ChatState => ({
        ...prevState,
        status: ChatConnectionStatus.Disconnected,
      }),
    );
  }

  setConnecting() {
    return this.setState(
      (previousState): ChatState => ({
        ...previousState,
        status: ChatConnectionStatus.Connecting,
      }),
    );
  }

  setConnected() {
    return this.setState(
      (previousState): ChatState => ({
        ...previousState,
        status: ChatConnectionStatus.Connected,
      }),
    );
  }

  setActive(active: boolean) {
    return this.setState(
      (previousState): ChatState => ({
        ...previousState,
        active,
      }),
    );
  }

  setUnreadMessagesCount(count: number) {
    return this.setState(
      (previousState): ChatState => ({
        ...previousState,
        unreadMessagesCount: count,
      }),
    );
  }

  setConversations(conversations: Conversation[]) {
    return this.setState(
      (previousState): ChatState => {
        const {
          conversationsIds,
          conversationsById,
          messagesByConversationId,
        } = conversations.reduce(
          (state, conversation) => ({
            conversationsIds: [...state.conversationsIds, conversation.id],
            conversationsById: {
              ...state.conversationsById,
              [conversation.id]: {
                ...state.conversationsById[conversation.id],
                ...conversation,
              },
            },
            messagesByConversationId: {
              ...state.messagesByConversationId,
              [conversation.id]:
                state.messagesByConversationId[conversation.id] || [],
            },
          }),
          {
            conversationsIds: [] as string[],
            conversationsById: {} as Record<string, Conversation>,
            messagesByConversationId: previousState.messagesByConversationId as Record<
              string,
              Message[]
            >,
          },
        );

        return {
          ...previousState,
          conversationsIds,
          conversationsById,
          messagesByConversationId,
        };
      },
    );
  }

  updateMessage(
    conversationId: string,
    messageId: string,
    updateBody: Partial<Message>,
  ) {
    return this.setState(
      (prevState): ChatState => {
        const updatedMessageIndex = prevState.messagesByConversationId[
          conversationId
        ].findIndex(({ _id }) => _id === messageId);
        if (updatedMessageIndex < 0) {
          return prevState;
        }

        const currentMessageBody =
          prevState.messagesByConversationId[conversationId][
            updatedMessageIndex
          ];
        const updatedMessageBody = {
          ...currentMessageBody,
          ...updateBody,
        };

        prevState.messagesByConversationId[conversationId][
          updatedMessageIndex
        ] = updatedMessageBody;

        return {
          ...prevState,
          messagesByConversationId: prevState.messagesByConversationId,
        };
      },
    );
  }

  updateConversation(id: string, conversation: Partial<Conversation>) {
    return this.setState(
      (prevState): ChatState => {
        const newUnreadMessagesReceived =
          prevState.conversationsById[id] && conversation.unreadCount
            ? conversation.unreadCount >
              prevState.conversationsById[id].unreadCount
            : false;
        const isNewConversation = prevState.conversationsIds.indexOf(id) < 0;

        return {
          ...prevState,
          // Push conversation to top if new messages received or if it's a new conversation
          conversationsIds:
            newUnreadMessagesReceived || isNewConversation
              ? [
                  id,
                  ...prevState.conversationsIds.filter(
                    conversationId => conversationId !== id,
                  ),
                ]
              : prevState.conversationsIds,
          conversationsById: {
            ...prevState.conversationsById,
            [id]: {
              ...prevState.conversationsById[id],
              ...conversation,
            },
          },
          // Initialise or use existing messages
          messagesByConversationId: {
            ...prevState.messagesByConversationId,
            [id]: prevState.messagesByConversationId[id] || [],
          },
        };
      },
    );
  }

  /**
   * Used for triggering list updates when deep update to a message inside a list is made
   */
  updateRefreshKey() {
    return this.setState(prevState => ({
      ...prevState,
      refreshKey: Date.now().toString(),
    }));
  }

  reset() {
    return this.setState(
      (prevState): ChatState => ({
        ...prevState,
        conversationsIds: [],
        conversationsById: {},
        messagesByConversationId: {},
      }),
    );
  }

  resetConversationMessages(conversationId: string) {
    return this.setState(
      (prevState: ChatState): ChatState => ({
        ...prevState,
        messagesByConversationId: {
          ...prevState.messagesByConversationId,
          [conversationId]: [],
        },
      }),
    );
  }

  pushConversationToTop(conversationId: string) {
    return this.setState(
      (prevState): ChatState => ({
        ...prevState,
        conversationsIds: [
          conversationId,
          ...prevState.conversationsIds.filter(id => id !== conversationId),
        ],
      }),
    );
  }

  setMessages(conversationId: string, messages: Message[]) {
    return this.setState(
      (previousState): ChatState => ({
        ...previousState,
        messagesByConversationId: {
          ...previousState.messagesByConversationId,
          [conversationId]: messages,
        },
      }),
    );
  }

  prependMessages(conversationId: string, messages: Message[]) {
    return this.setState(
      (previousState): ChatState => {
        const messagesToPrepend = difference(
          messages,
          previousState.messagesByConversationId[conversationId],
        );
        return {
          ...previousState,
          messagesByConversationId: {
            ...previousState.messagesByConversationId,
            [conversationId]: [
              ...messagesToPrepend,
              ...previousState.messagesByConversationId[conversationId],
            ],
          },
        };
      },
    );
  }

  appendMessages(conversationId: string, messages: Message[]) {
    const { user: sender, text } = messages[messages.length - 1];

    return this.setState(
      (previousState): ChatState => {
        const messagesToAppend = difference(
          messages,
          previousState.messagesByConversationId[conversationId],
        );
        return {
          ...previousState,
          messagesByConversationId: {
            ...previousState.messagesByConversationId,
            [conversationId]: [
              ...previousState.messagesByConversationId[conversationId],
              ...messagesToAppend,
            ],
          },
          conversationsById: {
            ...previousState.conversationsById,
            [conversationId]: {
              ...previousState.conversationsById[conversationId],
              lastMessage: formatLastMessage(
                formatSender(
                  sender.name as string,
                  sender._id === previousState.user._id,
                ),
                text,
              ),
            },
          },
        };
      },
    );
  }

  setUser(user: MessageUser) {
    return this.setState(
      (previousState): ChatState => ({
        ...previousState,
        user,
      }),
    );
  }
}
