import { useEffect } from "react";
import strings from "localisation/strings";
import useQuery from "common/hooks/useQuery";
import { getBusinessPlan } from "api/businessProfiles";
import QueryStatus from "common/api/models/QueryStatus";
import { PlanTypes, BusinessPlanHistory } from "api/models/Plan";
import useNotificationState from "hooks/useNotification";

type BusinessSubscriptionPlanPaymentsValidationResult =
  | "PAYMENT_PLAN_NOT_NEEDED"
  | "SUBSCRIPTION_PLAN_MISSING"
  | "OK"
  | "UNKNOWN";

const getBusinessSubscriptionPlanPaymentsValidationResult = (
  result: BusinessPlanHistory | undefined,
  requestStatus: QueryStatus,
): BusinessSubscriptionPlanPaymentsValidationResult => {
  if (!result || requestStatus !== QueryStatus.SUCCESSFUL) {
    return "UNKNOWN";
  }

  if (!result.currentPlan) {
    return "SUBSCRIPTION_PLAN_MISSING";
  }

  if (result.currentPlan.planType === PlanTypes.nonprofit) {
    return "PAYMENT_PLAN_NOT_NEEDED";
  }

  return "OK";
};

const useBusinessSubscriptionPlanPaymentsValidation = () => {
  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const { result, status } = useQuery({
    request: getBusinessPlan,
    compare: [],
  });

  const businessPlanStatus = getBusinessSubscriptionPlanPaymentsValidationResult(
    result,
    status,
  );
  useEffect(() => {
    if (businessPlanStatus === "SUBSCRIPTION_PLAN_MISSING") {
      addErrorNotification(
        strings("businessSettings.payment.paymentPlanNotChosenNotice"),
      );
    }

    if (businessPlanStatus === "PAYMENT_PLAN_NOT_NEEDED") {
      addSuccessNotification(
        strings("businessSettings.payment.paymentDataNotNeededNotice"),
      );
    }
  }, [businessPlanStatus]);

  return {
    isBusinessPlanValidated: status === QueryStatus.SUCCESSFUL,
    businessPlanValidationStatus: businessPlanStatus,
  };
};

export default useBusinessSubscriptionPlanPaymentsValidation;
