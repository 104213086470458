import React from "react";
import strings from "localisation/strings";
import ChatPlaceholder from "components/chat/ChatPlaceholder";

const ChatMessagesFailedToLoadPlaceholder = () => {
  return (
    <ChatPlaceholder message={strings("chat.messagesLoadFailedNotification")} />
  );
};

export default ChatMessagesFailedToLoadPlaceholder;
