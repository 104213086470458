import React, { Component } from "react";
import moment from "moment";
import styled from "style/styled-components";
import DateInput from "components/forms/DateInput";
import DateTimePicker from "components/inputs/DateTimePicker";

const Container = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  padding-right: ${({ theme }) => theme.margin.medium};
  > :first-child {
    margin-right: ${({ theme }) => theme.margin.xsmall};
  }
  .react-datepicker__header__dropdown--select {
    margin: 8px 0;
  }
`;

interface Props {
  start?: Date | null;
  startPlaceholder: string;
  startChange: (date?: Date) => void;
  end?: Date | null;
  endPlaceholder: string;
  endChange: (date?: Date) => void;
  showMonthYearDropDowns?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

interface State {
  openStart: boolean;
  openEnd: boolean;
}

class InlineDateRange extends Component<Props, State> {
  state = {
    openStart: false,
    openEnd: false,
  };

  changeStart = (date: Date) => {
    this.props.startChange(
      moment(date)
        .startOf("day")
        .toDate(),
    );

    this.setState({ openStart: false });
  };

  changeEnd = (date: Date) => {
    this.props.endChange(
      moment(date)
        .endOf("day")
        .toDate(),
    );

    this.setState({ openEnd: false });
  };

  getMinDate = (date?: Date | null) =>
    date
      ? moment(date)
          .startOf("day")
          .toDate()
      : undefined;

  getMaxDate = (date?: Date | null) =>
    date
      ? moment(date)
          .endOf("day")
          .toDate()
      : undefined;

  openStart = () => {
    if (this.props.start) {
      this.props.startChange(undefined);
    } else {
      this.setState({ openStart: true });
    }
  };

  openEnd = () => {
    if (this.props.end) {
      this.props.endChange(undefined);
    } else {
      this.setState({ openEnd: true });
    }
  };

  closeStart = () => this.setState({ openStart: false });

  closeEnd = () => this.setState({ openEnd: false });

  render() {
    const {
      start,
      startPlaceholder,
      end,
      endPlaceholder,
      showMonthYearDropDowns = false,
      minDate,
      maxDate,
    } = this.props;
    const { openStart, openEnd } = this.state;
    const endDate = maxDate ? maxDate : end;
    const startDate = minDate ? minDate : start;

    return (
      <Container>
        <DateTimePicker
          onInputClick={this.openStart}
          onClickOutside={this.closeStart}
          showMonthDropdown={showMonthYearDropDowns}
          showYearDropdown={showMonthYearDropDowns}
          dropdownMode="select"
          selected={start}
          customInput={
            <DateInput small width="72px" label={startPlaceholder} />
          }
          onChange={this.changeStart}
          dateFormat="dd.MM.yy"
          popperPlacement="left-start"
          maxDate={this.getMaxDate(endDate)}
          minDate={this.getMinDate(startDate)}
          open={openStart}
        />
        <DateTimePicker
          onInputClick={this.openEnd}
          onClickOutside={this.closeEnd}
          selected={end}
          showMonthDropdown={showMonthYearDropDowns}
          showYearDropdown={showMonthYearDropDowns}
          dropdownMode="select"
          customInput={<DateInput small width="72px" label={endPlaceholder} />}
          onChange={this.changeEnd}
          dateFormat="dd.MM.yy"
          popperPlacement="left-start"
          maxDate={this.getMaxDate(endDate)}
          minDate={this.getMinDate(startDate)}
          open={openEnd}
        />
      </Container>
    );
  }
}

export default InlineDateRange;
