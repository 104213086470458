import React, { useEffect, useRef, useState } from "react";
import { curry } from "ramda";
import { History } from "history";
import strings from "localisation/strings";
import styled from "style/styled-components";

import SubmitButton from "components/forms/SubmitButton";
import ActionButton from "components/buttons/ActionButton";
import MapInterface from "api/models/MapInterface";
import { BlockTitle } from "components/generic";
import { MarginedArea } from "components/generic/ViewContainer";
import { ResponsiveSpreadRow } from "components/rows/SpreadRow";
import ResponsiveHalfRowColumn from "components/columns/HalfRowColumn";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import sheetIcon from "common/assets/icons/sheet.svg";
import theme from "style/theme";
import SelectComponent from "components/forms/Select";
import {
  defaultSelectLabel,
  mapStatusOptions,
} from "components/table/DataTable/constants";
import SimpleIconedInput from "components/forms/FormFields/SimpleIconedInput";
import useFormFields from "hooks/useFormFields";
import {
  getMapPayload,
  mapFields,
  setMapFields,
} from "components/helpers/formHelpers/map";

const ControllerContainer = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 0 ${({ theme }) => theme.margin.large};
`;

const MapForm = ({
  createNew,
  success,
  result,
  changedValues,
  setBlockNavigation,
  isNew,
  history,
}: {
  createNew: any;
  success: (message: string, response: any) => void;
  result?: MapInterface;
  changedValues: Set<string>;
  setBlockNavigation: (shouldBlockNavigation: boolean) => void;
  isNew: boolean;
  history: History;
}) => {
  const [oldContent, setOldContent] = useState<MapInterface>();
  const [formFields, setFormFields] = useState<object>(mapFields);
  const { xsmall, full } = theme.forms;

  const {
    values,
    getSelectFieldProps,
    getSingleInputFieldProps,
    parseErrors,
    updateValues,
  } = useFormFields({
    ...formFields,
    changedValues,
    handleGenericErrors: true,
    translationScope: "mapsScreen.fields",
  });
  const updateValuesRef = useRef(updateValues);

  useEffect(() => {
    if (result) {
      setOldContent(result);
      setFormFields(setMapFields(result));
      updateValuesRef.current(setMapFields(result));
    }
  }, [result]);

  return (
    <>
      <BlockTitle
        children={
          isNew
            ? strings("mapsScreen.title.newMap")
            : strings("mapsScreen.title.editMap")
        }
      />
      <MarginedArea>
        <ResponsiveSpreadRow>
          <ResponsiveHalfRowColumn>
            <SimpleIconedInput
              icon={sheetIcon}
              width={xsmall}
              inputWidth={full}
              inputPlaceholder={strings(
                "mapsScreen.fields.mapName.placeholder",
              )}
              {...getSingleInputFieldProps("mapName")}
            />
          </ResponsiveHalfRowColumn>
          <ResponsiveHalfRowColumn>
            <InlineInputGroup
              icon={sheetIcon}
              placeholder={strings("mapsScreen.fields.mapStatus")}
              width={xsmall}
            >
              <SelectComponent
                placeholder={defaultSelectLabel()}
                classNamePrefix={"menu"}
                small
                {...getSelectFieldProps("mapStatus", mapStatusOptions())}
              />
            </InlineInputGroup>
          </ResponsiveHalfRowColumn>
        </ResponsiveSpreadRow>
      </MarginedArea>

      <ControllerContainer>
        <ActionButton
          text={strings("buttons.cancel")}
          onClick={() => history.push("/admin/konfiguration/karten")}
        />
        <SubmitButton
          request={createNew}
          text={strings("buttons.save")}
          loadingText={strings("buttons.states.requestInProgress")}
          special="publish"
          getPayload={() => getMapPayload(values, oldContent)}
          successfulRequestCallback={curry(success)(
            strings("actionsArea.savedMessage"),
          )}
          failedRequestCallback={parseErrors}
          setShouldBlockNavigation={setBlockNavigation}
        />
      </ControllerContainer>
    </>
  );
};

export default MapForm;
