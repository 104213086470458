import styled from "style/styled-components";
import illustration from "assets/icons/illustration.svg";
import React from "react";
import strings from "localisation/strings";
import { Snackbar } from "components/generic";

const EmptyTimelineText = styled.div`
  height: 18px;
  color: ${({ theme }) => theme.color.foreground.emptyText};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 18px;
  margin-left: ${({ theme }) => theme.margin.standart};
`;

const EmptyTimeline = () => (
  <Snackbar.Container>
    <Snackbar.Illustration src={illustration} />
    <EmptyTimelineText>
      {strings(`businessTimelineList.empty`)}
    </EmptyTimelineText>
  </Snackbar.Container>
);

export default EmptyTimeline;
