import React, { ReactNode } from "react";
import styled from "style/styled-components";

export const StyledTitle = styled.h1<{
  fixedMargins?: boolean;
  topMargined?: boolean;
}>`
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: 800;
  font-size: ${({ theme }) => theme.font.size.xlarge};
  line-height: 30px;
  margin: ${({ theme, fixedMargins, topMargined }) =>
    `${topMargined ? theme.margin.large : 0} 0 ${
      fixedMargins ? theme.margin.large : theme.margin.standart
    }`};
`;

const Title = ({
  children,
  className,
  fixedMargins,
  topMargined,
}: {
  children: ReactNode;
  className?: string;
  fixedMargins?: boolean;
  topMargined?: boolean;
}) => (
  <StyledTitle
    fixedMargins={fixedMargins}
    className={className}
    topMargined={topMargined}
  >
    {children}
  </StyledTitle>
);

export default Title;
