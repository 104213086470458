import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { WizardStepFourHeader } from "components/headers/WizardHeader";
import {
  BodyText,
  CenteredContentViewContainer,
  Title,
} from "components/generic";
import { ReactComponent as Bliggit } from "assets/icons/bliggit.svg";
import ActionButton from "components/buttons/ActionButton";
import strings from "localisation/strings";
import { tryRefreshing } from "api/AuthedAPI";
import { Section, Subsection } from "views/Wizard/sharedComponents";

const SuccessContentSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WizzardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: ${({ theme }) => theme.content.sizes.medium};
`;

const ActionsSection = styled(Section)`
  display: flex;
  justify-content: center;
`;
const WizardComplete = ({ history }: RouteComponentProps) => {
  const handleWizardCompleted = () => {
    // after completing business profile creation wizard we do a service token refresh to get updated privileges and
    // profileCompleted flag: thus menu items for creating content become available and we "exit" the wizard mode
    tryRefreshing()
      .then(() => {
        history.push("/partnerprofil");
      })
      .catch((refreshTokenError: Error) => console.error(refreshTokenError));
  };

  return (
    <>
      <WizardStepFourHeader />
      <CenteredContentViewContainer>
        <WizzardContainer>
          <SuccessContentSection>
            <Bliggit />
            <Section>
              <Title>{strings("wizardScreen.success.successTitle")}</Title>
            </Section>
            <Subsection>
              <BodyText centered>
                {strings("wizardScreen.success.planNotice")}
              </BodyText>
            </Subsection>
          </SuccessContentSection>
          <ActionsSection>
            <ActionButton
              special="publish"
              text={strings("wizardScreen.success.planLeaveButton")}
              onClick={handleWizardCompleted}
            />
          </ActionsSection>
        </WizzardContainer>
      </CenteredContentViewContainer>
    </>
  );
};

export default WizardComplete;
