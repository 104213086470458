import React from "react";
import { curry } from "ramda";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import strings from "localisation/strings";

import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import ListResultType from "api/models/ListResultType";
import {
  getBusinessProfiles,
  getBusinessProfileFilters,
} from "api/businessProfiles";

import FilterableList from "components/lists/FilterableList";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { SearchRequest } from "components/lists/ListRequestTypes";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import BusinessProfileList from "components/lists/businessProfileList/BusinessProfileList";

import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import useLoginState from "hooks/useLoginState";
import { NEW } from "constants/strings";
import { listTestId } from "testing/testId";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import ListTableView from "views/ListTableView";

interface Props {
  match: {
    params: {
      businessId?: string;
    };
  };
}

const BusinessUserProfiles = ({
  location,
  history,
  match,
}: RouteComponentProps & Props) => {
  const { getBusinessId, hasAdminRights, hasCuratorRights } = useLoginState();
  const isCurator = hasCuratorRights();
  // grabbing businessId directly from path if it's not stored in loginState yet
  // this fixes the edge-case where businessId is required for the special case
  const businessId =
    hasAdminRights() || isCurator
      ? getBusinessId() || match.params.businessId
      : undefined;

  const businessProfileListRequest: SearchRequest<ListResultType<
    BusinessUserProfileInterface
  >> = ({ offset, limit, body, q }) =>
    curry(getBusinessProfiles)(
      { q, filterReq: body, from: offset, size: limit },
      businessId,
      isCurator,
      {},
    );

  const postBusinessProfileFiltersRequest: FiltersRequestType = ({ filters }) =>
    curry(getBusinessProfileFilters)({ filters }, businessId);
  /**
   * Special behaviour for branch list, if only 1 item is present, redirect to
   * its detailed view without rendering anything. To establish number of items
   * before rendering anything we need to make an extra call:
   */
  const { result, status } = useQuery<
    ListResultType<BusinessUserProfileInterface>
  >({
    request: businessProfileListRequest({ limit: 2, offset: 0, body: {} }),
    compare: { businessId },
  });

  // Render nothing until initial call finishes
  if (status === QueryStatus.WAITING) {
    return null;
  }

  // If only 1 item is present in list response, redirect to its detailed view
  if (!isCurator && result && result.data.length === 1) {
    return <Redirect to={`partnerprofil/${result.data[0].id}`} />;
  }

  const onClick = (id: string) => history.push(`partnerprofil/${id}`);

  const renderListView = ({
    items,
    onItemClick,
  }: InteractiveListInterface<BusinessUserProfileInterface>) => {
    return (
      <BusinessProfileList
        emptyIndicatable
        businessProfiles={items}
        onItemClick={onItemClick}
      />
    );
  };
  if (isCurator && !window.location.pathname.includes("/partner/")) {
    return (
      <PrivilegedComponent
        id={PrivilegedComponentsIds.VIEW_BUSINESS_PROFILE_CURATOR}
        isView
      >
        <ListTableView<BusinessUserProfileInterface> />
      </PrivilegedComponent>
    );
  }

  return (
    <PrivilegedComponent
      id={PrivilegedComponentsIds.VIEW_BUSINESS_PROFILE}
      isView
    >
      <FilterableList<BusinessUserProfileInterface>
        onItemClick={onClick}
        location={location}
        hideFilter={true}
        hideSort={true}
        history={history}
        match={match}
        pageTitle={strings("businessUserProfileListScreen.title")}
        filterPageTitle={strings(
          "businessUserProfileListScreen.filterScreenTitle",
        )}
        sortPageTitle={strings("sortScreen.title")}
        searchPageTitle={strings("searchScreen.title")}
        searchRequest={businessProfileListRequest}
        postFiltersRequest={postBusinessProfileFiltersRequest}
        onAddNew={() => onClick(NEW)}
        renderListView={renderListView}
        defaultSort={DEFAULT_SORT}
        {...listTestId("businessprofiles")}
      />
    </PrivilegedComponent>
  );
};

export default BusinessUserProfiles;
