import React, { useState, useEffect } from "react";
import { curry, includes, replace } from "ramda";
import { RouteComponentProps, withRouter } from "react-router";

import {
  getNewsById,
  createNews,
  createDraftNews,
  updateNews,
  updateDraftNews,
} from "api/news";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { COPY, NEW } from "constants/strings";

import NewsForm from "components/forms/NewsForm";
import DetailedView, { Copy, Details } from "views/DetailedView";
import useNotification from "hooks/useNotification";
import emojiDataState from "state/singletons/emojiDataState";
import useQuery from "common/hooks/useQuery";
import { getEmojiData } from "api/imageStorage";
import QueryStatus from "common/api/models/QueryStatus";
import useLoginState from "hooks/useLoginState";

const getNewsRequest = curry(getNewsById);

const CopyForm = (props: any) => <NewsForm isNew {...props} />;

const DetailsForm = ({
  isDraft,
  updateItem,
  updateDraftItem,
  ...props
}: any) => (
  <NewsForm
    isNew={false}
    createNew={updateItem}
    createDraft={isDraft ? updateDraftItem : updateItem}
    {...props}
  />
);

const changedValues = new Set<string>();

const NewsDetailed = (props: RouteComponentProps<{ id: string }>) => {
  const { getBusinessIdForNewContent } = useLoginState();
  const businessId = getBusinessIdForNewContent();
  const { addSuccessNotification } = useNotification();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );
  const [forceDetailsUpdate, setForceDetailsUpdate] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(props.match.params.id === NEW);
  const isCopy = includes(props.match.params.id, COPY);
  const copyId = replace("?id=", "", props.location.search);

  useEffect(() => {
    return setIsNew(props.match.params.id === NEW);
  }, [props.match.params.id]);

  const successCallback = (message: string, response: any) => {
    addSuccessNotification(message);
    if (isNew || isCopy) {
      props.history.push(`/admin/inhalt/news/${response.id}`);
    } else {
      setForceDetailsUpdate(!forceDetailsUpdate);
    }
  };

  const mainProps = {
    changedValues,
    success: successCallback,
    setBlockNavigation: setShouldBlockNavigation,
    getDetailsRequest: getNewsRequest,
  };

  const mainRequests = {
    createNew: createNews,
    createDraft: createDraftNews,
  };

  const {
    result: emojiDataFetchResult,
    status: emojiDataFetchStatus,
  } = useQuery({ request: getEmojiData });
  useEffect(() => {
    if (emojiDataFetchStatus === QueryStatus.SUCCESSFUL) {
      emojiDataState.setEmojiData(emojiDataFetchResult);
    }
  }, [emojiDataFetchStatus]);

  return (
    <DetailedView
      {...props}
      privilegedId={PrivilegedComponentsIds.VIEW_NEWS_ADMIN}
      shouldBlockNavigation={shouldBlockNavigation}
      isCopy={isCopy}
      isNew={isNew}
      form={
        <NewsForm
          isNew
          businessId={businessId}
          {...mainRequests}
          {...mainProps}
        />
      }
      copy={
        <Copy id={copyId} form={CopyForm} {...mainRequests} {...mainProps} />
      }
      details={
        <Details
          id={props.match.params.id}
          form={DetailsForm}
          updateItem={updateNews}
          updateDraftItem={updateDraftNews}
          update={forceDetailsUpdate}
          {...mainProps}
        />
      }
    />
  );
};

export default withRouter(NewsDetailed);
