import React from "react";
import styled, { isMobile } from "style/styled-components";
import {
  ContentItemOption,
  DashboardInterface,
} from "api/models/DashboardInterface";
import StatisticTypeTabs from "components/dashboard/StatisticTypeTabs";
import AreaChart from "components/dashboard/AreaChart";
import ContentType from "common/api/models/ContentType";
import useWindowDimensions from "hooks/useWindowDimensions";
import ChartHeader from "components/dashboard/ChartHeader";
import SelectedContentItemOptionsInterface from "components/dashboard/SelectedContentItemOptionsInterface";
import EmptyChartMessage from "components/dashboard/EmptyChartMessage";
import { chartTestId } from "testing/testId";

interface Props {
  contentType: ContentType;
  fetchedDashboardResult: DashboardInterface;
  fetchedContentItemOptionsResult: ContentItemOption[] | undefined;
  selectedContentItemOptions: SelectedContentItemOptionsInterface;
  setSelectedContentItemOptions: (
    selectedContentItemOptions: SelectedContentItemOptionsInterface,
  ) => void;
  statisticType: string;
  setStatisticType: (statisticType: string) => void;
}

const EmptyContentMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.margin.large};
  padding-right: ${({ theme }) => theme.margin.medium};
  padding-left: ${({ theme }) => theme.margin.medium};
`;

const Container = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.margin.large};
  margin-right: ${({ theme, isMobileDevice }) =>
    isMobileDevice ? "0px" : theme.margin.large};
  margin-left: ${({ theme, isMobileDevice }) =>
    isMobileDevice ? "0px" : theme.margin.medium};
`;

const ChartContainer = styled.div`
  height: 350px;
`;

const Chart = ({
  contentType,
  fetchedDashboardResult,
  fetchedContentItemOptionsResult,
  selectedContentItemOptions,
  setSelectedContentItemOptions,
  statisticType,
  setStatisticType,
}: Props) => {
  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);

  const isSomeContentsCreated =
    fetchedContentItemOptionsResult &&
    fetchedContentItemOptionsResult.length >= 1;
  if (!isSomeContentsCreated) {
    return (
      <EmptyContentMessageContainer {...chartTestId(contentType as string)}>
        <EmptyChartMessage />
      </EmptyContentMessageContainer>
    );
  }

  return (
    <Container
      isMobileDevice={isMobileDevice}
      {...chartTestId(contentType as string)}
    >
      <ChartHeader
        contentType={contentType}
        fetchedDashboardResult={fetchedDashboardResult}
        fetchedContentItemOptionsResult={fetchedContentItemOptionsResult}
        selectedContentItemOptions={selectedContentItemOptions}
        setSelectedContentItemOptions={setSelectedContentItemOptions}
      />
      <StatisticTypeTabs
        fetchedResult={fetchedDashboardResult}
        contentType={fetchedDashboardResult.contentType}
        statisticType={statisticType}
        setStatisticType={setStatisticType}
      />
      <ChartContainer>
        <AreaChart
          data={fetchedDashboardResult[statisticType].data}
          statisticType={statisticType}
        />
      </ChartContainer>
    </Container>
  );
};

export default Chart;
