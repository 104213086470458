import React, { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "style/styled-components";
import deletedEndUserAvatar from "common/assets/icons/deletedEndUserAvatar.svg";
import endUserAvatar from "common/assets/icons/endUserAvatar.svg";
import botAvatar from "common/assets/icons/botAvatar.svg";
import { ReactComponent as UnreadIcon } from "assets/icons/chatSettings.svg";
import {
  EllipticText,
  Avatar,
  AbsoluteOverlaySpinner,
} from "components/generic";
import { InlineUnreadCount } from "components/chat/UnreadCount";
import useChatState from "hooks/useChatState";
import { chatConversationItemTestId } from "testing/testId";

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;

  .infinite-scroll-component__outerdiv {
    display: block; // So that children can overflow freely and set their own scrollbar
    max-height: 0; // So that outer div content doesn't cause it to expand in height
  }
`;

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
`;

const StyledUnreadIcon = styled(UnreadIcon)`
  width: 11px;
  height: 11px;
  margin-right: ${({ theme }) => theme.margin.medium};
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.color.foreground.secondaryDark};
  }
`;

const ConversationListItemContainer = styled.li<{ isSelected?: boolean }>`
  display: flex;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.chat.bg.activeConversation : "none"};
  align-items: stretch;
  padding: ${({ theme }) => `${theme.margin.medium} ${theme.margin.large}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.background.primary};
  cursor: pointer;
  :focus {
    outline: 0;
  }
`;

const ConversationNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ConversationName = styled(EllipticText)<{ hasUnreadMessages: boolean }>`
  font-weight: ${({ hasUnreadMessages }) =>
    hasUnreadMessages ? "900" : "normal"};
`;

const ConversationListItemWrapper = styled.span`
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.onLightBackground};

  :hover {
    ${ConversationName} {
      text-decoration: underline;
    }
  }
`;

const SpinnerContainer = styled.div`
  position: relative;
  height: 30px;
`;

const ConversationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  margin-left: ${({ theme }) => theme.margin.small};
`;

const LastMessageText = styled(EllipticText)`
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.color.foreground.tertiary};
`;

interface Props {
  scrollContainerDomId: string;
  selectedConversationId: string;
  isConversationsListLoading: boolean;
  isMoreConversationsAvailable: boolean;
  onConversationSelected: (id: string) => void;
  onMoreConversationsRequested: () => void;
}

interface ConversationProps {
  id: string;
  isSelected: boolean;
  onConversationSelected: (id: string) => void;
}

const ConversationListItem = ({
  id,
  isSelected,
  onConversationSelected,
}: ConversationProps) => {
  const handleClick = useCallback(() => {
    onConversationSelected(id);
  }, [onConversationSelected, id]);

  const {
    image,
    isUnread,
    isSpecial,
    title,
    unreadCount,
    isSystem,
    ownerDeleted,
    lastMessage,
    displayLastMessage,
  } = useChatState().conversationsById[id];
  const userAvatar = ownerDeleted ? deletedEndUserAvatar : endUserAvatar;

  return (
    <ConversationListItemWrapper
      onClick={handleClick}
      {...chatConversationItemTestId(id)}
    >
      <ConversationListItemContainer tabIndex={0} isSelected={isSelected}>
        <Avatar
          picture={isSystem ? botAvatar : image || userAvatar}
          size={40}
        />
        <ConversationBody>
          <ConversationNameContainer>
            {isSpecial && <StyledUnreadIcon />}
            <ConversationName hasUnreadMessages={isUnread}>
              {title}
            </ConversationName>
            <InlineUnreadCount size="medium" unreadCount={unreadCount} />
          </ConversationNameContainer>
          {lastMessage && displayLastMessage && (
            <LastMessageText>{lastMessage}</LastMessageText>
          )}
        </ConversationBody>
      </ConversationListItemContainer>
    </ConversationListItemWrapper>
  );
};

const ConversationsList = ({
  scrollContainerDomId,
  selectedConversationId,
  isConversationsListLoading,
  isMoreConversationsAvailable,
  onConversationSelected,
  onMoreConversationsRequested,
}: Props) => {
  const { conversationsIds } = useChatState();

  return (
    <Wrapper>
      <ListContainer>
        <InfiniteScroll
          scrollableTarget={scrollContainerDomId}
          dataLength={conversationsIds.length}
          next={onMoreConversationsRequested}
          hasMore={isMoreConversationsAvailable}
          loader={
            isConversationsListLoading && (
              <SpinnerContainer>
                <AbsoluteOverlaySpinner />
              </SpinnerContainer>
            )
          }
        >
          {isConversationsListLoading && <AbsoluteOverlaySpinner />}
          {conversationsIds.map(id => (
            <ConversationListItem
              id={id}
              key={id}
              isSelected={id === selectedConversationId}
              onConversationSelected={onConversationSelected}
            />
          ))}
        </InfiniteScroll>
      </ListContainer>
    </Wrapper>
  );
};

export default ConversationsList;
