import React from "react";
import {
  BlueArrow,
  BlueStepContainer,
  FilledBlueArrow,
  ProgressFlowRow,
  WhiteArrow,
  WhiteStepContainer,
} from "components/headers/BaseHeader";
import strings from "localisation/strings";
import styled from "style/styled-components";

const WizardProgressFlowRow = styled(ProgressFlowRow)`
  border: 1px solid ${({ theme }) => theme.color.foreground.disabled};
`;

export const WizardStepOneHeader = () => (
  <WizardProgressFlowRow>
    <BlueStepContainer>
      1. {strings("wizardScreen.header.businessAdministratorData")}
    </BlueStepContainer>
    <BlueArrow />
    <WhiteStepContainer>
      2. {strings("wizardScreen.header.businessDetails")}
    </WhiteStepContainer>
    <WhiteArrow />
    <WhiteStepContainer>
      3. {strings("wizardScreen.header.planSelection")}
    </WhiteStepContainer>
    <WhiteArrow />
    <WhiteStepContainer>
      4. {strings("wizardScreen.header.contract")}
    </WhiteStepContainer>
    <WhiteArrow />
    <WhiteStepContainer>
      5. {strings("wizardScreen.header.payment")}
    </WhiteStepContainer>
  </WizardProgressFlowRow>
);

export const WizardStepTwoHeader = () => (
  <WizardProgressFlowRow>
    <BlueStepContainer>
      1. {strings("wizardScreen.header.businessAdministratorData")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      2. {strings("wizardScreen.header.businessDetails")}
    </BlueStepContainer>
    <BlueArrow />
    <WhiteStepContainer>
      3. {strings("wizardScreen.header.planSelection")}
    </WhiteStepContainer>
    <WhiteArrow />
    <WhiteStepContainer>
      4. {strings("wizardScreen.header.contract")}
    </WhiteStepContainer>
    <WhiteArrow />
    <WhiteStepContainer>
      5. {strings("wizardScreen.header.payment")}
    </WhiteStepContainer>
  </WizardProgressFlowRow>
);

export const WizardStepThreeHeader = () => (
  <WizardProgressFlowRow>
    <BlueStepContainer>
      1. {strings("wizardScreen.header.businessAdministratorData")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      2. {strings("wizardScreen.header.businessDetails")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      3. {strings("wizardScreen.header.planSelection")}
    </BlueStepContainer>
    <BlueArrow />
    <WhiteStepContainer>
      4. {strings("wizardScreen.header.contract")}
    </WhiteStepContainer>
    <WhiteArrow />
    <WhiteStepContainer>
      5. {strings("wizardScreen.header.payment")}
    </WhiteStepContainer>
  </WizardProgressFlowRow>
);

export const WizardStepFourHeader = () => (
  <WizardProgressFlowRow>
    <BlueStepContainer>
      1. {strings("wizardScreen.header.businessAdministratorData")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      2. {strings("wizardScreen.header.businessDetails")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      3. {strings("wizardScreen.header.planSelection")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      4. {strings("wizardScreen.header.contract")}
    </BlueStepContainer>
    <BlueArrow />
    <WhiteStepContainer>
      5. {strings("wizardScreen.header.payment")}
    </WhiteStepContainer>
  </WizardProgressFlowRow>
);

export const WizardStepFiveHeader = () => (
  <WizardProgressFlowRow>
    <BlueStepContainer>
      1. {strings("wizardScreen.header.businessAdministratorData")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      2. {strings("wizardScreen.header.businessDetails")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      3. {strings("wizardScreen.header.planSelection")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      4. {strings("wizardScreen.header.contract")}
    </BlueStepContainer>
    <FilledBlueArrow />
    <BlueStepContainer>
      5. {strings("wizardScreen.header.payment")}
    </BlueStepContainer>
  </WizardProgressFlowRow>
);
