import React, { useCallback } from "react";
import styled from "style/styled-components";
import { QuickReplyOption } from "components/chat/types";
import { baseButtonStyle } from "components/buttons/ActionButton";

interface InteractiveItemProps {
  selectedValue: string | null;
  isDisabled: boolean;
  options: QuickReplyOption[];
  onInteractionFinished: (option: QuickReplyOption, value?: string) => void;
}

export const InteractiveButton = styled.button<{
  bordered: boolean;
  selected: boolean;
}>`
  ${baseButtonStyle}
  flex: 1 0 auto;
  white-space: normal;
  background-color: ${({ theme }) => theme.color.background.primary};
  color: ${({ theme }) => theme.color.foreground.action};
  &:disabled {
    color: ${({ theme, selected }) =>
      selected
        ? theme.color.foreground.action
        : theme.color.foreground.disabled};
    opacity: 0.7;
  }
  ${({ theme, bordered }) =>
    bordered &&
    `border-bottom: 1px solid ${theme.color.chat.message.background.left};`};
`;

const InteractiveButtons = (props: InteractiveItemProps) => {
  const handleButtonSelexted = useCallback(
    (option: QuickReplyOption) => {
      props.onInteractionFinished(option);
    },
    [props.onInteractionFinished],
  );

  return (
    <>
      {props.options.map((option, index, array) => (
        <InteractiveButton
          key={option.value.reply}
          tabIndex={index}
          selected={option.value.reply === props.selectedValue}
          onClick={() => handleButtonSelexted(option)}
          disabled={props.isDisabled}
          bordered={index !== array.length - 1}
        >
          {option.label}
        </InteractiveButton>
      ))}
    </>
  );
};

export default InteractiveButtons;
