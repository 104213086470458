import styled from "style/styled-components";
import CloseableTagButton from "components/buttons/CloseableTagButton";
import { TransparentTag } from "components/generic/InputListTag";

export interface HasErrorsInterface {
  hasErrors?: boolean;
}

export const TagSelectionFieldContainer = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.border.light};
  padding: ${({ theme }) =>
    `0 ${theme.margin.standart} ${theme.margin.standart}`};
`;

export const TagSelectionTitle = styled.span<HasErrorsInterface>`
  display: block;
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: ${({ theme }) => theme.font.size.xlarge};
  color: ${({ hasErrors, theme }) =>
    hasErrors
      ? theme.color.foreground.action
      : theme.color.foreground.secondary};
`;

export const DropdownWindow = styled.div<{ isOpened: boolean }>`
  display: ${({ isOpened }) => (isOpened ? "block" : "none")};
  position: absolute;
  z-index: ${({ theme }) => theme.layers.dropdown};
  transform: translateZ(0);
  top: 40px;
  left: -${({ theme }) => theme.margin.standart};
  width: calc(
    100% + ${({ theme }) => theme.margin.standart} +
      ${({ theme }) => theme.margin.standart}
  );
  max-height: 600px;
  overflow: hidden scroll;

  // IE11 specific styles
  @media all and (-ms-high-contrast: none) {
    overflow-y: scroll;
  }

  white-space: nowrap;
  padding: ${({ theme }) =>
    `${theme.margin.standart} ${theme.margin.standart} 0`};
  background: ${({ theme }) => theme.color.foreground.antiPrimary};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.border.medium};
`;

export const DropdownContainer = styled.div`
  display: block;
  position: relative;
`;

export const TAG_MARGIN = 2;

export const SpacedCloseableTagButton = styled(CloseableTagButton)`
  margin: ${TAG_MARGIN}px;
`;

export const TagButtonArea = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -${TAG_MARGIN}px;
`;

export const AddTag = styled(TransparentTag)<HasErrorsInterface>`
  margin: ${TAG_MARGIN}px;

  ${({ hasErrors, theme }) =>
    hasErrors &&
    `
    border-color: ${theme.color.foreground.action};
    color: ${theme.color.foreground.action};
    background-color: ${theme.color.background.secondaryLight};
  `};
`;

export const SearchInput = styled.input`
  margin-left: ${({ theme }) => theme.margin.xsmall};
`;
