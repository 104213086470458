import React from "react";
import { withRouter } from "react-router-dom";
import ListTableView from "views/ListTableView";
import { ExtendedRepresentativeInterface } from "api/models/RepresentativeInterface";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
const AllPublicUsers = () => {
  return (
    <PrivilegedComponent
      id={PrivilegedComponentsIds.VIEW_REPRESENTATIVES_ADMIN_TABLE}
      isView
    >
      <ListTableView<ExtendedRepresentativeInterface> />
    </PrivilegedComponent>
  );
};

export default withRouter(AllPublicUsers);
