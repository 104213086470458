import React from "react";
import styled from "style/styled-components";
import {
  PasswordStrengths,
  strengthColor,
} from "components/forms/RegistrationForm/getPasswordStrength";

const strengthLineProgress = {
  [PasswordStrengths.NONE]: 0,
  [PasswordStrengths.INVALID]: 0,
  [PasswordStrengths.WEAK]: 0.2,
  [PasswordStrengths.MEDIUM]: 0.6,
  [PasswordStrengths.STRONG]: 1,
};

const LineContainer = styled.div`
  width: 100px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
`;

const Line = styled.div<{ color: string; progress: number }>`
  background-color: ${({ color }) => color};
  width: ${({ progress }) => progress * 100}%;
  height: 4px;
  border-radius: 2px;
  z-index: -1;
  transition: width 0.2s;
`;

const BackgroundLine = styled(Line)`
  position: absolute;
`;

export interface PasswordStrengthLineProps {
  strength: PasswordStrengths;
}

const PasswordStrengthLine = ({ strength }: PasswordStrengthLineProps) => {
  const lineColor = strengthColor[strength];
  const progress = strengthLineProgress[strength];
  return (
    <LineContainer>
      <BackgroundLine
        color={strengthColor[PasswordStrengths.NONE]}
        progress={1}
      />
      <Line color={lineColor} progress={progress} />
    </LineContainer>
  );
};

export default PasswordStrengthLine;
