import React from "react";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";

import PriceMinMax from "api/models/PriceMinMax";
import shouldShowPrice from "common/events/shouldShowPrice";
import FormattedPrice from "components/lists/eventList/FormattedPrice";

export const PriceText = styled(FormattedPrice)`
  display: block;
  text-align: right;
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-size: ${({ theme }) => theme.font.size.xlarge};
  font-weight: 800;

  @media ${breakpoints.tabletMax} {
    font-size: ${({ theme }) => theme.font.size.medium};
    line-height: 18px;
  }

  @media ${breakpoints.phoneMax} {
    text-align: left;
    padding-bottom: ${({ theme }) => theme.margin.medium};
  }
`;

const PriceDisplay = ({ priceMinMax }: { priceMinMax?: PriceMinMax }) =>
  priceMinMax && shouldShowPrice(priceMinMax) ? (
    <PriceText price={priceMinMax} />
  ) : null;

export default PriceDisplay;
