import React from "react";
import styled from "style/styled-components";
import Headline, {
  LargeFontHeadline,
  ResizingHeadline,
} from "components/generic/Headline";

const Container = styled.div`
  margin: ${({ theme }) => theme.margin.large};
  max-width: 100%;
  flex: 1 0 auto;
`;

const CenteredLargeFontBlockTitleContainer = styled(Container)`
  margin: ${({ theme }) => theme.margin.xlarge} 0px
    ${({ theme }) => theme.margin.large};
`;

const CenteredHeadline = styled(Headline)`
  text-align: center;
`;

const CenteredLargeHeadline = styled(LargeFontHeadline)`
  text-align: center;
`;

const BlockTitle = ({ children, ...props }: { children: any }) => (
  <Container {...props}>
    <Headline>{children}</Headline>
  </Container>
);

export const MarginlessBlockTitle = styled(BlockTitle)`
  margin: 0;
`;

export const BlockTitleWithSideMargin = styled(BlockTitle)`
  margin: ${({ theme }) => theme.margin.xlarge};
`;

export const CenteredBlockTitle = ({
  children,
  ...props
}: {
  children: any;
}) => (
  <Container {...props}>
    <CenteredHeadline>{children}</CenteredHeadline>
  </Container>
);

export const CenteredLargeFontBlockTitle = ({
  children,
  ...props
}: {
  children: any;
}) => (
  <CenteredLargeFontBlockTitleContainer {...props}>
    <CenteredLargeHeadline>{children}</CenteredLargeHeadline>
  </CenteredLargeFontBlockTitleContainer>
);

export const ResizingBlockTitle = ({
  children,
  ...props
}: {
  children: any;
}) => (
  <Container {...props}>
    <ResizingHeadline>{children}</ResizingHeadline>
  </Container>
);

export default BlockTitle;
