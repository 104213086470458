import Location from "api/models/Location";
import { PaymentAddressDTO } from "api/models/Payments";

const defaultPaymentAddress = {
  companyName: "",
  email: "",
  phone: "",
  vatId: "",
  additionalInformation: "",
};

const defaultLocation: Location = {
  city: "",
  houseNo: "",
  postalCode: "",
  street: "",
};

export const defaultCreditCardDataFields = {
  textFields: {
    owner: "",
    companyName: "",
  },
  locationFields: {
    location: defaultLocation,
  },
  contactFields: {
    paymentAddress: defaultPaymentAddress,
  },
  checkboxFields: {
    defaultPaymentMethod: false,
  },
};

export const defaultSepaDataFields = {
  textFields: {
    owner: "",
    iban: "",
    companyName: "",
  },
  locationFields: {
    location: defaultLocation,
  },
  contactFields: {
    paymentAddress: defaultPaymentAddress,
  },
  checkboxFields: {
    defaultPaymentMethod: false,
  },
};

export const setCreditCardFields = (
  paymentAddress: PaymentAddressDTO,
  isDefaultPaymentMethod: boolean,
) => {
  return {
    textFields: {
      owner: "",
      companyName:
        paymentAddress && paymentAddress.companyName
          ? paymentAddress.companyName
          : "",
    },
    locationFields: {
      location: defaultLocation,
    },
    contactFields: {
      paymentAddress,
    },
    checkboxFields: {
      defaultPaymentMethod: isDefaultPaymentMethod,
    },
  };
};

export const setSepaFields = (
  paymentAddress: PaymentAddressDTO,
  isDefaultPaymentMethod: boolean,
) => {
  return {
    textFields: {
      owner: "",
      iban: "",
      companyName:
        paymentAddress && paymentAddress.companyName
          ? paymentAddress.companyName
          : "",
    },
    locationFields: {
      location: defaultLocation,
    },
    contactFields: {
      paymentAddress,
    },
    checkboxFields: {
      defaultPaymentMethod: isDefaultPaymentMethod,
    },
  };
};
