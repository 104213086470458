import React from "react";
import { SuggestionContainer } from "components/forms/FormFields/AddressAutocomplete/SuggestionsDropdown";
import styled from "styled-components";
import strings from "localisation/strings";

const SecondarySuggestionLabel = styled.span`
  color: ${({ theme }) => theme.color.foreground.tertiary};
`;

const NoLocationFoundSuggestion = () => (
  <SuggestionContainer>
    <SecondarySuggestionLabel>
      {strings("addressAutocomplete.noLocationFoundByName")}
    </SecondarySuggestionLabel>
  </SuggestionContainer>
);

export default NoLocationFoundSuggestion;
