import React from "react";
import strings from "localisation/strings";
import SelectComponent, { SelectItem } from "components/forms/Select";
import styled from "styled-components";
interface Props {
  isOpen?: boolean;
  onChange: (option: any) => void;
  value?: SelectItem;
  options: SelectItem[];
  isMulti?: boolean;
  width?: string;
  isSearchable?: boolean;
  onDashboard?: boolean;
  placeholder?: string;
  areCheckboxesDisabled?: boolean;
  classNamePrefix?: string;
  errors?: string[];
  style?: string;
}

const SelectList = (props: Props) => {
  const StyledSelect = styled(SelectComponent)`
    ${props.style}
  `;
  return (
    <StyledSelect
      classNamePrefix={
        props.classNamePrefix ? props.classNamePrefix : "selectList"
      }
      value={props.value}
      placeholder={
        props.placeholder
          ? props.placeholder
          : props.isMulti || !props.value
          ? strings("tableView.choose")
          : props.value.label
      }
      onChange={props.onChange}
      defaultMenuIsOpen={props.isOpen}
      options={props.options}
      small
      hideSelectedOptions={!props.isMulti}
      isMulti={props.isMulti}
      width={props.width || "90%"}
      isSearchable={props.isSearchable}
      onDashboard={props.onDashboard}
      areCheckboxesDisabled={props.areCheckboxesDisabled}
      errors={props.errors}
    />
  );
};

export default SelectList;
