import StoredState from "state/StoredState";
import { LoginTokens } from "api/models/UsersInterface";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import { arraysAreEqual } from "components/helpers/utils";
import { createPrivilegedComponentSet } from "utils";
import PRIVILEGES_TO_COMPONENTS_MAP from "constants/privilegesToComponentsMap";
import UserPrivileges from "constants/UserPrivileges";
import { logout } from "api/session";
import { addAuthorizationHeader } from "api/APIHelpers";

interface LoginStateType extends LoginTokens {
  firstName?: string;
  lastName?: string;
  keepLoggedIn: boolean;
  privilegedComponentSet: Set<string> | null;
  businessProfileEntity?: BusinessUserProfileInterface;
  incompleteBusinessProfileId?: string;
  currentUserBusinessId?: string;
}

const initialState: LoginStateType = {
  serviceToken: "",
  firstName: undefined,
  lastName: undefined,
  keepLoggedIn: true,
  privileges: [],
  privilegedComponentSet: null,
  businessId: undefined,
  profileCompleted: false,
  profilePublished: false,
  businessProfileId: undefined,
  businessProfileEntity: undefined, // locally stored entity of BUP for Wizard flow
  incompleteBusinessProfileId: undefined,
  currentUserBusinessId: undefined,
  defaultBusinessId: undefined,
  defaultBusinessName: undefined,
  onboardingState: {
    planSelected: false,
    termsAccepted: false,
    paymentDataProvided: false,
  },
};

export default class LoginState extends StoredState<LoginStateType> {
  state = initialState;
  stringifiedState = JSON.stringify(initialState);

  constructor(storageKey?: string) {
    super(storageKey || "LoginState", window.localStorage);
  }

  onStateRestored = (restoredState: Partial<LoginStateType>) => {
    const {
      serviceToken,
      firstName,
      lastName,
      keepLoggedIn,
      privileges,
      privilegedComponentSet,
      profileCompleted,
      profilePublished,
      businessId,
      incompleteBusinessProfileId,
      businessProfileEntity,
      currentUserBusinessId,
      defaultBusinessId,
      defaultBusinessName,
      onboardingState,
    } = restoredState;

    return this.setState({
      ...(keepLoggedIn !== null && { keepLoggedIn }),
      ...(profileCompleted && { profileCompleted }),
      ...(profilePublished && { profilePublished }),
      ...(serviceToken && privileges && privilegedComponentSet
        ? {
            serviceToken,
            firstName,
            lastName,
            keepLoggedIn,
            privileges,
            businessId,
            incompleteBusinessProfileId,
            businessProfileEntity,
            currentUserBusinessId,
            defaultBusinessId,
            defaultBusinessName,
            onboardingState,
            privilegedComponentSet: new Set(privilegedComponentSet),
          }
        : {
            serviceToken: "",
          }),
    });
  };

  refresh = async ({
    serviceToken,
    privileges,
    profileCompleted,
    businessProfileId: incompleteBusinessProfileId,
    businessId: currentUserBusinessId,
  }: LoginTokens) => {
    if (
      profileCompleted !== this.state.profileCompleted ||
      (privileges && !arraysAreEqual(privileges, this.state.privileges))
    ) {
      const privilegedComponentSet = createPrivilegedComponentSet(
        privileges,
        PRIVILEGES_TO_COMPONENTS_MAP,
        profileCompleted,
      );
      this.setState({
        serviceToken,
        privileges,
        profileCompleted,
        privilegedComponentSet,
        incompleteBusinessProfileId,
        currentUserBusinessId,
      });
    } else {
      this.setState({ serviceToken });
    }
  };

  getServiceToken = () => this.state.serviceToken;

  getState = () => {
    this.refreshStateFromStoreIfRequired();
    return this.state;
  };

  getUserName = () => {
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    return { firstName, lastName };
  };

  refreshStateFromStoreIfRequired = () => {
    const existingStateString = this.storage.getItem(this.storageKey);
    if (existingStateString !== this.stringifiedState) {
      const newState = JSON.parse(existingStateString || "{}");
      this.onStateRestored(newState);
    }
  };

  postStateSetAndStringified = (newStringifiedState: string) => {
    this.stringifiedState = newStringifiedState;
  };

  getPersistentLogin = () => this.state.keepLoggedIn;

  getUserPrivileges = () => this.state.privileges;

  getBusinessId = () => this.state.businessId;

  getCurrentUserBusinessId = () => this.state.currentUserBusinessId;

  getDefaultBusinessId = () => this.state.defaultBusinessId;

  getDefaultBusinessName = () => this.state.defaultBusinessName;

  isDefaultBusinessContext = () =>
    !!this.state.businessId &&
    this.state.businessId === this.state.defaultBusinessId;

  isDefaultBusinessContextOrUnselected = () =>
    !this.state.businessId ||
    this.state.businessId === this.state.defaultBusinessId;

  isMainBusinessContext = () =>
    !!this.state.businessId &&
    this.state.businessId === this.state.currentUserBusinessId;

  getIncompleteBusinessProfileId = () => this.state.incompleteBusinessProfileId;

  getBusinessProfileEntity = () => this.state.businessProfileEntity;

  getProfileCompleted = () => this.state.profileCompleted;

  getProfilePublished = () => this.state.profilePublished;

  // Admin or curator sets businessId when a particular Business Profile is clicked
  setBusinessId = (businessId: string) => this.setState({ businessId });

  setUserName = (firstName?: string, lastName?: string) => {
    this.setState({ firstName, lastName });
  };

  setBusinessProfileEntity = (
    businessProfileEntity: BusinessUserProfileInterface,
  ) => this.setState({ businessProfileEntity });

  hasAdminRights = () =>
    this.state.privileges.includes(UserPrivileges.TYPE_INTERNAL);

  hasCuratorRights = () =>
    this.state.privileges.includes(UserPrivileges.TYPE_CURATOR);

  isUserLoggedIn = () => {
    this.refreshStateFromStoreIfRequired();
    return !!this.state.serviceToken;
  };

  setCorrespondingStorage = (rememberMe: boolean) => {
    if (this.state.keepLoggedIn !== rememberMe) {
      this.storage.removeItem(this.storageKey);
      this.storage = rememberMe ? window.localStorage : window.sessionStorage;
      this.setState({ keepLoggedIn: rememberMe });
    }
  };

  getPrivilegedComponentSet = () => this.state.privilegedComponentSet;

  getOnboardingState = () => this.state.onboardingState;

  // for internal administrators we either take business ID from the current navigation context
  // or use the business ID of the default company
  getBusinessIdForNewContent = () =>
    this.hasAdminRights() || this.hasCuratorRights()
      ? this.getBusinessId() || this.getDefaultBusinessId()
      : undefined;

  logIn = ({
    serviceToken,
    privileges,
    profileCompleted,
    profilePublished,
    businessProfileId: incompleteBusinessProfileId,
    businessId: currentUserBusinessId,
    defaultBusinessId,
    defaultBusinessName,
    onboardingState,
  }: LoginTokens) => {
    const privilegedComponentSet = createPrivilegedComponentSet(
      privileges,
      PRIVILEGES_TO_COMPONENTS_MAP,
      profileCompleted,
    );
    this.setState({
      serviceToken,
      privileges,
      privilegedComponentSet,
      incompleteBusinessProfileId,
      currentUserBusinessId,
      defaultBusinessId,
      defaultBusinessName,
      profileCompleted,
      profilePublished,
      onboardingState,
    });
  };

  logOut = () => {
    if (this.isUserLoggedIn()) {
      try {
        // To avoid circular dependency issues between LoginState and session.ts, pass token header explicitly instead of using AuthedAPI
        logout(addAuthorizationHeader({ options: {}, tokens: this.state }));
      } catch (e) {
        // do nothing
      }
      this.setState({
        serviceToken: "",
        firstName: undefined,
        lastName: undefined,
        privileges: [],
        privilegedComponentSet: null,
        businessId: undefined,
        profileCompleted: false,
        profilePublished: false,
        businessProfileId: undefined,
        businessProfileEntity: undefined,
        incompleteBusinessProfileId: undefined,
        currentUserBusinessId: undefined,
        defaultBusinessId: undefined,
        defaultBusinessName: undefined,
        onboardingState: undefined,
      });
    }
  };
}
