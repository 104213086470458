import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import ContentType from "common/api/models/ContentType";
import useLoginState from "hooks/useLoginState";
import { TypeButton, TypeButtonProps } from "components/dashboard/TypeButton";

interface Props {
  contentType: ContentType;
  setContentType: (contentType: ContentType) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.chat.bg.sidebar};
  padding-top: ${({ theme }) => theme.margin.large};
  padding-left: ${({ theme }) => theme.margin.large};
  padding-right: ${({ theme }) => theme.margin.large};
  height: 50px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

const TypeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.margin.xlarge};
`;

const SelectIndicator = styled.div<{ isSelected: boolean }>`
  height: 4px;
  width: 100%;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.foreground.action : theme.color.chat.bg.sidebar};
`;

const ScrollableTabsTypeButton = ({
  isSelected,
  typeName,
  onClick,
  typeKey,
}: TypeButtonProps) => (
  <TypeButtonContainer>
    <TypeButton
      isSelected={isSelected}
      typeName={typeName}
      onClick={onClick}
      typeKey={typeKey}
    />
    <SelectIndicator isSelected={isSelected} />
  </TypeButtonContainer>
);

const TypeSelectScrollableTabs = ({ contentType, setContentType }: Props) => {
  const updateContentType = (typeToUpdate: ContentType) => {
    setContentType(typeToUpdate);
  };

  const isDefaultCompanyContext = useLoginState().isDefaultBusinessContext();

  return (
    <Container>
      <ScrollableTabsTypeButton
        isSelected={contentType === "BUSINESS"}
        typeName={strings("dashboardScreen.contentType.businessProfiles")}
        onClick={() => updateContentType("BUSINESS")}
        typeKey={"BUSINESS"}
      />
      <ScrollableTabsTypeButton
        isSelected={contentType === "OFFER"}
        typeName={strings("dashboardScreen.contentType.offers")}
        onClick={() => updateContentType("OFFER")}
        typeKey={"OFFER"}
      />
      <ScrollableTabsTypeButton
        isSelected={contentType === "EVENT"}
        typeName={strings("dashboardScreen.contentType.events")}
        onClick={() => updateContentType("EVENT")}
        typeKey={"EVENT"}
      />
      <ScrollableTabsTypeButton
        isSelected={contentType === "POST"}
        typeName={strings("dashboardScreen.contentType.posts")}
        onClick={() => updateContentType("POST")}
        typeKey={"POST"}
      />
      {isDefaultCompanyContext && (
        <ScrollableTabsTypeButton
          isSelected={contentType === "NEWS"}
          typeName={strings("dashboardScreen.contentType.news")}
          onClick={() => updateContentType("NEWS")}
          typeKey={"NEWS"}
        />
      )}
    </Container>
  );
};

export default TypeSelectScrollableTabs;
