import React, { useCallback, useEffect, useState } from "react";
import styled from "style/styled-components";
import useChatState from "hooks/useChatState";
import useMessagePublisher from "components/chat/hooks/useMessagePublisher";
import {
  useLiveMessageHistory,
  useMessagesList,
} from "components/chat/hooks/useMessageHistory";
import useConversationStatus from "components/chat/hooks/useConversationStatus";
import MessagesList from "components/chat/messagesList/MessagesList";
import breakpoints from "style/breakpoints";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import { Prompt, useHistory } from "react-router";
import strings from "localisation/strings";
import useLocale from "common/hooks/useLocale";
import { usePullToRefreshPreventer } from "components/helpers/PullToRefreshPreventer";
import ChatMessagesFailedToLoadPlaceholder from "components/chat/ChatMessagesFailedToLoadPlaceholder";
import { ChatConnectionStatus } from "components/chat/types";
import CustomModal from "components/modal/CustomModal";
import useCustomModal from "components/modal/useCustomModal";
import ConversationHeader from "components/chat/ConversationHeader";
import useConversationStarter from "components/chat/hooks/useConversationStarter";
import QueryStatus from "common/api/models/QueryStatus";

const FullScreenChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  @media ${breakpoints.phoneMax} {
    position: fixed;
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const ChatMessagesListOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const getPlaceholderLabel = (
  isBusinessChatDisabled: boolean,
  status: ChatConnectionStatus,
  isSystemConversation?: boolean,
  ownerDeleted?: boolean,
) => {
  if (isSystemConversation) {
    return strings("chat.isSystemMessage");
  }
  if (ownerDeleted) {
    return strings("chat.ownerDeleted");
  }
  if (isBusinessChatDisabled) {
    return strings("chat.businessChatUnavailable");
  }
  if (status === ChatConnectionStatus.Connected) {
    return strings("chat.inputPlaceholder");
  }

  if (status === ChatConnectionStatus.Connecting) {
    return strings("chat.connectingPlaceholder");
  }

  return strings("chat.noConnectionPlaceholder");
};

interface StartedConversationProps {
  conversationId: string;
  businessId?: string;
}
const Conversation = ({
  conversationId,
  businessId,
}: StartedConversationProps) => {
  const [isNavigationBlocked, setNavigationBlocked] = useState(false);
  const pullToRefreshPreventer = usePullToRefreshPreventer();
  const history = useHistory();
  const messagesList = useMessagesList(conversationId, businessId);
  const liveMessagesHistory = useLiveMessageHistory(conversationId);

  const isMessagesInitialised =
    messagesList.historyStatus === QueryStatus.SUCCESSFUL;
  useEffect(() => {
    if (isMessagesInitialised) {
      liveMessagesHistory.subscribe();
      return liveMessagesHistory.unsubscribe;
    }
  }, [
    isMessagesInitialised,
    conversationId,
    businessId,
    liveMessagesHistory.subscribe,
    liveMessagesHistory.unsubscribe,
  ]);

  const {
    user,
    refreshKey,
    status: chatConnectionStatus,
    conversationsById,
    messagesByConversationId,
  } = useChatState();
  const messagePublisher = useMessagePublisher(conversationId as string);
  const { isDisabled } = useConversationStatus(conversationId);
  const locale = useLocale();
  const { isSystem, ownerDeleted } = conversationsById[conversationId];
  const effectivelyDisabled = isDisabled || ownerDeleted;

  const handleInputTextChanged = useCallback(
    (inputText: string) => setNavigationBlocked(!!inputText),
    [setNavigationBlocked],
  );

  const {
    closeModal,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    modalVisible,
  } = useCustomModal(history, isNavigationBlocked);

  useEffect(() => {
    pullToRefreshPreventer.disablePullToRefresh();
    return pullToRefreshPreventer.enablePullToRefresh;
  }, []);

  const ComponentByHistoryInitialisationStatus = {
    [QueryStatus.UNDETERMINED]: <AbsoluteOverlaySpinner />,
    [QueryStatus.WAITING]: <AbsoluteOverlaySpinner />,
    [QueryStatus.ERROR]: <ChatMessagesFailedToLoadPlaceholder />,
    [QueryStatus.SUCCESSFUL]: (
      <>
        <MessagesList
          locale={locale}
          placeholder={getPlaceholderLabel(
            effectivelyDisabled,
            chatConnectionStatus,
            isSystem,
            ownerDeleted,
          )}
          onInputTextChanged={handleInputTextChanged}
          isDisabled={effectivelyDisabled}
          messages={messagesByConversationId[conversationId]}
          messagesRefreshKey={refreshKey}
          onMessagesSent={messagePublisher.handleMessageSent}
          onQuickReplySent={messagePublisher.handleQuickReplySelected}
          onLoadEarlierMessagesRequested={messagesList.loadMoreMessages}
          isEarlierMessagesLoading={messagesList.isMoreMessagesLoading}
          isEarlierMessagesAvailable={
            messagesList.totalMessagesCount >
            messagesByConversationId[conversationId].length
          }
          user={user}
          isSystemConversation={isSystem}
        />

        <Prompt when={isNavigationBlocked} message={handleBlockedNavigation} />
        <CustomModal
          popupMessage={strings("chat.unsentMessageNotice")}
          modalVisible={modalVisible}
          onConfirm={handleConfirmNavigationClick}
          onCancel={closeModal}
        />
      </>
    ),
  };

  return (
    <ChatMessagesListOverlayContainer>
      {ComponentByHistoryInitialisationStatus[messagesList.historyStatus]}
    </ChatMessagesListOverlayContainer>
  );
};

interface ConversationStarterProps {
  conversationId: string;
  businessId?: string;
}

const ConversationStarter: React.FC<ConversationStarterProps> = ({
  conversationId,
  businessId,
}) => {
  const { status } = useConversationStarter(conversationId, businessId);

  const ComponentByStarterStatus = {
    [QueryStatus.UNDETERMINED]: <AbsoluteOverlaySpinner />,
    [QueryStatus.WAITING]: <AbsoluteOverlaySpinner />,
    [QueryStatus.ERROR]: <ChatMessagesFailedToLoadPlaceholder />,
    [QueryStatus.SUCCESSFUL]: (
      <Conversation conversationId={conversationId} businessId={businessId} />
    ),
  };

  return (
    <FullScreenChatContainer>
      <ConversationHeader conversationId={conversationId} />
      <ChatMessagesListOverlayContainer>
        {ComponentByStarterStatus[status]}
      </ChatMessagesListOverlayContainer>
    </FullScreenChatContainer>
  );
};

export default ConversationStarter;
