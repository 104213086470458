import { WizardStepFiveHeader } from "components/headers/WizardHeader";
import { CenteredContentViewContainer } from "components/generic";
import React from "react";
import { RouteComponentProps } from "react-router";
import BusinessSettingsAddPayment, {
  AddPaymentRouteProps,
} from "views/BusinessSettings/Payment/BusinessSettingsAddPayment";
import BusinessPaymentSettings, {
  PaymentRouteProps,
} from "views/BusinessSettings/Payment/BusinessSettingsPayment";
import { routeNames } from "core/navigation";
import { PaymentDataResponseDTO } from "api/models/Payments";
import BusinessSettingsConfirmPayment, {
  BusinessSettingsConfirmPaymentProps,
} from "views/BusinessSettings/Payment/BusinessSettingsConfirmPayment";
import strings from "localisation/strings";

export const PaymentInWizard = (props: RouteComponentProps) => {
  const { history } = props;
  const paymentRouteProps: PaymentRouteProps = {
    ...props,
    location: {
      ...props.location,
      state: {
        ...props.location.state,
        useSidebar: false,
        goBack: () => history.push(routeNames.WizardStep.AddPayment),
        handleAddPaymentMethodClicked: () =>
          history.push(routeNames.WizardStep.AddPayment),
        handleEditPaymentMethodClicked: (
          editedPaymentMethod: PaymentDataResponseDTO,
        ) => {
          history.push(routeNames.WizardStep.AddPayment, {
            methodType: editedPaymentMethod.paymentType,
            isBackAvailable: true,
          });
        },
      },
    },
  };
  return (
    <>
      <WizardStepFiveHeader />
      <CenteredContentViewContainer>
        <BusinessPaymentSettings {...paymentRouteProps} />
      </CenteredContentViewContainer>
    </>
  );
};

export const AddPaymentInWizard = (props: RouteComponentProps) => {
  const addPaymentRouteProps: AddPaymentRouteProps = {
    ...props,
    location: {
      ...props.location,
      state: {
        ...props.location.state,
        isBackAvailable: true,
        useSidebar: false,
        submitButtonText: strings("wizardScreen.payment.confirmation"),
        submitRedirect: routeNames.WizardStep.Completed,
        paymentConfirmationRedirect: routeNames.WizardStep.PaymentConfirmation,
        exitRedirect: routeNames.WizardStep.Fourth,
        isOnboarding: true,
      },
    },
  };
  return (
    <>
      <WizardStepFiveHeader />
      <CenteredContentViewContainer>
        <BusinessSettingsAddPayment {...addPaymentRouteProps} />
      </CenteredContentViewContainer>
    </>
  );
};

export const PaymentConfirmationInWizard = (props: RouteComponentProps) => {
  const { history } = props;
  const paymentConfirmationRouteProps: BusinessSettingsConfirmPaymentProps = {
    ...props,
    location: {
      ...props.location,
      state: {
        ...props.location.state,
        onNoMandate: () => history.push(routeNames.WizardStep.AddPayment),
        onSuccess: () => history.push(routeNames.WizardStep.Completed),
        useSidebar: false,
        isOnboarding: true,
      },
    },
  };
  return (
    <>
      <WizardStepFiveHeader />
      <CenteredContentViewContainer>
        <BusinessSettingsConfirmPayment {...paymentConfirmationRouteProps} />
      </CenteredContentViewContainer>
    </>
  );
};
