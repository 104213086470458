import { removePxUnit } from "components/helpers/styleUtilities";
import styled from "style/styled-components";
import theme from "style/theme";

interface SuggestionContainerProps {
  isActive?: boolean;
}

const SUGGESTION_CONTAINER_LEFT_RIGHT_PADDING = theme.margin.sideMin;

export const SuggestionContainer = styled.div<SuggestionContainerProps>`
  background-color: ${({ isActive, theme }) =>
    isActive
      ? theme.color.background.secondaryLight
      : theme.color.background.primary};
  padding: ${({ theme }) => theme.margin.medium}
    ${SUGGESTION_CONTAINER_LEFT_RIGHT_PADDING};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PinLocationOnMapSuggestionContainer = styled.div`
  padding-left: ${({ theme }) =>
    removePxUnit(theme.forms.leftSide) -
    removePxUnit(SUGGESTION_CONTAINER_LEFT_RIGHT_PADDING)}px;
`;

const SuggestionsDropdownContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border.inputBorder};
  border-radius: ${({ theme }) => theme.border.radius.small};
  width: calc(100% - ${({ theme }) => theme.forms.leftSide});
  background: ${({ theme }) => theme.color.background.primary};
  position: absolute;
  right: 0;
  top: 105%;
  z-index: 2;
`;

export default SuggestionsDropdownContainer;
