import React, { Component } from "react";
import { Arrow } from "components/generic";
import InlineInput from "components/forms/FormFields/InlineInput";
import styled from "style/styled-components";

interface DateInputProps {
  inputPlaceholder?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  onClick?: () => void;
}

const Container = styled.div`
  position: relative;
  flex-basis: 100%;
`;

const ArrowContainer = styled.span`
  position: absolute;
  right: 10px;
  top: -1px;
`;

const Wrapper = styled.span<{ width?: string }>`
  flex-basis: 100%;
  .react-datepicker-wrapper {
    display: flex;
    flex-basis: ${({ width = "100%" }) => width};
    flex-grow: 1;
    .react-datepicker__input-container {
      flex-basis: ${({ width = "100%" }) => width};
      flex-grow: 1;
    }
  }

  .react-datepicker-popper .react-datepicker {
    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__time-container .react-datepicker__header {
      display: none;
    }
  }
`;

// react-datepicker cannot use stateless component properly
class DateTimeField extends Component<DateInputProps> {
  render() {
    return (
      <Container>
        <InlineInput
          {...this.props}
          placeholder={this.props.inputPlaceholder}
          disabled={this.props.isDisabled}
        />
        <ArrowContainer
          onClick={!this.props.isDisabled ? this.props.onClick : undefined}
        >
          <Arrow />
        </ArrowContainer>
      </Container>
    );
  }
}

export { Wrapper };
export default DateTimeField;
