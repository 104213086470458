import styled, { css } from "style/styled-components";

interface ListWidthProps {
  isGridMode: boolean;
}

// (325 [grid item width] + 2 * 8 [item margins]) * 4 [max number of items in a row]
const GRID_MAX_WIDTH = css`
  max-width: calc((${({ theme }) => theme.grid.itemWidth} + 2px * 8) * 4);
`;

const ListWidth = styled.div<ListWidthProps>`
  width: 100%;
  ${({ theme, isGridMode }) =>
    isGridMode ? GRID_MAX_WIDTH : `max-width: ${theme.content.sizes.large}`};
`;

export const ListPositioner = styled.div`
  margin: ${({ theme }) => theme.margin.large};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ListWidth;
