import { useState, useEffect, useRef } from "react";
import RequestOptions from "api/RequestOptions";
import EmptyHeaders from "common/api/EmptyHeaders";
import AbortController from "api/AbortController";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import APIError from "common/api/models/APIError";
import createUnknownError from "common/api/createUnknownError";
import QueryStatus from "common/api/models/QueryStatus";

export type MutationRequest<PayloadType, ResultType> = (
  payload: PayloadType,
  options: RequestOptions,
) => Promise<{ result: ResultType; headers: Headers }>;

interface MutationResult<T = any> {
  result?: T;
  error?: APIErrorResponse;
  headers: Headers | EmptyHeaders;
  status: QueryStatus;
}

const emptyHeaders: EmptyHeaders = {
  get: () => null,
};

const defaultResult: MutationResult = {
  result: undefined,
  error: undefined,
  headers: emptyHeaders,
  status: QueryStatus.UNDETERMINED,
};

/**
 * useMutation adds abort controller for cleanup on onmount,
 * statuses and default result.
 * It returns the function makeRequest (with abort controller) to
 * make the request and the result.
 */
const useMutation = <PayloadType, ResultType>(
  request: MutationRequest<PayloadType, ResultType>,
) => {
  const [mutationResult, setMutationResult] = useState<
    MutationResult<ResultType>
  >(defaultResult);
  const mounted = useRef<boolean>(true);
  let controller = new AbortController();

  const makeRequest = async (
    payload: PayloadType,
  ): Promise<
    | {
        result?: ResultType;
        headers?: Headers | EmptyHeaders;
        error?: APIErrorResponse;
      }
    | undefined
  > => {
    setMutationResult({
      ...defaultResult,
      status: QueryStatus.WAITING,
    });
    controller.abort();
    controller = new AbortController();
    try {
      const { result, headers } = await request(payload, {
        signal: controller.signal,
      });
      if (mounted.current) {
        setMutationResult({
          result,
          headers,
          error: undefined,
          status: QueryStatus.SUCCESSFUL,
        });
      }
      return { result, headers };
    } catch (err) {
      if (!mounted.current) {
        return;
      }
      if (err instanceof APIError) {
        setMutationResult({
          ...mutationResult,
          error: err.errorBody,
          status: QueryStatus.ERROR,
        });
      } else {
        setMutationResult({
          ...mutationResult,
          error: createUnknownError(err).errorBody,
          status: QueryStatus.ERROR,
        });
      }

      return {
        result: mutationResult.result,
        headers: mutationResult.headers,
        error: { ...err.errorBody, status: QueryStatus.ERROR },
      };
    }
  };

  // Aborts the request on unmount.
  useEffect(() => {
    return () => {
      mounted.current = false;
      controller.abort();
    };
    // Disable controller to be required in this useEffect
    // This useEffect should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { makeRequest, ...mutationResult };
};

export default useMutation;
