import React, { useEffect, useRef, useState } from "react";
import { curry } from "ramda";
import strings from "localisation/strings";
import { getDateLabel } from "utils";

import EntityTypes from "api/models/EntityTypes";
import PublicationStatuses from "api/models/PublicationStatuses";
import OfferInterface from "api/models/OfferInterface";

import DescriptionBlock from "components/forms//DescriptionBlock";
import TitleInput from "components/forms/ValidatedFields/TitleInput";
import TagSelectionField from "components/forms/TagSelection/TagSelectionField";
import { Title, VerticalLargeSpacer } from "components/generic";
import { getActionArea } from "components/helpers/formHelpers";
import {
  defaultOffersFields,
  getOffersPayload,
  setOffersFields,
} from "components/helpers/formHelpers/offer";
import {
  DiscountPrices,
  OfferTypeOptions,
  SaveStatusDisplay,
  Separator,
  SettingsArea,
  TextWithIcon,
} from "components/forms/FormFields";
import DateTimeRangePicker from "components/forms/ValidatedFields/DateTimeRangePicker";
import ReviewsAndRatings from "components/review/ReviewsAndRatings";
import {
  deleteReplyToReviewForOffer,
  deleteReplyToReviewForOfferAdmin,
  getReviewsForOffer,
  getReviewsForOfferAdmin,
  saveReplyToReviewForOffer,
  saveReplyToReviewForOfferAdmin,
} from "api/offers";
import AddressPicker from "components/forms/FormFields/AddressAutocomplete/AddressPicker";
import InFrameContentFields from "components/forms/FormFields/InFrameContentFields";
import PictureGallery from "components/forms/FormFields/PictureGallery";
import zooIcon from "assets/icons/zoo.svg";
import date from "common/assets/icons/date.svg";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import Checkbox from "components/forms/Checkbox";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

import useFormFields from "hooks/useFormFields";
import useLoginState from "hooks/useLoginState";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import ImageUploadType from "api/models/ImageUploadType";
import useMutation from "common/hooks/useMutation";
import { useLocation } from "react-router-dom";
import { COPY } from "constants/strings";
import {
  getAdditionalSettings,
  getAdditionalSettingsAdmin,
  getAdditionalSettingsCurator,
} from "api/businessProfiles";
import useQuery from "common/hooks/useQuery";
import HashtagsField from "components/forms/HashtagsField";
import { useTagRaceConditionCatcher } from "hooks/useTagRaceConditionCatcher";
import { getCuratedMaps } from "api/users";

// The max-height has aspect ratio scaling applied to keep margins between components same as before image box rescaling
const ImageBox = styled.div`
  max-height: ${({ theme }) =>
    500 + 500 * ((100 - theme.image.aspectRatio) / 100)}px;

  /*
    IE10+ CSS to fix an IE bug that causes flex box overflow.
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 0 auto;
  }

  @media ${breakpoints.tabletMax} {
    max-height: inherit;
  }
`;

const OfferForm = ({
  isNew,
  createNew,
  createDraft,
  success,
  result,
  changedValues,
  setBlockNavigation,
}: {
  isNew: boolean;
  createNew: any;
  createDraft: any;
  success: (message: string, response: OfferInterface) => void;
  result?: Partial<OfferInterface>;
  changedValues: Set<string>;
  setBlockNavigation: (shouldBlockNavigation: boolean) => void;
}) => {
  const {
    hasAdminRights,
    hasCuratorRights,
    getBusinessIdForNewContent,
  } = useLoginState();
  const adminRights = hasAdminRights();
  const curatorRights = hasCuratorRights();
  const [oldContent, setOldContent] = useState<Partial<OfferInterface>>();
  const [isSavingBlocked, setIsSavingBlocked] = useState<boolean>(false);
  const businessId: string = getBusinessIdForNewContent()!;
  const {
    isValid,
    getErrors,
    parseErrors,
    clearErrors,
    values,
    updateValues,
    getTextAreaFieldProps,
    getTagFieldProps,
    getImageListProps,
    getTextFieldProps,
    getPriceGroupFieldProps,
    getOfferTypeFieldProps,
    getDateTimeRangeFieldProps,
    getSettingsFieldProps,
    getLocationAddressFieldProps,
    getPinnedLocationAddressDescriptionFieldProps,
    getCheckboxFieldProps,
    getHashtagsFieldProps,
    onChange,
  } = useFormFields({
    ...defaultOffersFields,
    changedValues,
    setBlockNavigation,
    handleGenericErrors: true,
    translationScope: "postsScreen.fields",
  });
  const updateValuesRef = useRef(updateValues);
  const additionalSettingsRequest =
    adminRights || curatorRights
      ? curry(
          adminRights
            ? getAdditionalSettingsAdmin
            : getAdditionalSettingsCurator,
        )(businessId!)
      : getAdditionalSettings;
  const { result: additionalSettings } = useQuery({
    request: additionalSettingsRequest,
  });

  let id;
  let enableComments;
  let enableRatings;
  let enableProminentShoppingUrl = false;
  if (result) {
    id = result.id;
    enableComments = result.enableComments;
    enableRatings = result.enableRatings;
    enableProminentShoppingUrl = !!result.prominentShoppingEnabled;
  }

  if (isNew) {
    enableProminentShoppingUrl = !!(
      additionalSettings && additionalSettings.enableProminentShopping
    );
  }

  let getReviewsRequest;
  let saveReviewReplyRequest;
  let deleteReviewReplyRequest;
  if (id) {
    if (adminRights) {
      getReviewsRequest = curry(getReviewsForOfferAdmin)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForOfferAdmin)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForOfferAdmin)(id);
    } else {
      getReviewsRequest = curry(getReviewsForOffer)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForOffer)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForOffer)(id);
    }
  }

  useEffect(() => {
    if (result) {
      setOldContent(result);
      const updatedFormFields = setOffersFields(result);
      updateValuesRef.current(updatedFormFields);
    }
  }, [result]);
  const location = useLocation();
  const isCopied = location.pathname.endsWith(COPY);

  const disableTypeChange =
    !isNew && result ? result.status !== PublicationStatuses.draft : false;

  const { makeRequest } = useMutation(createNew);
  const { onRequestFinished, filterFieldErrors } = useTagRaceConditionCatcher();

  useEffect(() => {
    if (isCopied) return;

    // this makes a publish request on component mount to get all required fields
    makeRequest({
      ...values,
      tagIds: [],
      id: "",
      createdDateTime: "",
      status: PublicationStatuses.published,
      hideFrameConfirmation: true,
    }).then(e => {
      if (e && e.error) {
        parseErrors({
          ...e.error,
          detail: undefined,
          fieldsErrors: filterFieldErrors(e.error),
        });
      }
    });
  }, [location]);

  return (
    <>
      {!isNew && result && result.status && (
        <SaveStatusDisplay
          status={result.status}
          modifiedDate={result.modifiedDateTime || null}
        />
      )}
      <TitleInput {...getTextAreaFieldProps("title")} focusOnOpen />
      <TagSelectionField
        {...getTagFieldProps("tags")}
        fieldErrors={getErrors("tagIds")}
        type={EntityTypes.offers}
        setIsSavingBlocked={setIsSavingBlocked}
        isNew={isNew}
        isCopied={isCopied}
        businessId={businessId}
        onTagsLoaded={onRequestFinished}
      />

      <HashtagsField {...getHashtagsFieldProps("hashtags")} />

      <ImageBox>
        <PictureGallery
          {...getImageListProps()}
          container={ImageUploadType.OFFERS}
          smallPictureContainer={ImageUploadType.OFFERS}
          height="330px"
          parseErrors={parseErrors}
          updateKey={result ? result.modifiedDateTime : undefined}
        />
      </ImageBox>
      <TextWithIcon
        {...getTextFieldProps("venue", "location.venue")}
        placeholder={strings("detailedScreen.location.venue")}
        errors={getErrors("location.venue")}
        optional
        icon={zooIcon}
      />
      <AddressPicker
        autocompleteProps={getLocationAddressFieldProps()}
        pinnedDescriptionFieldProps={getPinnedLocationAddressDescriptionFieldProps(
          "locationAddress",
          "location",
        )}
        location={result ? result.location : undefined}
      />
      <DateTimeRangePicker
        {...getDateTimeRangeFieldProps("dateTimeRange")}
        canSelectInPast={!isNew}
      />
      <OfferTypeOptions
        {...getOfferTypeFieldProps()}
        prominentShoppingEnabled={enableProminentShoppingUrl}
        disabled={disableTypeChange}
      />
      <DiscountPrices {...getPriceGroupFieldProps()} />
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_OFFERS_ADMIN}>
        <InFrameContentFields
          textFieldProps={getTextFieldProps("inFrameUrl")}
          frameUrlErrors={getErrors("inFrameUrl")}
        />
      </PrivilegedComponent>
      <VerticalLargeSpacer />
      <DescriptionBlock {...getTextAreaFieldProps("description")} richText />
      <DescriptionBlock
        {...getTextAreaFieldProps("legalText")}
        label={strings("detailedScreen.legalText.label")}
        placeholder={strings("detailedScreen.legalText.placeholder")}
        maxLength={500}
        richText
      />
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_OFFERS_ADMIN}>
        <Separator />
        <Title>{strings("offersScreen.fields.publishAs.title")}</Title>
        <InlineInputGroup icon={date} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("topicOfTheWeek")}
            dateLabel={getDateLabel(result)}
          />
        </InlineInputGroup>
      </PrivilegedComponent>
      <Separator />
      {id &&
        getReviewsRequest &&
        saveReviewReplyRequest &&
        deleteReviewReplyRequest && (
          <ReviewsAndRatings
            id={id}
            getRequest={getReviewsRequest}
            saveReviewReply={saveReviewReplyRequest}
            deleteReviewReply={deleteReviewReplyRequest}
            enableComments={enableComments}
            enableRatings={enableRatings}
          />
        )}
      <SettingsArea {...getSettingsFieldProps()} />
      {getActionArea({
        values,
        isNew,
        clearErrors,
        parseErrors,
        oldContent,
        isSavingBlocked,
        isSubmitDisabled: !isValid,
        setShouldBlockNavigation: setBlockNavigation,
        successCallback: success,
        content: result,
        createDraftItem: createDraft,
        createNewItem: createNew,
        getContentPayload: getOffersPayload,
      })}
    </>
  );
};

export default OfferForm;
