import React, { ReactNode, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { assocPath, head, isEmpty, path, prop } from "ramda";

import usePagination from "common/hooks/usePagination";
import { getTotalItemsCount, getTotalPagesFromHeaders, isFailed } from "utils";
import ListResultType from "api/models/ListResultType";
import Filters from "api/models/Filters";
import SortingInterface from "api/models/SortingInterface";

import ListHeader from "components/lists/header/ListHeader";
import { Media } from "components/generic";
import Sidebar from "components/sidebar/Sidebar";
import SortScreen from "components/lists/filtering/SortScreen";
import SearchScreen from "components/lists/filtering/SearchScreen";
import FilterScreen from "components/lists/filtering/FilterScreen";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import { SearchRequest } from "components/lists/ListRequestTypes";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import ListWidth, { ListPositioner } from "components/lists/ListWidth";
import PhoneSortAndFilterHeader from "components/lists/header/Search/PhoneSortAndFilterHeader";
import Pagination from "components/pagination/Pagination";

import { countFilters } from "common/components/filtering/filterCountUtils";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import SortAndFilterHeader from "components/lists/header/SortAndFilterHeader";
import emojiDataState from "state/singletons/emojiDataState";
import { getEmojiData } from "api/imageStorage";
import useNotificationState from "hooks/useNotification";
import Testable from "testing/Testable";

interface FilterableListProps<T> extends RouteComponentProps, Testable {
  hideFilter?: boolean;
  hideSort?: boolean;
  pageTitle: string;
  filterPageTitle: string;
  sortPageTitle: string;
  searchPageTitle?: string;
  onItemClick: (id: string) => void;
  listRequest?: SearchRequest<ListResultType<T>>;
  searchRequest: SearchRequest<ListResultType<T>>;
  redirectOnEmptyResult?: boolean;
  postFiltersRequest: FiltersRequestType;
  renderGridView?: (props: InteractiveListInterface<T>) => ReactNode;
  renderListView: (props: InteractiveListInterface<T>) => ReactNode;
  onAddNew: () => void;
  defaultSort: SortingInterface;
}

const FilterableList = <T,>({
  pageTitle,
  filterPageTitle,
  sortPageTitle,
  searchPageTitle,
  location,
  hideFilter,
  hideSort,
  history,
  onItemClick,
  searchRequest,
  redirectOnEmptyResult,
  onAddNew,
  postFiltersRequest,
  renderListView,
  defaultSort,
  ...props
}: FilterableListProps<T>) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [isFilteringActive, setFilteringActive] = useState<boolean>(false);
  const [isSortingActive, setSortingActive] = useState<boolean>(false);
  const [isSearchActive, setSearchActive] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    offset,
    limit,
    page,
    updatePage,
    resetPagination,
    onPageSizeChange,
  } = usePagination();

  const toggleFiltering = () => setFilteringActive(!isFilteringActive);
  const toggleSorting = () => setSortingActive(!isSortingActive);
  const toggleSearch = () => setSearchActive(!isSearchActive);

  const confirmFilters = (filtersToApply: Partial<Filters>) => {
    setFilteringActive(false);
    if (filtersToApply !== appliedFilters) {
      resetPagination();
    }
    history.push({
      ...history.location,
      state: assocPath(
        ["filterReq", "filters"],
        filtersToApply,
        history.location.state,
      ),
    });
  };

  const confirmSort = (sortToApply: SortingInterface | object) => {
    setSortingActive(false);
    history.push({
      ...history.location,
      state: assocPath(
        ["filterReq", "sort"],
        sortToApply,
        history.location.state,
      ),
    });
  };

  const onSearch = (query: string) => {
    resetPagination(true);
    setSearchQuery(query);
  };
  const appliedFilters: Partial<Filters> =
    path(["state", "filterReq", "filters"], location) || {};

  const appliedSort: SortingInterface =
    path(["state", "filterReq", "sort"], location) || defaultSort;

  const appliedSortRule = head(prop("sortRules", appliedSort));

  const mainReq = searchRequest({
    offset,
    limit,
    body: { filters: appliedFilters, sort: appliedSort },
    q: searchQuery,
  });

  const { result, headers, status, error } = useQuery<ListResultType<T>>({
    request: mainReq,
    compare: [offset, page, limit, appliedSort, appliedFilters, searchQuery],
  });

  const { addErrorNotification } = useNotificationState();
  useEffect(() => {
    if (isFailed(status)) {
      addErrorNotification(error && error.detail);
    }
  }, [status]);

  const items = result && result.data ? result.data : [];

  // special handing for redirect when the result is empty
  useEffect(() => {
    if (
      redirectOnEmptyResult &&
      status === QueryStatus.SUCCESSFUL &&
      (!items || !items.length) &&
      isEmpty(appliedFilters) &&
      !searchQuery
    ) {
      if (initialLoad) setInitialLoad(false);
      onAddNew();
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, items.length]);

  // set initialLoad value to false once first requests completes succesfully
  useEffect(() => {
    if (status === QueryStatus.SUCCESSFUL) {
      if (initialLoad) setInitialLoad(false);
    }
  }, [status, initialLoad]);

  const totalNumberOfPages = getTotalPagesFromHeaders(headers, limit);
  const totalItems = getTotalItemsCount(headers);

  const sortOptions = result && result.sort ? result.sort : { sortRules: [] };
  const sortName = (appliedSortRule && prop("name", appliedSortRule)) || "";

  const {
    result: emojiDataFetchResult,
    status: emojiDataFetchStatus,
  } = useQuery({ request: getEmojiData });
  useEffect(() => {
    if (emojiDataFetchStatus === QueryStatus.SUCCESSFUL) {
      emojiDataState.setEmojiData(emojiDataFetchResult);
    }
  }, [emojiDataFetchStatus]);

  // don't render anything during initial load
  if (initialLoad) return null;

  return (
    <>
      <Media tablet phone>
        <PhoneSortAndFilterHeader
          hideFilter={hideFilter}
          hideSort={hideSort}
          filterCount={countFilters(appliedFilters)}
          toggleFiltering={toggleFiltering}
          toggleSorting={toggleSorting}
          toggleSearch={toggleSearch}
          sortName={sortName}
          onAddNew={onAddNew}
        />
      </Media>
      <ListPositioner {...props}>
        <ListWidth isGridMode={false}>
          <ListHeader title={pageTitle}>
            <SortAndFilterHeader
              hideFilter={hideFilter}
              hideSort={hideSort}
              filterCount={countFilters(appliedFilters)}
              onSearch={onSearch}
              toggleFiltering={toggleFiltering}
              toggleSorting={toggleSorting}
              toggleGridView={() => {}}
              onAddNew={onAddNew}
              gridViewActive={false}
              sortName={sortName}
            />
          </ListHeader>

          <Media desktop phone tablet>
            {status === QueryStatus.SUCCESSFUL &&
              renderListView({ items, onItemClick })}
          </Media>

          <Pagination
            onPageChange={updatePage}
            onPageSizeChange={onPageSizeChange}
            page={page}
            limit={limit}
            totalNumberOfPages={totalNumberOfPages}
            totalItems={totalItems}
          />
        </ListWidth>
      </ListPositioner>
      <Sidebar
        isActive={isFilteringActive}
        toggleActive={toggleFiltering}
        title={filterPageTitle}
      >
        <FilterScreen
          postFiltersRequest={postFiltersRequest}
          onConfirm={confirmFilters}
          appliedFilters={appliedFilters}
        />
      </Sidebar>
      <Sidebar
        isActive={isSortingActive}
        toggleActive={toggleSorting}
        title={sortPageTitle}
      >
        <SortScreen
          onConfirm={confirmSort}
          appliedSort={appliedSort}
          sortOptions={sortOptions}
          status={status}
        />
      </Sidebar>
      <Sidebar
        isActive={isSearchActive}
        toggleActive={toggleSearch}
        title={searchPageTitle}
      >
        <SearchScreen
          onConfirm={onSearch}
          query={searchQuery}
          toggleSearch={toggleSearch}
        />
      </Sidebar>
    </>
  );
};

export default FilterableList;
