import React from "react";

export const emailRegex = /^[A-Z0-9!#$%&‘*+-/=?^_`.{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}/i;

export const textRules = {
  // Contains at least one uppercase character
  uppercase: /(?=.*[A-Z\p{Lu}])/u,
  // Contains at least one lowercase character
  lowercase: /(?=.*[a-z\p{Ll}])/u,
  // Contains at least one digit
  digit: /(?=.*[0-9])/,
  // Contains at least one special character
  special: /(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
  // Contains special character(s) which is not password special characters
  // From https://www.owasp.org/index.php/Password_special_characters
  notAllowed: /[^ !"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~A-Za-z0-9\p{L}]/u,
};

const validateTextLength = ({
  text,
  minLength = 1,
  maxLength = Infinity,
}: {
  text: string;
  minLength?: number;
  maxLength?: number;
}) => minLength <= text.length && text.length <= maxLength;

const validateNumberRange = ({
  value,
  min = 0,
  max = Infinity,
  integer = false,
}: {
  value: number | string;
  min?: number;
  max?: number;
  integer?: boolean;
}) => {
  const numberValue = Number(value);
  return (
    min <= numberValue &&
    numberValue <= max &&
    (integer && numberValue
      ? numberValue === parseInt(String(value), 10)
      : true)
  );
};

const getPasswordRulesStatus = (password: string) => ({
  uppercase: textRules.uppercase.test(password),
  lowercase: textRules.lowercase.test(password),
  digit: textRules.digit.test(password),
  special: textRules.special.test(password),
  notAllowed: textRules.notAllowed.test(password),
});

const validatePassword = (password: string) => {
  const { uppercase, digit, special, notAllowed } = getPasswordRulesStatus(
    password,
  );
  return (
    validateTextLength({ text: password, minLength: 8, maxLength: 30 }) &&
    (uppercase || digit || special) &&
    !notAllowed
  );
};

const disableArrowKeys = (event: React.KeyboardEvent) => {
  if (event.keyCode && [37, 38, 39, 40].indexOf(event.keyCode) !== -1) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export {
  validateNumberRange,
  validateTextLength,
  getPasswordRulesStatus,
  validatePassword,
  disableArrowKeys,
};
