import React, { useMemo } from "react";
import MapInterface from "api/models/MapInterface";
import { getActiveMapsList } from "api/resources";
import useQuery from "common/hooks/useQuery";
import { MapAllowancePayload, MapCurationPayload } from "api/models/Plan";
import MapsDropdown from "components/forms/FormFields/MapsDropdown";
import QueryStatus from "common/api/models/QueryStatus";

interface MapsSelectListProps {
  entityId?: string;
  values: any[];
  allowedMaps?: MapAllowancePayload[] | MapCurationPayload[];
  fieldName: string;
  width?: string;
  style?: string;
  onChange: (selectedField: any, selectedValue: any) => void;
}

const MapsSelectList = ({
  entityId,
  values,
  allowedMaps,
  fieldName,
  width,
  style,
  onChange,
}: MapsSelectListProps) => {
  const allMapsResponse = useQuery({
    request: getActiveMapsList,
  });
  const allMaps = allMapsResponse.result?.maps ?? [];

  const getMapById = useMemo(() => {
    const mapById = (id: string) => allMaps.find(map => map.id === id);
    return mapById;
  }, [allMaps]);

  const filteredMaps = useMemo(() => {
    if (allowedMaps !== undefined) {
      return allowedMaps
        .map(({ mapId }) => getMapById(mapId))
        .filter((map): map is MapInterface => map !== undefined);
    }
    return allMaps;
  }, [allowedMaps, allMaps, getMapById]);

  if (allMapsResponse.status === QueryStatus.WAITING) {
    return null;
  }

  return (
    <MapsDropdown
      allMaps={filteredMaps}
      entityId={entityId}
      values={values}
      fieldName={fieldName}
      width={width}
      style={style}
      getMapById={getMapById}
      onChange={onChange}
    />
  );
};

export default MapsSelectList;
