import React from "react";
import useLoginState from "hooks/useLoginState";
import BusinessSettingsPlanSelectionAdmin from "views/BusinessSettings/PlanSelection/BusinessSettingsPlanSelectionAdmin";

const BusinessSettingsPlanSelectionAdminEntryPoint = () => {
  const businessId = useLoginState().getBusinessId();

  if (!businessId) {
    return null;
  }

  return <BusinessSettingsPlanSelectionAdmin businessId={businessId} />;
};

export default BusinessSettingsPlanSelectionAdminEntryPoint;
