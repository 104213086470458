import React, { Component } from "react";
import {
  SubCheckboxes,
  DropdownWindow,
} from "components/forms/CategoryDropdown/components";
import DropdownCheckbox from "components/forms/CategoryDropdown/DropdownCheckbox";
import TagInterface from "api/models/TagInterface";
import { removeFromList } from "components/forms/CategoryDropdown/helpers";

interface Props {
  isOpened: boolean;
  toggleCheckbox: (option: TagInterface) => void;
  isChecked: (id: string) => boolean;
  setCategories?: (options: any[]) => void;
  type: string;
  isPartiallySelected: (option: TagInterface) => boolean;
  options?: TagInterface[];
}

interface State {
  openedOptions: string[];
  categories: TagInterface[];
}

class CategoryDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openedOptions: [],
      categories: props.options || [],
    };
  }

  isOpenedOption = (id: string) => this.state.openedOptions.includes(id);

  toggleTree = (id: string, openedOptions: string[]) => {
    if (openedOptions.includes(id)) {
      // isOpened
      removeFromList(openedOptions, id);

      return openedOptions;
    }

    // !isOpened
    return [...openedOptions, id];
  };

  getArrowDirection = (id: string): string =>
    this.isOpenedOption(id) ? "down" : "right";

  toggleOpenTree = (id: string): void => {
    const { openedOptions } = this.state;

    this.setState({
      openedOptions: this.toggleTree(id, openedOptions),
    });
  };

  getCheckbox = (option: TagInterface, key: number) => {
    const { id } = option;
    const { toggleCheckbox, isChecked, isPartiallySelected } = this.props;
    return (
      <DropdownCheckbox
        option={option}
        key={key}
        toggleCheckbox={() => toggleCheckbox(option)}
        toggleOpen={() => this.toggleOpenTree(id)}
        arrowDirection={this.getArrowDirection(id)}
        partiallySelected={isPartiallySelected(option)}
        isChecked={isChecked(id)}
      />
    );
  };

  getSubCheckbox = (category: TagInterface) => {
    if (!category.tagSuccessors) return null;

    return (
      <SubCheckboxes isOpened={this.isOpenedOption(category.id)}>
        {category.tagSuccessors.map((c: TagInterface, k: number) =>
          this.getCheckbox(c, k),
        )}
      </SubCheckboxes>
    );
  };

  render() {
    const { categories } = this.state;
    const { isOpened } = this.props;

    if (!categories) {
      return null;
    }

    return (
      <DropdownWindow isOpened={isOpened}>
        {categories.map((c: TagInterface, k: number) => (
          <React.Fragment key={c.id}>
            {this.getCheckbox(c, k)}
            {this.getSubCheckbox(c)}
          </React.Fragment>
        ))}
      </DropdownWindow>
    );
  }
}

export default CategoryDropdown;
