import React, { Fragment } from "react";
import styled, { css } from "style/styled-components";
import { takeLast } from "ramda";
import DropdownArrow from "components/generic/DropdownArrow";

const PageSelectionContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: ${({ theme }) => theme.margin.large};
`;

interface PageButtonProps {
  active?: boolean;
  disabled?: boolean;
}

const activeButtonStyle = css`
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.color.foreground.secondaryDark};
  border-radius: ${({ theme }) => theme.border.radius.small};
`;

const disabledButtonStyle = css`
  cursor: auto;
  opacity: 0.3;
`;

const PageButton = styled.button<PageButtonProps>`
  padding: ${({ theme }) => theme.margin.medium};
  font-size: ${({ theme }) => theme.font.size.pagination};
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: baseline;
  cursor: pointer;

  ${({ disabled }) => (disabled ? disabledButtonStyle : "")}
  ${({ active }) => (active ? activeButtonStyle : "")}
`;

const Separator = styled.div`
  margin: ${({ theme }) => theme.margin.medium};
`;

export interface PageSelectionProps {
  page: number;
  beginning: number[];
  end: number[];
  totalNumberOfPages: number;
  onPageChange: (page: number) => void;
}

const PageSelection = ({
  page,
  totalNumberOfPages,
  onPageChange,
  beginning,
  end,
}: PageSelectionProps) => {
  return (
    <PageSelectionContainer>
      <PageButton
        onClick={() => page > 1 && onPageChange(page - 1)}
        disabled={page === 1}
      >
        <DropdownArrow direction="left" />
      </PageButton>
      <PageButton onClick={() => onPageChange(1)} active={1 === page}>
        {1}
      </PageButton>
      {beginning.map((value, index) => (
        <Fragment key={`page-button-${index}`}>
          {index === 0 && value > 2 && <Separator>...</Separator>}
          <PageButton
            onClick={() => onPageChange(value)}
            key={value}
            active={value === page}
          >
            {value}
          </PageButton>
        </Fragment>
      ))}
      {end.length > 1 && <Separator>...</Separator>}
      {takeLast(1, end).map(value => (
        <PageButton
          onClick={() => onPageChange(value)}
          key={value}
          active={value === page}
        >
          {value}
        </PageButton>
      ))}
      <PageButton
        onClick={() => page < totalNumberOfPages && onPageChange(page + 1)}
        disabled={page === totalNumberOfPages}
      >
        <DropdownArrow direction="right" />
      </PageButton>
    </PageSelectionContainer>
  );
};

export default PageSelection;
