import { last, range } from "ramda";

const getPaginationParameters = (page: number, totalNumberOfPages: number) => {
  // if page 1 or two start range from 2 otherwise from previous page
  let rangeStart;
  switch (page) {
    case 1:
      rangeStart = 2;
      break;
    case 2:
      rangeStart = 2;
      break;
    default:
      rangeStart = page - 1;
      break;
  }

  let beginning = range(
    rangeStart,
    page + 2 > totalNumberOfPages ? page + 1 : page + 2,
  );
  // if current page is page one show pages 2 at the beginning
  if (page === 1) {
    const rengeEnd = totalNumberOfPages < 3 ? 2 : 3;
    beginning = range(2, rengeEnd);
  }
  // if only one page no need to show anything as page one always visible
  if (totalNumberOfPages === 1) {
    beginning = [];

    return { beginning: [], end: [] };
  }
  // get all pages except ones in th beginning
  const end = range((last(beginning) || 1) + 1, totalNumberOfPages + 1);
  return { beginning, end };
};

export { getPaginationParameters };
