import styled from "style/styled-components";

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => `${theme.margin.xlarge} 0 ${theme.margin.medium}`};
  align-items: center;
`;

export default HeaderArea;
