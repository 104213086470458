import React from "react";
import strings from "localisation/strings";
import { CenteredText } from "components/generic";
import { InlineTextButton } from "components/buttons/TextButton";
import { openInSameTab } from "utils";
import styled from "style/styled-components";
import { partnerPageLink, partnerPageLinkText } from "core/constants";

const space = " ";

const LinkButtonContainer = styled(CenteredText)`
  margin-top: ${({ theme }) => theme.margin.large};
`;

interface TextualLinkProps {
  directionText: string;
  linkText: string;
  onClick: () => void;
}

const TextualLink = ({
  directionText,
  linkText,
  onClick,
}: TextualLinkProps) => (
  <LinkButtonContainer>
    {directionText}
    {space}
    <InlineTextButton onClick={onClick} text={linkText} />
  </LinkButtonContainer>
);

const BackToBliggitButton = () => {
  const handlePartnerPageLinkClicked = () => {
    openInSameTab(partnerPageLink);
  };

  return (
    <TextualLink
      linkText={partnerPageLinkText}
      directionText={strings("buttons.backTo")}
      onClick={handlePartnerPageLinkClicked}
    />
  );
};

export default BackToBliggitButton;
