import loginState from "state/singletons/loginState";
import { getRefreshedToken } from "api/session";
import API from "api/API";
import RequestParameters from "api/RequestParameters";
import PostRequestParameters from "api/PostRequestParameters";
import { addAuthorizationHeader, FileResultType } from "api/APIHelpers";

const sessionState = {
  isRefreshing: false,
  refreshPromise: Promise.resolve(),
};

export const tryRefreshing = async () => {
  sessionState.isRefreshing = true;
  try {
    const { serviceToken } = loginState.getState();
    const refreshResponse = await getRefreshedToken({ jwt: serviceToken });
    await loginState.refresh(refreshResponse.result);
    sessionState.isRefreshing = false;
  } catch (err) {
    await loginState.logOut();
    sessionState.isRefreshing = false;
    throw err;
  }
};

export default class AuthedAPI extends API {
  async getJson<ResultType>({
    path,
    queryParams = {},
    options = {},
  }: RequestParameters): Promise<{
    result: ResultType;
    headers: Headers;
  }> {
    try {
      if (sessionState.isRefreshing) {
        await sessionState.refreshPromise;
      }
      await loginState.isRestored;
      return await super.getJson({
        path,
        queryParams,
        options: loginState.isUserLoggedIn()
          ? addAuthorizationHeader({
              options,
              tokens: loginState.getState(),
            })
          : options,
      });
    } catch (originalError) {
      if (originalError.status === 401 && loginState.isUserLoggedIn()) {
        if (!sessionState.isRefreshing) {
          sessionState.refreshPromise = tryRefreshing();
        }
        await sessionState.refreshPromise;
        return await this.getJson({
          path,
          queryParams,
          options,
        });
      }

      throw originalError;
    }
  }

  async download({
    path,
    body = {},
    queryParams = {},
    options = {},
    defaultFileName,
  }: PostRequestParameters): Promise<{
    result: FileResultType;
    headers: Headers;
  }> {
    try {
      if (sessionState.isRefreshing) {
        await sessionState.refreshPromise;
      }
      await loginState.isRestored;
      return await super.download({
        path,
        body,
        queryParams,
        defaultFileName,
        options: loginState.isUserLoggedIn()
          ? addAuthorizationHeader({
              options,
              tokens: loginState.getState(),
            })
          : options,
      });
    } catch (originalError) {
      if (originalError.status === 401 && loginState.isUserLoggedIn()) {
        if (!sessionState.isRefreshing) {
          sessionState.refreshPromise = tryRefreshing();
        }
        await sessionState.refreshPromise;
        return await this.download({
          path,
          body,
          queryParams,
          options,
        });
      }

      throw originalError;
    }
  }
}
