import React, { ChangeEvent, Component } from "react";
import EntityTypes from "api/models/EntityTypes";
import Filters from "api/models/Filters";
import { Filter } from "components/table/DataTable/interfaces";
import { filterTypes } from "components/table/DataTable/constants";
import styled from "style/styled-components";
import { SelectList, CategoriesList } from "components/table/DataTable/Fields";
import Search from "components/lists/header/Search/SearchField";
import InlineDateRange from "components/forms/FormFields/DateTime/InlineDateRange";
import strings from "localisation/strings";
import { SearchBoxType } from "components/lists/header/Search/SearchBox";
import Checkbox from "components/forms/Checkbox";

const SearchInput = styled(Search)`
  width: auto;
  max-width: 90%;
  margin: 0;
`;
interface Props {
  allFilters?: Filters;
  filter: Filter;
  onChange: (id: string, value: any) => void;
}

const getValue = (obj: any, position: string, value?: Date) => {
  if (
    obj &&
    (obj.hasOwnProperty.call(obj, "start") ||
      obj.hasOwnProperty.call(obj, "end"))
  ) {
    return {
      ...obj,
      [position]: value,
    };
  }
  return { [position]: value };
};

class Field extends Component<Props, object> {
  onInputChange = (value: string) => {
    this.props.onChange(this.props.filter.id, value);
  };

  onSelectChange = (option: any) => {
    // Each selected option is full multi-select option object.
    // more info https://react-select.com/props#option
    this.props.onChange(this.props.filter.id, option);
  };

  onCheckboxChange = (options: string[]) => {
    this.props.onChange(this.props.filter.id, options);
  };

  onDateRangeChange = (position: string, value?: Date) => {
    const { onChange, filter } = this.props;
    const newValue = getValue(filter.value, position, value);
    onChange(filter.id, newValue);
  };

  onBooleanChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.filter.id, !this.props.filter.value);
  };

  render() {
    const {
      type,
      selectList,
      selectListProducer,
      value,
      listType,
      maxDate,
      minDate,
    } = this.props.filter;

    if (!type) return null;

    switch (type) {
      case filterTypes.searchField:
        return (
          <SearchInput
            type={SearchBoxType.Default}
            onSearch={this.onInputChange}
            value={value}
            maxLength={this.props.filter.maxLength || 50}
          />
        );
      case filterTypes.dateRange:
      case filterTypes.dateRangeWithMonthYear:
        return (
          <InlineDateRange
            start={value && value.start}
            startPlaceholder={strings("relativeDateTimeRange.from")}
            startChange={v => this.onDateRangeChange("start", v)}
            end={value && value.end}
            showMonthYearDropDowns={type === filterTypes.dateRangeWithMonthYear}
            endPlaceholder={strings("relativeDateTimeRange.to")}
            endChange={v => this.onDateRangeChange("end", v)}
            maxDate={maxDate}
            minDate={minDate}
          />
        );
      case filterTypes.simpleSelect:
        return (
          <SelectList
            onChange={this.onSelectChange}
            value={value}
            options={
              selectListProducer ? selectListProducer() : selectList || []
            }
          />
        );
      case filterTypes.selectList:
        return (
          <SelectList
            onChange={this.onSelectChange}
            value={value}
            options={
              selectListProducer ? selectListProducer() : selectList || []
            }
            isMulti
          />
        );
      case filterTypes.categoriesList:
        return (
          <CategoriesList
            type={listType || EntityTypes.news}
            onChange={this.onCheckboxChange}
            options={
              this.props.allFilters
                ? this.props.allFilters.tagFilter
                : undefined
            }
            checkedOptions={this.props.filter.value}
          />
        );
      case filterTypes.boolean:
        return (
          <Checkbox
            checked={!!this.props.filter.value}
            name={this.props.filter.id}
            onChange={this.onBooleanChange}
          />
        );
      default:
        return null;
    }
  }
}

export default Field;
