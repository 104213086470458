import React from "react";
import { Link } from "react-router-dom";
import TopNav from "components/topBar/TopNav";
import AppLogo from "components/topBar/AppLogo";
import styled from "style/styled-components";
import strings from "localisation/strings";
import Modal from "components/generic/Modal";
import LoggedIn from "components/generic/LoggedIn";
import LoggedOut from "components/generic/LoggedOut";
import {
  HeaderBody,
  SettingsMenuLink,
  TopBarRegisterButton,
  TopBarLoginButton,
} from "components/topBar/HeaderComponents";

import MySettingsIcon from "assets/icons/user.svg";
import BusinessLink from "components/topBar/BusinessLink";
import LogoutButton from "components/topBar/LogoutButton";
import Area from "components/generic/ActionArea";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import PrivilegedComponent from "components/PrivilegedComponent";

const ActionArea = styled(Area)`
  flex-grow: 1;
  align-items: center;
  margin-top: 0;
  margin-right: ${({ theme }) => theme.margin.large};
`;

const ProfileToggle = styled.a`
  cursor: pointer;
  margin: 10px;
  display: flex;
  align-items: center;
`;

const DownArrowIcon = styled.span`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: 10px;
  border-top: 5px solid ${({ theme }) => theme.color.foreground.antiPrimary};
`;

const MenuModal = styled(Modal)`
  top: ${({ theme }) => theme.globalComponents.headerHeight - 8}px;
  padding: 8px;
`;

export interface DesktopHeaderProps {
  areButtonsHidden?: boolean;
  onShowSettings: () => void;
  onHideSettings: () => void;
  onLogout: () => void;
  isSettingsVisible: boolean;
  appLogoLink: string;
  user?: {
    firstName?: string;
    lastName?: string;
  };
}

const DesktopHeader = ({
  areButtonsHidden,
  onShowSettings,
  onHideSettings,
  onLogout,
  isSettingsVisible,
  appLogoLink,
  user,
}: DesktopHeaderProps) => {
  return (
    <HeaderBody data-role="header">
      <AppLogo link={appLogoLink} />
      <TopNav data-role="top-nav" />
      <ActionArea>
        {!areButtonsHidden && (
          <LoggedOut>
            <TopBarLoginButton text={strings("buttons.logIn")} to="/anmelden" />
            <TopBarRegisterButton
              text={strings("buttons.register")}
              to="/registrieren"
            />
          </LoggedOut>
        )}
        <LoggedIn>
          <PrivilegedComponent
            id={PrivilegedComponentsIds.MENU_DEFAULT_COMPANY}
          >
            <BusinessLink />
          </PrivilegedComponent>
          <ProfileToggle onClick={onShowSettings}>
            <span data-role="app-user-info">
              {user && user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : "User"}
            </span>
            <DownArrowIcon />
          </ProfileToggle>
        </LoggedIn>
      </ActionArea>
      {/*Account Modal*/}
      <LoggedIn>
        <MenuModal
          isVisible={isSettingsVisible}
          onClose={onHideSettings}
          transparentBackdrop
        >
          <PrivilegedComponent id={PrivilegedComponentsIds.MENU_PERSONAL_DATA}>
            <Link to="/konto/persoenliche-angaben">
              <SettingsMenuLink src={MySettingsIcon} onClick={onHideSettings}>
                {strings("header.mySettings")}
              </SettingsMenuLink>
            </Link>
            <Link to="/agb">
              <SettingsMenuLink src={MySettingsIcon} onClick={onHideSettings}>
                {strings("header.termsAndConditions")}
              </SettingsMenuLink>
            </Link>
            <Link to="/marketingzwecke">
              <SettingsMenuLink src={MySettingsIcon} onClick={onHideSettings}>
                {strings("header.marketingPurposes")}
              </SettingsMenuLink>
            </Link>
            <Link to="/konto/passwort-aendern">
              <SettingsMenuLink src={MySettingsIcon} onClick={onHideSettings}>
                {strings("header.changePassword")}
              </SettingsMenuLink>
            </Link>
          </PrivilegedComponent>
          <LogoutButton onHideMenu={onHideSettings} onLogout={onLogout} />
        </MenuModal>
      </LoggedIn>
    </HeaderBody>
  );
};

export default DesktopHeader;
