import Intl from "intl";
import "intl/locale-data/jsonp/de-DE";

const formatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatPrice = (price?: number | null): string | undefined | null => {
  if (typeof price === "number") {
    return formatter.format(price);
  }
  return price;
};

export default formatPrice;
