import { prop } from "ramda";
import SortingInterface from "api/models/SortingInterface";
import Filters from "api/models/Filters";
import TagInterface from "api/models/TagInterface";

const countTagFilters = (tagFilter: TagInterface[]): number => {
  return tagFilter.reduce((acc, tag) => {
    const { tagSuccessors } = tag;
    if (!tagSuccessors || 0 === tagSuccessors.length) return acc + 1;
    return acc + countTagFilters(tagSuccessors);
  }, 0);
};

export const countFilters = (filters?: Partial<Filters>) => {
  if (!filters) return 0;
  const {
    dateTimeFilter = {},
    locationFilter = [],
    priceRangeFilter = [],
    offerTypeFilter = [],
    statusFilter = [],
    tagFilter = [],
  } = filters;
  const { dateTimeFrom, dateTimeTill } = dateTimeFilter;
  const dateTimeFilterCount = dateTimeFrom || dateTimeTill ? 1 : 0;
  const tagFilterCount = countTagFilters(tagFilter);
  return (
    dateTimeFilterCount +
    locationFilter.length +
    priceRangeFilter.length +
    offerTypeFilter.length +
    statusFilter.length +
    tagFilterCount
  );
};

export const countSorters = (sort?: SortingInterface) =>
  prop("sortRules", sort || { sortRules: [] }).length;
