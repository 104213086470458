import React from "react";
import strings from "localisation/strings";
import RepresentativePasswordForm from "components/forms/BusinessRepresentativePassword";
import ActivationPageLayout from "components/activation/UserActivationPageLayout";

interface Props {
  title?: string;
  isAdminForm?: boolean;
}

const RepresentativePasswordCreationView: React.FC<Props> = ({
  isAdminForm = false,
}) => {
  const adminPrefix = isAdminForm ? `${strings("adminPrefix")} ` : "";
  return (
    <ActivationPageLayout
      title={`${adminPrefix}${strings(
        "activateAccountScreen.passwordCreationTitle",
      )}`}
    >
      <RepresentativePasswordForm isAdminForm={isAdminForm} />
    </ActivationPageLayout>
  );
};

export default RepresentativePasswordCreationView;
