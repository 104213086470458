import React from "react";
import { SuggestionContainer } from "components/forms/FormFields/AddressAutocomplete/SuggestionsDropdown";
import styled from "styled-components";
import strings from "localisation/strings";

const PrimarySuggestionLabel = styled.span`
  color: ${({ theme }) => theme.color.foreground.action};
`;

interface Props {
  onClick: () => void;
}

const PinLocationOnMapSuggestion = ({ onClick }: Props) => (
  <SuggestionContainer>
    <PrimarySuggestionLabel onClick={onClick}>
      {strings("addressAutocomplete.pinOnMap")}
    </PrimarySuggestionLabel>
  </SuggestionContainer>
);

export default PinLocationOnMapSuggestion;
