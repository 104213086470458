import AuthedAPI from "api/AuthedAPI";
import RequestOptions from "api/RequestOptions";
import { KeepLocalParams } from "api/models/KeepLocalParams";

const keepLocalAPI = new AuthedAPI({ baseUrl: "https://keeplocal.de/" });

const transferDataToKeepLocalRegistrationPage = (
  queryParams: KeepLocalParams,
  options?: RequestOptions,
) =>
  keepLocalAPI.getJson({
    options,
    queryParams,
    path: "bliggit-wuppertal/",
  });

export { transferDataToKeepLocalRegistrationPage };
