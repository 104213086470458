import { useEffect, useCallback } from "react";
import notificationState from "state/singletons/notificationState";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";
import { GlobalNotification } from "state/NotificationState";

enum GlobalNotificationDuration {
  Error = 3500,
  Success = 2500,
}

const useGlobalNotification = (notification: GlobalNotification) => {
  const clearNotification = useCallback(() => {
    notificationState.removeNotification(notification.id);
  }, [notification.id]);

  useEffect(() => {
    if (notification.type === NotificationTypes.system) {
      // Global system notifications never time out
      return;
    }

    const duration =
      notification.type === NotificationTypes.error
        ? GlobalNotificationDuration.Error
        : GlobalNotificationDuration.Success;
    const timeoutHandle = setTimeout(clearNotification, duration);

    return () => clearTimeout(timeoutHandle);
  }, [notification.type, clearNotification]);

  return {
    clearNotification,
  };
};

export default useGlobalNotification;
