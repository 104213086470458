import React, { Component } from "react";
import { Subscribe } from "unstated";
import { RouteComponentProps, withRouter } from "react-router-dom";

import styled from "style/styled-components";
import strings from "localisation/strings";
import LocaleState from "state/LocaleState";
import {
  CenteredBlockTitle,
  CenteredContentViewContainer,
} from "components/generic";
import PasswordRecoveryForm from "components/forms/PasswordRecoveryForm";
import APIRequestMaker, {
  RequestMakerStatus,
  RequestType,
} from "components/APIRequestMaker";
import { adminPostPasswordRecovery, postPasswordRecovery } from "api/session";
import { PasswordRecoveryPayload } from "api/models/UsersInterface";
import PasswordRecoverySuccess from "components/forms/PasswordRecoverySuccess";
import FormMessage from "components/forms/FormMessage";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";

const RecoveryForm = styled.div`
  max-width: ${({ theme }) => theme.forms.small};
  margin: 20px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  ${inputContainerAutoZoomPrevetionStyles};
`;

const NowrapBlockTitle = styled(CenteredBlockTitle)`
  white-space: nowrap;
  margin-bottom: 0;
`;

const ErrorMessage = styled(FormMessage)`
  color: ${({ theme }) => theme.color.foreground.error};
  padding-top: 30px;
`;

interface Props extends RouteComponentProps {
  isAdmin?: boolean;
}

class PasswordRecoveryScreen extends Component<Props> {
  onShowLogin = () => {
    const { history, isAdmin } = this.props;
    if (isAdmin) {
      history.push("/admin/anmelden");
    } else {
      history.push("/anmelden");
    }
  };

  render() {
    const adminPrefix = this.props.isAdmin ? `${strings("adminPrefix")} ` : "";
    return (
      <Subscribe to={[LocaleState]}>
        {() => (
          <APIRequestMaker<object, PasswordRecoveryPayload>
            request={
              this.props.isAdmin
                ? adminPostPasswordRecovery
                : postPasswordRecovery
            }
            requestType={RequestType.MUTATION}
          >
            {({ status, error }, onSubmit) => {
              const errorMessage =
                status === RequestMakerStatus.ERROR && error ? (
                  <ErrorMessage>{error.detail}</ErrorMessage>
                ) : null;

              return (
                <CenteredContentViewContainer>
                  <NowrapBlockTitle>
                    {`${adminPrefix}${strings("passwordRecoveryScreen.title")}`}
                  </NowrapBlockTitle>
                  <RecoveryForm>
                    {status !== RequestMakerStatus.SUCCESSFUL && (
                      <PasswordRecoveryForm
                        navigateToLogin={this.onShowLogin}
                        onSubmit={onSubmit}
                        errorMessage={errorMessage}
                      />
                    )}
                    {status === RequestMakerStatus.SUCCESSFUL && (
                      <PasswordRecoverySuccess
                        navigateToLogin={this.onShowLogin}
                      />
                    )}
                  </RecoveryForm>
                </CenteredContentViewContainer>
              );
            }}
          </APIRequestMaker>
        )}
      </Subscribe>
    );
  }
}

export default withRouter(PasswordRecoveryScreen);
