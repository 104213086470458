import React from "react";
import getEmojiTextComponents from "components/helpers/getEmojiTextComponents";
import TableLink from "components/table/TableLink";

interface Props {
  title: string;
  link: string;
}

const EmojiTableLink = ({ title, link }: Props) => (
  <TableLink to={link}>{getEmojiTextComponents(title, 15)}</TableLink>
);

export default EmojiTableLink;
