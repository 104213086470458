import { ProfileOverrides } from "profiles/types/ProfileOverrides";
import { ReactComponent as Logo } from "profiles/saarlouis/assets/icons/logo.svg";

export const profileOverrides: ProfileOverrides = {
  locales: {
    de: {
      dashboardScreen: {
        warningButton: {
          location: "in Saarlouis",
        },
      },
      newsScreen: {
        fields: {
          topicOfTheWeek: {
            label: "Auf einen Bligg",
          },
        },
      },
      eventsScreen: {
        fields: {
          topicOfTheWeek: {
            label: "Auf einen Bligg",
          },
        },
      },
      postsScreen: {
        fields: {
          topicOfTheWeek: {
            label: "Auf einen Bligg",
          },
        },
      },
      offersScreen: {
        fields: {
          topicOfTheWeek: {
            label: "Auf einen Bligg",
          },
        },
      },
      tableView: {
        options: {
          hobby: "Aktiv sein in Saarlouis",
        },
        notificationsOptions: {
          topicOfTheWeek: "Auf einen Bligg",
        },
        topicOfTheWeek: "Auf einen Bligg",
      },
      businessUserProfileScreen: {
        fields: {
          topicOfTheWeek: {
            label: "Auf einen Bligg",
          },
          generalTagsField: {
            title: "Wähle ein Stadtviertel und passende User-Interessen",
            errorMessage: "Wähle mindestens eine Stadtteil-Kategorie aus",
          },
        },
      },
      gneralTagSelector: {
        tooManyDistricts:
          "Wähle bitte maximal {{maxNumDistrict}} Stadtteile oder 'Ganz Saarlouis' aus.",
      },
      geoLocations: {
        Saarlouis: "Lokal",
      },
      businessSettings: {
        "additional-settings": {
          fields: {
            venueId: {
              label: "Saarlouis Live Venue ID",
            },
          },
        },
        plan: {
          planChangePending:
            "Ihr neues Paket {{planName}} ist ab dem {{planChangeDate}} aktiv. Bis dahin können Sie die Leistungen Ihres bisherigen Paketes nutzen oder unter vertrieb@bliggit-sls.de weitere Leistungen anfragen.",
        },
      },
      adminVenuesScreen: {
        title: "Saarlouis Live-Events von Veranstaltungsorten Ausschließen",
      },
      adminAppStatsScreen: {
        link: "Matomo Dashboard",
      },
      registrationScreen: {
        fields: {
          permissionToAnalyzeData: {
            accept:
              "Ja, ich möchte von der Stadtwerke Saarlouis GmbH im Rahmen von Newslettern über Angebote, Neuigkeiten, verbesserte Dienstleistungsangebote, Änderungen und sonstige Ereignisse im Zusammenhang mit der City-Plattform Bliggit Saarlouis (beinhaltet das Angebot über die Webseite www.bliggit-sls.de, das Business-Portal www.bp.bliggit-sls.de sowie die Bliggit Saarlouis-App) regelmäßig über meine angegebene E-Mail-Adresse informiert werden. Detaillierte Informationen finde ich",
            link: "hier.",
            afterLink: " (optional)",
            label:
              "Ich bin damit einverstanden, dass meine Daten für Marketing-Zwecke verwendet werden.",
          },
        },
      },
      requestDeletionScreen: {
        subtitle: "Bitte gib die E-Mail Adresse deines Bliggit Saarlouis-Kontos ein.",
        successMessageLine1: "Sofern dieser E-Mail Adresse ein Bliggit Saarlouis-Konto zugewiesen ist, senden wir einen Link zur Bestätigung der Kontolöschung an diese E-Mail Adresse.",
      },
    },
  },
  theme: {
    icons: {
      Logo,
    },
  },
};
