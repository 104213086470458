enum ImageUploadType {
  BUSINESS = "BUSINESS",
  EVENTS = "EVENTS",
  NEWS = "NEWS",
  OFFERS = "OFFERS",
  POSTS = "POSTS",
  TAGS = "TAGS",
}

export default ImageUploadType;
