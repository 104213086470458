import styled from "style/styled-components";

const SearchInput = styled.input`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.font.size.medium};
  margin-left: ${({ theme }) => theme.margin.large};
  &::placeholder {
    color: ${({ theme }) => theme.color.background.tertiary};
    opacity: 1;
  }
`;

export default SearchInput;
