import React from "react";
import { NavLink } from "react-router-dom";
import { MarginlessViewContainer } from "components/generic/ViewContainer";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import { SmallIconedTextButton } from "components/buttons/TextButton";

const SidebarContainer = styled(MarginlessViewContainer)`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${({ theme }) => theme.margin.larger};
  padding-right: ${({ theme }) => theme.margin.larger};

  flex-basis: ${({ theme }) => theme.globalComponents.sidenavWidth};
  max-width: ${({ theme }) => theme.globalComponents.maxSidenavWidth};
  flex-grow: 0;
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.background.primary};
  border-right: 1px solid ${({ theme }) => theme.color.foreground.quaternary};

  @media ${breakpoints.tabletMax} {
    flex-basis: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${({ theme }) => theme.margin.standart};
    padding-right: ${({ theme }) => theme.margin.standart};
  }

  @media ${breakpoints.phoneMax} {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${({ theme }) => theme.margin.sideMin};
    padding-right: ${({ theme }) => theme.margin.sideMin};
    border-bottom: 1px solid ${({ theme }) => theme.color.foreground.quaternary};
    flex-basis: auto;
    flex-flow: row;
    max-width: initial;
    justify-content: space-around; // IE11 does not support space-evenly
    justify-content: space-evenly;
  }
`;

interface SidenavLayoutProps {
  sidebar: any;
  children?: React.ReactNode | React.ReactNode[];
}

const Sidenav = ({ sidebar }: SidenavLayoutProps) => (
  <SidebarContainer>{sidebar}</SidebarContainer>
);

const SidenavContainer = styled(MarginlessViewContainer)`
  flex-flow: row;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: hidden;

  @media ${breakpoints.phoneMax} {
    flex-flow: column;
  }
`;

const SidenavLayout = ({ sidebar, children }: SidenavLayoutProps) => {
  return (
    <SidenavContainer>
      <Sidenav sidebar={sidebar} />
      {children}
    </SidenavContainer>
  );
};

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  margin-top: ${({ theme }) => theme.margin.large};

  @media ${breakpoints.tabletMin} and ${breakpoints.tabletMax} {
    justify-content: center;
  }
`;

interface ActiveableProps {
  isActive: boolean;
}

const StyledIconedTextButton = styled(SmallIconedTextButton)<ActiveableProps>`
  font-weight: ${({ isActive }) => (isActive ? 900 : 300)};

  @media ${breakpoints.tabletMin} and ${breakpoints.tabletMax} {
    display: flex;
    flex-direction: column;
    flex-flow: column;
  }

  @media ${breakpoints.desktopMin} {
    text-align: left;
  }
`;

const StyledIcon = styled.span<ActiveableProps>`
  path {
    fill: ${({ theme, isActive }) =>
      isActive
        ? theme.color.foreground.action
        : theme.color.foreground.disabled};
  }
  height: 22px;
  width: 22px;
`;

interface SidebarLinkProps {
  to: string;
  text: string;
  icon: any;
  isActive: boolean;
}

const SidebarLink = ({ to, text, icon, isActive }: SidebarLinkProps) => {
  return (
    <StyledNavLink to={to}>
      <StyledIconedTextButton
        type="responsive"
        isActive={isActive}
        icon={<StyledIcon as={icon} isActive={isActive} />}
        text={text}
      />
    </StyledNavLink>
  );
};

export { SidebarLink };

export default SidenavLayout;
