import React from "react";
import styled from "style/styled-components";
import { ReactComponent as SmallArrow } from "assets/icons/arrow-small.svg";

type Direction = "down" | "right" | "up" | "left";

interface DropdownArrowProps {
  direction?: Direction;
  className?: string;
}

const getRotationFromDirection = (direction?: Direction) => {
  switch (direction) {
    case "down":
      return "90deg";
    case "right":
      return "0deg";
    case "up":
      return "270deg";
    case "left":
    default:
      return "180deg";
  }
};

const StyledArrow = styled(SmallArrow)<{ direction?: Direction }>`
  height: 12px;
  width: 12px;
  transform: ${({ direction }) =>
    `rotate(${getRotationFromDirection(direction)})`};
`;

const DropdownArrow = ({ direction, className }: DropdownArrowProps) => (
  <StyledArrow direction={direction} className={className} />
);

export default DropdownArrow;
