import styled, { css } from "style/styled-components";
import breakpoints, { mobileHeaderWidthMax } from "style/breakpoints";

const viewHorizontalMargins = css`
  margin-left: ${({ theme }) => theme.margin.large};
  margin-right: ${({ theme }) => theme.margin.large};
`;

const cozyViewHorizontalMargins = css`
  margin-left: ${({ theme }) => theme.margin.larger};
  margin-right: ${({ theme }) => theme.margin.larger};
`;

const viewVerticalMargins = css`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const cozyViewVerticalMargins = css`
  margin-top: ${({ theme }) => theme.margin.larger};
  margin-bottom: ${({ theme }) => theme.margin.larger};
`;

const MarginlessViewContainer = styled.div<{
  cozy?: boolean;
  contain?: boolean;
  expand?: boolean;
}>`
  display: flex;
  flex-flow: column nowrap;
  ${({ contain }) => contain && "position: relative;"}
  ${({ expand }) => expand && "flex-grow: 1;"}
`;

const ViewContainer = styled(MarginlessViewContainer)`
  ${({ cozy }) => (cozy ? cozyViewHorizontalMargins : viewHorizontalMargins)}
  ${({ cozy }) => (cozy ? cozyViewVerticalMargins : viewVerticalMargins)}
`;

const CenteredContentViewContainer = styled(ViewContainer)`
  align-items: center;
`;

const DetailsViewContainer = styled(MarginlessViewContainer)<{
  fullWidth?: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: ${({ theme, fullWidth }) =>
    fullWidth ? theme.content.sizes.large : theme.forms.max};
  margin: 0 auto ${({ theme }) => theme.margin.xlarge} auto;
  align-self: center;
  @media ${breakpoints.phoneMax} {
    padding: ${({ theme }) => theme.margin.small};
  }
`;

const MarginedArea = styled.div`
  margin: ${({ theme }) => `${theme.margin.xlarge} 0`};
  ${viewHorizontalMargins}
  
  @media (max-width: ${mobileHeaderWidthMax}px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export {
  MarginlessViewContainer,
  CenteredContentViewContainer,
  viewHorizontalMargins,
  viewVerticalMargins,
  DetailsViewContainer,
  MarginedArea,
};
export default ViewContainer;
