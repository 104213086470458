import React from "react";

const baseTheme = {
  icons: {
    Logo: (() => null) as React.ComponentType,
    MobileLogo: (() => null) as React.ComponentType,
    SecondaryBanner: (() => null) as React.ComponentType,
  },
  color: {
    background: {
      primary: "#ffffff",
      secondary: "#1A73E8",
      secondaryLight: "#fff4f4",
      tertiary: "#c3c5c7",
      quaternary: "#f0f0f0",
      quinary: "#9fa2a6",
      senary: "#f8f8f8",
      notice: "#fdf8ef",
      option: "#EBEFF5",
      empty: "#fffdf8",
      emptyBorder: "#f7b500",
      dark: "#1C363C",
      light: "#d8e5f0",
      disabled: "#D5DADB",
      flamePea: "#DE5F41", // The Flame Pea 500
    },
    foreground: {
      primary: "#1C363C", // The Papa Green 500
      secondary: "#1C363C", // The Papa Green 500
      secondaryLight: "#1C363C", // The Papa Green 500
      secondaryDark: "#1C363C", // The Papa Green 500
      tertiary: "#c3c5c7",
      quaternary: "#edeff2",
      quinary: "#9b9b9b",
      darkAccent: "#6d7278",
      action: "#1A73E8",
      actionLight: "#ecd6d6",
      antiPrimary: "#ffffff",
      noticeInactive: "#f3f1ed",
      noticeMedium: "#ffab1f",
      noticeStrong: "#219e1d",
      subTitle: "#b3beca",
      success: "#00bf92",
      error: "#ff0000",
      warning: "#f5a623",
      arrowIcon: "#434a54",
      /**
       * @deprecated please use `disabledNew` along with `background` `disabled` color
       */
      disabled: "#abbfC6",
      disabledNew: "#97A3A6",
      disabledLight: "#e4e7e7",
      emptyText: "#f69f00",
    },
    font: {
      success: "#0f9d03",
      onLightBackground: "#252525",
      onDarkBackground: "#ffffff",
    },
    border: {
      light: "#bec6c9",
      medium: "#d2d2d2",
      success: "#0f9d03",
      error: "#ff0000",
      inputBorder: "#afbacc",
    },
    component: {
      dropdownBorder: "#d2d2d2",
      paginationText: "#4a4a4a",
      tableBorder: "#e1e1e1",
      tagBackground: "#707a8a",
      tagBorder: "#58606e",
      removeButtonBg: "#434a54",
      removeButtonIcon: "#c3c6ca",
    },
    chat: {
      message: {
        background: {
          left: "#ebf0f2",
          right: "#1C363C",
        },
        font: {
          left: "darkgray",
          right: "white",
        },
        lightFont: {
          left: "#AAAAAA",
          right: "rgba(255, 255, 255, 0.5)",
        },
      },
      bg: {
        sidebar: "#F1F1F1",
        avatar: "#D8D8D8",
        activeConversation: "#EBF0F2",
      },
    },
  },
  globalComponents: {
    headerHeight: 50,
    footerHeight: 48,
    topNavigationButtonHeight: 48,
    footerNavigationButtonHeight: 48,
    sidenavWidth: "20%",
    maxSidenavWidth: "314px",
  },
  components: {
    payments: {
      placeholderColor: "gray", // as close to browser native color as possible
      inputHeight: "35px",
      inputFontFamily: "system-ui",
    },
    inlineInput: {
      textColor: "rgba(0, 0, 0, 0.85)",
      borderRadius: "3px",
      padding: "6px",
      lineHeight: "26px",
    },
    notification: {
      background: {
        error: "#FFF7F7",
        success: "#FBFEF7",
        system: "#d8e5f0",
      },
      foreground: {
        success: "#0f9d03",
        error: "#ff0000",
        system: "#1A73E8",
      },
    },
  },
  margin: {
    xsmall: "4px",
    small: "5px",
    medium: "8px",
    standart: "12px",
    large: "16px",
    larger: "24px",
    xlarge: "32px",
    xxlarge: "40px",
    sideMin: "20px",
  },
  font: {
    family: {
      unbranded: "sans-serif, 'Open Sans', Arial",
      openSans: "'Open Sans', Arial, sans-serif",
      roboto: "'Roboto', Arial, sans-serif",
      firaSans: "'Fira Sans', sans-serif",
    },
    size: {
      small: "10px",
      note: "12px",
      card: "18px",
      pagination: "13px",
      medium: "15px",
      large: "20px",
      table: "13px",
      xlarge: "24px",
      xxlarge: "28px",
    },
    lineHeight: {
      regular: "133%",
      cozy: "28px",
    },
  },
  border: {
    radius: {
      small: "3px",
      mediumSmall: "4px",
      medium: "6px",
      large: "11px",
      unlimited: "1000px",
    },
  },
  dash: {
    buttonHeight: "115px",
    buttonWidth: "212px",
  },
  forms: {
    xsmall: "240px",
    small: "315px",
    large: "343px",
    leftSide: "140px",
    leftSideWide: "200px",
    planPrice: "170px",
    planPriceWide: "240px",
    planPriceContainer: "480px",
    businessHours: "290px",
    xlarge: "420px",
    xxlarge: "620px",
    xxxlarge: "650px",
    max: "720px",
    full: "100%",
  },
  formElements: {
    select: {
      height: 140,
    },
    text: {
      height: 35,
    },
  },
  content: {
    sizes: {
      small: "315px",
      medium: "720px",
      large: "1200px",
    },
    fixedScrollableHeight: "256px",
  },
  grid: {
    itemWidth: "325px",
  },
  list: {
    itemHeight: {
      desktop: "120px",
      tablet: "90px",
      phone: "60px",
    },
  },
  layers: {
    dropdown: 1000,
    outsideFixedAreaDropdown: 1040,
    sticky: 1020,
    fixed: 1030,
    modalBackdrop: 1040,
    modal: 1050,
    popover: 1060,
    tooltip: 1070,
    questionsDropdown: 10,
    componentButton: 1,
  },
  cursors: {
    notAllowed: "not-allowed",
  },
  image: {
    aspectRatio: 66.6,
  },
};

export default baseTheme;
