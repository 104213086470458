import React, { ReactNode } from "react";
import APIRequestMaker, {
  RequestMakerStatus,
} from "components/APIRequestMaker";
import Filters from "api/models/Filters";
import { FilterAPIResponse } from "api/models/FilterInterface";
import OverlaySpinner from "components/generic/OverlaySpinner";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";

interface PossibleFiltersLoaderProps {
  postFiltersRequest: FiltersRequestType;
  appliedFilters: Partial<Filters>;
  children: (possibleFilters: Partial<Filters>) => ReactNode;
}

const FiltersLoader = ({
  postFiltersRequest,
  appliedFilters,
  children,
}: PossibleFiltersLoaderProps) => (
  <APIRequestMaker<FilterAPIResponse, Partial<FilterAPIResponse>>
    request={postFiltersRequest({ filters: appliedFilters })}
  >
    {({ result, status }) => {
      const { filters } = result || { filters: {} };
      return (
        <>
          {children(filters)}
          {status === RequestMakerStatus.WAITING && <OverlaySpinner />}
        </>
      );
    }}
  </APIRequestMaker>
);

export default FiltersLoader;
