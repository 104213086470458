import React from "react";
import * as PlanCard from "views/Wizard/PlanCard";
import BliggBubble from "components/generic/BliggBubble";
import BodyText from "components/generic/BodyText";
import styled from "style/styled-components";
import { noop } from "utils";
import ContentLimitEditButton from "views/BusinessSettings/PlanSelection/components/ContentLimitEditButton";

const DepletedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.background.primary};
  opacity: 0.6;
`;

const TopRightCornerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

interface ContentLimitCardProps {
  bubbleText: string;
  description: string;
  link?: JSX.Element;
  depleted?: boolean;
  secondaryHeroColor?: boolean;
  contentLimitEditable?: boolean;
  onContentLimitEdited?: (updatedContentLimit: number) => void;
}

const ContentLimitCard = ({
  bubbleText,
  description,
  link,
  depleted,
  secondaryHeroColor,
  contentLimitEditable,
  onContentLimitEdited = noop,
}: ContentLimitCardProps) => (
  <PlanCard.PlanContainer>
    <PlanCard.Hero>
      {contentLimitEditable && (
        <TopRightCornerContainer>
          <ContentLimitEditButton onEdited={onContentLimitEdited} />
        </TopRightCornerContainer>
      )}
      <PlanCard.VerticalHeroBackground
        color={secondaryHeroColor ? "flamePea" : "dark"}
      />
      <BliggBubble size="medium" bubbleText={bubbleText} />
    </PlanCard.Hero>

    <PlanCard.Description>
      <BodyText small centered>
        {description}
        {link}
      </BodyText>
    </PlanCard.Description>
    {depleted && !contentLimitEditable && <DepletedOverlay />}
  </PlanCard.PlanContainer>
);

export default ContentLimitCard;
