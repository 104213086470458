import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import strings from "localisation/strings";
import PaymentMethodsList from "views/BusinessSettings/Payment/PaymentMethodsList";
import useQuery from "common/hooks/useQuery";
import {
  deletePaymentMethod,
  getPaymentMethods,
  makePaymentMethodDefault,
} from "api/businessProfiles";
import QueryStatus from "common/api/models/QueryStatus";
import { routeNames } from "core/navigation";
import { isPayoneIntegrationEnabled } from "views/BusinessSettings/Payment/helpers";
import useBusinessSubscriptionPlanPaymentsValidation from "views/BusinessSettings/Payment/hooks/useBusinessSubscriptionPlanPaymentsValidation";
import PaymentMethodsConfig from "views/BusinessSettings/Payment/PaymentMethodsConfig";
import { PaymentDataResponseDTO } from "api/models/Payments";
import useNotificationState from "hooks/useNotification";
import useComponentMountedStatus from "hooks/useComponentMountedStatus";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import BusinessSettingsSidebar from "views/BusinessSettings/BusinessSettingsSidebar";

interface PaymentSettingsParams {
  useSidebar?: boolean;
  goBack?: () => void;
  handleAddPaymentMethodClicked?: () => void;
  handleEditPaymentMethodClicked?: (
    editedPaymentMethod: PaymentDataResponseDTO,
  ) => void;
}

export type PaymentRouteProps = RouteComponentProps<
  object,
  object,
  PaymentSettingsParams
>;

const BusinessPaymentSettings = ({ history, location }: PaymentRouteProps) => {
  const state: PaymentSettingsParams = location.state
    ? {
        useSidebar: location.state.useSidebar,
        goBack: location.state.goBack,
        handleAddPaymentMethodClicked:
          location.state.handleAddPaymentMethodClicked,
        handleEditPaymentMethodClicked:
          location.state.handleEditPaymentMethodClicked,
      }
    : {};

  const {
    isBusinessPlanValidated,
    businessPlanValidationStatus,
  } = useBusinessSubscriptionPlanPaymentsValidation();
  const isMounted = useComponentMountedStatus();
  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentDataResponseDTO[]
  >([]);

  const { result: { data = [] } = { data: [] }, status } = useQuery({
    request: getPaymentMethods,
    compare: [],
  });

  const payoneIntegrationEnabled = isPayoneIntegrationEnabled();
  useEffect(() => {
    const dataFetchingFinished =
      isBusinessPlanValidated &&
      businessPlanValidationStatus === "OK" &&
      status === QueryStatus.SUCCESSFUL;

    if (!dataFetchingFinished) {
      return;
    }

    const hasNoPlan = data.length === 0;
    // In case payone integration is disabled, we only care if sepa method is present
    const hasSepaMethod = data.find(
      ({ paymentType }) => paymentType === "SEPA",
    );

    if (hasNoPlan || (!payoneIntegrationEnabled && !hasSepaMethod)) {
      state.goBack
        ? state.goBack()
        : history.push(routeNames.BusinessSettings.AddPayment, {
            // Disable going back as the user will be redirected back again unless a payment method is created
            isBackAvailable: false,
          });
    }
  }, [
    data,
    status,
    isBusinessPlanValidated,
    payoneIntegrationEnabled,
    businessPlanValidationStatus,
  ]);

  useEffect(() => {
    setPaymentMethods(data);
  }, [status === QueryStatus.SUCCESSFUL]);

  const makeOptimisticResponseFailureHandler = (
    lastValidPaymentMethods: PaymentDataResponseDTO[],
  ) => (error: APIErrorResponse) => {
    // Only need to notify user and run effects if component is mounted
    if (isMounted) {
      addErrorNotification(error.detail || strings("generic.error"));
      // Set back the last known valid payment methods data
      setPaymentMethods(lastValidPaymentMethods);
    }
  };

  const handleSuccessfulRequest = () => {
    if (isMounted) {
      addSuccessNotification(strings("generic.success"));
    }
  };

  const handlePaymentMethodDeleteClicked = ({
    paymentId,
  }: PaymentDataResponseDTO) => {
    const previousPaymentMethods = [...paymentMethods];
    // Optimistically delete the payment method
    setPaymentMethods(
      paymentMethods.filter(method => method.paymentId !== paymentId),
    );

    const handleFailedRequest = makeOptimisticResponseFailureHandler(
      previousPaymentMethods,
    );

    // Request is not canceled on unmount as the user already expects success
    deletePaymentMethod(paymentId)
      .then(handleSuccessfulRequest)
      .catch(handleFailedRequest);
  };

  const handlePaymentMethodMadeDefault = ({
    paymentId,
  }: PaymentDataResponseDTO) => {
    const previousPaymentMethods = [...paymentMethods];
    // Optimistically set the method as default
    setPaymentMethods(
      paymentMethods.map(method => ({
        ...method,
        defaultPaymentMethod: method.paymentId === paymentId,
      })),
    );

    const handleFailedRequest = makeOptimisticResponseFailureHandler(
      previousPaymentMethods,
    );
    // Request is not canceled on unmount as the user already expects success
    makePaymentMethodDefault(paymentId)
      .then(handleSuccessfulRequest)
      .catch(handleFailedRequest);
  };

  const handleAddPaymentMethodClickedDefault = () => {
    history.push(routeNames.BusinessSettings.AddPayment);
  };

  const handleEditPaymentMethodClickedDefault = (
    editedPaymentMethod: PaymentDataResponseDTO,
  ) => {
    history.push(routeNames.BusinessSettings.AddPayment, {
      methodType: editedPaymentMethod.paymentType,
      isBackAvailable: true,
    });
  };

  return (
    <BusinessSettingsLayout
      title={strings("businessSettings.payment.title")}
      subtitle={strings("businessSettings.payment.comments")}
      isLoading={status === QueryStatus.WAITING}
      sidebar={
        (state.useSidebar ? (
          state.useSidebar
        ) : (
          true
        )) ? (
          <BusinessSettingsSidebar />
        ) : (
          undefined
        )
      }
    >
      {payoneIntegrationEnabled ? (
        <PaymentMethodsConfig
          editable={
            businessPlanValidationStatus !== "SUBSCRIPTION_PLAN_MISSING"
          }
          paymentMethods={paymentMethods}
          onEditPaymentRequested={
            state.handleEditPaymentMethodClicked ||
            handleEditPaymentMethodClickedDefault
          }
          onPaymentMethodDeleted={handlePaymentMethodDeleteClicked}
          onPaymentMethodMadeDefault={handlePaymentMethodMadeDefault}
        />
      ) : (
        <PaymentMethodsList
          paymentMethods={paymentMethods}
          onCreatePaymentRequested={
            state.handleAddPaymentMethodClicked ||
            handleAddPaymentMethodClickedDefault
          }
          onPaymentMethodDeleted={handlePaymentMethodDeleteClicked}
          onPaymentMethodMadeDefault={handlePaymentMethodMadeDefault}
        />
      )}
    </BusinessSettingsLayout>
  );
};

export default BusinessPaymentSettings;
