import React from "react";
import styled from "style/styled-components";

export const ProgressFlowRow = styled.div`
  display: flex;
  height: 39px;
`;

const BaseContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.size.medium};
`;

export const BlueStepContainer = styled(BaseContainer)`
  background-color: ${({ theme }) => theme.color.foreground.secondaryDark};
  color: ${({ theme }) => theme.color.foreground.antiPrimary};
`;

export const WhiteStepContainer = styled(BaseContainer)`
  color: ${({ theme }) => theme.color.foreground.quinary};
`;

const BlueBackground = styled.div`
  position: relative;
  width: 10;
  height: 39;
  background-color: ${({ theme }) => theme.color.foreground.secondaryDark};
`;

const BaseLargeTriangle = styled.div`
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19px 0 19px 10px;
`;

export const BlueArrow = styled(BaseLargeTriangle)`
  border-color: transparent transparent transparent
    ${({ theme }) => theme.color.foreground.secondaryDark};
`;

const GrayTriangle = styled(BaseLargeTriangle)`
  border-color: transparent transparent transparent
    ${({ theme }) => theme.color.foreground.disabled};
`;

const BaseSmallTriangle = styled.div`
  position: absolute;
  top: -15.5px;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 8px;
`;

const SmallBlueTriangle = styled(BaseSmallTriangle)`
  border-color: transparent transparent transparent
    ${({ theme }) => theme.color.foreground.secondaryDark};
`;

const SmallWhiteTriangle = styled(BaseSmallTriangle)`
  border-color: transparent transparent transparent
    ${({ theme }) => theme.color.foreground.antiPrimary};
`;

export const FilledBlueArrow = () => {
  return (
    <BlueBackground>
      <GrayTriangle>
        <SmallBlueTriangle />
      </GrayTriangle>
    </BlueBackground>
  );
};

export const WhiteArrow = () => {
  return (
    <GrayTriangle>
      <SmallWhiteTriangle />
    </GrayTriangle>
  );
};
