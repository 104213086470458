import React, { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import styled from "style/styled-components";
import FieldErrors from "components/forms/FieldErrors";
import TextareaAutosize from "react-autosize-textarea";

export interface AutoSizableInlineTextAreaFieldProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  errors?: string[];
  onContentChange: (content: string) => void;
  width?: string;
  autoFocus?: boolean;
}

const Container = styled.div`
  align-items: baseline;
  display: flex;
  padding: ${({ theme }) => theme.margin.medium} 0 20px;
  width: ${({ theme }) => theme.forms.full};
  flex-flow: column wrap;
`;

const TextAreaContainer = styled.div`
  width: ${({ theme }) => theme.forms.full};
`;

interface AutosizeTextAreaProps {
  hasErrors?: boolean;
  width?: string;
}

const AutosizeTextarea = styled(TextareaAutosize)<AutosizeTextAreaProps>`
  font-size: ${({ theme }) => theme.font.size.medium};
  width: ${({ theme, width }) => width || theme.forms.full};
  height: ${({ theme }) => theme.formElements.text.height}px;
  border: 1px solid
    ${({ theme, hasErrors }) =>
      hasErrors ? theme.color.border.error : theme.color.border.inputBorder};
  border-radius: ${({ theme }) => theme.components.inlineInput.borderRadius};
  color: ${({ hasErrors, theme }) =>
    hasErrors ? theme.color.foreground.action : theme.color.foreground.primary};
  line-height: ${({ theme }) => theme.components.inlineInput.lineHeight};
  padding: ${({ theme }) => theme.components.inlineInput.padding};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  resize: none;
  overflow: hidden;
  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
    color: ${({ hasErrors, theme }) =>
      hasErrors
        ? theme.color.foreground.actionLight
        : theme.color.foreground.tertiary};
  }
`;

const ErrorsContainer = styled.div`
  width: ${({ theme }) => theme.forms.full};
  display: flex;
  flex-wrap: nowrap;
`;

const AutoSizableInlineTextAreaField = (
  props: AutoSizableInlineTextAreaFieldProps,
) => {
  const {
    onContentChange,
    placeholder,
    value,
    errors,
    maxLength,
    width,
    autoFocus,
  } = props;
  const hasErrors = Boolean(errors && errors.length);

  return (
    <Container>
      <TextAreaContainer>
        <AutosizeTextarea
          onChange={e => {
            if (onContentChange) {
              onContentChange(e.currentTarget.value);
            }
          }}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          width={width}
          hasErrors={hasErrors}
          autoFocus={autoFocus}
        />
      </TextAreaContainer>
      {errors && (
        <ErrorsContainer>
          <FieldErrors errors={errors} />
        </ErrorsContainer>
      )}
    </Container>
  );
};

export default AutoSizableInlineTextAreaField;
