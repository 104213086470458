import React from "react";
import SidenavLayout from "components/generic/SidenavLayout";
import { BodyText, Title, ViewContainer } from "components/generic";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import styled from "style/styled-components";

interface FullWidthableProps {
  size?: "medium" | "large" | "full";
}
const BusinessSettingsScreenViewContainer = styled(ViewContainer)<
  FullWidthableProps
>`
  ${({ theme, size = "medium" }) =>
    size === "full"
      ? "flex-basis: 100%;"
      : `max-width: ${theme.content.sizes[size]};`}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface Props {
  title: string;
  expand?: boolean;
  subtitle?: string;
  // Hides content while loading
  isLoading?: boolean;
  // Overlays the content while loading
  isUpdating?: boolean;
  sidebar?: JSX.Element;
  titleRowButton?: JSX.Element;
  children: React.ReactNode;
}

const BusinessSettingsLayout: React.FC<Props & FullWidthableProps> = ({
  size,
  title,
  subtitle,
  isLoading,
  isUpdating,
  children,
  expand,
  sidebar,
  titleRowButton,
}) => {
  // Expand while loading so that the loader can center itself
  const whileNotLoaded = isLoading;

  if (sidebar) {
    return (
      <SidenavLayout sidebar={sidebar}>
        <BusinessSettingsScreenViewContainer
          cozy
          contain
          expand={whileNotLoaded || expand}
          size={size}
        >
          <TitleWrapper>
            <Title fixedMargins>{title}</Title>
            {titleRowButton}
          </TitleWrapper>
          {subtitle && <BodyText>{subtitle}</BodyText>}
          {isLoading ? <AbsoluteOverlaySpinner /> : children}
          {isUpdating && <AbsoluteOverlaySpinner />}
        </BusinessSettingsScreenViewContainer>
      </SidenavLayout>
    );
  }
  return (
    <BusinessSettingsScreenViewContainer
      cozy
      contain
      expand={whileNotLoaded || expand}
      size={size}
    >
      <TitleWrapper>
        <Title fixedMargins>{title}</Title>
        {titleRowButton}
      </TitleWrapper>
      {subtitle && <BodyText>{subtitle}</BodyText>}
      {isLoading ? <AbsoluteOverlaySpinner /> : children}
      {isUpdating && <AbsoluteOverlaySpinner />}
    </BusinessSettingsScreenViewContainer>
  );
};

export default BusinessSettingsLayout;
