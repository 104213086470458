import React, { useEffect, useState } from "react";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { getExcludedVenuesAdmin, updateExcludedVenuesAdmin } from "api/events";
import { EventVenue } from "api/models/EventInterface";
import EventVenueInput from "components/forms/AdminVenues/EventVenueInput";
import styled from "styled-components";
import ActionButton from "components/buttons/ActionButton";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import ViewContainer from "components/generic/ViewContainer";
import strings from "localisation/strings";
import { CenteredBlockTitle } from "components/generic/BlockTitle";
import { orderBy } from "lodash";
import useCustomModal from "components/modal/useCustomModal";
import CustomModal from "components/modal/CustomModal";
import useNotificationState from "hooks/useNotification";

const SaveButton = styled(ActionButton)`
  float: right;
`;

const ContentWrapper = styled.div`
  width: ${({ theme }) => theme.forms.max};
  align-self: center;
`;

const AdminVenues = ({ history }: RouteComponentProps) => {
  const [currentExclusions, setCurrentExclusions] = useState<EventVenue[]>([
    { venueId: "", name: "" },
  ]);

  const {
    addSuccessNotification,
    addErrorNotification,
  } = useNotificationState();
  const {
    result: loadedExclusions,
    makeRequest: makeGet,
    status: getStatus,
  } = useMutation(getExcludedVenuesAdmin);

  const {
    makeRequest: makeUpdate,
    status: updateStatus,
    error: updateError,
  } = useMutation(updateExcludedVenuesAdmin);

  const reload = () => {
    makeGet({});
  };

  const saveAndReload = () => {
    const allIds = currentExclusions
      .filter(venue => venue.venueId !== "")
      .map(venue => venue.venueId);

    makeUpdate(allIds);
  };
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );

  const handleExclusionsChanged = (exclusions: EventVenue[]) => {
    setShouldBlockNavigation(true);
    setCurrentExclusions(exclusions);
  };

  const {
    closeModal,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    modalVisible,
  } = useCustomModal(history, shouldBlockNavigation);

  useEffect(() => {
    if (getStatus === QueryStatus.SUCCESSFUL && loadedExclusions) {
      setCurrentExclusions(orderBy(loadedExclusions, ["name"]));
    }
  }, [getStatus]);

  useEffect(() => {
    if (updateStatus === QueryStatus.SUCCESSFUL) {
      setShouldBlockNavigation(false);
      addSuccessNotification(strings("generic.success"));
      reload();
    }

    if (updateStatus === QueryStatus.ERROR) {
      addErrorNotification(updateError && updateError.detail);
    }
  }, [updateStatus]);

  useEffect(() => {
    reload();
  }, []);

  return (
    <>
      <Prompt when={shouldBlockNavigation} message={handleBlockedNavigation} />
      <CustomModal
        popupMessage={strings("buttons.unsavedFormText")}
        modalVisible={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
      <ViewContainer>
        <ContentWrapper>
          <CenteredBlockTitle>
            {strings("adminVenuesScreen.title")}
          </CenteredBlockTitle>
          <EventVenueInput
            currentExclusions={currentExclusions}
            onExclusionsChanged={handleExclusionsChanged}
          />
          <SaveButton
            text={strings("buttons.save")}
            onClick={saveAndReload}
            special="publish"
          />
        </ContentWrapper>
      </ViewContainer>
    </>
  );
};

export default withRouter(AdminVenues);
