import React from "react";
import { routeNames } from "core/navigation";
import {
  PaymentsSidebarLink,
  PlansSidebarLink,
} from "views/BusinessSettings/links";

const BusinessSettingsSidebar = () => {
  return (
    <>
      <PlansSidebarLink
        link={routeNames.BusinessSettings.Plan}
        matchedRoutes={[
          routeNames.BusinessSettings.Plan,
          routeNames.BusinessSettings.PlanChange,
          routeNames.BusinessSettings.PlanChangeConfirmation,
        ]}
      />
      <PaymentsSidebarLink
        link={routeNames.BusinessSettings.Payment}
        matchedRoutes={[
          routeNames.BusinessSettings.Payment,
          routeNames.BusinessSettings.PaymentAdmin,
          routeNames.BusinessSettings.PaymentConfirmation,
          routeNames.BusinessSettings.AddPayment,
        ]}
      />
    </>
  );
};

export default BusinessSettingsSidebar;
