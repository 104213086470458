const optionFieldNameSeparator = ".";

export const constructOptionFieldName = (flowCode: string, optionId: string) =>
  [optionId, flowCode].join(optionFieldNameSeparator);

export const parseOptionFieldName = (optionFieldName: string) => {
  const [optionId, flowCode] = optionFieldName.split(optionFieldNameSeparator);
  return {
    flowCode,
    optionId,
  };
};

type FlowStatusToggleFieldName = string;
type FlowOptionFieldName = string;

export const isFlowOptionFieldName = (
  fieldName: FlowStatusToggleFieldName | FlowOptionFieldName,
) => {
  const isFlowOptionField = fieldName.includes(optionFieldNameSeparator);
  return !isFlowOptionField;
};
