import { ReactNode } from "react";
import { createPortal } from "react-dom";
import usePortal from "components/generic/portal/usePortal";

export interface PortalProps {
  id: string;
  children?: ReactNode;
}

const Portal = ({ id, children }: PortalProps) => {
  const target = usePortal(id);

  return createPortal(children, target);
};

export default Portal;
