import React from "react";
import { UnderlineNavLink } from "components/buttons/UnderlineButton";
import settingsIcon from "assets/icons/settingsWheel.svg";
import { NoMarginIcon } from "components/forms/FormFields/Icon";
import mailIcon from "assets/icons/mail.svg";
import { generatePath, matchPath } from "react-router";
import useLoginState from "common/hooks/useLoginState";
import { routeNames } from "core/navigation";
import DefaultBusinessContextUnreadCount from "components/topBar/menu/components/DefaultBusinessContextUnreadCount";
import { topSubNavButtonTestId } from "testing/testId";

interface TopBarLinkProps {
  link: string;
  matchedRoutes?: string[];
}

const TopBarMenuChatLink = ({ link }: TopBarLinkProps) => {
  return (
    <>
      <UnderlineNavLink
        activeClassName="sub-nav-active"
        key={link}
        to={link}
        data-role="chat-link"
        {...topSubNavButtonTestId("chat")}
      >
        <NoMarginIcon backgroundImage={mailIcon} active />
      </UnderlineNavLink>
      <DefaultBusinessContextUnreadCount />
    </>
  );
};

const BusinessTopBarMenuChatLink = () => {
  return <TopBarMenuChatLink link={routeNames.Chat} />;
};

const CuratorTopBarMenuChatLink = () => {
  const { getBusinessId } = useLoginState();
  const defaultBusinessChatLink = generatePath(routeNames.CuratorChat, {
    businessId: getBusinessId(),
  });
  return <TopBarMenuChatLink link={defaultBusinessChatLink} />;
};

const DefaultBusinessTopBarMenuChatLink = () => {
  const { getDefaultBusinessId } = useLoginState();
  const defaultBusinessChatLink = generatePath(routeNames.AdminChat, {
    businessId: getDefaultBusinessId(),
  });

  return <TopBarMenuChatLink link={defaultBusinessChatLink} />;
};

const DrilledDownBusinessMenuChatLink = () => {
  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();
  const businessChatLink = generatePath(routeNames.AdminChat, { businessId });

  return <TopBarMenuChatLink link={businessChatLink} />;
};

const TopBarMenuSubscriptionsLink = ({
  link,
  matchedRoutes = [link],
}: TopBarLinkProps) => (
  <UnderlineNavLink
    activeClassName="sub-nav-active"
    data-role="business-settings-link"
    key={link}
    to={link}
    isActive={(match, location) =>
      !!match || !!matchPath(location.pathname, matchedRoutes)
    }
  >
    <NoMarginIcon backgroundImage={settingsIcon} active />
  </UnderlineNavLink>
);

const BusinessTopBarMenuSubscriptionsLink = () => {
  return (
    <TopBarMenuSubscriptionsLink
      link={routeNames.BusinessSettings.Plan}
      matchedRoutes={[
        routeNames.BusinessSettings.Plan,
        routeNames.BusinessSettings.AddPayment,
        routeNames.BusinessSettings.Payment,
        routeNames.BusinessSettings.PaymentAdmin,
        routeNames.BusinessSettings.PaymentConfirmation,
      ]}
    />
  );
};

const BusinessAdminTopBarMenuSubscriptionsLink = () => {
  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();
  const planSelectionLink = generatePath(
    // parent route will non-exact-match all settings routes
    routeNames.BusinessSettings.ParentAdmin,
    { businessId },
  );

  return <TopBarMenuSubscriptionsLink link={planSelectionLink} />;
};

export {
  BusinessTopBarMenuChatLink,
  DefaultBusinessTopBarMenuChatLink,
  DrilledDownBusinessMenuChatLink,
  BusinessTopBarMenuSubscriptionsLink,
  BusinessAdminTopBarMenuSubscriptionsLink,
  CuratorTopBarMenuChatLink,
};
