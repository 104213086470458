import React, { Component } from "react";
import moment from "moment";
import styled from "style/styled-components";
import { DateTimeInput } from "components/forms/FormFields";
import strings from "localisation/strings";
import FieldErrors from "components/forms/FieldErrors";
import DateTimePickerComponent from "components/inputs/DateTimePicker";

interface Props {
  date: Date | null;
  disabled?: boolean;
  dateLabel: string;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  minTime?: Date | null;
  errors?: string[];
  inline?: boolean;
  showTimeSelect?: boolean;
  clearable?: boolean;
}

const InputContainer = styled.div<{ disabled: boolean }>`
  flex: 1;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.margin.large};

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 84px;
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
    .react-datepicker {
      cursor: not-allowed;
    }

    .react-datepicker__header {
      background-color: white;
    }

    .react-datepicker__month,
    .react-datepicker__time-list-item {
      background-color: #eee;
    }

    .react-datepicker__day,
    .react-datepicker__time-list-item,
    .react-datepicker__navigation {
      pointer-events: none;
    }

    .react-datepicker__time-container .react-datepicker__time {
      pointer-events: none;
    }

    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected {
      opacity: .5
  
      &:hover {
        background-color: ${theme.color.background.dark};
      }
    }
  `}
`;

const ClearButton = styled.div`
  font-size: 12px;
  line-height: 14px;
  padding: 2px 0;
  cursor: pointer;
  width: 100%;
`;

const ErrorContainer = styled.div`
  width: 100%;
`;

const currentDate = moment();

class DateTimePicker extends Component<Props> {
  static defaultProps = {
    showTimeSelect: true,
    clearable: true,
  };

  onChange = (date: Date | null) => {
    this.props.onChange(date);
  };

  clearDate = () => {
    this.props.onChange(null);
  };

  render() {
    const {
      date,
      dateLabel,
      disabled,
      errors,
      inline,
      showTimeSelect,
      clearable,
    } = this.props;

    let minDate;
    let minTime;
    let maxTime;

    if (this.props.minDate !== undefined) {
      minDate = this.props.minDate;
      minTime = this.props.minTime || currentDate.startOf("day").toDate();
      maxTime = currentDate.endOf("day").toDate();
    }

    return (
      <InputContainer disabled={!!disabled}>
        <DateTimePickerComponent
          disabled={disabled}
          onChange={this.onChange}
          shouldCloseOnSelect={true}
          customInput={<DateTimeInput dateLabel={dateLabel} />}
          showTimeSelect={showTimeSelect}
          inline={inline}
          popperPlacement="top-start"
          dateFormat="d. MMM yyyy HH:mm"
          timeIntervals={15}
          selected={date}
          minDate={minDate}
          minTime={minTime}
          maxTime={maxTime}
        />
        {errors && (
          <ErrorContainer>
            <FieldErrors errors={errors} />
          </ErrorContainer>
        )}
        {clearable && date && (
          <ClearButton onClick={this.clearDate}>
            {strings("dateTimePicker.clear")}
          </ClearButton>
        )}
      </InputContainer>
    );
  }
}

export default DateTimePicker;
