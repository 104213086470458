import React from "react";
import { MarginlessBlockTitle } from "components/generic/BlockTitle";
import CenteredSpreadRow from "components/generic/CenteredSpreadRow";
import CloseButton from "components/buttons/CloseButton";
import styled from "style/styled-components";

// It's declared not to hide <CloseButton>.
const SidebarBlockTitle = styled(MarginlessBlockTitle)`
  // 245px is the width of the current longest word comes here,
  // "Veranstaltungen" which stored at eventsScreen.filterScreenTitle (German.)
  max-width: 245px;
`;

interface SidebarTitleRowProps {
  title?: string;
  toggleActive: () => void;
  className?: string;
}

const SidebarTitleRow = ({
  title,
  toggleActive,
  ...props
}: SidebarTitleRowProps) => (
  <CenteredSpreadRow {...props}>
    <SidebarBlockTitle>{title}</SidebarBlockTitle>
    <CloseButton onClick={toggleActive} />
  </CenteredSpreadRow>
);

export default SidebarTitleRow;
