import React from "react";
import FieldMessage from "components/forms/FieldMessage";

import styled from "styled-components";

export interface FieldErrorsProps {
  errors?: string[];
  className?: string;
  testId?: string;
}

const StyledFieldMessage = styled(FieldMessage)`
  color: ${({ theme }) => theme.color.foreground.action};
`;

const FieldErrors = ({ errors = [], className, testId }: FieldErrorsProps) => {
  if (!errors || !errors.length) return null;
  return (
    <>
      {errors.map((error, index) => (
        <StyledFieldMessage
          data-cy={testId ? `${testId}-${error}` : ""}
          key={`${error}-${index}`}
          className={className}
        >
          {error}
        </StyledFieldMessage>
      ))}
    </>
  );
};

export default FieldErrors;
