import React from "react";

import DateTimeRange from "api/models/DateTimeRange";
import ShortEventDateRange from "components/events/ShortEventDateRange";

const ListItemDateTime = ({
  dateTimeRange,
}: {
  dateTimeRange?: DateTimeRange;
}) =>
  dateTimeRange &&
  (dateTimeRange.dateTimeFrom || dateTimeRange.dateTimeTill) ? (
    <ShortEventDateRange dateTimeRange={dateTimeRange} />
  ) : null;

export default ListItemDateTime;
