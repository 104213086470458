import React from "react";
import moment from "moment";
import styled from "style/styled-components";
import strings from "localisation/strings";
import PublicationStatuses from "api/models/PublicationStatuses";

const Status = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.color.background.tertiary};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: 12px;
  line-height: 24px;
  margin-top: ${({ theme }) => theme.margin.standart};
`;

const dateFormat = `DD.MM.YYYY [${strings(
  "detailedScreen.publishStatus.at",
)}] HH:mm`;

const getStatus = (status: string, modifiedDate: string | null) => {
  if (status && modifiedDate) {
    const date = moment(modifiedDate).format(dateFormat);
    switch (status) {
      case PublicationStatuses.draft:
      case PublicationStatuses.unpublished:
        return `${strings("detailedScreen.publishStatus.saved")}  ${date}`;
      case PublicationStatuses.published:
        return `${strings("detailedScreen.publishStatus.published")} ${date}`;
      case PublicationStatuses.prePublished:
        return `${strings(
          "detailedScreen.publishStatus.prePublished",
        )} ${date}`;
      default: // NOOP
    }
  }
  return null;
};

const StatusDisplay = ({
  modifiedDate,
  status,
}: {
  modifiedDate: string | null;
  status: string;
}) => <Status>{getStatus(status, modifiedDate)}</Status>;

export default StatusDisplay;
