import React, { useEffect, useState } from "react";
import { curry } from "ramda";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import {
  getExcludedVenuesBusiness,
  updateExcludedVenuesBusiess,
} from "api/events";
import { EventVenue } from "api/models/EventInterface";
import EventVenueInput from "components/forms/AdminVenues/EventVenueInput";
import styled from "styled-components";
import ActionButton from "components/buttons/ActionButton";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import strings from "localisation/strings";
import { orderBy } from "lodash";
import useLoginState from "hooks/useLoginState";
import BusinessSettingsAdminSidebar from "views/BusinessSettings/BusinessSettingsAdminSidebar";
import useCustomModal from "components/modal/useCustomModal";
import CustomModal from "components/modal/CustomModal";
import useNotificationState from "hooks/useNotification";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import { isLoading } from "utils";

const SaveButton = styled(ActionButton)`
  float: right;
`;

const ContentWrapper = styled.div`
  width: ${({ theme }) => theme.forms.max};
  align-self: center;
`;

const orderVenues = (venues: EventVenue[]): EventVenue[] =>
  orderBy(venues, [venue => (venue.name ? venue.name.toLowerCase() : "")]);

const BusinessVenues = ({ history }: RouteComponentProps) => {
  const [currentExclusions, setCurrentExclusions] = useState<EventVenue[]>([
    { venueId: "", name: "" },
  ]);
  const activeBusinessId = useLoginState().getBusinessId();
  const getExclusionsRequest = curry(getExcludedVenuesBusiness)(
    activeBusinessId ? activeBusinessId : "",
  );
  const putExclusionsRequest = curry(updateExcludedVenuesBusiess)(
    activeBusinessId ? activeBusinessId : "",
  );

  const {
    result: loadedExclusions,
    makeRequest: makeGet,
    status: getStatus,
  } = useMutation(getExclusionsRequest);

  const [updating, setUpdating] = useState(false);
  const {
    makeRequest: makeUpdate,
    status: updateExclusionsStatus,
  } = useMutation(putExclusionsRequest);
  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();

  const saveMapping = async () => {
    setUpdating(true);
    const allIds = currentExclusions
      .filter(venue => venue.venueId !== "")
      .map(venue => venue.venueId);

    const response = await makeUpdate(allIds);
    if (response) {
      if (response.error) {
        addErrorNotification(response.error.detail || strings("generic.error"));
      } else {
        addSuccessNotification(
          response.result && response.result.successMessage
            ? response.result.successMessage
            : strings("generic.success"),
        );
        if (response.result && response.result.data) {
          setCurrentExclusions(orderVenues(response.result.data));
        }
        setShouldBlockNavigation(false);
      }

      setUpdating(false);
    }
  };

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );

  const handleExclusionsChanged = (exclusions: EventVenue[]) => {
    setShouldBlockNavigation(true);
    setCurrentExclusions(exclusions);
  };

  const {
    closeModal,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    modalVisible,
  } = useCustomModal(history, shouldBlockNavigation);

  useEffect(() => {
    if (getStatus === QueryStatus.SUCCESSFUL && loadedExclusions) {
      setCurrentExclusions(orderVenues(loadedExclusions));
    }
  }, [getStatus]);

  useEffect(() => {
    makeGet({});
  }, []);

  return (
    <BusinessSettingsLayout
      isLoading={isLoading(getStatus)}
      isUpdating={isLoading(updateExclusionsStatus)}
      title={strings("businessSettings.businessVenuesScreen.title")}
      sidebar={<BusinessSettingsAdminSidebar />}
    >
      <Prompt when={shouldBlockNavigation} message={handleBlockedNavigation} />
      <CustomModal
        popupMessage={strings("buttons.unsavedFormText")}
        modalVisible={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
      <ContentWrapper>
        <EventVenueInput
          currentExclusions={currentExclusions}
          onExclusionsChanged={handleExclusionsChanged}
        />
        <SaveButton
          text={strings("buttons.save")}
          onClick={saveMapping}
          special="publish"
          disabled={updating}
          loadingText={strings("buttons.states.savingInProgress")}
        />
      </ContentWrapper>
    </BusinessSettingsLayout>
  );
};

export default withRouter(BusinessVenues);
