import TagInterface, { TagFilter } from "api/models/TagInterface";
import BaseContentInterface from "api/models/BaseContentInterface";
import DateTimeRange from "api/models/DateTimeRange";
import PriceRange from "api/models/PriceRange";
import PriceMinMax from "api/models/PriceMinMax";
import { AddressLocation } from "api/models/Location";
import Artist from "api/models/Artist";
import PublicationStatuses from "api/models/PublicationStatuses";
import { MapAssignmentPayload } from "api/models/Plan";

export enum EndDateType {
  generated = "GENERATED",
  fullDay = "FULL_DAY",
  appointment = "APPOINTMENT",
  unavailable = "UNAVAILABLE",
}

export default interface EventInterface extends BaseContentInterface {
  businessId: string;
  artists?: Artist[];
  title?: string;
  description?: string;
  dateTimeRange: DateTimeRange;
  location?: AddressLocation;
  priceMinMax?: PriceMinMax;
  priceRange: PriceRange;
  tags: TagInterface[];
  externalId?: DateTimeRange;
  tickets?: string;
  treueWeltLink?: string;
  topicOfTheWeek?: boolean;
  topicOfTheWeekStartDate?: Date;
  topicOfTheWeekEndDate?: Date;
  endDateType?: EndDateType;
  mapAssignments: MapAssignmentPayload[];
}

export interface EventsFilters extends TagFilter {
  title: string;
  dateTimeFilter: DateTimeRange;
  modifiedDateTimeFilter: DateTimeRange;
  dateTimeRangeFilter: DateTimeRange;

  termsMapFilter: {
    businessName: string;
    venue: string;
  };
  statusFilter: {
    id: PublicationStatuses;
    name: string;
  }[];
}

export interface EventVenue {
  venueId: string;
  name?: string;
}

export interface MappedVenues {
  successMessage?: string;
  data: EventVenue[];
}
