import React from "react";
import strings from "localisation/strings";
import ActionButton from "components/buttons/ActionButton";
import FormMessage from "components/forms/FormMessage";
import ActionButtonLink from "components/buttons/ActionButtonLink";
import styled from "styled-components";

interface Props {
  message: string | null;
  isLoading: boolean;
  isSubmittable: boolean;
  onSubmit: () => void;
}

const BackToLogin = styled(ActionButtonLink)<{
  onClick?: () => void;
}>`
  background-color: ${({ theme }) => theme.color.background.secondary};
  color: ${({ theme }) => theme.color.foreground.antiPrimary};
`;

const ConfirmUserActivation = ({
  message,
  isLoading,
  isSubmittable,
  onSubmit,
}: Props) => (
  <>
    {message && <FormMessage>{message}</FormMessage>}
    {isSubmittable ? (
      <ActionButton
        text={strings("activateAccountScreen.activateAccountButton")}
        loadingText={strings("buttons.states.requestInProgress")}
        disabled={isLoading}
        onClick={onSubmit}
      />
    ) : (
      <BackToLogin text={strings("buttons.goToLogin")} to="/anmelden" />
    )}
  </>
);

export default ConfirmUserActivation;
