import { ImageItem } from "hooks/helpers/Interfaces";
import PictureTypes from "api/models/PictureTypes";

export const getSlides = (
  pictures: ImageItem[] | null,
  limit: number,
): ImageItem[] => {
  let newArr: ImageItem[] = [];
  if (pictures != null) {
    const mainPictureArray = pictures
      .filter(picture => picture.type === PictureTypes.main)
      .slice(0, 1);
    const secondaryPictures = pictures.filter(
      picture => picture.type === PictureTypes.secondary,
    );
    const sliceEnd = limit - 1; // limit - mainPicture - 1 + 1 (since the slice method does not include the end-index item)
    const secondaryPicturesArray = secondaryPictures.slice(0, sliceEnd);
    newArr = [...mainPictureArray, ...secondaryPicturesArray];
  }

  const emptyItemsCount = limit - newArr.length;

  if (emptyItemsCount > 0) {
    for (let i = 0; i < emptyItemsCount; i = i + 1) {
      newArr.push({
        type: PictureTypes.secondary,
        url: null,
      });
    }
  }

  return newArr;
};

export const statusFormatter = (current: number, total: number): string =>
  `${current}/${total}`;
