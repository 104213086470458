import React from "react";
import { SidebarLink } from "components/generic/SidenavLayout";
import strings from "localisation/strings";
import { ReactComponent as PaymentIcon } from "assets/icons/coins.svg";
import { useRouteMatch } from "react-router";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

import { ReactComponent as PlanIcon } from "assets/icons/plans.svg";

interface SidebarLinkProps {
  link: string;
  matchedRoutes?: string[];
}

const PaymentsSidebarLink = ({
  link,
  matchedRoutes = [link],
}: SidebarLinkProps) => {
  const paymentRouteMatch = useRouteMatch(matchedRoutes);

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_PAYMENTS}>
      <SidebarLink
        to={link}
        text={strings("businessSettings.payment.link")}
        icon={PaymentIcon}
        isActive={!!paymentRouteMatch}
      />
    </PrivilegedComponent>
  );
};

const PlansSidebarLink = ({
  link,
  matchedRoutes = [link],
}: SidebarLinkProps) => {
  const planRouteMatch = useRouteMatch(matchedRoutes);

  return (
    <SidebarLink
      to={link}
      text={strings("businessSettings.plan.title")}
      icon={PlanIcon}
      isActive={!!planRouteMatch}
    />
  );
};

export { PaymentsSidebarLink, PlansSidebarLink };
