import React, { Component } from "react";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import List from "components/lists/List";
import BusinessProfileListItem from "components/lists/businessProfileList/BusinessProfileListItem";

export interface BusinessProfileListProps {
  emptyIndicator?: string;
  emptyIndicatable?: boolean;
  businessProfiles: BusinessUserProfileInterface[];
  onItemClick: (businessProfileId: string) => void;
}

class BusinessProfileList extends Component<BusinessProfileListProps> {
  render() {
    const {
      businessProfiles,
      onItemClick,
      emptyIndicatable,
      emptyIndicator,
    } = this.props;
    return (
      <List<BusinessUserProfileInterface>
        emptyIndicator={emptyIndicator}
        emptyIndicatable={emptyIndicatable}
        data={businessProfiles}
        renderItem={(businessProfile, index) => (
          <BusinessProfileListItem
            key={index}
            businessProfile={businessProfile}
            onClick={onItemClick}
          />
        )}
      />
    );
  }
}

export default BusinessProfileList;
