import styled from "style/styled-components";

const TagButton = styled.div<{ hasError?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 18px;
  line-height: 18px;
  color: ${({ theme, hasError }) =>
    hasError
      ? theme.color.background.secondary
      : theme.color.foreground.antiPrimary};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: bold;
  letter-spacing: 0.07px;
  border-radius: ${({ theme }) => theme.border.radius.small};
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.color.background.secondary : "transparent"};
  background-color: ${({ theme, hasError }) =>
    hasError
      ? theme.color.foreground.quaternary
      : theme.color.component.tagBackground};
  padding: 0 ${({ theme }) => theme.margin.xsmall};
`;

export default TagButton;
