import React, { Component } from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import { DateTimePicker } from "components/forms/FormFields";
import moment from "moment";
import { validateDateTime } from "components/helpers/dateRangeUtilities";

interface Props {
  changePublishDateTime: (date: Date | null) => void;
  changeUnpublishDateTime: (date: Date | null) => void;
  publishDateTime: Date | null;
  unpublishDateTime: Date | null;
  publishDateTimeErrors?: string[];
  unpublishDateTimeErrors?: string[];
}

interface State {
  publishDateTime: Date | null;
  unpublishDateTime: Date | null;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  margin-bottom: ${({ theme }) => theme.margin.large};

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  & div.react-datepicker__input-container > div:first-child {
    max-width: 100%;
  }
`;

const currentMoment = moment();
const currentDate = currentMoment.toDate();
const startOfDay = currentMoment.startOf("day").toDate();

class PublishRangePicker extends Component<Props, State> {
  changePublishDateTime = (publishDateTime: Date | null) => {
    const {
      changePublishDateTime,
      changeUnpublishDateTime,
      unpublishDateTime,
    } = this.props;

    publishDateTime && moment(publishDateTime).isSameOrBefore(currentDate)
      ? changePublishDateTime(currentDate)
      : changePublishDateTime(publishDateTime);

    if (
      publishDateTime &&
      unpublishDateTime &&
      moment(publishDateTime).isAfter(moment(unpublishDateTime))
    ) {
      changeUnpublishDateTime(publishDateTime);
    }
  };

  changeUnpublishDateTime = (date: Date | null) => {
    const { publishDateTime } = this.props;

    if (publishDateTime && date) {
      const minMoment = moment(publishDateTime).startOf("minute");
      const validatedDate = date ? validateDateTime(date, minMoment) : date;
      this.props.changeUnpublishDateTime(validatedDate);
    } else {
      this.props.changeUnpublishDateTime(date);
    }
  };

  render() {
    const {
      publishDateTime,
      unpublishDateTime,
      publishDateTimeErrors,
      unpublishDateTimeErrors,
    } = this.props;
    // if unpublishDate is not set - minTime is publishDate
    // otherwise if both publish and unpublish date are set and the day matches
    // - minTime is publishDate time. if both dates set and don't match -
    // minTime is start of day
    const unpublishMinTime =
      publishDateTime && unpublishDateTime
        ? moment(publishDateTime).isSame(unpublishDateTime, "day")
          ? publishDateTime
          : startOfDay
        : publishDateTime;

    const publishMinTime = publishDateTime
      ? moment(publishDateTime).isSame(currentDate, "day")
        ? currentDate
        : startOfDay
      : currentDate;

    return (
      <Container>
        <DateTimePicker
          date={publishDateTime}
          dateLabel={strings("dateTimePicker.publishOn")}
          onChange={this.changePublishDateTime}
          minDate={currentDate}
          minTime={publishMinTime}
          errors={publishDateTimeErrors}
        />
        <DateTimePicker
          disabled={!publishDateTime}
          date={unpublishDateTime}
          dateLabel={strings("dateTimePicker.unpublishOn")}
          onChange={this.changeUnpublishDateTime}
          minDate={publishDateTime || currentDate}
          minTime={unpublishMinTime}
          errors={unpublishDateTimeErrors}
        />
      </Container>
    );
  }
}

export default PublishRangePicker;
