import React, { useEffect, useState } from "react";
import moment from "moment";
import { DateTimeField, Wrapper } from "components/forms/FormFields/DateTime";
import strings from "localisation/strings";
import { defaultDateTimeFormat } from "components/forms/FormFields/DateTime/helpers";
import useLocale from "common/hooks/useLocale";
import DateTimePicker from "components/inputs/DateTimePicker";
import { Placement } from "@floating-ui/react";

interface Props {
  value?: string;
  setValue?: (value?: string) => void;
  valueFormat?: string;
  getDateFromValue?: (value?: string) => Date | null;
  isErrored?: boolean;
  width?: string;
  dateFormat?: string;
  timeFormat?: string;
  placeholder?: string;
  popperPlacement?: Placement;
  minDate?: Date;
  maxDate?: Date;
}

const Date = (props: Props) => {
  const {
    value,
    setValue,
    width,
    valueFormat = defaultDateTimeFormat,
    getDateFromValue,
    dateFormat = "MMM d yyyy", // datepicker format differs from moment
    placeholder = "",
    isErrored,
    ...rest
  } = props;

  const initialDate = getDateFromValue ? getDateFromValue(value) : null;
  const [date, setDate] = useState<Date | null>(initialDate);
  const locale = useLocale();
  const localizedDateFormat = locale === "en" ? "MMM d yyyy" : "d. MMM yyyy";

  const onChange = (d: Date) => {
    setDate(d);
    if (setValue) setValue(moment(d).format(valueFormat));
  };

  const inputPlaceholder = placeholder || strings("dateTimePicker.date");

  useEffect(() => {
    const newValue = getDateFromValue ? getDateFromValue(value) : null;
    setDate(newValue);
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Wrapper width={width}>
      <DateTimePicker
        selected={date}
        onChange={onChange}
        dateFormat={localizedDateFormat}
        customInput={
          <DateTimeField
            isErrored={isErrored}
            inputPlaceholder={inputPlaceholder}
          />
        }
        {...rest}
      />
    </Wrapper>
  );
};

export default Date;
