import React, { useEffect, useRef, useState } from "react";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
} from "api/models/ReviewInterface";
import useFormFields from "hooks/useFormFields";
import { setReplyFields } from "components/helpers/formHelpers/reply";
import strings from "localisation/strings";
import ReviewReplySaveButton from "components/review/ReviewReplySaveButton";
import styled from "style/styled-components";
import TextButton from "components/buttons/TextButton";
import ActionArea from "components/generic/ActionArea";
import { AbortablePayloadRequest } from "common/api/AbortablePayloadRequest";
import AutoSizableInlineTextAreaField from "components/forms/FormFields/AutoSizableInlineTextAreaField";

const CancelToggle = styled(TextButton)`
  margin: ${({ theme }) => `0px ${theme.margin.large}`};
`;
const CharactersCount = styled.span`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: ${({ theme }) => theme.formElements.text.height}px;
  color: ${({ theme }) => theme.color.foreground.tertiary};
  padding: ${({ theme }) => `${theme.margin.medium} ${theme.margin.xlarge}`};
  padding-left: 0px;
  width: ${({ theme }) => `${theme.content.sizes.small}`};
  height: 100%;
  justify-content: flex-start;
`;
const FormActions = styled(ActionArea)`
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

interface SaveReplyProps {
  onCancel: () => void;
  reply?: ReplyDetailsInterface;
  saveReply: AbortablePayloadRequest<
    ReviewReplyPayloadInterface,
    ReplyDetailsInterface
  >;
  onReplySaved: (result: ReplyDetailsInterface) => void;
}

const MAX_ALLOWED_REPLY_LENGTH = 1000;

const SaveReplyForm = ({
  onCancel,
  reply,
  saveReply,
  onReplySaved,
}: SaveReplyProps) => {
  const [oldContent, setOldContent] = useState<
    Partial<ReplyDetailsInterface>
  >();
  const [isNavigationBlocked, setBlockNavigation] = useState<boolean>(false);

  const {
    parseErrors,
    clearErrors,
    values,
    updateValues,
    getTextAreaFieldProps,
    unblockNavigation,
  } = useFormFields({
    setBlockNavigation,
    handleGenericErrors: true,
    textFields: {
      comment: "",
    },
    translationScope: "reviewReply.fields",
  });

  const updateValuesRef = useRef(updateValues);
  useEffect(() => {
    if (reply) {
      setOldContent(reply);
      const updatedFormFields = setReplyFields(reply);
      updateValuesRef.current(updatedFormFields);
    }
  }, [reply]);

  const handleOnClickCancel = async () => {
    onCancel();
    clearErrors();
    await unblockNavigation();
  };

  return (
    <>
      <AutoSizableInlineTextAreaField
        {...getTextAreaFieldProps("comment")}
        placeholder={strings("reviewReply.fields.comment.placeholder")}
        maxLength={MAX_ALLOWED_REPLY_LENGTH}
        autoFocus={true}
      />
      <FormActions position="center">
        <CharactersCount>{values.comment.length} / 1000</CharactersCount>
        <CancelToggle
          onClick={handleOnClickCancel}
          text={strings("reviewReply.cancelButton")}
        />
        <ReviewReplySaveButton
          values={values}
          disabled={values.comment.trim().length === 0}
          isNewReply={!reply}
          oldContent={oldContent}
          saveReply={saveReply}
          onReplySaved={onReplySaved}
          failedRequestCallback={parseErrors}
          isNavigationBlocked={isNavigationBlocked}
          setBlockNavigation={setBlockNavigation}
        />
      </FormActions>
    </>
  );
};

export default SaveReplyForm;
