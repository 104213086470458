import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { Omit, isNil } from "ramda";
import InlineInput from "components/forms/FormFields/InlineInput";

// Formatting functions applicable only to price display
const toNumber = (strNum: string): number | null => {
  if (strNum.length > 0) {
    return Number(strNum.replace(",", "."));
  }
  return null;
};

const toString = (num?: number | null): string => {
  if (num === null || num === undefined) return "";
  return num
    .toFixed(2)
    .toString()
    .replace(".", ",");
};

const priceFormat = (num: string): string =>
  num.replace(/(^[0-9]{0,20}[,.]?[0-9]{0,2}).*/g, "$1").replace(".", ",");

interface NumberInputElement
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value"> {
  value?: number | null;
}

export interface InlineInputProps extends Partial<NumberInputElement> {
  errors?: string[];
  showPlaceholderAsLabel?: boolean;
  inputPlaceholder?: string;
  width?: string;
  setValue: (value: number | null) => void;
  isFormattingDisabled?: boolean;
  testId?: string;
}

const InlineNumberInput = ({ value, setValue, ...props }: InlineInputProps) => {
  const defaultText =
    props.isFormattingDisabled && !isNil(value)
      ? value.toFixed()
      : priceFormat(toString(value));
  const [textValue, setTextValue] = useState<string>(defaultText);

  // handle default value when first value we got is undefined
  useEffect(() => {
    if (textValue || textValue === "") return setTextValue(defaultText);
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultText]);

  // on blur we update the actual value
  const onBlurHandler = () => {
    const newValue = toNumber(priceFormat(textValue));
    setValue(newValue);
  };

  // formatted price input only updates internal state on change
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const newValue = priceFormat(currentValue);
    setTextValue(newValue);
  };

  return (
    <InlineInput
      {...props}
      type="text"
      value={textValue}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
    />
  );
};

export default InlineNumberInput;
