import React from "react";
import useChatSettingsForm from "views/Chat/useChatSettingsForm";
import { RouteComponentProps, withRouter } from "react-router";
import ChatSettingsForm from "components/chat/ChatSettingsForm";
import ChatViewLayout from "views/Chat/ChatViewLayout";
import { ViewContainer } from "components/generic";
import styled from "styled-components";
import { ChatPageParams } from "views/Chat/ChatMessagesView";

const ChatSettingsViewContainer = styled(ViewContainer)`
  flex-grow: 1;
`;

const ChatSettingsView = ({ match }: RouteComponentProps<ChatPageParams>) => {
  const {
    values,
    getCheckboxFieldProps,
    getTextAreaFieldProps,
    presefinedFlows,
    handleChatSettingsFormSubmitted,
    isInitialChatSettingsLoading,
    isChatSettingsFormSubmittable,
  } = useChatSettingsForm(match.params.businessId);

  return (
    <ChatViewLayout>
      <ChatSettingsViewContainer>
        <ChatSettingsForm
          formValues={values}
          dynamicFlowFields={presefinedFlows}
          isFormLoading={isInitialChatSettingsLoading}
          isFormSubmittable={isChatSettingsFormSubmittable}
          onFormSubmitted={handleChatSettingsFormSubmitted}
          getCheckboxFieldProps={getCheckboxFieldProps}
          getTextAreaFieldProps={getTextAreaFieldProps}
        />
      </ChatSettingsViewContainer>
    </ChatViewLayout>
  );
};

export default withRouter(ChatSettingsView);
