import React from "react";
import useLoginState from "hooks/useLoginState";
import BusinessAccountMenu from "components/topBar/menu/BusinessAccountMenu";
import DrilledDownBusinessAccountMenuAdmin from "components/topBar/menu/DrilledDownBusinessAccountMenuAdmin";
import DefaultBusinessAccountMenuAdmin from "components/topBar/menu/DefaultBusinessAccountMenuAdmin";
import CuratorMainBusinessAccountMenu from "components/topBar/menu/CuratorMainBusinessAccountMenu";

interface Props {
  hideBusinessName?: boolean;
  fillBackground?: boolean;
}

const SubNavBusinessMenu = ({ hideBusinessName, fillBackground }: Props) => {
  const { getBusinessId, hasAdminRights, hasCuratorRights } = useLoginState();
  const isAdmin = hasAdminRights();
  const isCurator = hasCuratorRights();
  const isDrilledDownToBusiness = getBusinessId();

  if (!isAdmin && !isCurator) {
    return (
      <BusinessAccountMenu
        hideBusinessName={hideBusinessName}
        fillBackground={fillBackground}
      />
    );
  }
  if (isCurator) {
    return (
      <CuratorMainBusinessAccountMenu
        hideBusinessName={hideBusinessName}
        fillBackground={fillBackground}
      />
    );
  }

  if (isAdmin) {
    return isDrilledDownToBusiness ? (
      <DrilledDownBusinessAccountMenuAdmin
        hideBusinessName={hideBusinessName}
        fillBackground={fillBackground}
      />
    ) : (
      <DefaultBusinessAccountMenuAdmin fillBackground={fillBackground} />
    );
  }
  return null;
};

export default SubNavBusinessMenu;
