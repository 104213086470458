import AuthedAPI from "api/AuthedAPI";
import ListParams from "api/models/ListParams";
import RequestOptions from "api/RequestOptions";
import EventInterface, {
  EventVenue,
  MappedVenues,
} from "api/models/EventInterface";
import { FilterAPIResponse } from "api/models/FilterInterface";
import ListResultType from "api/models/ListResultType";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import EventPayloadInterface from "api/models/EventPayloadInterface";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
  ReviewResponse,
} from "api/models/ReviewInterface";

const eventsAPI = new AuthedAPI({});

const getEvents = (
  body: SimpleSearchInterface | undefined,
  businessId: string | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postListJson<ListResultType<EventInterface>>({
    queryParams,
    options,
    body,
    path: businessId
      ? `events/bo/events/search/${businessId}`
      : "events/bp/events/search",
  });

const getEventsAdmin = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postListJson<ListResultType<EventInterface>>({
    queryParams,
    options,
    body,
    path: "events/bo/events/search",
  });

const getCuratorEventsFromBusiness = (
  body: SimpleSearchInterface | undefined,
  businessId: string | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postListJson<ListResultType<EventInterface>>({
    queryParams,
    options,
    body,
    path: `events/bp/curator/events/search/${businessId}`,
  });

const getEventsCurator = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postListJson<ListResultType<EventInterface>>({
    queryParams,
    options,
    body,
    path: "events/bp/curator/events/search",
  });

const getEvent = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.getJson<EventInterface>({
    queryParams,
    options,
    path: `events/bp/events/${id}`,
  });

const getEventAdmin = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.getJson<EventInterface>({
    queryParams,
    options,
    path: `events/bo/events/${id}`,
  });

const getEventCurator = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.getJson<EventInterface>({
    queryParams,
    options,
    path: `events/bp/curator/events/${id}`,
  });

const createDraftEvent = (
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    options,
    body,
    path: "events/bp/events/drafts",
  });

const createDraftEventAdmin = (
  body: EventPayloadInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "events/bo/events/drafts",
  });

const createDraftEventCurator = (
  body: EventPayloadInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "events/bp/curator/events/drafts",
  });

const updateDraftEvent = (
  id: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bp/events/drafts/${id}`,
  });

const updateDraftEventAdmin = (
  id: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bo/events/drafts/${id}`,
  });

const updateDraftEventCurator = (
  id: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bp/curator/events/drafts/${id}`,
  });

const createEvent = (
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    options,
    body,
    path: "events/bp/events",
  });

const createEventAdmin = (
  body: EventPayloadInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "events/bo/events",
  });

const createEventCurator = (
  body: EventPayloadInterface,
  businessId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    options,
    body: businessId ? { ...body, businessId } : body,
    path: "events/bp/curator/events",
  });

const updateEvent = (
  id: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bp/events/${id}`,
  });

const updateEventAdmin = (
  id: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bo/events/${id}`,
  });

const updateEventCurator = (
  id: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bp/curator/events/${id}`,
  });

const updateEventStatusInList = (
  id: string,
  status: string,
  body: EventPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<EventPayloadInterface>({
    body,
    options,
    path: `events/bo/events/${id}/${status}`,
  });

const getEventsFilters = (
  body: Partial<FilterAPIResponse>,
  businessId: string | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<FilterAPIResponse>({
    options,
    body,
    path: businessId
      ? `events/bo/events/filters/${businessId}`
      : "events/bp/events/filters",
  });

const getReviewsForEvent = (
  eventId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `events/bp/events/${eventId}/reviews`,
  });

const getReviewsForEventAdmin = (
  eventId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  eventsAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `events/bo/events/${eventId}/reviews`,
  });

const saveReplyToReviewForEvent = (
  eventId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `events/bp/events/${eventId}/reviews/${reviewId}/replies`,
  });

const saveReplyToReviewForEventAdmin = (
  eventId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  eventsAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `events/bo/events/${eventId}/reviews/${reviewId}/replies`,
  });

const deleteReplyToReviewForEvent = (
  eventId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.deleteEntity<void>({
    options,
    path: `events/bp/events/${eventId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const deleteReplyToReviewForEventAdmin = (
  eventId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.deleteEntity<void>({
    options,
    path: `events/bo/events/${eventId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

const getExcludedVenuesAdmin = (options: RequestOptions | undefined) =>
  eventsAPI.getList<EventVenue[]>({
    options,
    path: "events/bo/events/venues",
  });

const updateExcludedVenuesAdmin = (
  body: string[],
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<EventVenue[]>({
    options,
    body,
    path: "events/bo/events/venues",
  });

const getExcludedVenuesBusiness = (
  businessId: string,
  options: RequestOptions | undefined,
) =>
  eventsAPI.getList<EventVenue[]>({
    options,
    path: `events/bo/events/venues/${businessId}`,
  });

const updateExcludedVenuesBusiess = (
  businessId: string,
  body: string[],
  options: RequestOptions | undefined,
) =>
  eventsAPI.putJson<MappedVenues>({
    options,
    body,
    path: `events/bo/events/venues/${businessId}`,
  });

export {
  eventsAPI,
  getEvents,
  getEvent,
  getEventAdmin,
  getEventCurator,
  getEventsAdmin,
  getEventsCurator,
  getCuratorEventsFromBusiness,
  createDraftEvent,
  createDraftEventAdmin,
  createDraftEventCurator,
  updateDraftEvent,
  updateDraftEventAdmin,
  updateDraftEventCurator,
  createEvent,
  createEventAdmin,
  createEventCurator,
  updateEvent,
  updateEventAdmin,
  updateEventCurator,
  updateEventStatusInList,
  getEventsFilters,
  getReviewsForEvent,
  getReviewsForEventAdmin,
  getExcludedVenuesAdmin,
  updateExcludedVenuesAdmin,
  getExcludedVenuesBusiness,
  updateExcludedVenuesBusiess,
  saveReplyToReviewForEvent,
  saveReplyToReviewForEventAdmin,
  deleteReplyToReviewForEvent,
  deleteReplyToReviewForEventAdmin,
};
