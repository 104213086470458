import React from "react";
import SimpleText, { BoldText } from "components/generic/SimpleText";
import strings from "localisation/strings";

export interface QuantityForCouponProps {
  quantityTotal?: number;
  participations?: number;
}

const CouponQuantity = ({
  quantityTotal,
  participations,
}: QuantityForCouponProps) => {
  return (
    <SimpleText>
      {strings("offersScreen.offerList.quantity")}
      {": "}
      <BoldText>{participations || 0} </BoldText>
      {quantityTotal
        ? `(${strings("offersScreen.offerList.limit")}: ${quantityTotal})`
        : `(${strings("offersScreen.offerList.unlimited")})`}
    </SimpleText>
  );
};

export default CouponQuantity;
