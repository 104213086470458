import React, { ChangeEvent, Component, ReactNode } from "react";
import { assoc, dissoc, isEmpty } from "ramda";

import styled from "style/styled-components";
import strings from "localisation/strings";
import { PasswordRecoveryPayload } from "api/models/UsersInterface";
import TextField from "components/forms/TextField";
import FormSubtitle from "components/forms/FormSubtitle";
import ActionButton from "components/buttons/ActionButton";
import TextButton from "components/buttons/TextButton";
import { emailRegex } from "common/helpers/validators";
import { removeEmptyValues } from "components/helpers/utils";
import { portalCode } from "api/constants";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

const MarginedTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.margin.medium};
  width: 100%;
`;

const CenteredActionButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.margin.medium};
  align-self: center;
`;

const LoginButton = styled(TextButton)`
  margin-top: ${({ theme }) => theme.margin.large};
  align-self: center;
`;

enum PasswordRecoveryFields {
  portalCode = "portalCode",
  email = "email",
}

type StateValues = { [key in PasswordRecoveryFields]?: string };

type Errors = { [key in PasswordRecoveryFields]?: string[] };

interface PasswordRecoveryFormProps {
  navigateToLogin: () => void;
  onSubmit: (payload: PasswordRecoveryPayload) => void;
  errorMessage: ReactNode | null;
  location: {
    state: {
      recoveryEmail: string;
    };
  };
}

interface PasswordRecoveryFormState {
  values: StateValues;
  errors: Errors;
}

const validatePasswordRecoveryForm = (values: StateValues): Errors => {
  const t = (translationKey: string) =>
    strings(translationKey, { scope: "passwordRecoveryScreen.fields" });

  let errors: Errors = {};

  const { email = "" } = values;

  let emailError = null;

  if (!email) {
    emailError = t(`email.errors.required`);
  } else if (!emailRegex.test(email)) {
    emailError = t(`email.errors.invalid`);
  }

  if (emailError) {
    errors = assoc("email", [emailError], errors);
  }

  return errors;
};

class PasswordRecoveryForm extends Component<
  PasswordRecoveryFormProps & RouteComponentProps,
  PasswordRecoveryFormState
> {
  state: PasswordRecoveryFormState = {
    errors: {},
    values: {
      portalCode,
      email: this.props.location.state.recoveryEmail,
    },
  };

  onChange = (key: PasswordRecoveryFields, value?: string) => {
    this.setState(({ values, errors }) => {
      return {
        values: { ...values, [key]: value },
        errors: dissoc(key, errors),
      };
    });
  };

  getValue = (key: PasswordRecoveryFields) => this.state.values[key];

  getErrors = (key: PasswordRecoveryFields) => this.state.errors[key] || [];

  getFieldProps = (field: PasswordRecoveryFields) => ({
    key: field,
    placeholder: strings(`passwordRecoveryScreen.fields.${field}.title`),
    errors: this.getErrors(field),
  });

  getTextFieldProps = (field: PasswordRecoveryFields) => ({
    ...this.getFieldProps(field),
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      this.onChange(field, event.target.value),
    onEnter: () =>
      this.props.onSubmit(this.state.values as PasswordRecoveryPayload),
    value: this.getValue(field),
  });

  validateFields = () => {
    const errors = validatePasswordRecoveryForm(this.state.values);
    this.setState({ errors });
    return errors;
  };

  onSubmit = () => {
    const errors = this.validateFields();
    if (isEmpty(removeEmptyValues(errors))) {
      this.props.onSubmit(this.state.values as PasswordRecoveryPayload);
    }
  };

  render() {
    const { navigateToLogin, errorMessage } = this.props;

    return (
      <>
        <FormSubtitle>
          {strings("passwordRecoveryScreen.subtitle")}
        </FormSubtitle>

        <MarginedTextField
          {...this.getTextFieldProps(PasswordRecoveryFields.email)}
        />

        <CenteredActionButton
          onClick={this.onSubmit}
          text={strings("passwordRecoveryScreen.submitButton")}
        />

        {errorMessage}

        <LoginButton
          onClick={navigateToLogin}
          text={strings("buttons.logIn")}
        />
      </>
    );
  }
}

export default withRouter(PasswordRecoveryForm);
