import React, { useState, useEffect, useContext } from "react";

export interface PullToRefreshContextProps {
  pullToRefreshEnabled: boolean;
  enablePullToRefresh: () => void;
  disablePullToRefresh: () => void;
}

const PullToRefreshContext = React.createContext<PullToRefreshContextProps>({
  pullToRefreshEnabled: true,
  enablePullToRefresh: () => {},
  disablePullToRefresh: () => {},
});

const overscrollBehaviorStyleKey = "overscroll-behavior" as any;

export const usePullToRefreshPreventer = () => {
  const { enablePullToRefresh, disablePullToRefresh } = useContext(
    PullToRefreshContext,
  );

  return {
    enablePullToRefresh,
    disablePullToRefresh,
  };
};

const PullToRefreshPreventer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [pullToRefreshEnabled, setPullToRefreshEnabled] = useState(true);

  useEffect(() => {
    if (!pullToRefreshEnabled) {
      document.documentElement.style[overscrollBehaviorStyleKey] = "none";

      return () => {
        document.documentElement.style[overscrollBehaviorStyleKey] = "";
      };
    }
  }, [pullToRefreshEnabled]);

  return (
    <PullToRefreshContext.Provider
      value={{
        pullToRefreshEnabled,
        enablePullToRefresh: () => setPullToRefreshEnabled(true),
        disablePullToRefresh: () => setPullToRefreshEnabled(false),
      }}
    >
      {React.Children.only(children)}
    </PullToRefreshContext.Provider>
  );
};

export default PullToRefreshPreventer;
