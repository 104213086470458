import React from "react";
import styled from "style/styled-components";
import ActionButtonText from "components/buttons/ActionButtonText";
import { ActionButtonProps, Container } from "components/buttons/ActionButton";

const ActionButtonTextWithPadding = styled(ActionButtonText)`
  padding: 0px ${({ theme }) => theme.margin.large};
`;

const ActionButtonWithPadding = ({
  text,
  loadingText,
  reference,
  ...props
}: ActionButtonProps) => (
  <Container {...props}>
    <ActionButtonTextWithPadding>
      {loadingText && props.disabled ? loadingText : text}
    </ActionButtonTextWithPadding>
  </Container>
);

export default ActionButtonWithPadding;
