import React from "react";
import { Range } from "react-range";
import rangeThumb from "assets/icons/range-thumb.svg";

export interface SliderProps {
  step: number;
  min: number;
  max: number;
  value: number;
  withMarkInStep?: number;
  onChange: (newValue: number) => void;
}

const Slider = ({
  step,
  min,
  max,
  value,
  withMarkInStep,
  onChange,
}: SliderProps) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={[value]}
      onChange={values => onChange(values[0])}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "6px",
            width: "100%",
            backgroundColor: "#ccc",
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            backgroundColor: "#fff",
            backgroundImage: `url(${rangeThumb})`,
            backgroundSize: "cover",
            width: 25,
            height: 26,
          }}
        />
      )}
      renderMark={({ props, index }) => {
        if (withMarkInStep === undefined) {
          return null;
        }

        return (
          <div
            {...props}
            style={{
              ...props.style,
              width: 2,
              height: 16,
              marginTop: 20,
              backgroundColor:
                withMarkInStep === index ? "#1C363C" : "transparent",
            }}
          />
        );
      }}
    />
  );
};

export default Slider;
