import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { curry } from "ramda";
import strings from "localisation/strings";

import useLoginState from "hooks/useLoginState";
import ListResultType from "api/models/ListResultType";
import EventInterface from "api/models/EventInterface";
import { getEvents, getEventsFilters } from "api/events";

import EventsList from "components/lists/eventList/EventsList";
import FilterableList from "components/lists/FilterableList";
import { SearchRequest } from "components/lists/ListRequestTypes";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";
import InteractiveListInterface from "components/lists/InteractiveListInterface";
import { DEFAULT_SORT } from "components/lists/eventList/constants";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import ListTableView from "views/ListTableView";
import { NEW } from "constants/strings";

const Events = ({ location, history, match }: RouteComponentProps) => {
  const { getBusinessId } = useLoginState();
  const businessId = getBusinessId();

  const postListRequest: SearchRequest<ListResultType<EventInterface>> = ({
    offset,
    limit,
    body,
    q,
  }) =>
    curry(getEvents)(
      { q, filterReq: body, from: offset, size: limit },
      businessId,
      {},
    );

  const postEventFiltersRequest: FiltersRequestType = ({ filters }) =>
    curry(getEventsFilters)({ filters }, businessId);

  const onClick = (id: string) => history.push(`veranstaltungen/${id}`);

  const renderListView = ({
    items,
    onItemClick,
  }: InteractiveListInterface<EventInterface>) => {
    return (
      <EventsList
        emptyIndicatable
        emptyIndicator={strings("listView.noContentFilterable")}
        events={items}
        onItemClick={onItemClick}
      />
    );
  };

  if (businessId) {
    return (
      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_POSTS} isView>
        <FilterableList<EventInterface>
          onItemClick={onClick}
          location={location}
          history={history}
          match={match}
          pageTitle={strings("eventsScreen.title")}
          filterPageTitle={strings("eventsScreen.filterScreenTitle")}
          sortPageTitle={strings("sortScreen.title")}
          searchPageTitle={strings("searchScreen.title")}
          searchRequest={postListRequest}
          postFiltersRequest={postEventFiltersRequest}
          onAddNew={() => onClick(NEW)}
          renderListView={renderListView}
          defaultSort={DEFAULT_SORT}
          redirectOnEmptyResult={true}
        />
      </PrivilegedComponent>
    );
  }

  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_EVENTS_ADMIN} isView>
      <ListTableView<EventInterface> />
    </PrivilegedComponent>
  );
};

export default withRouter(Events);
