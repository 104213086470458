import useLoginState from "hooks/useLoginState";

const useSameBusinessStatus = (businessId: string) => {
  const loginState = useLoginState();
  const adminContextBusinessId = loginState.getBusinessId();
  const currentUserBusinessId = loginState.getCurrentUserBusinessId();
  const isFromSameBusinessContext =
    adminContextBusinessId && businessId === adminContextBusinessId;
  const isFromSameUserBusiness =
    currentUserBusinessId && businessId === currentUserBusinessId;
  return {
    isSameBusiness: isFromSameBusinessContext || isFromSameUserBusiness,
  };
};

export default useSameBusinessStatus;
