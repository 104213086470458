import React from "react";
import styled from "style/styled-components";
import EditButton from "components/buttons/EditButton";
import CopyButton from "components/buttons/CopyButton";
import AddButton from "components/buttons/AddButton";
import { COPY } from "constants/strings";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LevelThreeCategoryButtons = ({
  tagId,
  questionId,
  onClickEdit,
  onClickCopy,
  onClickAdd,
}: {
  tagId: string;
  questionId?: string;
  onClickEdit: (id: string) => void;
  onClickCopy: (id: string) => void;
  onClickAdd: (id: string) => void;
}) => {
  const queryParameter = questionId ? `?qid=${questionId}` : "";

  return (
    <Container>
      <EditButton onClick={() => onClickEdit(tagId)} />
      <CopyButton onClick={() => onClickCopy(`${COPY}?id=${tagId}`)} />
      <AddButton
        onClick={() => onClickAdd(`${tagId}${queryParameter}`)}
        tooltipKey="categoriesScreen.actions.addNewSubCategory"
      />
    </Container>
  );
};

export default LevelThreeCategoryButtons;
