import React, { useCallback, useMemo, useState, useRef } from "react";
import { debounce } from "lodash";
import {
  AvatarProps,
  Bubble,
  BubbleProps,
  Composer,
  ComposerProps,
  Day,
  DayProps,
  GiftedChat,
  IMessage as GiftedChatMessage,
  InputToolbar,
  InputToolbarProps,
  LeftRightStyle,
  LoadEarlier,
  LoadEarlierProps,
  Message,
  MessageProps,
  MessageText,
  MessageTextProps,
  Send,
  SendProps,
  Time,
  TimeProps,
} from "react-native-gifted-chat";
import {
  Message as ChatMessage,
  MessageUser as IUser,
  QuickReplyOption,
  TalkerType,
} from "components/chat/types";
import styled from "style/styled-components";
import { ReactComponent as SendIconSVG } from "assets/icons/send.svg";
import breakpoints from "style/breakpoints";
import deletedEndUserAvatar from "common/assets/icons/deletedEndUserAvatar.svg";
import endUserAvatar from "common/assets/icons/endUserAvatar.svg";
import botAvatar from "common/assets/icons/botAvatar.svg";
import businessUserAvarar from "common/assets/icons/businessUserAvatar.svg";
import strings from "localisation/strings";
import {
  EllipticText,
  Avatar,
  AbsoluteOverlaySpinner,
} from "components/generic";
import InteractiveItem from "components/chat/messagesList/interactiveItems/InteractiveItem";
import {
  ViewStyle,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from "react-native";
import { chatMessagesTestId } from "testing/testId";
import { Platform } from "react-native";

const SCROLLED_TO_TOP_THRESHOLD = 100;
const SCROLL_DEBOUNCE_DURATION = 500;
const CHAT_INPUT_TOOLBAR_HEIGHT = 48;
const CHAT_TOOLBAR_MARGINS = 8;
/**
 * Before updating font size please consider that:
 * iOS auto-zooms focused input when it's font size is less than 16
 * This will break full screen messages list experience
 */
const CHAT_INPUT_FONT_SIZE = 16;
const CHAT_DEFAULT_MARGIN = "10px";

type IMessage = ChatMessage & GiftedChatMessage;

interface Props {
  user: IUser;
  placeholder: string;
  locale: string;
  messages: ChatMessage[];
  isDisabled: boolean;
  isEarlierMessagesAvailable: boolean;
  isEarlierMessagesLoading: boolean;
  messagesRefreshKey: string;
  onLoadEarlierMessagesRequested: () => void;
  onMessagesSent: (messages: ChatMessage[]) => void;
  onInputTextChanged: (inputText: string) => void;
  onQuickReplySent: (
    quickReplyMessageId: string,
    option: QuickReplyOption,
    value?: string,
  ) => void;
  isSystemConversation?: boolean;
}

const ShortEllipticText = styled(EllipticText)`
  max-width: 200px;
  display: inline-block;

  @media ${breakpoints.phoneMax} {
    max-width: 50vw;
  }
`;

if (typeof window.TextEncoder !== "function") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const TextEncodingPolyfill = require("text-encoding");
  window.TextEncoder = TextEncodingPolyfill.TextEncoder;
  window.TextDecoder = TextEncodingPolyfill.TextDecoder;
}

const DayContainer = styled.h4`
  & > div {
    position: relative;
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.margin.small};
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: ${({ theme }) => theme.margin.medium};
      right: ${({ theme }) => theme.margin.medium};
      height: 1px;
      background: ${({ theme }) => theme.color.background.tertiary};
      z-index: -1;
    }

    & > div {
      padding: ${({ theme }) => theme.margin.large};
      background: ${({ theme }) => theme.color.background.primary};
      position: initial;
      div {
        color: ${({ theme }) => theme.color.font.onLightBackground};
        font-size: ${({ theme }) => theme.font.size.pagination};
        font-weight: normal;
      }
    }
  }
`;

const MessageContainer = styled.div`
  max-width: ${({ theme }) => theme.content.sizes.medium};
  width: 100%;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.margin.small} ${theme.margin.medium}`};

  span {
    word-break: break-word;
  }
`;

const InputContainer = styled.div`
  & > div {
    border: none;
    width: 100%;
    max-width: ${({ theme }) => theme.content.sizes.medium};
    margin: 0 auto;
  }

  textarea {
    display: table-cell;
    vertical-align: middle;
    margin-left: ${({ theme }) => theme.margin.medium} !important;
    margin-right: ${({ theme }) => theme.margin.medium} !important;
    margin-top: ${CHAT_TOOLBAR_MARGINS}px;
    margin-bottom: ${CHAT_TOOLBAR_MARGINS}px;
    font-size: ${CHAT_INPUT_FONT_SIZE}px;
    line-height: 1.3 !important;
    flex-basis: 100%;
    background: ${({ theme }) => theme.color.chat.bg.sidebar};
    border-radius: ${({ theme }) => theme.border.radius.medium};
    padding-top: ${(CHAT_INPUT_TOOLBAR_HEIGHT - CHAT_INPUT_FONT_SIZE) / 2}px;
    padding-bottom: ${(CHAT_INPUT_TOOLBAR_HEIGHT - CHAT_INPUT_FONT_SIZE) / 2}px;
    padding-right: 44px !important; // giving space for send button
    padding-left: ${({ theme }) => theme.margin.large}!important;
  }
  @media ${breakpoints.phoneMax} {
    textarea {
      margin-left: ${({ theme }) => theme.margin.large} !important;
      margin-right: ${({ theme }) => theme.margin.large} !important;
    }
  }
`;

interface StyledByPositionElementProps {
  isUsersMessage: boolean;
}

interface StyledByQuickReplyProps {
  isQuickRepliesAvailable: boolean;
}

interface DisableableElementProps {
  isDisabled: boolean;
}

const MessageTextContainer = styled.div<
  StyledByPositionElementProps & StyledByQuickReplyProps
>`
  text-align: left;

  & > div > div:first-child {
    padding: ${({ theme }) => theme.margin.small};
    span {
      margin: 0 !important;
    }
    ${({ theme, isUsersMessage, isQuickRepliesAvailable }) =>
      isUsersMessage
        ? ` background: ${theme.color.chat.message.background.right};
              border-radius: ${theme.border.radius.medium};
              border-top-right-radius: ${theme.border.radius.medium}!important;
              border-bottom-right-radius: 0;
            `
        : `
          background: ${theme.color.chat.message.background.left};
          border-radius: ${theme.border.radius.medium};
          border-bottom-left-radius: 0;
          ${isQuickRepliesAvailable && "border-bottom-right-radius: 0;"}
  `};
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & > div {
      align-items: stretch;
      max-width: 350px;
    }
  }
`;

const SendButtonContainer = styled.div<DisableableElementProps>`
  position: absolute;
  right: 32px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  & > div {
    height: 23px;
  }

  @media ${breakpoints.phoneMax} {
    right: 32px;
  }

  g {
    fill: ${({ theme, isDisabled }) =>
      isDisabled
        ? theme.color.foreground.disabled
        : theme.color.foreground.action};
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    right: 32px;
  }
`;

const TimeContainer = styled.div<StyledByPositionElementProps>`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme, isUsersMessage }) =>
    isUsersMessage
      ? theme.color.chat.message.lightFont.right
      : theme.color.chat.message.lightFont.left};

  & > div {
    margin-bottom: 0;
    margin-right: ${({ theme, isUsersMessage }) =>
      isUsersMessage ? CHAT_DEFAULT_MARGIN : theme.margin.xsmall};
    margin-left: ${({ theme, isUsersMessage }) =>
      isUsersMessage ? theme.margin.xsmall : CHAT_DEFAULT_MARGIN};
  }

  & > div > div {
    color: ${({ theme, isUsersMessage }) =>
      isUsersMessage
        ? theme.color.chat.message.lightFont.right
        : theme.color.chat.message.lightFont.left};
  }
`;

const quickReplyBubbleContainerStyle: LeftRightStyle<React.CSSProperties> = {
  right: {},
  left: {
    alignItems: "stretch",
  },
};

const bubbleContainerStyle: LeftRightStyle<React.CSSProperties> = {
  left: {},
  right: {},
};

const BubbleComponent = (props: BubbleProps<IMessage>) => {
  const isQuickReplyMessageBubble =
    !!props.currentMessage &&
    !!props.currentMessage.quickReplies &&
    ((props.currentMessage as unknown) as IMessage).quickReplies.length > 0;

  return (
    <MessageTextContainer
      isUsersMessage={props.position === "right"}
      isQuickRepliesAvailable={isQuickReplyMessageBubble}
    >
      <Bubble<IMessage>
        {...props}
        containerStyle={
          (isQuickReplyMessageBubble
            ? quickReplyBubbleContainerStyle
            : bubbleContainerStyle) as LeftRightStyle<ViewStyle>
        }
      />
    </MessageTextContainer>
  );
};

const TimeComponent = (props: TimeProps<IMessage>) => {
  const isUsersMessage = props.position === "right";

  return (
    <TimeContainer isUsersMessage={isUsersMessage}>
      <Time {...props} />
      {props.currentMessage && (
        <>
          {!isUsersMessage && props.currentMessage.isSent && (
            <ShortEllipticText>
              {props.currentMessage.user.name}
            </ShortEllipticText>
          )}
          {!props.currentMessage.isSent && (
            <ShortEllipticText>{strings("chat.sendingText")}</ShortEllipticText>
          )}
        </>
      )}
    </TimeContainer>
  );
};

const DayComponent = (props: DayProps<IMessage>) => (
  <DayContainer>
    <Day {...props} />
  </DayContainer>
);

const InputComponent = (props: InputToolbarProps<IMessage>) => (
  <InputContainer {...chatMessagesTestId()}>
    <InputToolbar {...props} />
  </InputContainer>
);

const FooterComponent = () => null;

const MessageComponent = (props: MessageProps<IMessage>) => (
  <MessageContainer>
    <Message {...props} />
  </MessageContainer>
);

const SendComponent = ({
  isDisabled,
  ...props
}: SendProps<IMessage> & DisableableElementProps) => (
  <SendButtonContainer isDisabled={isDisabled}>
    <Send {...props} disabled={isDisabled}>
      <SendIconSVG />
    </Send>
  </SendButtonContainer>
);

const messageTextWithQuickReplyStyle = {
  right: {},
  left: { marginLeft: 4 },
};
const MessageTextComponent = (props: MessageTextProps<IMessage>) => (
  <MessageText
    {...props}
    {...(!!(props.currentMessage && props.currentMessage.quickReplyType) && {
      textStyle: messageTextWithQuickReplyStyle,
    })}
  />
);

const LoadEarlierComponent = (props: LoadEarlierProps) => (
  <LoadEarlier
    {...props}
    containerStyle={{ flexBasis: "auto" }}
    label={strings("chat.loadEarlier")}
  />
);

const CHAT_AVATAR_SIZE = 40; // matches size in endUserAvatar.svg
const AvatarComponent = (
  props: AvatarProps<IMessage> & { isSystemConversation?: boolean },
) => {
  if (
    props.isSystemConversation ||
    (props.currentMessage &&
      props.currentMessage.user.talkerType === TalkerType.BOT)
  ) {
    return <img src={botAvatar} alt={strings("chat.avatarAlt")} />;
  }
  const isAvatarPresent =
    props.currentMessage && props.currentMessage.user.avatar;

  if (props.currentMessage && isAvatarPresent) {
    return (
      <Avatar
        size={CHAT_AVATAR_SIZE}
        picture={props.currentMessage.user.avatar}
      />
    );
  }

  const isEndUserMessage =
    props.currentMessage &&
    props.currentMessage.user.talkerType === TalkerType.USER;

  const userAvatar =
    props.currentMessage &&
    props.currentMessage.user &&
    props.currentMessage.user.deleted
      ? deletedEndUserAvatar
      : endUserAvatar;

  return (
    <img
      src={isEndUserMessage ? userAvatar : businessUserAvarar}
      alt={strings("chat.avatarAlt")}
    />
  );
};

const additionalShouldMessageUpdate = (
  props: MessageProps<IMessage>,
  nextProps: MessageProps<IMessage>,
) => {
  if (!props.currentMessage || !nextProps.currentMessage) {
    return false;
  }

  return (
    nextProps.currentMessage.isInteracted !==
      props.currentMessage.isInteracted ||
    nextProps.currentMessage.selectedValue !==
      props.currentMessage.selectedValue ||
    nextProps.currentMessage.isSent !== props.currentMessage.isSent
  );
};

/**
 * Gifted chat does not allow custom typings for quick replies
 * So we need to cast as any for now
 */
const CustomQuickReplies = ({ currentMessage = {}, onQuickReply }: any) => {
  const {
    _id,
    selectedValue,
    isInteracted,
    quickReplyType,
    quickReplies,
  } = currentMessage;

  if (!quickReplyType || !onQuickReply) {
    return null;
  }

  return (
    <InteractiveItem
      selectedValue={selectedValue}
      isDisabled={isInteracted}
      type={quickReplyType}
      options={quickReplies}
      onInteractionFinished={(option, value) =>
        onQuickReply(_id, option, value)
      }
    />
  );
};

type WebStyleAdaptedToReactNativeTypings = any;
/**
 * Make the messages list invisible so that the initial scroll to bottom is unnoticed
 */
const hiddenStyle: WebStyleAdaptedToReactNativeTypings = {
  visibility: "hidden",
};

/**
 * Sets flex-basis to auto instead of 0% that is default to `flex: 1` so that IE properly sets load more button container height
 */
const headerWrapperStyle: WebStyleAdaptedToReactNativeTypings = {
  flex: 1,
  flexBasis: "auto",
};

const MessagesList = ({
  locale,
  placeholder,
  messages,
  onMessagesSent,
  onQuickReplySent,
  onInputTextChanged,
  user,
  isDisabled,
  isEarlierMessagesAvailable,
  isEarlierMessagesLoading,
  onLoadEarlierMessagesRequested,
  messagesRefreshKey,
  isSystemConversation,
}: Props) => {
  const [temporaryOverlayActive, setTemporaryOverlayActive] = useState(true);
  const [scrolledToTop, setScrolledToTop] = useState(false);
  const extraData = useMemo(
    () => ({
      isEarlierMessagesLoading,
      messagesRefreshKey,
      temporaryOverlayActive,
    }),
    [isEarlierMessagesLoading, temporaryOverlayActive, messagesRefreshKey],
  );

  const renderSend = useCallback(
    (props: SendProps<IMessage>) => {
      return <SendComponent {...props} isDisabled={isDisabled} />;
    },
    [isDisabled],
  );

  const renderAvatar = useCallback(
    (avatarProps: AvatarProps<IMessage>) => {
      return (
        <AvatarComponent
          {...avatarProps}
          isSystemConversation={isSystemConversation}
        />
      );
    },
    [isSystemConversation],
  );

  const giftedChatRef = useRef<GiftedChat<IMessage> | null>(null);
  const handleContentSizeChange = useCallback(() => {
    setTemporaryOverlayActive(false);
    return (
      !scrolledToTop &&
      window.requestAnimationFrame(() =>
        giftedChatRef.current!.scrollToBottom(false),
      )
    );
  }, [scrolledToTop]);

  const toggleScrolledToTop = useCallback((distanceFromTop: number) => {
    setScrolledToTop(distanceFromTop < SCROLLED_TO_TOP_THRESHOLD);
  }, []);
  const handleListScrolled = useCallback(() => {
    debounce((event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { y } = event.nativeEvent.contentOffset;
      toggleScrolledToTop(y);
    }, SCROLL_DEBOUNCE_DURATION);
  }, [toggleScrolledToTop]);

  const listViewProps = useMemo(
    () => ({
      onScroll: handleListScrolled,
      onContentSizeChange: handleContentSizeChange,
    }),
    [handleContentSizeChange],
  );

  const chatComposer = (
    props: ComposerProps & {
      onSend: SendProps<IMessage>["onSend"];
      text: SendProps<IMessage>["text"];
    },
  ) => {
    return (
      <Composer
        {...props}
        textInputProps={{
          ...props.textInputProps,
          blurOnSubmit: Platform.OS === "web",
          onSubmitEditing:
            Platform.OS === "web"
              ? () => {
                  if (props.text && props.onSend) {
                    props.onSend({ text: props.text.trim() }, true);
                  }
                }
              : undefined,
        }}
      />
    );
  };

  return (
    <>
      {temporaryOverlayActive && <AbsoluteOverlaySpinner />}
      <GiftedChat<IMessage>
        ref={giftedChatRef}
        {...(temporaryOverlayActive
          ? {
              messagesContainerStyle: hiddenStyle,
              listViewProps,
            }
          : {
              listViewProps,
              headerWrapperStyle,
            })}
        alignTop={false}
        isTyping={false}
        messages={messages as IMessage[]}
        placeholder={placeholder}
        extraData={extraData}
        dateFormat={"dddd, D. MMMM Y"}
        timeFormat={"HH:mm"}
        onInputTextChanged={onInputTextChanged}
        renderMessageText={MessageTextComponent}
        renderMessage={MessageComponent}
        renderDay={DayComponent}
        renderBubble={BubbleComponent}
        renderInputToolbar={InputComponent}
        renderSend={renderSend}
        renderTime={TimeComponent}
        renderAvatar={renderAvatar}
        renderFooter={FooterComponent}
        renderQuickReplies={CustomQuickReplies}
        onQuickReply={onQuickReplySent as any} // same unsupported custom quick reply types issue, so cast is needed
        shouldUpdateMessage={additionalShouldMessageUpdate}
        inverted={false}
        locale={locale}
        renderAvatarOnTop
        disableComposer={isDisabled}
        loadEarlier={isEarlierMessagesAvailable}
        isLoadingEarlier={isEarlierMessagesLoading}
        onLoadEarlier={onLoadEarlierMessagesRequested}
        minInputToolbarHeight={
          CHAT_INPUT_TOOLBAR_HEIGHT + CHAT_TOOLBAR_MARGINS * 2
        }
        minComposerHeight={CHAT_INPUT_TOOLBAR_HEIGHT}
        renderLoadEarlier={LoadEarlierComponent}
        showAvatarForEveryMessage
        onSend={onMessagesSent}
        user={user}
        renderComposer={chatComposer}
      />
    </>
  );
};

export default MessagesList;
