import React from "react";

import TopNav from "components/topBar/TopNav";
import AppLogo from "components/topBar/AppLogo";
import styled from "style/styled-components";
import BusinessLink from "components/topBar/BusinessLink";
import {
  AntiPrimaryExitIcon,
  Close,
  FullScreen,
  MenuContainer,
} from "components/topBar/HeaderComponents";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

const SecondaryMenuContainer = styled(MenuContainer)`
  background: ${({ theme }) => theme.color.foreground.secondary};
  height: 100vh;
  max-width: 320px;
  overflow: hidden;
`;

export interface MobileMenuProps {
  onHideMobileMenu: () => void;
  visible: boolean;
  appLogoLink: string;
}

const MobileMenu = ({
  onHideMobileMenu,
  visible,
  appLogoLink,
}: MobileMenuProps) => {
  return !visible ? null : (
    <>
      <FullScreen>
        <SecondaryMenuContainer>
          <AppLogo link={appLogoLink} />
          <Close onClick={onHideMobileMenu}>
            <AntiPrimaryExitIcon width={24} height={24} />
          </Close>
          <TopNav responsive />
          <PrivilegedComponent
            id={PrivilegedComponentsIds.MENU_DEFAULT_COMPANY}
          >
            <BusinessLink onClick={onHideMobileMenu} responsive />
          </PrivilegedComponent>
        </SecondaryMenuContainer>
      </FullScreen>
    </>
  );
};

export default MobileMenu;
