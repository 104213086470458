import React from "react";
import { curry } from "ramda";
import strings from "localisation/strings";

import BaseContentInterface from "api/models/BaseContentInterface";
import PublicationStatuses from "api/models/PublicationStatuses";
import APIErrorResponse from "common/api/models/APIErrorResponse";

import useNotification from "hooks/useNotification";

import SubmitButton from "components/forms/SubmitButton";
import ConfirmTooltip from "components/generic/ConfirmTooltip";
import ActionButton from "components/buttons/ActionButton";
import ActionArea from "components/generic/ActionArea";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";

export const getContentId = (content?: Partial<BaseContentInterface>) =>
  content && content.id;
export const getContentStatus = (content?: Partial<BaseContentInterface>) =>
  (content && content.status) ||
  (content && content.profileState) ||
  PublicationStatuses.draft;
export const getUnpublishMessage = (content?: Partial<BaseContentInterface>) =>
  content && content.unpublishMessage;

const getActionButtonTextString = (
  isPublished: boolean,
  values: Values,
): string => {
  if (isPublished) {
    return "unpublish";
  }

  const { publishDateTime } = values || {};
  const now = new Date();
  if (publishDateTime && new Date(publishDateTime) > now) {
    return "schedule";
  }

  return "publish";
};

const getToggledStatus = (
  targetPublicationStatus: PublicationStatuses,
  values: Values,
  content?: Partial<BaseContentInterface>,
) => {
  const { published, unpublished } = PublicationStatuses;
  const contentStatus = getContentStatus(content);
  const isPublished = contentStatus && contentStatus === published;
  const status = isPublished
    ? unpublished
    : contentStatus === unpublished
    ? published
    : targetPublicationStatus;
  const textString = getActionButtonTextString(isPublished, values);

  return {
    isPublished,
    status,
    textString,
  };
};

interface Values {
  [index: string]: any;
}

export const getPublishButton = ({
  isDisabled = false,
  isNew,
  createNewItem,
  successCallback,
  parseErrors,
  values,
  content,
  oldContent,
  getContentPayload,
  setShouldBlockNavigation,
  targetPublicationStatus,
  text,
}: {
  isDisabled?: boolean;
  isNew: boolean;
  createNewItem: any;
  successCallback: (message: string, response: any) => void;
  parseErrors: (response: APIErrorResponse) => void;
  values: Values;
  getContentPayload: any;
  content?: Partial<BaseContentInterface>;
  oldContent?: Partial<BaseContentInterface>;
  setShouldBlockNavigation: (shouldBlockNavigation: boolean) => void;
  targetPublicationStatus?: PublicationStatuses;
  text?: string;
}) => {
  const contentId = getContentId(content);
  const targetStatus = targetPublicationStatus
    ? targetPublicationStatus
    : PublicationStatuses.published;

  const { isPublished, status } = getToggledStatus(
    targetStatus,
    values,
    content,
  );

  const successMessageCallback = (response: Partial<BaseContentInterface>) => {
    const successMessage = isPublished
      ? strings("actionsArea.unpublishedMessage")
      : constructPublishedMessage(response);
    successCallback(successMessage, response);
  };

  const constructPublishedMessage = (
    response: Partial<BaseContentInterface>,
  ) => {
    const general = strings("actionsArea.publishedMessage.general");
    if (!response || response.remainingContentAmount === undefined) {
      return general;
    }
    if (response.remainingContentAmount === 0) {
      return `${general} ${strings(
        "actionsArea.publishedMessage.limitReached",
      )}`;
    }
    return `${general} ${strings("actionsArea.publishedMessage.limitations", {
      remainingContentAmount: response.remainingContentAmount,
    })}`;
  };

  if (isNew) {
    return (
      <SubmitButton<Partial<BaseContentInterface>, any>
        disabled={isDisabled}
        request={createNewItem}
        text={text || strings("buttons.confirm")}
        loadingText={strings("buttons.states.requestInProgress")}
        special="publish"
        getPayload={() => getContentPayload(targetStatus, values, oldContent)}
        successfulRequestCallback={successMessageCallback}
        failedRequestCallback={parseErrors}
        setShouldBlockNavigation={setShouldBlockNavigation}
      />
    );
  }

  if (!contentId) return <></>;

  return (
    <SubmitButton<Partial<BaseContentInterface>, any>
      disabled={isDisabled}
      request={createNewItem(contentId)}
      text={text || strings("buttons.confirm")}
      loadingText={strings("buttons.states.requestInProgress")}
      getPayload={() => getContentPayload(status, values, oldContent)}
      successfulRequestCallback={successMessageCallback}
      failedRequestCallback={parseErrors}
      special="publish"
      setShouldBlockNavigation={setShouldBlockNavigation}
    />
  );
};

interface ActionAreaProps {
  isNew: boolean;
  isSubmitDisabled: boolean;
  values: Values;
  oldContent?: Partial<BaseContentInterface>;
  content?: Partial<BaseContentInterface>;
  successCallback: (message: string, response: any) => void;
  parseErrors: (response: APIErrorResponse) => void;
  clearErrors?: () => void;
  checkNonResponseErrors?: () => boolean;
  updateInitialValues?: () => void;
  createDraftItem: any;
  createNewItem: any;
  getContentPayload: any;
  setShouldBlockNavigation: (shouldBlockNavigation: boolean) => void;
  targetPublicationStatus?: PublicationStatuses;
  publishButtonLabel?: string;
  suppressButtonConfirmation?: boolean;
  isSavingBlocked: boolean;
  isSticky?: boolean;
}

export const ContentActionArea = ({
  isNew,
  isSubmitDisabled = false,
  values,
  content,
  oldContent,
  createDraftItem,
  createNewItem,
  successCallback,
  clearErrors,
  checkNonResponseErrors,
  updateInitialValues,
  parseErrors,
  getContentPayload,
  setShouldBlockNavigation,
  targetPublicationStatus,
  publishButtonLabel,
  suppressButtonConfirmation,
  isSavingBlocked,
  isSticky = true,
}: ActionAreaProps) => {
  const { addErrorNotification } = useNotification();
  const { unblockNavigation } = useGlobalFormBlock();
  const contentId = getContentId(content);
  const contentStatus = getContentStatus(content);
  const { published } = PublicationStatuses;
  const isPublished = contentStatus && contentStatus === published;
  const textString = getActionButtonTextString(isPublished, values);
  const actionButtonLabel = publishButtonLabel
    ? publishButtonLabel
    : strings(`buttons.${textString}`);
  const unpublishMessage = isPublished
    ? getUnpublishMessage(content) || strings("popupMessages.unpublish")
    : strings(`popupMessages.${textString}`);

  const draftRequest = isNew
    ? createDraftItem
    : isPublished
    ? createNewItem(contentId)
    : createDraftItem(contentId);

  const validationErrorMsg = strings("actionsArea.validationError");
  const saveMsg = strings("actionsArea.savedMessage");
  const confirmationTooltip = { id: "confirmation-tooltip" };

  const showValidationErrorMsg = () => {
    addErrorNotification(validationErrorMsg);
  };

  const getPayload = (): any => {
    if (isSavingBlocked) {
      showValidationErrorMsg();
      return undefined;
    }
    return getContentPayload(contentStatus, values, oldContent);
  };

  const success = (
    message: string,
    response: Partial<BaseContentInterface>,
  ) => {
    if (clearErrors) clearErrors();
    if (updateInitialValues) updateInitialValues();

    const isProfileHeldForVerification =
      response.profileState === PublicationStatuses.prePublished;

    if (isProfileHeldForVerification) {
      // eslint-disable-next-line
      message = strings("actionsArea.publishedMessage.awaitingVerification");
    }

    successCallback(message, response);
    unblockNavigation();
  };

  return (
    <ActionArea sticky={isSticky}>
      <SubmitButton<Partial<BaseContentInterface>, any>
        text={strings("buttons.save")}
        loadingText={strings("buttons.states.requestInProgress")}
        request={draftRequest}
        failedRequestCallback={parseErrors}
        getPayload={getPayload}
        successfulRequestCallback={curry(success)(saveMsg)}
        setShouldBlockNavigation={setShouldBlockNavigation}
      />
      {suppressButtonConfirmation ? (
        getPublishButton({
          values,
          isNew,
          parseErrors,
          oldContent,
          content,
          createNewItem,
          getContentPayload,
          setShouldBlockNavigation,
          targetPublicationStatus,
          isDisabled: isSubmitDisabled,
          successCallback: success,
          text: publishButtonLabel,
        })
      ) : (
        <>
          <ActionButton
            text={actionButtonLabel}
            special="publish"
            data-event="click"
            data-tip
            data-for={confirmationTooltip.id}
            disabled={isSubmitDisabled}
          />
          <ConfirmTooltip
            tooltipId={confirmationTooltip.id}
            submitButton={getPublishButton({
              values,
              isNew,
              parseErrors,
              oldContent,
              content,
              createNewItem,
              getContentPayload,
              setShouldBlockNavigation,
              targetPublicationStatus,
              successCallback: success,
            })}
            popupMessage={unpublishMessage}
          />
        </>
      )}
    </ActionArea>
  );
};

export const getActionArea = ContentActionArea;
