import React from "react";
import ReactTooltip from "react-tooltip";
import ActionButton from "components/buttons/ActionButton";
import RequestOptions from "api/RequestOptions";
import useMutation from "common/hooks/useMutation";
import { useHistory } from "react-router";
import { EndDateType } from "api/models/EventInterface";
import DateTimeRange from "api/models/DateTimeRange";
import moment from "moment";

export type SubmitRequest<PayloadType, ResultType> = (
  payload: PayloadType,
  options?: RequestOptions,
) => Promise<{ result: ResultType; headers: Headers; status?: number }>;

export interface SubmitButtonProps<PayloadType, ResultType> {
  getPayload: () => PayloadType | undefined;
  successfulRequestCallback: (result: ResultType) => void;
  failedRequestCallback: (error: any) => void;
  request: SubmitRequest<PayloadType, ResultType>;
  text: string;
  loadingText?: string;
  className?: string;
  disabled?: boolean;
  confirmationText?: string;
  special?: "narrow" | "publish" | "transparent";
  setShouldBlockNavigation?: (shouldBlockNavigation: boolean) => void;
  hideReactTooltip?: boolean;
}

const SubmitButton = <PayloadType, ResultType>({
  request,
  disabled,
  text,
  loadingText,
  special,
  className,
  getPayload,
  confirmationText,
  successfulRequestCallback,
  failedRequestCallback,
  setShouldBlockNavigation,
  hideReactTooltip,
}: SubmitButtonProps<PayloadType, ResultType>) => {
  const history = useHistory();

  const { makeRequest, status } = useMutation(request);

  const isSubmitting = status === "WAITING";

  const onPressSubmit = async () => {
    const modalResponse = confirmationText // TODO: This should loose its purpose once confirmation tooltip is added to everywhere
      ? window.confirm(confirmationText)
      : true;

    const payload = getPayload();

    if (setShouldBlockNavigation) setShouldBlockNavigation(false);
    if (hideReactTooltip) ReactTooltip.hide();

    if (payload && modalResponse) {
      const isEvent = history.location.pathname.includes("veranstaltungen");
      let endDateType;
      let dateTimeRange;

      if (isEvent) {
        ({ endDateType, dateTimeRange } = payload as {
          endDateType?: EndDateType;
          dateTimeRange?: DateTimeRange;
        });
      }
      let payloadMod = payload;

      // if the form is an event form and
      // the selected endDateType is either fullDay or generated and
      // the user has selected atleast a startdate,
      // then the endtime is set to 23:59 of same date.
      if (
        isEvent &&
        dateTimeRange &&
        (endDateType === EndDateType.fullDay ||
          endDateType === EndDateType.generated) &&
        !!dateTimeRange.dateTimeFrom
      ) {
        const newDateTimeRange = {
          dateTimeFrom: dateTimeRange.dateTimeFrom,
          dateTimeTill: moment(dateTimeRange.dateTimeFrom)
            .endOf("day")
            .toISOString(),
        };
        payloadMod = { ...payload, dateTimeRange: newDateTimeRange };
      }

      const response = (await makeRequest(payloadMod)) || {};

      if (!response.error && response.result) {
        successfulRequestCallback(response.result);
      } else {
        failedRequestCallback(response.error);
        if (setShouldBlockNavigation) setShouldBlockNavigation(true);
      }
    }
  };

  return (
    <ActionButton
      className={className}
      text={isSubmitting && loadingText ? loadingText : text}
      disabled={isSubmitting || disabled}
      onClick={onPressSubmit}
      special={special}
    />
  );
};
export default SubmitButton;
