import React from "react";
import useComponentDidMount from "hooks/useComponentDidMount";
import { assoc, isEmpty } from "ramda";

import strings from "localisation/strings";
import styled from "style/styled-components";

import { ResetPasswordPayload } from "api/models/UsersInterface";
import { validatePassword } from "common/helpers/validators";
import useFormFields from "hooks/useFormFields";

import { FieldAligner } from "components/forms/FieldContainer";
import ActionButton from "components/buttons/ActionButton";
import { RequestMakerStatus } from "components/APIRequestMaker";
import { removeEmptyValues } from "components/helpers/utils";
import PasswordStrength, {
  showPasswordStrengthInfo,
} from "components/forms/RegistrationForm/PasswordStrength";
import { inputContainerAutoZoomPrevetionStyles } from "style/GlobalStyle";
import { PasswordToggleField } from "components/forms/LoginForm/LoginForm";

const Fieldset = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.forms.small};
  display: flex;
  flex-flow: column nowrap;
  ${inputContainerAutoZoomPrevetionStyles};
`;

const CenteredActionButton = styled(ActionButton)`
  align-self: center;
`;

interface PasswordChangeFields {
  password: string;
  passwordConfirmation: string;
}

type Errors = { [key in keyof PasswordChangeFields]?: string[] };

interface ChangePasswordFormProps {
  token: string;
  status: RequestMakerStatus;
  onSubmit: (payload: ResetPasswordPayload) => void;
}

const validateChangePasswordForm = (values: PasswordChangeFields): Errors => {
  const t = (translationKey: string) =>
    strings(translationKey, { scope: "changePasswordScreen.fields" });

  let errors: Errors = {};

  const { password = "", passwordConfirmation = "" } = values;

  const passwordErrorType = !password.length
    ? "required"
    : !validatePassword(password)
    ? "weak"
    : undefined;

  let passwordConfirmationErrorType = "";
  if (password !== passwordConfirmation) {
    passwordConfirmationErrorType = "noMatch";
  }
  if (!passwordConfirmation) {
    passwordConfirmationErrorType = "required";
  }

  if (passwordErrorType) {
    errors = assoc(
      "password",
      [t(`password.errors.${passwordErrorType}`)],
      errors,
    );
  }

  if (passwordConfirmationErrorType) {
    errors = assoc(
      "passwordConfirmation",
      [t(`passwordConfirmation.errors.${passwordConfirmationErrorType}`)],
      errors,
    );
  }

  return errors;
};

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { values, setErrors, getTextFieldProps, focusField } = useFormFields({
    translationScope: "changePasswordScreen.fields",
    textFields: {
      password: "",
      passwordConfirmation: "",
    },
  });

  useComponentDidMount(() => {
    focusField("password");
  });

  const validateFields = () => {
    const errors = validateChangePasswordForm(values);
    setErrors(errors);
    return errors;
  };

  const onSubmit = () => {
    const errors = validateFields();
    if (isEmpty(removeEmptyValues(errors))) {
      props.onSubmit({
        ...values,
        token: props.token,
      } as ResetPasswordPayload);
    } else {
      showPasswordStrengthInfo();
    }
  };

  return (
    <Fieldset>
      <PasswordToggleField
        onEnter={() => focusField("passwordConfirmation")}
        {...getTextFieldProps("password")}
      >
        <FieldAligner>
          <PasswordStrength password={values.password} />
        </FieldAligner>
      </PasswordToggleField>
      <PasswordToggleField
        onEnter={onSubmit}
        {...getTextFieldProps("passwordConfirmation")}
      />
      <CenteredActionButton
        text={strings("changePasswordScreen.submitButton")}
        loadingText={strings("buttons.states.requestInProgress")}
        disabled={props.status === RequestMakerStatus.WAITING}
        onClick={onSubmit}
      />
    </Fieldset>
  );
};

export default ChangePasswordForm;
