import SortingInterface from "api/models/SortingInterface";

export const LATEST_EVENTS_SORT: SortingInterface = {
  sortRules: [
    {
      id: "sortRules_modifiedDateTime_desc",
      field: "modifiedDateTime",
      name: "filterableLists.defaultSort",
      order: "desc",
    },
  ],
};

// TODO: replace the hard-coded value with a solution that doesn't break on API values change
export const DEFAULT_SORT: SortingInterface = LATEST_EVENTS_SORT;
