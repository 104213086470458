import React, { useState } from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";
import GenericRichTextContent from "components/generic/richText/GenericRichTextContent";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import Plan from "api/models/Plan";
import { Spinner, SpinnerContainer } from "components/generic/OverlaySpinner";
import { Subsection } from "views/Wizard/sharedComponents";

const ScrollableContractContainer = styled(Subsection)`
  display: flex;
  height: 40vh;
  overflow-y: scroll;
  padding: 0 ${({ theme }) => theme.margin.xlarge};
  border: 1px solid ${({ theme }) => theme.color.border.inputBorder};
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding-bottom: ${({ theme }) => theme.margin.large};
`;

interface Props {
  plan: Plan;
}

interface PlanContractTermsProps {
  contractTermsKey: string;
}

const PlanContractTerms = ({ contractTermsKey }: PlanContractTermsProps) => {
  const [contractStatus, setContractStatus] = useState<
    "loading" | "loaded" | "error"
  >("loading");

  return (
    <>
      <ScrollableContractContainer>
        {contractStatus === "loading" && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {contractStatus === "error" && (
          <Subsection>
            <span>
              {strings("wizardScreen.contract.contractFailedToLoadNotice")}
            </span>
          </Subsection>
        )}
        <GenericRichTextContent
          contentKey={contractTermsKey}
          onContentLoaded={() => setContractStatus("loaded")}
          onContentFailedToLoad={() => setContractStatus("error")}
        />
      </ScrollableContractContainer>
    </>
  );
};

const PlanContract = ({ plan }: Props) => (
  <>
    {plan.contract && (
      <Subsection>
        <RichTextRendering cozy value={plan.contract} />
      </Subsection>
    )}

    {plan.contractTermsKey && (
      <PlanContractTerms contractTermsKey={plan.contractTermsKey} />
    )}
  </>
);

export default PlanContract;
