import React, { ReactNode } from "react";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import Portal from "components/generic/portal/Portal";
import Backdrop from "components/generic/Backdrop";
import Media from "components/generic/Media";
import { CloseButton } from "components/topBar/HeaderComponents";
import styled from "style/styled-components";

const Exit = styled(ExitIcon)`
  width: 24px;
  height: 24px;
`;

const Dialog = styled.div`
  border-radius: 13px 0 13px 13px;
  background-color: ${({ theme }) => theme.color.foreground.antiPrimary};
  border: 1px solid ${({ theme }) => theme.color.foreground.quaternary};
  color: ${({ theme }) => theme.color.foreground.primary};
  display: flex;
  flex-direction: column;
  padding: 20px 40px 25px;
  position: absolute;
  left: 20vw;
  right: 20vw;
  top: 8vh;
  z-index: ${({ theme }) => theme.layers.modal};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
`;

const MobileDialog = styled.div`
  background-color: ${({ theme }) => theme.color.foreground.antiPrimary};
  color: ${({ theme }) => theme.color.foreground.primary};
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.layers.modal};
`;

export interface DialogProps {
  isVisible: boolean;
  onClose: () => void;
  children?: ReactNode;
  showCloseButton?: boolean;
  transparentBackdrop?: boolean;
  className?: string;
}

const DialogComponent = ({
  isVisible,
  showCloseButton,
  onClose,
  children,
  transparentBackdrop,
  className,
}: DialogProps) => {
  return (
    <Portal id={"dialog-root"}>
      <Backdrop
        onClick={onClose}
        isActive={isVisible}
        transparent={transparentBackdrop}
      />
      {isVisible && (
        <>
          <Media desktop>
            <Dialog className={className}>
              {showCloseButton && (
                <CloseButton onClick={onClose}>
                  <Exit />
                </CloseButton>
              )}
              {children}
            </Dialog>
          </Media>
          <Media phone tablet>
            <MobileDialog className={className}>
              {showCloseButton && (
                <CloseButton onClick={onClose}>
                  <Exit />
                </CloseButton>
              )}
              {children}
            </MobileDialog>
          </Media>
        </>
      )}
    </Portal>
  );
};

export default DialogComponent;
