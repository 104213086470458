import React from "react";
import breakpoints from "style/breakpoints";

import { IconedTextButton } from "components/buttons/TextButton";
import strings from "localisation/strings";
import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import styled from "style/styled-components";

const MarginedIcondTextButton = styled(IconedTextButton)`
  margin: auto ${({ theme }) => theme.margin.large};

  @media ${breakpoints.tabletMax} {
    margin: auto;
  }
`;

export interface SortButtonProps {
  toggleSorting: () => void;
  sortName: string;
}

const SortButton = ({ toggleSorting, sortName }: SortButtonProps) => (
  <MarginedIcondTextButton
    icon={<SortIcon width={14} height={14} />}
    onClick={toggleSorting}
    text={`${strings("filterableLists.sortButton")} ${
      (sortName === "filterableLists.defaultSort"
        ? strings("filterableLists.defaultSort")
        : sortName
      ).split("(")[0]
    }`}
  />
);

export default SortButton;
