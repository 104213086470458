import styled from "style/styled-components";

const Badge = styled.div`
  background-color: ${({ theme }) => theme.color.background.secondary};
  height: 22px;
  min-width: 27px;
  padding: 0 ${({ theme }) => theme.margin.medium};
  border-radius: 11px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export default Badge;
