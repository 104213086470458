import styled from "style/styled-components";
import MarketingPurposesButton from "components/forms/MarketingPurposesForm/MarketingPurposesButton";

const StyledMarketingPurposesButton = styled(MarketingPurposesButton)`
  margin-top: 20px;
  margin-bottom: ${({ theme }) => theme.margin.large};
  align-self: flex-end;
  width: 134px;
`;

export { StyledMarketingPurposesButton };
