import React from "react";
import PriceMinMax from "api/models/PriceMinMax";
import { isNil } from "ramda";
import strings from "localisation/strings";
import formatPrice from "common/components/formatters/formatPrice";
import styled from "style/styled-components";

export const PriceNowrap = styled.span`
  white-space: nowrap;
`;

const formatPriceMinMax = (priceMinMax?: PriceMinMax, unit = "€") => {
  if (!priceMinMax) {
    return;
  }

  const { min: minNum, max: maxNum, free } = priceMinMax;

  if (free) return strings("event.free");

  const rangeExists = !isNil(minNum) && !isNil(maxNum);

  const rangeEndpointsDiffer = minNum !== maxNum;

  const min = formatPrice(minNum);
  const max = formatPrice(maxNum);

  if (rangeExists && rangeEndpointsDiffer) {
    return (
      <>
        <PriceNowrap>{min} -</PriceNowrap>{" "}
        <PriceNowrap>
          {max} {unit}
        </PriceNowrap>
      </>
    );
  }

  const price = min || max;

  if (price) {
    return (
      <PriceNowrap>
        {price} {unit}
      </PriceNowrap>
    );
  }
};

export default formatPriceMinMax;
