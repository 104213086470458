import React from "react";
import { Title, HintTooltip } from "components/generic";
import { FieldAligner } from "components/forms/FieldContainer";
import Checkbox, {
  VerticalCheckboxSiblingContainer,
  CheckboxProps,
} from "components/forms/Checkbox";
import strings from "localisation/strings";
import ActionButton from "components/buttons/ActionButton";
import styled from "styled-components";
import FieldMargin from "components/forms/FieldMargin";
import TextAreaBlock, {
  TextAreaProps,
} from "components/forms/ValidatedFields/TextArea";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import { constructOptionFieldName } from "views/Chat/helpers";
import breakpoints from "style/breakpoints";

const ChatSettingsSubmitButton = styled(ActionButton)`
  margin-top: ${({ theme }) => theme.margin.medium};
`;

const RightAlignedItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ChatSettingsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  position: relative;
`;

const ChatSettingsFormWrapper = styled.div`
  width: ${({ theme }) => theme.forms.xlarge};
  max-width: ${({ theme }) => theme.forms.xlarge};

  @media ${breakpoints.phoneMax} {
    width: 100%;
  }
`;

const chatActivatedTooltipData = {
  id: "chatFormFields.chatActivated",
  text: "chatSettingsScreen.fields.chatActivated.label",
  hint: "chatSettingsScreen.fields.chatActivated.tooltip",
};

interface DynamicFlowField {
  code: string;
  enabled: boolean;
  flowTitle: string;
  tooltip: string;
  options: {
    id: string;
    fieldType: "TEXT_AREA";
    fieldValue: string;
  }[];
}

interface Props {
  formValues: Record<string, string | boolean>;
  dynamicFlowFields: DynamicFlowField[];

  isFormLoading: boolean;
  isFormSubmittable: boolean;
  onFormSubmitted: () => void;
  getCheckboxFieldProps: (fieldName: string) => CheckboxProps;
  getTextAreaFieldProps: (fieldName: string) => TextAreaProps;
}

const CHAT_ACTIVATED_FIELD = "chatActivated";
const AUTO_REPLY_FLOW_CODE = "OUT_OF_OFFICE";

const ChatSettingsForm = ({
  formValues,
  dynamicFlowFields,

  isFormLoading,
  isFormSubmittable,
  onFormSubmitted,
  getCheckboxFieldProps,
  getTextAreaFieldProps,
}: Props) => {
  const isChatActivated = formValues[CHAT_ACTIVATED_FIELD];
  const renderDynamicFlowFields = () =>
    dynamicFlowFields.map(
      ({ code: flowFieldName, flowTitle, tooltip, options }) =>
        (flowFieldName !== AUTO_REPLY_FLOW_CODE || isChatActivated) && (
          <FieldMargin key={flowFieldName}>
            <FieldAligner>
              <Checkbox
                {...getCheckboxFieldProps(flowFieldName)}
                text={
                  <span>
                    {flowTitle}
                    {tooltip && (
                      <HintTooltip
                        translateHint={false}
                        tooltipData={{
                          id: flowFieldName,
                          hint: tooltip,
                        }}
                      />
                    )}
                  </span>
                }
              />
              {!!formValues[flowFieldName] &&
                options.map(option => (
                  <VerticalCheckboxSiblingContainer key={option.id}>
                    <TextAreaBlock
                      {...getTextAreaFieldProps(
                        constructOptionFieldName(flowFieldName, option.id),
                      )}
                      minRows={3}
                      bordered={true}
                      placeholder={""}
                    />
                  </VerticalCheckboxSiblingContainer>
                ))}
            </FieldAligner>
          </FieldMargin>
        ),
    );

  return (
    <>
      <Title>{strings("chatSettingsScreen.title")}</Title>
      <ChatSettingsFormContainer>
        {isFormLoading && <AbsoluteOverlaySpinner />}
        <ChatSettingsFormWrapper>
          <FieldMargin>
            <FieldAligner>
              <Checkbox
                {...getCheckboxFieldProps("chatActivated")}
                text={<HintTooltip tooltipData={chatActivatedTooltipData} />}
              />
            </FieldAligner>
          </FieldMargin>

          {dynamicFlowFields && renderDynamicFlowFields()}

          <RightAlignedItemsContainer>
            <ChatSettingsSubmitButton
              special="publish"
              text={strings("chatSettingsScreen.fields.submit")}
              loadingText={strings("buttons.states.requestInProgress")}
              disabled={!isFormSubmittable}
              onClick={onFormSubmitted}
            />
          </RightAlignedItemsContainer>
        </ChatSettingsFormWrapper>
      </ChatSettingsFormContainer>
    </>
  );
};

export default ChatSettingsForm;
