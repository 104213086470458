import React from "react";
import UserDataForm from "components/forms/UserDataForm/UserDataForm";
import {
  UserDataFormValues,
  mapFormValuesToUserPayload,
} from "components/forms/UserDataForm/helpers";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import { PersonalData } from "api/models/UsersInterface";
import strings from "localisation/strings";
import useLoginState from "hooks/useLoginState";
import { putBpPersonalData, putBoPersonalData } from "api/users";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import useNotificationState from "hooks/useNotification";

interface Props {
  initialData: PersonalData | undefined;
}

const AdminPersonalDataForm = ({ initialData }: Props) => {
  const loginState = useLoginState();
  const putRequest = loginState.hasAdminRights()
    ? putBoPersonalData
    : putBpPersonalData;
  const { addSuccessNotification } = useNotificationState();
  const { makeRequest, status, error } = useMutation(putRequest);
  const { unblockNavigation } = useGlobalFormBlock();

  const handlePersonalDataFormSubmitted = async (
    formFields: UserDataFormValues,
  ) => {
    const { userId, ...personalDataFormPayload } = mapFormValuesToUserPayload(
      formFields,
    );
    const response = await makeRequest(personalDataFormPayload);

    if (response && !response.error && response.result) {
      addSuccessNotification(
        strings("personalDetailsForm.successUpdateMessage"),
      );
      await unblockNavigation();
      loginState.setUserName(
        response.result.firstName,
        response.result.lastName,
      );
    }
  };

  return React.createElement(UserDataForm, {
    submitLabel: strings("addRepresentativeForm.fields.submit"),
    defaultUserData: initialData,
    isSubmittable: status !== QueryStatus.WAITING,
    formSubmittedErrors: error,
    onSubmit: handlePersonalDataFormSubmitted,
  });
};

export default AdminPersonalDataForm;
