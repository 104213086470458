enum CellTypes {
  action = "ACTION",
  adminStatusChange = "ADMIN_STATUS_CHANGE",
  categoryLevel = "CATEGORY_LEVEL",
  createdBy = "CREATED_BY",
  date = "DATE",
  dateRange = "DATE_RANGE",
  link = "LINK",
  location = "LOCATION",
  list = "LIST",
  deepList = "DEEP_LIST",
  parent = "PARENT",
  dropdown = "DROPDOWN",
  type = "TYPE",
  businessType = "BUSINESS_TYPE",
  businessName = "BUSINESS_NAME",
  alertNotification = "ALERT",
  pushNotification = "PUSH_NOTIFICATION",
  topicOfTheWeekNotification = "TOPIC_OF_THE_WEED_NOTIFICATION",
  text = "TEXT",
}

export default CellTypes;
