import React from "react";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import IconActionButton from "components/buttons/IconActionButton";

const CopyButton = ({
  onClick,
  order,
  tooltipKey,
  ...rest
}: {
  onClick: () => void;
  order?: number;
  tooltipKey?: string;
}) => (
  <IconActionButton
    onClick={onClick}
    order={order}
    tooltipKey={tooltipKey ? tooltipKey : "buttons.copy"}
    {...rest}
  >
    <CopyIcon />
  </IconActionButton>
);

export default CopyButton;
