import { useCallback, useReducer } from "react";

interface PaginationParams {
  defaultLimit?: number;
  defaultPage?: number;
  defaultOffset?: number;
}

interface PaginationState {
  offset: number;
  limit: number;
  page: number;
}

type PaginationAction =
  | { type: "UPDATE_PAGE"; payload: { page: number } }
  | { type: "RESET_PAGINATION"; payload: { force?: boolean } }
  | { type: "LOAD_NEXT_PAGE" }
  | { type: "UPDATE_PAGE_SIZE"; payload: { newPageLimit: number } };

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const DEFAULT_OFFSET = 0;

const usePagination = (
  {
    defaultLimit = DEFAULT_LIMIT,
    defaultOffset = DEFAULT_OFFSET,
    defaultPage = DEFAULT_PAGE,
  }: PaginationParams = {
    defaultLimit: DEFAULT_LIMIT,
    defaultOffset: DEFAULT_OFFSET,
    defaultPage: DEFAULT_PAGE,
  },
) => {
  const [paginationState, dispatch] = useReducer(reducer, {
    offset: defaultOffset,
    limit: defaultLimit,
    page: defaultPage,
  });

  function reducer(state: PaginationState, action: PaginationAction) {
    switch (action.type) {
      case "UPDATE_PAGE":
        return {
          ...state,
          page: action.payload.page,
          offset: (action.payload.page - 1) * state.limit,
        };
      case "RESET_PAGINATION":
        if (action.payload.force || state.page !== 1 || state.offset !== 0) {
          return {
            ...state,
            page: 1,
            offset: 0,
          };
        }

        return state;
      case "LOAD_NEXT_PAGE":
        return {
          ...state,
          page: state.page + 1,
          offset: state.page * state.limit,
        };
      case "UPDATE_PAGE_SIZE":
        if (action.payload.newPageLimit === state.limit) {
          return state;
        }

        return {
          ...state,
          limit: action.payload.newPageLimit,
          page: 1,
          offset: 0,
        };
    }

    return state;
  }

  const updatePage = useCallback(
    (page: number) => {
      dispatch({
        type: "UPDATE_PAGE",
        payload: {
          page,
        },
      });
    },
    [dispatch],
  );

  const resetPagination = useCallback(
    (force?: boolean) => {
      dispatch({
        type: "RESET_PAGINATION",
        payload: {
          force,
        },
      });
    },
    [dispatch],
  );

  const loadNextPage = useCallback(() => {
    dispatch({
      type: "LOAD_NEXT_PAGE",
    });
  }, [dispatch]);

  const onPageSizeChange = useCallback(
    (newPageLimit: number) => {
      dispatch({
        type: "UPDATE_PAGE_SIZE",
        payload: {
          newPageLimit,
        },
      });
    },
    [dispatch],
  );

  return {
    updatePage,
    loadNextPage,
    resetPagination,
    onPageSizeChange,
    offset: paginationState.offset,
    limit: paginationState.limit,
    page: paginationState.page,
  };
};

export default usePagination;
