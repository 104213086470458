import React from "react";
import EventInterface from "api/models/EventInterface";
import List from "components/lists/List";
import EventListItem from "components/lists/eventList/EventListItem";

export interface EventsListProps {
  emptyIndicator?: string;
  emptyIndicatable?: boolean;
  events: EventInterface[];
  onItemClick: (eventId: string) => void;
}

const EventsList = (props: EventsListProps) => {
  const { events, onItemClick, emptyIndicatable, emptyIndicator } = props;
  return (
    <List<EventInterface>
      emptyIndicator={emptyIndicator}
      emptyIndicatable={emptyIndicatable}
      data={events}
      renderItem={(event, index) => (
        <EventListItem key={index} event={event} onClick={onItemClick} />
      )}
    />
  );
};

export default EventsList;
