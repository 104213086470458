import moment from "moment";
import { prop, isNil } from "ramda";

import TagInterface from "api/models/TagInterface";
import PriceMinMax from "api/models/PriceMinMax";
import DateTimeRange from "api/models/DateTimeRange";
import { AddressLocation } from "api/models/Location";
import PostInterface from "api/models/PostInterface";
import PublicationStatuses from "api/models/PublicationStatuses";
import PostPayloadInterface from "api/models/PostPayloadInterface";
import { nullable } from "utils";
import PictureTypes from "api/models/PictureTypes";
import Picture from "api/models/Picture";
import TopicInterface from "api/models/TopicInterface";

const getPublishDateTime = (publishDateTime?: Date | null) =>
  publishDateTime ? moment(publishDateTime).toISOString() : null;

interface Values {
  [index: string]: any;
}

export const defaultPostFields = {
  textAreaFields: {
    title: "",
    description: "",
  },
  textFields: {
    venue: "",
    pinnedDescription: "",
    inFrameUrl: "",
  },
  tagsFields: {
    tags: [] as TagInterface[],
  },
  imageListFields: {},
  settingsFields: {
    enableComments: true,
    enableRatings: true,
    publishDateTime: null as Date | null,
    unpublishDateTime: null as Date | null,
  },
  priceMinMaxFields: {
    priceMinMax: {} as PriceMinMax,
  },
  dateTimeRangeFields: {
    dateTimeRange: {} as DateTimeRange,
  },
  locationFields: {
    location: {} as AddressLocation,
  },
  checkboxFields: {
    publishAlert: false,
    publishPushNotification: false,
    alertToBusiness: false,
    topicOfTheWeek: false,
    smartService: false,
    jobOffer: false,
  },
  topicsFields: {
    topics: [] as TopicInterface[],
  },
  mapAssignmentFields: {
    mapAssignments: [],
  },
};

export const setPostFields = (post: Partial<PostInterface>) => {
  const {
    publishAlert = false,
    publishPushNotification = false,
    topics,
    alertToBusiness = false,
    topicOfTheWeek = false,
    title = "",
    description = "",
    tags = [],
    pictures,
    priceMinMax = {},
    dateTimeRange = {},
    location = {} as AddressLocation,
    enableComments: comments,
    enableRatings: ratings,
    publishDateTime: publish,
    unpublishDateTime: unpublish,
    jobOffer = false,
    smartService = false,
    inFrameUrl = "",
    hashtags,
    mapAssignments,
  } = post;

  const picturesList = pictures
    ? pictures.filter(
        o => o.type === PictureTypes.main || o.type === PictureTypes.secondary,
      )
    : null;

  const enableComments = comments === undefined ? true : comments;
  const enableRatings = ratings === undefined ? true : ratings;
  const publishDateTime = publish ? moment(publish).toDate() : null;
  const unpublishDateTime = unpublish ? moment(unpublish).toDate() : null;

  return {
    checkboxFields: {
      publishAlert,
      publishPushNotification,
      alertToBusiness,
      topicOfTheWeek,
      jobOffer,
      smartService,
    },
    textFields: {
      inFrameUrl,
      venue: location.venue,
      pinnedDescription: location ? location.pinnedDescription : undefined,
    },
    textAreaFields: {
      title,
      description,
    },
    tagsFields: { tags },
    imageListFields: {
      pictures: picturesList,
    },
    settingsFields: {
      enableComments,
      enableRatings,
      publishDateTime,
      unpublishDateTime,
    },
    priceMinMaxFields: { priceMinMax },
    dateTimeRangeFields: { dateTimeRange },
    locationFields: { location },
    hashtagsFields: { hashtags },
    topicsFields: {
      topics,
    },
    mapAssignmentFields: { mapAssignments },
  };
};

export const getPostPayload = (
  status: PublicationStatuses,
  values: Values,
  oldBody: Partial<PostInterface> = {},
): Partial<PostPayloadInterface> => {
  const {
    unpublishDateTime: unpublish,
    publishDateTime: publish,
    pictures: pictureValues,
    modifiedDateTime: modified,
    publishAlert,
    publishPushNotification,
    topics,
    alertToBusiness,
    topicOfTheWeek,
    title,
    description,
    enableComments,
    enableRatings,
    location,
    dateTimeRange,
    priceMinMax,
    tags,
    venue,
    smartService,
    jobOffer,
    inFrameUrl,
    pinnedDescription,
    hashtags,
    mapAssignments,
  } = values;
  const pictures = pictureValues ? [...pictureValues] : [];

  const tagIds = (tags || []).map(prop("id"));
  const unpublishDateTime = unpublish ? moment(unpublish).toISOString() : null;
  const modifiedDateTime = modified || null;
  const publishDateTime = getPublishDateTime(publish);
  const isPriceMinMaxEmpty =
    isNil(priceMinMax.min) && isNil(priceMinMax.max) && !priceMinMax.free;

  const optionalFields: {
    pictures?: Picture[];
  } = {};

  return {
    ...oldBody,
    status,
    title,
    description,
    enableComments,
    enableRatings,
    dateTimeRange,
    tagIds,
    pictures,
    publishAlert,
    publishPushNotification,
    topics,
    alertToBusiness,
    topicOfTheWeek,
    unpublishDateTime,
    modifiedDateTime,
    publishDateTime,
    smartService,
    jobOffer,
    inFrameUrl,
    hashtags,
    priceMinMax: isPriceMinMaxEmpty ? null : priceMinMax,
    location: {
      venue: nullable(venue),
      city: nullable(location.city),
      houseNo: nullable(location.houseNo),
      postalCode: nullable(location.postalCode),
      street: nullable(location.street),
      latitude: nullable(location.latitude),
      longitude: nullable(location.longitude),
      placeId: nullable(location.placeId),
      pinned: location.pinned,
      pinnedDescription: nullable(pinnedDescription),
    },
    mapAssignments,
    ...optionalFields,
  } as Partial<PostPayloadInterface>;
};
