import React, { ReactElement } from "react";
import styled, { css } from "style/styled-components";

interface IconButtonAlignmentProps {
  alignment?: "top" | "center";
}

const containerStyles = css<IconButtonAlignmentProps>`
  display: flex;
  padding: 0;
  margin: 0 ${({ theme }) => theme.margin.small};
  justify-content: center;
  align-items: ${({ alignment = "center" }) =>
    alignment === "center" ? "center" : "flex-start"};
  cursor: pointer;
`;

const IconButtonContainer = styled.button`
  ${containerStyles}
`;

const IconWrapper = styled.div`
  ${containerStyles}
`;

export interface Props extends IconButtonAlignmentProps {
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  children: ReactElement<any>;
  disabled?: boolean;
}

const TransparentIconButton = ({
  onClick,
  children,
  disabled,
  ...rest
}: Props) =>
  onClick ? (
    <IconButtonContainer onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </IconButtonContainer>
  ) : (
    // for handling onClick-less button with ReactTooltip in IE11
    <IconWrapper {...rest}>{children}</IconWrapper>
  );

export default TransparentIconButton;
