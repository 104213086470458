import React from "react";
import styled from "style/styled-components";

interface Props {
  name: string;
  total: number;
  isSelected: boolean;
  statisticTypeToBeApplied: string;
  setStatisticType: (statisticType: string) => void;
}

const StatisticTypeButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.color.foreground.primary};
  flex: 0 0 auto;
`;

const Container = styled.div`
  display: flex;
  margin-right: 50px;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: ${({ theme }) => theme.margin.xsmall};
`;

const Total = styled.div`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.margin.xsmall};
`;

const SelectIndicator = styled.div<{ isSelected: boolean }>`
  height: 4px;
  width: 100%;
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.color.foreground.action
      : theme.color.background.primary};
`;

const StatisticTypeTab = ({
  name,
  total,
  isSelected,
  statisticTypeToBeApplied,
  setStatisticType,
}: Props) => {
  const onClick = () => setStatisticType(statisticTypeToBeApplied);

  return (
    <StatisticTypeButton disabled={isSelected} onClick={onClick}>
      <Container>
        <Name>{name}</Name>
        <Total>{total}</Total>
      </Container>
      <SelectIndicator isSelected={isSelected} />
    </StatisticTypeButton>
  );
};

export default StatisticTypeTab;
