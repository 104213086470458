import React, { HTMLAttributes } from "react";
import styled from "style/styled-components";
import { ReactComponent as CloseCircleIcon } from "common/assets/icons/close-circle.svg";

const StyledRemoveButton = styled.button`
  cursor: pointer;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type RemoveButtonProps = HTMLAttributes<HTMLButtonElement>;

const RemoveButton = (props: RemoveButtonProps) => (
  <StyledRemoveButton {...props}>
    <CloseCircleIcon />
  </StyledRemoveButton>
);

export default RemoveButton;
