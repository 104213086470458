import Socket from "api/Socket";
import chatState from "state/singletons/chatState";
import loginState from "state/singletons/loginState";
import { apiUrl } from "core/constants";

export default class ChatSocket extends Socket {
  lastChatStateActive: boolean;

  constructor() {
    super(`${apiUrl}chat/bp/ws-chat-private`);

    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.setAuthorisationHeaders = this.setAuthorisationHeaders.bind(this);
    this.toggleConnectionByChatActiveStatus = this.toggleConnectionByChatActiveStatus.bind(
      this,
    );

    loginState.subscribe(this.setAuthorisationHeaders);
    chatState.subscribe(this.toggleConnectionByChatActiveStatus);

    this.lastChatStateActive = chatState.state.active;

    this.setAuthorisationHeaders();
    this.toggleConnectionByChatActiveStatus();
    this.enableChatStateListeners();
  }

  private setAuthorisationHeaders() {
    const serviceToken = loginState.getServiceToken();
    this.setConnectHeaders({
      ...(serviceToken ? { Authorization: `Bearer ${serviceToken}` } : {}),
    });
  }

  private enableChatStateListeners() {
    this.registerConnectListener(async () => {
      await chatState.setConnected();
      // eslint-disable-next-line no-console
      console.log("Connected");
    });
    this.registerDisconnectListener(async () => {
      await chatState.setDisconnected();
      // eslint-disable-next-line no-console
      console.log("Diconnected");
    });
    this.registerConnectingListener(async () => {
      await chatState.setConnecting();
      // eslint-disable-next-line no-console
      console.log("Connecting");
    });
    this.registerErrorListener(async () => {
      // eslint-disable-next-line no-console
      console.log("Error");
    });
  }

  private toggleConnectionByChatActiveStatus() {
    const { active } = chatState.state;
    const hasActiveChanged = active !== this.lastChatStateActive;

    if (!hasActiveChanged) {
      return;
    }

    this.lastChatStateActive = active;

    if (active) {
      this.connect();
    } else {
      this.disconnect();
    }
  }
}
