import styled from "style/styled-components";

const FullHeightOverlay = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.layers.fixed};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.background.primary};
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FullHeightOverlay;
