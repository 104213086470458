import moment from "moment";
import { prop } from "ramda";

import { AddressLocation } from "api/models/Location";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import PublicationStatuses from "api/models/PublicationStatuses";
import PostPayloadInterface from "api/models/PostPayloadInterface";
import PictureTypes from "api/models/PictureTypes";
import { nullable } from "utils";
import Picture from "api/models/Picture";
import TagInterface from "api/models/TagInterface";
import { MapAssignmentPayload } from "api/models/Plan";

interface Values {
  [index: string]: any;
}

const defaultContactInformation = {
  website: "",
  email: "",
  phone: "",
};

const defaultImprintInformation = {
  ...defaultContactInformation,
  legalName: "",
  businessName: "",
  additionalInformation: "",
};

const defaultBusinessHours = {
  comment: "",
  mondayStart: null as string | null,
  mondayEnd: null as string | null,
  mondayEnabled: false,
  tuesdayStart: null as string | null,
  tuesdayEnd: null as string | null,
  tuesdayEnabled: false,
  wednesdayStart: null as string | null,
  wednesdayEnd: null as string | null,
  wednesdayEnabled: false,
  thursdayStart: null as string | null,
  thursdayEnd: null as string | null,
  thursdayEnabled: false,
  fridayStart: null as string | null,
  fridayEnd: null as string | null,
  fridayEnabled: false,
  saturdayStart: null as string | null,
  saturdayEnd: null as string | null,
  saturdayEnabled: false,
  sundayStart: null as string | null,
  sundayEnd: null as string | null,
  sundayEnabled: false,
};

export const defaultBusinessUserProfileFields = {
  textAreaFields: {
    name: "",
    businessDescription: "",
    serviceDescription: "",
  },
  textFields: {
    email: "",
    phone: "",
    website: "",
    venue: "",
    pinnedDescription: "",
  },
  tagsFields: {
    tags: [] as TagInterface[],
  },
  imageFieldsBUP: {
    smallImageUrl: null as string | null,
  },
  imageListFields: {},
  settingsFields: {
    enableComments: true,
    enableRatings: true,
    publishDateTime: null as Date | null,
    unpublishDateTime: null as Date | null,
  },
  mapAssignmentFields: {
    mapAssignments: [],
  },
  locationFields: {
    location: {
      city: "",
      houseNo: "",
      postalCode: "",
      street: "",
      latitude: "",
      longitude: "",
      placeId: "",
      venue: "",
      pinned: "false",
      pinnedDescription: "",
    } as AddressLocation,
  },
  hiddenFields: {
    qrCode: "",
  },
  contactFields: {
    imprintInformation: defaultImprintInformation,
  },
  specialFields: {
    businessHours: defaultBusinessHours,
  },
  checkboxFields: {
    topicOfTheWeek: false,
    pushToTopOfStream: true,
  },
};

export const setBusinessUserProfileFields = (
  businessUserProfile: BusinessUserProfileInterface,
) => {
  const {
    name = "",
    businessDescription = "",
    serviceDescription = "",
    tags = [],
    pictures,
    businessHours,
    contactInformation,
    imprintInformation,
    enableComments: comments,
    enableRatings: ratings,
    publishDateTime: publish,
    unpublishDateTime: unpublish,
    qrCode = "",
    topicOfTheWeek = false,
    pushToTopOfStream = true,
    hashtags,
    mapAssignments,
  } = businessUserProfile;

  const picturesList = pictures
    ? pictures.filter(
        o => o.type === PictureTypes.main || o.type === PictureTypes.secondary,
      )
    : null;

  const smallPicture = pictures
    ? pictures.find(o => o.type === PictureTypes.small)
    : null;

  const imageFields = {
    smallImageUrl: smallPicture ? smallPicture.url : null,
  };

  const enableComments = comments === undefined ? true : comments;
  const enableRatings = ratings === undefined ? true : ratings;
  const publishDateTime = publish ? moment(publish).toDate() : null;
  const unpublishDateTime = unpublish ? moment(unpublish).toDate() : null;

  return {
    textAreaFields: {
      name,
      businessDescription,
      serviceDescription,
    },
    textFields: {
      email: contactInformation ? contactInformation.email : "",
      phone: contactInformation ? contactInformation.phone : "",
      website: contactInformation ? contactInformation.website : "",
      venue: contactInformation ? contactInformation.venue : "",
      pinnedDescription: contactInformation
        ? contactInformation.pinnedDescription
        : "",
    },
    hiddenFields: {
      qrCode,
    },
    tagsFields: { tags },
    imageFieldsBUP: imageFields,
    imageListFields: {
      pictures: picturesList,
    },
    settingsFields: {
      enableComments,
      enableRatings,
      publishDateTime,
      unpublishDateTime,
    },
    mapAssignmentFields: { mapAssignments },
    contactFields: {
      imprintInformation,
    },
    specialFields: {
      businessHours: businessHours ? businessHours : defaultBusinessHours,
    },
    locationFields: {
      location: {
        city: contactInformation ? contactInformation.city : "",
        houseNo: contactInformation ? contactInformation.houseNo : "",
        postalCode: contactInformation ? contactInformation.postalCode : "",
        street: contactInformation ? contactInformation.street : "",
        latitude: contactInformation ? contactInformation.latitude : "",
        longitude: contactInformation ? contactInformation.longitude : "",
        placeId: contactInformation ? contactInformation.placeId : "",
        pinned: contactInformation ? contactInformation.pinned : "false",
      },
    },
    checkboxFields: {
      topicOfTheWeek,
      pushToTopOfStream,
    },
    hashtagsFields: { hashtags },
  };
};

export const getBusinessUserProfilePayload = (
  status: PublicationStatuses,
  values: Values,
  oldBody: BusinessUserProfileInterface,
): Partial<PostPayloadInterface> => {
  const {
    location,
    name,
    email,
    phone,
    website,
    venue,
    imprintInformation,
    businessHours,
    title,
    businessDescription,
    serviceDescription,
    enableComments,
    enableRatings,
    tags,
    smallImageUrl,
    unpublishDateTime,
    publishDateTime,
    pictures: pictureValues,
    topicOfTheWeek,
    pushToTopOfStream,
    pinnedDescription,
    hashtags,
    mapAssignments,
  } = values;
  const pictures = pictureValues ? [...pictureValues] : [];

  const tagIds = (tags || []).map(prop("id"));

  // only include small image object if there's actual fields to pass through
  if (smallImageUrl) {
    pictures.push({
      type: PictureTypes.small,
      url: smallImageUrl,
    });
  }

  const optionalFields: {
    unpublishDateTime?: string | null;
    publishDateTime?: string;
    pictures?: Picture[];
  } = {};

  optionalFields.unpublishDateTime = unpublishDateTime
    ? moment(unpublishDateTime).toISOString()
    : unpublishDateTime;

  optionalFields.publishDateTime = publishDateTime
    ? moment(publishDateTime).toISOString()
    : publishDateTime;

  const contactInformationId =
    oldBody && oldBody.contactInformation
      ? oldBody.contactInformation.id
      : undefined;

  return {
    ...oldBody,
    businessHours,
    name,
    title,
    businessDescription,
    serviceDescription,
    enableComments,
    enableRatings,
    pictures,
    tagIds,
    topicOfTheWeek,
    pushToTopOfStream,
    hashtags,
    imprintInformation: {
      ...imprintInformation,
      id: imprintInformation.id,
      venue: nullable(imprintInformation.venue),
      city: nullable(imprintInformation.city),
      houseNo: nullable(imprintInformation.houseNo),
      postalCode: nullable(imprintInformation.postalCode),
      street: nullable(imprintInformation.street),
      latitude: nullable(imprintInformation.latitude),
      longitude: nullable(imprintInformation.longitude),
      placeId: nullable(imprintInformation.placeId),
    },
    profileState: status,
    contactInformation: {
      phone,
      website,
      email,
      id: contactInformationId,
      venue: nullable(venue),
      city: nullable(location.city),
      houseNo: nullable(location.houseNo),
      postalCode: nullable(location.postalCode),
      street: nullable(location.street),
      latitude: nullable(location.latitude),
      longitude: nullable(location.longitude),
      placeId: nullable(location.placeId),
      pinned: location.pinned,
      pinnedDescription: nullable(pinnedDescription),
    },
    mapAssignments,
    ...optionalFields,
  } as Partial<PostPayloadInterface>;
};
