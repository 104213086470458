import React from "react";
import { curry } from "ramda";
import strings from "localisation/strings";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import IconActionButton from "components/buttons/IconActionButton";
import { startCreatingUserLog } from "api/users";
import { ReactComponent as DownloadIcon } from "assets/icons/downloadCloud.svg";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";
import useNotification from "hooks/useNotification";

interface Props {
  userId: string;
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
  onButtonClick: () => void;
}

const DownloadButton = ({
  userId,
  disabled,
  setDisabled,
  onButtonClick,
}: Props) => {
  const userLogCreatingRequest = curry(startCreatingUserLog);
  const { makeRequest, status } = useMutation(userLogCreatingRequest);
  const { setNotification, addErrorNotification } = useNotification();

  const handleClick = async () => {
    const response = (await makeRequest(userId)) || {};

    if (!response.error && response.result) {
      setDisabled(!response.result.logFileId);
      setNotification(
        strings("adminPublicUsersPage.alert.downloadStarted"),
        NotificationTypes.system,
      );
    } else {
      addErrorNotification(strings("adminPublicUsersPage.errors.generic"));
    }
    onButtonClick();
  };

  const buttonDisabled = disabled || status === QueryStatus.WAITING;

  return (
    <IconActionButton onClick={handleClick} disabled={buttonDisabled}>
      <DownloadIcon opacity={buttonDisabled ? 0.3 : 1} />
    </IconActionButton>
  );
};

export default DownloadButton;
