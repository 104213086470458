import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { curry, includes, replace } from "ramda";
import { History } from "history";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { SUB, COPY, NEW } from "constants/strings";
import DetailedView, { Copy, Details } from "views/DetailedView";
import CategoriesForm from "components/forms/CategoriesForm";
import {
  createMainCategory,
  getMainCategory,
  getSubCategory,
  updateMainCategory,
  updateSubCategory,
} from "api/resources";
import SingleMainCategoryInterface from "api/models/SingleMainCategoryInterface";
import SingleSubCategoryInterface from "api/models/SingleSubCategoryInterface";
import useNotification from "hooks/useNotification";

const getMainCategoryRequest = curry(getMainCategory);
const getSubCategoryRequest = curry(getSubCategory);
const updateCategoryRequest = updateMainCategory;
const updateSubCategoryRequest = updateSubCategory;

interface DetailsFormProps {
  updateItem: any;
  id: string;
  questionId: string;
  success: (message: string, response: any) => void;
  result?: SingleMainCategoryInterface | SingleSubCategoryInterface;
  changedValues: Set<string>;
  setBlockNavigation: (shouldBlockNavigation: boolean) => void;
  isSubCategory: boolean;
  history: History;
}

const CopyForm = (props: any) => <CategoriesForm isNew {...props} />;

const DetailsForm = ({
  updateItem,
  id,
  questionId,
  ...props
}: DetailsFormProps) => {
  const newUpdateItem =
    questionId && questionId.length > 0
      ? updateItem(id, questionId)
      : updateItem(id);
  return <CategoriesForm isNew={false} createNew={newUpdateItem} {...props} />;
};

const changedValues = new Set<string>();

const AdminCategoriesDetailed = (
  props: RouteComponentProps<{ id: string }>,
) => {
  const {
    history,
    match: {
      params: { id },
    },
    location: { pathname, search },
  } = props;
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );
  const [forceDetailsUpdate, setForceDetailsUpdate] = useState<boolean>(true);
  const isNew = id === NEW;
  const isCopy = id === COPY;
  const isSubCategory = includes(SUB, pathname);
  const copyId = replace("?id=", "", search);
  const compare = isSubCategory ? { id, forceDetailsUpdate } : false;

  const updateItem = isSubCategory
    ? updateSubCategoryRequest
    : updateCategoryRequest;

  const { addSuccessNotification } = useNotification();
  const successCallback = (message: string, response: any) => {
    addSuccessNotification(message);
    if (isNew || isCopy) {
      history.push(`/admin/konfiguration/kategorien/${response.id}`);
    } else {
      setForceDetailsUpdate(!forceDetailsUpdate);
    }
  };

  const mainProps = {
    changedValues,
    history,
    specialCompare: compare,
    success: successCallback,
    setBlockNavigation: setShouldBlockNavigation,
    getDetailsRequest: isSubCategory
      ? getSubCategoryRequest
      : getMainCategoryRequest,
  };

  const mainRequests = {
    createNew: createMainCategory,
  };

  return (
    <DetailedView
      fullWidth
      privilegedId={PrivilegedComponentsIds.VIEW_CATEGORIES_ADMIN}
      shouldBlockNavigation={shouldBlockNavigation}
      isCopy={isCopy}
      isNew={isNew}
      form={
        <CategoriesForm
          isSubCategory={isSubCategory}
          isNew={isNew}
          {...mainProps}
          {...mainRequests}
        />
      }
      copy={
        <Copy id={copyId} form={CopyForm} {...mainRequests} {...mainProps} />
      }
      details={
        <Details
          id={id}
          form={DetailsForm}
          updateItem={updateItem}
          update={forceDetailsUpdate}
          isSubCategory={isSubCategory}
          {...mainProps}
        />
      }
    />
  );
};

export default withRouter(AdminCategoriesDetailed);
