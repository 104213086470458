import React from "react";
import { FileResultType, handleFileDownload } from "api/APIHelpers";
import SubmitButton, { SubmitRequest } from "components/forms/SubmitButton";
import { FilterableListLocationState } from "components/lists/ListRequestTypes";
import strings from "localisation/strings";
import useNotificationState from "hooks/useNotification";

export interface ExportButtonProps {
  request: SubmitRequest<Partial<FilterableListLocationState>, FileResultType>;
  getPayload: () => Partial<FilterableListLocationState>;
}

const ExportButton = ({ request, getPayload }: ExportButtonProps) => {
  const { addErrorNotification } = useNotificationState();

  return (
    <SubmitButton
      special="narrow"
      text={strings("tableView.dataExport")}
      loadingText={strings("buttons.states.requestInProgress")}
      request={request}
      failedRequestCallback={error => addErrorNotification(error.detail)}
      getPayload={getPayload}
      successfulRequestCallback={handleFileDownload}
    />
  );
};
export default ExportButton;
