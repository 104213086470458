import APIErrorResponse from "common/api/models/APIErrorResponse";
import strings from "localisation/strings";
import { useRef } from "react";

export const useTagRaceConditionCatcher = () => {
  const requestLoadedRef = useRef(false);

  return {
    filterFieldErrors: (error?: APIErrorResponse) => {
      const fieldErrors = (error && error.fieldsErrors) || [];

      return requestLoadedRef.current
        ? fieldErrors.filter(
            item =>
              item.message !==
              // Currently only General tags being preselected
              strings("gneralTagSelector.districtTagErrorMessage"),
          )
        : fieldErrors;
    },

    onRequestFinished: () => {
      if (!requestLoadedRef.current) {
        requestLoadedRef.current = true;
      }
    },
  };
};
