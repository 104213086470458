import React from "react";

import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";

import PrivilegedComponent from "components/PrivilegedComponent";

import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import ListTableView from "views/ListTableView";

const AdminBusinessProfiles = () => (
  <PrivilegedComponent
    id={PrivilegedComponentsIds.VIEW_BUSINESSES_ADMIN}
    isView
  >
    <ListTableView<BusinessUserProfileInterface> />
  </PrivilegedComponent>
);

export default AdminBusinessProfiles;
