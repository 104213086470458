import React, { Component } from "react";
import List from "components/lists/List";
import SearchInterface from "api/models/SearchInterface";
import BusinessTimelineListItem from "components/lists/businessTimelineList/BusinessTimelineListItem";

export interface BusinessTimelineListProps {
  emptyIndicator?: string;
  emptyIndicatable?: boolean;
  listItems: SearchInterface[];
  onItemClick: (businessProfileId: string, contentType: string) => void;
}

class BusinessTimelineList extends Component<BusinessTimelineListProps> {
  render() {
    const {
      listItems,
      onItemClick,
      emptyIndicatable,
      emptyIndicator,
    } = this.props;
    return (
      <List<SearchInterface>
        emptyIndicator={emptyIndicator}
        emptyIndicatable={emptyIndicatable}
        data={listItems}
        renderItem={(listItem, index) => (
          <BusinessTimelineListItem
            key={index}
            listItem={listItem}
            onItemClick={onItemClick}
          />
        )}
      />
    );
  }
}

export default BusinessTimelineList;
