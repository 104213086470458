import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";

const Button = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  cursor: pointer;
  padding: 11px;
  align-self: flex-start;
  flex: 1 1 auto; // IE11 fix
`;

const SmallButton = styled.button`
  width: 13px;
  height: 13px;
  display: flex;
  cursor: pointer;
  padding: 0px;
  align-self: flex-start;
  flex: 1 1 auto; // IE11 fix
`;

const StyledExitIcon = styled(ExitIcon)`
  width: 100%;
  height: 100%;
`;

interface CloseButtonProps extends HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  className?: string;
}

export const SmallCloseButton = (props: CloseButtonProps) => (
  <SmallButton {...props}>
    <StyledExitIcon />
  </SmallButton>
);
const CloseButton = (props: CloseButtonProps) => (
  <Button {...props}>
    <StyledExitIcon />
  </Button>
);

export default CloseButton;
