import React from "react";
import BusinessRepresentativesView from "views/BusinessRepresentatives/BusinessRepresentativesView";
import { getRepresentativesByBusinessId } from "api/businessRepresentatives";
import { RouteComponentProps, withRouter } from "react-router";
import useRepresentativesList from "views/BusinessRepresentatives/useRepresentativesListView";
import { curry } from "ramda";
import useLoginState from "hooks/useLoginState";
import strings from "localisation/strings";
import { deleteBoRepresentativeById } from "api/backOfficeBusinessRepresentatives";

interface AdminRepresentativeViewRouteParams {
  businessId: string;
}

const makeRepresentativeAdminRoute = (
  businessId: string,
  representativeId: string,
) => `/admin/inhalt/partner/${businessId}/partnervertreter/${representativeId}`;

const AdminRepresentativesView = ({
  history,
  match,
}: RouteComponentProps<AdminRepresentativeViewRouteParams>) => {
  const {
    representatives,
    addRepresentative,
    addCurator,
    editRepresentative,
    totalNumberOfPages,
    totalItems,
    handleDeleteItemClicked,
    page,
    updatePage,
    onPageSizeChange,
    limit,
    loading,
  } = useRepresentativesList({
    makeRepresentativeRoute: curry(makeRepresentativeAdminRoute)(
      match.params.businessId,
    ),
    navigate: history.push,
    listRequest: curry(getRepresentativesByBusinessId)(match.params.businessId),
    deleteRequest: curry(deleteBoRepresentativeById)(match.params.businessId),
    isAdmin: true,
  });

  const { isDefaultBusinessContext } = useLoginState();
  const isDefaultBusiness = isDefaultBusinessContext();

  return React.createElement(BusinessRepresentativesView, {
    loading,
    page,
    updatePage,
    onPageSizeChange,
    limit,
    totalNumberOfPages,
    totalItems,
    handleDeleteItemClicked,
    title: strings(
      isDefaultBusiness
        ? "businessRepresentativeList.titleDefaultBusiness"
        : "businessRepresentativeList.title",
    ),
    list: representatives,
    handleListItemAddClicked: addRepresentative,
    handleListItemClicked: editRepresentative,
    handleAddCuratorClicked: addCurator,
  });
};

export default withRouter(AdminRepresentativesView);
