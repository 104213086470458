import React, { Component, ComponentType } from "react";
import { Subscribe } from "unstated";
import LocaleState from "state/LocaleState";

const withLocaleDependency = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  class WithLocaleDependency extends Component<P> {
    render() {
      return (
        <Subscribe to={[LocaleState]}>
          {() => <WrappedComponent {...this.props} />}
        </Subscribe>
      );
    }
  }

  (WithLocaleDependency as any).displayName = `WithLocaleDependency(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    "Component"})`;

  return WithLocaleDependency;
};

export default withLocaleDependency;
