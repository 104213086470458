import React from "react";
import Dropzone from "react-dropzone";
import styled, { css } from "style/styled-components";
import strings from "localisation/strings";
import FieldErrors from "components/forms/FieldErrors";
import useNotificationState from "hooks/useNotification";
import useMutation from "common/hooks/useMutation";
import { uploadPictureBo, uploadPictureBp } from "api/resources";
import loginState from "state/singletons/loginState";
import ParamValidationError from "common/api/models/ParamValidationError";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { HintTooltip } from "components/generic";
import { dropZoneTestId } from "testing/testId";
import ImageBlobReduce from "image-blob-reduce";
import Pica from "pica";

export interface DragAndDropProps {
  errors?: string[];
  imageUrl?: string | null;
  setImageUrl?: (image: string | null) => void;
  container: string;
  height?: string;
  width?: string;
  parseErrors?: (response: APIErrorResponse) => void;
  setWaitingStatus?: (isWaiting: boolean) => void;
  hasFormErrors?: boolean;
  slideErrors: string[];
  setSlideErrors: (errors: string[]) => void;
  fixedRatio?: boolean;
  isSmall?: boolean;
  placeholder?: string;
  showDistinctError?: boolean;
  showHelpText?: boolean;
  withCropAndZoom: boolean;
  onInitialUpload?: (imageUrl: string) => void;
  setImageType?: (imageType: string) => void;
  setImageFileName?: (imageFileName: string) => void;
}

interface BaseDropContainerProps {
  active?: boolean;
  hasErrors?: boolean;
  imageUrl?: string | null;
  height?: string;
  width?: string;
  fixedRatio?: boolean;
  isSmall: boolean;
}

export const DragAndDropContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.medium};
  width: 100%;
`;

export const DropText = styled.div<{ hasValue?: boolean }>`
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 18px;
  font-size: 15px;
  color: ${({ theme }) => theme.color.foreground.quinary};
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: ${({ theme }) => theme.border.radius.medium};
  padding: ${({ theme }) => theme.margin.medium};
  visibility: ${({ hasValue }) => (hasValue ? "hidden" : "visible")};
`;

export const HintTooltipContainer = styled.div`
  position: absolute;
  top: 81%;
  left: 90%;
`;

export const Link = styled.div`
  color: ${({ theme }) => theme.color.background.secondary};
`;

export const SubText = styled.div<{ hasErrors?: boolean }>`
  font-size: ${({ theme }) => theme.font.size.small};
  white-space: nowrap;
  ${({ hasErrors, theme }) =>
    hasErrors &&
    `
    color: ${theme.color.background.secondary};
  `};
`;

// prettier-ignore
export const DropContainer = styled.div<BaseDropContainerProps>`
  ${({ isSmall }) => (isSmall ? smallContainerCss : mainContainerCss)}
  position: relative;
  outline: 0;
  cursor: pointer;
  background: ${({ theme }) => theme.color.foreground.antiPrimary} url(${({ imageUrl }) => imageUrl}) center/cover;

  :hover {
    ${DropText} {
      visibility: visible;
    }
  }
`;

export const smallContainerCss = css<any>`
  margin-top: -50px;
  margin-left: 42px;
  height: 100px;
  width: 100px;
  border: 1px solid
    ${({ hasErrors, active, theme }) =>
      hasErrors || active ? theme.color.background.secondary : "#cbcbcb"};
  border-radius: 50%;
`;

const mainContainerCss = css<BaseDropContainerProps>`
  margin-top: ${({ theme }) => theme.margin.large};
  padding-top: ${({ theme }) => theme.image.aspectRatio}%;
  max-width: 100%;
  border-radius: 3px;
  ${({ theme, fixedRatio, height, width }) =>
    fixedRatio
      ? `max-height: ${height ? height : "100%"};
    padding-top: ${theme.image.aspectRatio}%;`
      : `height: ${height ? height : "100%"};
  width: ${width ? width : "100%"};`}
  border: 1px dashed
    ${({ hasErrors, active, theme }) =>
      hasErrors || active ? theme.color.background.secondary : "#cbcbcb"};
`;

const AutoDragAndDrop = ({
  imageUrl,
  errors,
  height,
  width,
  setImageUrl,
  parseErrors,
  setWaitingStatus,
  hasFormErrors,
  slideErrors,
  setSlideErrors,
  fixedRatio,
  isSmall = false,
  showHelpText = true,
  placeholder,
  showDistinctError,
  withCropAndZoom,
  onInitialUpload,
  setImageType,
  setImageFileName,
  ...props
}: DragAndDropProps) => {
  const uploadPicture = loginState.hasAdminRights()
    ? uploadPictureBo
    : uploadPictureBp;
  const { makeRequest } = useMutation(uploadPicture);

  const clearErrors = () => {
    setSlideErrors([]);
  };

  const { addErrorNotification } = useNotificationState();

  // Extract image, send as FormData, get imageUrl back
  const onDrop = async (acceptedFiles: File[]) => {
    if (setWaitingStatus) setWaitingStatus(true);
    // TODO: isUploading status [optional]
    clearErrors();

    if (!acceptedFiles || acceptedFiles.length === 0) return;

    if (acceptedFiles[0].size > 15 * 1024 * 1024) {
      addErrorNotification(strings("dragAndDrop.fileSize"));
      if (setWaitingStatus) setWaitingStatus(false);
      return;
    }

    if (withCropAndZoom) {
      // Because of certain devices, the image must be downscaled if necessary
      const pica = Pica({ features: ["js", "wasm", "cib"] });
      const blob = await ImageBlobReduce({ pica }).toBlob(acceptedFiles[0], {
        max: 4096 / Math.sqrt(2),
      });
      const pictureUrl = URL.createObjectURL(blob);

      if (setImageUrl) setImageUrl(pictureUrl);
      if (setImageType) setImageType(acceptedFiles[0].type);
      if (setImageFileName) setImageFileName(acceptedFiles[0].name);
      if (setWaitingStatus) setWaitingStatus(false);
      if (onInitialUpload) onInitialUpload(pictureUrl);
    } else {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("container", props.container);
      const response = (await makeRequest(formData)) || {};

      if (parseErrors && response.error) parseErrors(response.error);

      if (response.error && showDistinctError) {
        addErrorNotification(response.error.detail);
      }

      if (response.error && response.error.paramsErrors) {
        const errorMessages: string[] = [];
        response.error.paramsErrors.forEach(
          (paramError: ParamValidationError) => {
            errorMessages.push(paramError.message);
          },
        );

        setSlideErrors(errorMessages);
      }

      const pictureUrl = (response.result && response.result.url) || null;
      if (setImageUrl) setImageUrl(pictureUrl);
      if (setWaitingStatus) setWaitingStatus(false);
    }
  };

  return (
    <DragAndDropContainer>
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        accept={["image/jpeg", "image/png"]}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <DropContainer
              {...getRootProps()}
              isSmall={isSmall}
              active={isDragActive}
              imageUrl={imageUrl}
              hasErrors={slideErrors.length > 0 || hasFormErrors}
              height={height}
              width={width}
              fixedRatio={fixedRatio}
              {...dropZoneTestId()}
            >
              <input {...getInputProps()} />
              <DropText hasValue={!!imageUrl}>
                {isDragActive ? (
                  <Link>{strings(`dragAndDrop.dropHere`)}</Link>
                ) : (
                  <>
                    {placeholder && <Link>{placeholder}</Link>}
                    {!isSmall && showHelpText && strings(`dragAndDrop.orDrop`)}
                  </>
                )}
              </DropText>
              {isSmall && (
                <HintTooltipContainer>
                  <HintTooltip
                    tooltipData={{
                      id: "promotion-tooltip",
                      hint: "dragAndDrop.hintForSmallOne",
                    }}
                  />
                </HintTooltipContainer>
              )}
            </DropContainer>
          );
        }}
      </Dropzone>
      <FieldErrors errors={slideErrors} />
    </DragAndDropContainer>
  );
};

export default AutoDragAndDrop;
