import React from "react";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
} from "api/models/ReviewInterface";
import strings from "localisation/strings";
import { getReplyPayload } from "components/helpers/formHelpers/reply";
import useNotification from "hooks/useNotification";
import SubmitButton from "components/forms/SubmitButton";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { AbortablePayloadRequest } from "common/api/AbortablePayloadRequest";

interface Values {
  comment: string;
}

interface Props {
  values: Values;
  disabled: boolean;
  isNewReply: boolean;
  oldContent?: Partial<ReplyDetailsInterface>;
  saveReply: AbortablePayloadRequest<
    ReviewReplyPayloadInterface,
    ReplyDetailsInterface
  >;
  onReplySaved: (result: ReplyDetailsInterface) => void;
  failedRequestCallback: (response: APIErrorResponse) => void;
  isNavigationBlocked: boolean;
  setBlockNavigation: (blockNavigation: boolean) => void;
}

const ReviewReplySaveButton = ({
  values,
  disabled,
  isNewReply,
  saveReply,
  onReplySaved,
  failedRequestCallback,
  isNavigationBlocked,
  setBlockNavigation,
}: Props) => {
  const { addErrorNotification } = useNotification();
  const saveBtnKey = isNewReply
    ? "reviewReply.saveNewCommentButton"
    : "reviewReply.saveUpdatedCommentButton";

  const getPayload = (): ReviewReplyPayloadInterface | undefined => {
    if (isNavigationBlocked) {
      addErrorNotification(strings("actionsArea.validationError"));
      return;
    }
    return getReplyPayload(values);
  };

  return (
    <SubmitButton<ReviewReplyPayloadInterface, ReplyDetailsInterface>
      text={strings(saveBtnKey)}
      request={saveReply}
      special="publish"
      disabled={disabled}
      failedRequestCallback={failedRequestCallback}
      getPayload={getPayload}
      successfulRequestCallback={onReplySaved}
      setShouldBlockNavigation={setBlockNavigation}
    />
  );
};

export default ReviewReplySaveButton;
