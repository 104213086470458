import styled from "style/styled-components";

interface IsOpened {
  isOpened: boolean;
}

export const SubCheckboxes = styled.div<IsOpened>`
  display: ${({ isOpened }) => (isOpened ? "block" : "none")};
  padding-left: ${({ theme }) => theme.margin.xlarge};
`;

export const DropdownWindow = styled.div<IsOpened>`
  top: 34px;
  z-index: 2;
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
  padding: 20px 50px 0 0px;
  position: absolute;
  background: ${({ theme }) => theme.color.foreground.antiPrimary};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.component.dropdownBorder};
  max-height: 400px;
  overflow-y: scroll;
  display: ${({ isOpened }) => (isOpened ? "block" : "none")};
`;

export const ArrowContainer = styled.span`
  padding: 5px 22px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;
