import React from "react";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import IconActionButton from "components/buttons/IconActionButton";

const AddButton = ({
  onClick,
  order,
  tooltipKey,
}: {
  onClick: () => void;
  order?: number;
  tooltipKey?: string;
}) => (
  <IconActionButton
    onClick={onClick}
    order={order}
    tooltipKey={tooltipKey ? tooltipKey : "buttons.addNew"}
  >
    <AddIcon />
  </IconActionButton>
);

export default AddButton;
