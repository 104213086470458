import React from "react";
import styled from "style/styled-components";
import ProgressBar from "components/review/ProgressBar";
import SimpleText, { BoldText } from "components/generic/SimpleText";

interface ReviewBarProps {
  title: string;
  count: number;
  total: number;
}

const StyledReviewBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => theme.margin.large} 0;
`;

const TitleText = styled(BoldText)`
  padding-right: ${({ theme }) => theme.margin.medium};
  flex-basis: 80px;
  white-space: nowrap;
`;

const CountText = styled(SimpleText)`
  padding-left: ${({ theme }) => theme.margin.medium};
  text-align: right;
  flex-basis: 75px;
`;

const ReviewPercentageBar = styled(ProgressBar)`
  flex: 4;
  flex-basis: 450px;
  flex-grow: 0;
`;

const ReviewBar = ({ title, count, total }: ReviewBarProps) => (
  <StyledReviewBar>
    <TitleText>{title}</TitleText>
    <ReviewPercentageBar progress={count / total || 0} />
    <CountText>{count}</CountText>
  </StyledReviewBar>
);

export default ReviewBar;
