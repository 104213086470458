import { useEffect, useRef } from "react";

// hook to store and use previous state of the provided value
function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    // store current value in ref
    ref.current = value;
  }, [value]);

  // return previous value (this is always initially "undefined")
  return ref.current;
}

export default usePrevious;
