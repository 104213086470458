import styled from "style/styled-components";

const SectionHeading = styled.h3`
  font-size: ${({ theme }) => theme.font.size.large};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: bold;
  color: ${({ theme }) => theme.color.font.onLightBackground};
`;

export default SectionHeading;
