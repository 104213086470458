import React from "react";
import HeaderArea from "components/lists/header/HeaderArea";
import Media from "components/generic/Media";
import styled from "styled-components";
import BlockTitle from "components/generic/BlockTitle";

const ListViewHeaderTitle = styled(BlockTitle)`
  margin: ${({ theme }) => `0 0 ${theme.margin.medium}`};
`;

interface Props {
  title: string;
  children: React.ReactNode;
}

const ListHeader: React.FC<Props> = ({ children, title }) => (
  <>
    <Media desktop>
      <HeaderArea>
        <ListViewHeaderTitle>{title}</ListViewHeaderTitle>
        {children}
      </HeaderArea>
    </Media>
    <Media tablet phone>
      <ListViewHeaderTitle>{title}</ListViewHeaderTitle>
    </Media>
  </>
);

export default ListHeader;
