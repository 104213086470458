import { useState, useEffect } from "react";
import { AddressLocation } from "api/models/Location";
import { FormErrorsInterface } from "hooks/useFormFields";
import strings from "localisation/strings";
import { curry } from "ramda";
import useGooglePlacesAPI, {
  GeocoderAddressComponent,
  GoogleAddressComponentType,
} from "components/forms/FormFields/AddressAutocomplete/useGooglePlacesAPI";

export interface Suggestion {
  id: string;
  primaryText: string;
  secondaryText: string;
}

export const formatAddressAPIErrors = (apiErrors: FormErrorsInterface = {}) => {
  const requiredFields = [
    "houseNo",
    "postalCode",
    "street",
    "city",
    "locationAddress",
    "contactInformationAddress",
  ];

  const formattedApiErrors = Object.keys(apiErrors).map(fieldName => {
    const error = apiErrors[fieldName];
    if (requiredFields.includes(fieldName) && error) {
      return error[0];
    }

    return null;
  });

  return formattedApiErrors.filter(
    (value): value is string => typeof value === "string",
  );
};

export const findFieldInAddressComponents = (
  addressComponents: GeocoderAddressComponent[],
  fieldName: GoogleAddressComponentType,
) => {
  const addressComponentWithFieldName = addressComponents.find(
    addressComponent => {
      return addressComponent.types.includes(fieldName);
    },
  );

  return addressComponentWithFieldName
    ? addressComponentWithFieldName.long_name
    : "";
};

interface UseAddressAutocompleteParams {
  initialValue?: string;
  apiErrors?: FormErrorsInterface;
  onSelect: (location: AddressLocation) => void;
  onChange: (addressValue: string) => void;
}

const useAddressAutocomplete = ({
  initialValue = "",
  apiErrors,
  onChange,
  onSelect,
}: UseAddressAutocompleteParams) => {
  const [formattedErrors, setFormattedErrors] = useState([] as string[]);
  const [autocompleteQueryValue, setAutocompleteQueryValue] = useState(
    initialValue,
  );

  useEffect(() => {
    setAutocompleteQueryValue(initialValue);
  }, [initialValue]);

  const {
    results,
    isLoading,
    isError,
    isNoAddressFound,
    getPlaceDetails,
    resetResults,
  } = useGooglePlacesAPI({
    query: autocompleteQueryValue,
    queryValidator: query => query.length > 2 && query !== initialValue,
  });

  useEffect(() => {
    setFormattedErrors(formatAddressAPIErrors(apiErrors));
  }, [apiErrors]);

  useEffect(() => {
    if (isError) {
      setFormattedErrors([strings("addressAutocomplete.addressNotFound")]);
    }
  }, [isError]);

  const resetToInitialState = () => {
    setFormattedErrors([]);
    if (isError) {
      selectEmptyAddressLocation();
    }
  };

  const selectEmptyAddressLocation = () => {
    onSelect({} as AddressLocation);
  };

  const selectAddressByPlaceId = async (placeId: string) => {
    const result = await getPlaceDetails(placeId);
    setSelectedLocation(result);
  };

  const setSelectedLocation = (
    location: google.maps.GeocoderResult | undefined,
  ) => {
    if (!location || hasNoLocationDetails(location)) {
      setFormattedErrors([strings("addressAutocomplete.addressNotFound")]);
      return;
    }
    const apiPreparedAddress = mapToAddressLocation(location);
    onSelect(apiPreparedAddress);
    resetResults();
  };

  const hasNoLocationDetails = (location: google.maps.GeocoderResult) =>
    !location.geometry || !location.place_id || !location.address_components;

  const mapToAddressLocation = (
    result: google.maps.GeocoderResult,
  ): AddressLocation => {
    const findFieldInResultAddressComponents = curry(
      findFieldInAddressComponents,
    )(result.address_components);

    return {
      placeId: result.place_id,
      street: findFieldInResultAddressComponents("route"),
      houseNo: findFieldInResultAddressComponents("street_number"),
      postalCode: findFieldInResultAddressComponents("postal_code"),
      city: findFieldInResultAddressComponents("locality"),
      latitude: result.geometry.location.lat().toString(),
      longitude: result.geometry.location.lng().toString(),
      pinned: "false",
      pinnedDescription: "",
    };
  };

  const changeAddressValueAndClearErrors = (address = "") => {
    onChange(address);

    if (formattedErrors.length) {
      setFormattedErrors([]);
    }

    setAutocompleteQueryValue(address);

    if (!address.length) {
      selectEmptyAddressLocation();
    }
  };

  return {
    isLoading,
    isNoAddressFound,
    addressValue: autocompleteQueryValue,
    resetAddress: resetToInitialState,
    selectAddress: selectAddressByPlaceId,
    clearSuggestions: () => {
      setAutocompleteQueryValue(initialValue);
      resetResults();
    },
    suggestions: results.map(
      ({ place_id, structured_formatting }): Suggestion => ({
        id: place_id,
        primaryText: structured_formatting.main_text,
        secondaryText: structured_formatting.secondary_text,
      }),
    ),
    changeAddress: changeAddressValueAndClearErrors,
    autocompleteErrors: formattedErrors,
  };
};

export default useAddressAutocomplete;
