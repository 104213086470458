import React, { useState } from "react";
import Filters from "api/models/Filters";
import PossibleFiltersLoader from "components/lists/filtering/FiltersLoader";
import FilterSelection from "components/lists/filtering/FilterSelection";
import FilteringCommands from "components/lists/filtering/FilteringCommands";
import FiltersRequestType from "components/lists/filtering/FiltersRequestType";

interface FilterScreenProps {
  onConfirm: (appliedFilters: Partial<Filters>) => void;
  appliedFilters?: Partial<Filters>;
  postFiltersRequest: FiltersRequestType;
}

const FilterScreen = ({
  onConfirm,
  appliedFilters: initialAppliedFilters,
  postFiltersRequest,
}: FilterScreenProps) => {
  const [appliedFilters, setAppliedFilters] = useState<Partial<Filters>>(
    initialAppliedFilters || {},
  );

  const onClear = () => setAppliedFilters({});

  return (
    <PossibleFiltersLoader
      appliedFilters={appliedFilters}
      postFiltersRequest={postFiltersRequest}
    >
      {possibleFilters => (
        <>
          <FilterSelection
            possibleFilters={possibleFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
          <FilteringCommands
            onConfirm={() => onConfirm(appliedFilters)}
            onClear={onClear}
          />
        </>
      )}
    </PossibleFiltersLoader>
  );
};

export default FilterScreen;
