import React from "react";
import styled from "style/styled-components";
import theme from "style/theme";

const DotBox = styled.svg`
  max-height: 10px;
  max-width: 10px;
  margin: 0 0 -1px 5px;
`;

const Dot = ({ color }: { color?: string }) => (
  <span>
    <DotBox>
      <circle
        cx="5"
        cy="5"
        r="5"
        fill={color ? color : theme.color.foreground.action}
      />
    </DotBox>
  </span>
);

export default Dot;
