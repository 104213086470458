import { flatten } from "ramda";
import ChatSettingsDTO, {
  UpdateChatSettingsDTO,
} from "api/models/ChatSettingsDTO";
import {
  parseOptionFieldName,
  isFlowOptionFieldName,
  constructOptionFieldName,
} from "views/Chat/helpers";

export interface ChatSettingsFormValues {
  chatActivated: boolean;
}

interface ChatFlowOption {
  id: string;
  fieldType: "TEXT_AREA";
  fieldValue: string;
}

interface ChatFlowMapping {
  code: string;
  enabled: boolean;
  flowTitle: string;
  tooltip: string;
  options: ChatFlowOption[];
}

export interface ChatSettings {
  chatActivated: boolean;
  flowMapping: ChatFlowMapping[];
}

export const mapChatFormValuesToChatSettingsDTO = ({
  chatActivated,
  ...flowMappingFieldsAndTheirOptions // assume all other fields are dynamic flow toggling names
}: ChatSettingsFormValues): UpdateChatSettingsDTO => {
  const flowActivationFields: Record<
    string,
    boolean | string
  > = flowMappingFieldsAndTheirOptions as Record<string, boolean | string>;

  const findFlowOptionFieldNames = (flowCode: string) => {
    return Object.keys(flowMappingFieldsAndTheirOptions).filter(fieldName => {
      const field = parseOptionFieldName(fieldName);
      return field.flowCode === flowCode;
    });
  };

  return {
    chatEnabled: chatActivated,
    flowMapping: Object.keys(flowActivationFields)
      .filter(isFlowOptionFieldName)
      .map(flowCode => ({
        code: flowCode,
        enabled: flowActivationFields[flowCode] as boolean,
        options: findFlowOptionFieldNames(flowCode).map(
          flowOptionFieldName => ({
            id: parseOptionFieldName(flowOptionFieldName).optionId,
            fieldType: "TEXT_AREA", // hardcoded for now since it's the only type that's possible
            fieldValue: flowActivationFields[flowOptionFieldName] as string,
          }),
        ),
      })),
  };
};

export const mapChatSettingsToChatFormValues = (chatSettings: ChatSettings) => {
  const flowCheckboxFields: Record<
    string,
    boolean
  > = chatSettings.flowMapping.reduce(
    (flowCheckboxMap, flow) => ({
      ...flowCheckboxMap,
      [flow.code]: flow.enabled,
    }),
    {},
  );

  interface FlowTextareaField {
    fieldName: string;
    fieldValue: string;
  }

  const flowTextareaFields: FlowTextareaField[] = (flatten(
    chatSettings.flowMapping.map(({ code, options }) =>
      options
        .filter(({ fieldType }) => fieldType === "TEXT_AREA")
        .map(({ id, fieldValue }) => ({
          fieldValue,
          fieldName: constructOptionFieldName(code, id),
        })),
    ),
  ) as unknown) as FlowTextareaField[];

  const flowCheckboxTextareaFields: Record<
    string,
    string
  > = flowTextareaFields.reduce(
    (flowCheckboxMap, flow) => ({
      ...flowCheckboxMap,
      [flow.fieldName]: flow.fieldValue,
    }),
    {},
  );

  return {
    textAreaFields: flowCheckboxTextareaFields,
    checkboxFields: {
      chatActivated: chatSettings.chatActivated,
      ...flowCheckboxFields,
    },
  };
};

export const mapChatSettingsDTOToChatSettings = (
  chatSettings: ChatSettingsDTO = {} as ChatSettingsDTO,
): ChatSettings => {
  return {
    chatActivated: chatSettings.chatEnabled || false,
    flowMapping: (chatSettings.flowMapping || []).map(
      ({ options = [], ...flow }) => ({
        ...flow,
        options,
      }),
    ),
  };
};
