import React, { useState } from "react";
import { path } from "ramda";
import SortingInterface, { SortRule } from "api/models/SortingInterface";
import FilteringCommands from "components/lists/filtering/FilteringCommands";
import List from "components/lists/List";
import RadioButton from "uiComponents/radioButton/RadioButton";
import FilterSection from "components/lists/filtering/FilterSection";
import OverlaySpinner from "components/generic/OverlaySpinner";
import QueryStatus from "common/api/models/QueryStatus";

interface SortScreenProps {
  onConfirm: (appliedSort: SortingInterface) => void;
  appliedSort: SortingInterface;
  sortOptions: SortingInterface;
  status: QueryStatus;
}

const SortScreen = ({
  onConfirm,
  appliedSort: initialAppliedSort,
  sortOptions,
  status,
}: SortScreenProps) => {
  const [appliedSort, setAppliedSort] = useState<SortingInterface>(
    initialAppliedSort,
  );

  const isSortChecked = (id: string) => {
    return appliedSort.sortRules.length
      ? appliedSort.sortRules[0].id === id
      : false;
  };

  const sortingOptions: SortRule[] | undefined = path(
    ["sortRules"],
    sortOptions,
  );

  return (
    <>
      {sortingOptions && sortingOptions.length && (
        <>
          <FilterSection>
            <List<SortRule>
              data={sortingOptions}
              renderItem={option => (
                <RadioButton
                  key={option.id}
                  value={option.id}
                  checked={isSortChecked(option.id)}
                  onChange={() => setAppliedSort({ sortRules: [option] })}
                  label={option.name}
                />
              )}
            />
          </FilterSection>
          <FilteringCommands onConfirm={() => onConfirm(appliedSort)} />
        </>
      )}
      {status === QueryStatus.WAITING && <OverlaySpinner />}
    </>
  );
};

export default SortScreen;
