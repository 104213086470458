import { useState, useEffect, useRef } from "react";
import { equals, slice } from "ramda";

interface CumulationProps<T> {
  items: T[];
  compare?: any;
}

function useCumulation<T>({ items, compare }: CumulationProps<T>) {
  const [savedItems, setSavedItems] = useState<T[]>([]);

  const isDuplicateItemset = equals(
    items,
    slice(-items.length, Infinity, savedItems),
  );

  // save previous items in variable so that async requests
  // that keep old items after compare has changed
  // wouldn't be saved
  const lastItems = useRef<T[]>();
  const comparingObject = JSON.stringify(compare);

  // reset cumulation on change of comparable values
  useEffect(() => {
    lastItems.current = items;
    setSavedItems([]);
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparingObject]);

  useEffect(() => {
    // add items to existing if non-duplicate
    if (!isDuplicateItemset && items !== lastItems.current) {
      setSavedItems([...savedItems, ...items]);
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return savedItems;
}

export default useCumulation;
