import React from "react";
import strings from "localisation/strings";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import briefCaseIcon from "common/assets/icons/briefcase-black.svg";
import TextAreaBlock, {
  TextAreaProps,
} from "components/forms/ValidatedFields/TextArea";

interface DescriptionType extends TextAreaProps {
  label?: string;
}

const DescriptionBlock = ({ errors, label, ...props }: DescriptionType) => (
  <InlineInputGroup
    icon={briefCaseIcon}
    placeholder={label ? label : strings("detailedScreen.description.label")}
    contentOnNewLine
    boldLabel
    margin="0"
  >
    <TextAreaBlock {...props} errors={errors} />
  </InlineInputGroup>
);

export default DescriptionBlock;
