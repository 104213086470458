import React from "react";
import {
  DescriptionArea,
  Details,
  EmojiTitle,
  Image,
  ListItemContainer,
  PriceAndModified,
} from "components/lists/item/BaseListItemComponents";
import SearchInterface from "api/models/SearchInterface";
import strings from "localisation/strings";
import styled, { isMobile } from "style/styled-components";
import useWindowDimensions from "hooks/useWindowDimensions";
import ListItemLocation from "components/lists/item/ListItemLocation";
import ListItemDateTime from "components/lists/item/ListItemDateTime";
import ListItemPriceDisplay, {
  PriceText,
} from "components/lists/item/ListItemPriceDisplay";
import OfferTypes from "api/models/OfferTypes";
import CouponQuantity from "components/lists/item/CouponQuantity";
import ParticipationsQuantity from "components/lists/item/ParticipationsQuantity";
// import { excludeDuplicateTags } from "components/helpers/formHelpers/category";
import { noop } from "utils";
import useSameBusinessStatus from "components/lists/businessTimelineList/useSameBusinessStatus";
import ContentType from "common/api/models/ContentType";

const BaseInfo = styled.div`
  display: flex;
  flex-grow: 2;
`;

const ItemTypeText = styled.span`
  font-size: ${({ theme }) => theme.font.size.pagination};
  color: ${({ theme }) => theme.color.foreground.quinary};
  padding-bottom: ${({ theme }) => theme.margin.small};
`;

const MobilePriceDetails = styled.div`
  & > ${PriceText} {
    padding-bottom: ${({ theme }) => theme.margin.small};
  }
`;

const TimelineListItemContainer = styled(ListItemContainer)`
  height: auto;
`;

const TimelineDetails = styled(Details)`
  margin-left: ${({ theme }) => theme.margin.large};
  flex-grow: 1;
`;

const DisabledItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.background.primary};
  opacity: 0.6;
  border-radius: ${({ theme }) => theme.border.radius.medium};
`;

export interface BusinessTimelineListItemProps {
  listItem: SearchInterface;
  onItemClick: (id: string, contentType: string) => void;
}

const BusinessTimelineListItem = ({
  listItem,
  onItemClick,
}: BusinessTimelineListItemProps) => {
  const {
    location,
    id,
    imageUrl,
    title,
    priceMinMax,
    dateTimeRange,
    type,
    quantityTotal,
    participations,
    contentType,
    businessId,
  } = listItem;
  const { width } = useWindowDimensions();
  const isMobileDevice = isMobile(width);

  const { isSameBusiness } = useSameBusinessStatus(businessId);

  const onClick = isSameBusiness ? () => onItemClick(id, contentType) : noop;

  return (
    <TimelineListItemContainer>
      <BaseInfo>
        <Image
          src={imageUrl}
          onClick={onClick}
          contentType={contentType as ContentType}
        />
        <DescriptionArea>
          <EmojiTitle title={title} onClick={onClick} />
          {isMobileDevice && (
            <MobilePriceDetails>
              <ListItemPriceDisplay priceMinMax={priceMinMax} />
            </MobilePriceDetails>
          )}
          <ListItemLocation location={location} />
          <ListItemDateTime dateTimeRange={dateTimeRange} />
          {type === OfferTypes.coupon && (
            <CouponQuantity
              quantityTotal={quantityTotal}
              participations={participations}
            />
          )}
          {type === OfferTypes.promotion && (
            <ParticipationsQuantity participations={participations} />
          )}
          {/* <Tags tags={excludeDuplicateTags(tags)} /> */}
          <ItemTypeText>
            {strings(`businessTimelineList.contentType.${contentType}`)}
          </ItemTypeText>
        </DescriptionArea>
      </BaseInfo>

      {!isMobileDevice && (
        <TimelineDetails>
          <PriceAndModified>
            <ListItemPriceDisplay priceMinMax={priceMinMax} />
          </PriceAndModified>
        </TimelineDetails>
      )}
      {!isSameBusiness && <DisabledItemOverlay />}
    </TimelineListItemContainer>
  );
};

export default BusinessTimelineListItem;
