import React from "react";
import styled, { isMobile, isDesktop } from "style/styled-components";
import {
  DashboardInterface,
  ContentItemOption,
} from "api/models/DashboardInterface";
import ContentType from "common/api/models/ContentType";
import useWindowDimensions from "hooks/useWindowDimensions";
import StatsRatingStars from "components/review/StatsRatingStars";
import strings from "localisation/strings";
import { SelectList } from "components/table/DataTable/Fields";
import { getContentTypeName } from "utils";
import { SelectItem } from "components/forms/Select";
import { initialSelectedOption } from "components/dashboard/ContentItemOption";
import SelectedContentItemOptionsInterface from "components/dashboard/SelectedContentItemOptionsInterface";
import { getOptionString } from "components/dashboard/DashboardUtils";
import { HintTooltip } from "components/generic";
import getEmojiTextComponents from "components/helpers/getEmojiTextComponents";

interface Props {
  contentType: ContentType;
  fetchedDashboardResult: DashboardInterface;
  fetchedContentItemOptionsResult: ContentItemOption[] | undefined;
  selectedContentItemOptions: SelectedContentItemOptionsInterface;
  setSelectedContentItemOptions: (
    selectedContentItemOptions: SelectedContentItemOptionsInterface,
  ) => void;
}

const InlineBlock = styled.div`
  display: inline-block;
`;

const Container = styled.div<{ isDesktopBrowser: boolean }>`
  display: flex;
  ${({ isDesktopBrowser }) =>
    isDesktopBrowser
      ? `
      flex-direction: row;
      justify-content: space-between;
    `
      : "flex-direction: column;"}
`;

const TitleRatingContainer = styled.div<{ isDesktopBrowser: boolean }>`
  display: flex;
  flex-direction: ${({ isDesktopBrowser }) =>
    isDesktopBrowser ? "row" : "column"};
`;

const Title = styled.div<{ isDesktopBrowser: boolean }>`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.family.firaSans};
  margin-left: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme, isDesktopBrowser }) =>
    isDesktopBrowser ? theme.margin.large : theme.margin.small};
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 200px;
`;

const StatsRatingStarContainer = styled.div`
  margin-left: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

const HintToolTipContainer = styled.div`
  margin-top: 2px;
`;

const RatingNumber = styled.div`
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.family.firaSans};
  margin-top: ${({ theme }) => theme.margin.xsmall};
  margin-left: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.large};
`;

const SelectContainer = styled.div<{
  isMobileDevice: boolean;
  maxWidth: string;
}>`
  ${({ isMobileDevice, maxWidth, theme }) =>
    isMobileDevice
      ? `margin-right: ${theme.margin.large};`
      : `max-width: ${maxWidth};`}
  margin-left: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.large};
  flex: 1;
`;

const ChartHeader = ({
  contentType,
  fetchedDashboardResult,
  fetchedContentItemOptionsResult,
  selectedContentItemOptions,
  setSelectedContentItemOptions,
}: Props) => {
  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);
  const isDesktopBrowser = isDesktop(screen.width);

  const options: SelectItem[] = getFetchedDataAsSelectItems(
    fetchedContentItemOptionsResult,
  );

  const selectedOptionString = getOptionString(contentType);

  const onChange = (value: SelectItem) => {
    const newOptions: SelectedContentItemOptionsInterface = getNewOptions(
      value,
      selectedContentItemOptions,
      contentType,
    );
    setSelectedContentItemOptions(newOptions);
  };

  const selectedValue = selectedContentItemOptions[selectedOptionString];
  const selectListWidth = isMobileDevice ? "100%" : "400px";

  return (
    <InlineBlock>
      <Container isDesktopBrowser={isDesktopBrowser}>
        <TitleRatingContainer isDesktopBrowser={isDesktopBrowser}>
          <Title isDesktopBrowser={isDesktopBrowser}>
            {getEmojiTextComponents(
              selectedValue === initialSelectedOption
                ? strings(
                    `dashboardScreen.titleAll.${getContentTypeName(
                      contentType,
                    )}`,
                  )
                : selectedValue.label,
              20,
            )}
          </Title>
          <RatingContainer>
            <StatsRatingStarContainer>
              <StatsRatingStars
                rating={fetchedDashboardResult.averageRating || 0}
              />
            </StatsRatingStarContainer>
            {fetchedDashboardResult.averageRating && (
              <>
                <RatingNumber>
                  {fetchedDashboardResult.averageRating}
                </RatingNumber>
                <HintToolTipContainer>
                  <HintTooltip
                    tooltipData={{
                      id: "rating-tooltip",
                      hint: "dashboardScreen.ratingTooltip",
                    }}
                  />
                </HintToolTipContainer>
              </>
            )}
          </RatingContainer>
        </TitleRatingContainer>
        <SelectContainer
          isMobileDevice={isMobileDevice}
          maxWidth={selectListWidth}
        >
          <SelectList
            onChange={onChange}
            value={selectedValue}
            options={options}
            width={"100%"}
            isSearchable={true}
            onDashboard={true}
          />
        </SelectContainer>
      </Container>
    </InlineBlock>
  );
};

const getFetchedDataAsSelectItems = (
  fetchedContentItemOptionsResult: ContentItemOption[] | undefined,
): SelectItem[] => {
  const contentItemOptions: SelectItem[] = [];

  contentItemOptions.push(initialSelectedOption);

  if (fetchedContentItemOptionsResult === undefined) {
    return contentItemOptions;
  }

  fetchedContentItemOptionsResult.forEach(requestContentItemOption => {
    const contentItemOption: SelectItem = {
      label: requestContentItemOption.value,
      value: requestContentItemOption.key,
    };

    contentItemOptions.push(contentItemOption);
  });

  return contentItemOptions;
};

const getNewOptions = (
  value: SelectItem,
  selectedContentItemOptions: SelectedContentItemOptionsInterface,
  contentType: ContentType,
): SelectedContentItemOptionsInterface => {
  switch (contentType) {
    case "BUSINESS":
      return {
        ...selectedContentItemOptions,
        businessProfile: value,
      };
    case "OFFER":
      return {
        ...selectedContentItemOptions,
        offer: value,
      };
    case "EVENT":
      return {
        ...selectedContentItemOptions,
        event: value,
      };
    case "POST":
      return {
        ...selectedContentItemOptions,
        post: value,
      };
    case "NEWS":
      return {
        ...selectedContentItemOptions,
        news: value,
      };
    default:
      return selectedContentItemOptions;
  }
};

export default ChartHeader;
