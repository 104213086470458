import AuthedAPI from "api/AuthedAPI";
import ListParams from "api/models/ListParams";
import ListResultType from "api/models/ListResultType";
import RequestOptions from "api/RequestOptions";
import NewsInterface from "api/models/NewsInterface";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
  ReviewResponse,
} from "api/models/ReviewInterface";
import NewsPayloadInterface from "api/models/NewsPayloadInterface";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";

const newsAPI = new AuthedAPI({});

const getNews = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  newsAPI.postListJson<ListResultType<NewsInterface>>({
    queryParams,
    options,
    body,
    path: "news/bo/news/search",
  });

const getNewsById = (
  id: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  newsAPI.getJson<NewsInterface>({
    queryParams,
    options,
    path: `news/bo/news/${id}`,
  });

const createNews = (body: NewsPayloadInterface, options?: RequestOptions) =>
  newsAPI.postJson<NewsInterface>({
    body,
    options,
    path: "news/bo/news",
  });

const createDraftNews = (
  body: NewsPayloadInterface,
  options: RequestOptions | undefined,
) =>
  newsAPI.postJson<NewsInterface>({
    body,
    options,
    path: "news/bo/news/drafts",
  });

const updateNews = (
  id: string,
  body: NewsPayloadInterface,
  options: RequestOptions | undefined,
) =>
  newsAPI.putJson<NewsInterface>({
    body,
    options,
    path: `news/bo/news/${id}`,
  });

const updateDraftNews = (
  id: string,
  body: NewsPayloadInterface,
  options: RequestOptions | undefined,
) =>
  newsAPI.putJson<NewsInterface>({
    body,
    options,
    path: `news/bo/news/drafts/${id}`,
  });

const getReviewsForNews = (
  newsId: string,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  newsAPI.getJson<ReviewResponse>({
    queryParams,
    options,
    path: `news/bo/news/${newsId}/reviews`,
  });

const saveReplyToReviewForNews = (
  newsId: string,
  reviewId: string,
  body: ReviewReplyPayloadInterface,
  options: RequestOptions | undefined,
) =>
  newsAPI.postJson<ReplyDetailsInterface>({
    options,
    body,
    path: `news/bo/news/${newsId}/reviews/${reviewId}/replies`,
  });

const deleteReplyToReviewForNews = (
  newsId: string,
  reviewId: string,
  replyId: string,
  options: RequestOptions | undefined,
) =>
  newsAPI.deleteEntity<void>({
    options,
    path: `news/bo/news/${newsId}/reviews/${reviewId}/replies`,
    entityId: replyId,
  });

export {
  createDraftNews,
  createNews,
  newsAPI,
  getNews,
  getNewsById,
  getReviewsForNews,
  updateNews,
  updateDraftNews,
  saveReplyToReviewForNews,
  deleteReplyToReviewForNews,
};
