import moment from "moment";

export interface DateTimeRangeInterface {
  start: Date | null;
  end: Date | null;
}

export const defaultBlockWidth = "45%";
export const defaultDateTimeFormat = "YYYY-MM-DD HH:mm:ssZZ"; // 2019-06-29 14:35:21+03:00
export const defaultDateFormatEn = "MMM D YYYY";

export const getValueFromDate = (
  date: Date | null,
  valueFormat: string = defaultDateTimeFormat,
): string | undefined => {
  if (date === null) return undefined;
  return moment(date).format(valueFormat);
};

export const getDateFromValue = (value?: string): Date | null => {
  // "Invalid date" is a special string returned for input strings that cannot be converted to a date(empty string, for example)
  if (value === undefined || value === "Invalid date") return null;
  return moment(value).toDate();
};

export const isDateInPast = (date?: Date | string | null): boolean => {
  if (date) {
    const currentMoment = moment();
    const dateMoment = moment(date);
    return currentMoment.isAfter(dateMoment);
  }

  return false;
};

export const getMinDateTime = (
  minDate?: Date | string | null,
  currentDate?: Date | null,
) => {
  if (isDateInPast(minDate) || isDateInPast(currentDate)) {
    return moment()
      .startOf("day")
      .toDate();
  }

  if (minDate) {
    if (currentDate) {
      const startCurrentDate = moment(currentDate).format(defaultDateFormatEn);
      const startMin = moment(minDate).format(defaultDateFormatEn);

      if (startCurrentDate !== startMin) {
        return moment()
          .startOf("day")
          .toDate();
      }
    }

    return moment(minDate).toDate();
  }

  if (currentDate) {
    const startCurrentDate = moment(currentDate).format(defaultDateFormatEn);
    const startNow = moment().format(defaultDateFormatEn);

    if (startCurrentDate !== startNow) {
      return moment()
        .startOf("day")
        .toDate();
    }
  }

  return moment().toDate();
};

export const getMaxTime = (date?: Date | string | null) => {
  if (date) {
    return moment(date).toDate();
  }

  return moment()
    .endOf("day")
    .toDate();
};
