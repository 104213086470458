import React from "react";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import BusinessRepresentativeList from "components/lists/businessRepresentativesList/BusinessRepresentativesList";
import strings from "localisation/strings";
import { RepresentativeInterface } from "api/models/RepresentativeInterface";
import Pagination from "components/pagination/Pagination";
import ListWidth, { ListPositioner } from "components/lists/ListWidth";
import ListHeader from "components/lists/header/ListHeader";
import ListHeaderActionButton from "components/lists/header/ListHeaderActionButton";
import { Media } from "components/generic";
import PhoneSortAndFilterHeader from "components/lists/header/Search/PhoneSortAndFilterHeader";
import ActionButtonWithPadding from "components/buttons/ActionButtonWithPadding";

interface Props {
  handleListItemAddClicked: () => void;
  handleAddCuratorClicked: () => void;
  handleListItemClicked: (itemId: string) => void;
  handleDeleteItemClicked: (itemId: string) => void;
  onPageSizeChange: (newLimit: number) => void;
  updatePage: (p: number) => void;
  page: number;
  limit: number;
  totalNumberOfPages: number;
  totalItems: number;
  title: string;
  loading: boolean;
  list: RepresentativeInterface[];
}

const RepresentativesView = ({
  handleListItemAddClicked,
  handleAddCuratorClicked,
  handleListItemClicked,
  handleDeleteItemClicked,
  onPageSizeChange,
  updatePage,
  page,
  limit,
  totalNumberOfPages,
  totalItems,
  list,
  loading,
  title,
}: Props) => (
  <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_REPRESENTATIVES} isView>
    <Media tablet phone>
      <PhoneSortAndFilterHeader
        hideFilter={true}
        hideSort={true}
        onAddNew={handleListItemAddClicked}
      />
    </Media>
    <ListPositioner>
      <ListWidth isGridMode={false}>
        <ListHeader title={title}>
          <ListHeaderActionButton
            text={strings("filterableLists.addNewButton")}
            onClick={handleListItemAddClicked}
            smallMargin
          />
          <ActionButtonWithPadding
            text={strings("businessRepresentativeList.addCuratorButton")}
            onClick={handleAddCuratorClicked}
            special="narrow"
          />
        </ListHeader>

        <BusinessRepresentativeList
          emptyIndicator={strings("listView.noContent")}
          emptyIndicatable={!loading}
          representatives={list}
          onItemClick={handleListItemClicked}
          onDeleteClick={handleDeleteItemClicked}
        />

        <Pagination
          onPageChange={updatePage}
          onPageSizeChange={onPageSizeChange}
          page={page}
          limit={limit}
          totalNumberOfPages={totalNumberOfPages}
          totalItems={totalItems}
        />
      </ListWidth>
    </ListPositioner>
  </PrivilegedComponent>
);

export default RepresentativesView;
