import React from "react";
import styled, { isMobile } from "style/styled-components";
import strings from "localisation/strings";
import InlineNumberInput from "components/forms/FormFields/InlineNumberInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import { PaddedSpacer } from "components/generic/Spacer";
import { PriceSymbol } from "components/forms/FormFields/PriceRange";
import priceIcon from "assets/icons/price.svg";
import globalTheme from "style/theme";
import useWindowDimensions from "hooks/useWindowDimensions";

export interface DiscountPricesProps {
  price?: number;
  discountPrice?: number;
  discountPercentage?: number;
  changePrice: (value: number | null) => void;
  changeDiscountPrice: (value: number | null) => void;
  changeDiscountPercentage: (value: number | null) => void;
  priceError?: string[];
  discountPriceError?: string[];
  discountPercentageError?: string[];
}

const PaddedPriceSymbol = () => (
  <PaddedSpacer padding={`0 ${globalTheme.margin.medium}`}>
    <PriceSymbol />
  </PaddedSpacer>
);

const Container = styled.span`
  line-height: 32px;
  display: flex;
  flex-wrap: wrap;
`;

const DiscountPrices = ({
  price,
  discountPrice,
  discountPercentage,
  changeDiscountPrice,
  changeDiscountPercentage,
  changePrice,
  priceError,
  discountPriceError,
  discountPercentageError,
}: DiscountPricesProps) => {
  const screen = useWindowDimensions();
  const isMobileDevice = isMobile(screen.width);

  return (
    <Container>
      <InlineInputGroup
        icon={priceIcon}
        placeholder={strings("detailedScreen.price.regular")}
        width={globalTheme.forms.leftSide}
        margin={`${globalTheme.margin.medium} 0`}
        totalWidth={isMobileDevice ? "100%" : "38%"}
      >
        <InlineNumberInput
          setValue={changePrice}
          value={price}
          placeholder={strings("detailedScreen.price.price")}
          errors={priceError}
          width="100%"
          testId="price"
        />
        {!isMobileDevice && <PaddedPriceSymbol />}
      </InlineInputGroup>

      <InlineInputGroup
        icon={isMobileDevice ? priceIcon : undefined}
        placeholder={
          isMobileDevice ? strings("detailedScreen.price.discounted") : ""
        }
        width={isMobileDevice ? globalTheme.forms.leftSide : "0px"}
        margin={`${globalTheme.margin.medium} 0`}
        totalWidth={isMobileDevice ? "100%" : "34%"}
      >
        <InlineNumberInput
          setValue={changeDiscountPrice}
          value={discountPrice}
          placeholder={strings("detailedScreen.price.discounted")}
          inputPlaceholder={strings("detailedScreen.price.price")}
          errors={discountPriceError}
          width="100%"
          showPlaceholderAsLabel={!isMobileDevice}
          testId="price"
        />
        {!isMobileDevice && <PaddedPriceSymbol />}
      </InlineInputGroup>

      <InlineInputGroup
        icon={isMobileDevice ? priceIcon : undefined}
        placeholder={
          isMobileDevice ? strings("detailedScreen.price.discount") : ""
        }
        width={isMobileDevice ? globalTheme.forms.leftSide : "0px"}
        margin={`${globalTheme.margin.medium} 0`}
        totalWidth={isMobileDevice ? "100%" : "28%"}
      >
        <InlineNumberInput
          setValue={changeDiscountPercentage}
          value={discountPercentage}
          placeholder={strings("detailedScreen.price.discount")}
          errors={discountPercentageError}
          width="100%"
          showPlaceholderAsLabel={!isMobileDevice}
          testId="price"
        />
      </InlineInputGroup>
    </Container>
  );
};

export default DiscountPrices;
