import React, { RefObject } from "react";
import styled from "style/styled-components";
import QRCode from "qrcode.react";
import strings from "localisation/strings";
import breakpoints from "style/breakpoints";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: right;
  top: -150px;
  margin-right: 40px;
  width: 220px;
  height: 214px;
  background-color: ${({ theme }) => theme.color.background.primary};
  border: 1px solid ${({ theme }) => theme.color.border.light};
  border-radius: ${({ theme }) => theme.border.radius.medium};

  > canvas {
    transform: scale(0.32);
    margin-top: -50px;
    // IE11 specific styles
    @media all and (-ms-high-contrast: none) {
      margin-top: -64px;
    }
  }

  @media ${breakpoints.tabletMax} {
    top: 0;
    margin: 0 0 ${({ theme }) => theme.margin.xlarge};
    width: 100%;
  }
`;

const DownloadLink = styled.a`
  position: absolute;
  bottom: 30px;
  text-align: center;
  width: 125px;
  margin: auto;
  font-size: ${({ theme }) => theme.font.size.pagination};
  font-weight: bold;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.color.foreground.primary};
  border-radius: 23px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  // Fix for IE 11
  left: 0;
  right: 0;
`;

interface Props {
  value?: string;
}

const QRCodeDownloadable = ({ value }: Props) => {
  if (!value) return null;

  const buttonRef: RefObject<HTMLAnchorElement> = React.createRef();

  const downloadQR = () => {
    const canvas: any = document.querySelector("canvas");

    const downloadButton = buttonRef.current;
    if (downloadButton) {
      const downloadName = "QR.png";
      if (canvas.msToBlob) {
        // for IE
        const canvasBlob = canvas.msToBlob();
        window.navigator.msSaveBlob(canvasBlob, downloadName);
      } else {
        downloadButton.href = canvas.toDataURL("image/png;base64");
        downloadButton.download = downloadName;
      }
    }
  };

  // Fix for IE 11
  const style = {
    height: "auto !important",
  };

  return (
    <Container>
      <QRCode value={value} size={300} level="H" {...{ style }} />
      <DownloadLink ref={buttonRef} onClick={downloadQR}>
        {strings("businessUserProfileScreen.fields.QRCode.download")}
      </DownloadLink>
    </Container>
  );
};

export default QRCodeDownloadable;
