import styled from "style/styled-components";
import breakpoints from "style/breakpoints";

const SidebarContentArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  position: relative;
  padding-bottom: 40px;

  @media ${breakpoints.phoneMax} {
    padding-bottom: 20px;
  }
`;

export default SidebarContentArea;
