import React, { ReactElement } from "react";
import EmojiInterface from "api/models/EmojiInterface";
import emojiDataState from "state/singletons/emojiDataState";

const getEmojiText = (
  emojiString: string,
  lineHeight: number,
  emojiData?: EmojiInterface[],
): ReactElement | string => {
  if (!emojiData) {
    return emojiString;
  }

  // When emojiString is "//smile//", the name will be "smile"
  const name = emojiString.slice(2, emojiString.length - 2);

  const foundData = emojiData.find(emoji => emoji.id === name);
  const url = foundData ? foundData.url : undefined;

  return url ? <img height={lineHeight} src={url} /> : emojiString;
};

const REGEX = /(\/\/\w*\/\/)/;

export const getEmojiTextComponents = (title: string, lineHeight: number) => {
  const titleWordList = title.split(REGEX);

  return (
    <>
      {titleWordList.map(titleWord => {
        const isEmojiString = REGEX.test(titleWord);
        if (isEmojiString) {
          return (
            <React.Fragment key={titleWord}>
              {getEmojiText(
                titleWord,
                lineHeight,
                emojiDataState.getEmojiData(),
              )}
            </React.Fragment>
          );
        }

        return titleWord;
      })}
    </>
  );
};

export default getEmojiTextComponents;
