import React from "react";
import strings from "localisation/strings";
import FormSubtitle from "components/forms/FormSubtitle";

const RequestDeletionSuccess = () => (
  <FormSubtitle>
    {strings("requestDeletionScreen.successMessageLine1")}
  </FormSubtitle>
);

export default RequestDeletionSuccess;
