import React from "react";
import DateTimeRange from "api/models/DateTimeRange";
import FilterSection from "components/lists/filtering/FilterSection";
import strings from "localisation/strings";
import DateRange from "components/forms/DateRange";

interface DateRangeFilterProps {
  dateTimeRange: DateTimeRange;
  onDateRangeChange: (newDateRange: DateTimeRange) => void;
}

const DateRangeFilter = ({
  dateTimeRange,
  onDateRangeChange,
}: DateRangeFilterProps) => (
  <FilterSection title={strings("filterScreen.dateFilter.title")}>
    <DateRange
      dateTimeRange={dateTimeRange}
      onDateRangeChange={onDateRangeChange}
    />
  </FilterSection>
);

export default DateRangeFilter;
