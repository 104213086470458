import React from "react";
import copy from "copy-to-clipboard";
import { apiUrl } from "core/constants";
import styled from "style/styled-components";
import strings from "localisation/strings";
import { NotificationTypes } from "components/generic/toaster/GenericToaster";
import useNotification from "hooks/useNotification";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const JustifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.color.foreground.action};
  font-size: ${({ theme }) => theme.font.size.small};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  min-width: 42px;
`;

interface Props {
  logFileId: string;
}

const CopyLinkButton = ({ logFileId }: Props) => {
  const { setNotification } = useNotification();
  const downloadURL = `${apiUrl}file-storage/sai/user-logs/${logFileId}`;

  const handleClick = () => {
    copy(downloadURL);
    setNotification(
      strings("adminPublicUsersPage.alert.linkCopied"),
      NotificationTypes.system,
    );
  };

  return (
    <Container onClick={handleClick}>
      <JustifyContainer>
        <CopyIcon width={35} height={35} />
      </JustifyContainer>
      <JustifyContainer>
        <Text>{strings("buttons.copyLink")}</Text>
      </JustifyContainer>
    </Container>
  );
};

export default CopyLinkButton;
