import React from "react";
import ChatSidebar from "components/chat/ChatSidebar";
import SidenavLayout from "components/generic/SidenavLayout";
import styled from "style/styled-components";

const ChatWithSidenavLayout = styled(SidenavLayout)`
  overflow: hidden;
`;

const ChatView: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ChatWithSidenavLayout sidebar={<ChatSidebar />}>
      {children}
    </ChatWithSidenavLayout>
  );
};

export default ChatView;
