import React from "react";
import { withRouter } from "react-router-dom";

import ListTableView from "views/ListTableView";
import NewsInterface from "api/models/NewsInterface";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

const News = () => {
  return (
    <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_NEWS_ADMIN} isView>
      <ListTableView<NewsInterface> />
    </PrivilegedComponent>
  );
};

export default withRouter(News);
