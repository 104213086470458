import SingleMainCategoryInterface from "api/models/SingleMainCategoryInterface";
import SingleSubCategoryInterface from "api/models/SingleSubCategoryInterface";
import EntityTypes from "api/models/EntityTypes";
import TagInterface from "api/models/TagInterface";
import { uniqWith } from "ramda";

interface Values {
  [index: string]: any;
}

export const defaultMainCategoryFields = {
  singleInputFields: {
    code: "",
  },
  numberFields: {
    weight: undefined,
  },
  selectFields: {
    tagType: "",
    tagStatus: "",
    smartServiceGroup: undefined,
  },
  imageFields: {
    imageUrl: null as string | null,
  },
  checkboxFields: {
    showAsInterests: false,
    showAsDistrict: false,
    showAsSmartService: false,
    showAsJobOffer: false,
  },
};

export const defaultMainCategoryQuestions = [
  {
    question: "",
    label: "",
    answers: [],
  },
];

export const defaultSubCategoryFields = {
  singleInputFields: {
    question: "",
    label: "",
    tagName: "",
  },
  subTagFields: {
    subTags: [
      {
        code: "",
        showAsInterests: false,
        showAsDistrict: false,
        tagStatus: "",
        weight: undefined,
        tagType: "",
      },
    ],
  },
  selectFields: {
    tagType: "",
  },
  imageFields: {
    imageUrl: null as string | null,
  },
};

export const defaultSubCategorySubTags = [
  {
    code: "",
    showAsInterests: false,
    showAsDistrict: false,
    tagStatus: "",
    weight: undefined,
    tagType: "",
  },
];

export const setCategoryFields = (
  category: Partial<SingleMainCategoryInterface>,
) => {
  const {
    code = "",
    weight,
    tagType = "",
    businessType = null,
    tagStatus = "",
    imageUrl = null,
    showAsInterests = false,
    showAsDistrict = false,
    showAsSmartService = false,
    showAsJobOffer = false,
    sourceLabels = [],
    smartServiceGroup,
  } = category;

  return {
    sourceLabels,
    singleInputFields: {
      code,
    },
    numberFields: {
      weight,
    },
    selectFields: {
      tagType,
      businessType,
      tagStatus,
      smartServiceGroup,
    },
    imageFields: {
      imageUrl,
    },
    checkboxFields: {
      showAsInterests,
      showAsDistrict,
      showAsSmartService,
      showAsJobOffer,
    },
  };
};

export const setSubCategoryFields = (
  category: Partial<SingleSubCategoryInterface>,
) => {
  // default value redefined here because it can't be read from the outer scope
  const defaultSubTags = [
    {
      code: "",
      showAsInterests: false,
      showAsDistrict: false,
      tagStatus: "",
      weight: undefined,
      tagType: "",
    },
  ];

  const {
    tagName = "",
    tagType = "",
    subTags = [],
    question = "",
    label = "",
    showAsDistrict = false,
    imageUrl = null,
  } = category;
  const newSubTags = subTags.length > 0 ? subTags : defaultSubTags;

  return {
    singleInputFields: {
      tagName,
      question,
      label,
    },
    subTagFields: {
      subTags: newSubTags,
    },
    selectFields: {
      tagType,
    },
    imageFields: {
      imageUrl,
    },
    hiddenFields: {
      showAsDistrict,
    },
  };
};

export const getCategoryPayload = (
  values: Values,
  oldBody: SingleMainCategoryInterface,
) => {
  const {
    code,
    weight,
    tagStatus,
    tagType,
    businessType,
    imageUrl,
    showAsInterests,
    showAsDistrict,
    showAsSmartService,
    showAsJobOffer,
    smartServiceGroup,
  } = values;

  return {
    ...oldBody,
    code,
    weight,
    tagStatus,
    tagType,
    businessType,
    showAsInterests,
    showAsDistrict,
    showAsSmartService,
    showAsJobOffer,
    imageUrl: showAsSmartService || showAsJobOffer ? imageUrl : undefined,
    smartServiceGroup,
  } as SingleMainCategoryInterface;
};

export const getSubCategoryPayload = (
  values: Values,
  oldBody: SingleSubCategoryInterface,
) => {
  const {
    tagName,
    tagType,
    subTags,
    showAsDistrict,
    showAsSmartService,
    showAsJobOffer,
    imageUrl,
    label,
    smartServiceGroup,
  } = values;

  subTags.forEach((item: any) => {
    if (item.tagType.length < 1) {
      item.tagType = tagType;
    }
  });

  const isGeneralWithPicture =
    tagType === EntityTypes.general && showAsDistrict;
  const isPostWithPicture =
    tagType === EntityTypes.posts && (showAsSmartService || showAsJobOffer);
  const isWithPicture = isGeneralWithPicture || isPostWithPicture;

  return {
    ...oldBody,
    tagName,
    tagType,
    label,
    subTags,
    imageUrl: isWithPicture ? imageUrl : undefined,
    smartServiceGroup,
  } as SingleSubCategoryInterface;
};

export const isQuestionRequiredValue = (
  value: string | number | boolean | undefined,
): boolean => {
  if (typeof value !== "string") return false;

  if (
    value === EntityTypes.events ||
    value === EntityTypes.posts ||
    value === EntityTypes.news ||
    value === EntityTypes.offers
  ) {
    return false;
  }

  return true;
};

export const isGeneralTypeValue = (tagType: string): boolean =>
  tagType === EntityTypes.general;

// TODO: if this list is to be changed, just check for qid from api and remove these conditionals
export const isBusinessRelated = (tagType: string): boolean => {
  if (
    tagType === EntityTypes.general ||
    tagType === EntityTypes.health ||
    tagType === EntityTypes.craftsman ||
    tagType === EntityTypes.professional_services ||
    tagType === EntityTypes.home_services ||
    tagType === EntityTypes.eating_drinking ||
    tagType === EntityTypes.hobby ||
    tagType === EntityTypes.eating_drinking ||
    tagType === EntityTypes.shopping
  ) {
    return true;
  }

  return false;
};

export const excludeDuplicateTags = (tags: TagInterface[]): TagInterface[] => {
  const hasSameName = (a: TagInterface, b: TagInterface): boolean =>
    a.name === b.name;
  return uniqWith(hasSameName)(tags);
};
