import styled from "style/styled-components";

interface SpacerProps {
  isInline?: boolean;
}

interface PaddedSpacerProps extends SpacerProps {
  padding?: string;
}

const FOOTER_HEIGHT = "48px";

const SpacerBase = styled.div<SpacerProps>`
  ${({ isInline }) => isInline && "display: inline-block;"};
`;

const HorizontalLargeSpacer = styled(SpacerBase)<SpacerProps>`
  width: ${({ theme }) => theme.margin.large};
`;

const HorizontalMediumSpacer = styled(SpacerBase)<SpacerProps>`
  width: ${({ theme }) => theme.margin.medium};
`;

const HorizontalSmallSpacer = styled(SpacerBase)<SpacerProps>`
  width: ${({ theme }) => theme.margin.small};
`;

const PaddedSpacer = styled(SpacerBase)<PaddedSpacerProps>`
  ${({ padding }) => (padding ? `padding: ${padding};` : "")}
`;

const VerticalLargeSpacer = styled(SpacerBase)<SpacerProps>`
  height: ${({ theme }) => theme.margin.large};
`;

const VerticalMediumSpacer = styled(SpacerBase)<SpacerProps>`
  height: ${({ theme }) => theme.margin.medium};
`;

const FooterSpacer = styled(SpacerBase)<SpacerProps>`
  height: ${({ theme }) => theme.margin.medium};
  margin-bottom: ${FOOTER_HEIGHT};
`;

export {
  HorizontalSmallSpacer,
  HorizontalMediumSpacer,
  HorizontalLargeSpacer,
  PaddedSpacer,
  VerticalMediumSpacer,
  VerticalLargeSpacer,
  FooterSpacer,
};
