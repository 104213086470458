import React, { useEffect, useRef } from "react";
import styled from "style/styled-components";
import breakpoints, { mobileHeaderWidthMax } from "style/breakpoints";
import GenericToaster, {
  NotificationTypes,
} from "components/generic/toaster/GenericToaster";
import SystemAlertToaster from "components/generic/toaster/SystemAlertToaster";
import useGlobalNotification from "components/generic/toaster/useGlobalNotification";
import { GlobalNotification } from "state/NotificationState";
import useLoginState from "hooks/useLoginState";
import useNotificationState from "hooks/useNotification";
import { useLocation } from "react-router-dom";

const globalNotificationWidthPercent = {
  desktop: 50,
  tablet: 80,
  mobile: 90,
};

const globalNotificationOffsetToCenter = {
  desktop: (100 - globalNotificationWidthPercent.desktop) / 2,
  tablet: (100 - globalNotificationWidthPercent.tablet) / 2,
  mobile: (100 - globalNotificationWidthPercent.mobile) / 2,
};

const GlobalNotificationsContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 120px;
  max-height: 65%;
  overflow-y: auto;

  min-width: ${globalNotificationWidthPercent.desktop}%;
  width: ${globalNotificationWidthPercent.desktop}%;
  left: ${globalNotificationOffsetToCenter.desktop}%;
  z-index: ${({ theme }) => theme.layers.popover};

  @media ${breakpoints.tabletMax} {
    min-width: ${globalNotificationWidthPercent.tablet}%;
    width: ${globalNotificationWidthPercent.tablet}%;
    left: ${globalNotificationOffsetToCenter.tablet}%;
  }

  @media (max-width: ${mobileHeaderWidthMax}px) {
    min-width: ${globalNotificationWidthPercent.mobile}%;
    width: ${globalNotificationWidthPercent.mobile}%;
    left: ${globalNotificationOffsetToCenter.mobile}%;
  }
`;

interface GlobalNotificationToasterProps {
  notification: GlobalNotification;
}

const GlobalNotificationToaster = ({
  notification,
}: GlobalNotificationToasterProps) => {
  const { clearNotification } = useGlobalNotification(notification);

  return notification.type === NotificationTypes.system ? (
    <SystemAlertToaster
      key={notification.id}
      title={notification.title}
      message={notification.message}
      clearNotification={clearNotification}
    />
  ) : (
    <GenericToaster
      type={notification.type}
      message={notification.message}
      clearNotification={clearNotification}
    />
  );
};

const GlobalNotificationsDisplay = () => {
  const notificationState = useNotificationState();
  const loginState = useLoginState();
  const userLoggedIn = loginState.isUserLoggedIn();
  const notifications = notificationState.getNotifications();

  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!userLoggedIn) {
      notificationState.clearNotifications();
    }
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      notificationState.clearNotifications();
    }
  }, [userLoggedIn, location]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <GlobalNotificationsContainer>
      {notifications.map(notification => (
        <GlobalNotificationToaster
          key={notification.id}
          notification={notification}
        />
      ))}
    </GlobalNotificationsContainer>
  );
};

export default GlobalNotificationsDisplay;
