import React from "react";
import styled, { css } from "style/styled-components";
import strings from "localisation/strings";
import PictureTypes from "api/models/PictureTypes";
import { ImageItem } from "hooks/helpers/Interfaces";
import { AutoDragAndDrop } from "components/forms/FormFields";
import { SmallIconedTextButton } from "components/buttons/TextButton";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as OkIcon } from "assets/icons/ok.svg";

const Container = styled.div`
  position: relative;
`;

const buttonStyles = css`
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  flex-flow: row nowrap;
  display: block;
  line-height: 12px;
`;

const DeleteButton = styled(SmallIconedTextButton)`
  top: 16px;
  right: 40px;
  ${buttonStyles}
`;

const SetAsMainButton = styled(SmallIconedTextButton)`
  top: 16px;
  right: 80px;
  padding-right: 12px;
  padding-left: 12px;
  ${buttonStyles}
`;
const MainButton = styled(SetAsMainButton)`
  cursor: unset;
  path {
    fill: ${({ theme }) => theme.color.foreground.noticeStrong};
  }
`;

const FixedRatioPictureComponent = ({
  picture,
  updateSlide,
  setAsMain,
  removePicture,
  container,
  height,
  width,
  parseErrors,
  setWaitingStatus,
  hasFormErrors,
  slideErrors,
  setSlideErrors,
  onInitialUpload,
  setImageType,
  setImageFileName,
}: {
  picture: ImageItem;
  setAsMain: () => void;
  removePicture: () => void;
  updateSlide: (picture: ImageItem) => void;
  container: string;
  height?: string;
  width?: string;
  parseErrors?: (response: APIErrorResponse) => void;
  setWaitingStatus?: (isWaiting: boolean) => void;
  hasFormErrors: boolean;
  slideErrors: string[];
  setSlideErrors: (errors: string[]) => void;
  onInitialUpload?: (imageUrl: string) => void;
  setImageType?: (imageType: string) => void;
  setImageFileName?: (imageFileName: string) => void;
}) => {
  const setImage = (image: string | null) => {
    updateSlide({
      type: picture.type,
      url: image,
    });
  };

  return (
    <Container key={`picture-#${picture.id}`}>
      <AutoDragAndDrop
        placeholder={strings(`dragAndDrop.dropHere`)}
        imageUrl={picture.url}
        setImageUrl={setImage}
        container={container}
        height={height}
        width={width}
        parseErrors={parseErrors}
        setWaitingStatus={setWaitingStatus}
        slideErrors={slideErrors}
        setSlideErrors={setSlideErrors}
        hasFormErrors={hasFormErrors}
        fixedRatio
        withCropAndZoom={true}
        onInitialUpload={onInitialUpload}
        setImageType={setImageType}
        setImageFileName={setImageFileName}
      />
      {picture.url && (
        <>
          <DeleteButton
            icon={<DeleteIcon width={30} height={30} />}
            text=""
            onClick={removePicture}
            title={strings(`dragAndDrop.remove`)}
          />

          {picture.type === PictureTypes.main && (
            <MainButton
              icon={<OkIcon width={16} height={30} />}
              text={strings(`dragAndDrop.mainPicture`)}
              disabled={true}
            />
          )}

          {picture.type !== PictureTypes.main && (
            <SetAsMainButton
              icon={<OkIcon width={16} height={30} />}
              text={strings(`dragAndDrop.setAsMain`)}
              onClick={setAsMain}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default FixedRatioPictureComponent;
