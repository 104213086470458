import React from "react";
import strings from "localisation/strings";
import { SettingsMenuLink } from "components/topBar/HeaderComponents";
import TextButton from "components/buttons/TextButton";

import LogOutIcon from "assets/icons/status.svg";

interface Props {
  onHideMenu: () => void;
  onLogout: () => void;
}

const LogoutButton = ({ onHideMenu, onLogout }: Props) => {
  const logoutAction = () => {
    onHideMenu();
    onLogout();
  };

  return (
    <SettingsMenuLink src={LogOutIcon} isAction>
      <TextButton onClick={logoutAction} text={strings("buttons.logOut")} />
    </SettingsMenuLink>
  );
};

export default LogoutButton;
