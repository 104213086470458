import React, { useState } from "react";
import ActionButton from "components/buttons/ActionButton";
import ActionArea from "components/generic/ActionArea";
import RichTextEditor from "components/generic/richText/RichTextEditor";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import { ConfirmButton } from "views/TextScreens/components";
import strings from "localisation/strings";
import useLocale from "common/hooks/useLocale";

export interface EditableRichTextProps {
  value?: string;
  onSave: (content: string) => void;
}

const editorConfig = {
  height: 400,
  format_tags: "p;h1;h2;h3",
  contentsCss: "/static/ckeditor/business-portal.css",
  toolbar: [
    {
      name: "basicstyles",
      items: [
        "Format",
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "-",
        "CopyFormatting",
        "RemoveFormat",
      ],
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
      ],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "tools", items: ["Maximize"] },
  ],
};

const EditableRichText = (props: EditableRichTextProps) => {
  const { value, onSave } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>(String(value || ""));
  const locale = useLocale();

  return editMode ? (
    <>
      <RichTextEditor
        placeholder={strings("richText.placeholder")}
        onContentChange={content => setTextValue(content)}
        config={editorConfig}
        uiLocale={locale}
        value={textValue}
        onReady={() => setEditorReady(true)}
      />

      {editorReady && (
        <ActionArea>
          <ActionButton
            text={strings("buttons.cancel")}
            onClick={() => {
              setEditMode(false);
              setTextValue(value || "");
            }}
          />
          <ActionButton
            text={strings("buttons.save")}
            special="publish"
            onClick={() => {
              if (onSave) {
                onSave(textValue);
              }
              setEditMode(false);
            }}
            loadingText={strings("buttons.states.savingInProgress")}
          />
        </ActionArea>
      )}
    </>
  ) : (
    <>
      <RichTextRendering cozy value={textValue} />

      <ConfirmButton
        text={strings("buttons.edit")}
        special="publish"
        onClick={() => {
          setEditorReady(false);
          setEditMode(true);
        }}
      />
    </>
  );
};

export default EditableRichText;
