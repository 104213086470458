import React from "react";
import ConversationsList from "components/chat/ConversationsList";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import ConversationBusinessFilter from "components/chat/ConversationBusinessFilter";
import { BusinessBranch } from "components/chat/types";
import { chatConversationsTestId } from "testing/testId";

interface Props {
  businessBranches: BusinessBranch[];
  selectedBranchId: string;
  selectedConversationId: string;
  /**
   * Most common scenario is that a business only has one branch and so doesn't need the branch select component
   * This is useful when a business has more than one branch (with or without conversations)
   */
  isBranchesSelectDisplayed: boolean;
  isConversationsListLoading: boolean;
  isMoreConversationsAvailable: boolean;
  onBusinessBranchSelected: (branchId: string) => void;
  onConversationSelected: (conversationId: string) => void;
  onMoreConversationsRequested: () => void;
}

const ConversationSectionContainer = styled.aside`
  position: relative;
  width: 100%;
  max-width: 310px;
  overflow-y: scroll;
  border-right: 1px solid ${({ theme }) => theme.color.chat.bg.sidebar};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media ${breakpoints.phoneMax} {
    max-width: inherit;
    border: 0;
    height: 100%;
  }
`;

const conversationsSectionDomId = "conversations-section";
const ConversationSection = ({
  businessBranches,
  selectedBranchId,
  selectedConversationId,
  onBusinessBranchSelected,
  onConversationSelected,
  isBranchesSelectDisplayed,
  isConversationsListLoading,
  isMoreConversationsAvailable,
  onMoreConversationsRequested,
}: Props) => (
  <ConversationSectionContainer
    id={conversationsSectionDomId}
    {...chatConversationsTestId()}
  >
    {isBranchesSelectDisplayed && (
      <ConversationBusinessFilter
        branchesList={businessBranches}
        selectedBranchId={selectedBranchId}
        onBranchSelected={onBusinessBranchSelected}
      />
    )}
    <ConversationsList
      scrollContainerDomId={conversationsSectionDomId}
      isConversationsListLoading={isConversationsListLoading}
      isMoreConversationsAvailable={isMoreConversationsAvailable}
      selectedConversationId={selectedConversationId}
      onConversationSelected={onConversationSelected}
      onMoreConversationsRequested={onMoreConversationsRequested}
    />
  </ConversationSectionContainer>
);

export default ConversationSection;
