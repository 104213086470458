import { useState, useEffect } from "react";
import globalFormExitPromptState from "state/singletons/globalFormExitPromptState";

const useGlobalFormBlock = () => {
  const [, update] = useState(Date.now());

  const stateChangeListener = () => {
    update(Date.now());
  };

  useEffect(() => {
    globalFormExitPromptState.subscribe(stateChangeListener);
    return () => {
      globalFormExitPromptState.unsubscribe(stateChangeListener);
    };
  }, []);

  return globalFormExitPromptState;
};

export default useGlobalFormBlock;
