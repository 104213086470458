import React, { useEffect, useState } from "react";
import TextArea from "react-textarea-autosize";
import styled from "style/styled-components";
import { StyledTitle } from "components/generic/Title";
import EmojiTitle from "components/generic/EmojiTitle";
import FieldErrors from "components/forms/FieldErrors";
import { TextAreaProps } from "components/forms/ValidatedFields/TextArea";

const PlaceholderTitle = styled(StyledTitle)<{ hasErrors?: boolean }>`
  color: ${({ theme }) => theme.color.foreground.tertiary};
  margin: 0;
`;

const Errors = styled(FieldErrors)`
  margin-top: 2px;
  position: relative;
  top: -10px;
`;

const InputContainer = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: 1px solid ${({ theme }) => theme.color.border.light};
  align-items: baseline;
  display: flex;
  margin-top: ${({ theme }) => theme.margin.standart};
  margin-bottom: ${({ theme }) => theme.margin.standart};
  padding: ${({ theme }) => `${theme.margin.standart} ${theme.margin.large}`};
`;

const Input = styled(TextArea)`
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-size: ${({ theme }) => theme.font.size.xlarge};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-weight: 800;
  width: 100%;
  border: 0;
  resize: none;
  padding: 0;
  overflow: hidden;
  line-height: 30px;
  margin: 0;

  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
  }
`;

interface Props extends TextAreaProps {
  focusOnOpen?: boolean;
}

const TitleInput = (props: Props) => {
  const { placeholder, onChange, focusOnOpen, errors, onBlur } = props;
  let inputRef: HTMLTextAreaElement;

  const value = String(props.value || "");
  const [isFocused, setIsFocused] = useState<boolean>(focusOnOpen || false);

  const onClick = () => setIsFocused(true);
  const onBlurWithUnfocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };
  useEffect(() => {
    if (isFocused && inputRef) inputRef.focus();
  });

  const hasErrors = Boolean(errors && errors.length);

  return (
    <>
      <InputContainer onClick={onClick}>
        {isFocused ? (
          <>
            <Input
              data-cy="input-title"
              onChange={onChange}
              placeholder={placeholder}
              value={value}
              inputRef={tag => (inputRef = tag)}
              onBlur={onBlurWithUnfocus}
              maxLength={80}
            />
          </>
        ) : value ? (
          <EmojiTitle title={value} />
        ) : (
          <PlaceholderTitle data-cy="input-title" hasErrors={hasErrors}>
            {placeholder}
          </PlaceholderTitle>
        )}
      </InputContainer>
      <Errors testId="input-title-error" errors={errors} />
    </>
  );
};

export default TitleInput;
