import moment from "moment";
import { isNil } from "ramda";

import AccessControlInterface from "api/models/AccessControlInterface";
import BusinessUserProfileInterface from "api/models/BusinessUserProfileInterface";
import OfferInterface from "api/models/OfferInterface";
import EventInterface from "api/models/EventInterface";
import PostInterface from "api/models/PostInterface";
import NewsInterface from "api/models/NewsInterface";

import LoginState from "state/LoginState";
import TagInterface from "api/models/TagInterface";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import UserPrivileges, { UserPrivilege } from "constants/UserPrivileges";
import ContentType from "common/api/models/ContentType";
import QueryStatus from "common/api/models/QueryStatus";

const formattedDate = (date: string, format = "DD.MM.YYYY") =>
  date ? moment(date).format(format) : "";

const dateReviver = (_: string, value: unknown) => {
  if (typeof value !== "string") {
    return value;
  }

  try {
    const m = moment(value, moment.ISO_8601);

    return m.isValid() ? m.toDate() : value;
  } catch (err) {
    return value;
  }
};

const parseTotalItemCount = (headers: {
  get: (name: string) => string | null;
}) => parseInt(headers.get("x-total-count") || "0", 10);

const getTotalPagesFromHeaders = (
  headers: {
    get: (name: string) => string | null;
  },
  limit: number,
) => Math.ceil(parseTotalItemCount(headers) / limit);

const getTotalItemsCount = (headers: {
  get: (name: string) => string | null;
}) => parseTotalItemCount(headers);

const commaList = (items: any[], fieldName?: string) => {
  if (fieldName) {
    if (items.length === 1) return items[0][fieldName];
    return items.map(item => item[fieldName]).join(", ");
  }
  if (items.length === 1) return items[0];
  return items.join(", ");
};

const flatCommaList = (
  items: any[],
  successors = "tagSuccessors",
  fieldName = "name",
) => {
  return items.reduce((acc: string, current: any) => {
    let newAcc = acc ? `${acc}, ${current[fieldName]}` : current[fieldName];
    if (current[successors] && current[successors].length) {
      newAcc += commaList(current[successors]);
    }
    return newAcc;
  }, "");
};

const getLastPathSegment = (path: string) => {
  const lastLeg = path
    .split("/")
    .filter(item => !!item)
    .pop();
  return lastLeg || "";
};

const mapTagsToIdArray = (tags: TagInterface[] = []): string[] => {
  const result: string[] = [];
  tags.reduce((items: string[], category) => {
    if (category.tagSuccessors && category.tagSuccessors.length) {
      items.concat(category.tagSuccessors.map(child => child.id));
    } else {
      items.push(category.id);
    }
    return items;
  }, result);

  return result;
};

const hasPrivilegedAccess = (
  item: AccessControlInterface,
  privilegedComponentSet: Set<string> | null,
) => {
  if (
    item.privilegeId &&
    (!privilegedComponentSet || !privilegedComponentSet.has(item.privilegeId))
  ) {
    return false;
  }
  return true;
};

const hasAccess = (item: AccessControlInterface, loginState?: LoginState) => {
  if (
    item.onlyIfUnauthenticated ||
    item.requireAuthenticatedUser ||
    item.privilegeId
  ) {
    const loggedIn = loginState && loginState.isUserLoggedIn();
    const privilegedComponentSet = loginState
      ? loginState.getPrivilegedComponentSet()
      : null;

    if (item.onlyIfUnauthenticated === true) {
      return !loggedIn;
    }

    if (item.privilegeId) {
      return hasPrivilegedAccess(item, privilegedComponentSet);
    }

    if (item.requireAuthenticatedUser) {
      return loggedIn;
    }
  }

  return true;
};

const createPrivilegedComponentSet = (
  userPrivileges: UserPrivilege[],
  privilegesToComponentsMap: Map<any, string[]>,
  profileCompleted: boolean,
) => {
  const privilegedComponentSet = new Set<string>();
  if (userPrivileges && userPrivileges.length) {
    userPrivileges.forEach((p: UserPrivilege) => {
      const components = privilegesToComponentsMap.get(p);
      if (components) {
        components.map(c => privilegedComponentSet.add(c));
      }
    });

    if (
      userPrivileges.includes(UserPrivileges.TYPE_BUSINESS) &&
      userPrivileges.includes(UserPrivileges.SETTINGS_BP_WRITE)
    ) {
      privilegedComponentSet.add(PrivilegedComponentsIds.EDIT_PAYMENTS);
    }

    if (
      !profileCompleted &&
      userPrivileges.includes(UserPrivileges.TYPE_BUSINESS) &&
      userPrivileges.includes(UserPrivileges.SETTINGS_BP_WRITE)
    ) {
      // this is a business admin and profile is not completed yet -> show wizard
      privilegedComponentSet.add(PrivilegedComponentsIds.VIEW_WIZARD);
      privilegedComponentSet.delete(PrivilegedComponentsIds.MENU_DASHBOARD);
      privilegedComponentSet.delete(PrivilegedComponentsIds.VIEW_DASHBOARD);
    }

    if (
      userPrivileges.includes(UserPrivileges.TYPE_BUSINESS) &&
      userPrivileges.includes(UserPrivileges.TYPE_CURATOR)
    ) {
      // delete it for curator in order to differentiate menu and navigation form other business users
      privilegedComponentSet.delete(PrivilegedComponentsIds.MENU_DASHBOARD);
    }
  }
  return privilegedComponentSet;
};

const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const dispatchBrowserEvent = (eventName: string) => {
  if (typeof Event === "function") {
    // modern browsers
    window.dispatchEvent(new Event(eventName));
  } else {
    // for IE and other old browsers
    // causes deprecation warning on modern browsers
    const event = window.document.createEvent("UIEvents");
    (event as any) /** initUIEvent is deprecated, so no longer declared in typings */
      .initUIEvent(eventName, true, false, window, 0);
    window.dispatchEvent(event);
  }
};

const nullable = <T>(value: T) => {
  const isNonEnteredStringTypeField = String(value) === "";
  if (isNonEnteredStringTypeField) {
    return null;
  }

  return isNil(value) ? null : value;
};

// IE11 does not support Array.prototype.flat() so we need a workaround.
const flatArray = <TArrayElement>(
  array: TArrayElement[][],
): TArrayElement[] => {
  return array.reduce((acc, val) => acc.concat(val), []);
};

const getDateYearsAgo = (years: number) => {
  const date = new Date();
  const yearAgo = date.getFullYear() - years;

  return new Date(yearAgo, 0, 1);
};

const getContentTypeName = (contentType: ContentType): string => {
  switch (contentType) {
    case "BUSINESS":
      return "business-profiles";
    case "OFFER":
      return "offers";
    case "EVENT":
      return "events";
    case "POST":
      return "posts";
    case "NEWS":
      return "news";
    default:
      return "";
  }
};

const getDateLabel = (
  result:
    | Partial<
        | BusinessUserProfileInterface
        | OfferInterface
        | EventInterface
        | PostInterface
        | NewsInterface
      >
    | undefined,
): string | undefined => {
  return result &&
    result.topicOfTheWeekStartDate &&
    result.topicOfTheWeekEndDate
    ? getDateLabelString(
        result.topicOfTheWeekStartDate,
        result.topicOfTheWeekEndDate,
      )
    : undefined;
};

const getDateLabelString = (
  topicOfTheWeekStartDate: Date,
  topicOfTheWeekEndDate: Date,
): string => {
  const formatter = "D. MMM YYYY";

  const startDate = moment(topicOfTheWeekStartDate).format(formatter);
  const endDate = moment(topicOfTheWeekEndDate).format(formatter);

  return ` (${startDate} - ${endDate})`;
};

const makeCancelable = <T>(promise: Promise<T>) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

const noop = () => {};

const openInSameTab = (url: string) => {
  window.open(url, "_self");
};

const makeQueryStatusValidator = (matchStatus: QueryStatus) => (
  ...statuses: QueryStatus[]
) => statuses.includes(matchStatus);

const isFinished = makeQueryStatusValidator(QueryStatus.SUCCESSFUL);
const isLoading = makeQueryStatusValidator(QueryStatus.WAITING);
const isFailed = makeQueryStatusValidator(QueryStatus.ERROR);
const isKeepLocalEnabled = () =>
  process.env.REACT_APP_KEEP_LOCAL_ENABLED === "true";
export {
  dateReviver,
  noop,
  nullable,
  commaList,
  createPrivilegedComponentSet,
  flatCommaList,
  formattedDate,
  hasAccess,
  hasPrivilegedAccess,
  mapTagsToIdArray,
  parseTotalItemCount,
  getLastPathSegment,
  getTotalPagesFromHeaders,
  getTotalItemsCount,
  getWindowDimensions,
  dispatchBrowserEvent,
  flatArray,
  getDateYearsAgo,
  getContentTypeName,
  getDateLabel,
  makeCancelable,
  openInSameTab,
  isFinished,
  isLoading,
  isFailed,
  isKeepLocalEnabled,
};
