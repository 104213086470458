import styled from "style/styled-components";

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background: #979797;
  opacity: 0.38;
  margin: 20px 0;
`;

export default Separator;
