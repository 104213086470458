import React, { Component } from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";

export interface InputProps {
  disabled?: boolean;
  max?: number;
  onChange: (value: any) => void;
  optional?: boolean; // adds optional tag to field
  placeholder?: string;
  suffix?: string;
  showLabel?: boolean; // toggles label display when value is present
  type?: string;
  validator?: (value: any) => any;
  value?: string | number;
  width?: string;
}

interface Props extends InputProps {
  onChange: (value: any) => void;
  onBlur?: () => void;
}

interface State {
  focused: boolean;
  suffix: string;
  value: string | number;
}

const Input = styled.input<InputProps>`
  padding: 2px 6px;
  border: 1px solid ${({ theme }) => theme.color.foreground.tertiary};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-size: ${({ theme }) => theme.font.size.medium};
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  line-height: 26px;

  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
  }
`;

const Value = styled.span<{ isBlack?: boolean; isBold?: boolean }>`
  padding: 4px 0;
  color: ${({ theme, isBlack }) =>
    isBlack ? theme.color.foreground.primary : theme.color.foreground.tertiary};
  font-size: ${({ theme }) => theme.font.size.medium};
  display: inline-block;
  line-height: 24px;
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
`;

class TransparentInput extends Component<Props, State> {
  private inputRef: HTMLInputElement | null;
  constructor(props: Props) {
    super(props);

    const { suffix, type, value } = props;

    this.state = {
      value: value || (type === "number" && value === 0) ? value : "",
      suffix: suffix || "",
      focused: false,
    };
    this.inputRef = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>): void {
    if (nextProps.value === undefined) this.setState({ value: "" });

    if (typeof nextProps.value === "string") {
      this.setState({ value: nextProps.value });
    }
  }

  changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { validator, onChange } = this.props;
    const inputValue = event.currentTarget.value;
    let value: number | string = inputValue;
    if (validator) {
      if (validator(inputValue)) {
        value = inputValue;
      } else {
        value = this.state.value;
      }
    }
    this.setState({ value }, () => onChange(value));
  };

  focus = () =>
    !this.props.disabled &&
    this.setState({ focused: true }, () => {
      const node = this.inputRef;
      if (node) {
        node.focus();
      }
    });

  blur = () => this.setState({ focused: false });

  render() {
    const {
      width,
      type,
      optional,
      placeholder,
      disabled,
      onBlur,
      showLabel,
    } = this.props;
    const { focused, value, suffix } = this.state;

    const optionalTag = optional ? strings("detailedScreen.optional") : "";

    const showValue = value || (type === "number" && value === 0);
    /**
     * Due to Firefox bug (https://bugzilla.mozilla.org/show_bug.cgi?id=1057858,
     * https://github.com/facebook/react/issues/11062) both onClick and onFocus
     * handlers are necessary for number type inputs to avoid triggering onBlur
     * immediately after onClick.
     *
     * TODO: avoid double triggering of this.focus for all inputs, maybe refactor number input into separate file?
     */
    return (
      <span onClick={this.focus} onFocus={this.focus} onBlur={this.blur}>
        {showLabel && value && `${placeholder} `}
        {focused ? (
          <Input
            disabled={disabled}
            placeholder={`${placeholder} ${optionalTag}`}
            value={value}
            onChange={this.changeHandler}
            onBlur={onBlur}
            width={width}
            type={type || "text"}
            ref={(tag: HTMLInputElement) => (this.inputRef = tag)}
          />
        ) : (
          <Value isBlack={!!showValue && !disabled} isBold={!!value}>
            {showValue ? value : `${placeholder} ${optionalTag}`}
            {showValue && suffix}
          </Value>
        )}
      </span>
    );
  }
}

export default TransparentInput;
