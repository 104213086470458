import React from "react";
import UserDataForm from "components/forms/UserDataForm/UserDataForm";
import useMutation from "common/hooks/useMutation";
import QueryStatus from "common/api/models/QueryStatus";
import { getWizardUserData, putUserData } from "api/users";
import { RouteComponentProps, withRouter } from "react-router";
import useQuery from "common/hooks/useQuery";
import strings from "localisation/strings";
import {
  mapFormValuesToUserPayload,
  UserDataFormValues,
} from "components/forms/UserDataForm/helpers";
import useLoginState from "hooks/useLoginState";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import { routeNames } from "core/navigation";

const WizardUserDataForm = ({ history }: RouteComponentProps) => {
  const { result: fetchedWizardUser } = useQuery({
    request: getWizardUserData,
    compare: [],
  });
  const { makeRequest, status, error } = useMutation(putUserData);
  const loginState = useLoginState();
  const { unblockNavigation } = useGlobalFormBlock();

  const handleWizardUserFormSubmitted = async (
    formFields: UserDataFormValues,
  ) => {
    const wizardUserFormPayload = mapFormValuesToUserPayload(formFields);
    const response = await makeRequest(wizardUserFormPayload);

    if (response && !response.error && response.result) {
      loginState.setUserName(
        response.result.firstName,
        response.result.lastName,
      );
      await unblockNavigation();
      history.push(routeNames.WizardStep.Second);
    }
  };

  return React.createElement(UserDataForm, {
    submitLabel: strings("buttons.next"),
    defaultUserData: fetchedWizardUser,
    isSubmittable: status !== QueryStatus.WAITING,
    formSubmittedErrors: error,
    onSubmit: handleWizardUserFormSubmitted,
  });
};

export default withRouter(WizardUserDataForm);
