import React from "react";
import styled from "style/styled-components";

interface ProgressBarInterface {
  progress: number;
  className?: string;
}

const Bar = styled.div`
  height: 8px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
`;

const BackgroundBar = styled(Bar)`
  flex: 1;
  background-color: ${({ theme }) => theme.color.foreground.quaternary};
`;

const ForegroundBar = styled(Bar)<ProgressBarInterface>`
  width: ${({ progress }) => progress * 100}%;
  background-color: ${({ theme }) => theme.color.foreground.secondaryLight};
  min-width: ${({ progress }) => (progress === 0 ? 0 : 8)}px;
`;

const ProgressBar = ({ progress, className }: ProgressBarInterface) => (
  <BackgroundBar className={className}>
    <ForegroundBar progress={progress} />
  </BackgroundBar>
);

export default ProgressBar;
