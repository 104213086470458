import React from "react";
import SearchFilters from "api/models/Filters";
import {
  ColumnModel,
  DataTableItem,
  Filter,
} from "components/table/DataTable/interfaces";
import { TableComponent } from "components/table/DataTable/components";
import Filters from "components/table/DataTable/Filters";
import Header from "components/table/DataTable/Header";
import Content from "components/table/DataTable/Content";
import { AbsoluteOverlaySpinner } from "components/generic/OverlaySpinner";
import styled from "style/styled-components";

export interface Props {
  allFilters?: SearchFilters;
  appliedFilters?: Filter[];
  columns: ColumnModel[];
  data: DataTableItem[];
  refreshTable: () => void;
  goTo?: (path: string) => void;
  sort?: string;
  setSort: (sort?: string) => void;
  setFilters: (filter?: Filter[]) => void;
  getChange: (body: any, id: string) => void;
  onClickEdit?: (id: string) => void;
  onClickCopy?: (id: string) => void;
  onClickAdd?: (id: string) => void;
  isLoading?: boolean;
  isCategoriesView?: boolean;
  isRepresentativesView?: boolean;
}

const TableWithLoaderContainer = styled(TableComponent)`
  position: relative;
`;

const DataTable = (props: Props) => {
  const {
    allFilters,
    appliedFilters,
    columns,
    getChange,
    sort,
    setSort,
    setFilters,
    data = [],
    onClickEdit,
    onClickCopy,
    onClickAdd,
    isLoading,
    isCategoriesView,
    isRepresentativesView,
    refreshTable,
  } = props;

  return (
    <TableWithLoaderContainer>
      <thead>
        <Header columns={columns} sort={sort} setSort={setSort} />
        <Filters
          columns={columns}
          appliedFilters={appliedFilters}
          setFilters={setFilters}
          allFilters={allFilters}
        />
      </thead>
      <Content
        getChange={getChange}
        columns={columns}
        data={data}
        onClickEdit={onClickEdit}
        onClickCopy={onClickCopy}
        onClickAdd={onClickAdd}
        isCategoriesView={isCategoriesView}
        isRepresentativesView={isRepresentativesView}
        refreshTable={refreshTable}
      />
      {isLoading && <AbsoluteOverlaySpinner />}
    </TableWithLoaderContainer>
  );
};

export default DataTable;
