import I18n from "i18n-js";
import moment from "moment";

import "moment/locale/de";

import { appProfile } from "profiles/AppProfile";

I18n.fallbacks = true;
I18n.defaultLocale = "de";
I18n.translations = appProfile.getTranslations();

export { I18n, moment };
