import React from "react";
import BusinessRepresentativesView from "views/BusinessRepresentatives/BusinessRepresentativesView";
import {
  deleteRepresentativeById,
  getRepresentatives,
  getRepresentativesByBusinessIdCurator,
} from "api/businessRepresentatives";
import { RouteComponentProps, withRouter } from "react-router";
import useRepresentativesList from "views/BusinessRepresentatives/useRepresentativesListView";
import strings from "localisation/strings";
import useLoginState from "hooks/useLoginState";
import { curry } from "lodash";

interface RepresentativeViewRouteParams {
  businessId: string;
}

const makeRepresentativeRouteBp = (representativeId: string) =>
  `/partnervertreter/${representativeId}`;

const makeRepresentativeCuratorRoute = (
  businessId: string,
  representativeId: string,
) => `/inhalt/partner/${businessId}/partnervertreter/${representativeId}`;

const RepresentativesView = ({
  history,
  match,
}: RouteComponentProps<RepresentativeViewRouteParams>) => {
  const { hasCuratorRights } = useLoginState();
  const curatorRights = hasCuratorRights();
  const makeRepresentativeRoute = curatorRights
    ? curry(makeRepresentativeCuratorRoute)(match.params.businessId)
    : makeRepresentativeRouteBp;
  const {
    representatives,
    addRepresentative,
    addCurator,
    editRepresentative,
    totalNumberOfPages,
    totalItems,
    handleDeleteItemClicked,
    page,
    updatePage,
    onPageSizeChange,
    limit,
    loading,
  } = useRepresentativesList({
    makeRepresentativeRoute,
    navigate: history.push,
    listRequest: curatorRights
      ? curry(getRepresentativesByBusinessIdCurator)(match.params.businessId)
      : getRepresentatives,
    deleteRequest: deleteRepresentativeById,
  });

  return React.createElement(BusinessRepresentativesView, {
    page,
    updatePage,
    onPageSizeChange,
    limit,
    loading,
    totalNumberOfPages,
    totalItems,
    handleDeleteItemClicked,
    title: strings("businessRepresentativeList.title"),
    list: representatives,
    handleListItemAddClicked: addRepresentative,
    handleListItemClicked: editRepresentative,
    handleAddCuratorClicked: addCurator,
  });
};

export default withRouter(RepresentativesView);
