import React from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";

import { SimpleText } from "components/generic";
import { ReactComponent as SortIcon } from "common/assets/icons/sort.svg";
import { ReactComponent as FilterIcon } from "common/assets/icons/filter.svg";
import { ReactComponent as SearchIcon } from "common/assets/icons/search.svg";
import { ReactComponent as AddIcon } from "common/assets/icons/add.svg";
import CountBadge from "components/generic/CountBadge";

const Separator = styled.div`
  margin: ${({ theme }) => `${theme.margin.medium} 0px`};
  width: 1px;
  background-color: ${({ theme }) => theme.color.foreground.quaternary};
`;

const ButtonRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding: 0px ${({ theme }) => theme.margin.large};
  border-style: solid;
  border-color: ${({ theme }) => theme.color.foreground.quaternary};
  border-bottom-width: 1px;
`;

const ButtonCell = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.margin.medium};
  cursor: pointer;
`;

const ButtonText = styled(SimpleText)`
  display: block;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.small};
`;

const StyledCountBadge = styled(CountBadge)`
  display: inline;
`;

export interface SortAndFilterHeaderProps {
  filterCount?: number;
  hideFilter?: boolean;
  hideSort?: boolean;
  toggleSorting?: () => void;
  toggleFiltering?: () => void;
  toggleSearch?: () => void;
  onAddNew: () => void;
  sortName?: string;
}

const PhoneSortAndFilterHeader = ({
  filterCount,
  hideFilter,
  hideSort,
  toggleSorting,
  toggleFiltering,
  sortName = "",
  toggleSearch,
  onAddNew,
}: SortAndFilterHeaderProps) => (
  <ButtonRow>
    {!hideFilter && (
      <>
        <ButtonCell onClick={toggleSearch}>
          <SearchIcon width={16} height={16} />
          <ButtonText>{strings("filterableLists.searchButton")}</ButtonText>
        </ButtonCell>
      </>
    )}
    {!hideSort && (
      <>
        <Separator>&ensp;</Separator>
        <ButtonCell onClick={toggleSorting}>
          <SortIcon width={16} height={16} />
          <ButtonText>{`${strings("filterableLists.sortButton")} ${
            (sortName === "filterableLists.defaultSort"
              ? strings("filterableLists.defaultSort")
              : sortName
            ).split("(")[0]
          }`}</ButtonText>
        </ButtonCell>
      </>
    )}
    {!hideFilter && (
      <>
        <Separator>&ensp;</Separator>
        <ButtonCell onClick={toggleFiltering}>
          <FilterIcon width={16} height={16} />
          <div>
            <ButtonText>{strings("filterableLists.filterButton")}</ButtonText>
            {!!filterCount && <StyledCountBadge text={`${filterCount}`} />}
          </div>
        </ButtonCell>
      </>
    )}
    <Separator>&ensp;</Separator>
    <ButtonCell onClick={onAddNew}>
      <AddIcon width={20} height={20} />
      <ButtonText>{strings("filterableLists.addNewButton")}</ButtonText>
    </ButtonCell>
  </ButtonRow>
);

export default PhoneSortAndFilterHeader;
