import React, {
  DetailedHTMLProps,
  RefObject,
  TextareaHTMLAttributes,
} from "react";
import TextArea from "react-textarea-autosize";
import styled from "style/styled-components";
import FieldErrors from "components/forms/FieldErrors";
import RichTextArea from "components/forms/FormFields/RichTextArea";

export interface TextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  onFocus?: () => void;
  errored?: boolean;
  inputRef?: RefObject<HTMLTextAreaElement>;
  onEnter?: () => void;
  errors?: string[];
  richText?: boolean;
  bordered?: boolean;
  minRows?: number;
  onContentChange: (content: string) => void;
  testId?: string;
}

const Container = styled.div`
  align-items: baseline;
  display: flex;
  padding: ${({ theme }) => theme.margin.medium} 0 20px;
  width: 100%;
  flex-flow: column wrap;
`;

const TextAreaContainer = styled.div`
  width: 100%;
`;

interface AutosizeTextAreaProps {
  bordered?: boolean;
  hasErrors?: boolean;
}

const AutosizeTextarea = styled(TextArea)<AutosizeTextAreaProps>`
  width: 100%;
  border: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.color.border.inputBorder}` : "0"};
  border-radius: 4px;
  color: ${({ hasErrors, theme }) =>
    hasErrors ? theme.color.foreground.action : theme.color.foreground.primary};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-family: ${({ theme }) => theme.font.family.firaSans};
  line-height: 24px;
  height: 75px;
  resize: none;
  overflow: hidden;
  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
    color: ${({ hasErrors, theme }) =>
      hasErrors
        ? theme.color.foreground.actionLight
        : theme.color.foreground.tertiary};
  }
`;

const Errors = styled(FieldErrors)`
  margin-top: -5px;
`;

const ErrorsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const TextAreaBlock = (props: TextAreaProps) => {
  const {
    onChange,
    onContentChange,
    placeholder,
    value,
    errors,
    maxLength,
    richText,
    bordered,
    minRows,
    testId = "",
  } = props;
  const hasErrors = Boolean(errors && errors.length);

  return (
    <Container>
      <TextAreaContainer>
        {richText ? (
          <RichTextArea
            onContentChange={onContentChange}
            placeholder={placeholder}
            value={String(value || "")}
            maxLength={maxLength}
            errors={errors}
          />
        ) : (
          <AutosizeTextarea
            onChange={onChange}
            placeholder={placeholder}
            value={String(value || "")}
            hasErrors={hasErrors}
            maxLength={maxLength}
            minRows={minRows}
            bordered={bordered}
          >
            {value}
          </AutosizeTextarea>
        )}
      </TextAreaContainer>
      {errors && (
        <ErrorsContainer>
          {!richText ? (
            <Errors testId={testId} errors={errors} />
          ) : (
            <FieldErrors testId={testId} errors={errors} />
          )}
        </ErrorsContainer>
      )}
    </Container>
  );
};

export default TextAreaBlock;
