import moment from "moment";
import { prop } from "ramda";

import TagInterface from "api/models/TagInterface";
import PriceMinMax from "api/models/PriceMinMax";
import Artist from "api/models/Artist";
import DateTimeRange from "api/models/DateTimeRange";
import { AddressLocation } from "api/models/Location";
import EventInterface from "api/models/EventInterface";
import PublicationStatuses from "api/models/PublicationStatuses";
import EventPayloadInterface from "api/models/EventPayloadInterface";
import { nullable } from "utils";
import PictureTypes from "api/models/PictureTypes";
import Picture from "api/models/Picture";

const getPublishDateTime = (publishDateTime?: Date | null) =>
  publishDateTime ? moment(publishDateTime).toISOString() : null;

const getArtists = (artists?: Artist[] | null) =>
  artists ? artists.filter(artist => artist.name !== "") : [];

interface Values {
  [index: string]: any;
}

const defaultArtists: Partial<Artist>[] = [{ name: "" }];

export const defaultEventFields = {
  artistsFields: {
    artists: defaultArtists,
  },
  textAreaFields: {
    title: "",
    description: "",
  },
  tagsFields: {
    tags: [] as TagInterface[],
  },
  textFields: {
    venue: "",
    pinnedDescription: "",
  },
  imageListFields: {},
  settingsFields: {
    enableComments: true,
    enableRatings: true,
    publishDateTime: null as Date | null,
    unpublishDateTime: null as Date | null,
  },
  priceMinMaxFields: {
    priceMinMax: {} as PriceMinMax,
  },
  dateTimeRangeFields: {
    dateTimeRange: {} as DateTimeRange,
  },
  locationFields: {
    location: {} as AddressLocation,
  },
  singleInputFields: {
    tickets: "",
    treueWeltLink: "",
  },
  checkboxFields: {
    topicOfTheWeek: false,
  },
  mapAssignmentFields: {
    mapAssignments: [],
  },
};

export const setEventFields = (event: Partial<EventInterface>) => {
  const {
    artists = defaultArtists,
    title = "",
    description = "",
    tags = [],
    pictures,
    priceMinMax = {},
    dateTimeRange = {},
    location = {} as AddressLocation,
    enableComments: comments,
    enableRatings: ratings,
    publishDateTime: publish,
    tickets = "",
    treueWeltLink = "",
    unpublishDateTime: unpublish,
    topicOfTheWeek = false,
    hashtags,
    endDateType,
    mapAssignments,
  } = event;

  const picturesList = pictures
    ? pictures.filter(
        o => o.type === PictureTypes.main || o.type === PictureTypes.secondary,
      )
    : null;

  const enableComments = comments === undefined ? true : comments;
  const enableRatings = ratings === undefined ? true : ratings;
  const publishDateTime = publish ? moment(publish).toDate() : null;
  const unpublishDateTime = unpublish ? moment(unpublish).toDate() : null;

  return {
    artistsFields: {
      artists,
    },
    textFields: {
      venue: location.venue,
      pinnedDescription: location ? location.pinnedDescription : "",
    },
    textAreaFields: {
      title,
      description,
    },
    tagsFields: { tags },
    imageListFields: {
      pictures: picturesList,
    },
    settingsFields: {
      enableComments,
      enableRatings,
      publishDateTime,
      unpublishDateTime,
    },
    priceMinMaxFields: { priceMinMax },
    dateTimeRangeFields: { dateTimeRange },
    locationFields: { location },
    singleInputFields: { tickets, treueWeltLink },
    checkboxFields: {
      topicOfTheWeek,
    },
    hashtagsFields: { hashtags },
    endDateTypeFields: { endDateType },
    mapAssignmentFields: { mapAssignments },
  };
};

export const getEventPayload = (
  status: PublicationStatuses,
  values: Values,
  oldBody: Partial<EventInterface> = {},
): Partial<EventPayloadInterface> => {
  const {
    artists,
    unpublishDateTime: unpublish,
    publishDateTime: publish,
    pictures: pictureValues,
    modifiedDateTime: modified,
    title,
    description,
    enableComments,
    enableRatings,
    location,
    dateTimeRange,
    priceMinMax,
    tags,
    tickets,
    treueWeltLink,
    venue,
    topicOfTheWeek,
    pinnedDescription,
    endDateType,
    hashtags,
    mapAssignments,
  } = values;
  const pictures = pictureValues ? [...pictureValues] : [];

  const tagIds = (tags || []).map(prop("id"));
  const unpublishDateTime = unpublish ? moment(unpublish).toISOString() : null;
  const modifiedDateTime = modified || null;
  const publishDateTime = getPublishDateTime(publish);
  const filteredArtists = getArtists(artists);

  const optionalFields: {
    pictures?: Picture[];
  } = {};

  return {
    ...oldBody,
    status,
    title,
    description,
    enableComments,
    enableRatings,
    dateTimeRange,
    priceMinMax,
    tagIds,
    pictures,
    unpublishDateTime,
    modifiedDateTime,
    publishDateTime,
    tickets,
    treueWeltLink,
    topicOfTheWeek,
    endDateType,
    hashtags,
    artists: filteredArtists,
    location: {
      venue: nullable(venue),
      city: nullable(location.city),
      houseNo: nullable(location.houseNo),
      postalCode: nullable(location.postalCode),
      street: nullable(location.street),
      latitude: nullable(location.latitude),
      longitude: nullable(location.longitude),
      placeId: nullable(location.placeId),
      pinned: location.pinned,
      pinnedDescription: nullable(pinnedDescription),
    },
    mapAssignments,
    ...optionalFields,
  } as Partial<EventPayloadInterface>;
};
