enum EntityTypes {
  general = "GENERAL",
  events = "EVENTS",
  news = "NEWS",
  posts = "POSTS",
  unknown = "UNKNOWN",
  offers = "OFFERS",
  shopping = "SHOPPING",
  hobby = "HOBBY",
  eating_drinking = "EATING_DRINKING",
  home_services = "HOME_SERVICES",
  professional_services = "PROFESSIONAL_SERVICES",
  craftsman = "CRAFTSMAN",
  health = "HEALTH",
  service = "SERVICE",
  null = "NULL",
}

export default EntityTypes;
