import ContentType from "common/api/models/ContentType";

export const getOptionString = (contentType: ContentType): string => {
  switch (contentType) {
    case "BUSINESS":
      return "businessProfile";
    case "OFFER":
      return "offer";
    case "EVENT":
      return "event";
    case "POST":
      return "post";
    case "NEWS":
      return "news";
    default:
      return "";
  }
};
