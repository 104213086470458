import React from "react";
import { ReactComponent as StarIcon } from "assets/icons/big-filled.svg";
import styled from "style/styled-components";

const DEFAULT_SIZE = 14;

const Container = styled.div`
  width: ${DEFAULT_SIZE}px;
  height: ${DEFAULT_SIZE}px;
  position: relative;
`;

const PartialContainer = styled.div<StatsRatingStarProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ progress }) => progress * 100}%;
  overflow: hidden;
`;

const BackgroundStar = styled(StarIcon)`
  display: block;
  width: ${DEFAULT_SIZE}px;
  height: ${DEFAULT_SIZE}px;
  path {
    fill: ${({ theme }) => theme.color.background.tertiary};
  }
`;

const ActiveStar = styled(BackgroundStar)`
  path {
    fill: ${({ theme }) => theme.color.foreground.noticeMedium};
  }
`;
export interface StatsRatingStarProps {
  progress: number;
}

export const StatsRatingStar = ({ progress }: StatsRatingStarProps) => (
  <Container>
    <BackgroundStar />
    <PartialContainer progress={progress}>
      <ActiveStar />
    </PartialContainer>
  </Container>
);

export default StatsRatingStar;
