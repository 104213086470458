import RequestOptions from "api/RequestOptions";

import AuthedAPI from "api/AuthedAPI";
import SimpleSearchInterface from "api/models/SimpleSearchInterface";
import ListParams from "api/models/ListParams";
import ListResultType from "api/models/ListResultType";
import {
  RepresentativeInterface,
  ExtendedRepresentativeInterface,
  AssignCuratorPayload,
} from "api/models/RepresentativeInterface";
import { BusinessFilterInterface } from "api/models/BusinessFilterInterface";
import { BackOfficeRepresentativeInterface } from "api/models/BackOfficeRepresentativeInterface";

const clientAPI = new AuthedAPI({});

const getRepresentativeRoles = () =>
  clientAPI.getJson<string[]>({
    path: "business-profiles/bp/business-profiles/representatives/roles",
  });

const postRepresentativesAdminSearch = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  clientAPI.postListJson<ListResultType<ExtendedRepresentativeInterface>>({
    queryParams,
    options,
    body,
    path: "users/bo/business-profiles/representatives/search",
  });

const postRepresentative = (
  body: Partial<RepresentativeInterface>,
  options: RequestOptions | undefined,
) => {
  return clientAPI.postJson<RepresentativeInterface>({
    options,
    body,
    path: "business-profiles/bp/business-profiles/representatives",
  });
};

const postRepresentativeCurator = (
  body: Partial<BackOfficeRepresentativeInterface>,
  options: RequestOptions | undefined,
) => {
  return clientAPI.postJson<RepresentativeInterface>({
    options,
    body,
    path: `business-profiles/bp/curator/business-profiles/${body.businessId}/representatives`,
  });
};

const getRepresentatives = (
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  clientAPI.postListJson<ListResultType<RepresentativeInterface>>({
    queryParams,
    options,
    body,
    path: "business-profiles/bp/business-profiles/representatives/search",
  });

const getRepresentativesByBusinessId = (
  businessId: string | undefined,
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  clientAPI.postListJson<ListResultType<RepresentativeInterface>>({
    queryParams,
    options,
    body,
    path: `business-profiles/bo/business-profiles/${businessId}/representatives/search`,
  });

const getRepresentativesByBusinessIdCurator = (
  businessId: string | undefined,
  body: SimpleSearchInterface | undefined,
  queryParams: ListParams | undefined,
  options: RequestOptions | undefined,
) =>
  clientAPI.postListJson<ListResultType<RepresentativeInterface>>({
    queryParams,
    options,
    body,
    path: `business-profiles/bp/curator/business-profiles/${businessId}/representatives/search`,
  });

const putRepresentative = (
  body: Partial<RepresentativeInterface>,
  options: RequestOptions | undefined,
) =>
  clientAPI.putJson<RepresentativeInterface>({
    options,
    body,
    path: `business-profiles/bp/business-profiles/representatives/${body.userId}`,
  });

const getBpRepresentativeById = (
  param: { id: string },
  options: RequestOptions | undefined,
) =>
  clientAPI.getJson<RepresentativeInterface>({
    options,
    path: `business-profiles/bp/business-profiles/representatives/${param.id}`,
  });

const getCuratorRepresentativeById = (
  param: { id: string; businessId?: string },
  options?: RequestOptions,
) =>
  clientAPI.getJson<RepresentativeInterface>({
    options,
    path: `business-profiles/bp/curator/business-profiles/${param.businessId}/representatives/${param.id}`,
  });

const deleteRepresentativeById = (
  entityId: string,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.deleteEntity<void>({
    queryParams,
    entityId,
    path: "business-profiles/bp/business-profiles/representatives",
  });

const activateRepresentativeById = (
  userId: string,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.postJson<Partial<RepresentativeInterface>>({
    queryParams,
    path: `users/bo/business-profiles/representatives/${userId}/activate`,
  });

const deactivateRepresentativeById = (
  userId: string,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.postJson<Partial<RepresentativeInterface>>({
    queryParams,
    path: `users/bo/business-profiles/representatives/${userId}/deactivate`,
  });

const updateRepresentativeRole = (
  userId: string,
  body: { userId: string; role: string },
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.postJson<Partial<RepresentativeInterface>>({
    queryParams,
    body,
    path: `users/bo/business-profiles/representatives/role`,
  });

const deleteInactiveBusinessRepresentative = (
  userId: string,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.deleteEntity<Partial<RepresentativeInterface>>({
    queryParams,
    entityId: userId,
    path: "users/bo/business-profiles/representatives",
  });

const getAllBusinessesIdsAssignedToRepresentative = (
  userId: string,
  options?: RequestOptions,
) =>
  clientAPI.getJson<ListResultType<BusinessFilterInterface>>({
    options,
    path: `business-profiles/bo/business-profiles/${userId}/businesses`,
  });

const addAdditionalBusiness = (
  body: Partial<AssignCuratorPayload>,
  queryParams: RequestOptions | undefined,
) =>
  clientAPI.postJson<Partial<AssignCuratorPayload>>({
    queryParams,
    body,
    path: `business-profiles/bo/business-profiles/${body.businessId}/representatives/curator/additional-business`,
  });

const removeAdditionalBusinessAdmin = (
  userId: string,
  entityId: string,
  queryParams?: RequestOptions,
) =>
  clientAPI.deleteEntity<void>({
    queryParams,
    entityId,
    path: `business-profiles/bo/business-profiles/representatives/curator/${userId}/additional-business`,
  });

export {
  activateRepresentativeById,
  deactivateRepresentativeById,
  getRepresentativeRoles,
  getRepresentatives,
  getRepresentativesByBusinessIdCurator,
  postRepresentativesAdminSearch,
  getRepresentativesByBusinessId,
  postRepresentative,
  postRepresentativeCurator,
  putRepresentative,
  getBpRepresentativeById,
  getCuratorRepresentativeById,
  deleteRepresentativeById,
  updateRepresentativeRole,
  deleteInactiveBusinessRepresentative,
  getAllBusinessesIdsAssignedToRepresentative,
  addAdditionalBusiness,
  removeAdditionalBusinessAdmin,
};
