import React, { useEffect, useState } from "react";
import { SelectList } from "components/table/DataTable/Fields";
import strings from "localisation/strings";
import MapInterface from "api/models/MapInterface";
import { SelectItem } from "components/forms/Select";
import {
  MapAssignmentPayload,
  MapCurationPayload,
  MapAllowancePayload,
} from "api/models/Plan";

interface MapsDropdownProps {
  allMaps: MapInterface[];
  values: MapCurationPayload[];
  entityId?: string;
  fieldName: string;
  width?: string;
  style?: string;
  getMapById: (mapId: any) => MapInterface | undefined;
  onChange: (selectedField: any, selectedValue: any) => void;
}

interface StringSelectOption {
  value: string;
  label: string;
}

const mapToSelectOption = (map: MapInterface): StringSelectOption => ({
  value: map.id,
  label: map.mapName,
});

const filterValidMapsCombinations = (
  items: SelectItem[],
  getMapById: (mapId: any) => MapInterface | undefined,
  fieldName: string,
  entityId?: string,
) => {
  const mapCombinationsList: (
    | MapCurationPayload
    | MapAllowancePayload
    | MapAssignmentPayload
  )[] = [];
  const mapsList: MapInterface[] = [];

  items.forEach(item => {
    const map = getMapById(item.value);
    if (map) {
      if (fieldName === "mapAssignments") {
        mapCombinationsList.push({
          contentId: entityId!,
          mapId: map.id,
          status: map.mapStatus,
        } as MapAssignmentPayload);
      } else if (fieldName === "mapCuration") {
        mapCombinationsList.push({
          mapId: map.id,
          status: map.mapStatus,
        } as MapCurationPayload);
      } else {
        mapCombinationsList.push({
          businessId: entityId!,
          mapId: map.id,
        } as MapAllowancePayload);
      }
      mapsList.push(map);
    }
  });

  return { mapCombinationsList, mapsList };
};

const MapsDropdown = ({
  allMaps,
  values,
  entityId,
  fieldName,
  width,
  style,
  onChange,
  getMapById,
}: MapsDropdownProps) => {
  const [
    selectedMapCombinationsList,
    setSelectedMapCombinationsList,
  ] = useState<
    (MapCurationPayload | MapAllowancePayload | MapAssignmentPayload)[]
  >([]);
  const [selectedMapsList, setSelectedMapsList] = useState<MapInterface[]>([]);
  const [
    selectedMapsValuesForSelectList,
    setSelectedMapsValuesForSelectList,
  ] = useState<any>([]);

  useEffect(() => {
    const { mapCombinationsList, mapsList } = filterValidMapsCombinations(
      values.map(({ mapId }) => ({ value: mapId })),
      getMapById,
      fieldName,
      entityId,
    );
    setSelectedMapCombinationsList(mapCombinationsList);
    setSelectedMapsValuesForSelectList(mapsList.map(mapToSelectOption));
    setSelectedMapsList(mapsList);
  }, []);

  useEffect(() => {
    setSelectedMapsValuesForSelectList(selectedMapsList.map(mapToSelectOption));
    onChange(fieldName, selectedMapCombinationsList);
  }, [selectedMapsList]);

  const onItemSelect = (selectedItems: SelectItem[]) => {
    const { mapCombinationsList, mapsList } = filterValidMapsCombinations(
      selectedItems,
      getMapById,
      fieldName,
      entityId,
    );
    setSelectedMapCombinationsList(mapCombinationsList);
    setSelectedMapsList(mapsList);
  };

  const mapsForSelectList: StringSelectOption[] = allMaps
    .map(mapToSelectOption)
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectList
      isMulti
      options={mapsForSelectList}
      onChange={onItemSelect}
      placeholder={strings(
        "businessSettings.additional-settings.fields.mapSelection.placeholder",
      )}
      width={width || "50%"}
      classNamePrefix="mapDropdown"
      value={selectedMapsValuesForSelectList}
      style={style}
    />
  );
};

export default MapsDropdown;
