import React from "react";
import strings from "localisation/strings";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import PlanSelection from "views/Wizard/PlanSelection";
import { getAvailablePlans, getBusinessPlan } from "api/businessProfiles";
import Plan from "api/models/Plan";
import useLocale from "common/hooks/useLocale";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import { withBackButton } from "components/generic/ActionArea";
import { routeNames } from "core/navigation";
import { RouteComponentProps } from "core/types";
import useCurrentUserBusinessId from "hooks/useCurrentUserBusinessId";
import useCurrentBusinessPlan from "views/BusinessSettings/PlanSelection/hooks/useCurrentBusinessPlan";
import BusinessSettingsSidebar from "views/BusinessSettings/BusinessSettingsSidebar";

const PlanChangeActionArea = withBackButton(() => null);

const BusinessSettingsPlanChange = ({ history }: RouteComponentProps) => {
  const locale = useLocale();

  const { result: plans, status: fetchAvailablePlansStatus } = useQuery({
    request: () => getAvailablePlans(false),
    compare: [locale],
  });

  const businessId = useCurrentUserBusinessId();
  const { loading: currentPlanLoading, currentPlan } = useCurrentBusinessPlan({
    businessId,
    request: getBusinessPlan,
  });

  const handleBackClicked = () => {
    history.push({
      pathname: routeNames.BusinessSettings.Plan,
    });
  };

  const handlePlanSelected = (plan: Plan) => {
    history.push({
      pathname: routeNames.BusinessSettings.PlanChangeConfirmation,
      state: { selectedPlan: plan },
    });
  };

  const handleCurrentPlanSelected = handleBackClicked;

  return (
    <BusinessSettingsLayout
      expand
      size="full"
      isLoading={
        fetchAvailablePlansStatus === QueryStatus.WAITING || currentPlanLoading
      }
      title={strings("businessSettings.planChange.title")}
      sidebar={<BusinessSettingsSidebar />}
    >
      {plans && plans.length > 0 && (
        <PlanSelection
          plans={plans}
          onSelect={handlePlanSelected}
          currentPlan={currentPlan}
          onCurrentPlanSelect={handleCurrentPlanSelected}
        />
      )}
      <PlanChangeActionArea
        isBackButtonEnabled={true}
        isActionAreaOneElement={true}
        onBackButtonClicked={handleBackClicked}
      />
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsPlanChange;
