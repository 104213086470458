import { useEffect } from "react";
import { curry } from "ramda";
import useMutation from "common/hooks/useMutation";
import { getAdminNotifications, getNotifications } from "api/chat";
import chatState from "state/singletons/chatState";
import useChatState from "hooks/useChatState";
import useLoginState from "hooks/useLoginState";

const UNREAD_NOTIFICATIONS_UPDATE_FREQUENCY = 10000;

const TotalUnreadCountChangeListener = () => {
  const { active } = useChatState();
  const {
    isUserLoggedIn,
    getBusinessId,
    hasAdminRights,
    getProfileCompleted,
    getDefaultBusinessId,
    isDefaultBusinessContextOrUnselected,
  } = useLoginState();

  const businessId = getBusinessId() || getDefaultBusinessId();

  const notificationRequest = hasAdminRights()
    ? curry(getAdminNotifications)(businessId)
    : getNotifications;

  const {
    makeRequest: makeNotificationsRequest,
    result: notifications,
  } = useMutation(notificationRequest);

  useEffect(() => {
    if (notifications) {
      chatState.setUnreadMessagesCount(notifications.totalUnreadCount);
    }
  }, [notifications]);

  const isAdmin = hasAdminRights();
  const isLoggedIn = isUserLoggedIn();
  const isProfileComplete = getProfileCompleted();
  const isDefaultContextOrUnselected = isDefaultBusinessContextOrUnselected();

  useEffect(() => {
    if (
      isLoggedIn &&
      isDefaultContextOrUnselected &&
      (isAdmin || isProfileComplete) &&
      !active
    ) {
      makeNotificationsRequest({});

      const intervalRef = window.setInterval(() => {
        makeNotificationsRequest({});
      }, UNREAD_NOTIFICATIONS_UPDATE_FREQUENCY);

      return () => {
        window.clearInterval(intervalRef);
      };
    }
  }, [
    isLoggedIn,
    isProfileComplete,
    active,
    getBusinessId(),
    isDefaultBusinessContextOrUnselected(),
  ]);

  return null;
};

export default TotalUnreadCountChangeListener;
