import { useCallback } from "react";
import { isNil } from "ramda";
import { Message, QuickReplyOption } from "components/chat/types";
import { OutgoingFlowMessageDTO } from "api/models/OutgoingMessageDTO";
import { publishChatMessage, publishChatFlowMessage } from "api/chat";
import chatState from "state/singletons/chatState";
import useConversationStatus from "components/chat/hooks/useConversationStatus";

const useMessagePublisher = (conversationId: string) => {
  const { flowMessageAwaitingReply } = useConversationStatus(conversationId);

  const handleQuickReplySelected = useCallback(
    (
      quickReplyMessageId: string,
      selectedQuickReplyOption: QuickReplyOption,
      selectedQuickReplyOptionValue?: string,
    ) => {
      chatState
        .updateMessage(conversationId, quickReplyMessageId, {
          selectedValue:
            selectedQuickReplyOptionValue ||
            selectedQuickReplyOption.value.reply,
          isInteracted: true,
          /**
           * Mark the message as unsent as now we are sending a reply by selecting some option
           * This will be covered by tests in the future
           */
          isSent: false,
        })
        .then(() => {
          chatState.updateRefreshKey();
        });

      if (!isNil(selectedQuickReplyOptionValue)) {
        // Mutate the quick reply option value in case of dynamic input
        selectedQuickReplyOption.value.reply = selectedQuickReplyOptionValue;
      }

      publishChatFlowMessage(
        conversationId,
        selectedQuickReplyOption.value as OutgoingFlowMessageDTO,
      );
    },
    [conversationId],
  );

  const handleMessageSent = useCallback(
    (sentMessages: Message[]) => {
      sentMessages.forEach(({ text, _id }) => {
        if (flowMessageAwaitingReply) {
          handleQuickReplySelected(
            flowMessageAwaitingReply._id,
            flowMessageAwaitingReply.quickReplies[0],
            text,
          );
        } else {
          publishChatMessage(conversationId, {
            conversationId,
            referenceId: _id,
            messageText: text,
          });
          chatState.appendMessages(conversationId, sentMessages);
        }
      });

      chatState.pushConversationToTop(conversationId);
    },
    [conversationId, handleQuickReplySelected, flowMessageAwaitingReply],
  );

  return {
    handleMessageSent,
    handleQuickReplySelected,
  };
};

export default useMessagePublisher;
