import React, { useEffect } from "react";
import strings from "localisation/strings";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import { updateBusinessPlan } from "api/businessProfiles";
import Plan from "api/models/Plan";
import useMutation from "common/hooks/useMutation";
import { routeNames } from "core/navigation";
import PlanChangeConfirmationForm from "views/BusinessSettings/PlanSelection/forms/PlanChangeConfirmationForm";
import useNotificationState from "hooks/useNotification";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import { isFailed, isFinished, isLoading } from "utils";
import { RouteComponentProps } from "core/types";
import BusinessSettingsSidebar from "views/BusinessSettings/BusinessSettingsSidebar";

interface BusinessSettingsPlanChangeConfirmationState {
  selectedPlan?: Plan;
}

const BusinessSettingsPlanChangeConfirmation = ({
  history,
  location: { state: { selectedPlan } = { selectedPlan: undefined } },
}: RouteComponentProps<BusinessSettingsPlanChangeConfirmationState>) => {
  const {
    error: updateBusinessPlanError,
    status: updateBusinessPlanStatus,
    makeRequest: makeUpdateBusinessPlanRequest,
  } = useMutation(updateBusinessPlan);
  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const {
    unblockNavigation,
    state: { isNavigationBlocked },
  } = useGlobalFormBlock();

  const handleFormSubmitted = () => {
    if (selectedPlan) {
      makeUpdateBusinessPlanRequest({
        planId: selectedPlan.planId,
      });
    }
  };

  useEffect(() => {
    if (!isNavigationBlocked && isFinished(updateBusinessPlanStatus)) {
      history.push(routeNames.BusinessSettings.Plan);
    }
  }, [updateBusinessPlanStatus, isNavigationBlocked]);

  useEffect(() => {
    if (isFinished(updateBusinessPlanStatus)) {
      addSuccessNotification(strings("generic.success"));
      unblockNavigation();
    }

    if (isFailed(updateBusinessPlanStatus)) {
      addErrorNotification(
        (updateBusinessPlanError && updateBusinessPlanError.detail) ||
          strings("generic.error"),
      );
    }
  }, [updateBusinessPlanStatus]);

  useEffect(() => {
    if (!selectedPlan) {
      // Most likely directly navigated into this page without selecting a plan
      history.replace(routeNames.BusinessSettings.PlanChange);
    }
  }, [selectedPlan]);

  return (
    <BusinessSettingsLayout
      title={strings("businessSettings.planChangeConfirmation.title")}
      sidebar={<BusinessSettingsSidebar />}
    >
      {selectedPlan && (
        <PlanChangeConfirmationForm
          submittable={!isLoading(updateBusinessPlanStatus)}
          selectedPlan={selectedPlan}
          onBackClick={history.goBack}
          onSubmit={handleFormSubmitted}
        />
      )}
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsPlanChangeConfirmation;
