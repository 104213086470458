import { removeEmptyValues } from "components/helpers/utils";

export interface UserDataFormValues {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneCode: string;
}

export interface UserDataFormFields {
  hiddenFields: {
    userId: string;
  };
  textFields: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  selectFields: {
    phoneCode: string;
  };
}

export const mapUserValuesToFormFields = ({
  userId = "",
  firstName = "",
  lastName = "",
  email = "",
  phone = "",
  phoneCode = "+49",
}: Partial<UserDataFormValues>): UserDataFormFields => ({
  hiddenFields: {
    userId,
  },
  textFields: {
    firstName,
    lastName,
    email,
    phone,
  },
  selectFields: {
    phoneCode: phoneCode || "+49",
  },
});

export const mapFormValuesToUserPayload = <TPayload>(
  payload: TPayload,
): TPayload => removeEmptyValues(payload);
