import React, { ReactNode, Component } from "react";
import { intersperse } from "ramda";
import ListItemSeparator from "components/lists/item/ListItemSeparator";
import { NoContent } from "components/generic";
import strings from "localisation/strings";

export interface ListProps<T> {
  emptyIndicatable?: boolean;
  emptyIndicator?: string;
  data: T[];
  // Note, the index taken as input should take separators into consideration
  // For example: in a data list of 2 items, the second item renderItem call would get "2" as index - #0 first item, #1 separator, #2 second item
  renderItem: (item: T, index: number) => ReactNode;
}

class List<T> extends Component<ListProps<T>> {
  render() {
    if (this.props.emptyIndicatable && !this.props.data.length) {
      return (
        <NoContent
          message={this.props.emptyIndicator || strings("tableView.noContent")}
        />
      );
    }

    return (
      <>
        {intersperse<"separator" | T>(
          "separator",
          this.props.data,
        ).map((item, index) =>
          item === "separator" ? (
            <ListItemSeparator key={index} />
          ) : (
            this.props.renderItem(item, index)
          ),
        )}
        {this.props.data.length > 0 && <ListItemSeparator />}
      </>
    );
  }
}

export default List;
