import React, {
  Component,
  HTMLProps,
  DetailedHTMLProps,
  InputHTMLAttributes,
  RefObject,
} from "react";
import styled, { css } from "style/styled-components";
import FieldContainer from "components/forms/FieldContainer";
import FieldHint from "components/forms/FieldHint";

const topPadding = 20;

export const InputStyles = css`
  margin: 0;
  padding: ${({ theme }) => theme.margin.large};
  padding-top: ${topPadding}px;
  font-size: ${({ theme }) => theme.font.size.medium};

  &::placeholder {
    color: ${({ theme }) => theme.color.foreground.tertiary};
  }

  &:disabled {
    // Override iOS / Android font color change
    -webkit-text-fill-color: ${({ theme }) => theme.color.foreground.tertiary};
    // Override iOS opacity change affecting text & background color
    -webkit-opacity: 1;
  }
`;

export const StyledInput = styled.input<Partial<HTMLProps<HTMLInputElement>>>`
  width: 100%;
  height: 100%;
  color: ${({ theme, disabled }) =>
    !disabled
      ? theme.color.foreground.primary
      : theme.color.foreground.tertiary};
  ${InputStyles};
`;

interface InputState {
  focused?: boolean;
}

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onFocus?: () => void;
  onBlur?: () => void;
  errored?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  onEnter?: () => void;
}
class Input extends Component<InputProps, InputState> {
  state = {} as InputState;

  onFocus = (callback?: () => void) => () =>
    this.setState({ focused: true }, callback);

  onBlur = (callback?: () => void) => () =>
    this.setState({ focused: false }, callback);

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter" && this.props.onEnter) {
      event.preventDefault();
      event.stopPropagation();
      this.props.onEnter();
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  render() {
    const {
      className,
      onFocus,
      onBlur,
      onEnter,
      errored,
      inputRef,
      value,
      disabled = false,
      ...props
    } = this.props;
    return (
      <FieldContainer
        className={className}
        errored={errored}
        focused={this.state.focused}
      >
        <FieldHint>{value ? props.placeholder : " "}</FieldHint>
        <StyledInput
          {...props}
          onKeyDown={this.onKeyDown}
          ref={inputRef}
          value={value}
          onFocus={this.onFocus(onFocus)}
          onBlur={this.onBlur(onBlur)}
          disabled={disabled}
          {...this.state}
        />
      </FieldContainer>
    );
  }
}

export default Input;
