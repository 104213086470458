import { getPaymentAddress } from "api/businessProfiles";
import Location from "api/models/Location";
import { PaymentAddressDTO } from "api/models/Payments";
import vatIdIcon from "assets/icons/vat-id.svg";
import briefcaseIcon from "common/assets/icons/briefcase-black.svg";
import phoneIcon from "common/assets/icons/contact.svg";
import emailIcon from "common/assets/icons/mail.svg";
import useQuery from "common/hooks/useQuery";
import { LocationInput, TextWithIcon } from "components/forms/FormFields";
import { Title } from "components/generic";
import { FormErrorsInterface } from "hooks/useFormFields";
import strings from "localisation/strings";
import React, { ChangeEvent, useEffect } from "react";
import appTheme from "style/theme";
import { TextWithIconProps } from "components/forms/FormFields/TextWithIcon";

const WideTextWithIcon = (props: TextWithIconProps) => (
  <TextWithIcon
    {...props}
    leftSideWidth={appTheme.forms.planPrice}
    width={appTheme.forms.full}
  />
);

export interface PaymentAddressProps {
  paymentAddress: PaymentAddressDTO;
  onChangePaymentAddressField: (
    field: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  onChangeLocation: (value: Location) => void;
  setSavedPaymentAddressDetails: (nextValues: PaymentAddressDTO) => void;
  errors: FormErrorsInterface;
}

const PaymentAddressDetails = ({
  paymentAddress,
  onChangePaymentAddressField,
  onChangeLocation,
  errors,
  setSavedPaymentAddressDetails,
}: PaymentAddressProps) => {
  const { phone, email, companyName, vatId } = paymentAddress;

  const { result } = useQuery({
    request: getPaymentAddress,
    compare: {},
  });

  useEffect(() => {
    if (result) {
      setSavedPaymentAddressDetails(result);
    }
  }, [result]);

  return (
    <div>
      <Title fixedMargins>
        {strings("businessSettings.paymentEdit.paymentAddressTitle")}
      </Title>
      <WideTextWithIcon
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangePaymentAddressField("companyName", event)
        }
        value={companyName}
        label={strings(
          "businessSettings.paymentEdit.paymentAddressForm.companyName.label",
        )}
        placeholder={strings(
          "businessSettings.paymentEdit.paymentAddressForm.companyName.placeholder",
        )}
        icon={briefcaseIcon}
        errors={errors.companyName}
      />
      <LocationInput
        location={paymentAddress}
        onChangeLocation={onChangeLocation}
        errors={errors}
        leftSideWidth={appTheme.forms.planPrice}
        hideVenue
      />
      <WideTextWithIcon
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangePaymentAddressField("email", event)
        }
        value={email}
        label={strings(
          "businessSettings.paymentEdit.paymentAddressForm.email.label",
        )}
        placeholder={strings(
          "businessSettings.paymentEdit.paymentAddressForm.email.placeholder",
        )}
        icon={emailIcon}
        errors={errors.email}
      />
      <WideTextWithIcon
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangePaymentAddressField("phone", event)
        }
        value={phone}
        label={strings(
          "businessSettings.paymentEdit.paymentAddressForm.phone.label",
        )}
        placeholder={strings(
          "businessSettings.paymentEdit.paymentAddressForm.phone.placeholder",
        )}
        icon={phoneIcon}
        errors={errors.phone}
      />
      <WideTextWithIcon
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangePaymentAddressField("vatId", event)
        }
        value={vatId}
        label={strings(
          "businessSettings.paymentEdit.paymentAddressForm.vatId.label",
        )}
        placeholder={strings(
          "businessSettings.paymentEdit.paymentAddressForm.vatId.placeholder",
        )}
        icon={vatIdIcon}
        errors={errors.vatId}
      />
    </div>
  );
};
export default PaymentAddressDetails;
