import { useState, useEffect } from "react";
import notificationState from "state/singletons/notificationState";

const useNotificationState = () => {
  // This state is just for triggering an update.
  const [, update] = useState(Date.now());

  const notificationChangeListener = () => {
    // Trigger a hook state update when something changes in the notificationState.
    update(Date.now());
  };

  // Subscribe to notificationState changes on mount, unsubscribe on unmount
  useEffect(() => {
    notificationState.subscribe(notificationChangeListener);
    return () => {
      notificationState.unsubscribe(notificationChangeListener);
    };
  }, []);

  return notificationState;
};

export default useNotificationState;
