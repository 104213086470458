import React from "react";
import useLocale from "common/hooks/useLocale";
import RichTextEditor from "components/generic/richText/RichTextEditor";
import { TextAreaProps } from "components/forms/ValidatedFields/TextArea";

const RichTextArea = (props: TextAreaProps) => {
  const { onContentChange, value, errors, placeholder } = props;
  const hasErrors = Boolean(errors && errors.length);
  const locale = useLocale();

  return (
    <div className={hasErrors ? "rte-has-errors" : ""}>
      <RichTextEditor
        value={String(value || "")}
        onContentChange={onContentChange}
        uiLocale={locale}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RichTextArea;
