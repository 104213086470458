import React from "react";
import { ReactComponent as CardIcon } from "assets/icons/plans.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as BankIcon } from "assets/icons/account-balance.svg";
import { ReactComponent as EditIcon } from "assets/icons/pen.svg";
import { ReactComponent as EmailIcon } from "assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/contact.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import styled from "style/styled-components";
import strings from "localisation/strings";
import ButtonWithConfirmation from "components/buttons/ButtonWithConfirmation";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import PrivilegedComponent from "components/PrivilegedComponent";
import { PaymentDataResponseDTO } from "api/models/Payments";
import { noop } from "utils";
import IconActionButton from "components/buttons/IconActionButton";

const cardIconAttrs = {
  width: 18,
  height: 18,
};

// Used to prevent the button from stretching to full height
const ConfirmationTooltipButtonContainer = styled.div``;

const PaymentListBankIcon = styled(BankIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentListCardIcon = styled(CardIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentListEmailIcon = styled(EmailIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentListPhoneIcon = styled(PhoneIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentListLocationIcon = styled(LocationIcon).attrs(cardIconAttrs)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const PaymentListEditIcon = styled(EditIcon).attrs(cardIconAttrs)`
  padding-right: ${({ theme }) => theme.margin.large};
  box-sizing: content-box;
  cursor: pointer;
  z-index: 1;
`;

const PaymentListDeleteIcon = styled(DeleteIcon).attrs(cardIconAttrs)`
  padding-right: ${({ theme }) => theme.margin.large};
  box-sizing: content-box;
  cursor: pointer;
  z-index: 1;
`;

const PaymentMethodListItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
`;

const PaymentMethodListItemSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.border.medium};
  padding-bottom: ${({ theme }) => theme.margin.large};
  padding-top: ${({ theme }) => theme.margin.large};
`;

const PaymentMethodListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.margin.large};
`;

const PaymentDetailsContainer = styled.div<{ expand?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ expand }) => expand && "flex-grow: 1;"}
  margin-right: ${({ theme }) => theme.margin.large};
`;

interface InfoTextProps {
  type?: "success" | "error" | "info" | "action";
  bold?: boolean;
  stacked?: boolean;
}

const CardInfoText = styled.span<InfoTextProps>`
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ theme, type = "info" }) => {
    const colorMap = {
      action: theme.color.foreground.action,
      success: theme.color.foreground.success,
      error: theme.color.foreground.error,
      info: theme.color.foreground.primary,
    };

    return colorMap[type];
  }};
  ${({ type = "info" }) => type === "action" && `cursor: pointer;`}
  ${({ theme, stacked }) => stacked && `margin-top: ${theme.margin.xsmall};`};
`;

const CardSection = styled.div`
  margin-top: ${({ theme }) => theme.margin.large};
`;

const ExpiredCardOverlay = styled.div`
  position: absolute;
  background: white;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PaymentMethodTypeTitle = styled.span`
  margin-top: ${({ theme }) => theme.margin.large};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.large};
  line-height: 48px;
`;

interface IdProps {
  id: string;
}

interface ClickHandlerProps {
  onClick: () => void;
}

interface OptionalClickHandlerProps {
  onClick?: () => void;
}

interface OptionalConfirmationProps {
  confirm?: boolean;
}

const AlterDefaultButton = ({ id, onClick }: IdProps & ClickHandlerProps) => (
  <PrivilegedComponent id={PrivilegedComponentsIds.EDIT_PAYMENTS}>
    <ButtonWithConfirmation
      id={`default-payment-${id}`}
      confirmationMessage={strings(
        "businessSettings.payment.changeDefaultPopupMessage",
      )}
      actionButton={
        <CardInfoText type="action">
          {strings("businessSettings.payment.makeDefault")}
        </CardInfoText>
      }
      onClick={onClick}
      submitButtonLabel={strings("buttons.confirm")}
    />
  </PrivilegedComponent>
);

const EditButtonActionIcon = (props: OptionalClickHandlerProps) => (
  <ConfirmationTooltipButtonContainer>
    <IconActionButton alignment="top">
      <PaymentListEditIcon {...props} />
    </IconActionButton>
  </ConfirmationTooltipButtonContainer>
);

const EditButton = ({
  id,
  onClick,
  confirm,
}: IdProps & ClickHandlerProps & OptionalConfirmationProps) => (
  <PrivilegedComponent id={PrivilegedComponentsIds.EDIT_PAYMENTS}>
    {confirm ? (
      <ButtonWithConfirmation
        id={`edit-payment-${id}`}
        confirmationMessage={strings(
          "businessSettings.payment.editPopupMessage",
        )}
        actionButton={<EditButtonActionIcon />}
        onClick={onClick}
        submitButtonLabel={strings("buttons.confirm")}
      />
    ) : (
      <EditButtonActionIcon onClick={onClick} />
    )}
  </PrivilegedComponent>
);

const DeleteButton = ({ id, onClick }: IdProps & ClickHandlerProps) => (
  <PrivilegedComponent id={PrivilegedComponentsIds.EDIT_PAYMENTS}>
    <ButtonWithConfirmation
      id={`delete-payment-${id}`}
      confirmationMessage={strings(
        "businessSettings.payment.deletePopupMessage",
      )}
      actionButton={
        <ConfirmationTooltipButtonContainer>
          <IconActionButton alignment="top">
            <PaymentListDeleteIcon />
          </IconActionButton>
        </ConfirmationTooltipButtonContainer>
      }
      onClick={onClick}
      submitButtonLabel={strings("buttons.confirm")}
    />
  </PrivilegedComponent>
);

interface PaymentMethodListItemProps {
  editable?: boolean;
  data: PaymentDataResponseDTO;
  onEditClicked: (method: PaymentDataResponseDTO) => void;
  onDeleteClicked: (method: PaymentDataResponseDTO) => void;
  onMakeDefaultClicked: (method: PaymentDataResponseDTO) => void;
}

const PaymentMethodListItem = ({
  data,
  editable,
  onEditClicked,
  onDeleteClicked,
  onMakeDefaultClicked,
}: PaymentMethodListItemProps) => {
  const {
    defaultPaymentMethod,
    cardExpired,
    cardExpireDate,
    maskedIban,
    truncatedCardPan,
    owner,
    paymentType,
    paymentId,
    paymentAddress,
  } = data;
  const handleDeleteClicked = () => onDeleteClicked(data);
  const handleDefaultClicked = () => onMakeDefaultClicked(data);
  const handleEditClicked = () => {
    onEditClicked(data);
  };
  const isEmptyMethod = !paymentId;
  const isAvailable = !isEmptyMethod && !cardExpired;

  return (
    <>
      <PaymentMethodListItemContainer>
        {paymentType === "CREDIT_CARD" && <PaymentListCardIcon />}
        {paymentType === "SEPA" && <PaymentListBankIcon />}

        <PaymentDetailsContainer expand>
          {paymentType === "SEPA" && (
            <CardInfoText>
              {maskedIban
                ? strings("businessSettings.payment.bankAccountLabel", {
                    iban: maskedIban,
                  })
                : strings("businessSettings.payment.bankAccountMissingLabel")}
            </CardInfoText>
          )}
          {paymentType === "CREDIT_CARD" && (
            <CardInfoText>
              {truncatedCardPan
                ? strings("businessSettings.payment.creditCardLabel", {
                    iban: truncatedCardPan,
                  })
                : strings("businessSettings.payment.creditCardMissingLabel")}
            </CardInfoText>
          )}
          {cardExpireDate && (
            <CardInfoText stacked>
              {strings("businessSettings.payment.expiryLabel", {
                expiry: cardExpireDate,
              })}
            </CardInfoText>
          )}
          {owner && <CardInfoText stacked>{owner}</CardInfoText>}
          {defaultPaymentMethod ? (
            <CardSection>
              <CardInfoText bold type="success">
                {strings("businessSettings.payment.defaultPaymentIndicator")}
              </CardInfoText>
            </CardSection>
          ) : (
            isAvailable && (
              <CardSection>
                <AlterDefaultButton
                  id={data.paymentId}
                  onClick={handleDefaultClicked}
                />
              </CardSection>
            )
          )}
        </PaymentDetailsContainer>

        {cardExpired && (
          <>
            <PaymentDetailsContainer>
              <CardInfoText bold type="info">
                {strings("businessSettings.payment.expiredIndicator")}
              </CardInfoText>
            </PaymentDetailsContainer>
            <ExpiredCardOverlay />
          </>
        )}

        {editable && (
          <EditButton
            id={data.paymentId}
            onClick={handleEditClicked}
            confirm={!isEmptyMethod && !cardExpired}
          />
        )}
        {!isEmptyMethod && !defaultPaymentMethod && (
          <DeleteButton id={data.paymentId} onClick={handleDeleteClicked} />
        )}
      </PaymentMethodListItemContainer>
      {paymentAddress && (
        <>
          <PaymentMethodListItemContainer>
            <CardSection>
              <PaymentListLocationIcon />
            </CardSection>
            <CardSection>
              <p>
                {paymentAddress.street}, {paymentAddress.houseNo}
              </p>
              <p>
                {paymentAddress.postalCode}, {paymentAddress.city}
              </p>
            </CardSection>
          </PaymentMethodListItemContainer>
          <PaymentMethodListItemContainer>
            <CardSection>
              <PaymentListEmailIcon />
            </CardSection>
            {paymentAddress && paymentAddress.email && (
              <CardSection>{paymentAddress.email}</CardSection>
            )}
          </PaymentMethodListItemContainer>
          <PaymentMethodListItemContainer>
            <CardSection>
              <PaymentListPhoneIcon />
            </CardSection>
            {paymentAddress && paymentAddress.phone && (
              <CardSection>{paymentAddress.phone}</CardSection>
            )}
          </PaymentMethodListItemContainer>
        </>
      )}
      <PaymentMethodListItemSeparator />
    </>
  );
};

const creditCardPlaceholderData = {
  paymentType: "CREDIT_CARD",
} as PaymentDataResponseDTO;
const sepaPlaceholderData = { paymentType: "SEPA" } as PaymentDataResponseDTO;

interface Props {
  editable?: boolean;
  paymentMethods: PaymentDataResponseDTO[];
  onEditPaymentRequested: (method: PaymentDataResponseDTO) => void;
  onPaymentMethodDeleted: (method: PaymentDataResponseDTO) => void;
  onPaymentMethodMadeDefault: (method: PaymentDataResponseDTO) => void;
}

const PaymentMethodsConfig = ({
  editable,
  paymentMethods,
  onEditPaymentRequested,
  onPaymentMethodDeleted,
  onPaymentMethodMadeDefault,
}: Props) => {
  const creditCardMethods = paymentMethods.filter(
    ({ paymentType }) => paymentType === "CREDIT_CARD",
  );
  const sepaMethods = paymentMethods.filter(
    ({ paymentType }) => paymentType === "SEPA",
  );

  return (
    <>
      <PaymentMethodListContainer>
        <PaymentMethodTypeTitle>
          {strings("businessSettings.paymentEdit.creditCardTitle")}
        </PaymentMethodTypeTitle>
        {creditCardMethods.length > 0 ? (
          creditCardMethods.map(cardMethod => (
            <PaymentMethodListItem
              key={cardMethod.paymentId}
              editable={editable}
              data={cardMethod}
              onEditClicked={onEditPaymentRequested}
              onDeleteClicked={onPaymentMethodDeleted}
              onMakeDefaultClicked={onPaymentMethodMadeDefault}
            />
          ))
        ) : (
          <PaymentMethodListItem
            data={creditCardPlaceholderData}
            editable={editable}
            onEditClicked={onEditPaymentRequested}
            onDeleteClicked={onPaymentMethodDeleted}
            onMakeDefaultClicked={onPaymentMethodMadeDefault}
          />
        )}

        <PaymentMethodTypeTitle>
          {strings("businessSettings.paymentEdit.bankAccountTitle")}
        </PaymentMethodTypeTitle>
        {sepaMethods.length > 0 ? (
          sepaMethods.map(sepaMethod => (
            <PaymentMethodListItem
              key={sepaMethod.paymentId}
              editable={editable}
              data={sepaMethod}
              onEditClicked={onEditPaymentRequested}
              onDeleteClicked={onPaymentMethodDeleted}
              onMakeDefaultClicked={onPaymentMethodMadeDefault}
            />
          ))
        ) : (
          <PaymentMethodListItem
            data={sepaPlaceholderData}
            editable={editable}
            onEditClicked={onEditPaymentRequested}
            onDeleteClicked={noop}
            onMakeDefaultClicked={noop}
          />
        )}
      </PaymentMethodListContainer>
    </>
  );
};

export default PaymentMethodsConfig;
