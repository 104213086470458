import TagInterface from "api/models/TagInterface";

const isSingleChildSelected = (
  checkedCategories: string[],
  parent?: TagInterface | "",
) => {
  if (parent && parent.tagSuccessors && parent.tagSuccessors.length > 0) {
    const parentChildren = parent.tagSuccessors.map(i => i.id);
    const selectedChildren = checkedCategories.filter(c =>
      parentChildren.includes(c),
    );
    return selectedChildren.length === 1;
  }

  return false;
};

export const removeFromList = (checkedCategories: string[], item: string) => {
  const itemIndex = checkedCategories.indexOf(item);
  if (itemIndex !== -1) checkedCategories.splice(itemIndex, 1);
};

export const findParent = (
  category: TagInterface,
  categories: TagInterface[],
) => {
  if (category.tagSuccessors) {
    return undefined;
  }

  return categories.find(
    parent =>
      !!(
        parent.tagSuccessors &&
        parent.tagSuccessors.find(child => child.id === category.id)
      ),
  );
};

export const toggleCategory = (
  category: TagInterface,
  categories: TagInterface[],
  checkedCategories: string[],
) => {
  const newCheckedCategories = checkedCategories.slice();
  const isCategoryChecked = newCheckedCategories.find(c => c === category.id);
  const parent = findParent(category, categories);
  const singleChildSelected = isSingleChildSelected(
    newCheckedCategories,
    parent,
  );

  // unCheck
  if (isCategoryChecked) {
    // clickedCategory
    removeFromList(newCheckedCategories, category.id);

    // Only has a single child - unCheck parent as well
    if (parent && singleChildSelected) {
      removeFromList(newCheckedCategories, parent.id);
    }

    // Parent category - unCheck every child
    if (!parent && category.tagSuccessors) {
      category.tagSuccessors.forEach(child => {
        removeFromList(newCheckedCategories, child.id);
      });
    }

    return newCheckedCategories;
  }

  // check - parent category - check all children as well
  if (!parent && category.tagSuccessors && category.tagSuccessors.length > 0) {
    const subTags = category.tagSuccessors
      .map(tag => tag.id)
      .filter(tag => !newCheckedCategories.includes(tag));

    if (!subTags.length) {
      // all sub tags are selected currently -> unselect everything
      category.tagSuccessors.forEach(child =>
        removeFromList(newCheckedCategories, child.id),
      );
      return [...newCheckedCategories];
    }
    return [...newCheckedCategories, ...subTags];
  }

  // check - parent category without children
  return [...newCheckedCategories, category.id];
};
