import styled from "style/styled-components";

const FieldMessage = styled.div`
  color: ${({ theme }) => theme.color.border.error};
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 11px;
  font-family: ${({ theme }) => theme.font.family.firaSans};
  margin-top: ${({ theme }) => theme.margin.small};
}
`;

export default FieldMessage;
