import {
  getConversationsHistory,
  getBusinessBranches,
  subscribeToConversationUpdates,
} from "api/chat";
import useQuery from "common/hooks/useQuery";
import usePagination from "common/hooks/usePagination";
import { curry } from "ramda";
import { useEffect, useCallback, useState } from "react";
import useCumulation from "hooks/useCumulation";
import { parseTotalItemCount } from "api/APIHelpers";
import useSocketSubscription from "components/chat/socket/useSocketSubscription";
import QueryStatus from "common/api/models/QueryStatus";
import { transformAPIConversation } from "components/chat/dataMappers";
import strings from "localisation/strings";
import chatState from "state/singletons/chatState";
import { makeCancelable } from "utils";
import { BusinessBranch } from "components/chat/types";
import ChatBusinessBranchesDTO from "api/models/ChatBusinessBranchesDTO";
import useLoginState from "hooks/useLoginState";

const noConversations: any[] = [];

const useConversationsList = (
  businessProfileId?: string,
  businessId?: string,
) => {
  const { hasAdminRights } = useLoginState();
  const isAdmin = hasAdminRights();
  const subscribeAndHandleConversationUpdates = useCallback(
    () =>
      subscribeToConversationUpdates(conversationUpdate => {
        chatState.setUnreadMessagesCount(conversationUpdate.totalUnreadCount);
        chatState.updateConversation(
          conversationUpdate.id,
          transformAPIConversation(conversationUpdate),
        );
      }),
    [chatState, transformAPIConversation, subscribeToConversationUpdates],
  );

  const socketSubscription = useSocketSubscription(
    subscribeAndHandleConversationUpdates,
  );

  const paginationParams = usePagination({
    defaultLimit: 25,
  });
  const request = useQuery({
    request: curry(getConversationsHistory)(businessId)(
      isAdmin,
      businessProfileId,
    )({
      limit: paginationParams.limit,
      offset: paginationParams.offset,
    }),
    compare: [
      paginationParams.offset,
      paginationParams.limit,
      businessProfileId,
      businessId,
    ],
  });

  const [historyStatus, setHistoryStatus] = useState(request.status);

  const conversations = useCumulation({
    items: request.result ? request.result.data : noConversations,
    compare: [businessProfileId, businessId],
  });

  useEffect(() => {
    setHistoryStatus(QueryStatus.UNDETERMINED);
    paginationParams.resetPagination();
  }, [
    businessProfileId,
    businessId,
    setHistoryStatus,
    paginationParams.resetPagination,
  ]);

  const isHistoryLoaded = historyStatus === QueryStatus.SUCCESSFUL;
  const isRequestSuccessful = request.status === QueryStatus.SUCCESSFUL;
  const isRequestLoading = request.status === QueryStatus.WAITING;
  const isRequestFailed = request.status === QueryStatus.ERROR;

  useEffect(() => {
    if (isRequestSuccessful) {
      const cancellableConversationsUpdate = makeCancelable(
        chatState.setConversations(conversations.map(transformAPIConversation)),
      );

      cancellableConversationsUpdate.promise
        .then(() => setHistoryStatus(QueryStatus.SUCCESSFUL))
        .catch(() => {
          // ignore
        });

      return cancellableConversationsUpdate.cancel;
    }
  }, [isRequestSuccessful, setHistoryStatus, conversations]);

  useEffect(() => {
    if (isRequestSuccessful) {
      // In case of update to QueryStatus.SUCCESSFUL, we skip it. It is set when chat state is updated.
      return;
    }
    if (isHistoryLoaded) {
      // Update this status only if history is not already initialised (initialisation can only happen once after first SUCCESS)
      return;
    }

    setHistoryStatus(request.status);
  }, [
    isRequestSuccessful,
    isHistoryLoaded,
    isRequestLoading,
    isRequestFailed,
    setHistoryStatus,
  ]);

  useEffect(() => {
    if (isHistoryLoaded) {
      socketSubscription.subscribe();
      return socketSubscription.unsubscribe;
    }
  }, [
    isHistoryLoaded,
    socketSubscription.subscribe,
    socketSubscription.unsubscribe,
  ]);

  const isMoreResultsAvailable =
    conversations.length < parseTotalItemCount(request.headers);

  const handleLoadMoreRequested = useCallback(() => {
    if (isMoreResultsAvailable) {
      paginationParams.loadNextPage();
    }
  }, [paginationParams.loadNextPage, isMoreResultsAvailable]);

  return {
    historyStatus,
    isLoaded: isHistoryLoaded,
    isLoading:
      historyStatus === QueryStatus.WAITING ||
      historyStatus === QueryStatus.UNDETERMINED,
    isLoadingMore: isHistoryLoaded && isRequestSuccessful,
    loadMoreConversations: handleLoadMoreRequested,
    next: paginationParams.loadNextPage,
    hasMore: isMoreResultsAvailable,
  };
};

const useBusinessBranches = (businessId?: string) => {
  const [branches, setBranches] = useState<BusinessBranch[]>([]);
  const { hasAdminRights } = useLoginState();
  const isAdmin = hasAdminRights();
  const request = useQuery<ChatBusinessBranchesDTO>({
    request: curry(getBusinessBranches)(businessId, isAdmin)({}),
    compare: [businessId],
  });

  const hasMultipleBranchesWithConversations =
    (request.result && request.result.data.length > 1) || false;
  const hasMultipleBranchesInTotal =
    (request.result && request.result.hasMultipleBranches) || false;

  useEffect(() => {
    if (request.result) {
      const allBranches = {
        businessProfileId: "",
        businessProfileName: strings("chat.allBranches"),
      };
      setBranches([allBranches, ...request.result.data]);
    }
  }, [request.result]);

  return {
    branches,
    hasMultipleBranches:
      hasMultipleBranchesWithConversations || hasMultipleBranchesInTotal,
  };
};

const useConversationsHistory = (
  businessProfileId?: string,
  businessId?: string,
) => {
  const conversations = useConversationsList(businessProfileId, businessId);
  const branches = useBusinessBranches(businessId);

  return {
    historyStatus: conversations.historyStatus,
    isLoaded: conversations.isLoaded,
    isLoading: conversations.isLoading,
    branches: branches.branches,
    hasMultipleBranches: branches.hasMultipleBranches,
    loadMoreConversations: conversations.loadMoreConversations,
    nextConversationsPage: conversations.next,
    hasMore: conversations.hasMore,
  };
};

export default useConversationsHistory;
