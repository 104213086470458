import React from "react";
import styled from "styled-components";
import ViewContainer from "components/generic/ViewContainer";
import strings from "localisation/strings";
import { CenteredBlockTitle } from "components/generic/BlockTitle";
import matomoDemoDashboardImage from "assets/matomoDemoDashboard.jpg";
import Container from "components/generic/Container";
import { VerticalLargeSpacer } from "components/generic";

const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL || "";

const ContentWrapper = styled.div`
  width: ${({ theme }) => theme.forms.max};
  align-self: center;
`;

const Logo = styled.img.attrs({
  src: matomoDemoDashboardImage,
})`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius.mediumSmall};
`;

const AdminAppStatistics = () => {
  return (
    <>
      <ViewContainer>
        <ContentWrapper>
          <CenteredBlockTitle>
            {strings("adminAppStatsScreen.title")}
          </CenteredBlockTitle>
          <Container justifyContent="center">
            {strings("adminAppStatsScreen.description")}
          </Container>
          <VerticalLargeSpacer />
          <Container justifyContent="center">
            <a href={analyticsUrl} title={strings("adminAppStatsScreen.link")}>
              <Logo />
            </a>
          </Container>
        </ContentWrapper>
      </ViewContainer>
    </>
  );
};

export default AdminAppStatistics;
