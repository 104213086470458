import React from "react";
import SimpleIconedInput from "components/forms/FormFields/SimpleIconedInput";
import mailIcon from "assets/icons/mail.svg";
import useFormFields from "hooks/useFormFields";
import SubmitButton from "components/forms/SubmitButton";
import { AssignCuratorPayload } from "api/models/RepresentativeInterface";
import strings from "localisation/strings";
import { addAdditionalBusiness } from "api/businessRepresentatives";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import styled from "styled-components";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";
import useLoginState from "hooks/useLoginState";
import { History } from "history";

interface AssignCuratorFormProps {
  businessId: string;
  history: History;
}

interface AssignCuratorButtonProps {
  style?: object;
  getPayload: () => AssignCuratorPayload | undefined;
  failedRequestCallback: (error: APIErrorResponse) => void;
  successfulRequestCallback: (response: object) => void;
  className?: string;
  disabled?: boolean;
}

const AssignCuratorButton = (props: AssignCuratorButtonProps) => (
  <SubmitButton<AssignCuratorPayload, object>
    request={addAdditionalBusiness}
    text={strings("businessRepresentativeList.addCuratorButton")}
    loadingText={strings("buttons.states.requestInProgress")}
    {...props}
  />
);

const StyledAssignCuratorButton = styled(AssignCuratorButton)`
  margin-top: 40px;
  align-self: flex-end;
`;

const AssignCuratorForm = ({ businessId, history }: AssignCuratorFormProps) => {
  const {
    values,
    getSingleInputFieldProps,
    parseErrors,
    getErrors,
  } = useFormFields({
    blockNavigation: true,
    handleGenericErrors: true,
    translationScope: "addCuratorForm.fields",
    textFields: {
      email: "",
    },
  });
  const { unblockNavigation } = useGlobalFormBlock();
  const { hasAdminRights } = useLoginState();
  const adminRights = hasAdminRights();

  const getPayload = (): AssignCuratorPayload | undefined => {
    return { email: values.email.trim(), businessId: businessId };
  };

  const onCuratorAssignFail = (body: APIErrorResponse) => parseErrors(body);

  const onSuccess = async () => {
    await unblockNavigation();
    history.push(
      adminRights
        ? `/admin/inhalt/partner/${businessId}/partnervertreter`
        : `/partnervertreter`,
    );
  };

  return (
    <>
      <SimpleIconedInput
        icon={mailIcon}
        inputWidth="100%"
        maxLength={255}
        errors={getErrors("email")}
        {...getSingleInputFieldProps("email")}
      />
      <StyledAssignCuratorButton
        getPayload={getPayload}
        failedRequestCallback={onCuratorAssignFail}
        successfulRequestCallback={onSuccess}
      />
    </>
  );
};

export default AssignCuratorForm;
