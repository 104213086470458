import React from "react";
import strings from "localisation/strings";
import useQuery from "common/hooks/useQuery";
import {
  getAdditionalSettingsAdmin,
  updateAdditionalSettingsAdmin,
} from "api/businessProfiles";
import useNotificationState from "hooks/useNotification";
import { curry } from "ramda";
import useMutation from "common/hooks/useMutation";
import BusinessSettingsAdminSidebar from "views/BusinessSettings/BusinessSettingsAdminSidebar";
import AdditionalSettingsForm from "components/forms/AdditionalSettingsForm";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import { isLoading } from "utils";
import { AdditionalSettingsPayload } from "api/models/Plan";

const BusinessSettingsAdditionalSettingsAdmin = ({
  businessId,
}: {
  businessId: string;
}) => {
  const additionalSettingsAdminRequest = curry(getAdditionalSettingsAdmin)(
    businessId,
  );
  const { result, status: fetchAdditionalSettingsStatus } = useQuery({
    request: additionalSettingsAdminRequest,
  });

  const {
    addErrorNotification,
    addSuccessNotification,
  } = useNotificationState();
  const updateBusinessAdditionalSettingsRequest = curry(
    updateAdditionalSettingsAdmin,
  );
  const { makeRequest, status: updateAdditionalSettingsStatus } = useMutation(
    updateBusinessAdditionalSettingsRequest,
  );

  const handleSave = async (values: AdditionalSettingsPayload) => {
    const response = (await makeRequest({ businessId, ...values })) || {};
    if (response && response.error) {
      addErrorNotification(response.error.detail);
    } else {
      addSuccessNotification(strings("generic.success"));
    }
  };

  return (
    <BusinessSettingsLayout
      isLoading={isLoading(fetchAdditionalSettingsStatus)}
      isUpdating={isLoading(updateAdditionalSettingsStatus)}
      title={strings("businessSettings.additional-settings.title")}
      sidebar={<BusinessSettingsAdminSidebar />}
    >
      <AdditionalSettingsForm
        settings={result}
        businessId={businessId}
        onSave={handleSave}
        showDistinctError
      />
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsAdditionalSettingsAdmin;
