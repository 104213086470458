import RequestOptions from "api/RequestOptions";
import AuthedAPI from "api/AuthedAPI";
import { RepresentativeInterface } from "api/models/RepresentativeInterface";
import {
  ChangePasswordPayload,
  ContactData,
  ContactDataPayload,
  PersonalData,
  UserInterest,
  UserInterestPayload,
  WizardUserDataPayload,
  UserLogDownloadInterface,
} from "api/models/UsersInterface";
import { MapCurationPayload } from "api/models/Plan";

const usersAPI = new AuthedAPI({});

const changePassword = (
  body: ChangePasswordPayload,
  options?: RequestOptions,
) =>
  usersAPI.postJson<ChangePasswordPayload>({
    body,
    options,
    path: "users/bp/user-data/change-password",
  });

const getWizardUserData = (options?: RequestOptions) =>
  usersAPI.getJson<WizardUserDataPayload>({
    options,
    path: "users/bp/user-data",
  });

const putUserData = (body: WizardUserDataPayload, options?: RequestOptions) =>
  usersAPI.putJson<WizardUserDataPayload>({
    body,
    options,
    path: "users/bp/user-data",
  });

const getBpPersonalData = (options?: RequestOptions) =>
  usersAPI.getJson<PersonalData>({ options, path: "users/bp/user-data" });

const putBpPersonalData = (
  body: Partial<RepresentativeInterface>,
  options?: RequestOptions,
) =>
  usersAPI.putJson<RepresentativeInterface>({
    options,
    body,
    path: "users/bp/user-data",
  });

const getBoPersonalData = (options?: RequestOptions) =>
  usersAPI.getJson<PersonalData>({ options, path: "users/bo/user-data" });

const putBoPersonalDataList = (body: any, options?: RequestOptions) =>
  usersAPI.putJson<{ users: PersonalData[] }>({
    options,
    body,
    path: "users/bo/user-data/user-list",
  });

const putBoPersonalData = (
  body: Partial<RepresentativeInterface>,
  options?: RequestOptions,
) =>
  usersAPI.putJson<RepresentativeInterface>({
    options,
    body,
    path: "users/bo/user-data",
  });

const getContactData = (options?: RequestOptions) =>
  usersAPI.getJson<ContactData>({ options, path: "users/bp/contactData" });

const putContactData = (options: RequestOptions, body?: ContactDataPayload) =>
  usersAPI.postJson<ContactData>({
    body,
    options: { ...options, method: "PUT" },
    path: "users/bp/contactData",
  });

const getUserInterests = (options?: RequestOptions) =>
  usersAPI.getList<UserInterest[]>({ options, path: "users/bp/interests" });

const putUserInterests = (
  body: UserInterestPayload,
  options?: RequestOptions,
) =>
  usersAPI.postJson<UserInterestPayload>({
    body,
    options: { ...options, method: "PUT" },
    path: "users/bp/interests",
  });

const startCreatingUserLog = (userId: string, options?: RequestOptions) =>
  usersAPI.postJson<UserLogDownloadInterface>({
    options,
    body: {},
    path: `users/bo/public-users/${userId}/download`,
  });

const deleteUserData = (userId: string, queryParams?: RequestOptions) =>
  usersAPI.deleteEntity<void>({
    queryParams,
    entityId: userId,
    path: "users/bo/public-users",
  });

const getCuratedMaps = (options?: RequestOptions) =>
  usersAPI.getJson<MapCurationPayload[]>({
    options,
    path: "users/bp/curator/user-data/maps",
  });

export {
  usersAPI,
  changePassword,
  getWizardUserData,
  putUserData,
  putBpPersonalData,
  getBpPersonalData,
  getContactData,
  putContactData,
  getUserInterests,
  putUserInterests,
  getBoPersonalData,
  putBoPersonalData,
  startCreatingUserLog,
  deleteUserData,
  putBoPersonalDataList,
  getCuratedMaps,
};
