import styled from "style/styled-components";

const CategoryTitle = styled.h4`
  color: ${({ theme }) => theme.color.font.onLightBackground};
  font-size: 11px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.margin.xsmall};
`;

export default CategoryTitle;
