import styled from "style/styled-components";
import ActionButtonLink from "components/buttons/ActionButtonLink";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import { Link } from "react-router-dom";

interface LinkIconProps {
  src: string;
  isAction?: boolean;
}

export const HeaderBody = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.foreground.secondary};
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  font-size: ${({ theme }) => theme.font.size.medium};
  height: ${({ theme }) => theme.globalComponents.headerHeight}px;
  align-items: center;
`;

export const MenuContainer = styled.div`
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.margin.large};
  background-color: ${({ theme }) => theme.color.background.primary};
`;

export const TopBarRegisterButton = styled(ActionButtonLink)<{
  onClick?: () => void;
}>`
  background-color: ${({ theme }) => theme.color.background.secondary};
  color: ${({ theme }) => theme.color.foreground.antiPrimary};
`;

export const TopBarLoginButton = styled(ActionButtonLink)<{
  onClick?: () => void;
}>`
  border-color: ${({ theme }) => theme.color.foreground.antiPrimary};
  color: ${({ theme }) => theme.color.foreground.antiPrimary};
  background-color: transparent;
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  margin-bottom: 20px;
`;

export { ExitIcon };

export const AntiPrimaryExitIcon = styled(ExitIcon)`
  fill: ${({ theme }) => theme.color.foreground.antiPrimary};
`;

export const Close = styled(CloseButton)`
  position: absolute;
  top: ${({ theme }) => theme.margin.large};
  right: ${({ theme }) => theme.margin.large};
  margin: 0;
`;

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};
`;

export const LoginFormContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.forms.small};
  margin: 0 auto;
`;

export const LinksList = styled.span`
  text-align: center;
`;

export const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.color.font.onLightBackground};
  text-decoration: none;
  text-align: left;
  line-height: 22px;
  vertical-align: middle;
  margin: 0 10px;
  padding: 10px 0;

  &:not(:nth-child(3)) {
    border-bottom: 1px solid ${({ theme }) => theme.color.foreground.quaternary};
  }
`;

export const Icon = styled.span<{ src?: string }>`
  background-image: ${({ src }) => (src ? `url("${src}")` : "none")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 22px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 8px;
`;

export const LinkIcon = styled.span<LinkIconProps>`
  display: inline-block;
  padding: 8px 0 8px 28px;
  cursor: pointer;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: 16px auto;
  background-position: left center;
  color: ${({ isAction, theme }) =>
    isAction ? theme.color.foreground.action : theme.color.foreground.primary};
`;

export const SettingsMenuLink = styled.span<LinkIconProps>`
  display: inline-block;
  padding: 8px 0 8px 8px;
  cursor: pointer;
  color: ${({ isAction, theme }) =>
    isAction ? theme.color.foreground.action : theme.color.foreground.primary};
`;
