import React, { useEffect, useState } from "react";
import { curry } from "ramda";
import { ExtendedRepresentativeInterface } from "api/models/RepresentativeInterface";
import SimpleIconedInput from "components/forms/FormFields/SimpleIconedInput";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import SelectComponent, {
  MultiOptionItemContainer,
  SelectItem,
} from "components/forms/Select";
import InputPhoneNumber from "components/forms/InputPhoneNumber";
import strings from "localisation/strings";
import userIcon from "assets/icons/user.svg";
import mailIcon from "assets/icons/mail.svg";
import briefcaseIcon from "common/assets/icons/briefcase-black.svg";
import phoneIcon from "assets/icons/contact.svg";
import mainTheme from "style/theme";
import ActionButtons from "components/forms/BusinessRepresentative/ActionButtons";
import useBusinessRepresentativeForm from "components/forms/BusinessRepresentative/useBusinessRepresentativeForm";
import RequestOptions from "api/RequestOptions";
import useFormFields from "hooks/useFormFields";
import OverlaySpinner from "components/generic/OverlaySpinner";
import useComponentDidMount from "hooks/useComponentDidMount";
import { BusinessFilterInterface } from "api/models/BusinessFilterInterface";
import MapsSelectList from "components/forms/FormFields/MapsSelectList";
import useLoginState from "hooks/useLoginState";
import RemoveButton from "components/buttons/RemoveButton";
import { Link } from "react-router-dom";
import theme from "style/theme";
import { Container } from "components/generic";
import CustomModal from "components/modal/CustomModal";
import useMutation from "common/hooks/useMutation";
import {
  getAllBusinessesIdsAssignedToRepresentative,
  removeAdditionalBusinessAdmin,
} from "api/businessRepresentatives";
import useNotification from "hooks/useNotification";

interface RepresentativeFormProps<T> {
  representative: Partial<T> | undefined;
  personalData?: boolean;
  isAdminView?: boolean;
  addNewRequest: (
    body: Partial<T>,
    options?: RequestOptions,
  ) => Promise<{ result: Partial<T>; headers: Headers }>;
  saveRequest: (
    body: Partial<T>,
    options?: RequestOptions,
  ) => Promise<{ result: Partial<T>; headers: Headers }>;
  mainBusinessId?: string;
}

const AddRepresentativeForm = ({
  representative,
  addNewRequest,
  saveRequest,
  mainBusinessId,
  isAdminView,
}: RepresentativeFormProps<ExtendedRepresentativeInterface>) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [additionalBusinessToRemove, setAdditionalBusinessToRemove] = useState<
    SelectItem
  >();
  const [allUsersBusinesses, setAllUsersBusinesses] = useState<
    BusinessFilterInterface[] | undefined
  >([]);
  const [refreshAllBusinessesList, setRefreshAllBusinessesList] = useState<
    number
  >(0);
  const { hasAdminRights } = useLoginState();
  const adminRights = hasAdminRights();
  const { addSuccessNotification } = useNotification();

  const {
    values,
    getSingleInputFieldProps,
    getSelectFieldProps,
    focusField,
    parseErrors,
    getErrors,
    updateValues,
    onChange,
  } = useFormFields({
    blockNavigation: true,
    handleGenericErrors: true,
    translationScope: "addRepresentativeForm.fields",
    textFields: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    selectFields: {
      role: "",
      phoneCode: "+49",
    },
    mapCurationFields: { mapCuration: [] },
  });
  const {
    addNewRepresentative,
    changeRepresentativeStatus,
    isSubmitting,
    representativeData,
    saveRepresentative,
    roles,
  } = useBusinessRepresentativeForm<ExtendedRepresentativeInterface>({
    representative,
    values,
    updateValues,
    parseErrors,
    saveRequest,
    addNewRequest,
    businessId: mainBusinessId,
    isAdminView,
  });

  useComponentDidMount(() => {
    focusField("email");
  });

  const getAllBusinessesIdsAssignedToRepresentativeRequest = curry(
    getAllBusinessesIdsAssignedToRepresentative,
  );
  const { makeRequest } = useMutation(
    getAllBusinessesIdsAssignedToRepresentativeRequest,
  );

  useEffect(() => {
    if (adminRights && representative?.userId) {
      makeRequest(representative.userId).then(response => {
        setAllUsersBusinesses(response?.result?.data);
      });
    }
  }, [representative?.userId, refreshAllBusinessesList]);

  const removeAdditionalBusinessRequest = curry(removeAdditionalBusinessAdmin);
  const { makeRequest: makeRemoveAdditionalBusinessRequest } = useMutation(
    removeAdditionalBusinessRequest(representative?.userId || ""),
  );

  const onAdditionalBusinessRemove = (option: SelectItem) => {
    if (option) {
      setModalVisible(true);
      setAdditionalBusinessToRemove(option);
    }
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const onModalConfirm = () => {
    setModalVisible(false);
    if (additionalBusinessToRemove?.value) {
      makeRemoveAdditionalBusinessRequest(
        additionalBusinessToRemove.value.toString(),
      ).then(result => {
        if (result) {
          addSuccessNotification(
            strings("addRepresentativeForm.successAdditionalBusinessRemove", {
              businessName: additionalBusinessToRemove?.label,
            }),
          );
        }
        setRefreshAllBusinessesList(refreshAllBusinessesList + 1);
      });
    }
  };

  const sortAllBusinesses = (allBusinesses: BusinessFilterInterface[]) => {
    let sortedBusinesses = [...allBusinesses];

    sortedBusinesses = sortedBusinesses.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    const mainBusinessIndex = allBusinesses.findIndex(
      business => business.businessId === representative?.businessId,
    );
    if (mainBusinessIndex !== -1) {
      const mainBusiness = sortedBusinesses[mainBusinessIndex];
      sortedBusinesses.splice(mainBusinessIndex, 1);
      sortedBusinesses.unshift(mainBusiness);
    }

    return sortedBusinesses;
  };

  if (!roles) return <OverlaySpinner />;

  return (
    <>
      <SimpleIconedInput
        icon={userIcon}
        inputWidth="100%"
        maxLength={50}
        errors={getErrors("firstName")}
        {...getSingleInputFieldProps("firstName")}
      />

      <SimpleIconedInput
        icon={userIcon}
        inputWidth="100%"
        maxLength={50}
        errors={getErrors("lastName")}
        {...getSingleInputFieldProps("lastName")}
      />

      <SimpleIconedInput
        icon={mailIcon}
        inputWidth="100%"
        maxLength={255}
        disabled={!!representativeData}
        className={representativeData ? "disabled" : ""}
        errors={getErrors("email")}
        {...getSingleInputFieldProps("email")}
      />

      <InlineInputGroup
        icon={phoneIcon}
        placeholder={strings("addRepresentativeForm.fields.phone.label")}
        width={mainTheme.forms.leftSide}
      >
        <InputPhoneNumber
          getSelectProps={curry(getSelectFieldProps)}
          phone={getSingleInputFieldProps("phone")}
          phoneCodeErrors={getErrors("phoneCode")}
          phoneErrors={getErrors("phone")}
        />
      </InlineInputGroup>

      <InlineInputGroup
        icon={userIcon}
        placeholder={strings("addRepresentativeForm.fields.role.label")}
        width={mainTheme.forms.leftSide}
        className={
          representativeData && representativeData.currentLoggedIn
            ? "disabled"
            : ""
        }
      >
        <SelectComponent
          {...getSelectFieldProps("role", roles)}
          classNamePrefix="businessRoleSelect"
          placeholder={strings("businessRepresentativeRoles.default")}
          disabled={representativeData && representativeData.currentLoggedIn}
          errors={getErrors("role")}
          small
        />
      </InlineInputGroup>
      {adminRights && values.role === "BUS_CURATOR" && allUsersBusinesses && (
        <InlineInputGroup
          icon={briefcaseIcon}
          placeholder={strings(
            "addRepresentativeForm.fields.assignedBusinesses.label",
          )}
          width={mainTheme.forms.leftSide}
          wrapText
        >
          <SelectComponent
            onChange={() => {}}
            options={sortAllBusinesses(allUsersBusinesses).map(business => ({
              value: business.businessId,
              label: business.name,
            }))}
            classNamePrefix="additionalBusinessesList"
            small
            placeholder={strings(
              "addRepresentativeForm.fields.assignedBusinesses.placeholder",
            )}
            customSelectOption={(option: SelectItem) => (
              <MultiOptionItemContainer>
                <Container justifyContent="space-between">
                  <Link
                    to={`/admin/inhalt/partner/${option.value}/partnerprofil`}
                    style={{
                      color: theme.color.font.onLightBackground,
                    }}
                  >
                    {option.label}
                  </Link>
                  {option.value !== representative?.businessId && ( //only additional bussiness can be removed
                    <RemoveButton
                      onClick={() => onAdditionalBusinessRemove(option)}
                    />
                  )}
                </Container>
              </MultiOptionItemContainer>
            )}
            isSearchable
          />
        </InlineInputGroup>
      )}
      {adminRights &&
        values.role === "BUS_CURATOR" &&
        representativeData?.mapCuration && (
          <InlineInputGroup
            icon={briefcaseIcon}
            placeholder={strings(
              "addRepresentativeForm.fields.assignedMaps.label",
            )}
            wrapText
          >
            <MapsSelectList
              values={representativeData.mapCuration}
              fieldName="mapCuration"
              width="100%"
              onChange={onChange}
            />
          </InlineInputGroup>
        )}
      <CustomModal
        popupMessage={strings(
          "addRepresentativeForm.removeAdditionalBusinessPopup",
          {
            businessName: additionalBusinessToRemove?.label,
          },
        )}
        modalVisible={modalVisible}
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
      />
      <ActionButtons
        isSubmitting={isSubmitting}
        isEditMode={!!representativeData}
        status={representativeData && representativeData.status}
        addNewCallback={addNewRepresentative}
        saveEditCallback={saveRepresentative}
        changeStatusCallback={changeRepresentativeStatus}
        currentLoggedIn={
          representativeData && representativeData.currentLoggedIn
        }
      />
    </>
  );
};

export default AddRepresentativeForm;
