import {
  RepresentativeInterface,
  UserStatuses,
} from "api/models/RepresentativeInterface";
import { BusinessRepresentativeRole } from "api/models/BusinessRepresentativeRoles";
import strings from "localisation/strings";

export const getActivationButtonText = (status?: UserStatuses) => {
  if (status && status === UserStatuses.DEACTIVATED) {
    return strings("addRepresentativeForm.fields.activate");
  }
  return strings("addRepresentativeForm.fields.deactivate");
};

export const setNewStatus = (fields: Partial<RepresentativeInterface>) => {
  // Change Business Representative status based on current status
  switch (fields.status) {
    case UserStatuses.ACTIVE:
      return (fields.status = UserStatuses.DEACTIVATED);
    case UserStatuses.DEACTIVATED:
      return (fields.status = UserStatuses.ACTIVE);
    default:
      return fields.status;
  }
};

export const getActivationConfirmationText = (status: UserStatuses) => {
  if (status && status === UserStatuses.DEACTIVATED) {
    return strings("popupMessages.activateUser");
  }
  return strings("popupMessages.deactivateUser");
};
export const addRolesLabels = (roles: string[]) => {
  const labeledRoles: BusinessRepresentativeRole[] = [];
  roles.map((role: string) =>
    labeledRoles.push({
      value: role,
      label: strings(`businessRepresentativeRoles.${role.toLowerCase()}`),
    }),
  );
  return labeledRoles;
};
