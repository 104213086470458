import React from "react";
import moment from "moment";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import CopyButton from "components/buttons/CopyButton";

import { HorizontalSmallSpacer } from "components/generic";

import Checkbox from "components/forms/Checkbox";
import { Time } from "components/forms/FormFields/DateTime";
import FieldErrors from "components/forms/FieldErrors";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";

interface DayOfTheWeekProps {
  fieldErrors?: string[];
  dayOfTheWeek: string;
  enabled: boolean;
  start?: string;
  end?: string;
  toggleBusinessHoursWeekdayEnabled: (value: any) => void;
  changeBusinessHoursWeekdayStart: (value: any) => void;
  changeBusinessHoursWeekdayEnd: (value: any) => void;
  cloneDate: () => void;
}

const TimeFields = styled.span`
  display: flex;
  width: 50%;

  @media ${breakpoints.phoneMax} {
    width: 100%;
  }
`;

const CheckboxContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: center;
  padding: ${({ theme }) => theme.margin.small} 0 0
    ${({ theme }) => theme.margin.medium};
`;

const createDateFromString = (dateString?: string | null): Date | null => {
  if (dateString) {
    const [time] = dateString.split("+");
    const [hours, minutes, seconds] = time.split(":");
    // TODO: This is a quick fix. Unifying and refactoring needed
    return moment(new Date())
      .add(1, "day")
      .hours(Number(hours))
      .minutes(Number(minutes))
      .seconds(Number(seconds))
      .toDate();
  }

  return null;
};

const formatTime = (date?: string) => moment(date).format("HH:mm:ssZ");

const CopyToAll = styled(CopyButton)`
  width: 40px;
  margin-top: -6px;
`;

const DayOfTheWeekBlock = ({
  fieldErrors,
  dayOfTheWeek,
  enabled,
  start,
  end,
  toggleBusinessHoursWeekdayEnabled,
  changeBusinessHoursWeekdayStart,
  changeBusinessHoursWeekdayEnd,
  cloneDate,
}: DayOfTheWeekProps) => (
  <>
    <InlineInputGroup
      offsetContent={
        <CheckboxContainer>
          <Checkbox
            name="enabled"
            checked={enabled}
            onChange={toggleBusinessHoursWeekdayEnabled}
          />
        </CheckboxContainer>
      }
      placeholder={dayOfTheWeek}
    >
      <TimeFields>
        <Time
          value={start}
          setValue={value => changeBusinessHoursWeekdayStart(formatTime(value))}
          getDateFromValue={createDateFromString}
          isDisabled={!enabled}
        />
        <HorizontalSmallSpacer />
        <Time
          value={end}
          setValue={value => changeBusinessHoursWeekdayEnd(formatTime(value))}
          getDateFromValue={createDateFromString}
          isDisabled={!enabled}
        />
      </TimeFields>
      <CopyToAll onClick={cloneDate} tooltipKey="buttons.copyToAll" />
    </InlineInputGroup>
    <FieldErrors errors={fieldErrors} />
  </>
);

export default DayOfTheWeekBlock;
