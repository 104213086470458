import ImageUploadType from "api/models/ImageUploadType";
import { AdditionalSettingsPayload } from "api/models/Plan";
import gallerieIcon from "common/assets/icons/gallerie.svg";
import ActionButton from "components/buttons/ActionButton";
import Checkbox from "components/forms/Checkbox";
import { AutoDragAndDrop } from "components/forms/FormFields";
import Icon from "components/forms/FormFields/Icon";
import InlineInputGroup, {
  Label,
} from "components/forms/FormFields/InlineInputGroup";
import ActionArea from "components/generic/ActionArea";
import useFormFields from "hooks/useFormFields";
import strings from "localisation/strings";
import React, { useEffect, useRef, useState } from "react";
import breakpoints from "style/breakpoints";
import styled from "styled-components";
import useQuery from "common/hooks/useQuery";
import { getActiveMapsList } from "api/resources";
import MapInterface from "api/models/MapInterface";
import MapsSelectList from "components/forms/FormFields/MapsSelectList";

const StyledIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.margin.medium};
`;

const FormContainer = styled.div`
  width: ${({ theme }) => theme.forms.xxlarge};
  max-width: ${({ theme }) => theme.forms.xxlarge};
  @media ${breakpoints.phoneMax} {
    width: 100%;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme }) => theme.margin.medium};
`;

const OffsetContainer = styled.div`
  height: ${({ theme }) => theme.formElements.text.height}px;
  line-height: ${({ theme }) => theme.formElements.text.height}px;

  > span {
    vertical-align: middle;
  }
`;

const FormLabel = styled(Label)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

const MapSelectionLabel = styled(Label)`
  margin-right: ${({ theme }) => theme.margin.large};
`;

interface PlanSelectionProps {
  settings?: AdditionalSettingsPayload;
  onSave: (values: AdditionalSettingsPayload) => void;
  showDistinctError?: boolean;
  businessId: string;
}

const AdditionalSettingsForm = ({
  settings: {
    imageUrl,
    enableProminentShopping,
    isTreuweltPartner,
    isKeeplocalPartner,
    mapAllowances,
  } = {
    imageUrl: "",
    enableProminentShopping: false,
    isTreuweltPartner: false,
    isKeeplocalPartner: false,
    mapAllowances: [],
  },
  onSave,
  showDistinctError,
  businessId,
}: PlanSelectionProps) => {
  const {
    getSmartServiceLogoPictureFieldProps,
    updateValues,
    getCheckboxFieldProps,
    onChange,
    values,
  } = useFormFields({
    translationScope: "businessSettings.additional-settings.fields",
    imageFields: {
      imageUrl,
    },
    checkboxFields: {
      enableProminentShopping: false,
      isTreuweltPartner: false,
      isKeeplocalPartner: false,
    },
    mapAllowanceFields: {
      mapAllowances: [],
    },
  });

  const updateValuesRef = useRef(updateValues);

  useEffect(() => {
    if (
      imageUrl ||
      enableProminentShopping ||
      isTreuweltPartner ||
      isKeeplocalPartner ||
      mapAllowances
    ) {
      const updatedFormFields = {
        imageFields: { imageUrl },
        checkboxFields: {
          enableProminentShopping,
          isTreuweltPartner,
          isKeeplocalPartner,
          mapAllowances,
        },
      };
      updateValuesRef.current(updatedFormFields);
    }
  }, [imageUrl, enableProminentShopping]);

  const [slideErrors, setSlideErrors] = useState<string[]>([]);

  return (
    <FormContainer>
      <FlexRow>
        <OffsetContainer>
          <StyledIcon backgroundImage={gallerieIcon} />
        </OffsetContainer>
        <FormLabel>
          {strings(
            "businessSettings.additional-settings.fields.imageField.label",
          )}
        </FormLabel>
        <AutoDragAndDrop
          {...getSmartServiceLogoPictureFieldProps("imageUrl")}
          placeholder={strings(`dragAndDrop.dropHere`)}
          container={ImageUploadType.POSTS}
          height="350px"
          width="500px"
          showHelpText={false}
          slideErrors={slideErrors}
          setSlideErrors={setSlideErrors}
          fixedRatio={true}
          showDistinctError={showDistinctError}
          withCropAndZoom={false}
        />
      </FlexRow>
      <InlineInputGroup width={""}>
        <MapSelectionLabel>
          {strings(
            "businessSettings.additional-settings.fields.mapSelection.label",
          )}
        </MapSelectionLabel>
        <MapsSelectList
          entityId={businessId}
          values={values.mapAllowances}
          fieldName="mapAllowances"
          onChange={onChange}
        />
      </InlineInputGroup>
      <FlexRow>
        <Checkbox {...getCheckboxFieldProps("enableProminentShopping")} />
      </FlexRow>
      <FlexRow>
        <Checkbox {...getCheckboxFieldProps("isTreuweltPartner")} />
      </FlexRow>
      <FlexRow>
        <Checkbox {...getCheckboxFieldProps("isKeeplocalPartner")} />
      </FlexRow>
      <ActionArea>
        <ActionButton
          text={strings("buttons.save")}
          special="publish"
          onClick={() =>
            onSave({
              imageUrl: values.imageUrl!,
              enableProminentShopping: values.enableProminentShopping,
              isTreuweltPartner: values.isTreuweltPartner,
              isKeeplocalPartner: values.isKeeplocalPartner,
              mapAllowances: values.mapAllowances,
            })
          }
          loadingText={strings("buttons.states.savingInProgress")}
        />
      </ActionArea>
    </FormContainer>
  );
};

export default AdditionalSettingsForm;
