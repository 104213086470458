import React from "react";
import PriceMinMax from "api/models/PriceMinMax";
import formatPriceMinMax from "components/helpers/formatPriceMinMax";

interface FormattedPriceProps {
  price?: PriceMinMax;
  unit?: string;
  className?: string;
}

const FormattedPrice = ({ price, unit, className }: FormattedPriceProps) => {
  const formattedPrice = formatPriceMinMax(price, unit);

  return !formattedPrice ? null : (
    <span className={className}>{formattedPrice}</span>
  );
};

export default FormattedPrice;
