import React from "react";
import { NavLink } from "react-router-dom";
import strings from "localisation/strings";
import styled from "style/styled-components";
import useLoginState from "hooks/useLoginState";

const BusinessNameLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.font.onDarkBackground};
  white-space: nowrap;
  text-decoration: none;
  margin: ${({ theme }) => theme.margin.xlarge};

  &:hover {
    font-weight: bold;
  }
`;

const BusinessNameLinkResponsive = styled(BusinessNameLink)`
  margin: ${({ theme }) => theme.margin.sideMin};
`;

const BusinessLink = ({
  onClick,
  responsive,
}: {
  onClick?: () => void;
  responsive?: boolean;
}) => {
  const {
    getDefaultBusinessId,
    getDefaultBusinessName,
    hasCuratorRights,
  } = useLoginState();
  const defBusinessId = getDefaultBusinessId();

  if (!defBusinessId) return null;

  const isCurator = hasCuratorRights();

  const defBusinessName = getDefaultBusinessName();
  const link = isCurator
    ? `/inhalt/partner/${defBusinessId}/partnerprofil`
    : `/admin/inhalt/partner/${defBusinessId}/partnerprofil`;
  const label = defBusinessName
    ? defBusinessName
    : strings("tabBar.DefaultCompany");

  return responsive ? (
    <BusinessNameLinkResponsive
      to={link}
      onClick={onClick}
      data-role="business-name-link"
    >
      {label}
    </BusinessNameLinkResponsive>
  ) : (
    <BusinessNameLink
      to={link}
      onClick={onClick}
      data-role="business-name-link"
    >
      {label}
    </BusinessNameLink>
  );
};

export default BusinessLink;
