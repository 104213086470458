import React from "react";

import { ChangePasswordPayload } from "api/models/UsersInterface";
import APIErrorResponse from "common/api/models/APIErrorResponse";

import useFormFields from "hooks/useFormFields";
import { FieldAligner } from "components/forms/FieldContainer";
import useComponentDidMount from "hooks/useComponentDidMount";
import strings from "localisation/strings";
import SubmitButton from "components/forms/SubmitButton";
import { changePassword } from "api/users";
import useNotification from "hooks/useNotification";
import styled from "style/styled-components";
import getPasswordStrength, {
  PasswordStrengths,
} from "components/forms/RegistrationForm/getPasswordStrength";
import PasswordStrength from "components/forms/RegistrationForm/PasswordStrength";
import { PasswordToggleField } from "components/forms/LoginForm/LoginForm";

interface ChangePasswordSubmitButtonProps {
  getPayload: () => ChangePasswordPayload | undefined;
  failedRequestCallback: (error: APIErrorResponse) => void;
  successfulRequestCallback: (response: object) => void;
  disabled?: boolean;
}

const ChangePasswordSubmitButton = (props: ChangePasswordSubmitButtonProps) => (
  <SubmitButton<ChangePasswordPayload, object>
    request={changePassword}
    text={strings("buttons.confirm")}
    loadingText={strings("buttons.states.requestInProgress")}
    {...props}
  />
);

const StyledChangePasswordSubmitButton = styled(ChangePasswordSubmitButton)`
  margin-bottom: ${({ theme }) => theme.margin.large};
  align-self: flex-end;
`;

const AccountChangePasswordForm = () => {
  const { addSuccessNotification } = useNotification();
  const { values, getTextFieldProps, focusField, parseErrors } = useFormFields({
    handleGenericErrors: true,
    translationScope: "accountChangePasswordScreen.fields",
    textFields: {
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  useComponentDidMount(() => {
    focusField("currentPassword");
  });

  const getPayload = (): ChangePasswordPayload | undefined => values;

  const onPasswordChangeFailed = (body: APIErrorResponse) => parseErrors(body);

  const preventSubmit = (data: any): boolean =>
    !data.currentPassword.length ||
    !data.password.length ||
    !data.passwordConfirmation.length ||
    getPasswordStrength(data.password) === PasswordStrengths.INVALID;

  return (
    <>
      <PasswordToggleField {...getTextFieldProps("currentPassword")} />
      <PasswordToggleField {...getTextFieldProps("password")}>
        <FieldAligner>
          <PasswordStrength password={values.password} />
        </FieldAligner>
      </PasswordToggleField>
      <PasswordToggleField {...getTextFieldProps("passwordConfirmation")} />

      <StyledChangePasswordSubmitButton
        getPayload={getPayload}
        successfulRequestCallback={() =>
          addSuccessNotification(strings("accountChangePasswordScreen.success"))
        }
        failedRequestCallback={onPasswordChangeFailed}
        disabled={preventSubmit(values)}
      />
    </>
  );
};

export default AccountChangePasswordForm;
