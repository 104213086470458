import styled from "style/styled-components";

const TableViewWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto 120px;
  padding: 10px;
  min-height: 300px; // needed for status dropdown, otherwise it gets cut out.
  width: 100%;
`;

export default TableViewWrapper;
