import styled from "style/styled-components";

/**
 * This container defines the background
 * for the elements listed at the bottom of the page
 */
const BottomElementsContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.background.senary};
`;

export default BottomElementsContainer;
