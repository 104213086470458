import AuthedAPI from "api/AuthedAPI";
import RequestOptions from "api/RequestOptions";
import EmojiInterface from "api/models/EmojiInterface";

export const imageStorageAPI = new AuthedAPI({
  baseUrl: process.env.REACT_APP_IMAGESTORAGE_URL,
  isWithoutHeader: true,
});

const getEmojiData = (options?: RequestOptions) =>
  imageStorageAPI.getJson<EmojiInterface[]>({
    options,
    queryParams: {},
    path: "emoji/icon_options.json",
  });

export { getEmojiData };
