import { useEffect, useCallback, useState } from "react";
import { curry } from "ramda";
import {
  getConversationMessages,
  subscribeToChatMessageUpdates,
} from "api/chat";
import usePagination from "common/hooks/usePagination";
import { parseTotalItemCount } from "api/APIHelpers";
import useSocketSubscription from "components/chat/socket/useSocketSubscription";
import QueryStatus from "common/api/models/QueryStatus";
import {
  transformAPIChatMessage,
  transformAPIChatMessageUpdate,
} from "components/chat/dataMappers";
import chatState from "state/singletons/chatState";
import ChatSocketMessageDTO from "api/models/ChatSocketMessageDTO";
import useQuery from "common/hooks/useQuery";
import { makeCancelable } from "utils";
import { QuickReplyType, Message } from "components/chat/types";
import ChatMessageDTO from "api/models/ChatMessageDTO";
import useLoginState from "hooks/useLoginState";

const appendOrUpdateOptimisticallySentMessages = (
  conversationId: string,
  message: ChatSocketMessageDTO,
) => {
  const receivedMessageId = message.referenceId || message.id;
  const messages =
    chatState.state.messagesByConversationId[conversationId] || [];
  const optimisticallySentMessage = messages.find(
    ({ _id }) => _id === receivedMessageId,
  );

  if (!optimisticallySentMessage) {
    chatState.appendMessages(conversationId, [
      transformAPIChatMessage(message),
    ]);
    chatState.pushConversationToTop(conversationId);
    return;
  }

  const {
    quickReplies: [firstQuickReply] = [],
    quickReplyType,
  } = optimisticallySentMessage;
  chatState
    .updateMessage(
      conversationId,
      receivedMessageId,
      transformAPIChatMessageUpdate(message),
    )
    .then(() => {
      chatState.updateRefreshKey();
    });

  if (!firstQuickReply || quickReplyType !== QuickReplyType.TEXT) {
    return;
  }

  const matchTextWithQuickReply = ({ text }: Message) =>
    text === firstQuickReply.value.reply;
  const quickReplyConfirmationMessage = messages.find(matchTextWithQuickReply);

  if (!quickReplyConfirmationMessage) {
    return;
  }

  chatState.updateMessage(conversationId, quickReplyConfirmationMessage._id, {
    isSent: true,
  });
};

export const useLiveMessageHistory = (conversationId: string) => {
  const subscribeAndHandleChatMessages = useCallback(() => {
    const unsubscribe = subscribeToChatMessageUpdates(
      conversationId,
      curry(appendOrUpdateOptimisticallySentMessages)(conversationId),
    );

    return unsubscribe;
  }, [conversationId, chatState]);

  const messagesSocketSubscription = useSocketSubscription(
    subscribeAndHandleChatMessages,
  );
  return messagesSocketSubscription;
};

const noMessages: ChatMessageDTO[] = [];

export const useMessagesList = (
  conversationId: string,
  businessId?: string,
) => {
  const { offset, limit, loadNextPage, resetPagination } = usePagination({
    defaultLimit: 20,
  });
  const { hasAdminRights } = useLoginState();
  const isAdmin = hasAdminRights();
  const { status, headers, result } = useQuery({
    request: curry(getConversationMessages)(businessId, isAdmin)(
      conversationId,
    )({
      limit,
      offset,
    }),
    compare: [conversationId, offset, limit],
  });
  const [historyStatus, setHistoryStatus] = useState(status);

  useEffect(() => {
    return () => {
      chatState.resetConversationMessages(conversationId);
      resetPagination();
      setHistoryStatus(QueryStatus.UNDETERMINED);
    };
  }, [conversationId]);

  const responseMessages = result ? result.data : noMessages;
  useEffect(() => {
    const isInitialUseCumulationRerender = status === QueryStatus.SUCCESSFUL;

    if (isInitialUseCumulationRerender) {
      const cancellableMessagesUpdate = makeCancelable(
        chatState.prependMessages(
          conversationId,
          responseMessages.map(transformAPIChatMessage).reverse(),
        ),
      );

      cancellableMessagesUpdate.promise
        .then(() => setHistoryStatus(QueryStatus.SUCCESSFUL))
        .catch(() => {
          // ignore
        });

      return cancellableMessagesUpdate.cancel;
    }
  }, [conversationId, responseMessages, chatState, transformAPIChatMessage]);

  useEffect(() => {
    if (status === QueryStatus.SUCCESSFUL) {
      // In case of update to QueryStatus.SUCCESSFUL, we skip it. It is set when chat state is updated.
      return;
    }
    if (historyStatus === QueryStatus.SUCCESSFUL) {
      // Update this status only if history is not already initialised (initialisation can only happen once after first SUCCESS)
      return;
    }

    setHistoryStatus(status);
  }, [status, setHistoryStatus]);

  return {
    historyStatus,
    loadMoreMessages: loadNextPage,
    isMoreMessagesLoading:
      historyStatus === QueryStatus.SUCCESSFUL &&
      status === QueryStatus.WAITING,
    totalMessagesCount: parseTotalItemCount(headers),
  };
};
