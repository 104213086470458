import React, { createRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "style/styled-components";
import { Subscribe } from "unstated";
import LocaleState from "state/LocaleState";

interface RecaptchaWidgetProps {
  onChange: (token: string | null) => void;
  sitekey: string;
  timestamp: Date;
}

const RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;

  /* break out of parent for 320px width screens */
  margin: ${({ theme }) => `20px -${theme.margin.medium} 10px`};
`;

const RecaptchaWidget = (props: RecaptchaWidgetProps) => {
  const recaptchaRef = createRef<ReCAPTCHA>();

  useEffect(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, [props.timestamp]);

  return (
    <Subscribe to={[LocaleState]}>
      {(localeState: LocaleState) => {
        const locale = localeState.getLocale();
        return (
          <RecaptchaWrapper>
            <ReCAPTCHA
              key={`ReCAPTCHA-${locale}`}
              hl={locale}
              {...props}
              ref={recaptchaRef}
            />
          </RecaptchaWrapper>
        );
      }}
    </Subscribe>
  );
};

export default RecaptchaWidget;
