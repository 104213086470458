import React from "react";

import {
  ContentContainer,
  TextScreenContainer,
} from "views/TextScreens/components";
import { MarginlessViewContainer } from "components/generic/ViewContainer";
import LoggedIn from "components/generic/LoggedIn";
import MarketingPurposesForLoggedInUser from "components/forms/MarketingPurposesForm/MarketingPurposesForLoggedInUser";
import GenericRichTextContent from "components/generic/richText/GenericRichTextContent";

import useLoginState from "hooks/useLoginState";

const MarketingPurposesScreen = () => {
  const { hasAdminRights } = useLoginState();
  // internal admins do not need marketing opt it option
  const allowMarketingOptIn = !hasAdminRights();
  return (
    <MarginlessViewContainer>
      <TextScreenContainer>
        <ContentContainer>
          <GenericRichTextContent contentKey="bp-marketing-purposes" />
          <LoggedIn>
            {allowMarketingOptIn && <MarketingPurposesForLoggedInUser />}
          </LoggedIn>
        </ContentContainer>
      </TextScreenContainer>
    </MarginlessViewContainer>
  );
};

export default MarketingPurposesScreen;
