import React from "react";
import { curry } from "ramda";
import strings from "localisation/strings";
import BusinessSettingsLayout from "views/BusinessSettings/BusinessSettingsLayout";
import PlanSelection from "views/Wizard/PlanSelection";
import {
  getAvailablePlansAdmin,
  getBusinessPlanAdmin,
} from "api/businessProfiles";
import Plan from "api/models/Plan";
import useLocale from "common/hooks/useLocale";
import useQuery from "common/hooks/useQuery";
import QueryStatus from "common/api/models/QueryStatus";
import { RouteComponentProps, generatePath } from "react-router";
import { withBackButton } from "components/generic/ActionArea";
import { routeNames } from "core/navigation";
import useSelectedBusinessId from "hooks/useSelectedBusinessId";
import BusinessSettingsAdminSidebar from "views/BusinessSettings/BusinessSettingsAdminSidebar";
import useCurrentBusinessPlan from "views/BusinessSettings/PlanSelection/hooks/useCurrentBusinessPlan";

const PlanChangeAdminActionArea = withBackButton(() => null);

const BusinessSettingsPlanChangeAdmin = ({ history }: RouteComponentProps) => {
  const locale = useLocale();
  const businessId = useSelectedBusinessId() as string;

  const { result: plans, status: fetchAvailablePlansStatus } = useQuery({
    request: curry(getAvailablePlansAdmin)(businessId),
    compare: [locale],
  });
  const { loading: currentPlanLoading, currentPlan } = useCurrentBusinessPlan({
    businessId,
    request: curry(getBusinessPlanAdmin)(businessId),
  });

  const navigateToPlanSettings = () => {
    const planAdminSettingsPath = generatePath(
      routeNames.BusinessSettings.PlanAdmin,
      {
        businessId,
      },
    );
    history.push(planAdminSettingsPath);
  };

  const handlePlanSelected = (plan: Plan) => {
    const adminPlanConfirmationPath = generatePath(
      routeNames.BusinessSettings.PlanChangeConfirmationAdmin,
      {
        businessId,
      },
    );
    history.push({
      pathname: adminPlanConfirmationPath,
      state: { selectedPlan: plan },
    });
  };

  const handleCurrentPlanSelected = navigateToPlanSettings;

  const isPlanChangeConfigLoading =
    fetchAvailablePlansStatus === QueryStatus.WAITING || currentPlanLoading;

  return (
    <BusinessSettingsLayout
      expand
      size="full"
      sidebar={<BusinessSettingsAdminSidebar />}
      isLoading={isPlanChangeConfigLoading}
      title={strings("businessSettings.planChange.title")}
    >
      {plans && plans.length > 0 && (
        <PlanSelection
          customPlanSelectable
          nonProfitPlanSelectable
          plans={plans}
          onSelect={handlePlanSelected}
          currentPlan={currentPlan}
          onCurrentPlanSelect={handleCurrentPlanSelected}
        />
      )}
      <PlanChangeAdminActionArea
        isBackButtonEnabled={true}
        isActionAreaOneElement={true}
        onBackButtonClicked={navigateToPlanSettings}
      />
    </BusinessSettingsLayout>
  );
};

export default BusinessSettingsPlanChangeAdmin;
