import React from "react";
import { Prompt, withRouter, RouteComponentProps } from "react-router";
import CustomModal from "components/modal/CustomModal";
import useCustomModal from "components/modal/useCustomModal";
import useGlobalFormBlock from "hooks/useGlobalFormBlock";

const GlobalFormNavigationExitPrompt = ({ history }: RouteComponentProps) => {
  const {
    state: { isNavigationBlocked, message },
  } = useGlobalFormBlock();

  const unblockGlobalNavigation = true;
  const {
    closeModal,
    handleBlockedNavigation,
    handleConfirmNavigationClick,
    modalVisible,
  } = useCustomModal(history, isNavigationBlocked, unblockGlobalNavigation);

  return (
    <>
      <Prompt when={isNavigationBlocked} message={handleBlockedNavigation} />
      <CustomModal
        popupMessage={message}
        modalVisible={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
    </>
  );
};
export default withRouter(GlobalFormNavigationExitPrompt);
