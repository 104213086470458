import React from "react";
import styled from "style/styled-components";
import TextAreaBlock, {
  TextAreaBlockProps,
} from "components/forms/FormFields/TextArea";

interface LabeledTextAreaProps extends TextAreaBlockProps {
  labelText: string;
}

const Label = styled.h2`
  font-weight: bold;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.family.firaSans};
`;

const Container = styled.div`
  padding-top: ${({ theme }) => theme.margin.large};
`;

const LabeledTextArea = ({ labelText, ...props }: LabeledTextAreaProps) => (
  <>
    <Container>
      <Label>{labelText}</Label>
      <TextAreaBlock {...props} />
    </Container>
  </>
);

export default LabeledTextArea;
