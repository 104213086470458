import React from "react";
import DatePicker, {
  registerLocale,
  ReactDatePickerProps,
} from "react-datepicker";
import useLocale from "common/hooks/useLocale";

import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";
import strings from "localisation/strings";

registerLocale("en", en);
registerLocale("de", de);

export type DateTimePickerProps = ReactDatePickerProps;

enum DateTimeFormat {
  Time = "HH:mm",
  DateEN = "MMM d yyyy",
  DateDE = "d. MMM yyyy",
  DateTimeDE = "d. MMM yyyy HH:mm",
  DateTimeEN = "dd.MM.YYYY HH:mm",
}

const DateTimePicker = ({
  minDate,
  maxDate,
  ...props
}: DateTimePickerProps) => {
  const locale = useLocale();
  const localizedDateFormat =
    locale === "en" ? DateTimeFormat.DateEN : DateTimeFormat.DateDE;
  const localizedDateTimeFormat =
    locale === "en" ? DateTimeFormat.DateTimeEN : DateTimeFormat.DateTimeDE;

  const defaultDatePickerProps: Partial<ReactDatePickerProps> = {
    locale,
    timeCaption: strings("dateTimePicker.time"),
    dateFormat: props.showTimeSelect
      ? localizedDateTimeFormat
      : localizedDateFormat,
    timeFormat: DateTimeFormat.Time,
    shouldCloseOnSelect: true,
    ...(minDate && {
      minDate: new Date(minDate),
    }),
    ...(maxDate && {
      maxDate: new Date(maxDate),
    }),
  };

  return <DatePicker {...defaultDatePickerProps} {...props} />;
};

export default DateTimePicker;
