import React from "react";
import strings from "localisation/strings";
import Plan from "api/models/Plan";
import ActionArea, { withBackButton } from "components/generic/ActionArea";
import ActionButton from "components/buttons/ActionButton";
import useFormFields from "hooks/useFormFields";
import PaymentsFormInlineInputGroup from "views/BusinessSettings/Payment/shared/PaymentsFormInlineInputGroup";
import { BodyText } from "components/generic";
import RichTextRendering from "components/generic/richText/RichTextRendering";
import { Subsection } from "views/Wizard/sharedComponents";
import InlineNumberInput from "components/forms/FormFields/InlineNumberInput";
import styled from "style/styled-components";

interface ActionAreaProps {
  submittable: boolean;
  onConfirmed: () => void;
}

const PlanChangeConfirmationActionArea = withBackButton(
  ({ submittable, onConfirmed }: ActionAreaProps) => (
    <ActionArea>
      <ActionButton
        alone
        disabled={!submittable}
        special="publish"
        text={strings("buttons.confirm")}
        onClick={onConfirmed}
      />
    </ActionArea>
  ),
);

const PlanPriceFormContainer = styled(Subsection)`
  max-width: ${({ theme }) => theme.forms.planPriceContainer};
`;

export interface PlanChangeValues {
  packagePrice: number | null;
}

interface Props {
  selectedPlan: Plan;
  submittable?: boolean;
  customPriceSpecifiable?: boolean;
  onBackClick: () => void;
  onSubmit: (values: PlanChangeValues) => void;
}

const PlanChangeConfirmationForm = ({
  submittable,
  customPriceSpecifiable,
  onSubmit,
  onBackClick,
  selectedPlan,
}: Props) => {
  const { getNumberFieldProps, values } = useFormFields({
    blockNavigation: true,
    numberFields: {
      packagePrice: 0,
    },
    translationScope: "businessSettings.planChangeConfirmation.form",
  });

  const handleFormSubmitted = () => {
    onSubmit({
      packagePrice: customPriceSpecifiable ? values.packagePrice : null,
    });
  };

  return (
    <>
      <BodyText cozy>
        {strings(
          "businessSettings.planChangeConfirmation.changeNotice.nextMonth",
        )}
      </BodyText>
      <Subsection>
        <BodyText cozy>
          {strings(
            "businessSettings.planChangeConfirmation.changeNotice.confirm",
            {
              planName: selectedPlan.name,
            },
          )}
        </BodyText>
      </Subsection>

      {selectedPlan.description && (
        <Subsection>
          <BodyText cozy>
            {strings(
              "businessSettings.planChangeConfirmation.changeNoticePlanIntro",
            )}
          </BodyText>
          <RichTextRendering cozy value={selectedPlan.contract || undefined} />
        </Subsection>
      )}

      {customPriceSpecifiable && (
        <PlanPriceFormContainer>
          <PaymentsFormInlineInputGroup
            placeholder={getNumberFieldProps("packagePrice").placeholder}
          >
            <InlineNumberInput {...getNumberFieldProps("packagePrice")} />
          </PaymentsFormInlineInputGroup>
        </PlanPriceFormContainer>
      )}

      <PlanChangeConfirmationActionArea
        submittable={!!submittable}
        isBackButtonEnabled={true}
        isActionAreaOneElement={true}
        onConfirmed={handleFormSubmitted}
        onBackButtonClicked={onBackClick}
      />
    </>
  );
};

export default PlanChangeConfirmationForm;
