import { I18n } from "localisation/i18n";
import { Container } from "unstated";

interface LocaleStateType {
  locale: string;
}

export default class LocaleState extends Container<LocaleStateType> {
  state = { locale: I18n.defaultLocale };

  constructor(storageKey?: string) {
    super();
  }

  getLocale = () => this.state.locale;
}
