import React from "react";
import {
  CenteredBlockTitle,
  CenteredContentViewContainer,
} from "components/generic";
import { CenteredContent } from "components/activation/CenteredContent";

interface Props {
  title: string;
  children: React.ReactNode;
}

const ActivationPageLayout: React.FC<Props> = ({ title, children }) => (
  <CenteredContentViewContainer>
    <CenteredBlockTitle>{title}</CenteredBlockTitle>
    <CenteredContent>{children}</CenteredContent>
  </CenteredContentViewContainer>
);

export default ActivationPageLayout;
