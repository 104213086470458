import React, { ReactNode } from "react";
import styled from "style/styled-components";
import SectionHeading from "components/generic/SectionHeading";
import { VerticalLargeSpacer } from "components/generic/Spacer";

const FilterSectionArea = styled.div<{ withTitle?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  margin-top: ${({ withTitle }) => (withTitle ? "40px" : "20px")};
`;

interface FilterSectionProps {
  title?: string;
  children?: ReactNode;
}

const FilterSection = ({ title, children }: FilterSectionProps) => (
  <FilterSectionArea withTitle={!!title}>
    {!!title && (
      <>
        <SectionHeading>{title}</SectionHeading>
        <VerticalLargeSpacer />
      </>
    )}
    {children}
  </FilterSectionArea>
);

export default FilterSection;
