import React, { useState } from "react";
import TransparentIconButton, {
  Props as TransparentIconButtonProps,
} from "components/buttons/TransparentIconButton";
import styled from "style/styled-components";
import strings from "localisation/strings";
import Tooltip from "react-tooltip";
import { isIE } from "components/helpers/browser-check";

const ActionTransparentIconButton = styled(TransparentIconButton)<{
  order?: number;
}>`
  order: ${({ order }) => order};
  z-index: ${({ theme }) => theme.layers.componentButton};
`;

const ToolTipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ToolTipText = styled.div`
  width: 120px;
  color: ${({ theme }) => theme.color.foreground.quaternary};
  background-color: ${({ theme }) => theme.color.foreground.secondaryLight};
  text-align: center;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  bottom: 122%;
  left: 50%;
  margin-left: -60px;

  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.foreground.secondaryLight}
      transparent transparent transparent;
  }
`;

interface Props {
  onClick?: () => void;
  order?: number;
  tooltipKey?: string;
  disabled?: boolean;
  children: JSX.Element;
}

const IconActionButtonIE = ({
  onClick,
  order,
  tooltipKey,
  children,
  disabled,
  ...rest
}: Props & TransparentIconButtonProps) => {
  // Internet Explorer 6-11

  const tooltip = tooltipKey ? strings(tooltipKey) : null;

  const [showTooltip, setShowTooltip] = useState(false);

  const onMouseOver = () => {
    if (!showTooltip) {
      setShowTooltip(true);
    }
  };
  const onMouseOut = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <ToolTipContainer>
      <ActionTransparentIconButton
        onClick={onClick}
        order={order}
        disabled={disabled}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        {...rest}
      >
        {children}
      </ActionTransparentIconButton>
      {showTooltip && tooltip ? <ToolTipText>{tooltip}</ToolTipText> : null}
    </ToolTipContainer>
  );
};

const IconActionButtonDefault = ({
  onClick,
  order,
  tooltipKey,
  children,
  disabled,
  ...rest
}: Props & TransparentIconButtonProps) => {
  const tooltip = tooltipKey ? strings(tooltipKey) : null;

  return (
    <>
      {tooltip && <Tooltip className="button-tooltip" effect="solid" />}
      <ActionTransparentIconButton
        onClick={onClick}
        order={order}
        data-tip={tooltip}
        disabled={disabled}
        {...rest}
      >
        {children}
      </ActionTransparentIconButton>
    </>
  );
};

const IconActionButton = ({
  children,
  ...rest
}: Props & TransparentIconButtonProps) => {
  return isIE() ? (
    <IconActionButtonIE {...rest} children={children} />
  ) : (
    <IconActionButtonDefault {...rest} children={children} />
  );
};

export default IconActionButton;
