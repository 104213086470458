export default interface TopicInterface {
  id: string;
  topicId: string;
  topicName: string;
  topicType: TopicType;
}

export enum TopicType {
  General = "GENERAL",
  Alert = "ALERT",
  SmartToolRadar = "ST_RADAR",
  Interest = "INTEREST",
}
