import APIError from "common/api/models/APIError";

const createUnknownError = (error: any): APIError =>
  new APIError({
    status: error.status || 400,
    titleCode: "errors.unknown",
    detailCode: "errors.unknown",
    path: error.url || "Unknown path",
  });

export default createUnknownError;
