import React, { useState, useEffect } from "react";
import styled from "style/styled-components";
import strings from "localisation/strings";

import SearchBox, {
  SearchBoxType,
} from "components/lists/header/Search/SearchBox";
import SearchInput from "components/lists/header/Search/SearchInput";
import { ReactComponent as SearchIcon } from "common/assets/icons/search-custom.svg";
import FilteringCommands from "components/lists/filtering/FilteringCommands";

const Icon = styled(SearchIcon)`
  opacity: 0.3;
`;

const Search = styled(SearchBox)`
  margin-left: 0;
  margin-right: 0;
  width: 100%;
`;

const Input = styled(SearchInput)`
  margin-left: 0;
`;

interface SearchScreenProps {
  onConfirm: (query: string) => void;
  query: string;
  toggleSearch: () => void;
}

const SearchScreen = ({
  onConfirm,
  query,
  toggleSearch,
}: SearchScreenProps) => {
  const [input, setInput] = useState<string>(query);

  useEffect(() => {
    if (query) setInput(query);
  }, [query]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setInput(userInput);
  };

  const onClear = () => setInput("");

  return (
    <>
      <Search type={SearchBoxType.Default}>
        <Input
          placeholder={strings("tabBar.Search")}
          value={input}
          type="text"
          onChange={handleInput}
        />
        <Icon width={20} height={20} />
      </Search>
      <FilteringCommands
        onConfirm={() => {
          onConfirm(input);
          toggleSearch();
        }}
        onClear={onClear}
      />
    </>
  );
};

export default SearchScreen;
