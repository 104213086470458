import useChatState from "hooks/useChatState";
import {
  Conversation,
  Message,
  QuickReplyType,
  ChatConnectionStatus,
} from "components/chat/types";
import { useMemo } from "react";

const matchNonInteractedTextQuickReplyMessage = ({
  quickReplyType,
  isInteracted,
}: Message) => quickReplyType === QuickReplyType.TEXT && !isInteracted;

const useConversationStatus = (conversationId: string) => {
  const {
    conversationsById,
    messagesByConversationId,
    status,
  } = useChatState();
  const conversation =
    conversationsById[conversationId] || ({} as Conversation);

  const messages = messagesByConversationId[conversationId] || [];
  const nonInteractedTextQuickReplyMessage = useMemo(() => {
    return messages.find(matchNonInteractedTextQuickReplyMessage) || null;
  }, [messages]);

  const isDisconnected = status !== ChatConnectionStatus.Connected;
  return {
    isDisabled:
      isDisconnected ||
      (conversation.isDisabled && !nonInteractedTextQuickReplyMessage),
    flowMessageAwaitingReply: nonInteractedTextQuickReplyMessage,
  };
};

export default useConversationStatus;
