import React, { useState, useEffect } from "react";
import { curry, includes, replace, __ } from "ramda";
import { RouteComponentProps, withRouter } from "react-router";

import {
  getOffer,
  createOffer,
  createDraftOffer,
  updateOffer,
  updateDraftOffer,
  getOfferAdmin,
  updateOfferAdmin,
  updateDraftOfferAdmin,
  updateOfferCurator,
  updateDraftOfferCurator,
  getOfferCurator,
  createDraftOfferCurator,
  createDraftOfferAdmin,
  createOfferAdmin,
  createOfferCurator,
} from "api/offers";

import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import { COPY, NEW } from "constants/strings";

import OfferForm from "components/forms/OfferForm";
import DetailedView, { Copy, Details } from "views/DetailedView";
import { getPathForContentItem } from "core/navigation";
import useLoginState from "hooks/useLoginState";
import useNotification from "hooks/useNotification";
import emojiDataState from "state/singletons/emojiDataState";
import useQuery from "common/hooks/useQuery";
import { getEmojiData } from "api/imageStorage";
import QueryStatus from "common/api/models/QueryStatus";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import OfferInterface from "api/models/OfferInterface";

const CopyForm = (props: any) => <OfferForm isNew {...props} />;

const DetailsForm = ({
  isDraft,
  updateItem,
  updateDraftItem,
  ...props
}: any) => (
  <OfferForm
    isNew={false}
    createNew={updateItem}
    createDraft={isDraft ? updateDraftItem : updateItem}
    {...props}
  />
);

const changedValues = new Set<string>();

const OfferDetailed = (props: RouteComponentProps<{ id: string }>) => {
  const { addSuccessNotification } = useNotification();
  // grabbing businessId to address BO instead of BP when necessary
  const {
    getBusinessId,
    getBusinessIdForNewContent,
    hasAdminRights,
    hasCuratorRights,
  } = useLoginState();
  const adminRights = hasAdminRights();
  const isCurator = hasCuratorRights();
  const contextBusinessId = getBusinessId();
  const businessId = getBusinessIdForNewContent();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(
    false,
  );
  const [forceDetailsUpdate, setForceDetailsUpdate] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(props.match.params.id === NEW);
  const isCopy = includes(props.match.params.id, COPY);
  const copyId = replace("?id=", "", props.location.search);

  useEffect(() => {
    return setIsNew(props.match.params.id === NEW);
  }, [props.match.params.id]);

  const successCallback = (message: string, response: any) => {
    addSuccessNotification(message);
    if (isNew || isCopy) {
      props.history.push(
        getPathForContentItem(
          "/angebote",
          response.id,
          adminRights,
          isCurator,
          contextBusinessId,
        ),
      );
    } else {
      setForceDetailsUpdate(!forceDetailsUpdate);
    }
  };

  // assigning calls depending on user's access rights
  const getOfferRequest = isCurator
    ? getOfferCurator
    : adminRights
    ? getOfferAdmin
    : getOffer;
  const updateOfferRequest = isCurator
    ? updateOfferCurator
    : adminRights
    ? updateOfferAdmin
    : updateOffer;
  const updateDraftOfferRequest = isCurator
    ? updateDraftOfferCurator
    : adminRights
    ? updateDraftOfferAdmin
    : updateDraftOffer;

  const mainProps = {
    changedValues,
    success: successCallback,
    setBlockNavigation: setShouldBlockNavigation,
    getDetailsRequest: curry(getOfferRequest),
  };

  const mainRequests = {
    createNew: isCurator
      ? // @ts-ignore
        curry(createOfferCurator)(__, businessId)
      : adminRights
      ? // @ts-ignore
        curry(createOfferAdmin)(__, businessId)
      : curry(createOffer),
    createDraft: isCurator
      ? // @ts-ignore
        curry(createDraftOfferCurator)(__, businessId)
      : adminRights
      ? // @ts-ignore
        curry(createDraftOfferAdmin)(__, businessId)
      : curry(createDraftOffer),
  };

  const {
    result: emojiDataFetchResult,
    status: emojiDataFetchStatus,
  } = useQuery({ request: getEmojiData });
  useEffect(() => {
    if (emojiDataFetchStatus === QueryStatus.SUCCESSFUL) {
      emojiDataState.setEmojiData(emojiDataFetchResult);
    }
  }, [emojiDataFetchStatus]);

  return (
    <DetailedView
      {...props}
      privilegedId={PrivilegedComponentsIds.VIEW_OFFERS}
      shouldBlockNavigation={shouldBlockNavigation}
      isCopy={isCopy}
      isNew={isNew}
      form={<OfferForm isNew {...mainRequests} {...mainProps} />}
      copy={
        <Copy id={copyId} form={CopyForm} {...mainRequests} {...mainProps} />
      }
      details={
        <Details
          id={props.match.params.id}
          form={DetailsForm}
          updateItem={updateOfferRequest}
          updateDraftItem={updateDraftOfferRequest}
          update={forceDetailsUpdate}
          {...mainProps}
        />
      }
    />
  );
};

export default withRouter(OfferDetailed);
