import React from "react";
import strings from "localisation/strings";
import ActivationPageLayout from "components/activation/UserActivationPageLayout";
import RegistrationConfirmationForm from "components/forms/ConfirmationForm/RegistrationConfirmationForm";
import useLoginState from "hooks/useLoginState";

const UserActivationConfirmationView = () => {
  const loginState = useLoginState();
  loginState.logOut();
  return (
    <ActivationPageLayout title={strings("activateAccountScreen.title")}>
      <RegistrationConfirmationForm />
    </ActivationPageLayout>
  );
};

export default UserActivationConfirmationView;
