import React from "react";
import styled from "style/styled-components";
import LoggedIn from "components/generic/LoggedIn";
import LoggedOut from "components/generic/LoggedOut";
import { Headline, Hint, Title } from "components/generic";
import strings from "localisation/strings";
import LoginForm from "components/forms/LoginForm/LoginForm";

import {
  Close,
  ExitIcon,
  FullScreen,
  LinksList,
  LoginFormContainer,
  MenuContainer,
  StyledLink,
  TopBarRegisterButton,
} from "components/topBar/HeaderComponents";
import LogoutButton from "components/topBar/LogoutButton";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";
import PrivilegedComponent from "components/PrivilegedComponent";

const StyledMenuContainer = styled(MenuContainer)`
  padding-top: 60px;
  padding-bottom: 60px;
`;

interface Props {
  visible: boolean;
  onHideMobileMenu: () => void;
  onLogout: () => void;
}

const MobileUserMenu = (props: Props) => {
  const { visible, onHideMobileMenu, onLogout } = props;

  if (!visible) return null;

  return (
    <FullScreen>
      <StyledMenuContainer>
        <Close
          onClick={() => {
            onHideMobileMenu();
          }}
        >
          <ExitIcon width={24} height={24} />
        </Close>

        <LoggedOut>
          <LoginFormContainer>
            <Title>{strings("loginForm.title")}</Title>
            <LoginForm
              mobile
              onDone={() => {
                onHideMobileMenu();
              }}
            />
            <TopBarRegisterButton
              text={strings("buttons.register")}
              onClick={() => {
                onHideMobileMenu();
              }}
              to="/registrieren"
            />
          </LoginFormContainer>
        </LoggedOut>
        <LoggedIn>
          <Headline>{strings("header.mySettings")}</Headline>
          <Hint>{strings("profilePopup.hint")}</Hint>

          <LinksList>
            <PrivilegedComponent
              id={PrivilegedComponentsIds.MENU_PERSONAL_DATA}
            >
              <StyledLink
                to="/konto/persoenliche-angaben"
                onClick={() => {
                  onHideMobileMenu();
                }}
              >
                {strings("header.mySettings")}
              </StyledLink>
              <StyledLink
                to="/agb"
                onClick={() => {
                  onHideMobileMenu();
                }}
              >
                {strings("header.termsAndConditions")}
              </StyledLink>
              <StyledLink
                to="/marketingzwecke"
                onClick={() => {
                  onHideMobileMenu();
                }}
              >
                {strings("header.marketingPurposes")}
              </StyledLink>
              <PrivilegedComponent
                id={PrivilegedComponentsIds.MENU_CHANGE_PASSWORD}
              >
                <StyledLink
                  to="/konto/passwort-aendern"
                  onClick={() => {
                    onHideMobileMenu();
                  }}
                >
                  {strings("header.changePassword")}
                </StyledLink>
              </PrivilegedComponent>
            </PrivilegedComponent>

            <LogoutButton onHideMenu={onHideMobileMenu} onLogout={onLogout} />
          </LinksList>
        </LoggedIn>
      </StyledMenuContainer>
    </FullScreen>
  );
};

export default MobileUserMenu;
