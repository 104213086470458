import React from "react";
import styled from "style/styled-components";
import SelectComponent, { SelectItem } from "components/forms/Select";

const StyledSelect = styled(SelectComponent)`
  min-width: 70px;
  max-width: 80px;
  font-size: 13px;
`;

interface Option {
  value: number;
  label: string;
}

const valueToOption = (value: number): Option => ({
  value,
  label: `${value}`,
});

const options = [10, 25, 50, 100].map(valueToOption);

export interface PageSizeSelectProps {
  pageSize: number;
  onPageSizeChange: (page: number) => void;
}

const PageSizeSelect = ({
  pageSize,
  onPageSizeChange,
}: PageSizeSelectProps) => {
  return (
    <StyledSelect
      classNamePrefix="pageSize"
      value={valueToOption(pageSize)}
      onChange={(option: SelectItem) =>
        onPageSizeChange(option.value as number)
      }
      options={options}
      small
      width="auto"
    />
  );
};

export default PageSizeSelect;
