import React, { useEffect, useState } from "react";
import moment from "moment";
import { Date, Time } from "components/forms/FormFields/DateTime";
import styled from "style/styled-components";
import {
  defaultBlockWidth,
  defaultDateTimeFormat,
  getDateFromValue,
} from "components/forms/FormFields/DateTime/helpers";
import FieldErrors from "components/forms/FieldErrors";
import { HorizontalSmallSpacer } from "components/generic";
import { Placement } from "@floating-ui/react";

interface Props {
  width?: string;
  paddingWidth?: string;
  dateWidth?: string;
  timeWidth?: string;
  timeIntervals?: number;
  dateFormat?: string;
  timeFormat?: string;
  datePlaceholder?: string;
  timePlaceholder?: string;
  popperPlacement?: Placement;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  value?: string;
  errors?: string[];
  setValue?: (value?: string) => void;
  getDateTimeFromValue?: (value?: string) => Date | null;
  hideTime?: boolean;
  testId?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div<{ width?: string }>`
  line-height: 32px;
  display: flex;
  min-height: 32px;
  height: 0;
`;

const Errors = styled(FieldErrors)`
  line-height: 14px;
`;

const DateFlexComponent = styled.div`
  min-width: 120px;
  height: 32px;
  flex: 1 1 auto;
`;

const DateTime = ({
  width,
  dateWidth,
  timeWidth,
  timeIntervals,
  dateFormat,
  timeFormat,
  datePlaceholder,
  timePlaceholder,
  popperPlacement,
  minDate,
  maxDate,
  minTime,
  maxTime,
  value,
  errors,
  setValue,
  getDateTimeFromValue,
  hideTime,
  testId,
}: Props) => {
  const initialDateTime = getDateTimeFromValue
    ? getDateTimeFromValue(value)
    : null;
  const [dateTime, setDateTime] = useState<Date | null>(initialDateTime);

  let dateTimeValue: string | undefined;
  if (dateTime) {
    dateTimeValue = moment(dateTime).format(defaultDateTimeFormat);
  }

  const isErrored = !!(errors && errors.length);

  const updateDateTime = (momentValue: string) => {
    setDateTime(moment(momentValue).toDate());
    if (setValue) setValue(moment(momentValue).format(defaultDateTimeFormat));
  };

  const onChangeDate = (v?: string) => {
    if (v) updateDateTime(v);
  };

  const onChangeTime = (v?: string) => {
    if (v) updateDateTime(v);
  };

  useEffect(() => {
    const newValue = getDateTimeFromValue ? getDateTimeFromValue(value) : null;
    setDateTime(newValue);
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <InputsContainer width={width}>
        <DateFlexComponent>
          <Date
            value={dateTimeValue}
            setValue={onChangeDate}
            valueFormat={dateFormat}
            getDateFromValue={getDateFromValue}
            isErrored={isErrored}
            width={dateWidth || defaultBlockWidth}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            placeholder={datePlaceholder}
            popperPlacement={popperPlacement}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DateFlexComponent>
        {!hideTime && (
          <>
            <HorizontalSmallSpacer />
            <DateFlexComponent>
              <Time
                value={dateTimeValue}
                setValue={onChangeTime}
                valueFormat={timeFormat}
                getDateFromValue={getDateFromValue}
                isErrored={isErrored}
                width={timeWidth || defaultBlockWidth}
                timeIntervals={timeIntervals}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                placeholder={timePlaceholder}
                popperPlacement={popperPlacement}
                minDate={minDate}
                maxDate={maxDate}
                minTime={minTime}
                maxTime={maxTime}
              />
            </DateFlexComponent>
          </>
        )}
      </InputsContainer>
      <Errors testId={testId} errors={errors} />
    </Container>
  );
};

export default DateTime;
