import React, {
  RefObject,
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
} from "react";

import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import InlineInput from "components/forms/FormFields/InlineInput";
import cardIcon from "common/assets/icons/card.svg";
import theme from "style/theme";
import styled from "style/styled-components";
import Testable from "testing/Testable";

export interface SingleInputFieldProps
  extends Partial<InputHTMLAttributes<HTMLInputElement>>,
    Testable {
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  errored?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  errors?: string[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[] | undefined;
  width?: string | number;
  inputWidth?: string;
  icon?: string;
  inputPlaceholder?: string;
  margin?: string;
  active?: boolean;
  testId?: string;
}

const Container = styled.div`
  display: block;
`;

const SimpleIconedInput = (props: SingleInputFieldProps) => {
  const {
    onFocus,
    placeholder,
    inputPlaceholder,
    onBlur,
    onChange,
    value,
    inputWidth,
    width = theme.forms.leftSide,
    icon = cardIcon,
    className = "",
    margin = "",
    active = false,
  } = props;
  return (
    <Container>
      <InlineInputGroup
        icon={icon}
        placeholder={placeholder}
        width={width}
        className={className}
        margin={margin && margin}
        active={active}
      >
        <InlineInput
          {...props}
          type="text"
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          width={inputWidth}
          placeholder={inputPlaceholder}
        />
      </InlineInputGroup>
    </Container>
  );
};

export default SimpleIconedInput;
