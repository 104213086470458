import React, { useState } from "react";
import styled from "style/styled-components";
import {
  ReplyDetailsInterface,
  ReviewReplyPayloadInterface,
} from "api/models/ReviewInterface";
import TextButton from "components/buttons/TextButton";
import strings from "localisation/strings";
import ActionArea from "components/generic/ActionArea";
import Separator from "components/forms/FormFields/Separator";
import emailIcon from "assets/icons/mail.svg";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import useNotification from "hooks/useNotification";
import ReviewReplyDeleteButton from "components/review/ReviewReplyDeleteButton";
import SaveReplyForm from "components/review/SaveReplyForm";
import { AbortableRequest } from "common/api/AbortableRequest";
import { AbortablePayloadRequest } from "common/api/AbortablePayloadRequest";

export const defaultReviewReplyFields = {};

const VerticalSeparator = styled.div`
  width: 2px;
  background: ${({ theme }) => theme.color.background.tertiary};
  opacity: 0.38;
  height: ${({ theme }) => theme.margin.large};
  margin-left: ${({ theme }) => theme.margin.larger};
  margin-right: ${({ theme }) => theme.margin.larger};
  vertical-align: middle;
`;

interface ReplyFormProps {
  reply?: ReplyDetailsInterface;
  saveReply: AbortablePayloadRequest<
    ReviewReplyPayloadInterface,
    ReplyDetailsInterface
  >;
  deleteReply: (replyId: string) => AbortableRequest<void>;
  onReplySaved: (result: ReplyDetailsInterface) => void;
  onReplyDeleted: () => void;
}

interface ReplyActionsProps {
  reply?: ReplyDetailsInterface;
  toggleExpand: () => void;
  deleteReply: (replyId: string) => AbortableRequest<void>;
  onReplyDeleted: () => void;
}

interface DisplayReplyProps {
  reply?: ReplyDetailsInterface;
  expanded: boolean;
}

const DisplayReply = ({ reply, expanded }: DisplayReplyProps) => {
  if (expanded || !reply) {
    return null;
  }
  return (
    <>
      <Separator />
      <InlineInputGroup
        icon={emailIcon}
        placeholder={reply.comment}
        wrapText={true}
        width="100%"
      />
    </>
  );
};

const CollapsedActions = ({
  reply,
  toggleExpand,
  deleteReply,
  onReplyDeleted,
}: ReplyActionsProps) => {
  return (
    <ActionArea position={"left"}>
      {!reply && (
        <TextButton
          onClick={toggleExpand}
          text={strings("reviewReply.replyButton")}
        />
      )}
      {reply && (
        <>
          <ReviewReplyDeleteButton
            deleteReply={deleteReply}
            onReplyDeleted={onReplyDeleted}
            replyId={reply.id}
          />
          <VerticalSeparator />
          <TextButton
            onClick={toggleExpand}
            text={strings("reviewReply.editCommentButton")}
          />
        </>
      )}
    </ActionArea>
  );
};

const ReviewReply = ({
  saveReply,
  deleteReply,
  onReplySaved,
  onReplyDeleted,
  reply,
}: ReplyFormProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpand = () => setExpanded(!expanded);
  const { addSuccessNotification } = useNotification();

  const handleReplySaved = (result: ReplyDetailsInterface) => {
    onReplySaved(result);
    addSuccessNotification();
    setExpanded(false);
  };

  const handleReplyDeleted = () => {
    onReplyDeleted();
    setExpanded(false);
  };

  return (
    <>
      <DisplayReply expanded={expanded} reply={reply} />
      {expanded && (
        <SaveReplyForm
          onCancel={toggleExpand}
          reply={reply}
          saveReply={saveReply}
          onReplySaved={handleReplySaved}
        />
      )}
      {!expanded && (
        <CollapsedActions
          reply={reply}
          toggleExpand={toggleExpand}
          deleteReply={deleteReply}
          onReplyDeleted={handleReplyDeleted}
        />
      )}
    </>
  );
};

export default ReviewReply;
