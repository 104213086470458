import React from "react";
import Plan, { BusinessPlanHistory } from "api/models/Plan";
import styled from "styled-components";
import ListItemSeparator from "components/lists/item/ListItemSeparator";
import moment from "moment";
import strings from "localisation/strings";
import List from "components/lists/List";

const ListHeaderSpan = styled.span`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  line-height: 18px;
  color: ${({ theme }) => theme.color.foreground.tertiary};
`;

const ListCellSpan = styled.span`
  font-family: ${({ theme }) => theme.font.family.firaSans};
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.color.font.onLightBackground};
  line-height: 18px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableCell = styled.div<{ width: string }>`
  flex-grow: 1;
  flex-basis: ${({ width }) => width};
  padding: ${({ theme }) => `${theme.margin.large} ${theme.margin.small}`};
`;

interface BusinessPlanHistoryProps {
  planHistory: BusinessPlanHistory;
}

const PlanHistoryListHeader = () => (
  <>
    <TableRow>
      <TableCell width={"10%"}>
        <ListHeaderSpan>{"#"}</ListHeaderSpan>
      </TableCell>
      <TableCell width={"20%"}>
        <ListHeaderSpan>
          {strings("businessSettings.plan.history.package")}
        </ListHeaderSpan>
      </TableCell>
      <TableCell width={"30%"}>
        <ListHeaderSpan>
          {strings("businessSettings.plan.history.price")}
        </ListHeaderSpan>
      </TableCell>
      <TableCell width={"40%"}>
        <ListHeaderSpan>
          {strings("businessSettings.plan.history.period")}
        </ListHeaderSpan>
      </TableCell>
    </TableRow>
    <ListItemSeparator />
  </>
);

const PlanHistoryListItem = (plan: Plan, index: number) => {
  // Actual data renderItem calls get index values in multiples of 2, due to odd indexes being used by separators
  const adjustedIndex = index === 0 ? 1 : index / 2 + 1;

  const planValidFrom =
    plan.validity && plan.validity.dateTimeFrom
      ? moment(plan.validity.dateTimeFrom)
          .utc(false)
          .format("DD.MM.YYYY")
      : null;
  const planValidTill =
    plan.validity && plan.validity.dateTimeTill
      ? moment(plan.validity.dateTimeTill)
          .utc(false)
          .format("DD.MM.YYYY")
      : "";

  const validityPeriodString = planValidFrom
    ? `${planValidFrom}  - ${planValidTill}`
    : "";
  const validityText =
    planValidFrom === planValidTill
      ? strings("businessSettings.plan.history.invalid")
      : validityPeriodString;

  return (
    <TableRow key={`tr${index}`}>
      <TableCell width="10%">
        <ListCellSpan>{adjustedIndex}</ListCellSpan>
      </TableCell>
      <TableCell width="20%">
        <ListCellSpan>{plan.name}</ListCellSpan>
      </TableCell>
      <TableCell width="30%">
        <ListCellSpan>{plan.price}</ListCellSpan>
      </TableCell>
      <TableCell width="40%">
        <ListCellSpan>{validityText}</ListCellSpan>
      </TableCell>
    </TableRow>
  );
};
const PlanHistoryList = ({ planHistory }: BusinessPlanHistoryProps) => {
  const historyData = planHistory.planHistory;
  return (
    <>
      <PlanHistoryListHeader />
      <List
        emptyIndicatable
        emptyIndicator={strings("businessSettings.plan.history.empty")}
        data={historyData}
        renderItem={PlanHistoryListItem}
      />
    </>
  );
};

export default PlanHistoryList;
