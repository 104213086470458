import React from "react";
import strings from "localisation/strings";
import {
  CenteredBlockTitle,
  Container,
  VerticalLargeSpacer,
} from "components/generic";
import { InlineTextButton } from "components/buttons/TextButton";
import { DetailsViewContainer } from "components/generic/ViewContainer";
import {
  partnerEmailSupportLink,
  partnerEmailSupportLinkText,
} from "core/constants";

const BusinessUserLinkExpiredView = () => (
  <>
    <DetailsViewContainer>
      <CenteredBlockTitle>
        {strings("activateAccountScreen.linkExpired.title")}
      </CenteredBlockTitle>

      <Container>
        {strings(
          "activateAccountScreen.linkExpired.contactBliggitAdmin.thanks",
        )}
      </Container>
      <Container>
        {strings(
          "activateAccountScreen.linkExpired.contactBliggitAdmin.linkExpired",
        )}
      </Container>
      <Container>
        {strings(
          "activateAccountScreen.linkExpired.contactBliggitAdmin.receiveNewLink",
        )}
      </Container>
      <VerticalLargeSpacer />
      <div>
        {strings(
          "activateAccountScreen.linkExpired.contactBliggitAdmin.problems",
        )}
        <InlineTextButton
          onClick={() => {
            window.location.href = partnerEmailSupportLink;
          }}
          text={partnerEmailSupportLinkText}
          type="inline"
        />
        {strings("activateAccountScreen.linkExpired.contactBliggitAdmin.help")}
      </div>
      <VerticalLargeSpacer />
      <Container>
        {strings("activateAccountScreen.linkExpired.contactBliggitAdmin.team")}
      </Container>
    </DetailsViewContainer>
  </>
);

export default BusinessUserLinkExpiredView;
