import React, { useEffect, useState } from "react";
import { equals, curry } from "ramda";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import styled from "style/styled-components";
import strings from "localisation/strings";
import PictureTypes from "api/models/PictureTypes";
import { ImageItem } from "hooks/helpers/Interfaces";
import FixedRatioPictureComponent from "components/forms/FormFields/PictureGallery/FixedRatioPictureComponent";
import {
  getSlides,
  statusFormatter,
} from "components/forms/FormFields/PictureGallery/helpers";
import APIErrorResponse from "common/api/models/APIErrorResponse";
import OverlaySpinner from "components/generic/OverlaySpinner";
import FieldErrors from "components/forms/FieldErrors";
import ImageUploadType from "api/models/ImageUploadType";
import SmallPicture from "components/forms/FormFields/PictureGallery/SmallPicture";
import CropAndZoomDialog from "components/generic/CropAndZoomDialog";

const StylesContainer = styled.div<{ height?: string }>`
  .carousel .slide {
    background: transparent;
  }

  /* Margins to align navigation side-bars with the slide height */
  .carousel.carousel-slider .control-arrow {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .carousel .control-arrow,
  .carousel .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2) !important;
  }

  .carousel .carousel-status {
    height: 20px;
    color: rgba(255, 255, 255, 0.85);
    font-size: ${({ theme }) => theme.font.size.pagination};
    font-family: ${({ theme }) => theme.font.family.firaSans};
    line-height: 20px;
    text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    top: 32px;
    left: 32px;
    max-width: 40px;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 0.8;
  }
`;

export interface PictureGalleryProps {
  width?: string;
  height?: string;
  pictures: ImageItem[] | null;
  setPictures?: (pictures: ImageItem[]) => void;
  limit?: number;
  container?: string;
  hasSmallImage?: boolean;
  smallImageUrl: string | null;
  smallPictureContainer: string;
  smallPictureErrors: string[];
  setSmallImage: (picture: ImageItem) => void;
  parseErrors?: (response: APIErrorResponse) => void;
  updateKey?: string;
  picturesErrors: string[];
}

const isMainType = (main = false) =>
  main ? PictureTypes.main : PictureTypes.secondary;

const hasMainItems = (slides: ImageItem[]) =>
  slides.filter(slide => slide.type === PictureTypes.main).length !== 0;

const hasOneItem = (slides: ImageItem[]) =>
  slides.filter(slide => slide.url).length === 1;

const cleanUpPictures = (pictureItems: ImageItem[]): ImageItem[] => {
  return pictureItems ? pictureItems.filter(p => p.url !== null) : pictureItems;
};

const PictureGallery = ({
  width,
  height,
  pictures = [],
  setPictures,
  smallImageUrl,
  smallPictureErrors,
  limit = 5,
  container = ImageUploadType.BUSINESS,
  parseErrors,
  updateKey,
  picturesErrors,
  smallPictureContainer,
  setSmallImage,
  hasSmallImage,
}: PictureGalleryProps) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [slides, setSlides] = useState<ImageItem[]>(getSlides(null, limit));
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [pictureUploadErrorMatrix, setPictureUploadErrorMatrix] = useState<
    string[][]
  >([[], [], [], [], []]);
  const [showCropAndZoom, setShowCropAndZoom] = useState<boolean>(false);
  const [cropAndZoomImageItem, setCropAndZoomImageItem] = useState<ImageItem>();
  const [cropAndZoomImageUrl, setCropAndZoomImageUrl] = useState<string>("");
  const [cropAndZoomImageType, setCropAndZoomImageType] = useState<string>("");
  const [cropAndZoomImageFileName, setCropAndZoomImageFileName] = useState<
    string
  >("");
  const [cropAndZoomRatio, setCropAndZoomRatio] = useState<number>(1);
  const [cropAndZoomMinZoom, setCropAndZoomMinZoom] = useState<number>(1);
  const [cropAndZoomMaxZoom, setCropAndZoomMaxZoom] = useState<number>(3);
  const [cropAndZoomCropShape, setCropAndZoomCropShape] = useState<
    "rect" | "round"
  >("round");
  const [cropAndZoomContainer, setCropAndZoomContainer] = useState<string>("");
  const [cropAndZoomIndex, setCropAndZoomIndex] = useState<number>(0);
  const [cropAndZoomWarning, setCropAndZoomWarning] = useState<boolean>(false);
  const [cropAndZoomPortraitWarning, setCropAndZoomPortraitWarning] = useState<
    boolean
  >(false);

  const setSlideErrors = (slideIndex: number, newSlideErrors: string[]) => {
    const newMatrix = pictureUploadErrorMatrix.map((slideErrors, index) =>
      index === slideIndex ? newSlideErrors : slideErrors,
    );
    setPictureUploadErrorMatrix(newMatrix);
  };

  const initSlides = (): void => {
    setSlides(getSlides(pictures, limit));
    setSelectedItem(0);
  };

  useEffect(() => {
    if (pictures && !equals(pictures, cleanUpPictures(slides))) {
      initSlides();
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictures]);

  useEffect(() => {
    if (pictures) {
      initSlides();
    }
    // TODO: Fix to match eslint rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateKey]);

  const removePicture = (index: number) => {
    const newSlides: ImageItem[] = slides.map((slide, i) => ({
      ...slide,
      id: index === i ? undefined : slide.id,
      url: index === i ? null : slide.url,
      type: PictureTypes.secondary,
      createdDateTime: index === i ? undefined : slide.createdDateTime,
    }));

    // If there's no main picture, set next available picture as main
    if (!hasMainItems(newSlides)) {
      newSlides.some((slide, idx) => {
        if (slide.url !== null) {
          newSlides[idx] = {
            ...newSlides[idx],
            type: PictureTypes.main,
          };
        }
        return slide.url !== null;
      });
    }

    setSlides(newSlides);
    if (setPictures) setPictures(newSlides);
    setSelectedItem(index);
  };

  const setAsMain = (index: number) => {
    const newSlides: ImageItem[] = slides.map((slide, i) => ({
      ...slide,
      type: isMainType(index === i),
    }));

    setSlides(newSlides);
    if (setPictures) setPictures(newSlides);
    setSelectedItem(index);
  };

  const updateSlide = (picture: ImageItem, index: number) => {
    const newPicture = {
      ...picture,
      type:
        hasMainItems(slides) && !hasOneItem(slides)
          ? picture.type
          : PictureTypes.main,
    };

    const newSlides: ImageItem[] = slides.map((slide, slideIndex) =>
      slideIndex === index ? newPicture : slide,
    );

    setSlides(newSlides);
    updatePictures(newSlides);
  };

  const updatePictures = (pictureItems: ImageItem[]): void => {
    if (setPictures) {
      setPictures(cleanUpPictures(pictureItems));
    }
  };

  const onChange = (index: number) => setSelectedItem(index);

  const [smallSlideErrors, setSmallSlideErrors] = useState<string[]>([]);

  const smallPicture: ImageItem = {
    url: smallImageUrl,
    type: PictureTypes.main,
  };

  const updadeSmallPicture = (image: ImageItem) => {
    if (setSmallImage) setSmallImage(image);
  };

  const hasSmallPictureErrors = Boolean(
    smallPictureErrors && smallPictureErrors.length > 0,
  );

  const onInitialUpload = (
    imageUrl: string,
    ratio: number,
    cropShape: "rect" | "round",
    containerToUse: string,
  ) => {
    const image = new Image();
    image.src = imageUrl;
    image.addEventListener("load", () => {
      const imageRatio = image.naturalWidth / image.naturalHeight;
      const minZoom = Math.min(
        imageRatio < ratio
          ? (1 / ratio) * imageRatio
          : ratio * (1 / imageRatio),
        1,
      );
      const maxZoom = minZoom + 2;

      setCropAndZoomImageUrl(imageUrl);
      setCropAndZoomRatio(ratio);
      setCropAndZoomMinZoom(minZoom);
      setCropAndZoomMaxZoom(maxZoom);
      setCropAndZoomCropShape(cropShape);
      setCropAndZoomContainer(containerToUse);
      setCropAndZoomWarning(
        cropShape === "round"
          ? Math.min(image.naturalWidth, image.naturalHeight) < 150
          : Math.max(image.naturalWidth, image.naturalHeight) < 720,
      );
      setCropAndZoomPortraitWarning(image.naturalWidth < image.naturalHeight);

      setShowCropAndZoom(true);
    });
  };

  const onHideCropAndZoom = (imageItem: ImageItem, imageUrl: string) => {
    setShowCropAndZoom(false);

    const newImageItem: ImageItem = {
      type: imageItem.type,
      url: imageUrl,
    };

    if (hasSmallImage && cropAndZoomRatio === 1) {
      updadeSmallPicture(newImageItem);
    } else {
      updateSlide(newImageItem, cropAndZoomIndex);
    }
  };

  return (
    <StylesContainer height={height}>
      <Carousel
        infiniteLoop={true}
        showIndicators={false}
        showThumbs={false}
        selectedItem={selectedItem}
        onChange={onChange}
        onClickItem={() => {}}
        width={width}
        useKeyboardArrows={false}
        autoPlay={false}
        emulateTouch={true}
        statusFormatter={statusFormatter}
      >
        {slides.map((picture, index) => {
          const setSlideErrorsFunction = curry(setSlideErrors)(index);
          return (
            <FixedRatioPictureComponent
              updateSlide={image => updateSlide(image, index)}
              key={`picrture-${index}`}
              picture={picture}
              setAsMain={() => setAsMain(index)}
              removePicture={() => removePicture(index)}
              height={height}
              width={width}
              container={container}
              parseErrors={parseErrors}
              setWaitingStatus={setShowOverlay}
              hasFormErrors={picturesErrors && picturesErrors.length > 0}
              slideErrors={pictureUploadErrorMatrix[index]}
              setSlideErrors={setSlideErrorsFunction}
              onInitialUpload={(imageUrl: string) => {
                setCropAndZoomImageItem(picture);
                setCropAndZoomIndex(index);
                onInitialUpload(imageUrl, 3 / 2, "rect", container);
              }}
              setImageType={setCropAndZoomImageType}
              setImageFileName={setCropAndZoomImageFileName}
            />
          );
        })}
      </Carousel>
      {hasSmallImage && (
        <SmallPicture
          placeholder={strings(`dragAndDropSmall.profileImage`)}
          picture={smallPicture}
          updateSlide={updadeSmallPicture}
          container={smallPictureContainer}
          parseErrors={parseErrors}
          setWaitingStatus={setShowOverlay}
          hasFormErrors={hasSmallPictureErrors}
          slideErrors={smallSlideErrors}
          setSlideErrors={setSmallSlideErrors}
          onInitialUpload={(imageUrl: string) => {
            setCropAndZoomImageItem(smallPicture);
            onInitialUpload(imageUrl, 1, "round", smallPictureContainer);
          }}
          setImageType={setCropAndZoomImageType}
          setImageFileName={setCropAndZoomImageFileName}
        />
      )}
      <FieldErrors errors={picturesErrors} />
      {showOverlay && <OverlaySpinner />}
      <CropAndZoomDialog
        isVisible={showCropAndZoom}
        onClose={onHideCropAndZoom}
        imageItem={cropAndZoomImageItem}
        imageUrl={cropAndZoomImageUrl}
        setImageUrl={setCropAndZoomImageUrl}
        imageType={cropAndZoomImageType}
        imageFileName={cropAndZoomImageFileName}
        ratio={cropAndZoomRatio}
        minZoom={cropAndZoomMinZoom}
        maxZoom={cropAndZoomMaxZoom}
        cropShape={cropAndZoomCropShape}
        parseErrors={parseErrors}
        container={cropAndZoomContainer}
        setSlideErrors={
          hasSmallImage && cropAndZoomRatio === 1
            ? setSmallSlideErrors
            : curry(setSlideErrors)(cropAndZoomIndex)
        }
        showWarning={cropAndZoomWarning}
        showPortraitWarning={cropAndZoomPortraitWarning}
      />
    </StylesContainer>
  );
};

export default PictureGallery;
