import React from "react";
import DateTimeRange from "api/models/DateTimeRange";
import strings from "localisation/strings";
import DateInput from "components/forms/DateInput";
import styled from "style/styled-components";
import { VerticalMediumSpacer } from "components/generic/Spacer";
import {
  getRestrictedDateRangeByDateFrom,
  getRestrictedDateRangeByDateTill,
  setDateToEndOfDay,
  setDateToStartOfDay,
} from "components/helpers/dateSelectionUtilities";
import DateTimePicker from "components/inputs/DateTimePicker";

export interface DateRangeProps {
  dateTimeRange: DateTimeRange;
  onDateRangeChange?: (newDateRange: DateTimeRange) => void;
}

const DateContainer = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
`;

const DateRange = ({
  dateTimeRange: { dateTimeFrom, dateTimeTill },
  onDateRangeChange,
}: DateRangeProps) => {
  const onSelectStart = (d?: Date | null) => {
    if (onDateRangeChange) {
      onDateRangeChange(
        getRestrictedDateRangeByDateFrom({
          dateTimeTill,
          dateTimeFrom: setDateToStartOfDay(d || undefined),
        }),
      );
    }
  };

  const onSelectEnd = (d?: Date | null) => {
    if (onDateRangeChange) {
      onDateRangeChange(
        getRestrictedDateRangeByDateTill({
          dateTimeFrom,
          dateTimeTill: setDateToEndOfDay(d || undefined),
        }),
      );
    }
  };

  const format = "d. MMM yyyy";
  const startLabel = strings("filterScreen.dateFilter.startDateTitle");
  const endLabel = strings("filterScreen.dateFilter.endDateTitle");

  const selectedStartDate = dateTimeFrom ? new Date(dateTimeFrom) : undefined;
  const selectedEndDate = dateTimeTill ? new Date(dateTimeTill) : undefined;

  return (
    <>
      <DateContainer>
        <DateTimePicker
          selected={selectedStartDate}
          customInput={<DateInput label={startLabel} />}
          dateFormat={format}
          onChange={onSelectStart}
        />
      </DateContainer>
      <VerticalMediumSpacer />
      <DateContainer>
        <DateTimePicker
          selected={selectedEndDate}
          customInput={<DateInput label={endLabel} />}
          dateFormat={format}
          onChange={onSelectEnd}
        />
      </DateContainer>
    </>
  );
};

export default DateRange;
