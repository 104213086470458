import * as styledComponents from "styled-components";

import { ThemeInterface } from "style/theme";
import { screenSizes } from "style/breakpoints";

const {
  default: styled,
  css,
  ThemeProvider,
  createGlobalStyle,
  keyframes,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>;

const isMobile = (width = 0) => width <= screenSizes.phoneWidthMax;

const isTablet = (width = 0) =>
  screenSizes.phoneWidthMax < width && width < screenSizes.desktopWidthMin;

const isDesktop = (width = 0) => width >= screenSizes.desktopWidthMin;

export type ThemedAndStyled<P> = P &
  styledComponents.ThemeProps<ThemeInterface>;

export {
  css,
  createGlobalStyle,
  ThemeProvider,
  keyframes,
  isMobile,
  isTablet,
  isDesktop,
};

export default styled;
