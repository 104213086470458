import React, { useEffect, useRef, useState } from "react";
import { curry } from "ramda";
import strings from "localisation/strings";
import { getDateLabel } from "utils";

import {
  deleteReplyToReviewForEvent,
  deleteReplyToReviewForEventAdmin,
  getReviewsForEvent,
  getReviewsForEventAdmin,
  saveReplyToReviewForEvent,
  saveReplyToReviewForEventAdmin,
} from "api/events";
import EventInterface from "api/models/EventInterface";
import EntityTypes from "api/models/EntityTypes";

import {
  Media,
  Title,
  VerticalLargeSpacer,
  VerticalMediumSpacer,
} from "components/generic";
import {
  ArtistsInput,
  SaveStatusDisplay,
  Separator,
  SettingsArea,
  TextWithIcon,
} from "components/forms/FormFields";
import SimpleIconedInput from "components/forms/FormFields/SimpleIconedInput";
import PriceRange from "components/forms/FormFields/PriceRange";
import DescriptionBlock from "components/forms/DescriptionBlock";
import TagSelectionField from "components/forms/TagSelection/TagSelectionField";
import DateTimeRangePicker from "components/forms/ValidatedFields/DateTimeRangePicker";
import TitleInput from "components/forms/ValidatedFields/TitleInput";
import ReviewsAndRatings from "components/review/ReviewsAndRatings";
import AddressPicker from "components/forms/FormFields/AddressAutocomplete/AddressPicker";
import zooIcon from "assets/icons/zoo.svg";
import date from "common/assets/icons/date.svg";
import treueWelt from "common/assets/icons/treueWelt.svg";

import { getActionArea } from "components/helpers/formHelpers";
import {
  defaultEventFields,
  getEventPayload,
  setEventFields,
} from "components/helpers/formHelpers/event";
import InlineInputGroup from "components/forms/FormFields/InlineInputGroup";
import PictureGallery from "components/forms/FormFields/PictureGallery";
import Checkbox from "components/forms/Checkbox";
import PrivilegedComponent from "components/PrivilegedComponent";
import PrivilegedComponentsIds from "constants/PrivilegedComponentsIds";

import useFormFields from "hooks/useFormFields";
import useLoginState from "hooks/useLoginState";
import appTheme from "style/theme";
import ImageUploadType from "api/models/ImageUploadType";
import styled from "style/styled-components";
import breakpoints from "style/breakpoints";
import useMutation from "common/hooks/useMutation";
import { useLocation } from "react-router-dom";
import { COPY } from "constants/strings";
import HashtagsField from "components/forms/HashtagsField";
import { useTagRaceConditionCatcher } from "hooks/useTagRaceConditionCatcher";
import { getUserHasPrivilege } from "components/topBar/menu/MenuComponents";
import MapsSelectList from "components/forms/FormFields/MapsSelectList";

// The max-height has aspect ratio scaling applied to keep margins between components same as before image box rescaling
const ImageBox = styled.div`
  max-height: ${({ theme }) =>
    500 + 500 * ((100 - theme.image.aspectRatio) / 100)}px;

  /*
    IE10+ CSS to fix an IE bug that causes flex box overflow.
    See: https://stackoverflow.com/questions/41489798/keep-image-ratio-using-max-width-and-max-height-in-ie-11
  */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 0 auto;
  }

  @media ${breakpoints.tabletMax} {
    max-height: inherit;
  }
`;

const EventForm = ({
  isNew,
  createNew,
  createDraft,
  success,
  result,
  changedValues,
  setBlockNavigation,
  businessId,
}: {
  isNew: boolean;
  createNew: any;
  createDraft: any;
  success: (message: string, response: EventInterface) => void;
  result?: Partial<EventInterface>;
  changedValues: Set<string>;
  setBlockNavigation: React.Dispatch<React.SetStateAction<boolean>>;
  businessId?: string;
}) => {
  const { hasAdminRights } = useLoginState();
  const adminRights = hasAdminRights();
  const [oldContent, setOldContent] = useState<Partial<EventInterface>>();
  const [isSavingBlocked, setIsSavingBlocked] = useState<boolean>(false);

  const {
    getErrors,
    parseErrors,
    clearErrors,
    values,
    isValid,
    updateValues,
    updateInitialValues,
    getArtistsFieldProps,
    getTextAreaFieldProps,
    getTagFieldProps,
    getImageListProps,
    getTextFieldProps,
    getDateTimeRangeFieldProps,
    getPriceMinMaxFieldProps,
    getSingleInputFieldProps,
    getSettingsFieldProps,
    getLocationAddressFieldProps,
    getPinnedLocationAddressDescriptionFieldProps,
    getCheckboxFieldProps,
    getHashtagsFieldProps,
    getEndDateTypeOptionFieldProps,
    onChange,
  } = useFormFields({
    ...defaultEventFields,
    changedValues,
    setBlockNavigation,
    handleGenericErrors: true,
    translationScope: "eventsScreen.fields",
  });
  const updateValuesRef = useRef(updateValues);

  let id;
  let enableComments;
  let enableRatings;

  if (result) {
    id = result.id;
    enableComments = result.enableComments;
    enableRatings = result.enableRatings;
  }

  useEffect(() => {
    if (result) {
      setOldContent(result);
      const updatedFormFields = setEventFields(result);
      updateValuesRef.current(updatedFormFields);
    }
  }, [result]);

  const location = useLocation();
  const isCopied = location.pathname.endsWith(COPY);

  let getReviewsRequest;
  let saveReviewReplyRequest;
  let deleteReviewReplyRequest;
  if (id) {
    if (adminRights) {
      getReviewsRequest = curry(getReviewsForEventAdmin)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForEventAdmin)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForEventAdmin)(id);
    } else {
      getReviewsRequest = curry(getReviewsForEvent)(id);
      saveReviewReplyRequest = curry(saveReplyToReviewForEvent)(id);
      deleteReviewReplyRequest = curry(deleteReplyToReviewForEvent)(id);
    }
  }

  const { makeRequest } = useMutation(createNew);
  const { onRequestFinished, filterFieldErrors } = useTagRaceConditionCatcher();

  useEffect(() => {
    if (isCopied) return;

    // this makes a publish request on component mount to get all required fields
    makeRequest({
      ...values,
      id: "",
      tagIds: [],
      pictures: [],
      createdDateTime: "",
      status: "PUBLISHED",
      hideFrameConfirmation: true,
      businessId: "",
    }).then(e => {
      if (e && e.error) {
        parseErrors({
          ...e.error,
          detail: undefined,
          fieldsErrors: filterFieldErrors(e.error),
        });
      }
    });
  }, [location]);

  return (
    <>
      {!isNew && result && result.status && (
        <SaveStatusDisplay
          status={result.status}
          modifiedDate={result.modifiedDateTime || null}
        />
      )}

      <TitleInput {...getTextAreaFieldProps("title")} focusOnOpen />

      <TagSelectionField
        {...getTagFieldProps("tags")}
        fieldErrors={getErrors("tagIds")}
        type={EntityTypes.events}
        setIsSavingBlocked={setIsSavingBlocked}
        isNew={isNew}
        isCopied={isCopied}
        businessId={businessId}
        onTagsLoaded={onRequestFinished}
      />

      <HashtagsField {...getHashtagsFieldProps("hashtags")} />

      <Media tablet phone>
        <VerticalLargeSpacer />
      </Media>

      <ImageBox>
        <PictureGallery
          {...getImageListProps()}
          container={ImageUploadType.EVENTS}
          smallPictureContainer={ImageUploadType.EVENTS}
          height="330px"
          parseErrors={parseErrors}
          updateKey={result ? result.modifiedDateTime : undefined}
        />
      </ImageBox>

      <ArtistsInput {...getArtistsFieldProps("artists")} />

      <TextWithIcon
        {...getTextFieldProps("venue", "location.venue")}
        placeholder={strings("detailedScreen.location.venue")}
        errors={getErrors("location.venue")}
        optional
        icon={zooIcon}
      />

      <AddressPicker
        autocompleteProps={getLocationAddressFieldProps()}
        pinnedDescriptionFieldProps={getPinnedLocationAddressDescriptionFieldProps(
          "locationAddress",
          "location",
        )}
        location={result ? result.location : undefined}
      />

      <DateTimeRangePicker
        {...getDateTimeRangeFieldProps("dateTimeRange")}
        endDateTypePickerProps={getEndDateTypeOptionFieldProps("endDateType")}
        canSelectInPast={!isNew}
      />

      <PriceRange {...getPriceMinMaxFieldProps("priceMinMax")} />

      <SimpleIconedInput
        margin={`${appTheme.margin.xsmall} 0`}
        {...getSingleInputFieldProps("tickets")}
      />

      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_EVENTS_ADMIN}>
        <SimpleIconedInput
          margin={`${appTheme.margin.xsmall} 0`}
          icon={treueWelt}
          active={true}
          {...getSingleInputFieldProps("treueWeltLink")}
        />
      </PrivilegedComponent>

      <VerticalMediumSpacer />

      <DescriptionBlock {...getTextAreaFieldProps("description")} richText />

      <Separator />
      {id &&
        getReviewsRequest &&
        saveReviewReplyRequest &&
        deleteReviewReplyRequest && (
          <ReviewsAndRatings
            id={id}
            getRequest={getReviewsRequest}
            saveReviewReply={saveReviewReplyRequest}
            deleteReviewReply={deleteReviewReplyRequest}
            enableComments={enableComments}
            enableRatings={enableRatings}
          />
        )}

      <PrivilegedComponent id={PrivilegedComponentsIds.VIEW_EVENTS_ADMIN}>
        <Separator />
        <Title>{strings("eventsScreen.fields.publishAs.title")}</Title>
        <InlineInputGroup icon={date} margin="0" width="50px">
          <Checkbox
            {...getCheckboxFieldProps("topicOfTheWeek")}
            dateLabel={getDateLabel(result)}
          />
        </InlineInputGroup>
        <Separator />
      </PrivilegedComponent>

      <SettingsArea {...getSettingsFieldProps()} />
      {getActionArea({
        values,
        isNew,
        clearErrors,
        updateInitialValues,
        parseErrors,
        oldContent,
        isSavingBlocked,
        isSubmitDisabled: !isValid,
        setShouldBlockNavigation: setBlockNavigation,
        successCallback: success,
        content: result,
        createDraftItem: createDraft,
        createNewItem: createNew,
        getContentPayload: getEventPayload,
      })}
    </>
  );
};

export default EventForm;
