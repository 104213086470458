import React, { ReactNode } from "react";
import { uniqueId } from "lodash";
import styled from "style/styled-components";
import FieldErrors from "components/forms/FieldErrors";

import { ReactComponent as CheckIcon } from "assets/icons/ok.svg";
import Testable from "testing/Testable";

const Check = styled(CheckIcon)`
  fill: white;
  width: 16px;
  height: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

interface Disableable {
  disabled?: boolean;
}

const CheckboxLabel = styled.label<Disableable>`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.color.foreground.disabled
      : theme.color.foreground.primary};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  line-break: normal;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  visibility: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
`;

export const VerticalCheckboxSiblingContainer = styled.div`
  margin-left: 36px;
`;

const ErrorContainer = styled(VerticalCheckboxSiblingContainer)`
  width: 100%;
`;

interface BackdropProps extends Disableable {
  checked: boolean;
  disabled: boolean;
}

const CheckBoxBackdrop = styled.div<BackdropProps>`
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  background-color: ${({ checked, disabled, theme }) => {
    if (disabled) {
      return theme.color.foreground.tertiary;
    }

    return checked
      ? theme.color.background.secondary
      : theme.color.foreground.antiPrimary;
  }};
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled
        ? theme.color.foreground.tertiary
        : theme.color.background.secondary};
  border-radius: ${({ theme }) => theme.border.radius.small};
  display: flex;
  margin: 0 ${({ theme }) => theme.margin.large} 0 2px;
  align-items: center;
  justify-content: center;
`;

export interface CheckboxProps extends Disableable, Testable {
  name: string;
  text?: string | ReactNode;
  dateLabel?: string;
  checked: boolean;
  checkComponent?: ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  errors?: string[];
}

const Checkbox = ({
  checked,
  checkComponent,
  children,
  name,
  onChange,
  text,
  dateLabel,
  errors,
  disabled = false,
  ...props
}: CheckboxProps) => {
  // This is needed to guarantee id uniqueness for multiple checkboxes
  const id = uniqueId(`checkbox-${name}`);
  return (
    <>
      <CheckboxContainer>
        {children}
        <HiddenCheckbox
          id={id}
          onChange={onChange}
          checked={checked}
          {...props}
        />
        <CheckboxLabel disabled={disabled} htmlFor={id}>
          <CheckBoxBackdrop disabled={disabled} checked={checked}>
            {checked && (checkComponent || <Check />)}
          </CheckBoxBackdrop>
          {text}
          {dateLabel}
        </CheckboxLabel>
      </CheckboxContainer>
      {errors && (
        <ErrorContainer>
          <FieldErrors errors={errors} />
        </ErrorContainer>
      )}
    </>
  );
};

export default Checkbox;
